import React from 'react'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { Box, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'


export const OpenAccessPageHeader = () => {
    const { title } = useAppSelector(state => state.openAccessReducer)
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
                <Typography variant="h1">
                    {title}
                </Typography>
            </Box>
            <Box sx={{
                maxWidth: '100%',
                height: 'auto',
                padding: 0,
                margin: 0,
            }}>
                <Logo/>
            </Box>
        </Box>
    )
}
