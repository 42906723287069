import React, { FC } from 'react'
import { Box, Divider, Drawer, IconButton, Paper, Stack, Typography } from '@mui/material'
import { CloseRounded, LabelRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMMENTS } from 'helpers/enums/titles'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import {
    DrawerCommentAssessmentItem,
} from 'components/drawers/DrawerCommentsAssessment/components/DrawerCommenAssessmenttItem/DrawerCommentAssessmentItem'
import {
    DrawerCommentAssessmentCreate,
} from 'components/drawers/DrawerCommentsAssessment/components/DrawerCommentAssessmentCreate/DrawerCommentAssessmentCreate'
import { useAuthentication } from 'store/hooks/authentication'

type DrawerCommentsAssessmentProps = {
    open: boolean;
    handleClose: () => void;
    skillLevelTitle: string;
    competenceTypeTitle: string;
    competenceTitle: string;
    indicators: IAssessmentIndicator[];
    disableEdit?: boolean;
    service?: PermissionServiceCode;
    divisions?: string[];
    onCreateComment?: (comment: IComment) => void
    onDeleteComment?: (uuid: string) => void
}

export const DrawerCommentsAssessment: FC<DrawerCommentsAssessmentProps> = ({
    open,
    handleClose,
    disableEdit,
    // skillLevelTitle,
    competenceTitle,
    competenceTypeTitle,
    indicators,
    service,
    divisions,
    onDeleteComment,
    onCreateComment,
}) => {
    const hasPermission = service ? useAuthentication({ service, divisions, accessLevel: 'w' }) : true
    const canEdit = hasPermission && onCreateComment && !disableEdit

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 620 },
                },
            }}
        >
            <Box
                role="presentation"
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    p: 5,
                }}
            >
                <Box display="flex" flexDirection="column" pb={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_COMMENTS}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Box>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body1">
                                {competenceTypeTitle}
                            </Typography>
                            {/*{skillLevelTitle && <ChipSkillLevel skillLevelTitle={skillLevelTitle}/>}*/}
                        </Stack>
                        <Typography
                            variant="body2"
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            {competenceTitle}
                        </Typography>
                    </Stack>
                </Box>
                <Box flex={1} overflow="auto">
                    <Stack spacing={3} divider={<Divider flexItem />}>
                        {indicators.map(indicator =>
                            <Paper key={indicator.uuid} variant="outlined" sx={{ borderRadius: 4, p: 3 }}>
                                <Stack direction="row" spacing={1}>
                                    <LabelRounded color="warning" />
                                    <Box>
                                        <Typography variant="body2">
                                            {indicator.title}
                                        </Typography>
                                    </Box>
                                </Stack>
                                {indicator.comments.length > 0
                                    ? <>
                                        <Divider sx={{ my: 3 }} />
                                        <Stack spacing={3} divider={<Divider flexItem />}>
                                            {indicator.comments.map((comment, idx) => <DrawerCommentAssessmentItem
                                                key={`${idx}-${comment.author}`}
                                                comment={comment}
                                                disableEdit={disableEdit}
                                                deleteComment={onDeleteComment}
                                            />)}
                                        </Stack>
                                    </>
                                    : <Box pt={2}>
                                        <EmptyPage
                                            contentText={`Здесь еще нет комментариев.${canEdit ? ' Оставьте первый' : ''}`}
                                        />
                                    </Box>}
                            </Paper>)}
                    </Stack>
                </Box>
                {service
                    ? <Authenticated service={service} accessLevel="w" divisions={divisions}>
                        {!disableEdit && onCreateComment && <Box pt={2}>
                            <DrawerCommentAssessmentCreate createComment={onCreateComment} />
                        </Box>}
                    </Authenticated>
                    : !disableEdit && onCreateComment && <Box pt={2}>
                        <DrawerCommentAssessmentCreate createComment={onCreateComment} />
                    </Box>}
            </Box>
        </Drawer>
    )
}
