import React, { FC, useEffect } from 'react'
import { Box, Button, Divider, Stack, useMediaQuery, useTheme } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks/redux'
import { PATH_EXAM, PATH_MASS_EXAM } from '../../helpers/enums/routePath'
import { appAction } from '../../store/actions'
import { ExamResultPageHeader } from '../../components/examResult/ExamResultPageHeader/ExamResultPageHeader'
import { ExamResultPageContent } from '../../components/examResult/ExamResultPageContent/ExamResultPageContent'
import { useExamItem, useExamResult } from '../../api/queries/exam/examQueries'
import { ErrorPage } from '../../components/ErrorPage/ErrorPage'
import { LoadingComponent } from '../../components/LoadingComponent/LoadingComponent'
import { TITLE_BUTTON_BACK_HISTORY } from '../../helpers/enums/titles'

interface ExamResultPageProps {
    parentPath?: string;
}

export const ExamResultPage: FC<ExamResultPageProps> = ({ parentPath }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { examUuid, massExamUuid } = useParams()

    const theme = useTheme()
    const gtLaptop = useMediaQuery(theme.breakpoints.up('laptop'))
    const { error: examMetaError, isFetching } = useExamItem(examUuid || '')
    const { error: examResultError, isFetching: isFetchingExamResult } = useExamResult(examUuid || '')

    useEffect(() => {
        const backPath = parentPath ? parentPath : massExamUuid?.length ? `${PATH_MASS_EXAM}/${massExamUuid}` : PATH_EXAM
        dispatch(appAction.setBackButtonPath(backPath))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [])

    if (isFetching && isFetchingExamResult) return <LoadingComponent/>
    if (examMetaError || examResultError) return <ErrorPage error={examMetaError || examResultError}/>

    return (
        <Stack
            sx={{
                minHeight: '100%',
                alignItems: 'flex-start',
            }}
        >
            {gtLaptop &&
                <Box mb={5}>
                    <Button
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={() => navigate(parentPath ? parentPath : massExamUuid?.length ? `${PATH_MASS_EXAM}/${massExamUuid}` : PATH_EXAM, { replace: true })}
                    >
                        {parentPath ? TITLE_BUTTON_BACK_HISTORY : massExamUuid?.length ? 'К тестированию' : 'К списку тестов'}
                    </Button>
                </Box>
            }
            <Stack spacing={3} width='100%'>
                <ExamResultPageHeader />
                <Divider/>
                <ExamResultPageContent/>
            </Stack>
        </Stack>
    )
}
