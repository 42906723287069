// Файл для организации меню приложения
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import WorkIcon from '@mui/icons-material/Work'
import PeopleIcon from '@mui/icons-material/People'
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded'
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded'
// import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
// import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded'
import {
    PATH_ANALYTICS,
    PATH_ASSESSMENT,
    PATH_CPA,
    PATH_DEVELOPMENT,
    PATH_EMPLOYEES,
    PATH_MASS_EXAM,
    PATH_HISTORY,
    PATH_PROFILE,
    PATH_SPECIALIZATION,
    PATH_EXAM,
} from 'helpers/enums/routePath'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { StyledComponent } from '@emotion/styled'
import { DifferenceRounded, WatchLaterRounded, FactCheck } from '@mui/icons-material'
import {
    TITLE_NAMESPACE_ASSESSMENTS,
    TITLE_PAGE_DEVELOPMENT,
    TITLE_PAGE_EMPLOYEES,
    TITLE_PAGE_EXAM,
    TITLE_PAGE_HISTORY,
    TITLE_PAGE_MY_ASSESSMENT,
    TITLE_PAGE_MY_EXAMS,
    TITLE_PAGE_SPECIALIZATIONS,
} from 'helpers/enums/titles'


export interface IMenuListItem {
    // сервис относящийся к пункту меню
    service: PermissionServiceCode;
    code: MenuItemsCodes;
    path: string;
    text: string;
    icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & { muiName: string } | StyledComponent<any>;
}

export enum MenuItemsCodes {
    DEVELOPMENT = 'main-menu-item_development',
    MY_ASSESSMENTS = 'main-menu-item_my-assessments',
    MY_EXAMS = 'main-menu-item_my-exams',
    PROFILE = 'main-menu-item_profile',
    HISTORY = 'main-menu-item_history',
    EMPLOYEES = 'main-menu-item_employees',
    SPECIALIZATIONS = 'main-menu-item_specializations',
    ANALYTICS = 'main-menu-item_analytics',
    CPA = 'main-menu-item_cpa',
    MASS_EXAM = 'main-menu-item_mass_exam',
}

export type MenuItemsCodesWithAlerts =
    MenuItemsCodes.DEVELOPMENT |
    MenuItemsCodes.MY_ASSESSMENTS |
    MenuItemsCodes.MY_EXAMS |
    MenuItemsCodes.EMPLOYEES |
    MenuItemsCodes.CPA |
    MenuItemsCodes.MASS_EXAM

// const structureCompany = styled(ShareRoundedIcon)(() => ({
//     rotate: '90deg',
// }))

export const mainMenuItems: IMenuListItem[] = [
    // {
    //     code: 'main-menu-item_home',
    //     text: 'Главная',
    //     path: PATH_HOME,
    //     icon: HomeRoundedIcon,
    // },
    {
        service: 'my_development',
        code: MenuItemsCodes.DEVELOPMENT,
        text: TITLE_PAGE_DEVELOPMENT,
        path: `${PATH_DEVELOPMENT}/pdp`,
        icon: SignalCellularAltRoundedIcon,
    },
    {
        service: 'profile',
        code: MenuItemsCodes.MY_ASSESSMENTS,
        text: TITLE_PAGE_MY_ASSESSMENT,
        path: PATH_ASSESSMENT,
        icon: DifferenceRounded,
    },
    {
        service: 'exam',
        code: MenuItemsCodes.MY_EXAMS,
        text: TITLE_PAGE_MY_EXAMS,
        path: PATH_EXAM,
        icon: FactCheck,
    },
    {
        service: 'profile',
        code: MenuItemsCodes.PROFILE,
        text: 'Мой профиль',
        path: PATH_PROFILE,
        icon: SentimentSatisfiedAltRoundedIcon,
    },
    {
        service: 'history',
        code: MenuItemsCodes.HISTORY,
        text: TITLE_PAGE_HISTORY,
        path: PATH_HISTORY,
        icon: WatchLaterRounded,
    },
]

export const additionalMenuItems: IMenuListItem[] = [
    {
        service: 'employee',
        code: MenuItemsCodes.EMPLOYEES,
        text: TITLE_PAGE_EMPLOYEES,
        path: PATH_EMPLOYEES,
        icon: PeopleIcon,
    },
    {
        service: 'specializations',
        code: MenuItemsCodes.SPECIALIZATIONS,
        text: TITLE_PAGE_SPECIALIZATIONS,
        path: PATH_SPECIALIZATION,
        icon: WorkIcon,
    },
    {
        service: 'pdp',
        code: MenuItemsCodes.ANALYTICS,
        text: 'Аналитика',
        path: PATH_ANALYTICS,
        icon: DonutSmallRoundedIcon,
    },
    {
        service: 'cpa',
        code: MenuItemsCodes.CPA,
        text: TITLE_NAMESPACE_ASSESSMENTS,
        path: PATH_CPA,
        icon: DifferenceRounded,
    },
    {
        service: 'mass_exam',
        code: MenuItemsCodes.MASS_EXAM,
        text: TITLE_PAGE_EXAM,
        path: PATH_MASS_EXAM,
        icon: FactCheck,
    },
    // {
    //     code: 'main-menu-item',
    //     text: 'Дополнительные роли',
    //     path: PATH_ADDITIONAL_ROLES,
    //     icon: GroupAddRoundedIcon,
    // },
    // {
    //     code: 'main-menu-item_allowances',
    //     text: 'Оклады и надбавки',
    //     path: PATH_SALARIES_AND_ALLOWANCES,
    //     icon: MonetizationOnRoundedIcon,
    // },
    // {
    //     code: 'main-menu-item_analytics',
    //     text: 'Аналитика',
    //     path: PATH_ANALYTICS,
    //     icon: DonutSmallRoundedIcon,
    // },
    // {
    //     code: 'main-menu-item',
    //     text: 'Опросы',
    //     path: PATH_SURVEYS,
    //     icon: TextSnippetRoundedIcon,
    // },
    // {
    //     code: 'main-menu-item',
    //     text: 'Оргструктура компании',
    //     path: PATH_ORGANIZATIONAL_STRUCTURE,
    //     icon: structureCompany,
    // },
    // {
    //     code: 'main-menu-item_recruitment',
    //     text: 'Найм',
    //     path: PATH_RECRUITMENT,
    //     icon: PersonAddAltRoundedIcon,
    // },
]

