import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { assessmentStatusAwaitingConfirmation } from 'helpers/assessment/getAssessmentStatus'
import {
    TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT,
    TITLE_BUTTON_OK,
    TITLE_BUTTON_SEND_RESULTS,
} from 'helpers/enums/titles'
import { QueueQueueRequestsController } from 'utils/QueueRequests'
import { myAssessmentActions } from 'store/actions'
import { Button, Stack } from '@mui/material'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { CheckRounded } from '@mui/icons-material'
import {
    MyAssessmentPassComment,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassAction/components/MyAssessmentPassComment/MyAssessmentPassComment'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'

export const MyAssessmentPassAction = () => {
    const { assessmentUuid } = useParams()
    const navigate = useNavigate()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { dialogConfirmSave, toggleDialog } = useDialogsState({
        dialogConfirmSave: false,
    })
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const assessmentQueue = QueueQueueRequestsController.getInstance().addNewQueue(assessmentUuid)
    const uncompletedIndicators = useAppSelector(state => state.MyAssessmentPassReducer.data.uncompletedIndicatorUuids)

    const saveResults = async () => {
        if (assessmentQueue && assessmentQueue.getLength() > 0 || !assessmentUuid) return
        try {
            if (uncompletedIndicators.length > 0) {
                await dispatch(myAssessmentActions.assessmentPassCheckAnswers())
                const docEl = document.getElementById(uncompletedIndicators[0])
                docEl?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                })
            } else {
                await dispatch(myAssessmentActions.assessmentPassChangeStatus({
                    uuid: assessmentUuid,
                    statusUuid: assessmentStatusAwaitingConfirmation,
                }))
                toggleDialog('dialogConfirmSave', true)
            }
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const redirectOnComplete = () => {
        toggleDialog('dialogConfirmSave', false)
        navigate(assessmentSetup?.redirectPath || '')
    }


    return (
        <>
            <DialogConfirm
                open={dialogConfirmSave}
                dialogTitle="Результаты сохранены"
                dialogText="Результаты оценки успешно сохранены"
                titleButtonConfirm={TITLE_BUTTON_OK}
                onSubmit={redirectOnComplete}
                handleClose={redirectOnComplete}
            />
            {!assessmentSetup?.completeStatus && <Stack spacing={3}>
                <MyAssessmentPassComment/>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={assessmentSetup?.disableSaveResults}
                    startIcon={<CheckRounded/>}
                    onClick={saveResults}
                >
                    {TITLE_BUTTON_SEND_RESULTS}
                </Button>
            </Stack>}
            {assessmentSetup?.completeStatus &&
                <Button
                    sx={{ mb: 3 }}
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate(`${PATH_ASSESSMENT}/${assessmentUuid}/result`, { replace: true })}
                >
                    {TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT}
                </Button>}
        </>
    )
}
