import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { TextLink } from 'components/common/TextLink/TextLink'
import { HINT_EMAIL_RECOVER_WAS_SEND } from 'helpers/enums/hints'
import { TITLE_BUTTON_AUTH } from 'helpers/enums/titles'
import { useNavigate } from 'react-router-dom'
import { PATH_LOGIN } from 'helpers/enums/routePath'
import { EmailOutlined } from '@mui/icons-material'

export const FormLoginForgottenSuccess: FC = () => {
    const navigate = useNavigate()
    return (
        <Stack spacing={4}>
            <Stack spacing={3}>
                <Box display="flex" justifyContent="center">
                    <EmailOutlined
                        color="primary"
                        fontSize="large"
                        sx={{ width: 100, height: 100 }}
                    />
                </Box>
                <Typography variant="body1">
                    {HINT_EMAIL_RECOVER_WAS_SEND}
                </Typography>
            </Stack>
            <Box display="flex" justifyContent="center">
                <TextLink onCustomClickEvent={() => navigate(PATH_LOGIN)}>
                    {TITLE_BUTTON_AUTH}
                </TextLink>
            </Box>
        </Stack>
    )
}
