import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { TITLE_BUTTON_BACK_HISTORY } from 'helpers/enums/titles'
import {
    HistoryPdp,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/HistoryPdp'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { employeeHistoryPdpActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useAppSelector } from 'store/hooks/redux'
import { PATH_ERROR } from 'helpers/enums/routePath'

type EmployeeHistoryPdpProps = {
    parentPath: string;
}

export const EmployeeHistoryPdp: FC<EmployeeHistoryPdpProps> = ({ parentPath }) => {
    const navigate = useNavigate()
    const { employeeUuid, pdpUuid } = useParams()
    const { pdp } = useAppSelector(state => state.employeeHistoryPdpReducer.data)
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const pdpPath = `${parentPath}/pdp/${pdp?.uuid}`

    const { isFetching, error } = useLayoutFetch(employeeHistoryPdpActions.getEmployeeHistoryPdp(
        employeeUuid || uuid,
        pdpUuid || '',
    ), {
        deps: [employeeUuid || uuid, pdpUuid],
        permissions: { service: 'history', accessLevel: 'r' },
    })

    if (isFetching) return <LoadingComponent />
    if (!pdp) return null
    if (error) return <Navigate to={PATH_ERROR} replace state={error} />

    return (
        <Box>
            <Button
                sx={{ mb: 5 }}
                startIcon={<ArrowBackRoundedIcon />}
                onClick={() => navigate(parentPath, { replace: true })}
            >
                {TITLE_BUTTON_BACK_HISTORY}
            </Button>
            {(employeeUuid || uuid) &&
                <HistoryPdp pdp={pdp} parentPath={pdpPath} employeeUuid={employeeUuid || uuid} />}
        </Box>
    )
}
