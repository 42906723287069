import React, { FC } from 'react'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_ADD,
    TITLE_BUTTON_CANCEL,
    TITLE_DIALOG_PDP_ITEM_CREATE,
    TITLE_DIALOG_PDP_ITEM_CREATE_MOBILE,
    TITLE_FIELD_DESCRIPTION,
    TITLE_FIELD_PLACEHOLDER_PDP_ITEM_DESCRIPTION,
    TITLE_FIELD_STUDY_TIME,
    TITLE_FIELD_TITLE_PDP_ITEM,
    TITLE_NAMESPACE_EMPLOYEE,
    TITLE_NAMESPACE_USER_ACTIVITY,
} from 'helpers/enums/titles'
import { Box, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { HINT_SUCCESS_PDP_ITEM_CREATE } from 'helpers/enums/hints'
import { InputForm } from 'components/form/InputForm/InputForm'
import { Controller, useForm } from 'react-hook-form'
import { IPdpUniqueItemCreateRequest } from 'api/typing/employeeTypes'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { developmentPdpActions, pdpActions } from 'store/actions'
import { useNotify } from 'store/hooks/useNotify'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { InputMask } from 'components/form/InputMask/InputMask'
import { useMobile } from 'helpers/hooks/useMobile'

type DialogCreatePdpItemProps = {
    handleClose: () => void;
    open: boolean;
    developmentPage?: boolean;
}

interface IFormValues extends Omit<IPdpUniqueItemCreateRequest, 'studyTime'> {
    studyTime: string;
}

export const DialogCreatePdpItem: FC<DialogCreatePdpItemProps> = ({ handleClose, open, developmentPage }) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const mobile =  useMobile()
    const { pdp: pdpPdp } = useAppSelector(state => state.pdpReducer.data)
    const { pdp: developmentPdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const pdp = developmentPage ? developmentPdp : pdpPdp

    const pdpUuidInPdp = pdpPdp?.uuid
    const pdpUuidInDevelopment = developmentPdp?.currentPdpUuid
    const pdpUuid = pdpUuidInPdp || pdpUuidInDevelopment

    const employee = pdp?.employee

    const { handleSubmit, control, formState: { errors } } = useForm<IFormValues>({
        shouldFocusError: false,
        defaultValues: {
            title: '',
            studyTime: '',
            sortIndex: pdp?.planItems?.length
                ? pdp?.planItems?.reduce((p, c) => p.sortIndex > c.sortIndex ? p : c).sortIndex + 1
                : 0,
            employeeUuid: employee?.uuid,
            pdpUuid: pdpUuid,
            entityType: 'unique',
            description: '',
            comments: [],
            typeTitle: TITLE_NAMESPACE_USER_ACTIVITY,
        },
    })

    const onSubmit = async (data: IFormValues) => {
        const payload: IFormValues = {
            ...removeWhiteSpaceFromObjectElements(data),
        }
        try {
            if (developmentPage)
                await dispatch(developmentPdpActions.createUniquePdpItem({
                    ...payload,
                    studyTime: Number(payload.studyTime),
                }))
            else
                await dispatch(pdpActions.createUniqueItemPdp({
                    ...payload,
                    studyTime: Number(payload.studyTime),
                }))
            notify(HINT_SUCCESS_PDP_ITEM_CREATE, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!employee || !pdp) return null

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {!mobile ? TITLE_DIALOG_PDP_ITEM_CREATE : TITLE_DIALOG_PDP_ITEM_CREATE_MOBILE}
            </DialogTitle>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogContent
                    sx={{
                        padding: mobile ? '0 24px' : '20px 24px',
                    }}
                >
                    {!mobile &&
                        <Box mb={2.5}>
                            <Typography variant="body1" mb={1}>
                                {TITLE_NAMESPACE_EMPLOYEE}
                            </Typography>
                            <Typography variant="body2">
                                {employee.lastName} {employee.firstName} {employee.secondName}
                            </Typography>
                        </Box>
                    }
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2.5}
                    >
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.title,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="title"
                                    title={TITLE_FIELD_TITLE_PDP_ITEM}
                                    required
                                    inputProps={{
                                        maxLength: fieldsRestriction
                                            .employees
                                            .employee
                                            .pdp
                                            .item
                                            .title
                                            .maxLength
                                            .value,
                                    }}
                                    autoFocus
                                    error={!!errors.title}
                                    helperText={errors?.title ? errors.title.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.description,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="description"
                                    title={TITLE_FIELD_DESCRIPTION}
                                    multiline
                                    rows={mobile ? 10 : 7}
                                    required
                                    inputProps={{
                                        maxLength: fieldsRestriction
                                            .employees
                                            .employee
                                            .pdp
                                            .item
                                            .description
                                            .maxLength
                                            .value,
                                    }}
                                    placeholder={TITLE_FIELD_PLACEHOLDER_PDP_ITEM_DESCRIPTION}
                                    error={!!errors.description}
                                    helperText={errors?.description ? errors.description.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                        <Controller
                            name="studyTime"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.studyTime,
                            }}
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    id="studyTime"
                                    mask={Number}
                                    scale={0}
                                    min={0}
                                    max={999}
                                    variant="outlined"
                                    required
                                    title={TITLE_FIELD_STUDY_TIME}
                                    error={!!errors.studyTime}
                                    helperText={errors?.studyTime ? errors.studyTime.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        sx={!mobile ? {} : {
                            fontSize: 16,
                            lineHeight: '26px',
                            padding: '12px 20px',
                        }}
                        variant="text"
                        onClick={handleClose}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        sx={!mobile ? {} : {
                            fontSize: 16,
                            lineHeight: '26px',
                            padding: '12px 20px',
                        }}
                        variant="contained"
                        type="submit"
                    >
                        {TITLE_BUTTON_ADD}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
