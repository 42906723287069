import {
    Method,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosInstance,
    AxiosStatic,
} from 'axios'
import { addExtractData } from 'api/interceptors/extractData'
import { addErrorLogger } from 'api/interceptors/errorLogger'
import { addHeadersToRequest } from 'api/interceptors/headersToRequest'
import { addRefreshingAccessToken } from 'api/interceptors/refreshToken'

export class BaseApi {
    protected readonly apiPrefix = 'api' as string
    protected readonly apiVersion = 'v1' as string
    protected readonly endpoint: string = ''
    protected readonly baseUrl: string = process.env.REACT_APP_BASE_URL as string
    protected http: AxiosInstance

    constructor(http: AxiosStatic) {
        const options = {
            baseURL: this.baseUrl,
        }

        this.http = http.create(options)
        this.setInterceptors()
    }

    public request<T, R = AxiosResponse<T>>(
        method: Method,
        path: string,
        config: AxiosRequestConfig<T> = {},
    ) {
        if (!this.endpoint && process.env.NODE_ENV !== 'production') {
            throw new Error(`You must set endpoint in "${this.constructor.name}"`)
        }

        const url = this.getUrl(path)

        return this.http.request<T, R>({
            ...config,
            method,
            url,
        })
    }

    protected setInterceptors() {
        addErrorLogger(this.http)
        addExtractData(this.http)
        addHeadersToRequest(this.http)
        addRefreshingAccessToken(this.http)
    }

    protected getUrl(path: string) {
        return [this.apiPrefix, this.apiVersion, this.endpoint, path]
            ?.filter(Boolean)
            ?.join('/')
            ?.replace('//', '/')
    }
}
