import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, Stack, Typography } from '@mui/material'
import { FilterListRounded } from '@mui/icons-material'
import {
    TITLE_FIELD_TIME,
    TITLE_FIELD_TYPE,
    TITLE_NAMESPACE_ALL,
    TITLE_NOT_FOUND,
} from 'helpers/enums/titles'
import { InputForm } from 'components/form/InputForm/InputForm'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { removeEmptyFromObject } from 'helpers/removeEmptyFromObject'
import { useAppSelector } from 'store/hooks/redux'
import { format, subDays, subMonths, subYears } from 'date-fns'

const recentlyValues = [
    {uuid: format(new Date(), 'dd.MM.yyyy'), title: 'Сегодня'},
    {uuid: format(subDays(new Date(), 1), 'dd.MM.yyyy'), title: 'Вчера'},
    {uuid: format(subDays(new Date(), 7), 'dd.MM.yyyy'), title: 'Последние 7 дней'},
    {uuid: format(subDays(new Date(), 30), 'dd.MM.yyyy'), title: 'Последние 30 дней'},
    {uuid: format(subMonths(new Date(), 3), 'dd.MM.yyyy'), title: 'Последние 3 месяца'},
    {uuid: format(subMonths(new Date(), 6), 'dd.MM.yyyy'), title: 'Последние 6 месяцев'},
    {uuid: format(subYears(new Date(), 1), 'dd.MM.yyyy'), title: 'Последний год'},
]

export const EmployeeTabHistoryFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { historyEventTypes } = useAppSelector(state => state.employeeViewHistoryReducer.data)

    const [recently, setRecently] = useState<string | null>(
        recentlyValues?.find(el => el.uuid === searchParams.get('recently'))?.title || null,
    )
    const [eventTypeUuid, setEventTypeUuid] = useState<string | null>(
        historyEventTypes?.find(el => el.uuid === searchParams.get('eventTypeUuid'))?.title || null,
    )

    const recentlyValuesOptions = useMemo(() => recentlyValues?.map(el => el.title) || [], [recentlyValues])
    const eventTypeUuidValuesOptions = useMemo(() => historyEventTypes?.map(el => el.title) || [], [historyEventTypes])


    useEffect(() => {
        if (!historyEventTypes || !recentlyValues) return

        const recentlyParam = recentlyValues.find(el => el.title === recently)?.uuid || null
        const eventTypeUuidParam = historyEventTypes?.find(el => el.title === eventTypeUuid)?.uuid || null
        if (searchParams.get('recently') !== recentlyParam || searchParams.get('eventTypeUuid') !== eventTypeUuidParam) {

            setSearchParams(removeEmptyFromObject({
                recently: recentlyParam,
                eventTypeUuid: eventTypeUuidParam,
            }) as URLSearchParamsInit)
        }

    }, [recently, eventTypeUuid])


    return (
        <Stack component="form" spacing={2} direction="row" alignItems="center">
            <FilterListRounded color="primary"/>
            <Autocomplete
                options={recentlyValuesOptions}
                sx={{
                    width: 224,
                }}
                value={recently}
                noOptionsText={TITLE_NOT_FOUND}
                getOptionLabel={(el) => `${TITLE_FIELD_TIME} (${el})`}
                onChange={(event, value) => setRecently(value as string)}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Typography variant="body1" sx={{color: ({palette})=> palette.text.primary}}>
                            {option}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <InputForm {...params} placeholder={`${TITLE_FIELD_TIME} (${TITLE_NAMESPACE_ALL})`}/>
                )}
            />
            <Autocomplete
                options={eventTypeUuidValuesOptions}
                sx={{
                    width: 224,
                }}
                value={eventTypeUuid}
                noOptionsText={TITLE_NOT_FOUND}
                getOptionLabel={(el) => `${TITLE_FIELD_TYPE} (${el})`}
                onChange={(event, value) => setEventTypeUuid(value as string)}
                renderOption={(props, option) => {
                    return (
                        <li {...props}>
                            <Typography variant="body1" sx={{color: ({palette})=> palette.text.primary}}>
                                {option}
                            </Typography>
                        </li>
                    )
                }}
                renderInput={(params) => (
                    <InputForm {...params} placeholder={`${TITLE_FIELD_TYPE} (${TITLE_NAMESPACE_ALL})`}/>
                )}
            />
        </Stack>
    )
}

