import { TransferCPA } from 'api/services/CPAService/transferCPA'

export class CPANotifyService extends TransferCPA {

    public deadline(uuid: string) {
        const path = `/${uuid}/notify/deadline`
        return this.request<{ uuid: string }>('POST', path, { data: { uuid } })
    }

    public geLastNotifyDeadline(uuid: string) {
        const path = `/${uuid}/notify/deadline/last`
        return this.request<null, { date: string }>('GET', path)
    }
}
