import React, { FC } from 'react'
import { Avatar, Badge, Box, Divider, Link, Stack, Typography } from '@mui/material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { useNavigate } from 'react-router-dom'
import { PATH_EMPLOYEES, PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import { TITLE_NAMESPACE_GRADE, TITLE_NAMESPACE_SPECIALIZATION } from 'helpers/enums/titles'
import { NavLink as RouterLink } from 'react-router-dom'
import { PointPositionGradeChip } from 'components/badges/PointPositionGradeChip/PointPositionGradeChip'
import { viewEmployeeTabs } from 'helpers/enums/tabs/employee/viewEmployeeTabs'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

type EmployeesTabCurrentEmployeeProps = {
    element: IEmployeesEmployee;
    divisionUuid: string;
}

export const EmployeesTabCurrentEmployee: FC<EmployeesTabCurrentEmployeeProps> = ({ element, divisionUuid }) => {
    const navigate = useNavigate()

    const specializationTitle = element?.currentPosition?.specializationTitle || TITLE_NAMESPACE_SPECIALIZATION
    const gradeTitle = element?.currentPosition?.gradeTitle || TITLE_NAMESPACE_GRADE
    const employeeFullName = `${element.lastName} ${element.firstName} ${element.secondName}`

    const onEdit = () => element?.uuid && navigate(`${PATH_EMPLOYEES}/${element.uuid}/${PATH_EMPLOYEES_EDIT}`)

    return (
        <>
            <Box display="flex" mb={1.5}>
                <Badge
                    badgeContent="!"
                    color="error"
                    overlap="circular"
                    invisible={!element.needAction}
                    sx={{ mr: 2.5 }}
                >
                    <Avatar src={element.photo} sx={{ width: 64, height: 64 }} />
                </Badge>
                <Box flex="1" display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Box mb={0.5}>
                            <Link
                                component={RouterLink}
                                to={`${PATH_EMPLOYEES}/${element.uuid}/${viewEmployeeTabs[0]}`}
                                variant="subtitle1"
                                underline="none"
                            >
                                {employeeFullName}
                            </Link>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: (theme) => element?.currentPosition?.specializationTitle ? '' : theme.palette.secondary.main,
                                    }}
                                >
                                    {specializationTitle}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: (theme) => element?.currentPosition?.specializationTitle
                                        || element?.currentPosition?.gradeTitle
                                            ? ''
                                            : theme.palette.secondary.main,
                                    }}
                                >
                                    -
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: (theme) => element?.currentPosition?.specializationTitle ? '' : theme.palette.secondary.main,
                                    }}
                                >
                                    {gradeTitle}
                                </Typography>
                                {element?.pointPosition &&
                                    <PointPositionGradeChip title={element?.pointPosition?.gradeTitle}/>}
                            </Stack>
                        </Box>
                    </Box>
                    <Authenticated service="employee" accessLevel="w" divisions={[divisionUuid]}>
                        <SecondaryActions
                            // onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    </Authenticated>
                </Box>
            </Box>
            <Divider sx={{ mb: 1.5 }} />
        </>
    )
}
