import React from 'react'
import { Box, Stack } from '@mui/material'
import { TITLE_PAGE_MY_EXAMS } from '../../helpers/enums/titles'
import { PageTitle } from '../../components/PageTitle/PageTitle'
import { ExamListPageFilters } from '../../components/exam/ExamList/ExamListPageFilters/ExamListPageFilters'
import { EmptyPage } from '../../components/EmptyPage/EmptyPage'
import { useExamList } from '../../api/queries/exam/examQueries'
import { LoadingComponent } from '../../components/LoadingComponent/LoadingComponent'
import { ErrorPage } from '../../components/ErrorPage/ErrorPage'
import { useAppSelector } from '../../store/hooks/redux'
import { ExamListPageContent } from '../../components/exam/ExamList/ExamListPageContent/ExamListPageContent'

export const ExamListPage = () => {
    const employeeUuid = useAppSelector(state => state.userReducer.data.uuid)
    const { isFetching, error, data } = useExamList(employeeUuid)

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Stack height='100%'>
            <Box mb={4}>
                <PageTitle
                    title={TITLE_PAGE_MY_EXAMS}
                />
            </Box>
            {!data?.length ?
                <EmptyPage contentText="Тут будут находиться тесты, которые вам назначат на прохождение" />
                :
                <>
                    <ExamListPageFilters/>
                    <ExamListPageContent />
                </>
            }
        </Stack>
    )
}
