import { AppDispatch } from 'store/store'
import { useAppDispatch } from 'store/hooks/redux'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useAuthentication } from 'store/hooks/authentication'

type FetchOptions = {
    permissions?: UseAuthenticationProps;
    deps?: Array<string | boolean | number | undefined>
}

export function useLayoutFetch(
    action: (dispatch: AppDispatch) => Promise<void>,
    options?: FetchOptions,
) {
    const dispatch = useAppDispatch()
    const [isFetching, setIsFetching] = useState(false)
    let fetch = false
    const accessGranted = options?.permissions ? useAuthentication(options.permissions) : true

    const [error, setError] = useState<any>(null)

    useLayoutEffect(() => {
        const isAllRequired = options?.deps ? options.deps.every((el) => !!el) : true
        const fetchData = async () => {
            if (!isAllRequired) return

            if (!accessGranted) return

            setIsFetching(true)
            fetch = true
            try {
                await dispatch(action)
            } catch (e) {
                setError(e)
            } finally {
                setIsFetching(false)
                fetch = false
            }
        }

        !fetch && fetchData().then()
    }, [])

    return { error, isFetching }
}

export function useFetch(action: (dispatch: AppDispatch) => Promise<void>, requiredVariables?: any[]) {
    const dispatch = useAppDispatch()
    const [isFetching, setIsFetching] = useState(false)
    let fetch = false


    const [error, setError] = useState<any>(null)

    useEffect(() => {
        const isAllRequired = requiredVariables ? requiredVariables.every((el) => !!el) : true
        const fetchData = async () => {
            if (!isAllRequired) return

            setIsFetching(true)
            fetch = true
            try {
                await dispatch(action)
            } catch (e) {
                setError(e)
            } finally {
                setIsFetching(false)
                fetch = false
            }
        }

        !fetch && fetchData().then()
    }, [])

    return { error, isFetching }
}
