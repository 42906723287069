import React, { FC, useState } from 'react'
import { Box, Button, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { TITLE_BUTTON_INSTRUCTION, TITLE_BUTTON_OK, TITLE_DIALOG_ASSESSMENT_INSTRUCTION } from 'helpers/enums/titles'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { useAppSelector } from 'store/hooks/redux'
import { getCpaType } from 'helpers/cpa/cpaTypes'
import { useAuthentication } from 'store/hooks/authentication'
import {
    instructionAssessmentCpa180360,
    instructionAssessmentCpa180360Editor,
    instructionAssessmentCpaSelf,
    instructionAssessmentEmployee,
    instructionAssessmentManagerOrParticipant,
} from 'helpers/enums/Instructions'

type AssessmentInstructionProps = {
    assessmentStatusUuid: string | undefined;
    answers: (IEntity & { description: string })[];
    evaluatedUuid: string | undefined;
    assessmentTypeUuid: string | undefined;
    isCpaPage?: boolean;
    divisionUuid?: string;
    mobileVersion?: boolean;
}

export const AssessmentInstruction: FC<AssessmentInstructionProps> = ({
    assessmentStatusUuid,
    answers,
    evaluatedUuid,
    assessmentTypeUuid,
    isCpaPage,
    divisionUuid,
    mobileVersion,
}) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const viewerIsEvaluated = uuid === evaluatedUuid
    const assessmentType = getCpaType(assessmentTypeUuid)
    const fullAccessCpa = useAuthentication({
        service: 'cpa',
        accessLevel: 'w',
        divisions: divisionUuid ? [divisionUuid] : [],
    })
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const instruction = (() => {
        switch (true) {
            case !isCpaPage && viewerIsEvaluated: {
                return instructionAssessmentEmployee
            }
            case !isCpaPage && !viewerIsEvaluated: {
                return instructionAssessmentManagerOrParticipant
            }
            case isCpaPage && assessmentType === 'self': {
                return instructionAssessmentCpaSelf
            }
            case isCpaPage && assessmentType !== 'self' && fullAccessCpa: {
                return instructionAssessmentCpa180360Editor
            }
            case isCpaPage && assessmentType !== 'self' && !fullAccessCpa: {
                return instructionAssessmentCpa180360
            }
        }
    })()

    if (!assessmentStatusUuid || !evaluatedUuid || !assessmentTypeUuid) return null

    return (
        <Box>
            {mobileVersion
                ? <IconButton color="primary" onClick={handleOpen}>
                    <InfoOutlined/>
                </IconButton>
                : <Button
                    variant="text"
                    startIcon={<InfoOutlined/>}
                    onClick={handleOpen}
                >
                    {TITLE_BUTTON_INSTRUCTION}
                </Button>}
            <Dialog
                fullScreen={mobile}
                open={open}
                onClose={handleClose}
                fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
            >
                <DialogTitle onClose={handleClose}>
                    {TITLE_DIALOG_ASSESSMENT_INSTRUCTION}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={4}>
                        <Stack spacing={1}>
                            {instruction?.textIntro?.map(text => <Typography
                                key={text}
                                variant="body2"
                            >
                                {text}
                            </Typography>)}
                        </Stack>
                        <Stack spacing={2} divider={<Divider/>}>
                            {answers?.map(answer => <Stack key={answer.uuid}>
                                <Typography variant="body2">
                                    {answer.title}
                                </Typography>
                                {answer.description && <Typography variant="body1">
                                    {answer.description}
                                </Typography>}
                            </Stack>)}
                        </Stack>
                        <Stack spacing={1}>
                            {instruction?.textConclusion?.map(text => <Typography
                                key={text}
                                variant="body2"
                            >
                                {text}
                            </Typography>)}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ padding: 3 }}>
                    <Button onClick={handleClose}>{TITLE_BUTTON_OK}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
