export enum Status {
    CREATED = 'created',
    READY = 'ready',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export enum EntityType {
    ORIGIN = 'origin',
    CUSTOM = 'custom',
    UNIQUE = 'unique'
}

export enum AccessLevel {
    R ='r', // Просмотр(свои подразделения)
    W ='w', // Просмотр и управление(свои подразделения)
    D ='d', // Нет доступа
    RX ='rx', // Просмотр
    WX = 'wx', // Просмотр и управление
}

export enum PermissionServiceCode {
    COMPETENCE_BASE ='competence_base',
    DICTIONARY = 'dictionary',
    EMPLOYEE = 'employee',
    PDP = 'pdp',
    ASSESSMENT = 'assessment',
    SPECIALIZATIONS = 'specializations',
    PROFILE = 'profile',
    MY_DEVELOPMENT = 'my_development',
    HISTORY = 'history',
    CPA = 'cpa',
    MASS_EXAM = 'mass_exam',
    EXAM = 'exam',
}
