import React from 'react'
import {IconButton, Tooltip} from '@mui/material'
import {HelpOutlineRounded} from '@mui/icons-material'
import {HINT_TOOLTIP_SUPPORT} from 'helpers/enums/hints'


export const AppSupportLink = () => {
    const supportLink = () => {
        window.open(process.env.REACT_APP_SUPPORT_LINK as string)
    }
    return (
        <Tooltip
            title={HINT_TOOLTIP_SUPPORT}
            placement="top"
            arrow
        >
            <IconButton
                onClick={() => supportLink()}
            >
                <HelpOutlineRounded/>
            </IconButton>
        </Tooltip>
    )
}
