import React from 'react'
import { HowToRegRounded, PlayArrowRounded, PlaylistAddCheckRounded, SummarizeRounded } from '@mui/icons-material'
import {
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_IN_PROGRESS,
    TITLE_NAMESPACE_ON_APPROVAL,
    TITLE_NAMESPACE_READY_TO_START,
    TITLE_NAMESPACE_WAS_CREATED,
} from 'helpers/enums/titles'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { emptyUuid } from 'helpers/isEmptyUuid'


// export const cpaStatusCreated = '5d3e19bd-350e-440d-ac28-50ef6808c9aa' // 'Создан', 'new'
export const cpaStatusCreated = emptyUuid // 'Создан', 'new'
export const cpaStatusReady = 'c903fa53-2f20-4c23-8d4f-e637b57434ff' // 'Готов к запуску', 'ready'
export const cpaStatusProgress = 'c62278cc-ca52-4d94-a45f-ed393ce37e90' // 'В процессе', 'in_progress'
export const cpaStatusApproval = 'be5b7367-f4a6-458d-81d4-eac251fde3be' // 'На утверждение', 'for_approval'
export const cpaStatusComplete = '216a0331-0b55-40ce-a810-e89c5621b5d3' // 'Завершена', 'done'

export const cpaStatusCreatedEntity = {
    uuid: cpaStatusCreated,
    title: TITLE_NAMESPACE_WAS_CREATED,
}
export const cpaStatusReadyEntity = {
    uuid: cpaStatusReady,
    title: TITLE_NAMESPACE_READY_TO_START,
}
export const cpaStatusProgressEntity = {
    uuid: cpaStatusProgress,
    title: TITLE_NAMESPACE_IN_PROGRESS,
}
export const cpaStatusApprovalEntity = {
    uuid: cpaStatusApproval,
    title: TITLE_NAMESPACE_ON_APPROVAL,
}
export const cpaStatusCompleteEntity = {
    uuid: cpaStatusComplete,
    title: TITLE_NAMESPACE_COMPLETE,
}

export const cpaStatuses = [cpaStatusCreated, cpaStatusReady, cpaStatusProgress, cpaStatusApproval, cpaStatusComplete]
export const cpaStatusesEntity = [
    cpaStatusCreatedEntity,
    cpaStatusReadyEntity,
    cpaStatusProgressEntity,
    cpaStatusApprovalEntity,
    cpaStatusCompleteEntity,
]

type CpaStatuses =
    typeof cpaStatusCreated
    | typeof cpaStatusReady
    | typeof cpaStatusProgress
    | typeof cpaStatusApproval
    | typeof cpaStatusComplete

export type CpaStatusCode = 'created' | 'ready' | 'progress' | 'approval' | 'complete'

export interface ICpaStatusChip extends IStatusChip {
    uuid: CpaStatuses;
    code: CpaStatusCode;
}

export const getCpaStatus = (statusUuid: string): CpaStatusCode | null => {
    switch (statusUuid) {
        case cpaStatusCreated:
            return 'created'
        case cpaStatusReady:
            return 'ready'
        case cpaStatusProgress:
            return 'progress'
        case cpaStatusApproval:
            return 'approval'
        case cpaStatusComplete:
            return 'complete'
        default:
            return null
    }
}

const cpaStatusChips: Record<string, ICpaStatusChip> = {
    [cpaStatusCreated]: {
        uuid: cpaStatusCreated,
        color: 'default',
        variant: 'filled',
        code: 'created',
        icon: React.createElement(SummarizeRounded),
        title: TITLE_NAMESPACE_WAS_CREATED,
    },
    [cpaStatusReady]: {
        uuid: cpaStatusReady,
        color: 'primary',
        variant: 'outlined',
        code: 'ready',
        icon: React.createElement(CheckCircleOutlineRoundedIcon),
        title: TITLE_NAMESPACE_READY_TO_START,
    },
    [cpaStatusProgress]: {
        uuid: cpaStatusProgress,
        color: 'primary',
        variant: 'outlined',
        code: 'progress',
        icon: React.createElement(PlayArrowRounded),
        title: TITLE_NAMESPACE_IN_PROGRESS,
    },
    [cpaStatusApproval]: {
        uuid: cpaStatusApproval,
        color: 'warning',
        variant: 'outlined',
        code: 'approval',
        icon: React.createElement(HowToRegRounded),
        title: TITLE_NAMESPACE_ON_APPROVAL,
    },
    [cpaStatusComplete]: {
        uuid: cpaStatusComplete,
        color: 'success',
        variant: 'filled',
        code: 'complete',
        icon: React.createElement(PlaylistAddCheckRounded),
        title: TITLE_NAMESPACE_COMPLETE,
    },
}

export const getCpaStatusChip = (status?: string) => status && cpaStatusChips[status]
    ? cpaStatusChips[status]
    : cpaStatusChips[cpaStatusCreated]
