import React, { memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_REQUIRED_LEVEL } from 'helpers/enums/titles'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    EmployeeAssessmentSkillLevel,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentSkillLevel/EmployeeAssessmentSkillLevel'
import {
    EmployeeAssessmentIndicator,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/EmployeeAssessmentIndicator'
import {
    EmployeeAssessmentCompetenceWarning,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentCompetenceWarning/EmployeeAssessmentCompetenceWarning'

type EmployeeAssessmentCompetenceProps = {
    competenceUuid: string;
}

export const EmployeeAssessmentCompetence = memo(
    function EmployeeAssessmentCompetence({ competenceUuid }: EmployeeAssessmentCompetenceProps) {
        const competence = useAppSelector(state => state.MyAssessmentPassReducer.data.competencies[competenceUuid])
        const competenceIndicatorUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceIndicatorUuids[competenceUuid])

        const competenceSkillLevelUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceSkillLevelUuids[competenceUuid])

        if (!competence) return null

        return (
            <Stack spacing={3}>
                <Stack spacing={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                        <Stack>
                            <Typography variant="caption">
                                {competence.typeTitle}
                            </Typography>
                            <Typography variant="h3">
                                {competence.title}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography variant="body2">
                                {TITLE_NAMESPACE_REQUIRED_LEVEL}
                            </Typography>
                            <ChipSkillLevel skillLevelTitle={competence.skillLevelTitle}/>
                            <Chip
                                color="warning"
                                label={`${competence.score}%`}
                            />
                        </Stack>
                    </Box>
                    <EmployeeAssessmentCompetenceWarning competenceUuid={competenceUuid}/>
                </Stack>
                <Divider/>
                {competenceSkillLevelUuids?.length > 0
                    ? <Stack spacing={6}>
                        {competenceSkillLevelUuids?.map(el => <EmployeeAssessmentSkillLevel
                            key={el}
                            skillLevelUuid={el}
                            competenceUuid={competenceUuid}
                        />)}
                    </Stack>
                    : <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                        <Stack spacing={2} divider={<Divider/>}>
                            {competenceIndicatorUuids.map((indicatorUuid, idx) => <EmployeeAssessmentIndicator
                                key={indicatorUuid}
                                competenceUuid={competenceUuid}
                                indicatorUuid={indicatorUuid}
                                idx={idx + 1}
                            />)}
                        </Stack>
                    </Paper>
                }
            </Stack>
        )
    },
)
