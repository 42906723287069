import {
    TITLE_NAMESPACE_LEVEL_BASE,
    TITLE_NAMESPACE_LEVEL_HIGH,
    TITLE_NAMESPACE_LEVEL_MIDDLE,
} from 'helpers/enums/titles'
import { green, grey, orange, red } from '@mui/material/colors'

// функция по рассчету цвета чипа с уровнем, нужна доработка и рефакторинг
export const getSkillLevelColor = (title: string) => {
    switch (title.toLowerCase()) {
        case TITLE_NAMESPACE_LEVEL_BASE.toLowerCase(): {
            return red[50]
        }
        case TITLE_NAMESPACE_LEVEL_MIDDLE.toLowerCase(): {
            return orange[50]
        }
        case TITLE_NAMESPACE_LEVEL_HIGH.toLowerCase(): {
            return green[50]
        }
        default: {
            return grey[100]
        }
    }
}
