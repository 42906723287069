import {
    TITLE_NAMESPACE_ARTICLE,
    TITLE_NAMESPACE_BOOK, TITLE_NAMESPACE_COMMUNICATION,
    TITLE_NAMESPACE_COURSE,
    TITLE_NAMESPACE_DOCUMENTATION, TITLE_NAMESPACE_EXERCISE, TITLE_NAMESPACE_INTERVIEW,
    TITLE_NAMESPACE_MASTER_CLASS, TITLE_NAMESPACE_MENTORING, TITLE_NAMESPACE_OTHER,
    TITLE_NAMESPACE_PROJECT, TITLE_NAMESPACE_SIMULATOR,
    TITLE_NAMESPACE_TASK,
    TITLE_NAMESPACE_TRAINER, TITLE_NAMESPACE_TRAINING,
    TITLE_NAMESPACE_VIDEO,
    TITLE_NAMESPACE_WEBINAR,
} from 'helpers/enums/titles'

export const formatsOfMaterials: IEntity[] = [
    {
        title: TITLE_NAMESPACE_COURSE,
        uuid: 'course',
    },
    {
        title: TITLE_NAMESPACE_BOOK,
        uuid: 'book',
    },
    {
        title: TITLE_NAMESPACE_DOCUMENTATION,
        uuid: 'documentation',
    },
    {
        title: TITLE_NAMESPACE_VIDEO,
        uuid: 'video',
    },
    {
        title: TITLE_NAMESPACE_WEBINAR,
        uuid: 'webinar',
    },
    {
        title: TITLE_NAMESPACE_ARTICLE,
        uuid: 'article',
    },
    {
        title: TITLE_NAMESPACE_TASK,
        uuid: 'task',
    },
    {
        title: TITLE_NAMESPACE_PROJECT,
        uuid: 'project',
    },
    {
        title: TITLE_NAMESPACE_MASTER_CLASS,
        uuid: 'masterClass',
    },
    {
        title: TITLE_NAMESPACE_TRAINER,
        uuid: 'trainer',
    },
    {
        title: TITLE_NAMESPACE_COMMUNICATION,
        uuid: 'communication',
    },
    {
        title: TITLE_NAMESPACE_INTERVIEW,
        uuid: 'interview',
    },
    {
        title: TITLE_NAMESPACE_MENTORING,
        uuid: 'mentoring',
    },
    {
        title: TITLE_NAMESPACE_TRAINING,
        uuid: 'training',
    },
    {
        title: TITLE_NAMESPACE_EXERCISE,
        uuid: 'exercise',
    },
    {
        title: TITLE_NAMESPACE_SIMULATOR,
        uuid: 'simulator',
    },
    {
        title: TITLE_NAMESPACE_OTHER,
        uuid: 'other',
    },
]
