import axios, { AxiosStatic } from 'axios'
import { PdpEmployeeService } from 'api/services/PdpService/PdpEmployeeService'

interface IPdpService {
    pdpService: () => PdpEmployeeService;
}

export class PdpService implements IPdpService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public pdpService() {
        return new PdpEmployeeService(this.axiosInstance)
    }
}
