import React from 'react'
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material'
import { TITLE_NAMESPACE_STRONG_SIDES } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'

export const AssessmentResultStrongSide = () => {
    const theme = useTheme()
    const sides = useAppSelector(state => state.assessmentResultReducer.data.sides)

    if (!sides || sides?.strong?.length <= 0) return null

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h2">
                    {TITLE_NAMESPACE_STRONG_SIDES}
                </Typography>
            </Box>
            <Stack direction="row" flexWrap="wrap" gap={1}>
                {sides.strong.map(side =>
                    <Chip
                        key={`${side.title}-${side.score}`}
                        label={side.title}
                        sx={{
                            // @ts-ignore
                            backgroundColor: theme.palette.success[50],
                            color: theme.palette.success.dark,
                            height: 'auto',
                            padding: 0.5,
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            '& .MuiChip-label': {
                                whiteSpace: 'normal',
                                // whiteSpace: 'normal !important',
                            },
                        }}
                        size="small"
                    />)}
            </Stack>
        </Box>
    )
}
