import { SpecializationCompetenceService } from 'api/services/SpecializationService/specializationCompetenceService'
import axios, { AxiosStatic } from 'axios'
import { SpecializationGradeService } from 'api/services/SpecializationService/specializationGradeService'
import {
    SpecializationCompetenceMatrixService,
} from 'api/services/SpecializationService/specializationCompetenceMatrixService'
import { SpecializationListService } from 'api/services/SpecializationService/specializationListService'


interface ISpecializationService {
    competenceService: () => SpecializationCompetenceService;
    gradeService: () => SpecializationGradeService;
    competenceMatrixService: () => SpecializationCompetenceMatrixService;
    specializationListService: () => SpecializationListService;
}

export class SpecializationService implements ISpecializationService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public competenceService() {
        return SpecializationCompetenceService.getInstance(this.axiosInstance)
    }

    public gradeService() {
        return SpecializationGradeService.getInstance(this.axiosInstance)
    }

    public competenceMatrixService() {
        return SpecializationCompetenceMatrixService.getInstance(this.axiosInstance)
    }

    public specializationListService() {
        return SpecializationListService.getInstance(this.axiosInstance)
    }
}
