import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_PERCENTAGE_DEVELOPMENT,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'

export const DevelopmentPdpItemsHeader = () => {
    return (
        <Box mb={2}>
            <Box display="flex" justifyContent="space-between" pr={2} py={1} mb={1}>
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" mr={1} width={32} textAlign="center">
                        №
                    </Typography><Typography variant="body1" mr={1} width={112} textAlign="center">
                        {TITLE_NAMESPACE_PERCENTAGE_DEVELOPMENT}
                    </Typography>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_PLAN_ITEM}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                    <Box>
                        <Typography variant="body1" width={156} textAlign="center">
                            {TITLE_NAMESPACE_STUDY_TIME}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" width={156} textAlign="center">
                            {TITLE_NAMESPACE_COMMENT}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <Divider />
        </Box>
    )
}

