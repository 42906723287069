import axios, { AxiosStatic } from 'axios'
import { EmployeeFilterService } from 'api/services/FilterService/employeeFilterService'

interface IFilterService {
    employeeFilterService: () => EmployeeFilterService;
}

export class FilterService implements IFilterService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public employeeFilterService(): EmployeeFilterService {
        return EmployeeFilterService.getInstance(this.axiosInstance)
    }

}
