import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<IUser> = {
    data: {} as IUser,
    isLoading: false,
    error: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userFetching: (state) => {
            state.isLoading = true
        },
        userFetchingSuccess: (state, action: PayloadAction<IUser>) => {
            state.isLoading = false
            state.error = null
            state.data = action.payload
        },
        userFetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        clearState: () => initialState,

        setUser: (state, action: PayloadAction<IUser>) => {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },
    },
})

export const userReducer = userSlice.reducer
