import { useQuery } from 'react-query'
import {
    cpaEmployeeActions,
    cpaItemActions,
    cpaItemCriteriaActions,
    cpaItemEmployeesActions,
    cpaItemScaleActions,
    cpaListActions,
    myAssessmentActions,
} from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { CPAService } from 'api/services/CPAService'
import {
    ICpaGetEmployeeAssessmentPreviewRequest,
    ICpaGetEmployeeRequest,
    ICpaGetEmployeesForAddRequest,
} from 'api/typing/cpaTypes'
import { useState } from 'react'

const cpaListApi = new CPAService().listService()
const cpaItemApi = new CPAService().itemService()
const cpaDictionaryApi = new CPAService().dictionaryService()


const getCpaListFn = async () => await cpaListApi.getList()
const getCpaItemFn = async (uuid: string) => await cpaItemApi.getItem(uuid)
const getCpaItemCriteriaPointFn = async (uuid: string) => await cpaItemApi.getItemCriteriaPoint(uuid)
const getCpaItemCriteriaCompetenceFn = async (uuid: string) => await cpaItemApi.getItemCriteriaCompetence(uuid)
export const getCpaItemScaleNumericFn = async (uuid: string) => await cpaItemApi.getItemScale(uuid)
const getCpaDictionaryScaleNumericFn = async (uuid: string) => await cpaDictionaryApi.getScaleNumericItem(uuid)
const getCpaEmployeesListFn = async (data: ICpaGetEmployeesForAddRequest) => await cpaItemApi.getCpaEmployeesList(data)
const getCpaItemEmployeesFn = async (uuid: string) => await cpaItemApi.getItemEmployees(uuid)
const getCpaPreviewAssessmentFn = async (data: ICpaGetEmployeeAssessmentPreviewRequest) =>
    await cpaItemApi.getCpaPreviewAssessment(data)
const getCpaEmployeeFn = async (data: ICpaGetEmployeeRequest) => await cpaItemApi.getCpaEmployee(data)
const getCpaStatusStateFn = async (uuid: string) => await cpaItemApi.getCpaStatusState(uuid)


// cpa list
export const useCpaList = () => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'cpaList',
        queryFn: getCpaListFn,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaListActions.setList(data))
        },
        retry: 0,
    })
}

// cpa itemMeta
export const useCpaItem = (itemUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['cpaItem', itemUuid],
        queryFn: () => getCpaItemFn(itemUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(cpaItemActions.setItem(data))
        },
        retry: 0,
        enabled,
    })
}

// cpa criteria
export const useCpaCriteriaPoint = (itemUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['cpaPoint', itemUuid],
        queryFn: () => getCpaItemCriteriaPointFn(itemUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemCriteriaActions.setCriteriaPoint(data))
        },
        retry: 0,
        enabled,
    })
}

export const useCpaCriteriaCompetence = (itemUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['cpaCompetence', itemUuid],
        queryFn: () => getCpaItemCriteriaCompetenceFn(itemUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemCriteriaActions.setCriteriaCompetence(data))
        },
        retry: 0,
        enabled,
    })
}

// cpa scales
export const useCpaScaleNumeric = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useCpaScaleNumeric', cpaUuid],
        queryFn: () => getCpaItemScaleNumericFn(cpaUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemScaleActions.setScaleNumeric(data))
            dispatch(myAssessmentActions.setAssessmentPassAnswers(data.answers))
        },
        retry: 0,
        enabled,
    })
}

export const useCpaDictionaryScaleNumericWithState = (scaleUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useCpaScaleNumericWithState', scaleUuid],
        queryFn: () => getCpaDictionaryScaleNumericFn(scaleUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemScaleActions.setScaleNumeric(data))
        },
        retry: 0,
        enabled,
    })
}

export const useCpaDictionaryScaleNumeric = (scaleUuid: string, enabled = true) => {
    return useQuery({
        queryKey: ['useCpaDictionaryScaleNumeric', scaleUuid],
        queryFn: () => getCpaDictionaryScaleNumericFn(scaleUuid),
        refetchOnWindowFocus: false,
        retry: 0,
        enabled,
    })
}


// cpa employees
export const useCpaItemEmployees = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['сpaItemEmployees', cpaUuid],
        queryFn: () => getCpaItemEmployeesFn(cpaUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemEmployeesActions.setCurrentEmployees(data))
        },
        retry: 0,
        enabled,
    })
}

// cpa employees list
export const useCpaEmployeesList = (payload: ICpaGetEmployeesForAddRequest, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useCpaEmployeesList', payload.cpaUuid, payload.employeeUuid],
        queryFn: () => getCpaEmployeesListFn(payload),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemEmployeesActions.setEmployeesList(data))
        },
        retry: 0,
        enabled,
    })
}
// cpa Participan list
export const useCpaParticipantList = (payload: ICpaGetEmployeesForAddRequest, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useCpaParticipantList', payload.cpaUuid, payload.employeeUuid],
        queryFn: () => getCpaEmployeesListFn(payload),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaEmployeeActions.setParticipantList(data))
        },
        retry: 0,
        enabled,
    })
}
// cpa preview assessment
export const useCpaPreviewAssessment = (payload: ICpaGetEmployeeAssessmentPreviewRequest, enabled = true) => {
    // const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useCpaPreviewAssessment',
        queryFn: () => getCpaPreviewAssessmentFn(payload),
        refetchOnWindowFocus: false,
        // onSuccess: (data) => {
        //     dispatch(cpaItemEmployeesActions.setPreviewAssessment(data))
        // },
        retry: 0,
        enabled,
    })
}
// cpa preview assessment
export const useCpaEmployee = (payload: ICpaGetEmployeeRequest, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useCpaEmployeeFn',
        queryFn: () => getCpaEmployeeFn(payload),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaEmployeeActions.setEmployee(data))
            dispatch(cpaItemEmployeesActions.updateEmployeeStatusFromPage(data))
        },
        retry: 0,
        enabled,
    })
}

// cpa status state
export const useCpaStatusState = (cpaUuid: string, enabled = true) => {
    const [fulfilled, setFulfilled] = useState(false)
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useCpaStatusState', cpaUuid],
        queryFn: () => getCpaStatusStateFn(cpaUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setFulfilled(data.fulfilled)
            dispatch(cpaItemActions.setStatusState(data))
        },
        retry: 0,
        refetchInterval: 5000,
        enabled: enabled && !fulfilled,
    })
}

