import React, { FC, useState } from 'react'
import { IconButton, TextField } from '@mui/material'
import { ClearRounded, SearchRounded } from '@mui/icons-material'
import { useDebounce } from 'react-use'

type TitleFilterProps = {
    filterByTitle: (value: string) => void;
    filterPlaceholder: string;
}
export const TitleFilter: FC<TitleFilterProps> = ({ filterByTitle, filterPlaceholder }) => {
    const [search, setSearch] = useState('')

    useDebounce(
        () => {
            filterByTitle(search)
        },
        300,
        [search],
    )

    return (
        <TextField
            fullWidth
            placeholder={filterPlaceholder}
            InputProps={{
                endAdornment: search.length > 0 ?
                    <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                    >
                        <ClearRounded color="disabled"/>
                    </IconButton>
                    :
                    <SearchRounded color="disabled"/>,
            }}
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
        />
    )
}
