import React, { FC, useMemo } from 'react'
import { ExamQuestionType } from '../../../api/typing/massExam'
import { ExamOneFromListAnswer } from '../ExamOneFromListAnswer/ExamOneFromListAnswer'
import { getQuestionTypeCode } from '../../../helpers/massExam/massExamConstructor'
import { IExamQuestion } from '../../../api/typing/exam'
import { ExamManyFromListAnswer } from '../ExamManyFromListAnswer/ExamManyFromListAnswer'
import { useTheme } from '@mui/material'

interface ExamQuestionCardAnswersProps {
    question:  IExamQuestion;
    openImageDialog: (value: string) => void;

}

export const ExamQuestionCardAnswers: FC<ExamQuestionCardAnswersProps> = ({ question, openImageDialog }) => {
    const theme = useTheme()

    const questionTypeCode = useMemo(() => getQuestionTypeCode(question.typeUuid), [question.typeUuid])
    const answers = useMemo(() => question.answers[questionTypeCode] || [], [questionTypeCode])
    const selectedAnswers = useMemo(() =>
        answers.filter(answer => answer.isAnswered).map(answer => answer.uuid),
    [answers],
    )
    switch (questionTypeCode) {
        case ExamQuestionType.ONE_FROM_LIST:
            return (
                <ExamOneFromListAnswer
                    answers={answers}
                    selectedAnswers={selectedAnswers}
                    openImageDialog={openImageDialog}
                    showCorrectAnswer
                    disabled
                    formControlLabelStyles={{
                        '& .MuiTypography-root': {
                            color: `${theme.palette.text.primary} !important`,
                        },
                    }}
                />
            )
        case ExamQuestionType.MANY_FROM_LIST:
            return (
                <ExamManyFromListAnswer
                    answers={answers}
                    selectedAnswers={selectedAnswers}
                    openImageDialog={openImageDialog}
                    showCorrectAnswer
                    disabled
                    formControlLabelStyles={{
                        '& .MuiTypography-root': {
                            color: `${theme.palette.text.primary} !important`,
                        },
                    }}
                />
            )
        default:
            return null
    }
}
