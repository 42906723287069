import React, { FC, useMemo } from 'react'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from '../../../dialogs/DialogTitle/DialogTitle'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { useExamItem } from '../../../../api/queries/exam/examQueries'
import { Loading } from '../../../Loading/Loading'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import {
    TITLE_BUTTON_COMPLETE, TITLE_NAMESPACE_DURATION,
    TITLE_NAMESPACE_INITIATOR,
    TITLE_NAMESPACE_WAS_CREATED_NEUTER,
    TITLE_NAMESPACE_WAS_LAUNCHED_NEUTER,
} from '../../../../helpers/enums/titles'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SaveIcon from '@mui/icons-material/Save'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Button from '@mui/material/Button'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { examStatusAwaitSelf } from '../../../../helpers/exam/examStatuses'
import { useNavigate } from 'react-router-dom'
import { PATH_EXAM } from '../../../../helpers/enums/routePath'
import { WarningAmberRounded } from '@mui/icons-material'

interface ExamStartDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    examUuid: string;
    statusUuid: string;
    timeSpent: number;
    isExpire: boolean;
}

export const ExamStartDialog: FC<ExamStartDialogProps> = ({
    open,
    setOpen,
    examUuid,
    timeSpent,
    statusUuid,
    isExpire,
}) => {
    const navigate = useNavigate()

    const { isFetching, error, data: exam } = useExamItem(examUuid)
    const isAwaitSelfExam = useMemo(() => statusUuid === examStatusAwaitSelf, [statusUuid])
    const examStartBtnTitle = useMemo(
        () => isAwaitSelfExam ? 'Начать тест' : 'Продолжить тест',
        [statusUuid],
    )

    const goToExamPassing = () => {
        navigate(`${PATH_EXAM}/${examUuid}`)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
            scroll='body'
        >
            {isFetching || error || !exam ?
                <Loading/>
                :
                <>
                    <DialogTitle onClose={() => setOpen(false)}>
                        <Typography
                            variant='body2'
                            lineHeight='42px'
                            fontSize='36px'
                            fontWeight='300'
                            mb={1}
                            sx={{ wordBreak: 'break-word'}}
                        >
                            {exam.title}
                        </Typography>
                        <Box display='flex' alignItems='center' gap={1}>
                            <Typography variant='body1'>
                                {TITLE_NAMESPACE_WAS_CREATED_NEUTER}: {exam.createdAt}
                            </Typography>
                            <Typography variant='body1'>|</Typography>
                            <Typography variant='body1'>
                                {TITLE_NAMESPACE_WAS_LAUNCHED_NEUTER}: {exam.startedAt}
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={3}>
                            <Stack spacing={2}>
                                <Box display='flex' alignItems='center' gap={1}>
                                    <Box display='flex' alignItems='center' gap={1}>
                                        <DateRangeIcon color='warning'/>
                                        <Typography>{TITLE_BUTTON_COMPLETE} к {exam.endedAt}</Typography>
                                    </Box>
                                    {isExpire &&
                                        <Chip
                                            variant='outlined'
                                            color='warning'
                                            label='Срок пропущен'
                                            icon={<WarningAmberRounded color="warning"/>}
                                        />
                                    }
                                </Box>
                                <Box display='flex' gap={1} alignItems='center'>
                                    <AssignmentIcon color='warning'/>
                                    <Typography variant="body1">
                                        {`${TITLE_NAMESPACE_INITIATOR} - ${exam?.author?.lastName} ${exam?.author?.firstName?.charAt(0)
                                            .toUpperCase()}.`}
                                    </Typography>
                                </Box>
                            </Stack>
                            {!!exam.description?.length &&
                                <>
                                    <Divider/>
                                    <Typography variant='body1'>
                                        { exam.description }
                                    </Typography>
                                </>
                            }
                            <Box display='flex' gap={1}>
                                <Chip
                                    label={
                                        <Typography variant='body1'>
                                            {TITLE_NAMESPACE_DURATION} {timeSpent} мин
                                        </Typography>
                                    }
                                    icon={<AccessTimeIcon/>}
                                    color='default'
                                />
                                <Chip
                                    label={<Typography variant='body1'>Ответы сохраняются автоматически</Typography>}
                                    icon={<SaveIcon/>}
                                    color='default'
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button
                            variant='contained'
                            endIcon={<ArrowForwardIosIcon/>}
                            onClick={goToExamPassing}
                        >
                            {examStartBtnTitle}
                        </Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}
