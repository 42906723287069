import { AppDispatch } from 'store/store'
import { SpecializationService } from 'api/services/SpecializationService'
import { specializationSetupSlice } from 'store/reducers/SpecializationSetupSlice'

export type ChangeActiveSpecialization = {
    uuid: string;
    groupUuid: string;
    value: boolean;
}

export type ChangeActiveSpecializationGroup = Omit<ChangeActiveSpecialization, 'groupUuid'>

export class SpecializationSetupAction {
    private static get competenceMatrixApi() {
        return new SpecializationService().competenceMatrixService()
    }

    public getSetupSpecializationCompetence(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSetupSlice.actions.fetching())
                const data = await SpecializationSetupAction
                    .competenceMatrixApi
                    .getSetupSpecializationCompetence(uuid)
                dispatch(specializationSetupSlice.actions.getSetupSpecializationCompetence(data))
            } catch (e: any) {
                dispatch(specializationSetupSlice.actions.fetchingError(e))
            }
        }
    }

    public changeActiveSpecialization(uuid: string, groupUuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSetupSlice.actions.fetching())
                dispatch(specializationSetupSlice.actions.changeActiveSpecialization({ uuid, groupUuid, value }))
            } catch (e: any) {
                dispatch(specializationSetupSlice.actions.fetchingError(e))
            }
        }
    }

    public changeActiveSpecializationGroup(uuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSetupSlice.actions.fetching())
                dispatch(specializationSetupSlice.actions.changeActiveSpecializationGroup({ uuid, value }))
            } catch (e: any) {
                dispatch(specializationSetupSlice.actions.fetchingError(e))
            }
        }
    }

    public restoreSetupState() {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSetupSlice.actions.fetching())
                dispatch(specializationSetupSlice.actions.restoreSetupState())
            } catch (e: any) {
                dispatch(specializationSetupSlice.actions.fetchingError(e))
            }
        }
    }

    public saveChanges(uuid: string, data: ISpecializationSetupSaveData) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSetupSlice.actions.fetching())
                void await SpecializationSetupAction.competenceMatrixApi.setSetupSpecializationCompetence(uuid, data)
                dispatch(specializationSetupSlice.actions.saveChanges())
            } catch (e: any) {
                dispatch(specializationSetupSlice.actions.fetchingError(e))
            }
        }
    }
}
