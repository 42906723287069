import { TransferForm } from 'api/services/FilterService/transferForm'
import { AxiosStatic } from 'axios'

export class EmployeeFilterService extends TransferForm {
    private static instance: EmployeeFilterService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!EmployeeFilterService.instance)
            EmployeeFilterService.instance = new EmployeeFilterService(axiosInstance)
        return EmployeeFilterService.instance
    }

    public getHistoryEventTypes() {
        const path = '/employee/history/event-type/all'
        return this.request<null, IEntity[]>('GET', path)
    }
}
