import React from 'react'
import { ExamList } from '../ExamList'


export const ExamListPageContent = () => {

    return (
        <ExamList />
    )
}
