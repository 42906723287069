import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { permissionDenied, services } from 'helpers/permissions'
import { EmployeesPage } from 'pages/employees/EmployeesPage/EmployeesPage'
import { SpecializationList } from 'pages/specialization/SpecializationList/SpecializationList'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { HistoryPage } from 'pages/history/HistoryPage/HistoryPage'
import { DevelopmentPage } from 'pages/development/DevelopmentPage/DevelopmentPage'
import { ProfilePage } from 'pages/profile/ProfilePage/ProfilePage'

export const HomePage = () => {
    const { permissions } = useAppSelector(state => state.userReducer.data)
    const allowedServices: string[] = []
    Object.keys(permissions || {}).forEach(serviceUuid => {
        if (permissions[serviceUuid] !== permissionDenied
            && serviceUuid !== services.competence_base.uuid
            && serviceUuid !== services.dictionary.uuid
        )
            allowedServices.push(serviceUuid)
    })
    let displayedService = {} as IEntity & { priority: number }
    allowedServices.forEach(serviceUuid => {
        const service = Object.values(services).find(service => service.uuid === serviceUuid)

        if (service && (Object.values(displayedService).length <= 0 || displayedService.priority > service?.priority))
            displayedService = service
    })

    switch (displayedService.uuid) {
        case services.employee.uuid: {
            return <EmployeesPage />
        }
        case services.specializations.uuid: {
            return <SpecializationList />
        }
        case services.competence_base.uuid: {
            // TODO: add the normal page after merge control and public
            return <ErrorPage errorCode={404} />
        }
        case services.dictionary.uuid: {
            // TODO: add the normal page after merge control and public
            return <ErrorPage errorCode={404} />
        }

        case services.profile.uuid: {
            return <ProfilePage />
        }

        case services.my_development.uuid: {
            return <DevelopmentPage />
        }

        case services.history.uuid: {
            return <HistoryPage />
        }

        default: {
            return <ErrorPage errorCode={404} />
        }
    }
}

