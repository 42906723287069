import React, { FC, useEffect, useMemo, useState } from 'react'
import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_ASSESSMENT_COMMENT_GLOBAL,
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_SHARED_COMMENT,
} from 'helpers/enums/titles'
import { InputForm } from 'components/form/InputForm/InputForm'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { useParams } from 'react-router-dom'
import { convertLocalStringToUtcString } from 'helpers/dateAndTime/convertLocalStringToUtcString'
import { LoadingButton } from '@mui/lab'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'

export const MyAssessmentPassComment: FC = () => {
    const { assessmentUuid } = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { uuid, firstName, lastName } = useAppSelector(state => state.userReducer.data)
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const initialComment = useMemo(
        () => assessment?.comments.find(el => el.authorUuid === uuid),
        [assessment?.comments],
    )

    const [comment, setComment] = useState(() => initialComment?.text || '')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setComment(initialComment?.text ?? '')
    }, [initialComment])

    const disableForm = !assessmentSetup?.canAddComment

    const changeComment = async () => {
        if (!assessmentUuid || disableForm) return
        const hasOldComment = !!initialComment?.text.length
        const hasNewComment = !!comment.length
        try {
            setLoading(true)
            switch (true) {
                // delete
                case !hasNewComment && hasOldComment: {
                    await deleteComment()
                    break
                }
                // update
                case hasNewComment && hasOldComment: {
                    if (!initialComment?.uuid) return
                    await dispatch(myAssessmentActions.updateAssessmentComment({
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: initialComment.createdAt,
                        updatedAt: convertLocalStringToUtcString(),
                        assessmentUuid,
                        uuid: initialComment?.uuid,
                    }))
                    notify('Комментарий сохранён успешно', 'success')
                    setOpen(false)
                    break
                }
                // create
                case hasNewComment && !hasOldComment: {
                    await dispatch(myAssessmentActions.createAssessmentComment({
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: convertLocalStringToUtcString(),
                        updatedAt: convertLocalStringToUtcString(),
                        assessmentUuid,
                        uuid: initialComment?.uuid,
                    }))
                    notify('Комментарий сохранён успешно', 'success')
                    setOpen(false)
                    break
                }
                default: {
                    break
                }
            }
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    const deleteComment = async () => {
        if (!initialComment?.uuid || !assessmentUuid) return
        try {
            await dispatch(myAssessmentActions.deleteAssessmentComment({
                assessmentUuid,
                uuid: initialComment?.uuid,
            }))
            notify('Комментарий удалён успешно', 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const restoreDefault = () => {
        setOpen(false)
        setComment(assessment?.comments.find(el => el.authorUuid === uuid)?.text || '')
    }

    return (
        <>
            <Stack spacing={3}>
                {initialComment && !open
                    ? <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            borderRadius: 4,
                            background: (theme) => theme.palette.background.default,
                        }}
                    >
                        {mobile
                            ? <Stack spacing={2}>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <CommentOutlined color="action"/>
                                    <Typography variant="body1" sx={{ flex: 1 }}>
                                        {TITLE_NAMESPACE_COMMENT}
                                    </Typography>
                                    <SecondaryActions
                                        onDelete={deleteComment}
                                        onEdit={() => setOpen(true)}
                                    />
                                </Stack>
                                <Typography variant="body2">
                                    {comment}
                                </Typography>
                            </Stack>
                            : <Stack direction="row" spacing={2} alignItems="flex-start">
                                <CommentOutlined color="action"/>
                                <Box flex={1} display="flex" justifyContent="space-between">
                                    <Stack spacing={0.5} flex={1}>
                                        <Typography variant="body1">
                                            {TITLE_NAMESPACE_COMMENT}
                                        </Typography>
                                        <Typography variant="body2">
                                            {comment}
                                        </Typography>
                                    </Stack>
                                    <Box>
                                        <SecondaryActions
                                            onDelete={deleteComment}
                                            onEdit={() => setOpen(true)}
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                        }
                    </Paper>
                    : !open
                        ? <Button
                            startIcon={initialComment ? <CommentOutlined /> : <AddCommentOutlined />}
                            variant="text"
                            disabled={disableForm}
                            sx={{ alignSelf: 'flex-start' }}
                            onClick={() => setOpen(prev => !prev)}
                        >
                            {TITLE_NAMESPACE_SHARED_COMMENT}
                        </Button>
                        : null}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Stack spacing={2}>
                        <InputForm
                            id={'comment-assessment'}
                            title={TITLE_FIELD_ASSESSMENT_COMMENT_GLOBAL}
                            value={comment}
                            disabled={disableForm}
                            multiline
                            onChange={(e) => setComment(e.target.value as string)}
                        />
                        {!disableForm && <Stack
                            direction={{ 'mobile': 'column', 'laptop': 'row' }}
                            justifyContent="flex-end"
                            spacing={mobile ? 1 : 2}
                        >
                            <Button
                                variant="text"
                                onClick={restoreDefault}
                            >
                                {TITLE_BUTTON_CANCEL}
                            </Button>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                disabled={(initialComment?.text ?? '') === comment}
                                startIcon={<CommentOutlined />}
                                onClick={changeComment}
                            >
                                {TITLE_BUTTON_SAVE}
                            </LoadingButton>
                        </Stack>}
                    </Stack>
                </Collapse>
            </Stack>
        </>
    )
}
