import React, { FC } from 'react'
import { CloseRounded } from '@mui/icons-material'
import { Box, Chip, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import {
    CPAAnalyticContentEmployeesDrawerItemEmployee,
} from './components/CPAAnalyticContentEmployeesDrawerItemEmployee/CPAAnalyticContentEmployeesDrawerItemEmployee'

type CPAAnalyticContentEmployeesDrawerProps = {
    open: boolean;
    handleClose: () => void;
    title: string;
    severity: 'warning' | 'success';
    alertText: string;
    alertIcon: React.ReactNode;
    employees?: TAnalyticCpaStatisticSummaryEmployee[];
}

export const CPAAnalyticContentEmployeesDrawer: FC<CPAAnalyticContentEmployeesDrawerProps> = ({
    severity,
    alertIcon,
    alertText,
    handleClose,
    open,
    title,
    employees,
}) => {

    if (!employees) return null

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { width: { xs: '100%', md: 'calc(100% - 320px)' } },
            }}
            ModalProps={{ keepMounted: true }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Stack spacing={5} px={5} pt={5} mb={4}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h1">
                                {title}
                            </Typography>
                            <Chip
                                variant="outlined"
                                color={severity}
                                size="small"
                                label={employees?.length}
                            />
                        </Stack>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Box>
                    <GFAlertInfo
                        severity={severity}
                        text={alertText}
                        icon={alertIcon}
                    />
                </Stack>
                <Stack spacing={2} flex={1} overflow="auto" px={5} pb={5} >
                    <Divider />
                    <Stack spacing={2} divider={<Divider />}>
                        {employees.map(employee => <CPAAnalyticContentEmployeesDrawerItemEmployee
                            key={employee.uuid}
                            employee={employee}
                        />)}
                    </Stack>
                    <Divider />
                </Stack>
            </Box>
        </Drawer>
    )
}
