import React from 'react'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { useAppSelector } from 'store/hooks/redux'

export const AssessmentPassAlerts = () => {
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    // const notCalculated = useMemo(() => {
    //     if (!assessment?.competencies.length) return false
    //     return assessment.competencies.some(competence =>
    //         competence.indicators.every(indicator =>
    //             indicator.finalAnswerUuid === 'cb56d054-d771-40a0-b09b-032acdc3f586'))
    // }, [assessment])

    const alertTitle = assessmentSetup?.allUncompleted
        ? 'Недостаточно данных для объективной оценки. Все компетенции с неопределенным результатом.'
        : 'Недостаточно данных для объективной оценки. Большинство компетенций с неопределенным результатом.'

    if (!assessmentSetup?.allUncompleted || !assessmentSetup.gtHalfUncompleted) return null

    return (
        <GFAlertInfo
            text={alertTitle}
            severity="error"
        />
    )
}
