import {
    differenceInCalendarDays,
    differenceInDays,
    differenceInMonths, differenceInYears,
    formatDuration,
    isValid, parse, subMonths, subYears,
} from 'date-fns'
import { ru } from 'date-fns/locale'

export function formatTimeToStringDuration(
    dateStart: number | Date | null,
    dateEnd: number | Date | null = new Date(),
    error?: boolean,
) {
    if (dateStart === null || typeof dateStart === undefined || !isValid(dateStart)) return null

    const timeToParseStart = typeof dateStart === 'number' ? new Date(dateStart) : dateStart
    const timeToParseEnd = !isValid(dateEnd) || dateEnd === null || typeof dateEnd === undefined
        ? new Date()
        : new Date(dateEnd)

    const yearsCount = differenceInYears(timeToParseEnd, timeToParseStart)

    const monthCount = differenceInMonths(timeToParseEnd, timeToParseStart) - yearsCount * 12

    const timeEndWithYearsAndMonth =
        subMonths(subYears(timeToParseEnd ? timeToParseEnd : new Date(), yearsCount), monthCount)
    const daysCount = differenceInDays(timeEndWithYearsAndMonth, timeToParseStart)

    if ((yearsCount + monthCount + daysCount) < 0 || error) {
        return formatDuration({
            years: 0,
            months: 0,
        }, { locale: ru, zero: true, format: ['months', 'years'] })
    }

    return formatDuration({
        years: yearsCount > 0 ? yearsCount : undefined,
        months: monthCount > 0 ? monthCount : undefined,
        days: yearsCount < 1 ? daysCount : undefined,
    }, { locale: ru, zero: true })
}

export function getDaysDifference(dateStart: Date, dateEnd: Date): Date
export function getDaysDifference(dateStart: string, dateEnd: string): string
export function getDaysDifference(dateStart?: any, dateEnd?: any): any {
    const datesIsTypeDate = typeof dateStart === typeof Date && typeof dateEnd === typeof Date
    let dateLeft = dateStart
    let dateRight = dateEnd

    if (!datesIsTypeDate) {
        dateRight = parse(dateStart, 'dd.MM.yyyy', new Date())
        dateLeft = parse(dateEnd, 'dd.MM.yyyy', new Date())
    }

    const days = differenceInCalendarDays(dateLeft, dateRight)
    return formatDuration({
        days,
    }, { locale: ru, zero: true })
}
