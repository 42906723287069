import React, { FC, ReactNode } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertColor, AlertTitle, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useMobile } from 'helpers/hooks/useMobile'

type GFAccordionAlertInfoProps = {
    children: ReactNode[];
    severity: AlertColor;
}
export const GFAccordionAlertInfo: FC<GFAccordionAlertInfoProps> = (props) => {
    const theme = useTheme()
    const mobile = useMobile()
    let colorIcon = ''
    let colorText = ''
    switch (true) {
        case props.severity === 'warning': {
            colorText = theme.palette.warning.dark
            colorIcon = theme.palette.warning.dark
            break
        }
        case props.severity === 'info': {
            colorText = theme.palette.primary.dark
            colorIcon = theme.palette.primary.main
            break
        }
        case props.severity === 'error': {
            colorText = theme.palette.error.dark
            colorIcon = theme.palette.error.main
            break
        }
        case props.severity === 'success': {
            colorText = theme.palette.success.dark
            colorIcon = theme.palette.success.main
            break
        }
        default: {
            colorText = theme.palette.primary.dark
            colorIcon = theme.palette.primary.main
            break
        }
    }

    return (
        <Alert
            severity={props.severity}
            sx={{
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                mb: 3,
                '& .MuiAlert-icon': {
                    color: colorIcon,
                    mr: 0,
                    mt: 1,
                },
                '& .MuiAlert-action': {
                    p: 0,
                    flex: mobile ? 1 : 'inherit',
                    my: 'auto',
                    alignSelf: 'flex-end',
                },
                '& .MuiAlert-message': {
                    flex: 1,
                    minWidth: 200,
                },
                '& .MuiTypography-root': {
                    color: colorText,
                    m: 0,
                },
            }}
        >
            <Accordion
                disableGutters
                sx={{
                    background: 'none',
                    boxShadow: 'none',
                    '& .MuiAccordionSummary-content': {
                        m: 0,
                    },
                }}
            >
                <AlertTitle>
                    <AccordionSummary
                        expandIcon={ <KeyboardArrowDownIcon sx={{ color: colorIcon }} /> }
                        sx={{ color: colorText, m: 0, minHeight: '40px' }}
                    >
                        {props.children[0]}
                    </AccordionSummary>
                </AlertTitle>

                <AccordionDetails sx={{ color: colorText }}>
                    {props.children[1]}
                </AccordionDetails>
            </Accordion>
        </Alert>
    )
}
