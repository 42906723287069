export const getYearsValuesArray = (min = 100, max = 0) => {
    const currentYear = new Date().getFullYear()
    const values: IEntity[] = []
    for (let i = currentYear + max; i >= currentYear - min; i--) {
        values.push({
            title: String(i),
            uuid: String(i),
        })
    }
    return values
}
