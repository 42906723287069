import React, { FC } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardContent } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN } from 'helpers/enums/titles'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { employeeHistoryPdpActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import {
    HistoryPdpItemViewUnique,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/ HistoryPdpItemViewPage/components/HistoryPdpItemUnique/HistoryPdpItemUnique'
import { useAppSelector } from 'store/hooks/redux'
import {
    HistoryPdpItemViewCompetence,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/ HistoryPdpItemViewPage/components/HistoryPdpItemCompetence/HistoryPdpItemCompetence'
import { PATH_ERROR } from 'helpers/enums/routePath'

type HistoryPdpItemViewPageProps = {
    employeeUuid: string;
    parentPath: string
}

export const HistoryPdpItemViewPage: FC<HistoryPdpItemViewPageProps> = ({ employeeUuid, parentPath }) => {
    const navigate = useNavigate()
    const { pdpUuid, itemUuid, entityType } = useParams()
    const isUniqueItem = entityType === 'unique'
    const { item } = useAppSelector(state => state.employeeHistoryPdpReducer.data)

    const { isFetching, error } = useLayoutFetch(employeeHistoryPdpActions.getEmployeeHistoryPdpItem(
        employeeUuid,
        pdpUuid || '',
        itemUuid || '',
    ),
    {
        permissions: { service: 'history', accessLevel: 'r' },
    })

    if (isFetching) return <LoadingComponent />
    if (!item) return null
    if (error) return <Navigate to={PATH_ERROR} replace state={error} />

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Button
                    sx={{ mb: 3 }}
                    startIcon={<ArrowBackRoundedIcon />}
                    onClick={() => navigate(parentPath, { replace: true })}
                >
                    {TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN}
                </Button>
                {isUniqueItem
                    ? <HistoryPdpItemViewUnique item={item} />
                    : <HistoryPdpItemViewCompetence item={item} />}
            </CardContent>
        </Card>
    )
}

