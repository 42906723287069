import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { formActions, oaAssessmentActions, openAccessActions } from 'store/actions'
import { useParams } from 'react-router-dom'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Box, Stack, Typography } from '@mui/material'
import {
    OAAssessmentCompetence,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentCompetence/OAAssessmentCompetence'
import {
    OAAssessmentProgress,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentProgress/OAAssessmentProgress'
import {
    OAAssessmentHeader,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentHeader/OAAssessmentHeader'
import { TITLE_NAMESPACE_ASSESSMENT } from 'helpers/enums/titles'
import {
    OAAssessmentActions,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentActions/OAAssessmentActions'
import {
    OAAssessmentResult,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/OAAssessmentResult'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import {
    OAAssessmentPassedDialog,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentPassedDialog/OAAssessmentPassedDialog'
import { useLocalStorage } from 'react-use'

export const OAAssessment = () => {
    const { assessmentUuid, hash } = useParams()
    const dispatch = useAppDispatch()
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const completeStatus = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'

    const {
        error,
        isFetching,
    } = useLayoutFetch(oaAssessmentActions.getAssessment(assessmentUuid || '', hash || ''), {
        deps: [hash, assessmentUuid],
    })

    const {
        error: formError,
        isFetching: formIsFetching,
    } = useLayoutFetch(formActions.getOAAssessmentAnswers(), {
        deps: [hash, assessmentUuid],
    })

    const [openViewPassedModal, setOpenViewPassedModal] = useLocalStorage('viewPassedModal', true)

    // Временно закомментировали кнопку Купить
    // const resultPageScrollMetricSent = useRef<boolean>(false)
    // const sendResultPageScrollMetric = () => {
    //     if (resultPageScrollMetricSent.current) return
    //     SendMetric.getInstance().sendMetricScroll('scrollResultDown')
    //     resultPageScrollMetricSent.current = true
    // }
    // useEffect(() => {
    //     if (!resultPageScrollMetricSent) return
    //     document.removeEventListener('scroll', sendResultPageScrollMetric)
    // }, [resultPageScrollMetricSent])

    useEffect(() => {
        dispatch(openAccessActions.setTitle(TITLE_NAMESPACE_ASSESSMENT))
        // return () => document.removeEventListener('scroll', sendResultPageScrollMetric)
    }, [])

    useEffect(() => {
        setOpenViewPassedModal(!completeStatus)
        // if (!completeStatus) return
        // document.addEventListener('scroll', sendResultPageScrollMetric)
    }, [completeStatus])

    if (isFetching || formIsFetching) return <LoadingComponent/>
    if (error || formError) return <ErrorPage error={error || formError}/>
    if (!Object.values(assessment).length) return <ErrorPage errorCode={403}/>

    return (
        <Stack spacing={3}>
            <OAAssessmentHeader/>
            <OAAssessmentResult/>
            <Box>
                {completeStatus && <Box my={3}>
                    <Typography variant="h2">
                        Ваши ответы
                    </Typography>
                </Box>}
                <Stack spacing={8}>
                    {assessment?.competencies?.map(competence => <OAAssessmentCompetence
                        key={competence.uuid}
                        competence={competence}
                    />)}
                </Stack>
            </Box>
            <OAAssessmentProgress/>
            <OAAssessmentActions
                setOpenViewPassedModal={setOpenViewPassedModal}
            />
            <OAAssessmentPassedDialog
                openViewPassedModal={openViewPassedModal}
                setOpenViewPassedModal={setOpenViewPassedModal}
            />
        </Stack>
    )
}
