import React from 'react'
import { Card, CardContent, Divider, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useCpaItem } from 'api/queries/cpa/cpaQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useAppSelector } from 'store/hooks/redux'
import { CPAScaleHeader } from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/CPAScaleHeader'
import { CPAScaleContent } from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleContent/CPAScaleContent'

export const CPAScale = () => {
    const { cpaUuid } = useParams()
    const { isLoading } = useCpaItem(cpaUuid || '')
    const itemMeta = useAppSelector(state => state.CPAItemReducer.meta)

    if (isLoading) return <LoadingComponent/>

    return (
        <Card>
            <CardContent>
                <Stack spacing={3} divider={<Divider/>}>
                    <CPAScaleHeader scaleUuid={itemMeta?.scale?.uuid}/>
                    <CPAScaleContent scaleUuid={itemMeta?.scale?.uuid}/>
                </Stack>
            </CardContent>
        </Card>
    )
}
