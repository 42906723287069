import React, { FC } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { DialogOAAssessmentPassed } from 'components/dialogs/DialogOAAssessmenPassed/DialogOAAssessmentPassed'

interface OAAssessmentPassedDialogProps {
    openViewPassedModal: boolean | undefined;
    setOpenViewPassedModal: (val: boolean) => void;
}

// если потребуется сделать кнопку, для повторного открытия моделаки, как с инструкцией - сюда нужно будет добавить компонент кнопки
export const OAAssessmentPassedDialog: FC<OAAssessmentPassedDialogProps> = ({
    openViewPassedModal,
    setOpenViewPassedModal,
}) => {
    // const { freeResultsDialog, toggleDialog } = useDialogsState({ freeResultsDialog: false })
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const completeStatus = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'

    if (!completeStatus) return null

    return (
        <DialogOAAssessmentPassed open={!!openViewPassedModal} handleClose={() => setOpenViewPassedModal(false)}/>
        // Временно закомментировали кнопку Купить
        // <>
        //     <OaAssessmentBuyResultsDialog
        //         open={!!openViewPassedModal}
        //         buyResults={() => {
        //             SendMetric.getInstance().sendMetricClick('clickbottonBuyResultb2c')
        //             setOpenViewPassedModal(false)
        //             toggleDialog('freeResultsDialog', true)
        //         }}
        //     />
        //     <OaAssessmentFreeResultsDialog
        //         open={freeResultsDialog}
        //         handleClose={() => toggleDialog('freeResultsDialog', false)}
        //     />
        // </>
    )
}
