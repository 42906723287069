import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import logger from 'redux-logger'
import { userReducer } from 'store/reducers/UserSlice'
import { authReducer } from 'store/reducers/AuthSlice'
import { companyReducer } from 'store/reducers/CompanySlice'
import { notifierReducer } from 'store/reducers/NotifierSlice'
import { specializationReducer } from 'store/reducers/SpecializationSlice'
import { employeeReducer } from 'store/reducers/EmployeeSlice'
import { gradeReducer } from 'store/reducers/grade/GradeSlice'
import { specializationSetupReducer } from 'store/reducers/SpecializationSetupSlice'
import { specializationListReducer } from 'store/reducers/SpecializationListSlice'
import { competenceReducer } from 'store/reducers/CompetenceSlice'
import {
    employeeEditPersonalDataReducer,
} from 'store/reducers/employee/edit/tabs/editPersonalData/EmployeeEditPersonalDataSlice'
import {
    employeeEditWorkInCompanyWorkExperienceReducer,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanySlice'
import {
    employeeEditWorkInCompanyCurrentPositionReducer,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyCurrentPositionSlice'
import {
    employeeEditWorkInCompanyPreviousPositionReducer,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyPreviousPositionSlice'
import {
    employeeEditWorkInCompanyProjectAndRolesReducer,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyProjectsAndRolesSlice'
import {
    employeeEditWorkExperiencePreviousJobReducer,
} from 'store/reducers/employee/edit/tabs/workExperience/EmployeeEditWorkExperiencePreviousJobSlice'
import {
    employeeEditWorkExperienceProjectsAndRolesReducer,
} from 'store/reducers/employee/edit/tabs/workExperience/EmployeeEditWorkProjectsAndRolesSlice'
import {
    employeeEditEducationEducationReducer,
} from 'store/reducers/employee/edit/tabs/education/EmployeeEditEducationEducationSlice'
import {
    employeeEditEducationCoursesReducer,
} from 'store/reducers/employee/edit/tabs/education/EmployeeEditEducationCoursesSlice'
import { employeeViewHistoryReducer } from 'store/reducers/employee/view/tabs/history/EmployeeViewHistorySlice'
import {
    employeeViewCompetenciesReducer,
} from 'store/reducers/employee/view/tabs/competencies/EmployeeViewCompetenciesSlice'
import { gradeDevelopmentPlanReducer } from 'store/reducers/grade/GradeDevelopmentPlanSlice'
import { openAccessDevelopmentPlanReducer } from 'store/reducers/openAccess/OpenAccessDevelopmentPlanSlice'
import { pdpItemReducer } from 'store/reducers/employee/view/tabs/pdp/PdpItemSlice'
import { pdpReducer } from 'store/reducers/employee/view/tabs/pdp/PdpSlice'
import { developmentPdpReducer } from 'store/reducers/development/pdp/DevelopmentPdpSlice'
import { developmentPdpItemReducer } from 'store/reducers/development/pdp/DevelopmentPdpItemSlice'
import { employeeHistoryPdpReducer } from 'store/reducers/employee/view/tabs/history/EmployeeHistoryPdpSlice'
import { assessmentReducer } from 'store/reducers/assessment/AssessmentSlice'
import { formReducer } from 'store/reducers/forms/FormSlice'
import { analyticReducer } from 'store/reducers/analytic/AnalyticSlice'
import { openAccessReducer } from 'store/reducers/openAccess/OpenAccessSlice'
import { oaAssessmentReducer } from 'store/reducers/openAccess/assessment/oaAssessmentSlice'
import { gradeAssessmentReducer } from 'store/reducers/grade/GradeAssessmentSlice'
import { oaAssessmentAnswersReducer } from 'store/reducers/openAccess/assessment/oaAssessmentAnswers'
import { CPAListReducer } from 'store/reducers/cpa/list/CPAListSlice'
import { CPAItemReducer } from 'store/reducers/cpa/item/CPAItemSlice'
import { CPAItemCriteriaReducer } from 'store/reducers/cpa/item/CPAItemCriteriaSlice'
import { CPAItemScaleReducer } from 'store/reducers/cpa/item/CPAItemScaleSlice'
import { CPAItemEmployeesReducer } from 'store/reducers/cpa/item/CPAItemEmployeesSlice'
import { CPAEmployeeReducer } from 'store/reducers/cpa/item/CPAEmployeeSlice'
import { myAssessmentListReducer } from 'store/reducers/assessment/MyAssessmentListSlice'
import { MyAssessmentPassReducer } from 'store/reducers/assessment/MyAssessmentPassSlice'
import { assessmentResultReducer } from 'store/reducers/assessment/assessmentResultSlice'
import { menuReducer } from 'store/reducers/app/menu/MenuSlice'
import { CPAItemAnalyticsReducer } from 'store/reducers/cpa/item/CPAItemAnalyticsSlice'
import { massExamListSliceReducer } from './reducers/massExam/massExamListSlice'
import { massExamItemReducer } from './reducers/massExam/massExamItemSlice'
import { MassExamItemEmployeesReducer } from './reducers/massExam/MassExamItemEmployeesSlice'
import { massExamQuestionConstructorReducer } from './reducers/massExam/massExamQuestionConstructorSlice'
import { examListReducer } from './reducers/exam/examListSlice'
import { examItemReducer } from './reducers/exam/examItemSlice'
import { alertsReducer } from './reducers/app/alerts/AlertsSlice'

const rootReducer = combineReducers({
    userReducer,
    authReducer,
    analyticReducer,
    pdpReducer,
    pdpItemReducer,
    companyReducer,
    notifierReducer,
    employeeReducer,
    specializationReducer,
    assessmentReducer,
    gradeReducer,
    formReducer,
    competenceReducer,
    employeeHistoryPdpReducer,
    developmentPdpReducer,
    developmentPdpItemReducer,
    specializationSetupReducer,
    employeeViewHistoryReducer,
    employeeViewCompetenciesReducer,
    employeeEditPersonalDataReducer,
    gradeDevelopmentPlanReducer,
    employeeEditWorkInCompanyWorkExperienceReducer,
    employeeEditWorkInCompanyCurrentPositionReducer,
    oaAssessmentReducer,
    gradeAssessmentReducer,
    employeeEditWorkInCompanyPreviousPositionReducer,
    employeeEditWorkInCompanyProjectAndRolesReducer,
    employeeEditWorkExperiencePreviousJobReducer,
    employeeEditEducationEducationReducer,
    employeeEditWorkExperienceProjectsAndRolesReducer,
    employeeEditEducationCoursesReducer,
    specializationListReducer,
    openAccessDevelopmentPlanReducer,
    openAccessReducer,
    oaAssessmentAnswersReducer,
    CPAListReducer,
    CPAItemReducer,
    CPAItemCriteriaReducer,
    CPAItemScaleReducer,
    CPAItemEmployeesReducer,
    CPAEmployeeReducer,
    myAssessmentListReducer,
    MyAssessmentPassReducer,
    assessmentResultReducer,
    menuReducer,
    CPAItemAnalyticsReducer,
    massExamListSliceReducer,
    massExamItemReducer,
    MassExamItemEmployeesReducer,
    massExamQuestionConstructorReducer,
    examListReducer,
    examItemReducer,
    alertsReducer,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
        devTools: process.env.NODE_ENV !== 'production',
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
