import React, { FC } from 'react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { QueueQueueRequestsController } from 'utils/QueueRequests'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { useParams } from 'react-router-dom'

type MyAssessmentIndicatorAnswersProps = {
    indicator: IAssessmentIndicator;
    skillLevelUuid: string;
    competenceUuid: string;
}

export const MyAssessmentIndicatorAnswers: FC<MyAssessmentIndicatorAnswersProps> = ({
    indicator,
    competenceUuid,
    skillLevelUuid,
}) => {
    const { assessmentUuid } = useParams()
    const queue = QueueQueueRequestsController.getInstance().addNewQueue(assessmentUuid)
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const formAnswers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    const disableForm = !assessmentSetup?.canSetAnswer

    const asyncAction = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!assessmentUuid) return
        try {
            await dispatch(myAssessmentActions.setAssessmentPassAnswer({
                answerUuid: (e.target as HTMLInputElement).value,
                assessmentUuid,
                indicatorUuid: indicator.uuid,
                skillLevelUuid,
                competenceUuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        queue?.enqueue(() => asyncAction(e), indicator.uuid)

    return (
        <Box>
            <FormControl>
                <RadioGroup
                    onChange={handleChange}
                    value={assessmentSetup?.showAnswer ? indicator?.finalAnswerUuid : undefined}
                >
                    {formAnswers?.map(el => <FormControlLabel
                        key={el.uuid}
                        value={el.uuid}
                        disabled={disableForm}
                        control={<Radio/>}
                        label={el.title}
                    />)}
                </RadioGroup>
            </FormControl>
        </Box>
    )
}
