import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Divider, Stack } from '@mui/material'
import {
    CompetenceTreeHeaderWithTotal,
} from 'components/competence/tree/CompetenceTreeHeaderWithTotal/CompetenceTreeHeaderWithTotal'
import {
    OAAssessmentResultTreeGroup,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultTree/components/OAAssessmentResultTreeGroup/OAAssessmentResultTreeGroup'

export const OAAssessmentResultTree = () => {
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    return (
        <Box>
            <CompetenceTreeHeaderWithTotal/>
            <Divider sx={{ my: 2 }}/>
            <Stack divider={<Divider sx={{ my: 0.5 }}/>}>
                {assessmentResult?.groupCompetence?.map(item => <OAAssessmentResultTreeGroup
                    key={item.uuid}
                    element={item}
                />)}
            </Stack>
            <Divider sx={{ my: 0.5 }}/>
        </Box>
    )
}
