import React, { FC, useState } from 'react'
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { EditRounded } from '@mui/icons-material'
import { TITLE_BUTTON_CHANGE, TITLE_BUTTON_SELECT, TITLE_NAMESPACE_ALL_COUNTS } from 'helpers/enums/titles'
import {
    CPAEmployeesDrawer,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/CPAEmployeesDrawer'
import { useAppSelector } from 'store/hooks/redux'

type CPAEmployeesActionProps = {
    employeesCount: number;
}

export const CPAEmployeesAction: FC<CPAEmployeesActionProps> = ({ employeesCount }) => {
    const [open, setOpen] = useState(false)
    const statusState = useAppSelector(state => state.CPAItemReducer.statusState)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const editableStatus = cpaStatusCode === 'ready' || cpaStatusCode === 'created'
    const fetchingStatusState = editableStatus ? false : !statusState?.fulfilled
    const blockButton = fetchingStatusState

    if (cpaStatusCode === 'complete') return null

    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            <Typography variant="body1">
                {TITLE_NAMESPACE_ALL_COUNTS} {employeesCount}
            </Typography>
            <Tooltip
                placement="bottom-start"
                title={fetchingStatusState ? 'Доступно после обновления статусов оценок' : ''}
                enterDelay={300}
                enterNextDelay={300}
            >
                <Box>
                    <Button
                        variant="outlined"
                        startIcon={<EditRounded/>}
                        disabled={blockButton}
                        onClick={() => setOpen(true)}
                    >
                        {employeesCount > 0 ? TITLE_BUTTON_CHANGE : TITLE_BUTTON_SELECT}
                    </Button>
                </Box>
            </Tooltip>
            <CPAEmployeesDrawer open={open} handleClose={() => setOpen(false)}/>
        </Stack>
    )
}
