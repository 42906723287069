import { AuthAction } from 'store/actions/authAction'
import { UserAction } from 'store/actions/userAction'
import { CompanyAction } from 'store/actions/companyAction'
import { NotifierAction } from 'store/actions/notifierAction'
import { SpecializationAction } from 'store/actions/specializationAction'
import { EmployeeAction } from 'store/actions/employee/employeeAction'
import { GradeAction } from 'store/actions/gradeAction'
import { SpecializationSetupAction } from 'store/actions/specializationSetupAction'
import { SpecializationListAction } from 'store/actions/specializationListAction'
import { CompetenceAction } from 'store/actions/competenceAction'
import { DevelopmentPlanAction } from 'store/actions/openAccess/developmentPlanAction'
import { PdpAction } from 'store/actions/employee/pdp/pdpAction'
import { PdpItemAction } from 'store/actions/employee/pdp/pdpItemAction'
import { DevelopmentPdpAction } from 'store/actions/development/developmentPdpAction'
import { DevelopmentPdpItemAction } from 'store/actions/development/developmentPdpItemAction'
import { EmployeeHistoryAction } from 'store/actions/employee/history/employeeHistoryAction'
import { FormAction } from 'store/actions/form/formAction'
import { AssessmentAction } from 'store/actions/assessment/assessmentAction'
import { AnalyticAction } from 'store/actions/analytic/analyticAction'
import { OAAssessmentAction } from 'store/actions/openAccess/assessment/oaAssessmentAction'
import { OpenAccessAction } from 'store/actions/openAccess/openAccessAction'
import { CpaListAction } from 'store/actions/cpa/cpaListAction'
import { CpaItemAction } from 'store/actions/cpa/cpaItemAction'
import { CpaItemCriteriaAction } from 'store/actions/cpa/cpaItemCriteriaAction'
import { CpaItemScaleAction } from 'store/actions/cpa/cpaItemScaleAction'
import { CpaItemEmployeesAction } from 'store/actions/cpa/cpaItemEmployeesAction'
import { CpaEmployeeAction } from 'store/actions/cpa/cpaEmployeeAction'
import { MyAssessmentAction } from 'store/actions/assessment/myAssessmentAction'
import { AppAction } from 'store/actions/app/appAction'
import { AnalyticCpaAction } from 'store/actions/analytic/analyticCpaAction'
import { ReportAction } from 'store/actions/report/reportAction'
import { MassExamListAction } from './massExam/massExamListAction'
import { MassExamItemAction } from './massExam/massExamItemAction'
import { MassExamItemEmployeesAction } from './massExam/massExamItemEmployeesAction'
import { MassExamQuestionConstructorAction } from './massExam/massExamQuestionConstructorAction'
import { ExamListAction } from './exam/examListAction'
import { ExamItemAction } from './exam/examItemAction'

export const authActions = new AuthAction()
export const userActions = new UserAction()
export const companyActions = new CompanyAction()
export const notifierActions = new NotifierAction()
export const specializationActions = new SpecializationAction()
export const employeeActions = new EmployeeAction()
export const gradeActions = new GradeAction()
export const specializationSetupActions = new SpecializationSetupAction()
export const specializationListActions = new SpecializationListAction()
export const competenceActions = new CompetenceAction()
export const developmentPlanActions = new DevelopmentPlanAction()
export const pdpActions = new PdpAction()
export const pdpItemActions = new PdpItemAction()
export const developmentPdpActions = new DevelopmentPdpAction()
export const developmentPdpItemActions = new DevelopmentPdpItemAction()
export const employeeHistoryPdpActions = new EmployeeHistoryAction()
export const assessmentActions = new AssessmentAction()
export const formActions = new FormAction()
export const oaAssessmentActions = new OAAssessmentAction()
export const openAccessActions = new OpenAccessAction()
export const analyticActions = new AnalyticAction()
export const cpaListActions = new CpaListAction()
export const cpaItemActions = new CpaItemAction()
export const cpaItemCriteriaActions = new CpaItemCriteriaAction()
export const cpaItemScaleActions = new CpaItemScaleAction()
export const cpaItemEmployeesActions = new CpaItemEmployeesAction()
export const cpaEmployeeActions = new CpaEmployeeAction()
export const myAssessmentActions = new MyAssessmentAction()
export const appAction = new AppAction()
export const analyticCpaAction = new AnalyticCpaAction()
export const reportAction = new ReportAction()
export const massExamListActions = new MassExamListAction()
export const massExamItemActions = new MassExamItemAction()
export const massExamItemEmployeesAction = new MassExamItemEmployeesAction()
export const massExamQuestionConstructorAction = new MassExamQuestionConstructorAction()
export const examListAction = new ExamListAction()
export const examItemAction = new ExamItemAction()
