import { Box, Button, Card, Checkbox, IconButton, ListItem, ListItemButton, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useAppDispatch } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import {
    developmentPdpItemActions,
    pdpItemActions,
} from 'store/actions'
import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'
import { MaterialTypeIcon } from 'components/common/MaterialTypeIcon/MaterialTypeIcon'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { CommentOutlined } from '@mui/icons-material'
import { TITLE_NAMESPACE_LEVEL } from 'helpers/enums/titles'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { DrawerMaterialCommentView } from 'components/drawers/DrawerMaterialCommentView/DrawerMaterialCommentView'

type PdpSetupMaterialMobileProps = {
    material: IPdpMaterialFromBase;
    isDevelopment?: boolean;
}

export const PdpSetupMaterialMobile: FC<PdpSetupMaterialMobileProps> = ({ material, isDevelopment }) => {
    const dispatch = useAppDispatch()
    const { commentView, toggleDialog } = useDialogsState({ commentView: false })

    const handleChange = () => {
        if (isDevelopment)
            dispatch(developmentPdpItemActions.changeActivePdpCompetenceMaterialSetup({
                uuid: material.uuid,
                flag: !material.selected,
            }))
        else
            dispatch(pdpItemActions.changeActivePdpCompetenceMaterialSetup({
                uuid: material.uuid,
                flag: !material.selected,
            }))
    }

    const openComment = (e: any) => {
        toggleDialog('commentView', true)
        e.stopPropagation()
    }

    return (
        <ListItem disablePadding>
            <DrawerMaterialCommentView
                open={commentView}
                comment={material.comment}
                materialTitle={material.title}
                materialFormat={material.format}
                handleClose={() => toggleDialog('commentView', false)}
            />
            <ListItemButton
                sx={{
                    py: 0,
                    px: 0,
                    borderRadius: '12px',
                }}
                onClick={handleChange}
            >
                <Checkbox
                    checked={material.selected}
                    disableRipple
                    color={material.isChanged ? 'warning' : 'primary'}
                    onChange={handleChange}
                    sx={{
                        p: 0,
                        mx: 1,
                        color: (theme) => material.isChanged
                            ? theme.palette.warning.main
                            : theme.palette.primary.main,
                    }}
                />
                <Card sx={{ borderRadius: 3, p: 3, flex: 1 }}>
                    <Typography variant='body2' mb={0.5}> {material.title}</Typography>
                    <Box display='flex' alignItems='center' mb={0.5}>
                        <MaterialTypeIcon materialTypeUuid={material.materialTypeUuid} />
                        <Typography ml={1}>{material.format}</Typography>
                    </Box>
                    <Typography mb={3}>{TITLE_NAMESPACE_LEVEL}: {material.skillLevel.title}</Typography>
                    <Box display='flex' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <AccessTimeIcon color='disabled' />
                            <Typography ml={1}> {formatHours(material.studyTime)}</Typography>
                        </Box>
                        <Box>
                            {material.link &&
                                <Button
                                    sx={{ whiteSpace: 'nowrap' }}
                                    endIcon={<OpenInNewIcon />}
                                    target="_blank"
                                    href={material.link}
                                >
                                </Button>
                            }
                            {material?.comment &&
                                <IconButton
                                    color="primary"
                                    onClick={(e) => openComment(e)}
                                >
                                    <CommentOutlined />
                                </IconButton>
                            }
                        </Box>
                    </Box>
                </Card>
            </ListItemButton>
        </ListItem>
    )
}
