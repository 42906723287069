import { AxiosInstance, AxiosRequestConfig } from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

/* eslint-disable */
const collectHeaders = (config: AxiosRequestConfig) => {
    if (!config?.headers) config.headers = {}
    const accessToken = cookies.get('accessToken')
    const companyUUID = cookies.get('companyUUID')
    // TODO: need use variable for name cookie
    const publicScript = cookies.get('X-Public-Script')
    if (accessToken) config.headers.Authorization = accessToken
    if (companyUUID) config.headers['Company-Uuid'] = companyUUID
    if (publicScript) config.headers['X-Public-Script'] = JSON.stringify(publicScript)
    return config
}
/* eslint-enable */

export const addHeadersToRequest = (axios: AxiosInstance) => {
    axios.interceptors.request.use(collectHeaders)
}
