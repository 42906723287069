import React, { FC, useMemo } from 'react'
import { Box, Chip, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_TOTAL } from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { SelectForm } from 'components/form/SelectForm/SelectForm'

type EmployeeAssessmentIndicatorFinalAnswerProps = {
    indicator: IAssessmentIndicator;
    competenceUuid: string;
}

export const EmployeeAssessmentIndicatorFinalAnswer: FC<EmployeeAssessmentIndicatorFinalAnswerProps> = ({
    indicator,
    competenceUuid,
}) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const formAnswers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    const currentSummarize = useMemo(
        () => assessmentSetup?.summarizingStatus && assessmentSetup.viewerIsCurrentReviewer,
        [assessmentSetup],
    )

    const currentAnswer = useMemo(
        () => formAnswers?.find(el => el.uuid === indicator?.finalAnswerUuid),
        [formAnswers, indicator],
    )
    const answerWasChanged = useMemo(
        () => Boolean(indicator.answers?.find(el => el.respondentUuid === 'final')?.isCalibrated),
        [formAnswers, indicator],
    )

    const changeAnswer = async (e: SelectChangeEvent<unknown>) => {
        if (!assessmentSetup?.uuid) return

        try {
            await dispatch(myAssessmentActions.setAssessmentPassAnswer({
                assessmentUuid: assessmentSetup.uuid,
                indicatorUuid: indicator.uuid,
                competenceUuid,
                skillLevelUuid: indicator?.skillLevelUuid,
                answerUuid: e.target.value as string,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Stack spacing={2}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                maxWidth={currentSummarize ? 364 : 267}
            >
                <Box>
                    <Typography variant="body2">
                        {TITLE_NAMESPACE_TOTAL}
                    </Typography>
                </Box>
                {currentSummarize
                    ? <SelectForm
                        id={`finalAnswer-${indicator.uuid}`}
                        values={formAnswers || []}
                        fullWidth={false}
                        value={indicator?.finalAnswerUuid}
                        onChange={changeAnswer}
                    />
                    : <Chip
                        label={currentAnswer?.title}
                        color={answerWasChanged ? 'warning' : 'default'}
                        size="small"
                    />
                }
            </Box>
        </Stack>
    )
}
