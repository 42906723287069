import React, { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Loading } from 'components/Loading/Loading'
import { PATH_LOGIN, PATH_OPEN_ACCESS } from 'helpers/enums/routePath'
import AppPage from 'pages/app/AppPage/AppPage'
import LoginPage from 'pages/login/LoginPage/LoginPage'
import { OpenAccessPage } from 'pages/openAccess/OpenAccessPage/OpenAccessPage'
import { Notification } from 'components/snackbar/Notification/Notification'
import { Metric } from 'components/Metric/Metric'


export const App: FC = () => {
    return (
        <div className="App">
            <Notification/>
            <Metric/>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/*" element={<AppPage/>}/>
                    <Route path={`${PATH_LOGIN}/*`} element={<LoginPage/>}/>
                    <Route path={`${PATH_OPEN_ACCESS}/*`} element={<OpenAccessPage/>}/>
                </Routes>
            </Suspense>
        </div>
    )
}
