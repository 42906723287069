import { HINT_PDP_INSTRUCTION_CONCLUSION_MANAGER, HINT_PDP_INSTRUCTION_INTRO_EMPLOYEE } from 'helpers/enums/hints'

export type InstructionListItem = {
    title: string;
    subList: InstructionListItem[];
}

export type Instruction = {
    textIntro?: string;
    textConclusion?: string;
    listItems: InstructionListItem[]
}

export type InstructionV2 = {
    textIntro?: string[];
    textConclusion?: string[];
}

export const instructionManagerCreatePdp: Instruction = {
    textConclusion: HINT_PDP_INSTRUCTION_CONCLUSION_MANAGER,
    listItems: [
        {
            title: 'Назначьте цель плана и срок завершения изучения плана сотрудником.',
            subList: [],
        },
        {
            title: 'При необходимости, измените содержание плана развития:',
            subList: [
                {
                    title: 'удалите ненужные пункты и материалы в пунктах',
                    subList: [],
                },
                {
                    title: 'наполните другими компетенциями',
                    subList: [],
                },
                {
                    title: 'наполните материалами из базы',
                    subList: [],
                },
                {
                    title: 'создайте индивидуальный материал для текущего ИПР, которого нет в базе',
                    subList: [],
                },
                {
                    title: 'создайте индивидуальный пункт плана для развития нужного навыка, которого нет в базе',
                    subList: [],
                },
            ],
        },
        {
            title: 'После того как заполнена цель и определен срок завершения, план развития можно отправить сотруднику на изучение.',
            subList: [],
        },
        {
            title: 'После отправки плана развития сотруднику отслеживайте прогресс изучения всего плана, каждого пункта и материалов внутри пункта плана.',
            subList: [],
        },
        {
            title: 'После того как сотрудник отправит план развития на согласование, проверьте освоенные навыки сотрудника вне системы.',
            subList: [],
        },
        {
            title: 'Если некоторые материалы или пункты плана недостаточно освоены, снимите отметку “пройденности” и верните план на изучение.',
            subList: [],
        },
        {
            title: 'Завершите план развития, когда все пункты плана будут успешно пройдены.',
            subList: [],
        },
        {
            title: 'Если сотрудник не отправит план развития в указанный вами срок, обратитесь к сотруднику и вместе скорректируйте дату завершения или содержание плана.',
            subList: [],
        },
    ],
}

export const instructionEmployeeWorkWithPdp: Instruction = {
    textIntro: HINT_PDP_INSTRUCTION_INTRO_EMPLOYEE,
    listItems: [
        {
            title: 'При самостоятельном создании плана развития скорректируйте его содержание:',
            subList: [
                {
                    title: 'добавьте цель и срок прохождении',
                    subList: [],
                },
                {
                    title: 'удалите ненужные пункты и материалы в пунктах',
                    subList: [],
                },
                {
                    title: 'наполните другими компетенциями',
                    subList: [],
                },
                {
                    title: 'наполните материалами из базы',
                    subList: [],
                },
                {
                    title: 'создайте индивидуальный материал для текущего ИПР, которого нет в базе',
                    subList: [],
                },
                {
                    title: 'создайте индивидуальный пункт плана для развития нужного навыка, которого нет в базе',
                    subList: [],
                },
            ],
        },
        {
            title: 'Отправьте план на согласование руководителю, который при необходимости поправит его содержание и отправит его вам на изучение.',
            subList: [],
        },
        {
            title: 'Если план развития отправил вам руководитель, первым делом ознакомьтесь с его целью и сроком. Важно оценить план и распределить время так, чтобы успеть отправить руководителю в срок.',
            subList: [],
        },
        {
            title: 'Откройте первый пункт плана, ознакомьтесь со списком материалов.',
            subList: [],
        },
        {
            title: 'Чтобы начать изучение материала, перейдите по ссылке этого материала.',
            subList: [],
        },
        {
            title: 'После освоения материала оставьте галочку у пройденного материала.',
            subList: [],
        },
        {
            title: 'После освоения всех материалов по всем уровням владения, компетенция будет считаться освоенной.',
            subList: [],
        },
        {
            title: 'При возникновении вопросов по компетенциям или конкретным материалам, можно оставить комментарий к элементу плана или связаться с руководителем вне системы.',
            subList: [],
        },
        {
            title: 'По готовности прохождения всего плана развития отправьте план на согласование руководителю.',
            subList: [],
        },
    ],
}

export const instructionAssessmentEmployee: InstructionV2 = {
    textIntro: [
        'Для оценки своих компетенций пройдите опросник, где представлены утверждения, позволяющие определить степень владения компетенциями.',
        'Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение вы проявляли при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:',
    ],
    textConclusion: [
        'Срок прохождения оценки указан в начале опроса. Ваши ответы и комментарии сохраняются автоматически - есть возможность продолжить оценку позже.',
        'После того как будете уверены в проставленных ответах, отправьте результаты и ожидайте формирования итоговых результатов оценки с подробным отчетом.',
        'На основании итоговых результатов вы или ваш руководитель сможете сформировать Индивидуальный план развития.',
    ],
}

export const instructionAssessmentManagerOrParticipant: InstructionV2 = {
    textIntro: [
        'Для того, чтобы оценить сотрудника пройдите опросник, где представлены утверждения, позволяющие определить степень владения компетенциями.',
        'Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение проявлял сотрудник при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:',
    ],
    textConclusion: [
        'Срок прохождения оценки указан в начале опроса. Ваши ответы и комментарии сохраняются автоматически, поэтому можете отвлечься и продолжить оценку позже.',
        'После того как будете уверены в проставленных ответах, отправьте результаты.',
    ],
}

export const instructionAssessmentCpaSelf: InstructionV2 = {
    textIntro: [
        'После прохождения опросника сотрудником можно перейти к утверждению результатов и формированию финального отчета самооценки.',
        'Если нужно изменить результаты самооценки, перейдите к коректировке итоговых ответов. В режиме корректировки проставьте в поле Итог нужные ответы, которые необходимо изменить.',
        'Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение проявлял сотрудник при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:',
    ],
    textConclusion: [
        'Обратите внимание: Корректировать результаты одновременно может только один пользователь.',
        'После утверждения результатов самооценки вы сможете сформировать индивидуальный план развития.',
    ],
}
export const instructionAssessmentCpa180360Editor: InstructionV2 = {
    textIntro: [
        '1. После запуска оценочной процедуры опросники направятся сотрудникам и другим участникам для прохождения.',
        '2. После прохождения оценки всеми участниками - утвердите результаты.',
        '3. При необходимости можно перейти к утверждению результатов, если хотя бы один участник или сам сотрудник пройдет опросник.',
        '4. Если нужно скорректировать результаты оценки, перейдите к калибровке ответов. В режиме корректировки проставьте в поле Итог нужные ответы, которые необходимо изменить.',
        'Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение проявлял сотрудник при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:',
    ],
    textConclusion: [
        'Обратите внимание: Корректировать результаты одновременно может только один пользователь.',
        '5. На основании итоговых результатов вы или сам сотрудник сможете сформировать Индивидуальный план развития.',
    ],
}

export const instructionAssessmentCpa180360: InstructionV2 = {
    textIntro: [
        '1. После запуска оценочной процедуры HR-ом можно перейти к оценке сотрудника.',
        '2. Для оценки сотрудника пройдите опросник, где представлены утверждения, позволяющие определить степень владения компетенциями.',
        'Срок прохождения оценки указан в начале опроса.',
        'После того как будете уверены в проставленных ответах, отправьте результаты.',
        '3. После прохождения оценки всеми участниками - утвердите результаты.',
        '4. При необходимости можно перейти к утверждению результатов, если хотя бы один участник или сам сотрудник пройдет опросник.',
        '5. Если нужно скорректировать результаты самооценки, перейдите к калибровке ответов. В режиме корректировки проставьте в поле Итог нужные ответы, которые необходимо изменить.',
        'Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение проявлял сотрудник при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:',
    ],
    textConclusion: [
        'Обратите внимание: Корректировать результаты одновременно может только один пользователь.',
        '6. На основании итоговых результатов вы или сам сотрудник сможете сформировать Индивидуальный план развития.',
    ],
}
