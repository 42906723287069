import React, { FC, useMemo, useState } from 'react'
import { Box, Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { ChevronRightRounded, ExpandLess, ExpandMore, MoreHorizRounded } from '@mui/icons-material'
import {
    CPAEmployeesStatisticEmployeeDrawerEmployee,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeCard/components/CPAEmployeesStatisticEmployeeDrawer/components/CPAEmployeesStatisticEmployeeDrawerDivision/CPAEmployeesStatisticEmployeeDrawerEmployee/CPAEmployeesStatisticEmployeeDrawerEmployee'

type CPAEmployeesStatisticEmployeeDrawerDivisionProps = {
    division: ICpaItemDivision;
    employeeList: string[]
    isWithoutParticipants: boolean;
}

export const CPAEmployeesStatisticEmployeeDrawerDivision: FC<CPAEmployeesStatisticEmployeeDrawerDivisionProps> = ({
    division,
    employeeList,
    isWithoutParticipants,
}) => {
    const [open, setOpen] = useState(true)
    const handleClick = () => setOpen(prev => !prev)

    const currentEmployees = useMemo(
        () => division.employees.filter(el => employeeList.includes(el.uuid)),
        [division, employeeList],
    )

    if (!currentEmployees.length) return null

    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Box display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick}>
                    <Tooltip placement="bottom-start" title={division.fullPath} enterDelay={300} enterNextDelay={300}>
                        <Stack direction="row" spacing={0.5}>
                            <MoreHorizRounded color="disabled"/>
                            <ChevronRightRounded color="disabled"/>
                            <Typography variant="subtitle1">
                                {division.title}
                            </Typography>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </Stack>
                    </Tooltip>
                </Box>
            </Stack>
            <Collapse in={open} timeout="auto">
                <Stack spacing={2} mt={1}>
                    <Divider/>
                    <Stack spacing={2} divider={<Divider/>}>
                        {currentEmployees.map(employee => <CPAEmployeesStatisticEmployeeDrawerEmployee
                            key={employee?.uuid}
                            employee={employee}
                            isWithoutParticipants={isWithoutParticipants}
                        />)}
                    </Stack>
                    <Divider/>
                </Stack>
            </Collapse>
        </Stack>
    )
}
