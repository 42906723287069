import React, { Suspense } from 'react'
import { Box } from '@mui/material'
import { PageTitle } from 'components/PageTitle/PageTitle'
import { TITLE_PAGE_HISTORY } from 'helpers/enums/titles'
import { Loading } from 'components/Loading/Loading'
import { Route, Routes } from 'react-router-dom'
import {
    EmployeeHistory,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistory/EmployeeHistory'
import {
    EmployeeHistoryPdp,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/EmployeeHistoryPdp'
import { PATH_HISTORY } from 'helpers/enums/routePath'
import { HistoryAssessmentMeta } from 'pages/history/HistoryPage/components/HistoryAssessmentMeta/HistoryAssessmentMeta'
import { HistoryAssessment } from 'pages/history/HistoryPage/components/HistoryAssessment/HistoryAssessment'
import {
    HistoryAssessmentResult,
} from 'pages/history/HistoryPage/components/HistoryAssessmentResult/HistoryAssessmentResult'
import { ExamResultPage } from '../../ExamResultPage/ExamResultPage'
import { ExamListPage } from '../../ExamListPage/ExamListPage'

export const HistoryPage = () => {
    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <Box mb={4}>
                <PageTitle title={TITLE_PAGE_HISTORY}/>
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
                <Suspense fallback={<Loading/>}>
                    <Routes>
                        <Route index element={<EmployeeHistory parentPath={PATH_HISTORY} isPathMyHistory/>}/>
                        <Route path="pdp/*">
                            <Route path=":pdpUuid/*" element={<EmployeeHistoryPdp parentPath={PATH_HISTORY}/>}/>
                        </Route>
                        <Route path="assessment">
                            <Route path=":assessmentUuid">
                                <Route index element={<HistoryAssessmentMeta parentPath={PATH_HISTORY}/>}/>
                                <Route path="pass" element={<HistoryAssessment parentPath={PATH_HISTORY}/>}/>
                                <Route path="result" element={<HistoryAssessmentResult parentPath={PATH_HISTORY}/>}/>
                            </Route>
                        </Route>

                        <Route path="exam/*">
                            <Route index element={<ExamListPage/>} />
                            <Route path=":examUuid/result" element={<ExamResultPage parentPath={PATH_HISTORY} />}/>
                        </Route>
                    </Routes>
                </Suspense>
            </Box>
        </Box>
    )
}

