import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ICpaGetEmployeesForAddEmployee,
    ICpaGetEmployeesForAddResponse,
    ICpaSetEmployeeParticipantsRequest,
} from 'api/typing/cpaTypes'
import { CpaTypeCode, getCpaType } from 'helpers/cpa/cpaTypes'
import { cpaRoleManager } from 'helpers/cpa/cpaRoles'


type TParticipantState = Record<string, ICpaGetEmployeesForAddEmployee>

type TParticipantDivisionState = Omit<ICpaGetEmployeesForAddResponse, 'employees'> & {
    employees: TParticipantState,
    employeesUuid: string[],
}

interface ICPAItemEmployeesState {
    cpaTypeCode: CpaTypeCode | null;
    employee: ICpaEmployee | null;
    employeeRoles: Record<string, ICpaEmployeeRole>
    divisionInfo: { divisionUuid: string; divisionManagerUuid: string; };
    participantCount: number;
    employeeRoleParticipants: Record<string, ICpaEmployeeRoleParticipant>
    dialogParticipants: {
        role: IEntity | null;
        participantList: ICpaGetEmployeesForAddResponse[];
        participants: Record<string, TParticipantDivisionState>;
    };
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAItemEmployeesState = {
    cpaTypeCode: null,
    employee: null,
    employeeRoles: {},
    divisionInfo: {
        divisionUuid: '',
        divisionManagerUuid: '',
    },
    participantCount: 0,
    employeeRoleParticipants: {},
    dialogParticipants: {
        role: null,
        participants: {},
        participantList: [],
    },
    isLoading: false,
    error: null,
}

export const CPAEmployeeSlice = createSlice({
    name: 'CPAEmployee',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setEmployeeDivisionInfo: (state, action: PayloadAction<{
            divisionUuid: string;
            divisionManagerUuid: string;
        }>) => {
            state.divisionInfo.divisionManagerUuid = action.payload.divisionManagerUuid
                ?? state.divisionInfo.divisionManagerUuid
            state.divisionInfo.divisionUuid = action.payload.divisionUuid ?? state.divisionInfo.divisionUuid
        },

        setEmployee: (state, action: PayloadAction<ICpaEmployee>) => {
            state.employee = action.payload
            state.participantCount = 0
            state.divisionInfo.divisionManagerUuid = action.payload.manager?.uuid
                ?? state.divisionInfo.divisionManagerUuid

            state.cpaTypeCode = getCpaType(action.payload.assessment?.cpaTypeUuid)
            state.employeeRoles = action.payload.roles.reduce((acc, el) => ({
                ...acc,
                [el.uuid]: el,
            }), {} as Record<string, ICpaEmployeeRole>)
            state.employeeRoleParticipants = action.payload.roles.reduce((acc, role) => {
                const participants = role.participant.reduce((accP, participant) => {
                    state.participantCount += 1
                    return {
                        ...accP,
                        [participant.uuid]: { ...participant, roleUuid: role.uuid },
                    }
                }, {} as Record<string, ICpaEmployeeRoleParticipant>)
                return { ...acc, ...participants }
            }, {} as Record<string, ICpaEmployeeRoleParticipant>)
        },
        setParticipantList: (state, action: PayloadAction<ICpaGetEmployeesForAddResponse[]>) => {
            state.dialogParticipants.participants = {}
            const managerUuid = state.employee?.manager?.uuid
            let hasManagerInList = false
            const roleIsManager = state?.dialogParticipants?.role?.uuid === cpaRoleManager
            state.dialogParticipants.participantList = action.payload.reduce((acc, division) => {
                const employees = division.employees.reduce((acc, employee) => {
                    if (employee.uuid === state.employee?.uuid) return acc
                    hasManagerInList = hasManagerInList || managerUuid === employee.uuid
                    const isEmployeeManager = employee.uuid === state.divisionInfo.divisionManagerUuid
                    const employeeRoleIsCurrentRole = employee?.role?.uuid === state.dialogParticipants?.role?.uuid
                    const employeeIsBlocked = (!employee?.role
                        ? false
                        : !employeeRoleIsCurrentRole) || isEmployeeManager && roleIsManager

                    const employeeIsSelected = state.dialogParticipants?.role?.uuid === employee?.role?.uuid

                    acc.push({ ...employee, isSelected: employeeIsSelected, blocked: employeeIsBlocked })
                    return acc
                }, [] as ICpaGetEmployeesForAddEmployee[])

                if (employees.length)
                    acc.push({ ...division, employees })
                return acc
            }, [] as ICpaGetEmployeesForAddResponse[])

            state.dialogParticipants.participantList.forEach(division => {
                let someParticipantSelected = false
                let allParticipantSelected = true
                const employeesUuid: string[] = []
                const participants = division.employees.reduce(
                    (acc, participant) => {
                        if (!participant.blocked) {
                            someParticipantSelected = someParticipantSelected || Boolean(participant.isSelected)
                            allParticipantSelected = allParticipantSelected && Boolean(participant.isSelected)
                        }
                        employeesUuid.push(participant.uuid)
                        return { ...acc, [participant.uuid]: participant }
                    }, {} as TParticipantState,
                )

                state.dialogParticipants.participants[division.uuid] = {
                    ...division,
                    employees: participants,
                    employeesUuid,
                    isSelected: someParticipantSelected,
                    allSelected: allParticipantSelected,
                }
            })

            if (!hasManagerInList && managerUuid && roleIsManager) {
                if (!state.dialogParticipants.participants[state.divisionInfo.divisionUuid])
                    state.dialogParticipants.participants[state.divisionInfo.divisionUuid] = {
                        employees: {},
                        uuid: state.divisionInfo.divisionUuid,
                        isSelected: false,
                        employeesUuid: [],
                        title: '',
                        allSelected: false,
                        fullPath: '',
                    }
                state.dialogParticipants.participants[state.divisionInfo.divisionUuid].employeesUuid.push(managerUuid)
                state.dialogParticipants.participants[state.divisionInfo.divisionUuid].employees[managerUuid] = {
                    participants: [],
                    uuid: managerUuid,
                    role: null,
                    isSelected: true,
                    divisionUuid: state.divisionInfo.divisionUuid,
                    blocked: true,
                    assessment: null,
                    participantCount: 0,
                    firstName: '',
                    grade: null,
                    lastName: '',
                    photo: '',
                    secondName: '',
                    specialization: null,
                }
            }

        },

        setAllParticipantsState: (state, action: PayloadAction<boolean>) => {
            Object.keys(state.dialogParticipants.participants).forEach(divisionUuid => {
                state.dialogParticipants.participants[divisionUuid].isSelected = action.payload
                state.dialogParticipants.participants[divisionUuid].allSelected = action.payload
                state.dialogParticipants.participants[divisionUuid].employeesUuid.forEach(employeeUuid => {
                    if (
                        state.dialogParticipants.participants[divisionUuid].employees[employeeUuid].blocked
                        || state.divisionInfo?.divisionManagerUuid === employeeUuid
                    ) return

                    state.dialogParticipants
                        .participants[divisionUuid]
                        .employees[employeeUuid]
                        .isSelected = action.payload
                })
            })
        },

        setSelectStateDivision: (state, action: PayloadAction<{ divisionUuid: string; isSelected: boolean }>) => {
            const { divisionUuid, isSelected } = action.payload
            state.dialogParticipants.participants[divisionUuid].isSelected = isSelected
            state.dialogParticipants.participants[divisionUuid].allSelected = isSelected
            state.dialogParticipants.participants[divisionUuid].employeesUuid.forEach(employeeUuid => {
                if (
                    state.dialogParticipants.participants[divisionUuid].employees[employeeUuid].blocked
                    || state.divisionInfo?.divisionManagerUuid === employeeUuid
                ) return
                state.dialogParticipants.participants[divisionUuid].employees[employeeUuid].isSelected = isSelected
            })
        },

        setSelectStateEmployee: (
            state,
            action: PayloadAction<{ employeeUuid: string; divisionUuid: string; isSelected: boolean }>,
        ) => {
            const { divisionUuid, isSelected, employeeUuid } = action.payload
            state.dialogParticipants.participants[divisionUuid].employees[employeeUuid].isSelected = isSelected
            let someParticipantSelected = false
            let allParticipantSelected = true
            state.dialogParticipants.participants[divisionUuid].employeesUuid.forEach(uuid => {
                if (state.dialogParticipants.participants[divisionUuid].employees[uuid].blocked) return
                const employeeSelected = state.dialogParticipants.participants[divisionUuid].employees[uuid].isSelected
                someParticipantSelected = Boolean(someParticipantSelected || employeeSelected)
                allParticipantSelected = Boolean(allParticipantSelected && employeeSelected)
            })

            if (someParticipantSelected === state.dialogParticipants.participants[divisionUuid].isSelected
                && allParticipantSelected === state.dialogParticipants.participants[divisionUuid].allSelected) return
            state.dialogParticipants.participants[divisionUuid].isSelected = someParticipantSelected
            state.dialogParticipants.participants[divisionUuid].allSelected = allParticipantSelected
        },

        setDialogParticipantsState: (state, action: PayloadAction<IEntity | null>) => {
            state.dialogParticipants.role = action.payload
        },

        deleteParticipantsFromEmployee: (
            state,
            action: PayloadAction<ICpaSetEmployeeParticipantsRequest & { deletedUuid: string }>,
        ) => {
            if (
                !state.employeeRoles[action.payload.roleUuid]
                || state.divisionInfo?.divisionManagerUuid === action.payload.deletedUuid
            ) return

            state.employeeRoles[action.payload.roleUuid].participant = state
                .employeeRoles[action.payload.roleUuid]
                .participant.filter(el => el.uuid !== action.payload.deletedUuid)
            state.participantCount -= 1

            if (state.employee?.assessment?.progress)
                state.employee.assessment.progress.assessmentsCount -= 1
        },
    },
})

export const CPAEmployeeReducer = CPAEmployeeSlice.reducer
