import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { AppMenuMobile } from 'components/AppMenu/components/AppMenuMobile/AppMenuMobile'
import { AppMenuDesktop } from 'components/AppMenu/components/AppMenuDesktop/AppMenuDesktop'

export const AppMenu = () => {
    const theme = useTheme()
    const isMobileMenu = useMediaQuery(theme.breakpoints.down('desktop'))
    if (isMobileMenu) return <AppMenuMobile/>
    return <AppMenuDesktop/>
}
