import React, { FC } from 'react'
import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMMENTS } from 'helpers/enums/titles'
import { DrawerCommentItem } from 'components/drawers/DrawerComments/components/DrawerCommentItem/DrawerCommentItem'
import {
    DrawerCommentCreate,
} from 'components/drawers/DrawerComments/components/DrawerCommentCreate/DrawerCommentCreate'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { useAuthentication } from 'store/hooks/authentication'
import { useDesktop } from 'helpers/hooks/useDesktop'

type DrawerCommentsProps = {
    open: boolean;
    handleClose: () => void;
    entityTitle: string;
    itemTitle: string | React.ReactNode;
    comments: IComment[];
    disableEdit?: boolean;
    service?: PermissionServiceCode;
    divisions?: string[];
    onCreateComment?: (comment: IComment) => void
    onDeleteComment?: (uuid: string) => void
    onUpdateComment?: (comment: IComment) => void
}

export const DrawerComments: FC<DrawerCommentsProps> = ({
    open,
    handleClose,
    disableEdit,
    entityTitle,
    itemTitle,
    comments,
    service,
    divisions,
    onDeleteComment,
    onCreateComment,
    // onUpdateComment,
}) => {
    const desktop = useDesktop()
    const hasPermission = service ? useAuthentication({ service, divisions, accessLevel: 'w' }) : true
    const canEdit = hasPermission && onCreateComment && !disableEdit

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 620 },
                },
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    py: 5,
                }}
            >
                <Box display="flex" flexDirection="column" pb={3} px={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_COMMENTS}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Box>
                    <Stack spacing={1}>
                        <Typography variant="body1">
                            {entityTitle}
                        </Typography>
                        {typeof itemTitle === 'string'
                            ? <Typography
                                variant="body2"
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                }}
                            >
                                {itemTitle}
                            </Typography>
                            :
                            <Box>
                                {itemTitle}
                            </Box>
                        }
                    </Stack>
                </Box>
                <Box flex={1} overflow="auto" px={5} pb={5}>
                    {comments?.length > 0 ?
                        <>
                            <Divider sx={{ mb: 3 }}/>
                            <Stack spacing={3} divider={<Divider flexItem/>}>
                                {comments.map(comment =>
                                    <DrawerCommentItem
                                        key={`${comment.uuid}-${comment.author}`}
                                        comment={comment}
                                        disableEdit={disableEdit}
                                        deleteComment={onDeleteComment}
                                    />)}
                            </Stack>
                        </>
                        :
                        <Box
                            display="flex"
                            height="100%"
                            justifyContent="center"
                            alignItems="center"
                            py={3}
                        >
                            <EmptyPage
                                contentText={`Здесь еще нет комментариев.${canEdit ? ' Оставьте первый' : ''}`}
                            />
                        </Box>
                    }
                </Box>
                <Authenticated service={service} accessLevel="w" divisions={divisions}>
                    {!disableEdit && onCreateComment &&
                        <Box>
                            {!desktop && <Divider sx={{ mb: 3 }} />}
                            <Box pt={2} px={5}>
                                <DrawerCommentCreate createComment={onCreateComment}/>
                            </Box>
                        </Box>
                    }
                </Authenticated>
            </Box>
        </Drawer>
    )
}
