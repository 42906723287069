import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISpecializationListOnePayload } from 'api/typing/specializationTypes'

const initialState: IState<ISpecializationListState> = {
    data: {} as ISpecializationListState,
    isLoading: false,
    error: null,
}

export const specializationListSlice = createSlice({
    name: 'specializationList',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getMetaForCreateOne: (state, action: PayloadAction<ISpecializationMetaForCreate>) => {
            state.isLoading = false
            state.error = null
            state.data.createOneMeta = action.payload
        },
        createOne: (state, action: PayloadAction<ISpecializationListOnePayload
            & { meta: ISpecializationMetaForCreate }>) => {
            const { meta: { specializations, divisions }, specializationUuid, divisionsUuid } = action.payload
            const divisionInPayload = divisions.filter(division => divisionsUuid.includes(division.uuid))
            const currentSpecialization = specializations.find(el => el.uuid === specializationUuid)
            if (!currentSpecialization) return

            const specializationsToAdd = {
                uuid: specializationUuid,
                title: currentSpecialization.title,
                employeesCount: 0,
                gradesCount: 0,
            }

            if (state.data?.list?.length <= 0) {
                divisionInPayload.forEach(division => {
                    state.data.list.push({
                        uuid: division.uuid,
                        title: division.title,
                        fullPath: division.fullPath,
                        specializations: [specializationsToAdd],
                    })
                })
            } else {
                if (!state.data.list) state.data.list = []
                divisionInPayload.forEach(division => {
                    if (!state.data?.list?.some(el => {
                        if (el.uuid === division.uuid) {
                            if (el.specializations.some(specialization => specialization.uuid === specializationUuid))
                                return true
                            el.specializations.push({
                                uuid: specializationUuid,
                                title: currentSpecialization.title,
                                employeesCount: 0,
                                gradesCount: 0,
                            })
                            return true
                        }
                    })) {
                        state.data.list.push({
                            uuid: division.uuid,
                            title: division.title,
                            fullPath: division.fullPath,
                            specializations: [specializationsToAdd],
                        })
                    }
                })
            }
            state.isLoading = false
            state.error = null
        },
        updateOne: (state, action: PayloadAction<ISpecializationListOnePayload
            & { specialization: Omit<ISpecializationListSpecialization, 'employeesCount'> }>) => {
            const { divisionsUuid, specialization, specializationUuid } = action.payload

            state.data.list = state.data?.list?.map(division => {
                if (divisionsUuid.includes(division.uuid)) {
                    if (!division.specializations.some(({ uuid }) => uuid === specializationUuid))
                        division.specializations.push({ ...specialization, employeesCount: 0 })
                } else {
                    // eslint-disable-next-line no-param-reassign
                    division.specializations = division.specializations.filter(el => el.uuid !== specializationUuid)
                }
                return division

            })
            state.isLoading = false
            state.error = null
        },
        deleteOne: (state, action: PayloadAction<ISpecializationListOnePayload>) => {
            const { divisionsUuid, specializationUuid } = action.payload

            state.data.list = state.data?.list?.map(division => {
                if (divisionsUuid.includes(division.uuid)) {
                    return {
                        ...division,
                        specializations: division.specializations.filter(specialization =>
                            specialization.uuid !== specializationUuid),
                    }
                }
                return division
            })
            state.isLoading = false
            state.error = null
        },
        getList: (state, action: PayloadAction<ISpecializationListDivision[]>) => {
            state.isLoading = false
            state.error = null
            state.data.list = action.payload
        },


        clearMeta: (state) => {
            state.isLoading = false
            state.error = null
            state.data.createOneMeta = {} as ISpecializationMetaForCreate
        },
    },
})

export const specializationListReducer = specializationListSlice.reducer
