import React, { JSXElementConstructor } from 'react'
import { GroupsRounded, PeopleRounded, PersonRounded } from '@mui/icons-material'
import {
    TITLE_NAMESPACE_ASSESSMENT_180,
    TITLE_NAMESPACE_ASSESSMENT_360,
    TITLE_NAMESPACE_SELF_ASSESSMENT,
} from 'helpers/enums/titles'

export const cpaTypeSelfAssessment = 'ffec06b7-286c-4e6a-9af4-e2735b926c0f' // Оценка компетенций,self
export const cpaType180 = 'd9c08d74-ba53-45ee-8210-2028b699a4b9' // Оценка 180,180
export const cpaType360 = '896b6621-6f23-4c7e-ad59-75ab54f6ca2b' // Оценка 360,360
export const cpaTypes = [cpaTypeSelfAssessment, cpaType180, cpaType360]
export type CpaTypeCode = 'self' | '180' | '360'
type CpaTypeUuids = typeof cpaTypeSelfAssessment | typeof cpaType180 | typeof cpaType360


export interface ICpaChip {
    uuid: CpaTypeUuids;
    color: 'default' | 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info';
    variant: 'outlined' | 'filled';
    code: CpaTypeCode;
    title: string;
    icon: React.ReactElement<any, string | JSXElementConstructor<any>>;
}

const cpaChips: Record<string, ICpaChip> = {
    [cpaTypeSelfAssessment]: {
        uuid: cpaTypeSelfAssessment,
        title: TITLE_NAMESPACE_SELF_ASSESSMENT,
        color: 'primary',
        variant: 'outlined',
        code: 'self',
        icon: React.createElement(PersonRounded),
    },
    [cpaType180]: {
        uuid: cpaType180,
        title: TITLE_NAMESPACE_ASSESSMENT_180,
        color: 'secondary',
        variant: 'outlined',
        code: '180',
        icon: React.createElement(PeopleRounded),
    },
    [cpaType360]: {
        uuid: cpaType360,
        title: TITLE_NAMESPACE_ASSESSMENT_360,
        color: 'success',
        variant: 'outlined',
        code: '360',
        icon: React.createElement(GroupsRounded),
    },
}

export const getCpaChipFromType = (type: string | undefined) => type && cpaChips[type]
    ? cpaChips[type]
    : cpaChips[cpaTypeSelfAssessment]

// export const getCpaType = (type: string | undefined): Pick<ICpaChip, 'code' | 'uuid' | 'title'> | null => type && cpaChips[type]
export const getCpaType = (type: string | undefined): CpaTypeCode | null => type && cpaChips[type]
    ? cpaChips[type].code
    // ? pickFromObject(cpaChips[type], ['code', 'uuid', 'title'])
    : null
