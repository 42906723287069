import React from 'react'
import { Box, Card, CardContent, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'

export const CPAMetaSkeleton = () => {
    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box flex={1}>
                                <Typography variant="h1">
                                    <Skeleton/>
                                </Typography>
                            </Box>
                            <Skeleton variant="rounded" width={100}/>
                            <Skeleton variant="rounded" width={100}/>
                            <Skeleton variant="rounded" width={20}/>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Skeleton variant="rounded" width={100}/>
                            <Skeleton variant="rounded" width={100}/>
                        </Stack>
                    </Stack>
                    <Skeleton variant="rounded"/>
                    <Divider/>
                    <Grid container>
                        <Grid item mobile={12} tablet={6}>
                            <Stack spacing={2}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Skeleton variant="rounded" width={20}/>
                                    <Skeleton variant="rounded" width={100}/>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Skeleton variant="rounded" width={20}/>
                                    <Skeleton variant="rounded" width={100}/>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item mobile={12} tablet={6}>
                            <Stack spacing={2}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Skeleton variant="rounded" width={20}/>
                                    <Skeleton variant="rounded" width={100}/>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Skeleton variant="rounded" width={20}/>
                                    <Skeleton variant="rounded" width={100}/>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    )
}
