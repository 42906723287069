import React, { FC } from 'react'
import {
    TITLE_BUTTON_COMMENT_PDP,
    TITLE_NAMESPACE_CANCELED,
    TITLE_NAMESPACE_DEVELOPMENT_PLAN,
    TITLE_NAMESPACE_ENDED_TO,
    TITLE_NAMESPACE_PDP_COMPLETED,
    TITLE_NAMESPACE_POINT,
    TITLE_NAMESPACE_PROGRESS_COMPLETE,
    TITLE_NAMESPACE_REVIEWER,
} from 'helpers/enums/titles'
import { Box, Button, Divider, Grid, Stack, Typography, useTheme } from '@mui/material'
import { CommentOutlined, DateRangeRounded, FlagRounded, PersonRounded } from '@mui/icons-material'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { getDaysDifference } from 'helpers/dateAndTime/formatTimeToStringDuration'

type HistoryPdpInfoProps = {
    pdp: IPdp;
    pdpStatus: IPdpStatus;
}

export const HistoryPdpInfo: FC<HistoryPdpInfoProps> = ({ pdp, pdpStatus }) => {
    const theme = useTheme()
    const { dialogComment, toggleDialog } = useDialogsState({
        dialogComment: false,
    })
    const pdpIsCompleted = pdpStatus.status === 'completed'

    const colorEndedAt = pdp?.endedAt || pdpStatus.status === 'expired'
        ? theme.palette.text.secondary
        : theme.palette.warning.main

    return (
        <Grid container>
            <DrawerComments
                open={dialogComment}
                handleClose={() => toggleDialog('dialogComment', false)}
                entityTitle={TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                itemTitle={<PointPositionSpecializationGrade
                    title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}/>}
                comments={pdp.comments}
                disableEdit={true}
            />
            <Grid item mobile={pdpIsCompleted ? 12 : 5.5}>
                <Stack spacing={2.5}>
                    {pdp?.reviewer && <Stack direction="row" alignItems="center" spacing={1}>
                        <PersonRounded color="warning"/>
                        <Typography>
                            {TITLE_NAMESPACE_REVIEWER}: {pdp?.reviewer?.name}
                        </Typography>
                    </Stack>}
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <DateRangeRounded color="warning"/>
                        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem/>}>
                            <Typography sx={{ color: colorEndedAt }}>
                                {`${TITLE_NAMESPACE_ENDED_TO} ${pdp?.endedAt}`}
                            </Typography>
                            <Typography variant="body1">
                                {pdpIsCompleted
                                    ? `${TITLE_NAMESPACE_PDP_COMPLETED}: ${pdp?.finishedAt} (${getDaysDifference(pdp.sentAt, pdp.finishedAt)})`
                                    : `${TITLE_NAMESPACE_CANCELED}: ${pdp?.finishedAt}`
                                }
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FlagRounded color="warning" />
                        <Typography variant="body1">
                            {`${TITLE_NAMESPACE_POINT} - ${pdp?.point}`}
                        </Typography>
                    </Stack>
                    {pdp?.comments?.length > 0 && <Button
                        startIcon={<CommentOutlined color="primary" />}
                        variant="outlined"
                        sx={{ alignSelf: 'flex-start' }}
                        onClick={() => toggleDialog('dialogComment', true)}
                    >
                        {TITLE_BUTTON_COMMENT_PDP}
                    </Button>}
                </Stack>
            </Grid>
            {!pdpIsCompleted && <>
                <Grid item mobile={1}>
                    <Box display="flex" height="100%" justifyContent="center">
                        <Divider orientation="vertical" flexItem/>
                    </Box>
                </Grid>
                <Grid
                    item
                    mobile={5.5}
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                    justifyContent="flex-end"
                >
                    <GFProgressBar
                        value={pdp?.progress}
                        title={TITLE_NAMESPACE_PROGRESS_COMPLETE}
                    />
                </Grid>
            </>}
        </Grid>
    )
}
