import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate } from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeWorkInCompanyPreviousPositionState> = {
    data: {} as IEmployeeWorkInCompanyPreviousPositionState,
    isLoading: false,
    error: null,
}

export const employeeEditWorkInCompanyPreviousPositionSlice = createSlice({
    name: 'employeeEditWorkInCompanyPreviousPosition',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getPreviousPositionList: (state, action: PayloadAction<IEmployeeWorkInCompanyPreviousPosition[]>) => {
            state.data.positionList = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        deletePreviousPositionItem: (state, action: PayloadAction<string>) => {
            state.data.positionList = state.data.positionList.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        createPreviousPositionItem: (
            state,
            action: PayloadAction<IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate>,
        ) => {
            const { uuid, gradeUuid, specializationUuid, divisionUuid, endDate, startDate } = action.payload
            state.data.positionList.push({
                uuid: uuid ?? '',
                endDate,
                startDate,
                division: state.data.divisions.find(el => el.uuid === divisionUuid) || {} as IEntity,
                specialization: state.data.specializations.find(el => el.uuid === specializationUuid) || {} as IEntity,
                grade: state.data.grades.find(el => el.uuid === gradeUuid) || {} as IEntity,
            })
            state.isLoading = false
            state.error = null
        },

        pushPreviousPositionItem: (state, action: PayloadAction<IEmployeeWorkInCompanyPreviousPosition>) => {
            state.data.positionList.push(action.payload)
            state.isLoading = false
            state.error = null
        },

        updatePreviousPositionItem: (
            state,
            action: PayloadAction<IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate>,
        ) => {
            const { specializationUuid, divisionUuid, gradeUuid, ...data } = action.payload
            state.data.positionList = state.data.positionList.map(el => {
                if (el.uuid === action.payload.uuid)
                    return {
                        ...el,
                        ...data,
                        division: state.data.divisions.find(el => el.uuid === divisionUuid) || {} as IEntity,
                        specialization: state.data.specializations.find(el =>
                            el.uuid === specializationUuid) || {} as IEntity,
                        grade: state.data.grades.find(el => el.uuid === gradeUuid) || {} as IEntity,
                    }
                return el
            })
            state.isLoading = false
            state.error = null
        },

        getDivisions: (state, action: PayloadAction<IEntity[]>) => {
            state.data.divisions = action.payload
            state.isLoading = false
            state.error = null
        },

        getSpecializations: (state, action: PayloadAction<IEntity[]>) => {
            state.data.specializations = action.payload
            state.isLoading = false
            state.error = null
        },

        getGrades: (state, action: PayloadAction<IEntity[]>) => {
            state.data.grades = action.payload
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployeeWorkInCompanyPreviousPositionState
            state.lastQuery = null
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditWorkInCompanyPreviousPositionReducer = employeeEditWorkInCompanyPreviousPositionSlice.reducer
