import { AxiosError, AxiosInstance } from 'axios'
import { logResponseError } from 'helpers/log'


const errorLoggerResponse = (error: AxiosError) => {
    const baseURL = error?.config.baseURL as string
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const configUrl = error?.config?.url as string
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const { status, data } = error?.response || {}

    const message = (data as { message: string })?.message || error?.message
    logResponseError(baseURL, configUrl, status || error.code, message)

    return Promise.reject(error)
}

export const addErrorLogger = (axios: AxiosInstance) => {
    axios.interceptors.response.use(void 0, errorLoggerResponse)
}
