import React from 'react'
import { Stack } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import {
    MyAssessmentPassCompetenceMobile,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/components/MyAssessmentPassMobileCompetencies/components/MyAssessmentPassCompetenceMobile/MyAssessmentPassCompetenceMobile'

export const MyAssessmentPassMobileCompetencies = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    return (
        <Stack spacing={8}>
            {assessment?.competencies.map(competence => <MyAssessmentPassCompetenceMobile
                key={competence.uuid}
                competenceUuid={competence.uuid}
            />)}
        </Stack>
    )
}
