import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EmployeeHistoryPdpState {
    pdp: IPdp;
    item: IPdpItem & Pick<IPdp, 'employee' | 'reviewer'>;
}

const initialState: IState<EmployeeHistoryPdpState> = {
    data: {} as EmployeeHistoryPdpState,
    isLoading: false,
    error: null,
}

export const employeeHistoryPdpSlice = createSlice({
    name: 'employeeHistoryPdp',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getHistoryPdp: (state, action: PayloadAction<IPdp>) => {
            state.data.pdp = action.payload
            state.isLoading = false
            state.error = null
        },

        getHistoryPdpItem: (state, action: PayloadAction<IPdpItem & Pick<IPdp, 'employee' | 'reviewer'>>) => {
            state.data.item = action.payload
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeHistoryPdpReducer = employeeHistoryPdpSlice.reducer
