import React, { FC, useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_DESCRIPTION,
    TITLE_NAMESPACE_ANSWER_VARIANTS,
    TITLE_NAMESPACE_SCALE_NAME,
} from 'helpers/enums/titles'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { InputForm } from 'components/form/InputForm/InputForm'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { AddRounded, CloseRounded, ImportExportRounded } from '@mui/icons-material'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { emptyUuid, isEmptyUuid } from 'helpers/isEmptyUuid'
import { useAppDispatch } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { cpaScaleNumericUuid } from 'helpers/cpa/scale/cpaScalesUuids'
import { cpaItemScaleActions } from 'store/actions'

type FormValues = {
    title: string;
    answers: IDictionaryScaleItemAnswer[],
    answersToDelete: IDictionaryScaleItemAnswer[],
    emptyAnswer: IDictionaryScaleItemAnswer | undefined,
}

type CPAScaleDrawerContentItemDrawerDataProps = {
    scaleItem: undefined | IDictionaryScaleItem
    handleClose: () => void;
}

export const CPAScaleDrawerContentItemDrawerData: FC<CPAScaleDrawerContentItemDrawerDataProps> = ({
    scaleItem,
    handleClose,
}) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            title: scaleItem?.title || '',
            answers: scaleItem?.answers
                .filter(el => !isEmptyUuid(el.uuid) && el.weight >= 0)
                .sort((a, b) => a.weight - b.weight) || [],
            answersToDelete: [],
            emptyAnswer: scaleItem?.answers.find(el => isEmptyUuid(el.uuid) || el.weight < 0),
        },
        mode: 'onSubmit',
    })
    const isOrigin = scaleItem?.entityType === 'origin'
    const watchEmptyAnswer = watch('emptyAnswer')

    const { fields: answersToDelete, append: appendDeletedAnswer } = useFieldArray({
        name: 'answersToDelete',
        control,
    })

    const { fields, append: appendAnswer, remove, move } = useFieldArray({
        name: 'answers',
        control,
    })

    const removeEmptyItem = () => {
        setValue('emptyAnswer', undefined)
        if (answersToDelete.find(el => isEmptyUuid(el.uuid) || el.weight < 0)) return
        appendDeletedAnswer({
            uuid: watchEmptyAnswer?.uuid ?? emptyUuid,
            title: 'Не знаю',
            description: 'Нет четкого ответа (в расчете не учитывается)',
            weight: -1,
        })
    }

    const addEmptyItem = () => {
        // const answersToDelete
        // if (answersToDelete.find(el => isEmptyUuid(el.uuid) || el.weight < 0)) return
        setValue('emptyAnswer', {
            uuid: emptyUuid,
            title: 'Не знаю',
            description: 'Нет четкого ответа (в расчете не учитывается)',
            weight: -1,
        })
    }

    const addAnswer = () => {
        // const weight = fields.reduce((acc, el) => acc > el.weight ? acc : el.weight, 0) + 1
        const weight = fields.length
        appendAnswer({
            title: '',
            uuid: '',
            weight,
            description: '',
        })
    }

    const removeAnswer = (index: number) => {
        !!fields[index]?.uuid && appendDeletedAnswer(fields[index])
        remove(index)
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) return

        move(result.source.index, result.destination.index)
    }

    const onSubmit = async (payload: FormValues) => {
        const answers = payload.answers.map((el, idx) => ({ ...el, weight: idx }))
        payload?.emptyAnswer && answers.push(payload.emptyAnswer)
        try {
            if (!scaleItem) {
                await dispatch(cpaItemScaleActions.createNewScale({
                    title: payload.title,
                    answers,
                    entityType: 'custom',
                    typeUuid: cpaScaleNumericUuid,
                    answersUuidToDelete: payload.answersToDelete.map(el => el.uuid),
                }))
            } else {
                await dispatch(cpaItemScaleActions.updateScale({
                    ...scaleItem,
                    title: payload.title,
                    answers,
                    answersUuidToDelete: payload.answersToDelete.map(el => el.uuid),
                }))
            }
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogConfirm
                open={open}
                dialogTitle="Изменение шкалы оценивания"
                dialogText="Вы уверены, что хотите сохранить изменения?"
                titleButtonConfirm={TITLE_BUTTON_SAVE}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={handleSubmit(onSubmit)}
                handleClose={() => setOpen(false)}
            />
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Typography variant="h3">
                            {TITLE_NAMESPACE_SCALE_NAME}
                        </Typography>
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                ...fieldsRestriction.cpa.scale.title,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="title"
                                    name="title"
                                    inputProps={{
                                        maxLength: fieldsRestriction.cpa.scale.title.maxLength.value,
                                    }}
                                    disabled={isOrigin}
                                    required
                                    placeholder={TITLE_NAMESPACE_SCALE_NAME}
                                    error={!!errors.title}
                                    helperText={errors?.title ? errors.title.message : null}
                                />
                            )}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Typography variant="h3">
                            {TITLE_NAMESPACE_ANSWER_VARIANTS}
                        </Typography>
                        <GFAlertInfo
                            text="В шкале может быть от 2 до 10 ответов, не включая ответ «Не знаю»"
                        />
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Box>
                                    <ImportExportRounded color="disabled"/>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        Балл
                                    </Typography>
                                </Box>
                                <Box flex={1}>
                                    <Typography variant="body1">
                                        Текст ответа *
                                    </Typography>
                                </Box>
                                <Box flex={1}>
                                    <Typography variant="body1">
                                        Описание
                                    </Typography>
                                </Box>
                            </Stack>
                            <Divider/>
                            {watchEmptyAnswer && <>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#F8F8F8',
                                            borderRadius: 4,
                                            py: 1.25,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DragIndicatorRoundedIcon
                                            fontSize="small"
                                            color="action"
                                        />
                                    </Box>
                                    <Chip color="default" label="-"/>
                                    <Box flex={1}>
                                        <Typography variant="body2">
                                            {watchEmptyAnswer.title}
                                        </Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography variant="body2">
                                            {watchEmptyAnswer.description}
                                        </Typography>
                                    </Box>
                                    {!isOrigin && <IconButton color="primary" onClick={removeEmptyItem}>
                                        <CloseRounded/>
                                    </IconButton>}
                                </Stack>
                                <Divider/>
                            </>}
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable isDropDisabled={isOrigin} droppableId="droppable" direction="vertical">
                                    {(droppableProvided: DroppableProvided) => (
                                        <div
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps}
                                        >
                                            <Stack spacing={2} divider={<Divider/>}>
                                                {fields.map((field, index) => (
                                                    <Draggable
                                                        key={field.id}
                                                        draggableId={field.id}
                                                        index={index}
                                                        isDragDisabled={isOrigin}
                                                    >
                                                        {(
                                                            draggableProvided: DraggableProvided,
                                                            snapshot: DraggableStateSnapshot,
                                                        ) => {
                                                            return (
                                                                <Stack
                                                                    key={field.id}
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    style={{
                                                                        ...draggableProvided.draggableProps.style,
                                                                        background: snapshot.isDragging
                                                                            ? 'rgba(245,245,245, 0.75)'
                                                                            : 'none',
                                                                        height: 52,
                                                                    }}
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                >
                                                                    <Box
                                                                        {...draggableProvided.dragHandleProps}
                                                                        sx={{
                                                                            backgroundColor: '#F8F8F8',
                                                                            borderRadius: 4,
                                                                            py: 1.25,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            cursor: isOrigin ? undefined : 'grab',
                                                                        }}
                                                                    >
                                                                        <DragIndicatorRoundedIcon
                                                                            fontSize="small"
                                                                            color="action"
                                                                        />
                                                                    </Box>
                                                                    <Chip color="default" label={index}/>
                                                                    <Box flex={1}>
                                                                        <Controller
                                                                            name={`answers.${index}.title`}
                                                                            control={control}
                                                                            rules={{
                                                                                ...fieldsRestriction
                                                                                    .cpa
                                                                                    .scale
                                                                                    .answerTitle,
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <InputForm
                                                                                    {...field}
                                                                                    id={`title-${index}`}
                                                                                    placeholder="Текст ответа"
                                                                                    required
                                                                                    disabled={isOrigin}
                                                                                    type="text"
                                                                                    inputProps={{
                                                                                        maxLength: fieldsRestriction
                                                                                            .cpa
                                                                                            .scale
                                                                                            .answerTitle
                                                                                            .maxLength
                                                                                            .value,
                                                                                    }}
                                                                                    autoComplete="name"
                                                                                    error={!!errors
                                                                                        ?.answers
                                                                                        ?.[index]?.title}
                                                                                    helperText={
                                                                                        errors?.answers?.[index]?.title
                                                                                            ? errors
                                                                                                ?.answers
                                                                                                ?.[index]
                                                                                                ?.title
                                                                                                ?.message
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                    <Box flex={1}>
                                                                        <Controller
                                                                            name={`answers.${index}.description`}
                                                                            control={control}
                                                                            rules={{
                                                                                ...fieldsRestriction
                                                                                    .cpa
                                                                                    .scale
                                                                                    .description,
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <InputForm
                                                                                    {...field}
                                                                                    id={`description-${index}`}
                                                                                    variant="outlined"
                                                                                    inputProps={{
                                                                                        maxLength: fieldsRestriction
                                                                                            .cpa
                                                                                            .scale
                                                                                            .description
                                                                                            .maxLength
                                                                                            .value,
                                                                                    }}
                                                                                    disabled={isOrigin}
                                                                                    placeholder={
                                                                                        TITLE_FIELD_DESCRIPTION
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                    {!isOrigin &&
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => removeAnswer(index)}
                                                                        >
                                                                            <CloseRounded/>
                                                                        </IconButton>}
                                                                </Stack>
                                                            )
                                                        }}
                                                    </Draggable>
                                                ))}
                                                {droppableProvided.placeholder}
                                            </Stack>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {!isOrigin && <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Button
                                    variant="text"
                                    startIcon={<AddRounded/>}
                                    disabled={fields.length >= 10}
                                    onClick={addAnswer}
                                >
                                    Добавить ответ
                                </Button>
                                <FormControlLabel
                                    htmlFor="emptyItem"
                                    control={<Checkbox
                                        name="emptyItem"
                                        id="emptyItem"
                                        checked={!!watchEmptyAnswer}
                                        onChange={!watchEmptyAnswer ? addEmptyItem : removeEmptyItem}
                                    />}
                                    label="Ответ «Не знаю»"
                                />
                            </Box>}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    variant="text"
                    onClick={handleClose}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    disabled={fields.length < 2 || isOrigin}
                    type={scaleItem ? 'button' : 'submit'}
                    onClick={scaleItem ? () => setOpen(true) : undefined}
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Box>
        </Stack>

    )
}
