import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Card, CardContent, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_COURSES,
    TITLE_NAMESPACE_EMPLOYEE_EDIT_EDUCATION,
    TITLE_NAMESPACE_HIGH_EDUCATION,
} from 'helpers/enums/titles'
import { PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import { EditRounded } from '@mui/icons-material'
import { HINT_NO_DATA } from 'helpers/enums/hints'
import {
    EducationItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabEducation/components/EducationCard/components/EducationItem/EducationItem'
import {
    CourseItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabEducation/components/CoursesCard/components/CourseItem/CourseItem'
import { useFetch } from 'store/hooks/useFetch'
import { employeeActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'

type EmployeeTabInfoEducationProps = {
    employeeUuid: string;
    parentPath: string;
    hasNotPermissionEdit: boolean;
}

export const EmployeeTabInfoEducation: FC<EmployeeTabInfoEducationProps> = ({
    employeeUuid,
    parentPath,
    hasNotPermissionEdit,
}) => {
    const navigate = useNavigate()
    const {
        data: { educations },
    } = useAppSelector(state => state.employeeEditEducationEducationReducer)
    const {
        data: courses,
    } = useAppSelector(state => state.employeeEditEducationCoursesReducer)

    const { isFetching: loadingEducationList } =
        useFetch(employeeActions.getEducationList(employeeUuid), [employeeUuid])
    const { isFetching: loadingCourseList } =
        useFetch(employeeActions.getCourseList(employeeUuid), [employeeUuid])
    const loading = loadingEducationList || loadingCourseList

    if (loading) return <LoadingComponent />
    return (
        <Card>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h2" mr={2}>
                        {TITLE_NAMESPACE_EMPLOYEE_EDIT_EDUCATION}
                    </Typography>
                    <IconButton
                        color="primary"
                        disabled={hasNotPermissionEdit}
                        onClick={() => navigate(`${parentPath}/${PATH_EMPLOYEES_EDIT}/education`)}
                    >
                        <EditRounded />
                    </IconButton>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2} divider={<Divider sx={{ my: 2 }} />}>
                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_HIGH_EDUCATION}
                        </Typography>

                        {educations && educations.length > 0
                            ? <Stack spacing={2}>{educations.map(education => <EducationItem
                                key={education.uuid}
                                education={education}
                                pageViewEmployee
                                employeeUuid={employeeUuid}
                            />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>

                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_COURSES}
                        </Typography>
                        {courses && courses.length > 0
                            ? <Stack spacing={2}>{courses.map(course => <CourseItem
                                key={course.uuid}
                                course={course}
                                pageViewEmployee
                                employeeUuid={employeeUuid}
                            />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

