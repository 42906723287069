import { AxiosStatic } from 'axios'
import { TransferSpecialization } from 'api/services/SpecializationService/transferSpecialization'
import { IGradeUpdate, ISpecializationGradeOnePayload } from 'api/typing/specializationTypes'

export class SpecializationGradeService extends TransferSpecialization {
    private static instance: SpecializationGradeService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!SpecializationGradeService.instance)
            SpecializationGradeService.instance = new SpecializationGradeService(axiosInstance)
        return SpecializationGradeService.instance
    }

    public getSpecializationGrade(uuid: string) {
        const path = `/${uuid}/grade`
        return this.request<null, ISpecializationGradeItem[]>('GET', path)
    }

    public getSpecializationGradeMeta(specializationUuid: string) {
        const path = `/${specializationUuid}/grade/create/one`
        return this.request<null, ISpecializationGradeMeta>('GET', path)
    }

    public createSpecializationGrade(specializationUuid: string, data: ISpecializationGradeOnePayload) {
        const path = `/${specializationUuid}/grade/create/one`
        return this.request<ISpecializationGradeOnePayload, ISpecializationGradeMeta>('POST', path, { data })
    }

    public updateSpecializationGrade(
        specializationUuid: string,
        gradeUuid: string,
        data: ISpecializationGradeOnePayload,
    ) {
        const path = `/${specializationUuid}/grade/${gradeUuid}`
        return this.request<ISpecializationGradeOnePayload, ISpecializationGradeMeta>('PUT', path, { data })
    }

    public deleteSpecializationGrade(
        specializationUuid: string,
        gradeUuid: string,
    ) {
        const path = `/${specializationUuid}/grade/${gradeUuid}`
        return this.request<{ specializationUuid: string, gradeUuid: string, }, ISpecializationGradeMeta>('DELETE', path, {
            data: {
                specializationUuid,
                gradeUuid,
            },
        })
    }

    public getGrade(specializationUuid: string, gradeUuid: string) {
        const path = `/${specializationUuid}/grade/${gradeUuid}`
        return this.request<null, IGrade>('GET', path)
    }

    public updateGrade(specializationUuid: string, gradeUuid: string, data: IGradeUpdate) {
        const path = `/${specializationUuid}/grade/${gradeUuid}`
        return this.request<IGradeUpdate, IGrade>('PUT', path, { data })
    }

    public deleteGrade(
        specializationUuid: string,
        gradeUuid: string,
    ) {
        const path = `/${specializationUuid}/grade/${gradeUuid}`
        return this.request<{ specializationUuid: string, gradeUuid: string }, ISpecializationGradeMeta>('DELETE', path, {
            data: {
                specializationUuid,
                gradeUuid,
            },
        })
    }

    public getGradeDevelopmentPlan(specializationUuid: string, gradeUuid: string) {
        const path = `/${specializationUuid}/grade/${gradeUuid}/development-plan`
        return this.request<null, IGradeDevelopmentPlan>('GET', path)
    }

    public getGradeDevelopmentPlanCompetence(
        specializationUuid: string,
        gradeUuid: string,
        competenceUuid: string,
        entityType: string,
    ) {
        const path = `/${specializationUuid}/grade/${gradeUuid}/development-plan/competence/${competenceUuid}/${entityType}`
        return this.request<null, IGradeDevelopmentPlanCompetence>('GET', path)
    }

    public getGradeAssessment(specializationUuid: string, gradeUuid: string) {
        const path = `/${specializationUuid}/grade/${gradeUuid}/assessment`
        return this.request<null, IGradeAssessment>('GET', path)
    }
}
