import React, { FC } from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import {
    TITLE_FIELD_PLACEHOLDER_SEARCH_COMPETENCE_OR_GROUP,
} from 'helpers/enums/titles'
import { ClearRounded, SearchRounded } from '@mui/icons-material'

type CreatePdpCompetenceFiltersProps = {
    search: string;
    setSearch: (value: string) => void;
}

export const CreatePdpCompetenceFilters: FC<CreatePdpCompetenceFiltersProps> = ({ search, setSearch }) => (
    <Box flex={1} maxWidth={600}>
        <TextField
            fullWidth
            placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_COMPETENCE_OR_GROUP}
            InputProps={{
                endAdornment: search.length > 0
                    ? <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                    >
                        <ClearRounded color="disabled"/>
                    </IconButton>
                    : <SearchRounded color="disabled"/>,
            }}
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
        />
    </Box>
)
