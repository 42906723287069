import { AppDispatch } from 'store/store'
import { AnalyticService } from 'api/services/AnalyticService'
import { AnalyticSlice } from 'store/reducers/analytic/AnalyticSlice'

export class AnalyticAction {
    private static get analyticApi() {
        return new AnalyticService().analyticService()
    }

    public getAnalyticAssessment() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AnalyticSlice.actions.fetching())
                const data = await AnalyticAction.analyticApi.getAnalyticAssessment()
                await dispatch(AnalyticSlice.actions.setAnalyticAssessment(data))
                dispatch(AnalyticSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AnalyticSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getAnalyticPdp() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AnalyticSlice.actions.fetching())
                const data = await AnalyticAction.analyticApi.getAnalyticPdp()
                await dispatch(AnalyticSlice.actions.setAnalyticPdp(data))
                dispatch(AnalyticSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AnalyticSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
