import React, { FC, ReactNode, TransitionEventHandler } from 'react'
import { Box, Card, IconButton, Link, Stack, styled, Typography } from '@mui/material'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { DraggableId, DraggableProvided, DraggableStyle } from '@hello-pangea/dnd'
import { NavLink as RouterLink, useParams } from 'react-router-dom'
import { AddCommentOutlined, CommentOutlined, WarningAmberRounded } from '@mui/icons-material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { developmentPdpActions, pdpActions } from 'store/actions'
import {
    TITLE_DIALOG_PDP_ITEM_DELETE,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_USER_ACTIVITY,
} from 'helpers/enums/titles'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import {
    HINT_PDP_ITEM_DELETE,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
    HINT_SUCCESS_PDP_ITEM_DELETE,
} from 'helpers/enums/hints'
import {
    DialogEditPdpItemUnique,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewUnique/dialogs/DialogEditPdpItemUnique/DialogEditPdpItemUnique'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { GFEducationStatusProgress } from 'components/common/GFEducationStatusProgress/GFEducationStatusProgress'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useDesktop } from 'helpers/hooks/useDesktop'

type PdpPlanItemProps = {
    item: IPdpPlanItem;
    pdpStatus: IPdpStatus;
    style?: React.CSSProperties | DraggableStyle;
    isDevelopment?: boolean;
    draggableProvided?: DraggableProvided;
    'data-rfd-draggable-context-id'?: string;
    'data-rfd-draggable-id'?: DraggableId;
    onTransitionEnd?: TransitionEventHandler;
    lift: (value: string) => any;
    isControlDragging: boolean;
    firstItem: boolean;
    lastItem: boolean;
}

//TODO: need refactoring margin and paddings

export const PdpPlanItem = React.forwardRef<HTMLDivElement, PdpPlanItemProps>(function PdpPlanItem(inProps, ref) {
    const desktop = useDesktop()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { pdpUuid } = useParams()
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { pdp: pdpPdp } = useAppSelector(state => state.pdpReducer.data)
    const { pdp: developmentPdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const {
        draggableProvided,
        item,
        pdpStatus,
        isDevelopment,
        lift,
        isControlDragging,
        firstItem,
        lastItem,
        ...props
    } = inProps
    const isUnique = item.entityType === 'unique'
    const itemIdentity = isUnique ? item.entityType : 'competence'
    const { commentEdit, itemDelete, itemEdit, toggleDialog } = useDialogsState({
        commentEdit: false,
        itemDelete: false,
        itemEdit: false,
    })
    const pdp = pdpPdp || developmentPdp
    const employeeUuid = pdp?.employee?.uuid
    const itemPath = `item/${item.uuid}/${itemIdentity}`

    const deleteComment = async (uuid: string) => {
        if (!pdpUuid || !employeeUuid) return
        try {
            if (isDevelopment)
                await dispatch(developmentPdpActions.deletePdpItemComment({
                    uuid,
                    pdpUuid,
                    itemUuid: item.uuid,
                }))
            else
                await dispatch(pdpActions.deletePdpItemComment({
                    uuid,
                    pdpUuid,
                    itemUuid: item.uuid,
                    employeeUuid,
                }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createComment = async (comment: IComment) => {
        if (!pdpUuid || !employeeUuid) return
        try {
            if (isDevelopment)
                await dispatch(developmentPdpActions.createPdpItemComment({
                    ...comment,
                    pdpUuid,
                    itemUuid: item.uuid,
                }))
            else
                await dispatch(pdpActions.createPdpItemComment({
                    ...comment,
                    pdpUuid,
                    employeeUuid,
                    itemUuid: item.uuid,
                }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const onDeleteConfirm = async () => {
        if (!employeeUuid || !pdpUuid) return
        try {
            if (isDevelopment)
                await dispatch(developmentPdpActions.deletePdpItem({
                    pdpUuid,
                    itemUuid: item.uuid,
                }))
            else
                await dispatch(pdpActions.deleteItemPdp({
                    employeeUuid,
                    pdpUuid,
                    itemUuid: item.uuid,
                }))
            notify(HINT_SUCCESS_PDP_ITEM_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const WrapComponent: FC<{ children: ReactNode }> = ({ children }) => (
        !desktop ?
            <Card sx={{ p: 3, flex: 1 }}>{children}</Card>
            :
            <Box display="flex" alignItems="center" px={2} py={1} borderRadius={4}>
                {children}
            </Box>
    )

    const PdpPlanItemInfoWrapper: FC<{ children: ReactNode }> = ({ children }) => (
        !desktop ?
            <Stack direction='row' justifyContent={!desktop ? 'space-between' : 'start'} >{ children }</Stack>
            :
            <>{ children }</>
    )

    const GFPdpItem = styled(Box)(() => ({
        width: desktop ? 156 : 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }))

    return (
        <>
            <DialogConfirmDelete
                open={itemDelete}
                handleClose={() => toggleDialog('itemDelete', false)}
                onSubmit={onDeleteConfirm}
                title={TITLE_DIALOG_PDP_ITEM_DELETE}
                elementTitle={item.title}
                helperText={HINT_PDP_ITEM_DELETE}
                elementType={TITLE_NAMESPACE_PLAN_ITEM}
            />
            <DrawerComments
                service={isDevelopment ? undefined : 'pdp'}
                divisions={[employee?.division?.uuid]}
                open={commentEdit}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
                onDeleteComment={deleteComment}
                onCreateComment={createComment}
                handleClose={() => toggleDialog('commentEdit', false)}
            />
            {pdpUuid && itemEdit && employeeUuid && <DialogEditPdpItemUnique
                open={itemEdit}
                pdpUuid={pdpUuid}
                employee={pdp?.employee}
                isDevelopment={isDevelopment}
                item={item}
                handleClose={() => toggleDialog('itemEdit', false)}
            />}
            <Box
                ref={ref}
                display={!desktop ? 'flex' : 'block'}
                justifyContent='space-between'
                alignItems='center'
                {...props}
            >
                {!desktop &&
                    <Stack>
                        <IconButton
                            color='primary'
                            disabled={isControlDragging || firstItem}
                            onClick={() => {
                                const drag = lift(item.uuid)
                                drag.moveUp()
                                drag.drop()
                            }}
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                            color='primary'
                            disabled={isControlDragging || lastItem}
                            onClick={() => {
                                const drag = lift(item.uuid)
                                drag.moveDown()
                                drag.drop()
                            }}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    </Stack>
                }
                <WrapComponent>
                    {desktop &&
                        <Authenticated
                            service={isDevelopment ? undefined : 'pdp'}
                            accessLevel="w"
                            divisions={[employee?.division?.uuid]}
                        >
                            <Box
                                {...draggableProvided?.dragHandleProps}
                                display="flex"
                                alignSelf="stretch"
                                mr={2.5}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#F8F8F8',
                                        borderRadius: 4,
                                        py: 1.25,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <DragIndicatorRoundedIcon
                                        fontSize="small"
                                        color="action"
                                    />
                                </Box>
                            </Box>
                        </Authenticated>
                    }
                    {pdpStatus.status !== 'default' && <Box mr={3}>
                        <GFEducationStatusProgress
                            progress={item.progress}
                            entityType={item.entityType}
                            isPassed={item.isPassed}
                        />
                    </Box>}
                    <Stack direction="row" spacing={1} flex={1} alignItems="center">
                        <Box display="flex" flex={1} flexDirection="column">
                            <Box display="flex" sx={{ mb: 0.5 }} alignItems="center">
                                <Box flex={1} mr={1}>
                                    <Link component={RouterLink} to={itemPath} variant="body1">
                                        {item.title}
                                    </Link>
                                </Box>
                            </Box>
                            <Typography variant="body1" fontSize="smaller">
                                {item.typeTitle || TITLE_NAMESPACE_USER_ACTIVITY}
                            </Typography>
                        </Box>
                        <Stack spacing={1} direction="row" alignItems="center">
                            {item.materialsCount > 0 || item.entityType === 'unique' || isDevelopment
                                ? null
                                : <WarningAmberRounded sx={{ color: ({ palette }) => palette.warning.dark }}/>}
                            {!!item?.changeLog &&
                                <BadgeChangeLog changeLog={item.changeLog} evaluatedUuid={pdp?.employee?.uuid}/>}
                        </Stack>
                    </Stack>
                    <PdpPlanItemInfoWrapper>
                        <GFPdpItem>
                            {!desktop && <AccessTimeIcon color='disabled' sx={{ mr: 1 }} />}
                            <Typography variant={!desktop ? 'body1' : 'body2'}>
                                {formatHours(item.studyTime)}
                            </Typography>
                        </GFPdpItem>
                        <Box display='flex'>
                            <GFPdpItem>
                                <IconButton onClick={() => toggleDialog('commentEdit', true)}>
                                    {item.comments?.length > 0
                                        ? <CommentOutlined color="primary"/>
                                        : <AddCommentOutlined color={'action'}/>}
                                </IconButton>
                            </GFPdpItem>
                            <Authenticated
                                service={isDevelopment ? undefined : 'pdp'}
                                accessLevel="w"
                                divisions={[employee?.division?.uuid]}
                            >
                                <SecondaryActions
                                    onEdit={isUnique ? () => toggleDialog('itemEdit', true) : undefined}
                                    onDelete={() => toggleDialog('itemDelete', true)}
                                />
                            </Authenticated>
                        </Box>
                    </PdpPlanItemInfoWrapper>
                </WrapComponent>
            </Box>
        </>
    )
})
