import React, { FC, useMemo, useState } from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_INITIATOR } from 'helpers/enums/titles'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { DateRangeRounded } from '@mui/icons-material'
import { ChipStatus } from '../../../chips/ChipStatus/ChipStatus'
import { IExamListItem } from '../../../../api/typing/exam'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { addDays, isPast, parse } from 'date-fns'
import { ExamStartDialog } from '../../dialogs/ExamStartDialog/ExamStartDialog'
import {
    examStatusCancel,
    ExamStatusCode,
    examStatusComplete,
    getExamStatusChip,
} from '../../../../helpers/exam/examStatuses'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import { PATH_EXAM } from '../../../../helpers/enums/routePath'
import { ExamResultChip } from '../../ExamResultChip/ExamResultChip'
import { useAppSelector } from '../../../../store/hooks/redux'
import { MenuItemsCodes } from '../../../../helpers/enums/menuItemsPosition'
import { AlertType } from '../../../../store/hooks/useGetAlertsData'

interface ExamListItemProps {
    exam: IExamListItem;
}

export const ExamListItem: FC<ExamListItemProps> = ({ exam }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const isValidDate = isValidDateFromApi(exam?.endedAt)
    const examStatus = getExamStatusChip(exam.statusUuid)
    const completeStatus = examStatus.code === ExamStatusCode.DONE
    const cancelStatus = examStatus.code === ExamStatusCode.CANCEL
    const isExpire = isValidDate && !(completeStatus || cancelStatus) && isPast(addDays(parse(exam?.endedAt, 'dd.MM.yyyy', new Date()), 1))
    const isTestCanceled = exam.statusUuid === examStatusCancel

    const alerts = useAppSelector(state => state.alertsReducer)

    const myExamsAlert = useMemo(
        () => alerts[MenuItemsCodes.MY_EXAMS],
        [alerts],
    )

    const resultIconColor = useMemo(() => {
        switch (true) {
            case !exam.passMark:
                return 'primary'
            case isExpire:
                return 'warning'
            case exam.result && exam.result.isPassed:
                return 'success'
            case exam.result && !exam.result.isPassed:
                return 'error'
            default:
                return 'primary'
        }
    }, [isExpire, exam.result])

    const exmCompletionData = useMemo(
        () => exam.statusUuid === examStatusComplete ?
            {
                btnTitle: 'Дата завершения',
                date: exam.finishedAt,
            }
            :
            {
                btnTitle: 'Срок завершения',
                date: exam.endedAt,
            } ,
        [exam.statusUuid],
    )

    const clickOnExamItem = () => {
        switch (true) {
            case completeStatus:
                navigate(`${PATH_EXAM}/${exam.uuid}/result`)
                return
            case isTestCanceled:
                return
            default:
                setOpen(true)
                return
        }
    }

    return (
        <>
            {open &&
                <ExamStartDialog
                    open={open}
                    setOpen={setOpen}
                    examUuid={exam.uuid}
                    timeSpent={exam.timeSpent}
                    statusUuid={exam.statusUuid}
                    isExpire={isExpire}
                />
            }
            <Card
                sx={{
                    p: 3,
                    cursor: isTestCanceled ? 'initial' : 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 3,
                    border: myExamsAlert?.alertType !== AlertType.WAIT && isExpire ? `1px solid ${myExamsAlert?.badgeColor}` : 'none',
                }}
                onClick={clickOnExamItem}
            >
                <Stack spacing={1}>
                    <Typography variant='subtitle1' sx={{ wordBreak: 'break-word'}}>{exam.title}</Typography>
                    <Box display='flex' gap={1} alignItems='center'>
                        <AssignmentIcon color='action'/>
                        <Typography variant="body1">
                            {`${TITLE_NAMESPACE_INITIATOR} - ${exam?.author?.lastName} ${exam?.author?.firstName?.charAt(0)
                                .toUpperCase()}.`}
                        </Typography>
                    </Box>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography variant='body1'>{exmCompletionData.btnTitle}</Typography>
                        <Box  display='flex' gap={1} alignItems='center'>
                            <DateRangeRounded color={isExpire ? 'warning' : 'action'}/>
                            <Typography
                                variant='body1'
                                sx={{ color: ({ palette }) =>
                                    isExpire ? palette.warning.main : palette.text.disabled,
                                }}
                            >
                                {exmCompletionData.date}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={3}
                        minWidth={'198px'}
                    >
                        <ChipStatus status={getExamStatusChip(exam.statusUuid)} />
                        {exam.result ?
                            <ExamResultChip
                                examResult={exam.result}
                                passMark={exam.passMark}
                                endedAt={exam.endedAt}
                                statusUuid={exam.statusUuid}
                                onClickOnChip={clickOnExamItem}
                            />
                            :
                            <ArrowForwardIosIcon color={resultIconColor} sx={{width: '13px', height: '13px'}} />
                        }
                    </Stack>
                </Stack>
            </Card>
        </>
    )
}
