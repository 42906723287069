import React, { FC } from 'react'
import { Chip } from '@mui/material'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

type ChipAssessmentStatusProps = {
    status: string | undefined;
}

export const ChipAssessmentStatus: FC<ChipAssessmentStatusProps> = ({ status }) => {
    const assessmentStatus = getAssessmentStatus(status)
    if (!assessmentStatus) return null

    return (
        <Chip
            label={assessmentStatus.title}
            color={assessmentStatus.color}
            variant={assessmentStatus.chipVariant}
            icon={assessmentStatus.icon}
        />
    )
}
