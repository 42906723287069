import {
    CheckBoxOutlineBlankRounded,
    CheckRounded,
    ClearRounded,
    FactCheckRounded,
    FlagRounded,
    HourglassEmptyRounded,
    PersonRounded,
    SummarizeRounded,
} from '@mui/icons-material'
import {
    TITLE_NAMESPACE_AWAIT_COMPLETE,
    TITLE_NAMESPACE_AWAITING_CONFIRMATION,
    TITLE_NAMESPACE_AWAITING_ESTIMATION,
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_NOT_COMPLETE,
    TITLE_NAMESPACE_SUMMARIZING,
    TITLE_NAMESPACE_WAS_CREATED,
} from 'helpers/enums/titles'
import React, { JSXElementConstructor } from 'react'
import { emptyUuid } from 'helpers/isEmptyUuid'
import { HINT_NO_DATA } from 'helpers/enums/hints'

// export const statusCreated = '2d05afeb-cf9a-4bd3-a47a-d438611df8fd' // Создана
export const assessmentStatusCreated = emptyUuid // Создана
export const assessmentStatusAwaitingEstimation = '380574cf-60ee-410d-b8ee-3bf394a80b74' // Ожидает самооценки
export const assessmentStatusAwaitingConfirmation = '6bd91a54-1f72-4c20-8ac8-d7c8b12bda21' // Ожидает подтверждения итогов -> Ожидает итогов
export const assessmentStatusSummarizing = 'b27528ad-12db-4660-b5e1-30eaf28509a9' // Подведение итогов
export const assessmentStatusComplete = 'd6b80f0d-85f7-44bd-b351-b6604f59dbf0' // Завершен
export const assessmentStatusNotComplete = '9aa3fb82-4acf-4eb8-823e-31779b376c3d' // ,Не завершена
export const assessmentStatusAwaitComplete = 'b7c18702-b483-43ac-9540-c0e9884cc369' // ,Ожидает завершения
export const assessmentStatusNoData = 'fb03a318-b46f-43fa-b7a2-a31784d748bd' // ,Нет данных

export const assessmentStatuses: AssessmentStatus[] = [
    assessmentStatusCreated,
    assessmentStatusAwaitingEstimation,
    assessmentStatusAwaitingConfirmation,
    assessmentStatusSummarizing,
    assessmentStatusComplete,
    assessmentStatusNotComplete,
    assessmentStatusAwaitComplete,
    assessmentStatusNoData,
]

export type AssessmentStatusCode = 'created'
    | 'estimation'
    | 'confirmation'
    | 'summarizing'
    | 'complete'
    | 'notComplete'
    | 'awaitComplete'
    | 'noData'

export type AssessmentStatus = typeof assessmentStatusCreated
    | typeof assessmentStatusAwaitingEstimation
    | typeof assessmentStatusAwaitingConfirmation
    | typeof assessmentStatusSummarizing
    | typeof assessmentStatusComplete
    | typeof assessmentStatusNotComplete
    | typeof assessmentStatusAwaitComplete
    | typeof assessmentStatusNoData

export interface IAssessmentStatusItem {
    uuid: AssessmentStatus;
    color: 'default' | 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info';
    chipVariant: 'outlined' | 'filled';
    code: AssessmentStatusCode;
    title: string;
    // icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & { muiName: string } | StyledComponent<any>;
    icon: React.ReactElement<any, string | JSXElementConstructor<any>>;
}

const assessmentChipStatuses: Record<AssessmentStatus, IAssessmentStatusItem> = {
    [assessmentStatusCreated]: {
        code: 'created',
        color: 'default',
        chipVariant: 'filled',
        icon: React.createElement(SummarizeRounded),
        title: TITLE_NAMESPACE_WAS_CREATED,
        uuid: assessmentStatusCreated,
    },
    [assessmentStatusAwaitingEstimation]: {
        code: 'estimation',
        color: 'primary',
        chipVariant: 'outlined',
        icon: React.createElement(PersonRounded),
        title: TITLE_NAMESPACE_AWAITING_ESTIMATION,
        uuid: assessmentStatusAwaitingEstimation,
    },
    [assessmentStatusAwaitingConfirmation]: {
        code: 'confirmation',
        color: 'warning',
        chipVariant: 'outlined',
        icon: React.createElement(HourglassEmptyRounded),
        title: TITLE_NAMESPACE_AWAITING_CONFIRMATION,
        uuid: assessmentStatusAwaitingConfirmation,
    },
    [assessmentStatusSummarizing]: {
        code: 'summarizing',
        color: 'warning',
        chipVariant: 'outlined',
        icon: React.createElement(FactCheckRounded),
        title: TITLE_NAMESPACE_SUMMARIZING,
        uuid: assessmentStatusSummarizing,
    },
    [assessmentStatusComplete]: {
        code: 'complete',
        color: 'success',
        chipVariant: 'filled',
        icon: React.createElement(CheckRounded),
        title: TITLE_NAMESPACE_COMPLETE,
        uuid: assessmentStatusComplete,
    },

    [assessmentStatusNotComplete]: {
        code: 'notComplete',
        color: 'default',
        chipVariant: 'filled',
        icon: React.createElement(ClearRounded),
        title: TITLE_NAMESPACE_NOT_COMPLETE,
        uuid: assessmentStatusNotComplete,
    },

    [assessmentStatusAwaitComplete]: {
        code: 'awaitComplete',
        color: 'warning',
        chipVariant: 'outlined',
        icon: React.createElement(FlagRounded),
        title: TITLE_NAMESPACE_AWAIT_COMPLETE,
        uuid: assessmentStatusAwaitComplete,
    },

    [assessmentStatusNoData]: {
        code: 'noData',
        color: 'default',
        chipVariant: 'outlined',
        icon: React.createElement(CheckBoxOutlineBlankRounded),
        title: HINT_NO_DATA,
        uuid: assessmentStatusNoData,
    },
}

export const assessmentStatusesEntity: IEntity[] = assessmentStatuses.map(el => {
    return { uuid: assessmentChipStatuses[el].uuid, title: assessmentChipStatuses[el].title }
})

export const getAssessmentStatus = (status?: string) => assessmentChipStatuses[status as AssessmentStatus]
    ? assessmentChipStatuses[status as AssessmentStatus]
    : null


export const getNextAssessmentStatus = (statusUuid: string) => {
    const statuses = [
        assessmentStatusCreated,
        assessmentStatusAwaitingEstimation,
        assessmentStatusAwaitingConfirmation,
        assessmentStatusSummarizing,
        assessmentStatusComplete,
    ]
    const currentIndex = statuses.findIndex(el => el === statusUuid)

    return currentIndex === statuses.length - 1 ? statusUuid : statuses[currentIndex + 1]
}
