import React, { FC } from 'react'
import { Card, CardContent, Stack, Typography, Divider, IconButton } from '@mui/material'
import {
    TITLE_FIELD_DATE_OF_BIRTH,
    TITLE_NAMESPACE_EMAIL,
    TITLE_NAMESPACE_EMPLOYEE_EDIT_PERSONAL_DATA, TITLE_NAMESPACE_PHONE_NUMBER,
} from 'helpers/enums/titles'
import { CakeRounded, EditRounded, EmailRounded, LocalPhoneRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import { useAppSelector } from 'store/hooks/redux'
import { getYearsOld } from 'helpers/dateAndTime/getYearsOld'


type EmployeeTabInfoPersonalDataProps = {
    parentPath: string;
    hasNotPermissionEdit: boolean;
}

export const EmployeeTabInfoPersonalData: FC<EmployeeTabInfoPersonalDataProps> = ({
    parentPath,
    hasNotPermissionEdit,
}) => {
    const { data: { employee } } = useAppSelector(state => state.employeeReducer)
    const navigate = useNavigate()
    const employeeYears = getYearsOld(employee.dateOfBirth)

    return (
        <Card>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h2">
                        {TITLE_NAMESPACE_EMPLOYEE_EDIT_PERSONAL_DATA}
                    </Typography>
                    <IconButton
                        color="primary"
                        disabled={hasNotPermissionEdit}
                        onClick={() => navigate(`${parentPath}/${PATH_EMPLOYEES_EDIT}/personal-data`)}
                    >
                        <EditRounded />
                    </IconButton>
                </Stack>

                <Divider sx={{ my: 2 }} />
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <CakeRounded sx={{ color: ({ palette }) => palette.text.secondary }} />
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            {TITLE_FIELD_DATE_OF_BIRTH}:
                        </Typography>
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            {employee?.dateOfBirth} {employeeYears && `(${employeeYears})`}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <EmailRounded sx={{ color: ({ palette }) => palette.text.secondary }} />
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            {TITLE_NAMESPACE_EMAIL}:
                        </Typography>
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            {employee?.email}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <LocalPhoneRounded sx={{ color: ({ palette }) => palette.text.secondary }} />
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            {TITLE_NAMESPACE_PHONE_NUMBER}:
                        </Typography>
                        <Typography variant={employee?.phone ? 'body2' : 'body1'} sx={{ alignSelf: 'center' }}>
                            {employee?.phone ? employee.phone : '+ _ (___) ___-__-__'}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}
