import React, { FC, useEffect } from 'react'
import { Box, Button, Card, CardContent, Divider, Stack, useMediaQuery, useTheme } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import {
    TITLE_BUTTON_ASSESSMENT_RESULT_DETAILED_RESULT,
    TITLE_BUTTON_BACK_HISTORY,
    TITLE_BUTTON_BACK_TO_ASSESSMENT,
} from 'helpers/enums/titles'
import { useNavigate, useParams } from 'react-router-dom'
import { AssessmentResult } from 'components/assessment/AssessmentResult/AssessmentResult'
import { FactCheckRounded } from '@mui/icons-material'
import { useAppDispatch } from 'store/hooks/redux'
import {appAction} from 'store/actions'

type HistoryAssessmentResult = {
    parentPath: string;
}

export const HistoryAssessmentResult: FC<HistoryAssessmentResult> = ({ parentPath }) => {
    const dispatch = useAppDispatch()
    const { assessmentUuid } = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const gtLaptop = useMediaQuery(theme.breakpoints.up('laptop'))
    useEffect(() => {
        dispatch(appAction.setBackButtonPath(parentPath))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [])

    return (
        <Box>
            {gtLaptop && <Button
                sx={{ mb: 3 }}
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate(parentPath, { replace: true })}
            >
                {TITLE_BUTTON_BACK_HISTORY}
            </Button>}
            <Card>
                <CardContent>
                    <Button
                        sx={{ mb: 3 }}
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={() => navigate(`${parentPath}/assessment/${assessmentUuid}`, { replace: true })}
                    >
                        {TITLE_BUTTON_BACK_TO_ASSESSMENT}
                    </Button>
                    <Stack spacing={4}>
                        <AssessmentResult>
                            <Stack spacing={4} divider={<Divider/>}>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        startIcon={<FactCheckRounded/>}
                                        onClick={() => navigate(`${parentPath}/assessment/${assessmentUuid}/pass`)}
                                    >
                                        {TITLE_BUTTON_ASSESSMENT_RESULT_DETAILED_RESULT}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="text"
                                        startIcon={<ArrowBackRoundedIcon/>}
                                        sx={{ mt: 4 }}
                                        onClick={() => navigate(
                                            `${parentPath}/assessment/${assessmentUuid}`,
                                            { replace: true },
                                        )}
                                    >
                                        {TITLE_BUTTON_BACK_TO_ASSESSMENT}
                                    </Button>
                                </Box>
                            </Stack>
                        </AssessmentResult>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}
