import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tab, Tabs } from '@mui/material'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { TabPanel } from 'components/tabs/TabPanel/TabPanel'
import {
    TITLE_NAMESPACE_EMPLOYEES_CURRENT,
} from 'helpers/enums/titles'
import { EmployeesTabCurrent } from 'pages/employees/EmployeesPage/components/EmployeesTabCurrent/EmployeesTabCurrent'

const tabsValues = ['current', 'reserve', 'ex']

export const EmployeesTabs = () => {
    const { tabPanel } = useParams()
    const navigate = useNavigate()

    const [value, setValue] = useState(tabsValues.findIndex(el => el === tabPanel) < 0
        ? 0
        : tabsValues.findIndex(el => el === tabPanel))

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        navigate(`${PATH_EMPLOYEES}/${tabsValues[newValue] || tabsValues[0]}`)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={TITLE_NAMESPACE_EMPLOYEES_CURRENT} />
                    {/*<Tab label={TITLE_NAMESPACE_EMPLOYEES_EX}/>*/}
                    {/*<Tab label={TITLE_NAMESPACE_EMPLOYEES_RESERVE}/>*/}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <EmployeesTabCurrent />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/*{specializationUuid && <SpecializationGrade specializationUuid={specializationUuid}/>}*/}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/*{specializationUuid && <SpecializationGrade specializationUuid={specializationUuid}/>}*/}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {/*{specializationUuid && <SpecializationGrade specializationUuid={specializationUuid}/>}*/}
            </TabPanel>
        </Box>
    )
}

