export const sortArrayFromSortIndex = <T extends Array<any>>(arr: T) =>
    [...arr].sort((a, b) => a.sortIndex - b.sortIndex)

export const sortArrayFromSortIndexAndTitle = <T extends Array<any>>(arr: T) =>
    [...arr].sort((a, b) => a.sortIndex - b.sortIndex || a?.title?.localeCompare(b?.title))

export const sortArrayFromField = <T extends Array<any>>(arr: T, fieldName: keyof T[0]) =>
    [...arr]?.sort((a, b) => typeof a[fieldName] === 'number'
        ? a[fieldName] - b[fieldName]
        : a?.[fieldName]?.localeCompare(b?.[fieldName]))
