import React, { FC } from 'react'
import {
    CPAEmployeeDrawerPreviewAssessmentHeader,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentHeader/CPAEmployeeDrawerPreviewAssessmentHeader'
import { useAppSelector } from 'store/hooks/redux'
import { useCpaPreviewAssessment } from 'api/queries/cpa/cpaQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Stack } from '@mui/material'
import {
    CPAEmployeeDrawerPreviewAssessmentCompetencies,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentCompetencies/CPAEmployeeDrawerPreviewAssessmentCompetencies'

type CPAEmployeeDrawerPreviewAssessmentProps = {
    employee: ICpaItemDivisionEmployee | ICpaEmployee;
    cpaUuid: string;
}

export const CPAEmployeeDrawerPreviewAssessment: FC<CPAEmployeeDrawerPreviewAssessmentProps> = ({
    employee,
    cpaUuid,
}) => {
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const { isFetching, data, error } = useCpaPreviewAssessment({
        cpaUuid,
        employeeUuid: employee.uuid,
        criteriaUuid: currentCriteriaUuid,
    })

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    if (!data) return null

    return (
        <Stack spacing={4}>
            <CPAEmployeeDrawerPreviewAssessmentHeader employee={employee} indicatorsCount={data.indicatorsCount}/>
            <CPAEmployeeDrawerPreviewAssessmentCompetencies competencies={data.competencies}/>
        </Stack>
    )
}
