import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { FormRecover } from 'pages/login/LoginPage/components/FormRecover/FormRecover'
import { Box, Stack } from '@mui/material'
import {
    FormLoginFromCredentials,
} from 'pages/login/LoginPage/components/FormLoginFromCredentials/FormLoginFromCredentials'
import {
    FormLoginForgotPassword,
} from 'pages/login/LoginPage/components/FormLoginForgotPassword/FormLoginForgotPassword'
import logo from 'assets/icons/logo.svg'


const LoginPage: FC = () => {
    return (
        <Box
            component="main"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            // height="100vh"
            // maxHeight="100dvh"
            minHeight="100dvh"
            sx={{
                background: 'linear-gradient(137deg, #116F84 9.55%, #84C4D3 87.46%)',
            }}
        >
            <Stack
                spacing={4}
                sx={{
                    padding: 4,
                    maxWidth: 450,
                    width: '100%',
                    background: (theme) => theme.palette.common.white,
                    border: '1px solid #F2F8FB',
                    borderRadius: 4,
                }}
            >
                <img src={logo} height={64} alt="Grade Factor logo"/>
                <Routes>
                    <Route path="/" element={<FormLoginFromCredentials/>}/>
                    <Route path="/forgotten" element={<FormLoginForgotPassword/>}/>
                    <Route path="/recover/:hash" element={<FormRecover/>}/>
                    <Route path="/*" element={<FormLoginFromCredentials/>}/>
                </Routes>
            </Stack>
        </Box>

    )
}

export default LoginPage
