import React, { useState } from 'react'
import { EditRounded } from '@mui/icons-material'
import { TITLE_BUTTON_CHANGE, TITLE_BUTTON_SELECT } from 'helpers/enums/titles'
import { Button } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import {
    CPAScaleDrawer,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/CPAScaleDrawer'

export const CPAScaleAction = () => {
    const currentScaleUuid = useAppSelector(state => state.CPAItemScaleReducer.currentScaleUuid)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const [open, setOpen] = useState(false)

    if (cpaStatusCode !== 'created' && cpaStatusCode !== 'ready') return null
    return (
        <>
            {open && <CPAScaleDrawer open={open} handleClose={() => setOpen(false)}/>}
            <Button
                variant="outlined"
                startIcon={<EditRounded/>}
                onClick={() => setOpen(true)}
            >
                {currentScaleUuid && !isEmptyUuid(currentScaleUuid) ? TITLE_BUTTON_CHANGE : TITLE_BUTTON_SELECT}
            </Button>
        </>
    )
}
