import { LoggerFactory } from 'utils/logger'

const logger = LoggerFactory.createLogger()

interface IQueueItem {
    func: () => Promise<void>;
    uuid: string;
}

export interface IQueueRequests {
    enqueue: (func: () => Promise<void>, uuid: string) => void;
    dequeue: () => void;
}

export class QueueRequests implements IQueueRequests {
    // Идентификатор очереди
    private id: string
    // Длина запросов в очереди
    private length: number
    // Состояние очереди
    private isBusy: boolean
    // Элементы очереди
    private queue: IQueueItem[] = []

    // Инициализация очереди
    constructor(uuid: string) {
        this.id = uuid
        this.length = 0
        this.isBusy = false
    }

    // Добавление элементов в очередь запросов
    public enqueue(func: () => Promise<void>, uuid: string) {
        const sameItemIndex = this.queue.findIndex(el => el.uuid === uuid)
        // В случае, если элемент уже есть в очереди, необходимо удалить старый
        if (sameItemIndex >= 0) {
            this.queue.splice(sameItemIndex, 1)
        }

        // Добавляем элемент в очередь
        this.queue.push({ func, uuid })

        // Запуск очереди
        this.startQueue()
    }

    // Удаляем элемент из очереди
    public dequeue() {
        this.queue.shift()
    }

    // получить длину очереди
    public getLength() {
        return this.queue.length
    }

    // Начало прохода по очереди
    private startQueue() {
        // если нет элементов или очередь занята - ничего не делаем
        if (this.queue.length === 0 || this.isBusy) return

        // Выполняем действие очереди
        this.execute().catch(e => logger.error(e))
    }

    private async execute() {
        try {
            // занимаем очередь
            this.isBusy = true
            // выполняем функцию первого элемента
            await this.queue[0].func()
            // удаляем первый элемент из очереди
            this.dequeue()
        } catch (e: any) {
            logger.error(e)
        } finally {
            // Убираем флаг занятости
            this.isBusy = false
            // Продолжаем выполнение очереди
            this.startQueue()
        }
    }
}

// Контроллер по работе с очередями
export class QueueQueueRequestsController {
    private static instance: QueueQueueRequestsController
    // Храним инстансы очередей по ключу
    private queueList: Map<string, QueueRequests> = new Map()

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    public static getInstance() {
        if (!QueueQueueRequestsController.instance)
            QueueQueueRequestsController.instance = new QueueQueueRequestsController()
        return QueueQueueRequestsController.instance
    }

    // Добавляем новую очередь
    public addNewQueue(queueId: string | undefined) {
        if (!queueId) {
            logger.error(`incorrect queue id, expected "string", but got  "${typeof queueId}" queueId = ${queueId}`)
            return undefined
        }
        // если очередь уже существует, возвращаем ее инстанс
        if (this.queueList.has(queueId))
            return this.queueList.get(queueId)

        const queue = new QueueRequests(queueId)
        this.queueList.set(queueId, queue)

        return queue
    }

    public removeQueue(queueId: string) {
        this.queueList.delete(queueId)
    }

}
