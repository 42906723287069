import React from 'react'
import {
    TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE,
    TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY,
} from 'helpers/enums/titles'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { useAppSelector } from 'store/hooks/redux'
import {
    DevelopmentPlanCompetenceStepper,
} from 'pages/openAccess/OpenAccessPage/components/OAPdpPage/components/DevelopmentPlanPage/components/DevelopmentPlanCompetenceStepper/DevelopmentPlanCompetenceStepper'

export const DevelopmentPlanPage = () => {
    const { developmentPlan } = useAppSelector(state => state.openAccessDevelopmentPlanReducer.data)

    return (
        <Box>
            <Card>
                <CardContent sx={{ p: 4 }}>
                    <Box sx={{ mb: 4 }}>
                        <PointPositionSpecializationGrade
                            title={developmentPlan?.currentPosition}
                        />
                    </Box>
                    <Box>
                        <Box mb={2} display="flex" justifyContent="space-between">
                            <Box flex="1" display="flex" justifyContent="space-between">
                                <Stack direction="row" spacing={2}>
                                    <Box padding={0.8}>
                                        <Typography variant="body1">
                                            №
                                        </Typography>
                                    </Box>
                                    <Box padding={0.8}>
                                        <Typography variant="body1">
                                            {TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box display="flex">
                                    <Typography variant="body1">
                                        {TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ ml: 5, mb: 1 }} />
                        {developmentPlan?.competencies &&
                            <DevelopmentPlanCompetenceStepper competencies={developmentPlan?.competencies} />}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}
