import { TransferForm } from 'api/services/FormService/transferForm'
import { IGetCpaScale } from 'api/typing/formTypes'

export class CpaFormService extends TransferForm {
    private prefix = 'cpa'

    public getCpaCriteria() {
        const path = `/${this.prefix}/criteria/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getCpaCriteriaCompetencies() {
        const path = `/${this.prefix}/criteria/competence/all`
        return this.request<null, ICpaCriteriaCompetenceGroup[]>('GET', path)
    }

    public getCpaScales() {
        const path = `/${this.prefix}/scale/all`
        return this.request<null, IGetCpaScale[]>('GET', path)
    }
}
