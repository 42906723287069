import { AxiosStatic } from 'axios'
import { TransferSpecialization } from 'api/services/SpecializationService/transferSpecialization'
import { ISpecializationListOnePayload } from 'api/typing/specializationTypes'

export class SpecializationListService extends TransferSpecialization {
    private static instance: SpecializationListService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!SpecializationListService.instance)
            SpecializationListService.instance = new SpecializationListService(axiosInstance)
        return SpecializationListService.instance
    }

    public getMetaForCreateOne() {
        return this.request<null, ISpecializationMetaForCreate>('GET', 'create/one')
    }

    public createOne(data: ISpecializationListOnePayload) {
        return this.request<ISpecializationListOnePayload, null>('POST', 'create/one', { data })
    }

    public updateOne(data: ISpecializationListOnePayload) {
        const path = `/${data.specializationUuid}`
        return this.request<ISpecializationListOnePayload, null>('PUT', path, { data })
    }

    public deleteOne(data: ISpecializationListOnePayload) {
        const path = `/${data.specializationUuid}`
        return this.request<ISpecializationListOnePayload, null>('DELETE', path, { data })
    }

    public getList() {
        return this.request<null, ISpecializationListDivision[]>('GET', '/list/all')
    }

    public getSpecialization(uuid: string) {
        return this.request<null, ISpecialization>('GET', `/${uuid}`)
    }
}
