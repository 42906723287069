import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Divider, Stack, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    PdpPlanItem,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/PdpPlanItem/PdpPlanItem'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
    PreDragActions,
    SensorAPI,
    SnapDragActions,
} from '@hello-pangea/dnd'
import { developmentPdpActions, pdpActions } from 'store/actions'
import { sortArrayFromSortIndexAndTitle } from 'helpers/sortArray'
import { useNotify } from 'store/hooks/useNotify'
import { useParams } from 'react-router-dom'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { ColorPartial } from '@mui/material/styles/createPalette'
import { useDesktop } from 'helpers/hooks/useDesktop'

type PdpPlanListItemsProps = {
    isDevelopment?: boolean
}

export const PdpPlanListItems: FC<PdpPlanListItemsProps> = ({ isDevelopment }) => {
    const desktop = useDesktop()
    const dispatch = useAppDispatch()
    const { pdpUuid } = useParams()
    const theme = useTheme()
    const notify = useNotify()
    const { pdp: pdpPdp } = useAppSelector(state => state.pdpReducer.data)
    const { pdp: developmentPdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const pdp = useMemo(() => pdpPdp ?? developmentPdp, [ pdpPdp, developmentPdp ])
    const employeeUuid = pdp?.employee?.uuid

    const [ bufferArray, setBufferArray ] = useState(pdp?.planItems || [])

    const pdpStatus = useMemo(() => getPdpStatus(pdp?.status), [ pdp ])

    useEffect(() => {
        setBufferArray(pdp?.planItems || [])
    }, [ pdpPdp, pdpPdp?.planItems, developmentPdp, developmentPdp?.planItems, pdp?.planItems, pdp?.planItems?.length ])

    const sortedArray = useMemo(() => sortArrayFromSortIndexAndTitle(bufferArray).map((el, idx) => ({
        ...el,
        sortIndex: idx,
    })), [ bufferArray ])

    const handleDragEnd = async (result: DropResult) => {
        setIsControlDragging(false)

        if (!result.destination
            || result.destination.index === result.source.index
            || !employeeUuid
            || !pdpUuid
        ) return

        const newItemsArray = [ ...sortedArray ]
        const destinationItem = newItemsArray[result.destination.index]

        const startIndex = Math.min(result.destination.index, result.source.index)
        const endIndex = Math.max(result.destination.index, result.source.index)

        for (let i = startIndex; i <= endIndex; i++) {
            let delta = 0
            result.source.index > result.destination.index ? delta = 1 : delta = -1
            if (i === result.source.index) {
                newItemsArray[i] = {
                    ...newItemsArray[i],
                    sortIndex: destinationItem.sortIndex,
                }
                continue
            }
            newItemsArray[i] = {
                ...newItemsArray[i],
                sortIndex: sortedArray[i + delta].sortIndex,
            }
        }

        try {
            await setBufferArray(newItemsArray)
            if (isDevelopment)
                void await dispatch(developmentPdpActions.changeOrderPdpItems({
                    pdpUuid,
                    items: newItemsArray.map(({ uuid, sortIndex }) => ({ uuid, sortIndex })),
                    newItemsArray,
                }))
            else
                void await dispatch(pdpActions.changeOrderItemPdp({
                    pdpUuid,
                    items: newItemsArray.map(({ uuid, sortIndex }) => ({ uuid, sortIndex })),
                    employeeUuid,
                    newItemsArray,
                }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const sensorAPIRef = useRef<SensorAPI | undefined | null>(null)
    const [ isControlDragging, setIsControlDragging ] = useState(false)

    function lift(quoteId: string): SnapDragActions | undefined | null {
        setIsControlDragging(true)
        const api: SensorAPI | undefined | null = sensorAPIRef.current

        if (!api) {
            return null
        }

        const preDrag: PreDragActions | undefined | null = api.tryGetLock(quoteId)

        if (!preDrag) {
            return null
        }
        return preDrag.snapLift()
    }

    return (
        <DragDropContext
            onDragEnd={handleDragEnd}
            sensors={[
                (api) => {
                    sensorAPIRef.current = api
                },
            ]}
        >
            <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                    <div
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        <Stack
                            spacing={!desktop ? 3 : 0.5}
                            divider={!desktop ? null : <Divider/>}
                        >
                            {sortedArray?.map((item, index) => (
                                <Draggable
                                    key={item.uuid}
                                    draggableId={item.uuid}
                                    index={index}
                                >
                                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                                        return (
                                            <PdpPlanItem
                                                ref={draggableProvided.innerRef}
                                                item={item}
                                                pdpStatus={pdpStatus}
                                                isDevelopment={isDevelopment}
                                                {...draggableProvided.draggableProps}
                                                draggableProvided={draggableProvided}
                                                lift={lift}
                                                isControlDragging={isControlDragging}
                                                firstItem={index === 0}
                                                lastItem={index === sortedArray.length - 1}
                                                style={{
                                                    ...draggableProvided.draggableProps.style,
                                                    background: snapshot.isDragging
                                                        ? 'rgba(245,245,245, 0.75)'
                                                        : (item.materialsCount > 0 || isDevelopment || item.entityType === 'unique')
                                                            ? 'none'
                                                            : (theme.palette.warning as ColorPartial)[50],
                                                }}
                                            />
                                        )
                                    }}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </Stack>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
