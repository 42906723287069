import React from 'react'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import {
    EmployeeAssessmentPreviewIndicator,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/components/EmployeeAssessmentPreviewContentCompetencies/components/EmployeeAssessmentPreviewIndicator/EmployeeAssessmentPreviewIndicator'

export const EmployeeAssessmentPreviewContentCompetencies = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    return (
        <Stack spacing={8}>
            {assessment?.competencies?.map(competence => <Stack
                key={competence?.uuid}
                spacing={3}
            >
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Box>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography variant="caption">
                                {competence.typeTitle}
                            </Typography>
                        </Stack>
                        <Typography variant="h3">
                            {competence.title}
                        </Typography>
                    </Box>
                </Box>
                <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                    <Stack spacing={2} divider={<Divider/>}>
                        {competence.indicators.map((indicator, idx) => <EmployeeAssessmentPreviewIndicator
                            key={indicator.uuid}
                            idx={idx + 1}
                            indicator={indicator}
                        />)}
                    </Stack>
                </Paper>
            </Stack>)}
        </Stack>
    )
}
