import { useState } from 'react'

export function useDialogsState<T extends Record<string, boolean>>(dialogsState: T) {
    const [dialogs, setDialogs] = useState<T>(dialogsState)
    // TODO: need refactoring types for use array of keys
    // type DialogState = Record<typeof dialogKeys[number], boolean>
    // const [dialogs, setDialogs] = useState<DialogState>(
    //     dialogKeys.reduce((a, v) => ({ ...a, [v]: false }), {} as DialogState),
    // )

    const toggleDialog = (name: keyof typeof dialogs, state: boolean) => {
        setDialogs({
            ...dialogs,
            [name]: state,
        })
    }

    return { ...dialogs, dialogs, toggleDialog }
}
