import React, { FC } from 'react'
import { getCpaCriteria } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { Typography } from '@mui/material'
import { TITLE_NAMESPACE_NOT_SELECTED_YET } from 'helpers/enums/titles'
import {
    CPAEmployeeDrawerPreviewAssessmentCriteriaCompetence,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentHeader/components/CPAEmployeeDrawerPreviewAssessmentCriteria/components/CPAEmployeeDrawerPreviewAssessmentCriteriaCompetence/CPAEmployeeDrawerPreviewAssessmentCriteriaCompetence'
import {
    CPAEmployeeDrawerPreviewAssessmentCriteriaCurrent,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentHeader/components/CPAEmployeeDrawerPreviewAssessmentCriteria/components/CPAEmployeeDrawerPreviewAssessmentCriteriaCurrent/CPAEmployeeDrawerPreviewAssessmentCriteriaCurrent'
import {
    CPAEmployeeDrawerPreviewAssessmentCriteriaPoint,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentHeader/components/CPAEmployeeDrawerPreviewAssessmentCriteria/components/CPAEmployeeDrawerPreviewAssessmentCriteriaPoint/CPAEmployeeDrawerPreviewAssessmentCriteriaPoint'

type CPAEmployeeDrawerPreviewAssessmentCriteriaProps = {
    criteriaUuid: string;
    specialization: IEntity | null | undefined;
    grade: IEntity | null | undefined;
}

export const CPAEmployeeDrawerPreviewAssessmentCriteria: FC<CPAEmployeeDrawerPreviewAssessmentCriteriaProps> = ({
    criteriaUuid,
    specialization,
    grade,
}) => {
    const currentCriteria = getCpaCriteria(criteriaUuid)

    switch (currentCriteria?.code) {
        case 'current':
            return <CPAEmployeeDrawerPreviewAssessmentCriteriaCurrent grade={grade} specialization={specialization}/>
        case 'point':
            return <CPAEmployeeDrawerPreviewAssessmentCriteriaPoint grade={grade} specialization={specialization}/>
        case 'competence':
            return <CPAEmployeeDrawerPreviewAssessmentCriteriaCompetence/>
        default:
            return <Typography variant="body1" sx={{ color: (theme) => theme.palette.warning.main }}>
                {TITLE_NAMESPACE_NOT_SELECTED_YET}
            </Typography>
    }
}
