import React, { FC } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import { useMobile } from 'helpers/hooks/useMobile'

type GFPopoverOnSkeletonProps = {
    icon?: React.ReactNode;
    title: string;
    text: string;
    children?: React.ReactNode;
}

export const GFPopoverOnSkeleton: FC<GFPopoverOnSkeletonProps> = ({ children, title, text, icon }) => {
    const mobile =  useMobile()
    return (
        <Card
            sx={{
                maxWidth: 500,
                width: '100%',
                p: 3,
                borderRadius: 4,
                border: ({ palette }) => `2px solid ${palette.primary.main}`,
                margin: mobile ? '0 24px' : '0',
            }}
        >
            <Stack spacing={3} alignItems="center">
                {icon}
                <Typography variant="subtitle1" textAlign="center">
                    {title}
                </Typography>
                <Typography variant="body2" textAlign="center">
                    {text}
                </Typography>
                {children}
            </Stack>
        </Card>
    )
}

