import React, { FC, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { IExamAnswerData } from '../../../api/typing/exam'
import { MassExamAnswerData } from '../../../api/typing/massExam'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface ExamAnswerLabelProps {
    answer: IExamAnswerData | MassExamAnswerData;
    showCorrectAnswer?: boolean;
    openImageDialog: (value: string) => void;
}

export const ExamAnswerLabel: FC<ExamAnswerLabelProps> = ({
    answer,
    openImageDialog,
    showCorrectAnswer = false,
}) => {
    const isOnlyTextAnswer = useMemo(() => !!answer.title.length && !answer.imgLink.length, [answer])
    const isOnlyImageAnswer = useMemo(() => !answer.title.length && !!answer.imgLink.length, [answer])
    return (
        <Box display='flex' alignItems='center' gap={2}>
            <Box>
                {!!answer.title.length &&
                    <Typography pt={answer.imgLink.length ? '10px' : 0}>
                        {answer.title}
                    </Typography>
                }
                {!!answer.imgLink.length &&
                    <Box
                        display='flex'
                        alignItems='center'
                        gap={2}
                        mt={answer.title.length ? 2 : 0}
                    >
                        <img
                            src={answer.imgLink}
                            alt='answer-img'
                            style={{
                                width: 'auto',
                                height: '100px',
                                borderRadius: '8px',
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                            onClick={() => openImageDialog(answer.imgLink)}
                        />
                        {showCorrectAnswer && !!answer.title.length &&
                            <>
                                {answer.isCorrect &&
                                    <CheckIcon color='success' />
                                }
                                {(answer as IExamAnswerData).isAnswered && !answer.isCorrect &&
                                    <CloseIcon color='error' />
                                }
                            </>
                        }
                    </Box>
                }
            </Box>
            {showCorrectAnswer && (isOnlyTextAnswer || isOnlyImageAnswer) &&
                <>
                    {answer.isCorrect &&
                        <CheckIcon color='success' />
                    }
                    {(answer as IExamAnswerData).isAnswered && !answer.isCorrect &&
                        <CloseIcon color='error' />
                    }
                </>
            }
        </Box>
    )
}
