import React, { FC } from 'react'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import {
    TITLE_BUTTON_FILTER,
    TITLE_DRAWER_ADD_MATERIALS_IN_PDP,
    TITLE_DRAWER_ADD_MATERIALS_IN_PDP_MOBILE,
    TITLE_NAMESPACE_PLAN_ITEM,
} from 'helpers/enums/titles'
import { CloseRounded, FilterListRounded } from '@mui/icons-material'
import { PdpSetupMaterialsFilters } from '../PdpSetupMaterialsFilters/PdpSetupMaterialsFilters'
import { PdpSetupMaterialsActions } from '../PdpSetupMaterialsActions/PdpSetupMaterialsActions'
import { useMobile } from 'helpers/hooks/useMobile'

type PdpSetupMaterialsHeaderProps = {
    item:  IPdpItem & Pick<IPdp, 'author' | 'reviewer' | 'employee'>;
    handleClose: () => void;
    search: string;
    setSearch: (value: string) => void;
    countTitle: string;
    addMaterials: () => void;
    setOpenFilters: (value: boolean) => void;
}

export const PdpSetupMaterialsHeader: FC<PdpSetupMaterialsHeaderProps> = ({
    item,
    handleClose,
    search,
    setSearch,
    countTitle,
    addMaterials,
    setOpenFilters,
}) => {
    const mobile = useMobile()
    return (
        <Box
            sx={{
                pt: mobile ? 3 : 5,
                pr: mobile ? 0 : 5,
                pl: mobile ? 0 : 2.5,
                mb: mobile ? 0: 5,
            }}
        >
            <Box
                display="flex"
                flexDirection={mobile ? 'row-reverse' : 'initial'}
                sx={{px: mobile ? 3 : 0}}
            >
                <IconButton
                    color="primary"
                    sx={{ mr: mobile ? 0 : 2.5, alignSelf: 'flex-start' }}
                    onClick={handleClose}
                >
                    <CloseRounded />
                </IconButton>
                <Box flex={1}>
                    <Typography variant="h1" mb={mobile ? 1 : 4}>
                        {!mobile ? TITLE_DRAWER_ADD_MATERIALS_IN_PDP : TITLE_DRAWER_ADD_MATERIALS_IN_PDP_MOBILE}
                    </Typography>
                    <Box mb={mobile ? 3 : 5} display="flex" justifyContent="space-between" alignItems="flex-end">
                        <Box flex={1} maxWidth={600}>
                            <Box mb={mobile ? 0 : 3}>
                                <Typography variant="body1" mb={1}>
                                    {TITLE_NAMESPACE_PLAN_ITEM}
                                </Typography>
                                <Typography variant="body2">
                                    {item.title}
                                </Typography>
                            </Box>
                            {!mobile ?
                                <PdpSetupMaterialsFilters
                                    search={search}
                                    setSearch={setSearch}
                                />
                                :
                                <Button
                                    variant="text"
                                    sx={{ alignSelf: 'flex-start' }}
                                    startIcon={<FilterListRounded/>}
                                    onClick={() => setOpenFilters(true)}
                                >
                                    {TITLE_BUTTON_FILTER}
                                </Button>
                            }
                        </Box>
                        {!mobile &&
                            <PdpSetupMaterialsActions
                                countTitle={countTitle}
                                handleClose={handleClose}
                                addMaterials={addMaterials}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            {mobile && <Divider />}
        </Box>
    )
}
