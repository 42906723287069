import React, { FC, useEffect } from 'react'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_PDP_EDIT,
    TITLE_FIELD_GRADE,
    TITLE_NAMESPACE_SPECIALIZATION,
} from 'helpers/enums/titles'
import {
    Autocomplete,
    Box,
    InputLabel,
    ListSubheader,
    Stack,
    TextField,
    Tooltip,
    Typography, useMediaQuery,
    useTheme,
} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import {
    HINT_CHANGE_SPECIALIZATION_OR_GRADE_WILL_LOST_PDP,
    HINT_CHOOSE,
    HINT_EMPLOYEE_CREATE_PDP,
    HINT_ERROR_REQUIRED,
    HINT_SUCCESS_PDP_CHANGED,
} from 'helpers/enums/hints'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { developmentPdpActions, formActions, pdpActions } from 'store/actions'
import { IPdpCreateRequest } from 'api/typing/employeeTypes'
import { ChevronRightRounded, MoreHorizRounded } from '@mui/icons-material'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useNavigate } from 'react-router-dom'
import { PATH_DEVELOPMENT, PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { biggerBtnForMobile } from 'styles/styles'

type DialogEditPdpProps = {
    handleClose: () => void;
    open: boolean;
}

type PdpForm = Omit<IPdpCreateRequest, 'specializationUuid'> & {
    specialization: IFormSpecialization | null;
}

export const DialogEditPdp: FC<DialogEditPdpProps> = ({ open, handleClose }) => {
    const notify = useNotify()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { formGrades, formSpecializations } = useAppSelector(state => state.formReducer.data)
    // TODO: need create one reducer state for pdp
    const { pdp } = useAppSelector(state => state.pdpReducer.data)
    const { pdp: developmentPdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const { uuid } = useAppSelector(state => state.userReducer.data)

    const employee = pdp?.employee || developmentPdp?.employee
    const pdpUuid = pdp?.uuid || developmentPdp?.currentPdpUuid
    const currentPontPosition = pdp?.pointPosition || developmentPdp?.pointPosition
    const pdpSpecializationUuid = currentPontPosition?.specializationUuid
    const pdpGradeUuid = currentPontPosition?.gradeUuid

    const viewerIsEmployee = uuid === employee?.uuid

    const { handleSubmit, control, setValue, reset, getValues, formState: { errors } } = useForm<PdpForm>({
        defaultValues: {
            specialization: formSpecializations?.find(el => el.uuid === pdpSpecializationUuid) || null,
            gradeUuid: pdpGradeUuid,
            employeeUuid: employee?.uuid,
        },
    })

    const {
        isFetching: fetchSpecializationList,
        error: specializationError,
    } = useLayoutFetch(formActions.getSpecializations())

    const { isFetching: fetchGradeList, error: gradeError } = useLayoutFetch(
        formActions.getGrades(pdpSpecializationUuid),
        {
            deps: [pdpSpecializationUuid],
        },
    )

    useEffect(() => {
        if (!formGrades?.some(el => el.uuid === getValues('gradeUuid')))
            setValue('gradeUuid', '')
    }, [formGrades])

    useEffect(() => {
        if (fetchSpecializationList || fetchGradeList) return
        reset({
            specialization: formSpecializations?.find(el => el.uuid === pdpSpecializationUuid) || null,
            gradeUuid: pdpGradeUuid,
            employeeUuid: employee?.uuid,
        })
    }, [fetchSpecializationList, fetchGradeList])

    const updateGrades = async (specializationUuid?: string) => {
        if (!specializationUuid) return
        try {
            await dispatch(formActions.getGrades(specializationUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const onSubmit = async (data: PdpForm) => {
        if (!data.specialization?.uuid) return
        const payload = {
            ...data,
            specializationUuid: data.specialization.uuid,
            pdpUuid,
        }

        try {
            if (viewerIsEmployee) {
                const uuid: string = await dispatch(developmentPdpActions.updatePdp(payload))
                navigate(`${PATH_DEVELOPMENT}/pdp/${uuid}`)
            } else {
                const uuid: string = await dispatch(pdpActions.changePdp(payload))
                navigate(`${PATH_EMPLOYEES}/${employee?.uuid}/pdp/${uuid}`)
            }

            notify(HINT_SUCCESS_PDP_CHANGED, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (fetchSpecializationList || fetchGradeList) return null

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <DialogTitle onClose={handleClose}>
                    {TITLE_DIALOG_PDP_EDIT}
                </DialogTitle>
                {specializationError || gradeError
                    ? <Box overflow="auto" py={2}>
                        <ErrorPage error={specializationError || gradeError}/>
                    </Box>
                    : <DialogContent>
                        <Stack spacing={2.5}>
                            <Box>
                                <Typography variant="body2">
                                    {HINT_EMPLOYEE_CREATE_PDP}
                                </Typography>
                            </Box>
                            <Box>
                                <InputLabel htmlFor="specialization" sx={{ mb: 0.5 }}>
                                    {TITLE_NAMESPACE_SPECIALIZATION} *
                                </InputLabel>
                                <Controller
                                    name="specialization"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            id="specialization"
                                            options={formSpecializations || []}
                                            getOptionLabel={(option) => option.title}
                                            filterSelectedOptions
                                            isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
                                            groupBy={option => option.parentDivisionTitle}
                                            onChange={(e, d) => {
                                                field.onChange(d)
                                                void updateGrades(d?.uuid)
                                            }}
                                            renderGroup={({ group, key, children }) => {
                                                const currentDivision =
                                                    formSpecializations?.find(el =>
                                                        el.parentDivisionTitle === group)
                                                return (
                                                    <div key={key}>
                                                        <ListSubheader sx={{ position: 'sticky', top: '-8px' }}>
                                                            <Tooltip
                                                                title={currentDivision?.fullPath || ''}
                                                                placement="bottom-start"
                                                                PopperProps={{
                                                                    sx: {
                                                                        '& .MuiTooltip-tooltip': {
                                                                            // TODO: Need fix. Need important to override custom slyles
                                                                            margin: '-4px !important',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    <MoreHorizRounded
                                                                        color="disabled"
                                                                        fontSize="small"
                                                                    />
                                                                    <ChevronRightRounded
                                                                        color="disabled"
                                                                        fontSize="small"
                                                                        sx={{ mr: 0.5 }}
                                                                    />
                                                                    {group}
                                                                </Box>
                                                            </Tooltip>
                                                        </ListSubheader>
                                                        {children}
                                                    </div>
                                                )
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} value={option.title}>
                                                    <Typography
                                                        variant="body2"
                                                        ml={7}
                                                    >
                                                        {option.title}
                                                    </Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    title={TITLE_NAMESPACE_SPECIALIZATION}
                                                    placeholder={HINT_CHOOSE}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name="gradeUuid"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field }) => (
                                        <SelectForm
                                            {...field}
                                            id="gradeUuid"
                                            title={TITLE_FIELD_GRADE}
                                            required
                                            values={formGrades || []}
                                            error={!!errors.gradeUuid}
                                            helperText={errors?.gradeUuid ? errors.gradeUuid.message : null}
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body2">
                                    {HINT_CHANGE_SPECIALIZATION_OR_GRADE_WILL_LOST_PDP}
                                </Typography>
                            </Box>
                        </Stack>
                    </DialogContent>}
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

