import { BaseApi } from 'api/index'

export class TransferEmployee extends BaseApi {
    protected endpoint = 'employee'
    protected apiVersion = 'v1'

    public useVersion(version: string) {
        this.apiVersion = version
        return this
    }
}
