import React, { memo, useMemo } from 'react'
import { Box, Chip } from '@mui/material'
import { InfoOutlined, WarningAmberRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'

type EmployeeAssessmentCompetenceWarningProps = {
    competenceUuid: string;
}

export const EmployeeAssessmentCompetenceWarning = memo(
    function EmployeeAssessmentCompetenceWarning({ competenceUuid }: EmployeeAssessmentCompetenceWarningProps) {

        const indicators = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators)
        const indicatorUuids = useAppSelector(
            state => state.MyAssessmentPassReducer.data.competenceIndicatorUuids[competenceUuid],
        )

        const hasNeedAction = useMemo(
            () => indicatorUuids.some(el => indicators[el].needAction
                || indicators[el].answers?.some(answer => answer?.role?.divergent)),
            [indicators],
        )
        const notCalculated = indicatorUuids.length / 2
            <= indicatorUuids.filter(el => indicators[el].answers.some(answer => !answer.isCalculated && answer.respondentUuid !== 'final')).length

        if (!hasNeedAction && !notCalculated) return null

        return (
            <Box>
                {notCalculated && <Chip
                    sx={{
                        padding: '4px 4px 4px 12px',
                        height: 'auto',

                        '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                    label="Недостаточно данных для объективной оценки"
                    variant="outlined"
                    color="error"
                    size="small"
                    icon={<InfoOutlined/>}
                />}
                {!notCalculated && <Chip
                    sx={{
                        padding: '4px 4px 4px 12px',
                        height: 'auto',

                        '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                    label="Оценки участников сильно расходятся"
                    variant="outlined"
                    color="warning"
                    size="small"
                    icon={<WarningAmberRounded/>}
                />}
            </Box>
        )
    },
)
