import axios, { AxiosStatic } from 'axios'
import { Report } from 'api/services/ReportService/report'

interface IReportService {
    reportService: () => Report;
}

export class ReportService implements IReportService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public reportService(): Report {
        return new Report(this.axiosInstance)
    }
}
