import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ErrorOutlineRounded } from '@mui/icons-material'
import {
    CPACriteriaAction,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/CPACriteriaAction'
import { TITLE_NAMESPACE_CPA_CRITERIA } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

export const CPACriteriaHeader = () => {
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack flex={1} direction="row" alignItems="center" spacing={1}>
                <Typography variant="h3">
                    {TITLE_NAMESPACE_CPA_CRITERIA}
                </Typography>
                <Authenticated service="cpa" accessLevel="wx">
                    {(!currentCriteriaUuid || isEmptyUuid(currentCriteriaUuid)) &&
                        <ErrorOutlineRounded color="warning"/>}
                </Authenticated>
            </Stack>
            <Authenticated service="cpa" accessLevel="wx">
                <CPACriteriaAction/>
            </Authenticated>
        </Box>
    )
}
