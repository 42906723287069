import React from 'react'
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { HINT_ERROR_EMAIL, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { PATTERN_EMAIL } from 'helpers/enums/regexPatterns'
import { InputForm } from 'components/form/InputForm/InputForm'
import { TITLE_BUTTON_START, TITLE_FIELD_FIRST_NAME, TITLE_NAMESPACE_EMAIL } from 'helpers/enums/titles'
import { OpenAccessService } from 'api/services/OpenAccessService'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotify } from 'store/hooks/useNotify'
import { IOAAssessmentCreateRequest } from 'api/typing/openAccessTypes'
import { useLocalStorage } from 'react-use'

const oaAssessmentApi = new OpenAccessService().oaAssessmentService()

export const OAAssessmentInit = () => {
    const [, setOpen] = useLocalStorage('viewInstruction', true)
    const { hash } = useParams()
    const notify = useNotify()
    const navigate = useNavigate()
    const { handleSubmit, control, formState: { errors } } = useForm<IOAAssessmentCreateRequest>({
        defaultValues: {
            email: '',
            firstName: '',
            hash,
        },
    })
    const onSubmit = async (payload: IOAAssessmentCreateRequest) => {
        try {
            const { uuid } = await oaAssessmentApi.createAssessmentForAnonymous(payload)
            setOpen(true)
            navigate(uuid)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <Card sx={{ maxWidth: 650 }}>
                <CardContent>
                    <Stack spacing={3}>
                        <Typography variant="h3">
                            Чтобы пройти опрос, заполните E-mail и Имя
                        </Typography>
                        <Box>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="firstName"
                                        title={TITLE_FIELD_FIRST_NAME}
                                        autoComplete="given-name"
                                        required
                                        autoFocus
                                        error={!!errors.firstName}
                                        helperText={errors?.firstName ? errors.firstName.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                    pattern: {
                                        value: PATTERN_EMAIL,
                                        message: HINT_ERROR_EMAIL,
                                    },
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="email"
                                        title={TITLE_NAMESPACE_EMAIL}
                                        required
                                        type="email"
                                        autoComplete="email"
                                        error={!!errors.email}
                                        helperText={errors?.email ? errors.email.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Button type="submit" variant="contained" sx={{ alignSelf: 'flex-end' }}>
                            {TITLE_BUTTON_START}
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}
