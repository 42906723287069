import React from 'react'
import { Chip, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { HINT_CHIP_DATA_SAVED_AUTOMATICALLY } from 'helpers/enums/hints'
import { AccessTimeRounded, SaveRounded } from '@mui/icons-material'
import { AssessmentInstruction } from 'components/assessment/AssessmentInstruction/AssessmentInstruction'
import { useAppSelector } from 'store/hooks/redux'

export const MyAssessmentPassHeaderHelperChips = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const answers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    if (assessmentSetup?.completeStatus) return null

    if (mobile) return (
        <Paper
            elevation={0}
            sx={{ alignSelf: 'stretch', p: 1.5, background: theme.palette.background.default, borderRadius: 4 }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography variant="body1" fontWeight={500}>
                        {assessmentSetup?.durationTitle}
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                        {HINT_CHIP_DATA_SAVED_AUTOMATICALLY}
                    </Typography>
                </Stack>
                <AssessmentInstruction
                    assessmentTypeUuid={assessment?.cpa?.typeUuid ?? assessment?.typeUuid}
                    assessmentStatusUuid={assessment?.statusUuid}
                    evaluatedUuid={assessment?.evaluated.uuid}
                    answers={answers}
                    mobileVersion
                />
            </Stack>
        </Paper>
    )
    return (
        <Stack spacing={1} alignItems="flex-start">
            <Stack spacing={1} direction="row" alignItems="center">
                <Chip
                    icon={<AccessTimeRounded/>}
                    label={assessmentSetup?.durationTitle}
                    sx={{
                        fontSize: 16,
                        lineHeight: '19px',
                        fontWeight: 500,
                        color: ({ palette }) => palette.text.secondary,
                        background: ({ palette }) => palette.background.default,
                        '& .MuiChip-icon': {
                            marginLeft: 1.5,
                        },
                    }}
                />
                <AssessmentInstruction
                    assessmentTypeUuid={assessment?.cpa?.typeUuid ?? assessment?.typeUuid}
                    assessmentStatusUuid={assessment?.statusUuid}
                    evaluatedUuid={assessment?.evaluated.uuid}
                    answers={answers}
                />
            </Stack>
            <Chip
                icon={<SaveRounded/>}
                label={HINT_CHIP_DATA_SAVED_AUTOMATICALLY}
                sx={{
                    fontSize: 16,
                    lineHeight: '19px',
                    fontWeight: 500,
                    color: ({ palette }) => palette.text.secondary,
                    background: ({ palette }) => palette.background.default,
                    '& .MuiChip-icon': {
                        marginLeft: 1.5,
                    },
                }}
            />
        </Stack>
    )
}
