import React, { FC, useState } from 'react'
import { IconButton, InputAdornment, InputLabel, Stack, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'
import { TextFieldProps } from '@mui/material/TextField/TextField'

type InputPasswordProps = {
    id: string;
    title?: string;
    colorLabel?: string;
}

export const InputPassword: FC<TextFieldProps & InputPasswordProps> = React.forwardRef(function InputPassword(
    inProps,
    ref,
) {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Stack spacing={0.5}>
            {inProps.title
                ? <InputLabel
                    htmlFor={inProps.id}
                    sx={{
                        color: inProps.colorLabel,
                    }}
                >
                    {inProps.title}
                </InputLabel>
                : null}
            <TextField
                {...inProps}
                ref={ref}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onMouseDown={() => setShowPassword((prev) => !prev)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>),
                }}
            />
        </Stack>
    )
})
