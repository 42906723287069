import { CPAService } from 'api/services/CPAService'
import { useMutation, useQuery } from 'react-query'
import { useNotify } from 'store/hooks/useNotify'
import { AssessmentService } from 'api/services/AssessmentService'
import { HINT_SUCCESS_NOTIFY_SEND } from 'helpers/enums/hints'
import { MassExamService } from '../../services/massExamService'

const cpaNotifyApi = new CPAService().notifyService()
const assessmentApi = new AssessmentService().assessmentService()
const massExamApi = new MassExamService().notifyService()

const sendCpaNotifyDeadline = async (uuid: string) => await cpaNotifyApi.deadline(uuid)
const getCpaLastNotifyDeadline = async (uuid: string) => await cpaNotifyApi.geLastNotifyDeadline(uuid)

const sendAssNotifyDeadline = async (uuid: string) => await assessmentApi.assessmentNotifyDeadline(uuid)
const getLastAssessmentNotifyDeadline = async (uuid: string) =>
    await assessmentApi.getLastAssessmentNotifyDeadline(uuid)
const sendAssNotifyReview = async (uuid: string) => await assessmentApi.assessmentNotifyReview(uuid)
const getLastAssessmentNotifyReview = async (uuid: string) => await assessmentApi.getLastAssessmentNotifyReview(uuid)
const sendMassExamNotifyExpired = async (uuid: string) => await massExamApi.expired(uuid)

export const useGetCpaNotifyDeadlineLast = (cpaUuid: string, enabled = true) => {
    const notify = useNotify()
    return useQuery({
        queryKey: ['useGetCpaNotifyLast', cpaUuid],
        queryFn: () => getCpaLastNotifyDeadline(cpaUuid),
        refetchOnWindowFocus: false,
        retry: 0,
        onError: (e: any) => notify(e.userMessage, 'error'),
        enabled,
    })
}
export const useGetLastAssessmentNotifyDeadline = (uuid: string, enabled = true) => {
    const notify = useNotify()
    return useQuery({
        queryKey: ['useGetLastAssessmentNotifyDeadline', uuid],
        queryFn: () => getLastAssessmentNotifyDeadline(uuid),
        refetchOnWindowFocus: false,
        retry: 0,
        onError: (e: any) => notify(e.userMessage, 'error'),
        enabled,
    })
}
export const useGetLastAssessmentNotifyReview = (uuid: string, enabled = true) => {
    const notify = useNotify()
    return useQuery({
        queryKey: ['useGetLastAssessmentNotifyReview', uuid],
        queryFn: () => getLastAssessmentNotifyReview(uuid),
        refetchOnWindowFocus: false,
        retry: 0,
        onError: (e: any) => notify(e.userMessage, 'error'),
        enabled,
    })
}

export const useSendCpaNotifyDeadline = (uuid: string) => {
    // const queryClient = useQueryClient()
    const notify = useNotify()
    return useMutation({
        mutationKey: `useSendCpaNotifyDeadline-${uuid}`,
        mutationFn: () => sendCpaNotifyDeadline(uuid),
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: [`cpaItem-${uuid}`] }).catch(e => e)
            notify(HINT_SUCCESS_NOTIFY_SEND, 'success')
        },
        onError: (e: any) => {
            notify(e.userMessage, 'error')
        },
    })
}

export const useSendAssessmentNotifyDeadline = (uuid: string) => {
    // const queryClient = useQueryClient()
    const notify = useNotify()
    return useMutation({
        mutationKey: `useSendAssessmentNotifyDeadline-${uuid}`,
        mutationFn: () => sendAssNotifyDeadline(uuid),
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: [`cpaItem-${uuid}`] }).catch(e => e)
            notify(HINT_SUCCESS_NOTIFY_SEND, 'success')
        },
        onError: (e: any) => {
            notify(e.userMessage, 'error')
        },
    })
}

export const useSendAssessmentNotifyReview = (uuid: string) => {
    // const queryClient = useQueryClient()
    const notify = useNotify()
    return useMutation({
        mutationKey: `useSendAssessmentNotifyReview-${uuid}`,
        mutationFn: () => sendAssNotifyReview(uuid),
        onSuccess: () => {
            // queryClient.invalidateQueries({ queryKey: [`cpaItem-${uuid}`] }).catch(e => e)
            notify(HINT_SUCCESS_NOTIFY_SEND, 'success')
        },
        onError: (e: any) => {
            notify(e.userMessage, 'error')
        },
    })
}

export const useSendMassExamNotifyExpired = (uuid: string) => {
    const notify = useNotify()
    return useMutation({
        mutationKey: `useSendMassExamNotifyExpires-${uuid}`,
        mutationFn: () => sendMassExamNotifyExpired(uuid),
        onSuccess: () => {
            notify(HINT_SUCCESS_NOTIFY_SEND, 'success')
        },
        onError: (e: any) => {
            notify(e.userMessage, 'error')
        },
    })
}
