import React, { FC, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_CREATE_MATERIAL,
    TITLE_DIALOG_EDIT_MATERIAL,
    TITLE_FIELD_FORMAT,
    TITLE_FIELD_STUDY_TIME,
    TITLE_FIELD_TITLE,
    TITLE_FIELD_TYPE,
    TITLE_NAMESPACE_LINK,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_SKILL_LEVEL,
} from 'helpers/enums/titles'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { InputForm } from 'components/form/InputForm/InputForm'
import { InputMask } from 'components/form/InputMask/InputMask'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useAppDispatch } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { IEmployeePdpCompetenceMaterialCreateRequest } from 'api/typing/employeeTypes'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { developmentPdpItemActions, pdpItemActions } from 'store/actions'
import {
    HINT_ERROR_REQUIRED,
    HINT_SUCCESS_PDP_MATERIAL_CREATE,
    HINT_SUCCESS_PDP_MATERIAL_EDIT,
} from 'helpers/enums/hints'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { formatsOfMaterials } from 'helpers/enums/formatsOfMaterials'
import { materialsTypes } from 'helpers/getMaterialType'
import { biggerBtnForMobile } from 'styles/styles'

interface IFormValues extends Omit<IEmployeePdpCompetenceMaterialCreateRequest, 'studyTime'> {
    studyTime: string
}

type PdpCompetenceMaterialCreateUpdateProps = {
    handleClose: () => void;
    open: boolean;
    material?: IPdpCompetenceMaterial;
    pdpUuid: string;
    employee: IParticipantEmployee;
    item: IPdpItem;
    isDevelopment?: boolean;
}

export const PdpCompetenceMaterialCreateUpdate: FC<PdpCompetenceMaterialCreateUpdateProps> = ({
    pdpUuid,
    employee,
    item,
    material,
    open,
    isDevelopment,
    handleClose,
}) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { handleSubmit, control, watch, getValues, setValue, formState: { errors } } = useForm<IFormValues>({
        shouldFocusError: false,
        defaultValues: {
            pdpUuid: pdpUuid,
            title: material?.title ?? '',
            entityType: 'unique',
            comment: material?.comment ?? '',
            comments: material?.comments ?? [],
            format: formatsOfMaterials.find(el => el.title === material?.format)?.uuid ?? '',
            employeeUuid: employee?.uuid,
            materialTypeUuid: material?.materialTypeUuid || '',
            studyTime: material?.studyTime ? String(material.studyTime) : '',
            itemUuid: item.uuid,
            link: material?.comment ?? '',
            skillLevelUuid: material?.skillLevelUuid ?? '',
            sortIndex: material?.sortIndex ?? 0,
        },
    })
    const skillLevelsArray = item.skillLevels.map(({ uuid, title }) => ({ uuid, title }))
    const watchSkillLevelUuid = watch('skillLevelUuid')

    useEffect(() => {
        const skillLevelUuid = getValues('skillLevelUuid')
        let sortIndex = 0
        item.skillLevels.some(level => {
            if (level.uuid !== skillLevelUuid) return false

            sortIndex = level?.materials?.length > 0
                ? level.materials.reduce((p, c) => p.sortIndex > c.sortIndex ? p : c).sortIndex + 1
                : 0
            return true
        })
        setValue('sortIndex', sortIndex)
    }, [watchSkillLevelUuid])

    const onSubmit = async (data: IFormValues) => {
        const payload: IEmployeePdpCompetenceMaterialCreateRequest = {
            ...removeWhiteSpaceFromObjectElements(data),
            studyTime: Number(data.studyTime),
        }
        try {
            if (!material) {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.createItemMaterial({
                        ...payload,
                        format: formatsOfMaterials.find(el => el.uuid === payload.format)?.title ?? '',
                    }))
                else
                    await dispatch(pdpItemActions.createPdpCompetenceMaterial({
                        ...payload,
                        format: formatsOfMaterials.find(el => el.uuid === payload.format)?.title ?? '',
                    }))
                notify(HINT_SUCCESS_PDP_MATERIAL_CREATE, 'success')
            } else {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.updateItemMaterial({
                        ...payload,
                        format: formatsOfMaterials.find(el => el.uuid === payload.format)?.title ?? '',
                        uuid: material.uuid,
                    }))
                else
                    await dispatch(pdpItemActions.updatePdpCompetenceMaterial({
                        ...payload,
                        format: formatsOfMaterials.find(el => el.uuid === payload.format)?.title ?? '',
                        uuid: material.uuid,
                    }))
                notify(HINT_SUCCESS_PDP_MATERIAL_EDIT, 'success')
            }
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <DialogTitle onClose={handleClose}>
                    {material ? TITLE_DIALOG_EDIT_MATERIAL : TITLE_DIALOG_CREATE_MATERIAL}
                </DialogTitle>
                <DialogContent>
                    <Box mb={2.5}>
                        <Typography variant="body1" mb={1}>
                            {TITLE_NAMESPACE_PLAN_ITEM}
                        </Typography>
                        <Typography variant="body2">
                            {item.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Box mb={2.5}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.pdp.material.title,
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="title"
                                        title={TITLE_FIELD_TITLE}
                                        required
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .pdp
                                                .material
                                                .title
                                                .maxLength
                                                .value,
                                        }}
                                        autoFocus
                                        error={!!errors.title}
                                        helperText={errors?.title ? errors.title.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="materialTypeUuid"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="materialTypeUuid"
                                        title={TITLE_FIELD_TYPE}
                                        required
                                        values={materialsTypes}
                                        error={!!errors.materialTypeUuid}
                                        helperText={errors?.materialTypeUuid ? errors.materialTypeUuid.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="format"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="format"
                                        title={TITLE_FIELD_FORMAT}
                                        required
                                        values={formatsOfMaterials}
                                        error={!!errors.format}
                                        helperText={errors?.format ? errors.format.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="skillLevelUuid"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="skillLevelUuid"
                                        title={TITLE_NAMESPACE_SKILL_LEVEL}
                                        required
                                        values={skillLevelsArray}
                                        error={!!errors.skillLevelUuid}
                                        helperText={errors?.skillLevelUuid ? errors.skillLevelUuid.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="studyTime"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.pdp.item.studyTime,
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        id="studyTime"
                                        mask={Number}
                                        scale={0}
                                        min={0}
                                        max={999}
                                        variant="outlined"
                                        required
                                        title={TITLE_FIELD_STUDY_TIME}
                                        error={!!errors.studyTime}
                                        helperText={errors?.studyTime ? errors.studyTime.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="link"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.pdp.material.link,
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="link"
                                        title={TITLE_NAMESPACE_LINK}
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .pdp
                                                .material
                                                .link
                                                .maxLength
                                                .value,
                                        }}
                                        error={!!errors.link}
                                        helperText={errors?.link ? errors.link.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Typography variant="body2">
                            {material
                                ? 'Изменения в материал будут сохранены только в этом плане развития.'
                                : !mobile ?
                                    'Этот материал будет добавлен в план развития только у этого сотрудника'
                                    :
                                    'Этот материал будет добавлен только в текущий план развития'
                            }
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

