import React, { FC } from 'react'
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import { TITLE_NAMESPACE_DEVELOPMENT_PLAN } from 'helpers/enums/titles'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { DateRangeRounded, FlagRounded } from '@mui/icons-material'

type PdpSkeletonProps = {
    isFetching?: boolean;
}

export const PdpSkeleton: FC<PdpSkeletonProps> = ({ isFetching = false }) => {
    const animation = isFetching && 'wave'

    return (
        <Card>
            <CardContent sx={{ opacity: isFetching ? 1 : 0.6 }}>
                <Stack spacing={6}>
                    <Box>
                        <Stack direction="row" spacing={3} mb={1} justifyContent="flex-start">
                            <Typography variant="h2" mr={3}>
                                {TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                            </Typography>
                            <PointPositionSpecializationGrade
                                title={
                                    <Skeleton
                                        animation={animation}
                                        variant="text"
                                        sx={{ fontSize: 27 }}
                                        width={120}
                                    />}
                            />
                        </Stack>
                        <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={200} />
                    </Box>
                    <Grid container>
                        <Grid item mobile={5.5}>
                            <Stack spacing={2.5}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <DateRangeRounded color="warning"/>
                                    <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={230}/>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <FlagRounded color="warning"/>
                                        <Skeleton
                                            animation={animation}
                                            variant="text"
                                            sx={{ fontSize: 27 }}
                                            width={'100%'}
                                        />
                                    </Stack>
                                    <Skeleton
                                        animation={animation}
                                        variant="text"
                                        sx={{ fontSize: 27 }}
                                        width={'100%'}
                                    />
                                    <Skeleton
                                        animation={animation}
                                        variant="text"
                                        sx={{ fontSize: 27 }}
                                        width={'100%'}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item mobile={1}>
                            <Box display="flex" height="200px" justifyContent="center">
                                <Divider orientation="vertical" flexItem/>
                            </Box>
                        </Grid>
                        <Grid item mobile={5.5}>
                            <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={170}/>
                            <Skeleton
                                animation={animation}
                                variant="rounded"
                                sx={{ height: 32, width: '100%', borderRadius: 4 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box>
                        <Box mb={4}>
                            <Skeleton animation={animation} variant="text" sx={{ fontSize: 40 }} width={200} />
                            <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={160} />
                        </Box>
                        <Box display="flex" justifyContent="space-between" p={1}>
                            <Stack direction="row" spacing={3}>
                                <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={17} />
                                <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={80} />
                                <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={100} />
                            </Stack>
                            <Stack direction="row" spacing={3} mr={3}>
                                <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={126} />
                                <Skeleton animation={animation} variant="text" sx={{ fontSize: 27 }} width={126} />
                            </Stack>
                        </Box>
                        <Divider sx={{ marginLeft: 5 }} />
                        {[...Array(3)].map((el, i) => (
                            <Box key={`${i}-row-pdp-item`}>
                                <Box display="flex" alignItems="center" py={1}>
                                    <Box display="flex" alignItems="center" alignSelf="stretch" mr={2.5}>
                                        <Skeleton
                                            animation={animation}
                                            variant="circular"
                                            width={32}
                                            height={32}
                                        />
                                    </Box>
                                    <Box mr={3}>
                                        <Skeleton
                                            animation={animation}
                                            variant="rounded"
                                            width={80}
                                            height={32}
                                            sx={{ borderRadius: 4 }}
                                        />
                                    </Box>
                                    <Box display="flex" flex={1}>
                                        <Box display="flex" flexDirection="column">
                                            <Skeleton
                                                animation={animation}
                                                variant="text"
                                                sx={{ fontSize: 27 }}
                                                width={230}
                                            />
                                            <Skeleton
                                                animation={animation}
                                                variant="text"
                                                sx={{ fontSize: 20 }}
                                                width={70}
                                            />
                                        </Box>
                                    </Box>
                                    {[...Array(2)].map((el, i) => (
                                        <Box
                                            key={`${i}-comment-or-hours`}
                                            sx={{
                                                width: 156,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Skeleton
                                                animation={animation}
                                                variant="rounded"
                                                width={23}
                                                height={19}
                                            />
                                        </Box>))}
                                </Box>
                                <Divider />
                            </Box>))}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

