import React, { FC, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Link, Stack } from '@mui/material'
import { InputForm } from 'components/form/InputForm/InputForm'
import { InputPassword } from 'components/form/InputPassword/InputPassword'
import { authActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { TextLink } from 'components/common/TextLink/TextLink'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PATTERN_EMAIL } from 'helpers/enums/regexPatterns'
import { HINT_ERROR_EMAIL, HINT_ERROR_REQUIRED, HINT_TOOLTIP_SUPPORT } from 'helpers/enums/hints'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PATH_HOME } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_FORGOT_PASSWORD,
    TITLE_BUTTON_LOG_IN,
    TITLE_CHECKBOX_REMEMBER_ME,
    TITLE_FIELD_PASSWORD,
    TITLE_NAMESPACE_EMAIL,
} from 'helpers/enums/titles'
import { useNotify } from 'store/hooks/useNotify'
import { LoadingButton } from '@mui/lab'
import { HelpOutlineRounded } from '@mui/icons-material'
import { useMobile } from 'helpers/hooks/useMobile'
import { useLocalStorage } from 'react-use'

const defaultValues = {
    login: '',
    password: '',
    isRemember: false,
}

type FormValues = {
    login: string;
    password: string;
    isRemember: boolean;
}

export const FormLoginFromCredentials: FC = () => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const mobile =  useMobile()
    const [loading, setLoading] = useState(false)
    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({ defaultValues })

    const [, setPDPBannerWatched] = useLocalStorage('PDPBannerWatched', false)
    const [, setIsMassExamListBannerWatched] = useLocalStorage('massExamListBannerWatched', false)
    const [, setIsCPAListBannerWatched] = useLocalStorage('CPAListBannerWatched', false)

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            setLoading(true)
            await dispatch(authActions.login(data))
            const requestedPath = searchParams.get('requestedPath')
            requestedPath ? navigate(requestedPath) : navigate(PATH_HOME)
            setPDPBannerWatched(false)
            setIsMassExamListBannerWatched(false)
            setIsCPAListBannerWatched(false)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack spacing={4}>
                <Stack spacing={3}>
                    <Controller
                        name="login"
                        control={control}
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            pattern: {
                                value: PATTERN_EMAIL,
                                message: HINT_ERROR_EMAIL,
                            },
                        }}
                        render={({ field }) => (
                            <InputForm
                                {...field}
                                id="login"
                                title={TITLE_NAMESPACE_EMAIL}
                                disabled={loading}
                                type="email"
                                autoComplete="username"
                                autoFocus
                                error={!!errors.login}
                                helperText={errors?.login ? errors.login.message : null}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{ required: HINT_ERROR_REQUIRED }}
                        render={({ field }) => (
                            <InputPassword
                                {...field}
                                id="password"
                                disabled={loading}
                                title={TITLE_FIELD_PASSWORD}
                                autoComplete="current-password"
                                error={!!errors.password}
                                helperText={errors?.password ? errors.password.message : null}
                            />
                        )}
                    />
                    <Controller
                        name="isRemember"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox
                                    {...field}
                                    id="isRemember"
                                    disabled={loading}
                                    size="small"
                                    sx={{ py: 0 }}
                                />}
                                label={TITLE_CHECKBOX_REMEMBER_ME}
                            />
                        )}
                    />
                </Stack>
                <Stack spacing={3}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                    >
                        {TITLE_BUTTON_LOG_IN}
                    </LoadingButton>
                    <Stack
                        direction={mobile ? 'column' : 'row'}
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={3}
                    >
                        <TextLink onCustomClickEvent={() => navigate('forgotten')}>
                            {TITLE_BUTTON_FORGOT_PASSWORD}
                        </TextLink>
                        <Button
                            startIcon={<HelpOutlineRounded/>}
                            component={Link}
                            href={process.env.REACT_APP_SUPPORT_LINK}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {HINT_TOOLTIP_SUPPORT}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
