import React, { FC, useState } from 'react'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import {
    CPAScaleDrawerContentItemDrawer,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/components/CPASceleDrawerContentItemDrawer/CPASceleDrawerContentItemDrawer'
import { TITLE_BUTTON_EDIT, TITLE_BUTTON_PREVIEW } from 'helpers/enums/titles'
import { useAppDispatch } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { cpaItemScaleActions } from 'store/actions'

type CPAScaleDrawerContentItemActionsProps = {
    scaleUuid: string;
    scaleType: string;
}

export const CPAScaleDrawerContentItemActions: FC<CPAScaleDrawerContentItemActionsProps> = ({
    scaleUuid,
    scaleType,
}) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const isOrigin = scaleType === 'origin'

    const deleteItem = async () => {
        try {
            await dispatch(cpaItemScaleActions.deleteScale(scaleUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            {open && <CPAScaleDrawerContentItemDrawer
                scaleUuid={scaleUuid}
                open={open}
                handleClose={() => setOpen(false)}
            />}
            <SecondaryActions
                editTitle={isOrigin ? TITLE_BUTTON_PREVIEW : TITLE_BUTTON_EDIT}
                onEdit={() => setOpen(true)}
                onDelete={isOrigin ? undefined : deleteItem}
            />
        </>
    )
}
