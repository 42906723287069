import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPdpProgressRequest } from 'api/typing/employeeTypes'
import {
    DevelopmentPdpUpdateUniqueRequest,
    DevelopmentPdpUpdateUniqueResponse,
    IDevelopmentPdpAddItemsFromBaseResponse,
    IDevelopmentPdpChangePointOrDateRequest,
    IDevelopmentPdpChangePointOrDateResponse,
    IDevelopmentPdpCommentDeleteRequest,
    IDevelopmentPdpCreateUniqueRequest,
    IDevelopmentPdpCreateUniqueResponse,
    IDevelopmentPdpDeleteItemRequest,
    IDevelopmentPdpItemCommentCreateRequest,
    IDevelopmentPdpItemCommentDeleteRequest,
    IDevelopmentPdpItemCommentUpdateRequest,
    IDevelopmentPdpProgressStatusItem,
} from 'api/typing/developmentPdpService'
import {
    changeActiveSpecialization,
    changeActiveSpecializationGroup,
    initSaveDataToSetup,
} from 'helpers/specializationCompetenceFunctions'
import { ChangeActiveSpecialization, ChangeActiveSpecializationGroup } from 'store/actions/specializationSetupAction'
import { emptyUuid } from 'helpers/isEmptyUuid'
import { format } from 'date-fns'

interface IDevelopmentPdpState {
    pdp: IDevelopmentPdp;
    competenciesFromBase: ISetupCompetenceGroup[];
    competenciesFromBaseCopy: ISetupCompetenceGroup[];
    competenceFromBaseSaved: ISpecializationSetupSaveData;
}

const initialState: IState<IDevelopmentPdpState> = {
    data: {} as IDevelopmentPdpState,
    isLoading: false,
    error: null,
}

export const developmentPdpSlice = createSlice({
    name: 'developmentPdp',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: (state) => {
            state.data.pdp = {} as IDevelopmentPdp
            state.isLoading = false
            state.error = null
        },

        getPdp: (state, action: PayloadAction<IDevelopmentPdp>) => {
            state.data.pdp = action.payload
            state.isLoading = false
            state.error = null
        },

        createPdp: (state, action: PayloadAction<IPdp>) => {
            const { uuid, ...other } = action.payload
            state.data.pdp = {
                ...state.data.pdp,
                currentPdpUuid: uuid,
                ...other,
            }
        },

        deletePdp: (state) => {
            state.data.pdp = {
                ...state.data.pdp,
                currentPdpUuid: emptyUuid,
                status: { uuid: '', title: '' },
            }
        },

        createPdpComment: (state, action: PayloadAction<IComment>) => {
            state.data.pdp.comments.push(action.payload)
        },

        updatePdpComment: (state, action: PayloadAction<IComment>) => {
            state.data.pdp.comments = state.data.pdp.comments.map(el => el.uuid === action.payload.uuid
                ? { ...el, ...action.payload }
                : el,
            )
        },

        deletePdpComment: (state, action: PayloadAction<IDevelopmentPdpCommentDeleteRequest>) => {
            state.data.pdp.comments = state.data.pdp.comments.filter(el => el.uuid !== action.payload.uuid)
        },

        createPdpItemComment: (state, action: PayloadAction<IDevelopmentPdpItemCommentCreateRequest & IComment>) => {
            state.data.pdp.planItems.some(item => {
                if (item.uuid !== action.payload.itemUuid) return false

                item.comments.push(action.payload)
                return true
            })
        },

        updatePdpItemComment: (state, action: PayloadAction<IDevelopmentPdpItemCommentUpdateRequest>) => {
            state.data.pdp.planItems.some(item => {
                if (item.uuid !== action.payload.itemUuid) return false

                // eslint-disable-next-line no-param-reassign
                item.comments = item.comments.map(comment => comment.uuid === action.payload.uuid
                    ? { ...comment, ...action.payload }
                    : comment,
                )
                return true
            })
        },

        deletePdpItemComment: (state, action: PayloadAction<IDevelopmentPdpItemCommentDeleteRequest>) => {
            state.data.pdp.planItems.some(item => {
                if (item.uuid !== action.payload.itemUuid) return false

                // eslint-disable-next-line no-param-reassign
                item.comments = item.comments.filter(comment => comment.uuid !== action.payload.uuid)
                return true
            })
        },

        changeStatus: (state, action: PayloadAction<IEntity>) => {
            state.data.pdp.status = action.payload
        },

        setDateSentForApprovalAt: (state) => {
            state.data.pdp.sentForApprovalAt = format(new Date(), 'dd.MM.yyyy')
        },

        changeOrderItems: (state, action: PayloadAction<IPdpPlanItem[]>) => {
            state.data.pdp.planItems = action.payload
        },

        updatePdpTotalProgress: (state, action: PayloadAction<IPdpProgressRequest>) => {
            state.data.pdp.progress = action.payload.totalProgress
        },

        changePdpPointOrDate: (
            state,
            action: PayloadAction<IDevelopmentPdpChangePointOrDateRequest & IDevelopmentPdpChangePointOrDateResponse>,
        ) => {
            state.data.pdp = { ...state.data.pdp, ...action.payload }
        },

        createUniquePdpItem: (
            state,
            action: PayloadAction<IDevelopmentPdpCreateUniqueResponse & IDevelopmentPdpCreateUniqueRequest>,
        ) => {
            state.data.pdp.planItems.push(action.payload)
            state.data.pdp.changeLog = action.payload.changeLog
        },

        addPdpItemsFromBase: (state, action: PayloadAction<IDevelopmentPdpAddItemsFromBaseResponse>) => {
            state.data.pdp.planItems = action.payload.planItems
            state.data.pdp.studyTime = action.payload.studyTime
            state.data.pdp.changeLog = action.payload.changeLog
        },

        updateUniquePdpItem: (
            state,
            action: PayloadAction<DevelopmentPdpUpdateUniqueRequest & DevelopmentPdpUpdateUniqueResponse>,
        ) => {
            state.data.pdp.planItems = state.data.pdp.planItems.map(el => {
                if (el.uuid !== action.payload.uuid) return el

                return {
                    ...el,
                    ...action.payload,
                }
            })
            state.data.pdp.changeLog = action.payload.changeLog
        },

        deletePdpItem: (
            state,
            action: PayloadAction<IDevelopmentPdpProgressStatusItem & IDevelopmentPdpDeleteItemRequest>,
        ) => {
            const { itemUuid, totalProgress, canSend, changeLog } = action.payload
            const deletedIdx = state.data.pdp.planItems.findIndex(el => el.uuid === itemUuid)
            state.data.pdp.studyTime -= state.data.pdp.planItems[deletedIdx].studyTime
            state.data.pdp.planItems.splice(deletedIdx, 1)
            state.data.pdp.progress = totalProgress
            state.data.pdp.changeLog = changeLog
            state.data.pdp.canSend = canSend
        },

        getCompetenceForPdp: (state, action: PayloadAction<ISetupCompetenceGroup[]>) => {
            state.data.competenceFromBaseSaved = {} as ISpecializationSetupSaveData
            state.data.competenciesFromBase = action.payload
            initSaveDataToSetup(state.data.competenceFromBaseSaved, action.payload)
            state.data.competenciesFromBaseCopy = action.payload
        },

        changeActiveCompetenceGroup: (state, action: PayloadAction<ChangeActiveSpecializationGroup>) => {
            changeActiveSpecializationGroup(
                state.data.competenciesFromBase,
                state.data.competenceFromBaseSaved,
                action.payload.uuid,
                action.payload.value,
            )
        },

        changeActiveCompetence: (state, action: PayloadAction<ChangeActiveSpecialization>) => {
            changeActiveSpecialization(
                state.data.competenciesFromBase,
                state.data.competenceFromBaseSaved,
                action.payload.uuid,
                action.payload.groupUuid,
                action.payload.value,
            )
        },

    },
})

export const developmentPdpReducer = developmentPdpSlice.reducer
