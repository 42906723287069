import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate } from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeWorkInCompanyCurrentPositionState> = {
    data: {} as IEmployeeWorkInCompanyCurrentPositionState,
    isLoading: false,
    error: null,
}

export const employeeEditWorkInCompanyCurrentPositionSlice = createSlice({
    name: 'employeeEditWorkInCompanyCurrentPosition',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        setCurrentPosition: (state, action: PayloadAction<IEmployeeWorkInCompanyCurrentPosition>) => {
            state.data.currentPosition = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        updateCurrentPosition: (state, action: PayloadAction<IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate>) => {
            const { specializationUuid, divisionUuid, gradeUuid, ...dates } = action.payload
            state.data.currentPosition = {
                ...dates,
                specialization: state.data.specializations?.find(el => el.uuid === specializationUuid) || {} as IEntity,
                division: state.data.divisions?.find(el => el.uuid === divisionUuid) || {} as IEntity,
                grade: state.data.grades?.find(el => el.uuid === gradeUuid) || {} as IEntity,
            }
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        getDivisions: (state, action: PayloadAction<IEntity[]>) => {
            state.data.divisions = action.payload
            state.isLoading = false
            state.error = null
        },

        getSpecializations: (state, action: PayloadAction<IEntity[]>) => {
            state.data.specializations = action.payload
            state.isLoading = false
            state.error = null
        },

        getGrades: (state, action: PayloadAction<IEntity[]>) => {
            state.data.grades = action.payload
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployeeWorkInCompanyCurrentPositionState
            state.lastQuery = null
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditWorkInCompanyCurrentPositionReducer = employeeEditWorkInCompanyCurrentPositionSlice.reducer
