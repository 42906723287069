import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { getSpiderData } from 'helpers/assessment/resultStateFunction'
import { Box,Stack } from '@mui/material'
import { GraphicSpider } from 'components/graphics/GraphicSpider/GraphicSpider'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'

export const AssessmentResultGraphicMobile = () => {
    const cpaRoles = useAppSelector(state => state.formReducer.data.cpaRoles)
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)

    const { data, keys } = getSpiderData(result?.groupCompetence, cpaRoles, true)

    if (data.length < 3) return null

    return (
        <Stack spacing={3}>
            <Box>
                {/*TODO: Title = Как работать с графиком*/}
                <GFAlertInfo
                    text="По нажатию на ось вы можете увидеть подробности - название компетенции и результаты по ролям"
                />
            </Box>
            <Box width="100%" height={500}>
                <GraphicSpider data={data} keys={keys} indexBy="title" maxValue={100}/>
            </Box>
        </Stack>
    )
}
