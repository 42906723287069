import React, { FC } from 'react'
import { TITLE_NAMESPACE_COMMENTS } from 'helpers/enums/titles'
import { Box, Divider, Drawer, IconButton, Typography, useTheme } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { useMobile } from 'helpers/hooks/useMobile'
import { Puller } from '../../common/Puller/Puller'

type DialogMaterialCommentViewProps = {
    handleClose: () => void;
    open: boolean;
    comment: string;
    materialTitle: string;
    materialFormat: string;
}

export const DrawerMaterialCommentView: FC<DialogMaterialCommentViewProps> = ({
    handleClose,
    open,
    comment,
    materialTitle,
    materialFormat,
}) => {
    const mobile = useMobile()
    const theme = useTheme()

    return (
        <Drawer
            anchor={mobile ? 'bottom' : 'right'}
            open={open}
            onClose={handleClose}
            ModalProps={{
                keepMounted: true,
                style: {
                    zIndex: theme.zIndex.modal,
                },
            }}
            sx={{ '& .MuiPaper-root': { borderRadius: '16px 16px 0 0' }}}
        >
            <Box
                role='presentation'
                sx={{
                    width: mobile ? '100%' : 620,
                    p: mobile ? 3 : 5,
                    borderRadius: '16px 16px 0 0',
                    height: mobile ? '50vh' : 'initial',
                }}
            >
                {mobile &&
                    <Box
                        display='flex'
                        justifyContent='center'
                        mb={4}
                    >
                        <Puller />
                    </Box>
                }
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant={mobile ? 'h2' : 'h1'}>
                                {TITLE_NAMESPACE_COMMENTS}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Box>
                    <Typography variant="body1" mb={1}>
                        {materialFormat}
                    </Typography>
                    <Typography variant="body2" mb={3}>
                        {materialTitle}
                    </Typography>
                    <Divider />
                </Box>
                <Box mt={3}>
                    <Typography variant="body2" mb={3}>
                        {comment}
                    </Typography>
                    <Divider />
                </Box>
            </Box>
        </Drawer>
    )
}
