import React, { FC } from 'react'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_COMMENTS,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { CommentOutlined } from '@mui/icons-material'
import { useDialogsState } from 'store/hooks/useDialogsState'
import {
    HistoryPdpItemViewCompetenceContent,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/ HistoryPdpItemViewPage/components/HistoryPdpItemCompetence/components/HistoryPdpItemCompetenceContent/HistoryPdpItemCompetenceContent'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'

type HistoryPdpItemViewCompetenceProps = {
    item: IPdpItem & Pick<IPdp, 'employee' | 'reviewer'>;
}

export const HistoryPdpItemViewCompetence: FC<HistoryPdpItemViewCompetenceProps> = ({ item }) => {
    const { dialogCommentView, toggleDialog } = useDialogsState({
        dialogCommentView: false,
    })

    return (
        <Box>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={true}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
            />
            <Stack spacing={2} mb={6} maxWidth={800}>
                <Box>
                    <Typography variant="h2" mb={1}>
                        {item?.title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body1">
                            {item?.competenceType}
                        </Typography>
                        {!!item?.changeLog &&
                            <BadgeChangeLog changeLog={item?.changeLog} evaluatedUuid={item?.employee?.uuid}/>}
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="body1" mb={1}>
                        {TITLE_NAMESPACE_STUDY_TIME}: {formatHours(item?.studyTime)}
                    </Typography>
                    <Typography variant="body1">
                        {item.description}
                    </Typography>
                </Box>
                <Box maxWidth={300}>
                    <GFProgressBar value={item.progress}/>
                </Box>
                <Button
                    startIcon={<CommentOutlined />}
                    variant="outlined"
                    disabled={item?.comments?.length <= 0}
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={() => toggleDialog('dialogCommentView', true)}
                >
                    {item.comments.length > 0 ? TITLE_NAMESPACE_COMMENTS : TITLE_NAMESPACE_COMMENT}
                </Button>
            </Stack>
            <HistoryPdpItemViewCompetenceContent />
        </Box>
    )
}

