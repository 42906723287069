import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAssessmentSetAnswerRequest } from 'api/typing/assessmentServiceTypes'
import { isEmptyUuid } from 'helpers/isEmptyUuid'

interface IOAAssessmentAnswersState {
    data: string[]
}

const initialState: IOAAssessmentAnswersState = {
    data: [],
}

export const oaAssessmentAnswersSlice = createSlice({
    name: 'oaAssessmentAnswers',
    initialState,
    reducers: {
        initAssessmentAnswers: (state, action: PayloadAction<IAssessment>) => {
            const answers = new Set<string>()
            action.payload.competencies.forEach(el => {
                el.indicators.forEach(indicator => {
                    if (!indicator.finalAnswerUuid || isEmptyUuid(indicator.finalAnswerUuid)) return
                    answers.add(indicator.uuid)
                })
            })
            state.data = Array.from(answers)
        },
        setAssessmentAnswer: (state, action: PayloadAction<IAssessmentSetAnswerRequest>) => {
            const answers = new Set(state.data)
            answers.add(action.payload.indicatorUuid)
            state.data = Array.from(answers)
        },
    },
})

export const oaAssessmentAnswersReducer = oaAssessmentAnswersSlice.reducer
