import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'


/**
 * @function useTabsChecker
 * @param {string[]} tabs - Available tabs array, who can be used for
 * @param {string | undefined} currentTab - Current tab name
 * @param {string} [pathToRelocate] - Path, when we need to redirect user
 * @description Function check the current tab from available tabs and redirect user if it need
 */

export function useTabsChecker(tabs: string[], currentTab: string | undefined, pathToRelocate?: string) {
    const navigate = useNavigate()

    const hasTabs = useMemo(() => tabs.some(tab => tab === currentTab), [currentTab])

    useEffect(() => {
        !hasTabs && pathToRelocate && navigate(pathToRelocate)
    }, [hasTabs])
}
