import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<IEmployeeHistoryState> = {
    data: {} as IEmployeeHistoryState,
    isLoading: false,
    error: null,
}

export const employeeViewHistorySlice = createSlice({
    name: 'employeeViewHistory',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getHistoryEventTypes: (state, action: PayloadAction<IEntity[]>) => {
            state.data.historyEventTypes = action.payload
            state.isLoading = false
            state.error = null
        },
        getHistory: (state, action: PayloadAction<IEmployeeHistory[]>) => {
            state.data.historyList = action.payload
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeViewHistoryReducer = employeeViewHistorySlice.reducer
