import React, { FC } from 'react'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import {
    CPAEmployeeDrawerPreviewAssessmentIndicator,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentCompetencies/components/CPAEmployeeDrawerPreviewAssessmentCompetence/CPAEmployeeDrawerPreviewAssessmentIndicator/CPAEmployeeDrawerPreviewAssessmentIndicator'

type CPAEmployeeDrawerPreviewAssessmentCompetenceProps = {
    competence: {
        uuid: string;
        title: string;
        typeTitle: string;
        skillLevelTitle: string;
        indicators: IEntity[];
    }
}

export const CPAEmployeeDrawerPreviewAssessmentCompetence: FC<CPAEmployeeDrawerPreviewAssessmentCompetenceProps> = ({
    competence,
}) => {
    return (
        <Stack spacing={3}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Box>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="caption">
                            {competence.typeTitle}
                        </Typography>
                    </Stack>
                    <Typography variant="h3">
                        {competence.title}
                    </Typography>
                </Box>
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                <Stack spacing={2} divider={<Divider/>}>
                    {competence.indicators.map((indicator, idx) => <CPAEmployeeDrawerPreviewAssessmentIndicator
                        key={indicator.uuid}
                        idx={idx + 1}
                        indicator={indicator}
                    />)}
                </Stack>
            </Paper>
        </Stack>
    )
}
