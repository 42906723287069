import { TransferForm } from 'api/services/FormService/transferForm'

export class OAFormService extends TransferForm {
    private prefix = 'oa'

    public getAssessmentAnswers(hash: string) {
        const path = `${this.prefix}/assessment/answer/all`
        return this.request<null, IEntity[]>('GET', path, { params: { hash } })
    }
}
