import React, { FC } from 'react'
import {
    CPACriteriaPoint,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/components/CPACriteriaPoint/CPACriteriaPoint'
import { getCpaCriteria } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import {
    CPACriteriaCompetence,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/components/CPACriteriaCompetence/CPACriteriaCompetence'
import {
    CPACriteriaCurrent,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/components/CPACriteriaCurrent/CPACriteriaCurrent'
import {
    CPACriteriaDefault,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/components/CPACriteriaDefault/CPACriteriaDefault'

type CPACriteriaContentProps = {
    criteriaUuid?: string;
}

export const CPACriteriaContent: FC<CPACriteriaContentProps> = ({
    criteriaUuid,
}) => {
    const currentCriteria = getCpaCriteria(criteriaUuid)

    switch (currentCriteria?.code) {
        case 'current':
            return <CPACriteriaCurrent/>
        case 'point':
            return <CPACriteriaPoint/>
        case 'competence':
            return <CPACriteriaCompetence/>
        default:
            return <CPACriteriaDefault/>
    }
}
