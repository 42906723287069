import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_DELETE } from 'helpers/enums/titles'
import { HINT_IMPOSSIBLE_TO_CANCEL_ACTION, HINT_SURE_TO_DELETE } from 'helpers/enums/hints'
import { biggerBtnForMobile } from 'styles/styles'

type DialogConfirmDeleteProps = {
    open: boolean;
    handleClose: () => void;
    onSubmit: () => void;
    title: string;
    elementTitle?: string;
    helperText?: string;
    elementType?: string;
}

export const DialogConfirmDelete: FC<DialogConfirmDeleteProps> = ({
    open,
    handleClose,
    title,
    elementTitle,
    elementType,
    helperText,
    onSubmit,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    const handleSubmit = async () => {
        await onSubmit()
        await handleClose()
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {title}
            </DialogTitle>
            <Box display="flex" flex={1} flexDirection="column">
                <DialogContent>
                    <Typography variant="body2">
                        {HINT_SURE_TO_DELETE} {elementType}{elementTitle && ` «${elementTitle}»`}?
                    </Typography>
                    <Box mt={1}>
                        {helperText ? <Typography variant="body2">
                            {helperText}
                        </Typography> : null}
                        <Typography variant="body2">
                            {HINT_IMPOSSIBLE_TO_CANCEL_ACTION}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="error"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_DELETE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
