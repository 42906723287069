import React, { useMemo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import {
    CPACreateForUnCompletedDialog,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeeCreateUnCompleteAlert/components/CPACreateForUnCompletedDialog/CPACreateForUnCompletedDialog'
import { emptyUuid } from 'helpers/isEmptyUuid'

const isStatusNotComplete = (status?: string) => getAssessmentStatus(status)?.code === 'notComplete'

export const CPAEmployeeCreateUnCompleteAlert = () => {
    const divisions = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const cpaMeta = useAppSelector(state => state.CPAItemReducer.meta)

    const unCompletedAssessments = useMemo(() => {
        return divisions.reduce((acc, division) => {
            const employeesInDivision =
                division.employees.reduce((a, v) => {
                    if (isStatusNotComplete(v?.assessment?.statusUuid))
                        a.push(v.uuid)
                    return a
                }, [] as string[])
            acc.push(...employeesInDivision)
            return acc
        }, [] as string[])
    }, [divisions])

    // TODO: delete || unCompletedAssessments.length >= 0 to enable
    if (
        cpaStatusCode !== 'complete'
        || unCompletedAssessments.length <= 0
        || !cpaMeta
        || (cpaMeta.childCpaUuid && cpaMeta.childCpaUuid !== emptyUuid)
        // || unCompletedAssessments.length >= 0
    ) return null

    return (
        <div>
            <GFAlertInfo
                text="Не по всем сотрудникам есть данные для получения результатов оценки. Вы можете создать новую оценку по этим сотрудникам для возобновления процедуры."
                action={<CPACreateForUnCompletedDialog
                    initialCpaUuid={cpaMeta.uuid}
                    cpaTitle={cpaMeta.title}
                    cpaTypeUuid={cpaMeta.typeUuid}
                    employees={unCompletedAssessments}
                />}
            />
        </div>
    )
}
