import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { TITLE_BUTTON_CREATE } from 'helpers/enums/titles'
import { AddButton } from 'components/common/AddButton/AddButton'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

type PageTitleProps = {
    title: string;
    buttonTitle?: string;
    button?: React.ReactNode;
    onCreate?: () => void;
    service?: PermissionServiceCode,
    disableButton?: boolean;
    accessLevel?: AccessLevel;
}

export const PageTitle: FC<PageTitleProps> = ({
    title,
    onCreate,
    buttonTitle,
    button,
    service,
    accessLevel,
    disableButton,
}) => {
    const titleButton = buttonTitle ?? TITLE_BUTTON_CREATE
    return (
        <Box
            display="flex"
            flexDirection={{ 'mobile': 'column', 'tablet': 'row' }}
            justifyContent={{ 'mobile': 'stretch', 'tablet': 'space-between' }}
            alignItems={{ 'mobile': 'stretch', 'tablet': 'center' }}
            flex={1}
            gap={3}
        >
            <Typography variant="h1">{title}</Typography>
            {button
                ? button
                : onCreate ?
                    <Authenticated service={service} accessLevel={accessLevel}>
                        <AddButton
                            sx={{ whiteSpace: 'nowrap' }}
                            variant="contained"
                            disabled={disableButton}
                            onClick={onCreate}
                        >
                            {titleButton}
                        </AddButton>
                    </Authenticated>
                    : null}
        </Box>
    )
}
