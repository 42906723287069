import React, { useMemo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Paper, Stack } from '@mui/material'
import { CPAAnalyticHeader } from './components/CPAAnalyticHeader/CPAAnalyticHeader'
import { CPAAnalyticContent } from './components/CPAAnalyticContent/CPAAnalyticContent'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

export const CPAAnalytic = () => {
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const currentEmployees = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const allEmployeesHasStatusNotComplete = useMemo(
        () => !currentEmployees || currentEmployees?.every(division =>
            division.employees.every(employee => getAssessmentStatus(employee.assessment?.statusUuid)?.code === 'notComplete')),
        [currentEmployees],
    )

    if (cpaStatusCode !== 'complete' || allEmployeesHasStatusNotComplete) return null

    return (
        <Paper elevation={0} sx={{ p: 3, borderRadius: 4, background: (theme) => theme.palette.background.default }}>
            <Stack spacing={3}>
                <CPAAnalyticHeader/>
                <CPAAnalyticContent/>
            </Stack>
        </Paper>
    )
}
