import { TransferLayer } from 'api/services/OpenAccessService/transferLayer'
import { AxiosStatic } from 'axios'

export class DevelopmentPlanService extends TransferLayer {
    private static instance: DevelopmentPlanService
    private prefix = 'development-plan'

    static getInstance(axiosInstance: AxiosStatic) {
        if (!DevelopmentPlanService.instance)
            DevelopmentPlanService.instance = new DevelopmentPlanService(axiosInstance)
        return DevelopmentPlanService.instance
    }

    public getDevelopmentPlan(hash: string) {
        const path = `/${this.prefix}/${hash}`
        return this.request<null, IDevelopmentPlan>('GET', path)
    }

    public getDevelopmentPlanCompetence(hash: string, competenceUuid: string, entityType: string) {
        const path = `/${this.prefix}/${hash}/competence/${competenceUuid}/${entityType}`
        return this.request<null, IGradeDevelopmentPlanCompetence>('GET', path)
    }
}
