import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IMenuState {
    backButtonPath: string | null;
}

const initialState: IMenuState = {
    backButtonPath: null,
}

export const menuSlice = createSlice({
    name: 'appMenu',
    initialState,
    reducers: {
        setBackButtonPath: (state, action: PayloadAction<string | null>) => {
            state.backButtonPath = action.payload
        },
    },
})

export const menuReducer = menuSlice.reducer
