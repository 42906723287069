import React, { FC, useMemo } from 'react'
import { ICpaGetEmployeesForAddEmployee } from 'api/typing/cpaTypes'
import { Stack, Tooltip } from '@mui/material'
import { DoNotDisturbOnTotalSilenceRounded } from '@mui/icons-material'
import {
    CPAEmployeeDrawerEmployeeCheckbox,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerContent/components/CPAEmployeeDrawerContentEmployee/components/CPAEmployeeDrawerEmployeeCheckbox/CPAEmployeeDrawerEmployeeCheckbox'
import { useParams } from 'react-router-dom'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { EmployeeMetaListItem } from 'components/EmployeeMetaListItem/EmployeeMetaListItem'

type CPAEmployeeDrawerContentEmployeeProps = {
    employee: ICpaGetEmployeesForAddEmployee;
}

export const CPAEmployeeDrawerContentEmployee: FC<CPAEmployeeDrawerContentEmployeeProps> = ({ employee }) => {
    const { cpaUuid } = useParams()
    const hasAssessment = useMemo(
        () => {
            const hasAssessment = !!employee.assessment
            const hasCpa = hasAssessment && employee.assessment?.cpaUuid
            const isCurrentCpa = hasCpa && cpaUuid === employee.assessment?.cpaUuid
            const assessmentStatusCode = getAssessmentStatus(employee.assessment?.statusUuid)?.code
            const assessmentIsUnCompleted = assessmentStatusCode !== 'complete' && assessmentStatusCode !== 'notComplete'

            return Boolean(hasAssessment && assessmentIsUnCompleted && !isCurrentCpa)
            // return !!employee.assessment?.uuid || !!employee.assessment && employee.assessment?.cpaUuid !== cpaUuid
        },
        [employee],
    )

    return (
        <Stack direction="row" alignItems="center" spacing={2} sx={{ opacity: hasAssessment ? 0.5 : 1 }}>
            <CPAEmployeeDrawerEmployeeCheckbox
                hasAssessment={hasAssessment}
                employeeUuid={employee.uuid}
            />
            <EmployeeMetaListItem
                fullName={`${employee.lastName} ${employee.firstName} ${employee.secondName}`}
                specialization={employee?.specialization}
                grade={employee?.grade}
            />
            {hasAssessment && <Tooltip
                title="Сотрудник уже занят в другой оценке"
                placement="bottom"
                enterDelay={300}
                enterNextDelay={300}
            >
                <DoNotDisturbOnTotalSilenceRounded/>
            </Tooltip>}
        </Stack>
    )
}
