import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { reportAction } from 'store/actions'
import { AnalyticReportButton } from '../AnalyticReportButton/AnalyticReportButton'
import { TITLE_BUTTON_RESULTS_EXPORT } from 'helpers/enums/titles'

export const AssessmentAnalyticReport = () => {
    const dispatch = useAppDispatch()
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const getReportLink = async () => {
        return await dispatch(reportAction.assessmentAnalyticReport({
            assessmentUuid: result?.uuid,
            fileType: 'xlsx',
            reportLink: '',
        }))
    }

    return (
        <AnalyticReportButton
            buttonTitle={TITLE_BUTTON_RESULTS_EXPORT}
            getReportLink={getReportLink}
        />
    )
}
