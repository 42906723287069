import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IMassExamChangeStatusRequest,
    IMassExamDescriptionUpdateRequest,
    IMassExamItemMeta,
    IMassExamItemMetaQuestion,
    IMassExamPassMarkSaveRequest,
    IMassExamUpdateRequest,
} from 'api/typing/massExam'
import { getMassExamStatusCode, MassExamStatusCode, MassExamStatuses } from 'helpers/massExam/massExamStatuses'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { addDays, format, isPast, parse } from 'date-fns'
import { IExamQuestion } from '../../../api/typing/exam'

interface IMassExamItemState {
    meta: IMassExamItemMeta;
    massExamStatusCode: MassExamStatusCode | null;
    isExpired: boolean;
    isLoading: boolean,
    error: IErrorResponse | null,
    currentQuestionPreview: IExamQuestion | null;
}

const initialState: IMassExamItemState = {
    meta: {} as IMassExamItemMeta,
    isExpired: false,
    massExamStatusCode: null,
    isLoading: false,
    error: null,
    currentQuestionPreview: null,
}

export const massExamItemSlice = createSlice({
    name: 'massExamItemSlice',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setMassExamMeta: (state, action: PayloadAction<IMassExamItemMeta>) => {
            state.meta = action.payload
            state.massExamStatusCode = getMassExamStatusCode(action.payload.statusUuid)
            state.isExpired = isValidDateFromApi(action.payload.endedAt) && isPast(addDays(parse(action.payload.endedAt, 'dd.MM.yyyy', new Date()), 1))
        },

        setNotifiedAt: (state, action: PayloadAction<string>) => {
            state.meta.notifiedAt = action.payload
        },

        updateMassExamStatus: (state, action: PayloadAction<MassExamStatuses>) => {
            state.meta.statusUuid = action.payload
        },

        updateItem: (state, action: PayloadAction<IMassExamUpdateRequest>) => {
            state.meta = {
                ...state.meta,
                ...action.payload,
            }
            state.isExpired = isValidDateFromApi(action.payload.endedAt) && isPast(addDays(parse(action.payload.endedAt, 'dd.MM.yyyy', new Date()), 1))
        },

        updateDescription: (state, action: PayloadAction<IMassExamDescriptionUpdateRequest>) => {
            state.meta = {
                ...state.meta,
                ...action.payload,
            }
        },

        changeStatusMassExam: (state, action: PayloadAction<IMassExamChangeStatusRequest>) => {
            state.meta.statusUuid = action.payload.statusUuid
            state.massExamStatusCode = getMassExamStatusCode(action.payload.statusUuid)
        },

        startMassExam: (state) => {
            state.meta.startedAt = format(new Date(), 'dd.MM.yyyy')
        },

        updatePassMark: (state, action: PayloadAction<IMassExamPassMarkSaveRequest>) => {
            state.meta = {
                ...state.meta,
                passMark: {
                    uuid: action.payload.passMark.uuid,
                    code: action.payload.passMark.code,
                    data: action.payload.passMark.data,
                },
            }
        },

        deletePassMark: (state) => {
            state.meta = {
                ...state.meta,
                passMark: null,
            }
        },

        addToQuestionList: (state, action: PayloadAction<IMassExamItemMetaQuestion>) => {
            state.meta = {
                ...state.meta,
                questions: [...state.meta.questions, action.payload],
            }
        },

        deleteFromQuestionList: (state, action: PayloadAction<{ uuid: string }>) => {
            state.meta = {
                ...state.meta,
                questions: state.meta.questions.filter(item => item.uuid !== action.payload.uuid),
            }
        },

        updateQuestionActiveStatus: (state, action: PayloadAction<{uuid: string, active: boolean, score: number}>) => {
            const questions = state.meta.questions.map(item =>
                (item.uuid === action.payload.uuid ? action.payload : item))
            state.meta = {
                ...state.meta,
                questions: [...questions],
            }
        },
        updateEmployeesCount: (state, action: PayloadAction<{employeesCount: number}>) => {
            state.meta = {
                ...state.meta,
                employeesCount: action.payload.employeesCount,
            }
        },
        updateQuestionListInMeta: (state, action: PayloadAction<IMassExamItemMetaQuestion[]>) => {
            state.meta.questions = action.payload
        },
        setQuestionPreview: (state, action: PayloadAction<IExamQuestion>) => {
            state.currentQuestionPreview = action.payload
        },
    },
})

export const massExamItemReducer = massExamItemSlice.reducer
