import React, { FC, useState } from 'react'
import { Box, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ClearRounded, EditRounded } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { useParams } from 'react-router-dom'
import { employeeActions } from 'store/actions'
import { IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate } from 'api/typing/employeeTypes'
import { formatTimeToStringDuration } from 'helpers/dateAndTime/formatTimeToStringDuration'
import {
    EditPreviousPositionItemForm,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabWorkInCompany/components/EditPreviousPositionCard/components/EditPreviousPositionItemForm/EditPreviousPositionItemForm'
import { parse } from 'date-fns'
import { HINT_RECORD_SUCCESS_DELETE, HINT_RECORD_SUCCESS_EDIT } from 'helpers/enums/hints'

type EditPreviousPositionItemProps = {
    position: IEmployeeWorkInCompanyPreviousPosition;
    isCurrentPosition?: boolean;
    pageViewEmployee?: boolean;
}

export const EditPreviousPositionItem: FC<EditPreviousPositionItemProps> = ({
    position,
    isCurrentPosition,
    pageViewEmployee,
}) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { employeeUuid } = useParams()
    const [edit, setEdit] = useState(false)
    const showChip = !!position?.startDate

    const handleEdit = () => {
        setEdit(true)
    }

    const handleDelete = async () => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.deleteEmployeeWorkInCompanyPreviousPosition(employeeUuid, position.uuid))
            notify(HINT_RECORD_SUCCESS_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    const editComplete = async (payload: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate) => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.updateEmployeeWorkInCompanyPreviousPosition(employeeUuid, payload))
            setEdit(false)
            notify(HINT_RECORD_SUCCESS_EDIT, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            {edit
                ? <EditPreviousPositionItemForm
                    onCancelForm={() => setEdit(false)}
                    onSubmitForm={editComplete}
                    itemData={position}
                />
                : <>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Box overflow="hidden">
                            <Stack spacing={0.5}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" mr={1}>
                                        {position?.specialization?.title} - {position?.grade?.title}
                                    </Typography>
                                    {showChip && <Chip
                                        label={formatTimeToStringDuration(
                                            parse(position?.startDate, 'dd.MM.yyyy', new Date()),
                                            parse(position?.endDate, 'dd.MM.yyyy', new Date()),
                                        )}
                                        size="small"
                                        color={isCurrentPosition ? 'secondary' : 'default'}
                                    />}
                                </Box>
                                <Typography variant="body1">
                                    {position?.division?.title}
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body1">
                                        {position?.startDate}
                                    </Typography>
                                    {position?.startDate && <Typography variant="body1">-</Typography>}
                                    <Typography variant="body1">
                                        {position?.endDate ? position?.endDate : 'н.в'}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        {!pageViewEmployee && <Box display="flex" flexWrap="nowrap" alignSelf="center">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditRounded />
                            </IconButton>
                            <IconButton color="primary" onClick={handleDelete}>
                                <ClearRounded />
                            </IconButton>
                        </Box>}
                    </Box>
                    {!pageViewEmployee && <Divider sx={{ mt: 2 }} />}
                </>}
        </>
    )
}

