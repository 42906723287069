import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Box, Button, Stack, SwipeableDrawer, Typography, useTheme } from '@mui/material'
import { Puller } from '../../common/Puller/Puller'
import {
    TITLE_BUTTON_APPLY, TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_FILTER,
    TITLE_BUTTON_RESET,
} from 'helpers/enums/titles'
import { CloseRounded } from '@mui/icons-material'

type DrawerFiltersMobileProps = {
    openFilters: boolean;
    setOpenFilters: (value: boolean) => void;
    applySearchFilter: (value: string) => void;
    subTitle: string;
    children: ReactNode;
}

export const DrawerFiltersMobile: FC<DrawerFiltersMobileProps> = ({
    openFilters,
    setOpenFilters,
    applySearchFilter,
    children,
    subTitle,
}) => {
    const theme = useTheme()
    const [search, setSearch] = useState('')

    const applyFilters = () => {
        applySearchFilter(search)
        setOpenFilters(false)
    }

    const resetFilters = () => {
        setSearch('')
    }

    useEffect(() => {
        if (!openFilters) {
            resetFilters()
        }
    }, [openFilters])

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={openFilters}
            onOpen={() => setOpenFilters(true)}
            onClose={() => setOpenFilters(false)}
            ModalProps={{
                keepMounted: true,
                style: {zIndex: theme.zIndex.modal},
            }}
            PaperProps={{
                sx: {
                    height: '100vh',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                    px: 3,
                    pb: 3,
                    pt: 2,
                }}
            >
                <Puller mb={3} />
                <Stack justifyContent="space-between" flex={1}>
                    <Box>
                        <Stack spacing={1} mb={3}>
                            <Typography variant="h3">{TITLE_BUTTON_FILTER}</Typography>
                            <Typography variant="body1">{subTitle}</Typography>
                        </Stack>
                        <Box mb={3}>
                            <Button
                                startIcon={<CloseRounded/>}
                                sx={{ alignSelf: 'flex-start', pl: 0 }}
                                disabled={!search.length}
                                onClick={resetFilters}
                            >
                                {TITLE_BUTTON_RESET}
                            </Button>
                        </Box>
                        {children}
                    </Box>
                    <Stack spacing={1}>
                        <Button
                            variant="contained"
                            onClick={applyFilters}
                            sx={{
                                fontSize: 16,
                                lineHeight: '26px',
                                padding: '12px 20px',
                            }}
                        >
                            {TITLE_BUTTON_APPLY}
                        </Button>
                        <Button
                            sx={{
                                fontSize: 16,
                                lineHeight: '26px',
                                padding: '12px 20px',
                            }}
                            onClick={() => setOpenFilters(false)}
                        >
                            {TITLE_BUTTON_CANCEL}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </SwipeableDrawer>
    )
}
