import React, { FC, useMemo, useState } from 'react'
import { Box, Button, Divider, Drawer, IconButton, Stack, TextField, Typography } from '@mui/material'
import {
    TITLE_BUTTON_BACK_TO_EMPLOYEES,
    TITLE_BUTTON_CANCEL,
    TITLE_FIELD_PLACEHOLDER_SEARCH_EMPLOYEE,
    TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS,
} from 'helpers/enums/titles'
import { ClearRounded, CloseRounded, PeopleAltRounded, SearchRounded } from '@mui/icons-material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useCpaEmployeesList } from 'api/queries/cpa/cpaQueries'
import { useNavigate, useParams } from 'react-router-dom'
import { ICpaGetEmployeesForAddResponse } from 'api/typing/cpaTypes'
import { containsText } from 'helpers/containsText'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import {
    CPAEmployeesDrawerContent,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerContent/CPAEmployeesDrawerContent'
import {
    CPAEmployeesDrawerSummarize,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerSummarize/CPAEmployeesDrawerSummarize'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { cpaItemEmployeesActions } from 'store/actions'

type CPAScaleDrawerProps = {
    open: boolean;
    handleClose: () => void;
}

// TODO: need to create universal method for search in list
const filterEmployees = (divisions: ICpaGetEmployeesForAddResponse[], search: string) => {
    return divisions.reduce((acc, division) => {
        const employees = division.employees.filter(employee => containsText(employee.firstName, search)
            || containsText(employee.lastName, search)
            || containsText(employee.secondName, search))
        if (employees.length)
            acc.push({ ...division, employees })
        return acc
    }, [] as ICpaGetEmployeesForAddResponse[])
}

export const CPAEmployeesDrawer: FC<CPAScaleDrawerProps> = ({ open, handleClose }) => {
    const { cpaUuid } = useParams()
    const [openConfirm, setOpenConfirm] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const { isLoading, error } = useCpaEmployeesList({ cpaUuid: cpaUuid || '' }, open)
    const employeesList = useAppSelector(state => state.CPAItemEmployeesReducer.employeesList)
    const hasEmployeesToSelect = useMemo(
        () => employeesList?.reduce((acc, division) => acc + division.employees.length, 0) || 0 > 0,
        [employeesList],
    )
    const filteredList = useMemo(
        () => filterEmployees(employeesList, search),
        [employeesList, search],
    )

    const onClose = () => setOpenConfirm(true)

    const confirmClose = () => {
        dispatch(cpaItemEmployeesActions.restoreEmployeeList())
        handleClose()
    }


    return (
        <>
            <DialogConfirm
                open={openConfirm}
                dialogTitle={TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS}
                dialogText="Вы уверены, что хотите закрыть страницу?"
                dialogHelperText="Внесенные изменения не будут сохранены."
                titleButtonConfirm="Закрыть без сохранения"
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={confirmClose}
                handleClose={() => setOpenConfirm(false)}
            />
            <Drawer
                anchor="right"
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        width: { mobile: '100%', laptop: 'calc(100% - 320px)' },
                    },
                }}
                ModalProps={{ keepMounted: true }}
            >
                <Box
                    role="presentation"
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <Box display="flex" flexDirection="column" px={5} pt={5}>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                            <Box flex={1}>
                                <Typography variant="h1">
                                    {TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS}
                                </Typography>
                            </Box>
                            <IconButton color="primary" onClick={onClose}>
                                <CloseRounded/>
                            </IconButton>
                        </Box>
                        {hasEmployeesToSelect &&
                            <Box mb={5} display="flex" justifyContent="space-between" alignItems="flex-end">
                                <Box flex={1} maxWidth={600}>
                                    <TextField
                                        fullWidth
                                        placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_EMPLOYEE}
                                        InputProps={{
                                            endAdornment: search.length > 0
                                                ? <IconButton
                                                    size="small"
                                                    onClick={() => setSearch('')}
                                                >
                                                    <ClearRounded color="disabled"/>
                                                </IconButton>
                                                : <SearchRounded color="disabled"/>,
                                        }}
                                        value={search}
                                        onChange={({ target: { value } }) => setSearch(value)}
                                    />
                                </Box>
                                <CPAEmployeesDrawerSummarize handleClose={handleClose} onClose={onClose}/>
                            </Box>}
                    </Box>
                    <Stack spacing={3} flex={1} overflow="auto" px={5} pb={5}>
                        <Divider/>
                        <>
                            {isLoading && <LoadingComponent/>}
                            {error && !isLoading && <ErrorPage error={error}/>}
                            {!error && !isLoading && hasEmployeesToSelect && <CPAEmployeesDrawerContent
                                divisions={filteredList}
                            />}
                            {!hasEmployeesToSelect && !isLoading && <EmptyPage
                                service="employee"
                                accessLevel="w"
                                contentText="Для запуска оценки добавьте сотрудников в компанию"
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<PeopleAltRounded/>}
                                    onClick={() => navigate(PATH_EMPLOYEES)}
                                >
                                    {TITLE_BUTTON_BACK_TO_EMPLOYEES}
                                </Button>
                            </EmptyPage>}
                        </>
                    </Stack>
                </Box>
            </Drawer>
        </>
    )
}
