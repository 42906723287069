import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { TITLE_BUTTON_OK } from 'helpers/enums/titles'

type DialogOAAssessmentPassedProps = {
    handleClose: () => void;
    open: boolean;
}

export const DialogOAAssessmentPassed: FC<DialogOAAssessmentPassedProps> = ({ open, handleClose }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {/*TODO: create title of modal*/}
                Поздравляем, результаты оценки готовы!
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body2">
                        Чтобы прокачать недостающие навыки - отправили тебе
                        на почту готовый план развития с материалами по каждой компетенции.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
                <Button onClick={handleClose}>{TITLE_BUTTON_OK}</Button>
            </DialogActions>
        </Dialog>
    )
}
