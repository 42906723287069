import axios, { AxiosStatic } from 'axios'
import { Assessment } from 'api/services/AssessmentService/assessment'

interface IAssessmentService {
    assessmentService: () => Assessment;
}

export class AssessmentService implements IAssessmentService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public assessmentService() {
        return new Assessment(this.axiosInstance)
    }
}
