import React, { FC, useState } from 'react'
import { Card, CardActionArea, CardContent, Chip, Icon, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { ChevronRightRounded } from '@mui/icons-material'
import {
    CPAEmployeesStatisticEmployeeDrawer,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeCard/components/CPAEmployeesStatisticEmployeeDrawer/CPAEmployeesStatisticEmployeeDrawer'
import {
    CPAEmployeesStatisticEmployeeIcon,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeIcon/CPAEmployeesStatisticEmployeeIcon'

type CPAEmployeesStatisticEmployeeCardProps = {
    title: string;
    employeeList: string[];
    iconType: 'progress' | 'expired' | 'notStarted' | 'withoutParticipants';
}

export const CPAEmployeesStatisticEmployeeCard: FC<CPAEmployeesStatisticEmployeeCardProps> = ({
    title,
    employeeList,
    iconType,
}) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const itemCpa = useAppSelector(state => state.CPAItemReducer.meta)

    if (!employeeList || !employeeList.length) return null

    return (
        <>
            <CPAEmployeesStatisticEmployeeDrawer
                open={openDrawer}
                handleClose={() => setOpenDrawer(false)}
                title={title}
                iconType={iconType}
                employeeList={employeeList}
            />
            <Card
                sx={{
                    flex: 1,
                    minWidth: 'min-content',
                    maxWidth: { 'mobile': '100%', 'tablet': 'calc(50% - 16px)' },
                }}
            >
                <CardActionArea sx={{ height: '100%' }} onClick={() => setOpenDrawer(true)}>
                    <CardContent>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack spacing={1} alignItems="flex-start">
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <CPAEmployeesStatisticEmployeeIcon iconType={iconType}/>
                                    <Typography variant="body2">
                                        {title}
                                    </Typography>
                                </Stack>
                                <Chip
                                    label={`${employeeList.length}/${itemCpa.employeesCount}`}
                                    variant="outlined"
                                    color="warning"
                                />
                            </Stack>
                            <Icon>
                                <ChevronRightRounded/>
                            </Icon>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}
