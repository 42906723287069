import React, {FC, useEffect} from 'react'
import {Box, Button} from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TITLE_BUTTON_BACK_HISTORY } from 'helpers/enums/titles'
import {
    MyAssessmentMetaDesktop,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaDesktop/MyAssessmentMetaDesktop'

import { useNavigate, useParams } from 'react-router-dom'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'

import { useQuery } from 'react-query'
import { getAssessmentMetaFn } from 'api/queries/assessment/assessmentQueries'
import { appAction, myAssessmentActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useAppDispatch } from 'store/hooks/redux'

type HistoryAssessmentMetaProps = {
    parentPath: string;
}

export const HistoryAssessmentMeta: FC<HistoryAssessmentMetaProps> = ({parentPath}) => {
    const { assessmentUuid } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { error, isFetching } = useQuery({
        queryKey: ['useGetMyAssessmentMeta', assessmentUuid],
        queryFn: () => getAssessmentMetaFn(assessmentUuid || ''),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setMyAssessmentMeta(data))
        },
        retry: 0,
    })

    useEffect(() => {
        dispatch(appAction.setBackButtonPath(PATH_ASSESSMENT))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [])

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    if (!assessmentUuid) return <ErrorPage errorCode={404}/>
    return (
        <Box>
            <Button
                sx={{ mb: 3 }}
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate(parentPath, { replace: true })}
            >
                {TITLE_BUTTON_BACK_HISTORY}
            </Button>
            <MyAssessmentMetaDesktop/>
        </Box>
    )
}
