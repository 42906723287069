import React, { FC } from 'react'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { useAppSelector } from 'store/hooks/redux'
import { getYear, parse } from 'date-fns'
import { convertUtcStringToLocalString } from 'helpers/dateAndTime/convertUtcStringToLocalString'

type DrawerCommentAssessmentItemProps = {
    comment: IComment;
    disableEdit?: boolean
    deleteComment?: (uuid: string) => void;
}

export const DrawerCommentAssessmentItem: FC<DrawerCommentAssessmentItemProps> = ({
    comment,
    deleteComment,
    disableEdit,
}) => {
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const canEdit = uuid === comment.authorUuid
    const commentDate = comment?.createdAt
    const isValidDate = getYear(parse(commentDate, 'dd.MM.yyyy HH:mm', new Date())) > 1
    const formattedData = isValidDate ? convertUtcStringToLocalString(commentDate) : ''

    return (
        <Box display="flex">
            <Box flex={1}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Chip
                        label={comment.author}
                        size="small"
                        sx={{ mr: 1 }}
                    />
                    {isValidDate &&
                        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem/>}>
                            {formattedData?.split(' ')?.map(el =>
                                <Typography
                                    key={el}
                                    variant="body1"
                                >
                                    {el}
                                </Typography>)}
                        </Stack>}
                </Box>
                <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
                    {comment.text}
                </Typography>
            </Box>
            <Box>
                {canEdit && !disableEdit && deleteComment && <SecondaryActions
                    onDelete={() => deleteComment(comment.uuid)}
                />}
            </Box>
        </Box>
    )
}

