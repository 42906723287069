import React, { FC } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

type ExpandElementsProps = {
    open: boolean;
}

export const ExpandElements: FC<ExpandElementsProps> = ({ open }) => {
    return (
        <>
            {open ? <ExpandLess/> : <ExpandMore/>}
        </>
    )
}
