import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
} from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeWorkInCompanyProjectsAndRolesState> = {
    data: {} as IEmployeeWorkInCompanyProjectsAndRolesState,
    isLoading: false,
    error: null,
}

export const employeeEditWorkInCompanyProjectsAndRolesSlice = createSlice({
    name: 'employeeEditWorkInCompanyProjectsAndRoles',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getProjectsAndRolesList: (state, action: PayloadAction<IEmployeeWorkInCompanyProjectsAndRoles[]>) => {
            state.data.projectsAndRoles = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        deleteProjectsAndRolesItem: (state, action: PayloadAction<string>) => {
            state.data.projectsAndRoles = state.data.projectsAndRoles.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        createProjectsAndRolesItem: (
            state,
            action: PayloadAction<IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate>,
        ) => {
            const { uuid, roleUuid, projectUuid, endDate, startDate } = action.payload
            state.data.projectsAndRoles.push({
                uuid: uuid ?? '',
                endDate,
                startDate,
                role: state.data.roles.find(el => el.uuid === roleUuid) || {} as IEntity,
                project: state.data.projects.find(el => el.uuid === projectUuid) || {} as IEntity,
            })
            state.isLoading = false
            state.error = null
        },

        updateProjectsAndRolesItem: (
            state,
            action: PayloadAction<IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate>,
        ) => {
            const { roleUuid, projectUuid, ...data } = action.payload
            state.data.projectsAndRoles = state.data.projectsAndRoles.map(el => {
                if (el.uuid === action.payload.uuid)
                    return {
                        ...el,
                        ...data,
                        role: state.data.roles.find(el => el.uuid === roleUuid) || {} as IEntity,
                        project: state.data.projects.find(el => el.uuid === projectUuid) || {} as IEntity,
                    }
                return el
            })
            state.isLoading = false
            state.error = null
        },

        getProjects: (state, action: PayloadAction<IEntity[]>) => {
            state.data.projects = action.payload
            state.isLoading = false
            state.error = null
        },

        getRoles: (state, action: PayloadAction<IEntity[]>) => {
            state.data.roles = action.payload
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployeeWorkInCompanyProjectsAndRolesState
            state.lastQuery = null
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditWorkInCompanyProjectAndRolesReducer = employeeEditWorkInCompanyProjectsAndRolesSlice.reducer
