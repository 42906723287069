import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { ArrowForwardRounded } from '@mui/icons-material'

type CPAEmployeeDrawerPreviewAssessmentCriteriaCurrentProps = {
    grade: IEntity | null | undefined;
    specialization: IEntity | null | undefined;
}

export const CPAEmployeeDrawerPreviewAssessmentCriteriaCurrent:
    FC<CPAEmployeeDrawerPreviewAssessmentCriteriaCurrentProps> = ({
        specialization,
        grade,
    }) => {
        return (
            <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body1">
                    {specialization?.title} - {grade?.title}
                </Typography>
                <ArrowForwardRounded color="warning"/>
                <PointPositionSpecializationGrade
                    title={`${specialization?.title} - ${grade?.title} (Текущая)`}
                />
            </Stack>
        )
    }
