import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuItemsCodes, MenuItemsCodesWithAlerts } from '../../../../helpers/enums/menuItemsPosition'
import { ReactNode } from 'react'
import { AlertType } from '../../../hooks/useGetAlertsData'

export type AlertData = {
    bannerTitle: string;
    bannerIcon: ReactNode | null;
    tooltipTitle: string;
    backgroundColor: string;
    badgeColor: string;
    alertType: AlertType;
}

type AlertsSliceInitialState = {
    [key in MenuItemsCodesWithAlerts]: AlertData | null;
};

const initialState: AlertsSliceInitialState = {
    [MenuItemsCodes.DEVELOPMENT]: null,
    [MenuItemsCodes.MY_ASSESSMENTS]: null,
    [MenuItemsCodes.MY_EXAMS]: null,
    [MenuItemsCodes.EMPLOYEES]: null,
    [MenuItemsCodes.CPA]: null,
    [MenuItemsCodes.MASS_EXAM]: null,
}

export const alertsSlice = createSlice({
    name: 'alertsSlice',
    initialState,
    reducers: {
        setAlertData: (
            state,
            action: PayloadAction<{ menuItemCode: MenuItemsCodesWithAlerts, alertData: AlertData | null}>,
        ) => {
            state[action.payload.menuItemCode] = action.payload.alertData
        },
    },
})

export const alertsReducer = alertsSlice.reducer
