import { differenceInYears, formatDuration, isDate, isValid, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

export const getYearsOld = (date: string | Date) => {
    let findDate = date
    if (typeof findDate === 'string')
        findDate = parse(findDate, 'dd.MM.yyyy', new Date())

    if (!isValid(findDate) || !isDate(findDate)) return null

    const years = differenceInYears(new Date(), findDate)

    return formatDuration({ years }, { locale: ru, zero: true })
}
