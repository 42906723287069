import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
    TITLE_BUTTON_CONTINUE_NOW,
    TITLE_BUTTON_LATER,
    TITLE_DIALOG_EMPLOYEE_ADD,
} from 'helpers/enums/titles'
import { HINT_CONTINUE_CREATE_EMPLOYEE, HINT_SUCCESS_CREATE_EMPLOYEE } from 'helpers/enums/hints'
import { useNavigate } from 'react-router-dom'
import { PATH_EMPLOYEES, PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'

type DialogContinueCreateEmployeeProps = {
    handleClose: () => void;
    open: boolean;
    employeeUuid: string;
}

export const DialogContinueCreateEmployee: FC<DialogContinueCreateEmployeeProps> = ({
    open,
    handleClose,
    employeeUuid,
}) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    const onConfirmContinue = () => {
        navigate(
            `${PATH_EMPLOYEES}/${employeeUuid}/${PATH_EMPLOYEES_EDIT}/personal-data`,
            { state: { continueCreate: true } },
        )
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_EMPLOYEE_ADD}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <Typography variant="body1" mb={1}>
                        {HINT_SUCCESS_CREATE_EMPLOYEE}
                    </Typography>
                    <Typography variant="body1">
                        {HINT_CONTINUE_CREATE_EMPLOYEE}
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_LATER}</Button>
                    <Button
                        variant="contained"
                        onClick={onConfirmContinue}
                    >
                        {TITLE_BUTTON_CONTINUE_NOW}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
