import React, { FC } from 'react'
import { ICPAGetCriteriaCompetenceSkillLevel } from 'api/typing/cpaTypes'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_SKILL_LEVEL } from 'helpers/enums/titles'
import {
    CPACriteriaDrawerItemCompetenceItem,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/components/CPACriteriaDrawerItemCompetenceItem/CPACriteriaDrawerItemCompetenceItem'

type CPACriteriaDrawerItemCompetenceSkillLevelProps = {
    item: ICPAGetCriteriaCompetenceSkillLevel;
}

export const CPACriteriaDrawerItemCompetenceSkillLevel: FC<CPACriteriaDrawerItemCompetenceSkillLevelProps> = ({
    item,
}) => {
    if (!item.competencies.length) return null
    return (
        <Card>
            <CardContent>
                <Stack spacing={2} divider={<Divider/>}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            {item.title}
                        </Typography>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_SKILL_LEVEL}
                        </Typography>
                    </Box>
                    {item.competencies.map(el => <CPACriteriaDrawerItemCompetenceItem
                        key={el.uuid}
                        item={el}
                        competenceTypeUuid={item.uuid}
                    />)}
                </Stack>
            </CardContent>
        </Card>
    )
}
