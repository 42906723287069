import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useGetAssessmentResult, useGetAssessmentResultAccordance } from 'api/queries/assessment/assessmentQueries'
import { useFormCpaRoles } from 'api/queries/form/formQueries'
import { useCpaCriteriaPoint } from 'api/queries/cpa/cpaQueries'
import { getCpaCriteria } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import {
    AssessmentResultDesktop,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/AssessmentResultDesktop'
import { useMediaQuery, useTheme } from '@mui/material'
import {
    AssessmentResultMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/AssessmentResultMobile'

type AssessmentResultProps = {
    assessmentUuid?: string;
    children?: React.ReactNode;
}

export const AssessmentResult: FC<AssessmentResultProps> = ({
    assessmentUuid: assessmentUuidFromProps,
    children,
}) => {
    const { assessmentUuid } = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { data, isFetching, error } = useGetAssessmentResult((assessmentUuidFromProps ?? assessmentUuid) || '')
    const { isFetching: isFetchingCpaRoles, error: errorCpaRoles } = useFormCpaRoles(true)
    const { data: dataCriteria, isFetching: isFetchingCriteria, error: errorCriteria } = useCpaCriteriaPoint(
        data?.cpa?.uuid || '',
        Boolean(data?.cpa?.uuid && getCpaCriteria(data?.cpa?.criteria?.uuid)?.code === 'point'),
    )
    const { isFetching: isFetchingAccordance, error: errorAccordance } = useGetAssessmentResultAccordance(
        (assessmentUuidFromProps ?? assessmentUuid) || '',
        Boolean(data && dataCriteria?.grade?.uuid && isEmptyUuid(dataCriteria?.grade?.uuid)),
    )

    if (isFetching || isFetchingCpaRoles || isFetchingCriteria || isFetchingAccordance) return <LoadingComponent/>
    if (error || errorCpaRoles || errorCriteria || errorAccordance)
        return <ErrorPage error={error || errorCpaRoles || errorCriteria || errorAccordance}/>
    if (!data) return null

    if (mobile) return <AssessmentResultMobile>{children}</AssessmentResultMobile>

    return <AssessmentResultDesktop>{children}</AssessmentResultDesktop>
}
