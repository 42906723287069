import React, { FC } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ResponsiveRadialBar } from '@nivo/radial-bar'


type CPAAnalyticContentGraphicProps = {
    total: number | null;
}

export const CPAAnalyticContentGraphic: FC<CPAAnalyticContentGraphicProps> = ({ total }) => {
    const theme = useTheme()
    const normalizeAverage = total === null ? 0 : total > 100 ? 100 : total < 0 ? 0 : total
    const averageColor = (() => {
        switch (true) {
            case normalizeAverage >= 50 && normalizeAverage <= 75: {
                return theme.palette.warning.main
            }
            case normalizeAverage > 75: {
                return theme.palette.success.main
            }
            default: {
                return theme.palette.secondary.main
            }
        }
    })()
    const data = [
        {
            id: 'average',
            data: [{
                x: '',
                y: normalizeAverage,
                color: averageColor,
            }],
        },
    ]
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {total === null || isNaN(total)
                ? <Typography variant="h3">Нет данных</Typography>
                : <Typography variant="h1">{total}%</Typography>}
            <Box width={140} height={70}>
                <ResponsiveRadialBar
                    data={data}
                    startAngle={-90}
                    endAngle={90}
                    padding={0.4}
                    maxValue={100}
                    cornerRadius={6}
                    isInteractive={false}
                    margin={{ top: -10, right: 0, bottom: -70, left: 0 }}
                    colors={{ datum: 'data.color' }}
                    enableRadialGrid={false}
                    enableCircularGrid={false}
                    radialAxisStart={null}
                    circularAxisOuter={null}
                />
            </Box>
        </Stack>
    )
}
