import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tab, Tabs } from '@mui/material'
import { PATH_PROFILE } from 'helpers/enums/routePath'
import { TabPanel } from 'components/tabs/TabPanel/TabPanel'
import {
    TITLE_NAMESPACE_COMPETENCIES,
    TITLE_NAMESPACE_INFO_ABOUT_SELF,
} from 'helpers/enums/titles'
import { viewProfileTabs } from 'helpers/enums/tabs/profile/viewProfileTabs'
import {
    EmployeeTabInfo,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabInfo/EmployeeTabInfo'
import { useAppSelector } from 'store/hooks/redux'
import {
    EmployeeTabCompetencies,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabCompetencies/EmployeeTabCompetencies'

export const ProfileTabs = () => {
    const { tabPanel } = useParams()
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const navigate = useNavigate()

    const [value, setValue] = useState(viewProfileTabs.findIndex(el => el === tabPanel) < 0
        ? 0
        : viewProfileTabs.findIndex(el => el === tabPanel))

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        navigate(`${PATH_PROFILE}/${viewProfileTabs[newValue] || viewProfileTabs[0]}`)
    }

    useEffect(() => {
        setValue(viewProfileTabs.findIndex(el => el === tabPanel) < 0
            ? 0
            : viewProfileTabs.findIndex(el => el === tabPanel))
    }, [tabPanel])


    return (
        <Box sx={{
            width: '100%',
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={TITLE_NAMESPACE_INFO_ABOUT_SELF} />
                    <Tab label={TITLE_NAMESPACE_COMPETENCIES} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {uuid && <EmployeeTabInfo employeeUuid={uuid} parentPath={PATH_PROFILE} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {uuid && <EmployeeTabCompetencies blockLink={true} employeeUuid={uuid} />}
            </TabPanel>
        </Box>
    )
}

