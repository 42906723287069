import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { TITLE_BUTTON_OK } from 'helpers/enums/titles'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Instruction } from 'helpers/enums/Instructions'

type DialogInstructionProps = {
    dialogTitle: string;
    instruction: Instruction;
    handleClose: () => void;
    open: boolean;
}

export const DialogInstruction: FC<DialogInstructionProps> = ({ open, handleClose, instruction, dialogTitle }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Stack spacing={3} component={'ol'}>
                        {instruction.textIntro ? <Typography variant="body2">
                            {instruction.textIntro}
                        </Typography> : null}
                        {instruction.listItems.map((el, idx) => (
                            <Box key={el.title}>
                                <Typography key={el.title} variant="body2">
                                    {idx + 1}. {el.title}
                                </Typography>
                                {el.subList.length > 0 && <Box mt={1} ml={1}>
                                    {el.subList.map(subListItem => (
                                        <Box display="flex" key={subListItem.title}>
                                            <Box mr={1}>
                                                &bull;
                                            </Box>
                                            <Typography key={subListItem.title} variant="body2">
                                                {subListItem.title}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>}
                            </Box>
                        ))}
                        {instruction.textConclusion ? <Typography variant="body2">
                            {instruction.textConclusion}
                        </Typography> : null}
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
                <Button onClick={handleClose}>{TITLE_BUTTON_OK}</Button>
            </DialogActions>
        </Dialog>
    )
}

