export const formatHours = (hours: number | null | undefined) => {
    // if (hours >= 720) {
    //     return `${Math.floor(hours / 720)} мес`
    // }
    // if (hours >= 24 && hours < 720) {
    //     return `${Math.floor(hours / 24)} д`
    // }
    const hoursCount = hours ?? 0
    return `${hoursCount} ч`
}
