import React, { FC } from 'react'
import { Box, CSSObject, Drawer as MuiDrawer, IconButton, styled, Theme, useTheme } from '@mui/material'
import {
    AppMenuDesktopFooter,
} from 'components/AppMenu/components/AppMenuDesktop/components/AppMenuDesktopFooter/AppMenuDesktopFooter'
import {
    AppMenuDesktopContent,
} from 'components/AppMenu/components/AppMenuDesktop/components/AppMenuDesktopContent/AppMenuDesktopContent'
import {
    AppMenuDesktopHeader,
} from 'components/AppMenu/components/AppMenuDesktop/components/AppMenuDesktopHeader/AppMenuDesktopHeader'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useLocalStorage } from 'react-use'

const drawerWidth = 320

const openedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
    overflowX: 'hidden',
})

const closedMixinIcon = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    left: '300px',
})

const openedMixinIcon = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    left: '50px',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

export const AppMenuDesktop: FC = () => {
    const theme = useTheme()
    const [isMenuOpen, setIsMenuOpen] = useLocalStorage('openMenu', true)

    return (
        <>
            <Drawer
                variant="permanent"
                open={isMenuOpen}
                PaperProps={{
                    sx: {
                        background: theme.palette.background.default,
                    },
                }}
            >
                <IconButton
                    disableRipple
                    sx={{
                        position: 'fixed',
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        border: '1px solid #E2E7E9',
                        top: '120px',
                        zIndex: theme.zIndex.drawer,
                        overflowX: 'hidden',
                        ...(!isMenuOpen && openedMixinIcon(theme)),
                        ...(isMenuOpen && closedMixinIcon(theme)),
                    }}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    {isMenuOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
                <AppMenuDesktopHeader/>
                <Box flex="1">
                    <AppMenuDesktopContent/>
                </Box>
                <AppMenuDesktopFooter/>
            </Drawer>
        </>
    )
}
