import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { TITLE_NAMESPACE_BEST_COMPETENCIES } from 'helpers/enums/titles'

const getMapBests = (groups: IGradeCompetenciesGroup[], map = new Map<string, string[]>()) => {
    if (!groups) return new Map<string, string[]>()
    groups.forEach(group => {
        group.competencies.forEach(competence => {
            if (competence.score > 75) {
                map.set(competence.competenceTypeTitle, map.get(competence.competenceTypeTitle)?.length
                    ? [...map.get(competence.competenceTypeTitle) ?? [], competence.title]
                    : [competence.title])
            }
        })
        if (group.subGroups.length > 0)
            getMapBests(group.subGroups, map)
    })

    return map
}

export const EmployeeStrongSides = () => {
    const {
        existingCompetenceTypes,
        groupCompetencies,
    } = useAppSelector(state => state.employeeViewCompetenciesReducer.data)


    const arrayBests = getMapBests(groupCompetencies)

    return (
        <Box>
            <Grid container spacing={3}>
                {existingCompetenceTypes?.map(typeTitle =>
                    <Grid key={typeTitle} item mobile={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2" mb={3}>
                                    {TITLE_NAMESPACE_BEST_COMPETENCIES} <br/>{typeTitle}
                                </Typography>
                                <Grid container spacing={1}>
                                    {arrayBests.get(typeTitle)?.length ?
                                        arrayBests.get(typeTitle)?.map(title =>
                                            <Grid key={title} item mobile="auto" sx={{ maxWidth: '100% !important' }}>
                                                <Chip
                                                    label={title}
                                                    color="primary"
                                                    size="small"
                                                />
                                            </Grid>)
                                        : <Chip
                                            label="Пока не обнаружены"
                                        />}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>,
                )}
            </Grid>
        </Box>
    )
}
