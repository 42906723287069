import React, { FC, lazy, Suspense } from 'react'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { Loading } from 'components/Loading/Loading'
import {
    PATH_ANALYTICS,
    PATH_ASSESSMENT,
    PATH_CPA,
    PATH_CPA_ANALYTICS,
    PATH_DEVELOPMENT,
    PATH_EMPLOYEES,
    PATH_EMPLOYEES_EDIT,
    PATH_ERROR,
    PATH_EXAM,
    PATH_HISTORY,
    PATH_MASS_EXAM,
    PATH_PROFILE,
    PATH_SPECIALIZATION,
} from 'helpers/enums/routePath'
import { ProtectedRoute } from 'components/auth/ProtectedRoute/ProtectedRoute'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { HomePage } from 'pages/home/HomePage/HomePage'
import { useApp } from 'api/queries/profile/profileQueries'
import { CPAPage } from 'pages/cpa/CPAPage/CPAPage'
import { MyAssessmentPass } from 'pages/assessment/MyAssessmentPass/MyAssessmentPass'
import { EmployeeAssessmentPage } from 'pages/employees/EmployeeAssessmentPage/EmployeeAssessmentPage'
import { EmployeeAssessmentPreview } from 'pages/employees/EmployeeAssessmentPreview/EmployeeAssessmentPreview'
import { AppMenu } from 'components/AppMenu/AppMenu'
import { ButtonScrollTop } from 'components/common/ButtonScrollTop/ButtonScrollTop'

const SpecializationPage = lazy(() => import('pages/specialization/SpecializationPage/SpecializationPage').then(module => ({ default: module.SpecializationPage })))
const CompetenceConfigPage = lazy(() => import('pages/specialization/CompetenceConfigPage/CompetenceConfigPage').then(module => ({ default: module.CompetenceConfigPage })))
const EmployeesPage = lazy(() => import('pages/employees/EmployeesPage/EmployeesPage').then(module => ({ default: module.EmployeesPage })))
const EmployeePage = lazy(() => import('pages/employees/EmployeePage/EmployeePage').then(module => ({ default: module.EmployeePage })))
const AnalyticPage = lazy(() => import('pages/analytic/AnalyticPage/AnalyticPage').then(module => ({ default: module.AnalyticPage })))
const GradePage = lazy(() => import('pages/specialization/GradePage/GradePage').then(module => ({ default: module.GradePage })))
const CompetenceConfigSetupPage = lazy(() => import('pages/specialization/CompetenceConfigSetupPage/CompetenceConfigSetupPage').then(module => ({ default: module.CompetenceConfigSetupPage })))
const SpecializationList = lazy(() => import('pages/specialization/SpecializationList/SpecializationList').then(module => ({ default: module.SpecializationList })))
const CompetenceLayoutFromMatrix = lazy(() => import('pages/specialization/CompetenceLayoutFromMatrix/CompetenceLayoutFromMatrix').then(module => ({ default: module.CompetenceLayoutFromMatrix })))
const CompetenceLayoutFromGrade = lazy(() => import('pages/specialization/CompetenceLayoutFromGrade/CompetenceLayoutFromGrade').then(module => ({ default: module.CompetenceLayoutFromGrade })))
const EmployeeEdit = lazy(() => import('pages/employees/EmployeePage/components/EmployeeEdit/EmployeeEdit').then(module => ({ default: module.EmployeeEdit })))
const CompetenceLayoutFromEmployee = lazy(() => import('pages/specialization/CompetenceLayoutFromEmployee/CompetenceLayoutFromEmployee').then(module => ({ default: module.CompetenceLayoutFromEmployee })))
const DevelopmentPage = lazy(() => import('pages/development/DevelopmentPage/DevelopmentPage').then(module => ({ default: module.DevelopmentPage })))
const HistoryPage = lazy(() => import('pages/history/HistoryPage/HistoryPage').then(module => ({ default: module.HistoryPage })))
const ProfilePage = lazy(() => import('pages/profile/ProfilePage/ProfilePage').then(module => ({ default: module.ProfilePage })))
const ProfileEdit = lazy(() => import('pages/profile/ProfileEdit/ProfileEdit').then(module => ({ default: module.ProfileEdit })))
const EmployeeAssessmentResult = lazy(() => import('pages/employees/EmployeeAssessmentResult/EmployeeAssessmentResult').then(
    module => ({ default: module.EmployeeAssessmentResult })))
const MyAssessmentList = lazy(() => import('pages/assessment/MyAssessmentList/MyAssessmentList').then(module => ({ default: module.MyAssessmentList })))
const MyAssessmentPreview = lazy(() => import('pages/assessment/MyAssessmentPreview/MyAssessmentPreview').then(module => ({ default: module.MyAssessmentPreview })))
const MyAssessmentMeta = lazy(() => import('pages/assessment/MyAssessmentMeta/MyAssessmentMeta').then(module => ({ default: module.MyAssessmentMeta })))
const MyAssessmentResult = lazy(() => import('pages/assessment/MyAssessmentResult/MyAssessmentResult').then(module => ({ default: module.MyAssessmentResult })))
const CPAListPage = lazy(() => import('pages/cpa/CPAListPage/CPAListPage').then(module => ({ default: module.CPAListPage })))
const CPAAnalyticPage = lazy(() => import('pages/cpa/CPAAnalyticPage/CPAAnalyticPage').then(module => ({ default: module.CPAAnalyticPage })))
const CPAEmployeePage = lazy(() => import('pages/cpa/CPAEmployeePage/CPAEmployeePage').then(module => ({ default: module.CPAEmployeePage })))
const CPAEmployeeAssessmentResult = lazy(() => import('pages/cpa/CPAEmployeeAssessmentResult/CPAEmployeeAssessmentResult').then(
    module => ({ default: module.CPAEmployeeAssessmentResult })))
const CPAEmployeeAssessmentPass = lazy(() => import('pages/cpa/CPAEmployeeAssessmentPass/CPAEmployeeAssessmentPass').then(
    module => ({ default: module.CPAEmployeeAssessmentPass })))
const MassExamListPage = lazy(() => import('pages/MassExamListPage/MassExamListPage').then(module => ({ default: module.MassExamListPage })))
const ExamListPage = lazy(() => import('pages/ExamListPage/ExamListPage').then(module => ({ default: module.ExamListPage })))
const ExamPage = lazy(() => import('pages/ExamPage/ExamPage').then(module => ({ default: module.ExamPage })))
const ExamResultsPage = lazy(() => import('pages/ExamResultPage/ExamResultPage').then(module => ({ default: module.ExamResultPage })))
const MassExamPage = lazy(() => import('pages/MassExamPage/MassExamPage').then(module => ({ default: module.MassExamPage })))
const MassExamConstructorPage = lazy(() => import('pages/MassExamConstructorPage/MassExamConstructorPage').then(module => ({ default: module.MassExamConstructorPage })))

const AppPage: FC = () => {
    const { isLoading, error } = useApp()
    const [scrollTarget, setScrollTarget] = React.useState<Node | undefined>(undefined)
    if (isLoading) return <Loading/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                maxHeight: '100dvh',
                flexDirection: { 'mobile': 'column', 'desktop': 'row' },
            }}
        >
            <AppMenu/>
            <Box
                component="main"
                ref={(node: Node) => {
                    if (node) {
                        setScrollTarget(node)
                    }
                }}
                sx={{
                    flexGrow: 1,
                    p: { 'mobile': 3, 'desktop': 5 },
                    position: 'relative',
                    // height: '100vh',
                    // maxHeight: '100dvh',
                    overflow: 'auto',
                }}
            >
                <Suspense fallback={<Loading/>}>
                    <Routes>
                        <Route path={PATH_CPA} element={<ProtectedRoute service="cpa" accessLevel="r"/>}>
                            <Route index element={<CPAListPage/>}/>
                            <Route path=":cpaUuid">
                                <Route index element={<CPAPage/>}/>
                                <Route path={PATH_CPA_ANALYTICS} element={<CPAAnalyticPage/>}/>
                                <Route path="employee">
                                    <Route index element={<ErrorPage errorCode={404}/>}/>
                                    <Route path=":employeeUuid">
                                        <Route index element={<CPAEmployeePage/>}/>
                                        <Route path="assessment">
                                            <Route index element={<ErrorPage errorCode={404}/>}/>
                                            <Route path=":assessmentUuid">
                                                <Route index element={<CPAEmployeeAssessmentResult/>}/>
                                                <Route
                                                    path="result"
                                                    element={<CPAEmployeeAssessmentResult/>}
                                                />
                                                <Route
                                                    path="pass"
                                                    element={<CPAEmployeeAssessmentPass/>}
                                                />
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>

                        <Route
                            path={`${PATH_PROFILE}/*`}
                            element={<ProtectedRoute service="profile" accessLevel="r"/>}
                        >
                            <Route index element={<ProfilePage/>}/>
                            <Route path=":tabPanel/*" element={<ProfilePage/>}/>
                            <Route
                                path={`${PATH_EMPLOYEES_EDIT}/*`}
                                element={<ProtectedRoute service="profile" accessLevel="w"/>}
                            >
                                <Route index element={<ProfileEdit/>}/>
                                <Route path=":tabPanel" element={<ProfileEdit/>}/>
                            </Route>
                        </Route>

                        <Route
                            path={PATH_EMPLOYEES}
                            element={<ProtectedRoute service="employee" accessLevel="r"/>}
                        >
                            <Route index element={<EmployeesPage/>}/>
                            <Route path=":tabPanel" element={<EmployeesPage/>}/>
                            <Route path=":employeeUuid/*">
                                <Route path=":tabPanel/*" element={<EmployeePage/>}/>
                                <Route
                                    path={`${PATH_EMPLOYEES_EDIT}/*`}
                                    element={<ProtectedRoute service="employee" accessLevel="w"/>}
                                >
                                    <Route index element={<EmployeeEdit/>}/>
                                    <Route path=":tabPanel" element={<EmployeeEdit/>}/>
                                </Route>
                                <Route
                                    path="specialization/:specializationUuid/competence/:competenceUuid/:entityType"
                                    element={<CompetenceLayoutFromEmployee/>}
                                />
                                <Route
                                    path="assessment/:assessmentUuid/result"
                                    element={<EmployeeAssessmentResult/>}
                                />
                                <Route
                                    path="assessment/:assessmentUuid/preview"
                                    element={<EmployeeAssessmentPreview/>}
                                />
                                <Route
                                    path="assessment/:assessmentUuid/pass"
                                    element={<EmployeeAssessmentPage/>}
                                />
                            </Route>
                        </Route>

                        <Route
                            path={PATH_SPECIALIZATION}
                            element={<ProtectedRoute service="specializations" accessLevel="r"/>}
                        >
                            <Route index element={<SpecializationList/>}/>
                            <Route path=":specializationUuid/*">
                                <Route path=":tabPanel/*">
                                    <Route index element={<SpecializationPage/>}/>
                                    <Route path=":gradeUuid/*">
                                        <Route path="competence">
                                            <Route
                                                path=":competenceUuid/:entityType"
                                                element={<CompetenceLayoutFromGrade/>}
                                            />
                                        </Route>
                                        <Route path=":tabPanel/*" element={<GradePage/>}/>
                                        <Route index element={<GradePage/>}/>
                                    </Route>
                                </Route>
                                <Route
                                    path="competence"
                                    element={<ProtectedRoute service="specializations" accessLevel="w"/>}
                                >
                                    <Route index element={<CompetenceConfigPage/>}/>
                                    <Route
                                        path=":competenceUuid/:entityType"
                                        element={<CompetenceLayoutFromMatrix/>}
                                    />
                                    <Route path="setup" element={<CompetenceConfigSetupPage/>}/>
                                </Route>
                            </Route>
                            <Route path=":specializationUuid" element={<SpecializationPage/>}/>
                        </Route>

                        <Route
                            path={PATH_ANALYTICS}
                            element={<ProtectedRoute service="my_development" accessLevel="r"/>}
                        >
                            <Route index element={<AnalyticPage/>}/>
                            <Route path=":tabPanel" element={<AnalyticPage/>}/>
                        </Route>

                        <Route
                            path={`${PATH_DEVELOPMENT}`}
                            element={<ProtectedRoute service="my_development" accessLevel="r"/>}
                        >
                            <Route path="pdp/*" element={<DevelopmentPage/>}/>
                        </Route>

                        <Route
                            path={`${PATH_HISTORY}/*`}
                            element={<ProtectedRoute service="history" accessLevel="r"/>}
                        >
                            <Route path="*" element={<HistoryPage />} />
                        </Route>

                        <Route
                            path={`${PATH_ASSESSMENT}/*`}
                            element={<ProtectedRoute service="my_development" accessLevel="r"/>}
                        >
                            <Route index element={<MyAssessmentList/>}/>
                            <Route path=":assessmentUuid">
                                <Route index element={<MyAssessmentMeta/>}/>
                                <Route path="pass" element={<MyAssessmentPass/>}/>
                                <Route path="preview" element={<MyAssessmentPreview/>}/>
                                <Route path="result" element={<MyAssessmentResult/>}/>
                            </Route>
                        </Route>

                        <Route
                            path={`${PATH_MASS_EXAM}`}
                            element={<ProtectedRoute service="mass_exam" accessLevel="r"/>}
                        >
                            <Route path="/mass-exam" element={<MassExamListPage/>}/>
                            <Route path=":massExamUuid">
                                <Route index element={<MassExamPage/>}/>
                                <Route path='constructor' element={<MassExamConstructorPage/>}/>
                                <Route path='exam-preview'>
                                    <Route path=":examUuid">
                                        <Route index element={<ExamPage/>}/>
                                        <Route path=":questionSort" element={<ExamPage/>}/>
                                    </Route>
                                </Route>
                                <Route path='employee/:employeeUuid/exam/:examUuid'>
                                    <Route path="result" element={<ExamResultsPage/>}/>
                                </Route>
                            </Route>
                        </Route>

                        <Route
                            path={`${PATH_EXAM}`}
                            element={<ProtectedRoute service="exam" accessLevel="r"/>}
                        >
                            <Route path="/exam" element={<ExamListPage/>}/>
                            <Route path=":examUuid">
                                <Route index element={<ExamPage/>}/>
                                <Route path=":questionSort" element={<ExamPage/>}/>
                                <Route path="result" element={<ExamResultsPage/>}/>
                            </Route>
                        </Route>

                        <Route path={PATH_ERROR} element={<ErrorPage/>}/>
                        <Route path="/*" element={<HomePage/>}/>
                        <Route path="*" element={<ErrorPage/>}/>
                    </Routes>
                </Suspense>
                <ButtonScrollTop target={scrollTarget}/>
            </Box>
        </Box>
    )
}

export default AppPage
