import React, { FC, useMemo, useState } from 'react'
import { Box, Collapse, Tooltip, Typography } from '@mui/material'
import { ChevronRightRounded, ExpandLess, ExpandMore, MoreHorizRounded } from '@mui/icons-material'
import {
    EmployeesTabCurrentEmployee,
} from 'pages/employees/EmployeesPage/components/EmployeesTabCurrent/components/EmployeesTabCurrentEmployee/EmployeesTabCurrentEmployee'

type EmployeesTabCurrentDepartmentProps = {
    element: IEmployeesDepartment;
}

export const EmployeesTabCurrentDepartment: FC<EmployeesTabCurrentDepartmentProps> = ({ element }) => {
    const [open, setOpen] = useState(true)
    const sortedFullPath = useMemo(() =>
        element.fullPath && [...element.fullPath]?.sort((a, b) =>
            a.sortIndex > b.sortIndex ? 1 : -1),
    [element?.fullPath?.length],
    )
    const fullPathTitleTooltip = sortedFullPath?.map(el => el.title).join(' / ')

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <Box mb={4}>
            <Box display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick} mb={3}>
                <Tooltip placement="bottom-start" title={fullPathTitleTooltip} enterDelay={300} enterNextDelay={300}>
                    <Box display="flex">
                        {fullPathTitleTooltip && <>
                            <MoreHorizRounded color="disabled"/>
                            <ChevronRightRounded color="disabled" sx={{ mr: 0.5 }}/>
                        </>}

                        <Typography mr={0.5} variant="subtitle1">
                            {element.title}
                        </Typography>
                    </Box>
                </Tooltip>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
            >
                {element?.employees?.map(employee =>
                    <EmployeesTabCurrentEmployee key={employee.uuid} element={employee} divisionUuid={element.uuid}/>)}
            </Collapse>
        </Box>
    )
}

