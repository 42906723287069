import React from 'react'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'

export const AppMenuMobileDrawerContentProfile = () => {
    const { photo, firstName, lastName } = useAppSelector(state => state.userReducer.data)
    return (
        <Box>
            <Stack direction="row" alignItems="center" spacing={2} px={3}>
                <Avatar
                    src={photo}
                    sx={{
                        width: 64,
                        height: 64,
                    }}
                />
                <Stack>
                    <Typography variant="body1">
                        {lastName}
                    </Typography>
                    <Typography variant="body1">
                        {firstName}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}
