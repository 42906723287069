import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assessmentStatusComplete } from 'helpers/assessment/getAssessmentStatus'


interface IOAAssessment {
    assessment: IAssessment;
    assessmentResult: IAssessmentResult;
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: IOAAssessment = {
    assessment: {} as IAssessment,
    assessmentResult: {} as IAssessmentResult,
    isLoading: false,
    error: null,
}

export const oaAssessmentSlice = createSlice({
    name: 'oaAssessment',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },

        setAssessment: (state, action: PayloadAction<IAssessment>) => {
            state.assessment = action.payload
        },

        setAssessmentStatusComplete: (state) => {
            state.assessment.statusUuid = assessmentStatusComplete
        },

        setAssessmentResult: (state, action: PayloadAction<IAssessmentResult>) => {
            state.assessmentResult = action.payload
            let count = 1
            const setTitleNew = (groups: IAssessmentCompetenceGroup[]) => {
                return groups.map(g => {
                    if (g.groupCompetence.length)
                        setTitleNew(g.groupCompetence)

                    if (g.competencies.length)
                        // eslint-disable-next-line no-param-reassign
                        g.competencies = g.competencies.map(c => {
                            const title = `${count}. ${c.title}`
                            count++
                            return { ...c, title }
                        })
                })
            }
            setTitleNew(state.assessmentResult.groupCompetence)
        },

        checkAssessmentAnswers: (state, action: PayloadAction<string[]>) => {
            const answers = new Set<string>(action.payload)
            state.assessment.competencies = state.assessment.competencies.map(competence => {
                // eslint-disable-next-line no-param-reassign
                competence.indicators = competence.indicators.map(indicator => {
                    const res = { ...indicator }
                    if (!answers.has(indicator.uuid))
                        res.error = true
                    return res
                })
                return competence
            })

        },
    },
})

export const oaAssessmentReducer = oaAssessmentSlice.reducer
