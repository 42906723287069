import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IEmployeeEditEducationCourseCreateOrUpdate,
} from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeEditEducationCourse[]> = {
    data: [],
    isLoading: false,
    error: null,
}

export const employeeEditEducationCoursesSlice = createSlice({
    name: 'employeeEditEducationCourses',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getCourseList: (state, action: PayloadAction<IEmployeeEditEducationCourse[]>) => {
            state.data = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        createCourse: (state, action: PayloadAction<IEmployeeEditEducationCourseCreateOrUpdate>) => {
            const { uuid } = action.payload
            if (!uuid) return
            state.data.push({
                uuid,
                ...action.payload,
            })
            state.isLoading = false
            state.error = null
        },

        updateCourse: (state, action: PayloadAction<IEmployeeEditEducationCourseCreateOrUpdate>) => {
            state.data = state.data.map(el => {
                if (el.uuid === action.payload.uuid)
                    return {
                        ...el,
                        ...action.payload,
                    }
                return el
            })
            state.isLoading = false
            state.error = null
        },

        deleteCourse: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = []
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditEducationCoursesReducer = employeeEditEducationCoursesSlice.reducer
