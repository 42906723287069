import React, { FC, useMemo } from 'react'
import { Chip } from '@mui/material'

interface ExamResultChipForHistoryProps {
    result: string;
    percent: number;
}

export const ExamResultChipForHistory: FC<ExamResultChipForHistoryProps> = ({ result, percent}) => {

    const resultIconColor = useMemo(() => {
        switch (result) {
            case 'success':
                return 'success'
            case 'failed':
                return 'error'
            default:
                return 'primary'
        }
    }, [result])

    return (
        <Chip
            label={`${percent}%`}
            variant='outlined'
            color={resultIconColor}
        />
    )
}
