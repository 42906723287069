import React, { FC } from 'react'
import { Box, Stack, Theme, Typography, useTheme } from '@mui/material'
import { AutoGraphRounded, CheckCircleOutlineRounded, RadioButtonUncheckedRounded } from '@mui/icons-material'

type GFEducationStatusProgressProps = {
    progress: number;
    isPassed?: boolean;
    entityType: EntityType;
}

const getCompetenceIcon = (progress: number, theme: Theme) => {
    switch (true) {
        case progress < 100 && progress > 0: {
            return <AutoGraphRounded sx={{ color: theme.palette.common.white }} />
            break
        }
        case progress === 100: {
            return <CheckCircleOutlineRounded sx={{ color: theme.palette.common.white }} />
            break
        }
        default: {
            return null
            break
        }
    }
}

const UniqueProgress: FC<{ isPassed?: boolean }> = ({ isPassed }) => {
    const { palette } = useTheme()
    if (isPassed)
        return (<CheckCircleOutlineRounded sx={{ color: palette.common.white }} />)
    return (<RadioButtonUncheckedRounded sx={{ color: palette.text.disabled }} />)
}

const CompetenceProgress: FC<{ progress: number }> = ({ progress }) => {
    const theme = useTheme()
    const IconProgress = getCompetenceIcon(progress, theme)

    return (
        <Stack direction="row" spacing={0.5}
            color={progress === 0 ? theme.palette.text.disabled : theme.palette.common.white}>
            {IconProgress}
            <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                color={progress === 0 ? theme.palette.text.disabled : theme.palette.common.white}
            >
                {progress}%
            </Typography>
        </Stack>
    )
}

export const GFEducationStatusProgress: FC<GFEducationStatusProgressProps> = ({ progress, isPassed, entityType }) => {
    const isUnique = entityType === 'unique'
    const { palette } = useTheme()
    let colorBox = ''

    switch (true) {
        case isUnique && isPassed || !isUnique && progress === 100: {
            colorBox = palette.success.main
            break
        }
        case !isUnique && progress < 100 && progress > 0: {
            colorBox = palette.primary.main
            break
        }
        default: {
            colorBox = palette.background.default
            break
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: colorBox,
                width: 80,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: isUnique || !isUnique && progress === 0 ? 'center' : 'flex-start',
                p: 0.5,
                borderRadius: 4,
                border: isUnique && !isPassed || !isUnique && progress === 0 ? 1 : 0,
            }}
        >
            {isUnique
                ? <UniqueProgress isPassed={isPassed} />
                : <CompetenceProgress progress={progress} />}
        </Box>
    )
}
