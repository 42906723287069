import { mainTheme } from 'theme/mainTheme'
import {
    TITLE_NAMESPACE_PERMISSION_DENIED,
    TITLE_NAMESPACE_PERMISSION_EDIT,
    TITLE_NAMESPACE_PERMISSION_EDIT_ALL,
    TITLE_NAMESPACE_PERMISSION_VIEW,
    TITLE_NAMESPACE_PERMISSION_VIEW_ALL,
} from 'helpers/enums/titles'
import { AccessLevel } from '../types/enums'

// Uuids прав доступа
export const permissionViewAll = 'd2018df9-6863-415c-994c-59dc48512211'
export const permissionEditAll = '235580c2-22b7-472f-8b42-cd81fc8534e9'
export const permissionDenied = '9716f125-f12c-4129-a1d6-8e2d9dc1fcfa'
export const permissionEdit = '8df8b9c1-c240-4d84-b009-8d5b2434dd15'
export const permissionView = '9ef48149-a8ce-4439-b6f6-a9f384b584d4'

export type PermissionKey = typeof permissionViewAll
    | typeof permissionView
    | typeof permissionEditAll
    | typeof permissionEdit
    | typeof permissionDenied


type Permission = {
    uuid: PermissionKey;
    accessLevel: AccessLevel;
    title: string,
    chipColor: string,
    textColor: string,
    tooltip?: string;
}

// Карта ролей
export const appRoles = {
    'methodist': '789d83b9-8ef1-42a6-b7f1-bb36ca666988',
    'zero': 'f46fa569-f39c-4247-902a-17401402cf6e',
    'root': 'e90bfd89-7506-42bb-a83d-367ce0cda4c1',
    'employee': 'dfb93abd-62f6-4925-b089-5c5a8a0e910e',
    'hr': '175541b2-4fbf-4950-9082-e2ebe3226486',
    'admin': '2cafb148-1b94-408a-ab3b-4c8b084fe3a3',
    'manager': 'd63ec970-e2f3-405a-9819-fd07aba308b8',
}

// Сервисы приложения, разбиты по приоритетам, сделано для того,чтобы в случае отсутсвия каких либо сервисов
// в доступе, отображать на главной странице следующий по приоритету
export const services: Record<PermissionServiceCode, IEntity & { priority: number }> = {
    'my_development': {
        uuid: '57cf5944-31ff-4000-b365-995a0a54659b',
        title: 'Мое развитие',
        priority: 6,
    },
    'profile': {
        uuid: 'e8f6137d-8f59-4378-a71f-8d7ce2609450',
        title: 'Мой профиль',
        priority: 5,
    },
    'employee': {
        uuid: 'b5ffb570-1e50-4398-92b1-a6642cc268b7',
        title: 'Сотрудники',
        priority: 1,
    },
    'pdp': {
        uuid: 'e02bcd34-637a-4153-8671-b4fe60120908',
        title: 'Текущее развитие сотрудников(ИПР)',
        priority: 8,
    },
    'assessment': {
        uuid: 'e02bcd34-637a-4153-8671-b4fe60120908',
        title: 'Текущее развитие сотрудников(ИПР)',
        priority: 8,
    },
    'history': {
        uuid: 'a40c7e61-e9a4-425f-827b-4f8cab36a25a',
        title: 'История',
        priority: 7,
    },
    'specializations': {
        uuid: 'ac7c30c4-d4c2-4e95-8ccd-30d7b380156a',
        title: 'Специализации',
        priority: 2,
    },
    'competence_base': {
        uuid: '7a33d882-f3ec-45c7-9e69-3212e39c86bd',
        title: 'База компетенций',
        priority: 3,
    },
    'dictionary': {
        uuid: '1dce4573-ac3b-45e2-9a70-8b76fd51cf79',
        title: 'Справочники',
        priority: 4,
    },
    'cpa': {
        uuid: 'e02bcd34-637a-4153-8671-b4fe60120908',
        title: 'Текущее развитие сотрудников CPA',
        priority: 8,
    },
    'mass_exam': {
        uuid: 'e02bcd34-637a-4153-8671-b4fe60120908',
        title: 'Тестирование',
        priority: 8,
    },
    'exam': {
        uuid: '57cf5944-31ff-4000-b365-995a0a54659b',
        title: 'Мои тесты',
        priority: 8,
    },
}

// Карта прав доступа, указывающая уровень доступа и мета информацию
export const permissions: Record<PermissionKey, Permission> = {
    [permissionEditAll]: {
        uuid: permissionEditAll,
        accessLevel: AccessLevel.WX,
        title: TITLE_NAMESPACE_PERMISSION_EDIT_ALL,
        // @ts-ignore
        chipColor: mainTheme.palette.success[50],
        textColor: mainTheme.palette.success.dark,
        tooltip: '',
    },
    [permissionEdit]: {
        uuid: permissionEdit,
        accessLevel: AccessLevel.W,
        title: TITLE_NAMESPACE_PERMISSION_EDIT,
        // @ts-ignore
        chipColor: mainTheme.palette.success[50],
        textColor: mainTheme.palette.success.dark,
        tooltip: 'Только свои подразделения',
    },
    [permissionViewAll]: {
        uuid: permissionViewAll,
        accessLevel: AccessLevel.RX,
        title: TITLE_NAMESPACE_PERMISSION_VIEW_ALL,
        // @ts-ignore
        chipColor: mainTheme.palette.primary[50],
        textColor: mainTheme.palette.primary.dark,
    },
    [permissionView]: {
        uuid: permissionView,
        accessLevel: AccessLevel.R,
        title: TITLE_NAMESPACE_PERMISSION_VIEW,
        // @ts-ignore
        chipColor: mainTheme.palette.primary[50],
        textColor: mainTheme.palette.primary.dark,
        tooltip: 'Только свои подразделения',
    },
    [permissionDenied]: {
        uuid: permissionDenied,
        accessLevel: AccessLevel.D,
        title: TITLE_NAMESPACE_PERMISSION_DENIED,
        chipColor: mainTheme.palette.background.default,
        textColor: mainTheme.palette.text.secondary,
    },
}

// Метод для получения уровня доступа по уиду
export const getPermission = (permissionUuid: string) => {
    return permissions[permissionUuid as PermissionKey] || null
}
