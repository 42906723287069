import React, { FC } from 'react'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { CPAAnalyticContentGraphic } from './components/CPAAnalyticContentGraphic/CPAAnalyticContentGraphic'
import {
    CPAAnalyticContentCompliance,
} from 'pages/cpa/CPAPage/components/CPAAnalytic/components/CPAAnalyticContent/components/CPAAnalyticContentAverage/components/CPAAnalyticContentCompliance/CPAAnalyticContentCompliance'

type CPAAnalyticContentAverageProps = {
    average: TAnalyticCpaStatisticSummary['average'];
    isAllGrades?: boolean;
}

export const CPAAnalyticContentAverage: FC<CPAAnalyticContentAverageProps> = ({ average, isAllGrades }) => {
    return (
        <Card>
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Stack spacing={1}>
                        <Typography variant="subtitle1">
                            Средний уровень соответствия
                        </Typography>
                        <Typography variant="body2">
                            по всем оцениваемым сотрудниками
                        </Typography>
                    </Stack>
                    {isAllGrades
                        ? <CPAAnalyticContentCompliance/>
                        : <CPAAnalyticContentGraphic total={average.total}/>}
                </Box>
            </CardContent>
        </Card>
    )
}
