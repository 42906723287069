import React, { FC } from 'react'
import { Chip } from '@mui/material'
import { getMoneyString } from 'helpers/getMoneyString'

type MoneyChipProps = {
    sum: number;
    hidePlus?: boolean
}

export const MoneyChip: FC<MoneyChipProps> = ({ sum, hidePlus }) => (
    <Chip
        label={`${hidePlus ? '' : '+'}${getMoneyString(sum)}`}
        size="small"
    />
)

