import {
    IEmployeeEditEducationCourseCreateOrUpdate,
    IEmployeeEditEducationEducationCreateOrUpdate,
    IEmployeePersonalDataUpdate,
    IEmployeeWorkExperiencePreviousJobCreateOrUpdate,
    IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate, IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate,
    IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate, IEmployeeWorkInCompanyWorkExperienceCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { TransferEmployee } from 'api/services/EmployeeService/transferEmployee'

export class EmployeeEditService extends TransferEmployee {
    public updateEmployeePersonalData(data: IEmployeePersonalDataUpdate) {
        const path = `/${data.uuid}`
        return this.request<IEmployeePersonalDataUpdate, { photo: string }>('PUT', path, { data })
    }

    //----------Work experience----------//

    public updateEmployeeWorkInCompanyWorkExperience(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyWorkExperienceCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/work-experience`
        return this.request<IEmployeeWorkInCompanyWorkExperienceCreateOrUpdate, null>('POST', path, { data })
    }

    //----------Work experience----------//

    //----------Current position----------//

    public updateEmployeeWorkInCompanyCurrentPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/current-position`
        return this.request<IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate, { currentUuid: string; previousUuid: string }>('POST', path, { data })
    }

    //----------Previous position----------//

    public createEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/previous-position/new`
        return this.request<IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public deleteEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        positionUuid: string,
    ) {
        const path = `${employeeUuid}/edit/company/previous-position/${positionUuid}`
        return this.request<{ uuid: string, employeeUuid: string; }, null>('DELETE', path, {
            data: {
                uuid: positionUuid,
                employeeUuid,
            },
        })
    }

    public updateEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/previous-position/${data.uuid}`
        return this.request<IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate, null>('PUT', path, { data })
    }

    //----------Previous position----------//

    //----------Projects and Roles----------//

    public createEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/projects-and-roles/new`
        return this.request<IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public deleteEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        projectsAndRolesUuid: string,
    ) {
        const path = `${employeeUuid}/edit/company/projects-and-roles/${projectsAndRolesUuid}`
        return this.request<{ uuid: string, employeeUuid: string }, null>('DELETE', path, {
            data: {
                uuid: projectsAndRolesUuid,
                employeeUuid,
            },
        })
    }

    public updateEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/company/projects-and-roles/${data.uuid}`
        return this.request<IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate, null>('PUT', path, { data })
    }

    //----------Projects and Roles----------//


    //----------Salary----------//
    public getEmployeeSalary(employeeUuid: string) {
        const path = `${employeeUuid}/edit/salary`
        return this.request('GET', path)
    }

    //----------Salary----------//

    //----------Work experience----------//
    //----------Work experience previous jobs----------//

    public createPreviousJobs(employeeUuid: string, data: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) {
        const path = `${employeeUuid}/edit/experience/job/create/one`
        return this.request<IEmployeeWorkExperiencePreviousJobCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public updatePreviousJobs(employeeUuid: string, data: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) {
        const path = `${employeeUuid}/edit/experience/job/${data.uuid}`
        return this.request<IEmployeeWorkExperiencePreviousJobCreateOrUpdate, null>('PUT', path, { data })
    }

    public deletePreviousJobs(employeeUuid: string, uuid: string) {
        const path = `${employeeUuid}/edit/experience/job/${uuid}`
        return this.request<{ uuid: string, employeeUuid: string }, null>('DELETE', path, {
            data: {
                uuid,
                employeeUuid,
            },
        })
    }

    //----------Work experience previous jobs----------//

    //----------Work experience previous projects and roles----------//

    public createPreviousProjectAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/experience/projects-and-roles/create/one`
        return this.request<IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public updatePreviousProjectAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/experience/projects-and-roles/${data.uuid}`
        return this.request<IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate, null>('PUT', path, { data })
    }

    public deletePreviousProjectAndRoles(employeeUuid: string, uuid: string) {
        const path = `${employeeUuid}/edit/experience/projects-and-roles/${uuid}`
        return this.request<{ uuid: string, employeeUuid: string }, null>('DELETE', path, {
            data: {
                uuid,
                employeeUuid,
            },
        })
    }

    //----------Work experience previous projects and roles----------//

    //----------Education----------//
    //----------Education-card----------//

    public createEducation(
        employeeUuid: string,
        data: IEmployeeEditEducationEducationCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/education/education/create/one`
        return this.request<IEmployeeEditEducationEducationCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public updateEducation(
        employeeUuid: string,
        data: IEmployeeEditEducationEducationCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/education/education/${data.uuid}`
        return this.request<IEmployeeEditEducationEducationCreateOrUpdate, null>('PUT', path, { data })
    }

    public deleteEducation(employeeUuid: string, uuid: string) {
        const path = `${employeeUuid}/edit/education/education/${uuid}`
        return this.request<{ uuid: string, employeeUuid: string }, null>('DELETE', path, {
            data: {
                uuid,
                employeeUuid,
            },
        })
    }

    //----------Education-card----------//

    //----------Course-card----------//

    public createCourse(
        employeeUuid: string,
        data: IEmployeeEditEducationCourseCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/education/course/create/one`
        return this.request<IEmployeeEditEducationCourseCreateOrUpdate, { uuid: string }>('POST', path, { data })
    }

    public updateCourse(
        employeeUuid: string,
        data: IEmployeeEditEducationCourseCreateOrUpdate,
    ) {
        const path = `${employeeUuid}/edit/education/course/${data.uuid}`
        return this.request<IEmployeeEditEducationCourseCreateOrUpdate, null>('PUT', path, { data })
    }

    public deleteCourse(employeeUuid: string, uuid: string) {
        const path = `${employeeUuid}/edit/education/course/${uuid}`
        return this.request<{ uuid: string, employeeUuid: string }, null>('DELETE', path, {
            data: {
                uuid,
                employeeUuid,
            },
        })
    }

    //----------Course-card----------//
    //----------Education----------//
}
