import React, { FC } from 'react'
import { MenuList } from '@mui/material'
import { IMenuListItem, MenuItemsCodesWithAlerts } from 'helpers/enums/menuItemsPosition'
import { AppMenuListItemLink } from 'components/AppMenu/components/AppMenuListItemLink/AppMenuListItemLink'
import { useAppSelector } from '../../../../../../store/hooks/redux'

type MenuListProps = {
    menuItems: IMenuListItem[];
}

export const AppMenuDesktopList: FC<MenuListProps> = ({ menuItems }) => {
    const alerts = useAppSelector(state => state.alertsReducer)

    return (
        <MenuList component="nav" disablePadding>
            {menuItems.map((item) => (
                <AppMenuListItemLink
                    key={item.code}
                    item={item}
                    alert={alerts[item.code as MenuItemsCodesWithAlerts]}
                />
            ))}
        </MenuList>
    )
}

