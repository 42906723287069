import React from 'react'
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { TITLE_NAMESPACE_WEAK_SIDES } from 'helpers/enums/titles'

export const AssessmentResultWeakSide = () => {
    const theme = useTheme()
    const sides = useAppSelector(state => state.assessmentResultReducer.data.sides)

    if (!sides || sides?.weak.length <= 0) return null

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h2">
                    {TITLE_NAMESPACE_WEAK_SIDES}
                </Typography>
            </Box>
            <Stack direction="row" flexWrap="wrap" gap={1}>
                {sides.weak.map(side =>
                    <Chip
                        key={`${side.title}-${side.score}`}
                        label={side.title}
                        sx={{
                            // @ts-ignore
                            backgroundColor: theme.palette.warning[50],
                            color: theme.palette.warning.dark,
                            height: 'auto',
                            padding: 0.5,
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            '& .MuiChip-label': {
                                whiteSpace: 'normal',
                                // whiteSpace: 'normal !important',
                            },
                        }}
                        size="small"
                    />)}
            </Stack>
        </Box>
    )
}
