interface ILogger {
    warn(...message: string[]): void

    debug(...message: string[]): void

    error(...message: string[]): void

    info(...message: string[]): void
}

class ProductionLogger implements ILogger {
    warn(...message: string[]) {
        console.warn(message.join(''))
    }

    error(...message: string[]) {
        console.error(message.join(''))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    info(...message: string[]): void {
        return void 0
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debug(...message: string[]) {
        return void 0
    }
}

class DevelopmentLogger implements ILogger {
    warn(...message: string[]) {
        console.warn(message.join(''))
    }

    error(...message: string[]) {
        console.error(message.join(''))
    }

    info(...message: string[]): void {
        console.info(message.join(''))
    }

    debug(...message: string[]) {
        console.debug(message.join(''))
    }
}

export class LoggerFactory {
    static createLogger(): ILogger {
        if (process.env.NODE_ENV === 'production')
            return new ProductionLogger()
        return new DevelopmentLogger()
    }
}
