import React, { useMemo } from 'react'
import { Alert, AlertColor, Box, Card, Stack, Typography, useTheme } from '@mui/material'
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { useAppSelector } from '../../../store/hooks/redux'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

export const ExamResultProgress = () => {
    const theme = useTheme()
    const examMeta = useAppSelector(state => state.examItemReducer.meta)
    const examResultProgress = useAppSelector(state => state.examItemReducer.result?.progress)
    const averageColor = useMemo(() => {
        switch (true) {
            case !examMeta.passMark:
                return theme.palette.primary.main
            case examResultProgress?.result && examResultProgress.result.isPassed:
                return theme.palette.success.main
            case examResultProgress?.result && !examResultProgress.result.isPassed:
                return theme.palette.error.main
            default:
                return theme.palette.primary.main
        }
    }, [examResultProgress])

    const data = [
        {
            id: 'average',
            data: [{
                x: '',
                y: examResultProgress?.result?.percent || 0,
                color: averageColor,
            }],
        },
    ]

    const alertData = useMemo(() => {
        switch (true) {
            case !examMeta.passMark:
                return null
            case examResultProgress?.result && examResultProgress.result.isPassed:
                return {
                    text: 'Тест успешно пройден, набрано достаточное количество баллов',
                    color: 'success' as AlertColor,
                    textColor: theme.palette.success.main,
                    icon: <CheckIcon color='success' />,
                }
            case examResultProgress?.result && !examResultProgress.result.isPassed:
                return {
                    text: ['Тест не пройден', 'К сожалению, результаты теста не достигли минимального требуемого уровня для его прохождения.'],
                    color: 'error' as AlertColor,
                    textColor: theme.palette.error.main,
                    icon: <CloseIcon color='error' />,
                }
            default:
                return null
        }
    }, [examResultProgress, examMeta])

    if (!examResultProgress) return null

    return (
        <Stack spacing={2}>
            <Typography variant='h2'>Результаты теста</Typography>
            <Stack direction='row' spacing={2}>
                <Card
                    sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant='body2'>Баллов набрано</Typography>
                    <Stack direction='row' spacing={1} alignItems='flex-end'>
                        <Typography variant='h1'>{examResultProgress.current}</Typography>
                        <Typography variant='h2'>/ {examResultProgress.max}</Typography>
                    </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                    <Typography variant='body2'>Итог</Typography>
                    <Stack direction='row' spacing={1} alignItems='flex-end'>
                        <Typography variant='h1'>{examResultProgress?.result?.percent}%</Typography>
                        <Box width={140} height={70}>
                            <ResponsiveRadialBar
                                data={data}
                                startAngle={-90}
                                endAngle={90}
                                padding={0.4}
                                maxValue={100}
                                cornerRadius={6}
                                isInteractive={false}
                                margin={{ top: -10, right: 0, bottom: -70, left: 0 }}
                                colors={{ datum: 'data.color' }}
                                enableRadialGrid={false}
                                enableCircularGrid={false}
                                radialAxisStart={null}
                                circularAxisOuter={null}
                            />
                        </Box>
                    </Stack>
                </Card>
            </Stack>
            {alertData &&
                <Alert
                    severity={alertData.color}
                    icon={alertData.icon}
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    {Array.isArray(alertData?.text) ?
                        alertData?.text.map(el => (
                            <Typography key={el} variant="body2" color={alertData.textColor}>
                                {el}
                            </Typography>
                        ))
                        :
                        <Typography variant="body2" color={alertData.textColor}>
                            {alertData?.text}
                        </Typography>
                    }
                </Alert>
            }
        </Stack>
    )
}
