// Файл где собраны текста подсказки (Задумка последующего обьединения с файлом titles.ts чтобы использовать через i18n)

export const HINT_ERROR_EMAIL = 'Введите адрес электронной почты'
export const HINT_ERROR_REQUIRED = 'Заполните поле'
export const HINT_CHOOSE = 'Выберите'
export const HINT_INPUT_TEXT = 'Введите'

export const HINT_ERROR_PASSWORD = 'Пароль не соответствует требованиям'
export const HINT_ERROR_PASSWORD_CONFIRM = 'Пароли не совпадают'

export const HINT_ERROR_INCORRECT_DATE = 'Неправильно введена дата'
export const HINT_ERROR_EXCESS_MAX_IMAGE_SIZE = 'Допустимый размер файла 2.5Мб'
export const HINT_ERROR_WORK_CURRENT_POSITION_EXPIRED = 'Дата работы в текущей позиции истекла'

export const HINT_ERROR_REQUIRED_SALARY = 'Укажите оклад'

export const HINT_WARNING_ADD_EMPLOYEE_DOESNT_HAVE_DIVISION = 'Для добавления сотрудника необходимо добавить подразделения в структуру компании'
export const HINT_WARNING_ADD_SPECIALIZATION_DOESNT_HAVE_DATA = 'Для добавления специализации необходимо добавить подразделения в структуру компании и заполнить справочник специализаций'
export const HINT_WARNING_ADD_GRADE_DOESNT_HAVE_DATA = 'Для добавления грейда необходимо заполнить справочник грейдов'

export const HINT_NO_DATA = 'Нет данных'
export const HINT_WITHOUT_CHANGES = 'Без изменений'

export const HINT_NEED_TO_FILL_EMPLOYEE_PROFILE_TO_INVITE = 'Перед приглашением сотрудника в систему необходимо внести его специализацию и грейд'
export const HINT_NEED_TO_FILL_EMPLOYEE_PROFILE = 'Необходимо внести специализацию и грейд сотрудника'
export const HINT_INVITE_EMPLOYEE = 'Пригласите сотрудника в систему для начала его работы'
export const HINT_INVITE_EMPLOYEE_SUCCESS_SENT = 'Приглашение отправлено успешно'
export const HINT_INVITE_EMPLOYEE_DESCRIPTION = 'Сотруднику будет отправлено письмо на электронную почту, которую вы указали в его профиле. В письме будут содержаться инструкции для первого входа в систему.'

export const HINT_RECORD_SUCCESS_CREATE = 'Запись успешно создана'
export const HINT_RECORD_SUCCESS_DELETE = 'Запись успешно удалена'
export const HINT_RECORD_SUCCESS_EDIT = 'Изменения успешно сохранены'


export const HINT_PASSWORD_RULE = 'Пароль должен быть длиной минимум восемь символов, включать большие и маленькие буквы, цифры и специальные символы'
export const HINT_EMAIL_RECOVER = 'Не удаётся войти в систему? Введите E-mail, с которым вы входите в систему, и мы пришлем ссылку для восстановления пароля'
export const HINT_EMAIL_RECOVER_WAS_SEND = 'Письмо с инструкцией по восстановлению пароля отправлено на e-mail.'

export const HINT_TOOLTIP_LOGOUT = 'Выйти из приложения'
export const HINT_TOOLTIP_SUPPORT = 'Поддержка'

export const HINT_ERROR_MAX_LENGTH_FIELD = 'Разрешенное количество символов поля'
export const HINT_ERROR_HAS_UNCOMPLETED_ELEMENTS = 'Пожалуйста, ответьте на все утверждения'
export const HINT_CHIP_DATA_SAVED_AUTOMATICALLY = 'Ответы и комментарии сохраняются автоматически'
export const HINT_CHANGED_DATA_SAVED_AUTOMATICALLY = 'Изменения сохраняются автоматически'
export const HINT_PDP_ALERT_EMPLOYEE_WAS_CREATED_FROM_SPECIALIZATION = 'План развития создан на основе профиля специализации. Обозначьте срок завершения плана развития и поставьте цель. Затем, когда содержимое плана будет готово, отправьте план развития сотруднику.'
export const HINT_PDP_ALERT_DEVELOPMENT_GO_REVIEW_WHEN_READY = 'Когда будете готовы, отправьте план развития на согласование руководителю'

export const HINT_PDP_ALERT_EMPLOYEE_WAS_SENT = 'План развития отправлен сотруднику. Вы можете редактировать план в любой момент времени, все изменения отобразятся у сотрудника.'
export const HINT_PDP_ALERT_DEVELOPMENT_READY_TO_STUDY = 'План развития готов к прохождению. Обратите внимание на цель и срок завершения, оцените свои возможности и рассчитайте нагрузку так, чтобы успеть отправить пройденный план до истечения срока.'
export const HINT_PDP_ALERT_DEVELOPMENT_ROLLBACK = 'План вернули на изучение, возможно нужно что-то подтянуть. Ознакомьтесь с изменениями или уточните у Проверяющего вне системы.'
export const HINT_PDP_ALERT_EMPLOYEE_ROLLBACK = 'В настоящий момент сотрудник изучает план развития. Вы можете отредактировать план в любой момент времени, все изменения отобразятся у сотрудника.'
export const HINT_PDP_ALERT_DEVELOPMENT_APPROVAL = 'Дождитесь, пока содержание плана согласуют.'
export const HINT_PDP_ALERT_EMPLOYEE_WAS_APPROVAL = 'План развития создан сотрудником. Проверьте содержание плана, при необходимости скорректируйте. Вы можете отредактировать план в любой момент времени, все изменения отобразятся у сотрудника.'

export const HINT_PDP_ALERT_EMPLOYEE_ON_STUDY = 'В настоящий момент сотрудник изучает план развития. Вы можете отредактировать план в любой момент времени, все изменения отобразятся у сотрудника.'
export const HINT_PDP_ALERT_DEVELOPMENT_ON_STUDY = 'После того, как все пункты плана будут пройдены, у вас появится возможность отправить план на проверку.'

export const HINT_PDP_INSTRUCTION_INTRO_EMPLOYEE = 'Данный план развития является вектором вашего роста, но не ограничивает его. Материалы плана позволят ускорить вход в освоение компетенции, но крайне приветствуется изучение дополнительных источников и применение новых знаний на практике. Ваш план развития состоит из пунктов - компетенций и уникальных пунктов вроде задач, они отмечены как “Пользовательская активность”. Все пункты упорядочены по приоритету, от наиболее к наименее важному.'
export const HINT_PDP_INSTRUCTION_CONCLUSION_MANAGER = 'После отправки плана сотруднику, можно редактировать его содержание, цель и срок завершения. Сотруднику будут видны все внесенные изменения.'
export const HINT_PDP_ALERT_PDP_CREATE_INSTRUCTION = 'Создайте индивидуальный план развития для сотрудника на целевую позицию'
export const HINT_PDP_ALERT_EMPLOYEE_EXPIRED = 'Сотрудник не закончил план развития к назначенному сроку. Измените срок завершения плана.'
export const HINT_PDP_ALERT_DEVELOPMENT_EXPIRED = 'Вы не успели завершить изучение и отправить план развития к назначенному сроку. Обратитесь к руководителю вне системы для изменения сроков или содержания плана.'

export const HINT_PDP_ALERT_DEVELOPMENT_REVIEW = 'План развития успешно отправлен и ожидает завершения проверки.'

export const HINT_PDP_ALERT_EMPLOYEE_COMPLETED = 'Сотрудник закончил изучение. Вы можете подтвердить результат прохождения или скорректировать план и вернуть его обратно сотруднику на изучение.'

export const HINT_CHIP_INCLUDED_LEVELS = 'Включает в себя также индикаторы следующих уровней -'
export const HINT_HAS_CHANGES_SURE_SAVE = 'Вы внесли изменения в набор компетенций. Вы подтверждаете их сохранение?'
export const HINT_CHANGES_WILL_EFFECT_AFTER_SAVING = 'Изменения вступят в силу сразу после сохранения.'
export const HINT_CHANGES_WILL_LOST = 'Все внесенные изменения будут утеряны.'
// PDP
export const HINT_CHANGE_SPECIALIZATION_OR_GRADE_WILL_LOST_PDP = 'Смена специализации или грейда приведет к полной замене созданного плана развития.'
export const HINT_SUCCESS_PDP_CHANGED = 'План развития успешно изменен'
export const HINT_SUCCESS_PDP_DELETED = 'План развития успешно удален'
export const HINT_SUCCESS_PDP_CREATED = 'План развития успешно создан'
export const HINT_SUCCESS_PDP_SENT_TO_APPROVAL = 'План развития успешно отправлен на проверку'

//PDP
// Analytic
export const HINT_SUCCESS_ASSESSMENT_CREATED = 'Оценка успешно создана'
export const HINT_SUCCESS_ASSESSMENT_UPDATED = 'Оценка успешно изменена'
export const HINT_SUCCESS_ASSESSMENT_DELETE = 'Оценка успешно удалена'
export const HINT_SUCCESS_ASSESSMENT_CANCEL = 'Оценка успешно отменена'

export const HINT_SURE_TO_DELETE = 'Вы уверены, что хотите удалить'
export const HINT_SURE_TO_DELETE_COMMENT = 'Вы уверены, что хотите удалить этот комментарий'

export const HINT_DELETE_SPECIALIZATION_FROM_DIVISIONS = 'из следующих подразделений? (необходимо выбрать)'
export const HINT_DELETE_SPECIALIZATION_FROM_DIVISION = 'из подразделения'
export const HINT_DELETE_MATERIAL_FROM_COMPETENCE = 'Материал будет удален только из компетенции'
export const HINT_DELETE_MATERIAL_UNDER_SPECIALIZATION = 'в рамках специализации'
export const HINT_DELETE_MATERIAL = 'Удалить материал'
export const HINT_DELETE_PDP_MATERIAL = 'Материал будет удален только из плана развития у этого сотрудника.'
export const HINT_DELETE_PDP_MATERIAL_LAST = 'Удаление последнего материала приведет и к удалению этого пункта из плана развития.'

export const HINT_SPECIALIZATION_WILL_SAVED_IN_OTHER_DIVISIONS = 'Специализация сохранится в других подразделениях, в которые она включена.'

export const HINT_IMPOSSIBLE_TO_CANCEL_ACTION = 'Отменить действие будет невозможно.'

export const HINT_EMPLOYEE_CREATE_PDP = 'Выберите текущую или целевую позицию и грейд для формирования плана развития.'

export const HINT_EMPLOYEE_CREATE_ASSESSMENT = 'Выберите текущую или целевую позицию и грейд на оценку'

export const HINT_LINK_WAS_COPIED = 'Ссылка скопирована'

export const HINT_SUCCESS_CREATE_SPECIALIZATION = 'Специализация успешно добавлена'
export const HINT_SUCCESS_CREATE_GRADE = 'Грейд успешно добавлен'

export const HINT_SUCCESS_CREATE_EMPLOYEE = 'Сотрудник и его учетная запись успешно созданы.'
export const HINT_CONTINUE_CREATE_EMPLOYEE = 'Сейчас система знает о сотруднике очень мало данных и не может полноценно с ним работать. Поэтому, пожалуйста, продолжите заполнение профиля сотрудника.'

export const HINT_SUCCESS_EDIT_SPECIALIZATION = 'Специализация успешно изменена'
export const HINT_SUCCESS_EDIT_GRADE = 'Грейд успешно изменен'
export const HINT_SUCCESS_DELETE_SPECIALIZATION_MULTIPLY = 'Специализация из выбранных подразделений успешно удалена'
export const HINT_SUCCESS_DELETE_SPECIALIZATION = 'Специализация успешно удалена'
export const HINT_SUCCESS_DELETE_GRADE = 'Грейд успешно удален'

export const HINT_SUCCESS_CHANGE_COMMENT = 'Комментарий успешно изменен'

export const HINT_SUCCESS_COMMENT_CREATE = 'Комментарий успешно сохранен'
export const HINT_SUCCESS_COMMENT_DELETE = 'Комментарий успешно удален'

export const HINT_SUCCESS_NOTIFY_SEND = 'Напоминание успешно отправлено'

export const HINT_SUCCESS_PDP_ITEM_DELETE = 'Пункт плана развития успешно удален'
export const HINT_SUCCESS_PDP_ITEM_CREATE = 'Пункт плана развития успешно создан'
export const HINT_SUCCESS_PDP_ITEM_EDIT = 'Пункт плана развития успешно изменен'
export const HINT_SUCCESS_PDP_MATERIAL_DELETE = 'Материал успешно удален'
export const HINT_SUCCESS_PDP_MATERIAL_CREATE = 'Материал успешно добавлен'
export const HINT_SUCCESS_PDP_MATERIAL_EDIT = 'Материал успешно изменен'
export const HINT_SUCCESS_PDP_MATERIAL_SETUP = 'Материалы из базы компетенций успешно добавлены'
export const HINT_SUCCESS_PDP_COMPETENCE_WAS_ADDED = 'Компетенции успешно добавлены в план развития'
export const HINT_SUCCESS_PDP_SEND_TO_EMPLOYEE = 'План развития успешно отправлен сотруднику на прохождение'
export const HINT_SUCCESS_PDP_CANCELED = 'План развития успешно отменен'
export const HINT_SUCCESS_PDP_CLOSED = 'План развития успешно завершен'
export const HINT_PDP_SEND_TO_EMPLOYEE_TEXT = 'Вы уверены, что хотите отправить сотруднику на прохождение план развития?'
export const HINT_PDP_COMPLETE = 'Вы уверены, что хотите утвердить прохождение плана развития сотрудником?'
export const HINT_PDP_SEND_TO_REVIEW = 'Вы уверены, что хотите отправить план развития руководителю на проверку?'
export const HINT_PDP_SEND_TO_APPROVAL = 'Вы уверены, что хотите отправить план развития руководителю для согласования?'
export const HINT_PDP_ITEM_DELETE = 'Пункт будет удален только из плана развития у этого сотрудника.'
export const HINT_PDP_ITEM_COMPETENCE_DELETE = 'Компетенция будет удалена только из плана развития у этого сотрудника.'
export const HINT_PDP_CANCEL = 'Вы уверены, что хотите отменить отправленный индивидуальный план развития?'
export const HINT_PDP_ROLLBACK = 'Вы уверены, что хотите вернуть план развития сотруднику на изучение?'
export const HINT_PDP_ROLLBACK_HELP_TEXT = 'Все изменения отобразятся у сотрудника после возврата.'
export const HINT_PDP_WILL_CLOSED = 'План развития будет закрыт.'
export const HINT_PDP_SEND_TO_REVIEW_HELPER_TEXT = 'Результат проверки будет отправлен вам на почту. План развития будет либо закрыт, либо возвращен на доработку.'
export const HINT_PDP_SEND_TO_EMPLOYEE_HELPER_TEXT = 'Возможность редактирования плана развития сохраняется в любой момент.'
export const HINT_PDP_CANCEL_HELPER_TEXT = 'Данный план развития будет перенесен в историю без возможности восстановления.'
export const HINT_ASSESSMENT_CANCEL = 'Вы уверены, что хотите отменить оценку, находящуюся в процессе?'
export const HINT_ASSESSMENT_CANCEL_HELPER_TEXT = 'Информация об оценке будет утеряна.'

export const HINT_EMPTY_PAGE_CURRENT_POSITION = 'Работа с разделом станет доступна после того как вам добавят текущую позицию.'
export const HINT_EMPTY_PAGE_PDP_CREATING = 'Сейчас для вас уже готовится план развития. Когда он будет готов, вы получите уведомление на e-mail.'
export const HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE = 'Запросите новый план развития у руководителя HR или создайте его сами. Наверняка есть навыки, которые вы давно хотели освоить.'
export const HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE_MOBILE = 'Запросите новый план развития у руководителя HR или создайте его сами в ПК версии Grade Factor. Наверняка есть навыки, которые вы давно хотели освоить.'

export const HINT_DEVELOPMENT_EMPTY_PDP_NOT_EXIST_OLD = 'Здесь будет отображаться ваш план развития. Обратитесь к руководителю или HR, чтобы они отправили его вам, или создайте план сами.'
export const HINT_DEVELOPMENT_EMPTY_PDP_EXIST_OLD = 'Запросите новый план развития у руководителя или HR. Пока вы можете посмотреть свой последний завершенный план развития.'
export const HINT_EMPTY_EMPLOYEE_ASSESSMENT = 'Сотруднику еще не были назначены оценки'

export const HINT_INDICATORS_AND_MATERIALS_SHOULD_BE_FOR_ALL_LEVELS = 'Индикаторы и материалы должны быть добавлены ко всем уровням владения компетенцией'
export const HINT_PDP_HAS_EMPTY_ITEM = 'В некоторых пунктах плана развития нет материалов. Добавьте их, чтобы отправить план развития.'
export const HINT_PDP_EMPTY_ITEM = 'В этом пункте плана развития нет материалов. Добавьте хотя бы один.'


export const HINT_NOTHING_WAS_FOUND = 'По вашему запросу ничего не найдено'
export const HINT_WHAT_IS_GF = 'Grade Factor – автоматизированная HR - платформа, которая поможет вам управлять развитием коллектива на пользу компании'

export const HINT_CAN_ADD_PARTICIPANTS = 'Можете добавить участников'

export const HINT_PDP_ESTIMATED_COMPLETION_DATE = 'Если отводить на изучение этого плана развития по 1 ч в день, то если начать завтра, ориентировочная дата завершения - '

export const HINT_ANALYTIC_ASSESSMENT = 'В расчете учитываются результаты двух последних оценок сотрудников на свои текущие позиции'

export const REPORT_EXPORT_FILE_AWAIT = 'Загрузка файла начнется через несколько секунд'

export const HINT_COMPLETION_DATE = 'Срок завершения'

export const HINT_SURE_SEND_REMINDER = 'Вы уверены, что хотите отправить напоминания?'
export const HINT_REMINDER_FOR_NOT_EXAMED_EMPLOYEES = 'Уведомления будут отправлены участникам, которые еще не прошли тестирование.'
export const HINT_ADD_MASS_EXAM_DESCRIPTION = 'Добавьте описание тестирования, которое увидит сотрудник перед началом прохождения.'
export const HINT_SUCCESS_DESCRIPTION_UPDATE = 'Описание успешно изменено'
export const HINT_UNSUCCESS_DESCRIPTION_UPDATE = 'Описание не было изменено из-за ошибки. Попробуйте еще раз.'
