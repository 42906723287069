import React, { useState } from 'react'
import { EditRounded } from '@mui/icons-material'
import { TITLE_BUTTON_CHANGE, TITLE_BUTTON_SELECT } from 'helpers/enums/titles'
import { Button } from '@mui/material'
import {
    CPACriteriaDrawer,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/CPACriteriaDrawer'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { cpaItemCriteriaActions } from 'store/actions'

export const CPACriteriaAction = () => {
    const dispatch = useAppDispatch()
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const [open, setOpen] = useState(false)

    const onOpenDrawer = () => {
        dispatch(cpaItemCriteriaActions.restoreInitialCriteriaCompetence())
        setOpen(true)
    }

    if (cpaStatusCode !== 'created' && cpaStatusCode !== 'ready') return null

    return (
        <>
            <CPACriteriaDrawer open={open} handleClose={() => setOpen(false)}/>
            <Button
                variant="outlined"
                startIcon={<EditRounded/>}
                onClick={onOpenDrawer}
            >
                {!isEmptyUuid(currentCriteriaUuid) && currentCriteriaUuid ? TITLE_BUTTON_CHANGE : TITLE_BUTTON_SELECT}
            </Button>
        </>
    )
}
