import React, { FC, useEffect, useState } from 'react'
import { useFormCpaTypes } from 'api/queries/form/formQueries'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { cpaItemActions } from 'store/actions'
import { format, parse } from 'date-fns'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_UPDATE_ASSESSMENT,
    TITLE_FIELD_ASSESSMENT_ENDED_AT,
    TITLE_FIELD_CPA_TITLE,
    TITLE_FIELD_CPA_TYPE,
} from 'helpers/enums/titles'
import Dialog from '@mui/material/Dialog'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import DialogContent from '@mui/material/DialogContent'
import { HINT_CHOOSE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { InputForm } from 'components/form/InputForm/InputForm'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { DatePicker } from '@mui/x-date-pickers'
import { DateRangeRounded } from '@mui/icons-material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { ICPAUpdateRequest } from 'api/typing/cpaTypes'
import { useCpaItemEmployees } from 'api/queries/cpa/cpaQueries'

type FormValues = Omit<ICPAUpdateRequest, 'endedAt'> & {
    endedAt: Date | null;
}

type DialogCpaEditProps = {
    open: boolean;
    handleClose: () => void
}

export const DialogCpaEdit: FC<DialogCpaEditProps> = ({ open, handleClose }) => {
    const { data: cpaTypes, error, isFetching } = useFormCpaTypes()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const [isCreating, setIsCreating] = useState(false)
    const cpaItem = useAppSelector(state => state.CPAItemReducer.meta)
    const { refetch: refetchCpaEmployees } = useCpaItemEmployees(cpaItem?.uuid || '', false)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)

    const { handleSubmit, control, reset, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            endedAt: parse(cpaItem?.endedAt, 'dd.MM.yyyy', new Date()),
            title: cpaItem?.title,
            typeUuid: cpaItem?.typeUuid,
            uuid: cpaItem?.uuid,
        },
    })

    useEffect(() => {
        const defaultValues = {
            endedAt: parse(cpaItem?.endedAt, 'dd.MM.yyyy', new Date()),
            title: cpaItem?.title,
            typeUuid: cpaItem?.typeUuid,
            uuid: cpaItem?.uuid,
        }
        reset(defaultValues)
        return () => reset(defaultValues)
    }, [cpaItem, cpaTypes])

    const onSubmit: SubmitHandler<FormValues> = async (payload) => {
        if (!payload.endedAt) return
        const preparedPayload = {
            uuid: payload.uuid,
            title: cpaStatusCode !== 'created' && cpaStatusCode !== 'ready' ? cpaItem.title : payload.title,
            typeUuid: cpaStatusCode !== 'created' && cpaStatusCode !== 'ready' ? cpaItem.typeUuid : payload.typeUuid,
            endedAt: format(payload.endedAt, 'dd.MM.yyyy'),
        }
        const isChangedType = cpaItem?.typeUuid && preparedPayload.typeUuid !== cpaItem.typeUuid
        try {
            setIsCreating(true)
            await dispatch(cpaItemActions.updateItem(preparedPayload))
            isChangedType && await refetchCpaEmployees()
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setIsCreating(false)
        }
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                component="form"
                flex={1}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <DialogTitle onClose={handleClose}>
                    {TITLE_DIALOG_UPDATE_ASSESSMENT}
                </DialogTitle>
                {isFetching && <LoadingComponent/>}
                {error && !isFetching ? <Box overflow="auto" py={2}>
                    <ErrorPage error={error}/>
                </Box> : null}
                {!error && !isFetching && <DialogContent>
                    <Stack spacing={2}>
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                ...fieldsRestriction.cpa.title,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="title"
                                    title={TITLE_FIELD_CPA_TITLE}
                                    required
                                    inputProps={{
                                        maxLength: fieldsRestriction.cpa.title.maxLength.value,
                                    }}
                                    disabled={cpaStatusCode !== 'created' && cpaStatusCode !== 'ready'}
                                    autoFocus
                                    error={!!errors.title}
                                    helperText={errors?.title ? errors.title.message : null}
                                />
                            )}
                        />
                        <Controller
                            name="typeUuid"
                            control={control}
                            rules={{
                                required: HINT_ERROR_REQUIRED,
                            }}
                            render={({ field }) => (
                                <SelectForm
                                    {...field}
                                    id="typeUuid"
                                    name="typeUuid"
                                    values={cpaTypes || []}
                                    required
                                    disabled={isFetching || !cpaTypes || cpaStatusCode !== 'created' && cpaStatusCode !== 'ready'}
                                    title={TITLE_FIELD_CPA_TYPE}
                                    placeholder={HINT_CHOOSE}
                                    error={!!errors.typeUuid}
                                    helperText={errors?.typeUuid ? errors.typeUuid.message : null}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="endedAt"
                            rules={{ ...fieldsRestriction.cpa.endedAt }}
                            render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                <DatePicker
                                    {...field}
                                    inputRef={ref}
                                    inputFormat="dd.MM.yyyy"
                                    minDate={new Date()}
                                    components={{ OpenPickerIcon: DateRangeRounded }}
                                    toolbarPlaceholder={HINT_CHOOSE}
                                    renderInput={(params) => (
                                        <InputForm
                                            {...params}
                                            id="endedAt"
                                            title={TITLE_FIELD_ASSESSMENT_ENDED_AT}
                                            onBlur={onBlur}
                                            required
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: HINT_CHOOSE,
                                            }}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>}
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button
                        variant="contained"
                        disabled={!!error || isCreating}
                        type="submit"
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
