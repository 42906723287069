import React, { useMemo } from 'react'
import { useGetAnalyticCpaLevelCompliance } from 'api/queries/analytics/analytics'
import { useAppSelector } from 'store/hooks/redux'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Chip, Skeleton, Stack, Typography } from '@mui/material'

export const CPAAnalyticContentCompliance = () => {
    const cpaMeta = useAppSelector(state => state.CPAItemReducer.meta)
    const { data, isLoading, error } = useGetAnalyticCpaLevelCompliance(cpaMeta.uuid)

    const maxValue = useMemo(() => data?.reduce((acc, el) => acc > el.score ? acc : el.score, 0), [data])

    if (isLoading) return <Skeleton variant="rounded" width={240}/>
    if (error) return <ErrorPage error={error}/>
    if (!data) return null

    return (
        <Stack gap={2} direction="row" flexWrap="wrap" justifyContent="flex-end">
            {data.map(el => <Stack key={el.grade.uuid} direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">
                    {el.grade.title}
                </Typography>
                <Chip
                    label={`${el.score}%`}
                    sx={{
                        // @ts-ignore
                        background: ({ palette }) => el.score === maxValue ? palette.primary.main : palette.primary[50],
                        color: ({ palette }) => el.score === maxValue ? palette.common.white : palette.primary.main,
                    }}
                />
            </Stack>)}
        </Stack>
    )
}
