import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_CPA_SCALE } from 'helpers/enums/titles'
import {
    CPAScaleAction,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/CPAScaleAction'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

type CPAScaleHeaderProps = {
    scaleUuid: string | undefined;
}

export const CPAScaleHeader: FC<CPAScaleHeaderProps> = ({ scaleUuid }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack flex={1} direction="row" alignItems="center" spacing={1}>
                <Typography variant="h3">
                    {TITLE_NAMESPACE_CPA_SCALE}
                </Typography>
                <Authenticated service="cpa" accessLevel="wx">
                    {(scaleUuid && isEmptyUuid(scaleUuid)) ? <ErrorOutlineRounded color="warning"/> : undefined}
                </Authenticated>
            </Stack>
            <Authenticated service="cpa" accessLevel="wx">
                <CPAScaleAction/>
            </Authenticated>
        </Box>
    )
}
