import { format, fromUnixTime, getTime, parse } from 'date-fns'

export const convertUtcStringToLocalString = (utcDateTimeString: string): string  => {
    const offset = new Date().getTimezoneOffset() * 60000

    const milliseconds = getTime(parse(utcDateTimeString, 'dd.MM.yyyy HH:mm', new Date()))
    const unix = fromUnixTime((milliseconds - offset) / 1000)

    return format(unix, 'dd.MM.yyyy HH:mm')
}
