import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IPdpCommentDeleteRequest,
    IPdpDeleteItemRequest,
    IPdpItemCommentCreateRequest,
    IPdpItemCommentDeleteRequest,
    IPdpItemCommentUpdateRequest,
    IPdpItemUpdateRequest,
    IPdpProgressRequest,
    IPdpSetupCompetenceItemsResponse,
    IPdpUniqueItemCreateRequest,
    IPdpUpdateRequest,
} from 'api/typing/employeeTypes'
import { omitObject } from 'helpers/omitObject'
import {
    changeActiveSpecialization,
    changeActiveSpecializationGroup,
    initSaveDataToSetup,
} from 'helpers/specializationCompetenceFunctions'
import { ChangeActiveSpecialization, ChangeActiveSpecializationGroup } from 'store/actions/specializationSetupAction'
import { format } from 'date-fns'

interface IPdpState {
    pdp: IPdp;
    // specializationList: IFormSpecialization[];
    // gradeList: IEntity[];
    competenciesFromBase: ISetupCompetenceGroup[];
    competenciesFromBaseCopy: ISetupCompetenceGroup[];
    competenceFromBaseSaved: ISpecializationSetupSaveData;
}

const initialState: IState<IPdpState> = {
    data: {} as IPdpState,
    isLoading: false,
    error: null,
}

export const pdpSlice = createSlice({
    name: 'pdp',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: (state) => {
            state.data.pdp = {} as IPdp
            state.isLoading = false
            state.error = null
        },

        setPdp: (state, action: PayloadAction<IPdp>) => {
            state.data.pdp = action.payload
        },

        changeStatusPdp: (state, action: PayloadAction<IEntity>) => {
            state.data.pdp.status = action.payload
            state.data.pdp.sentAt = format(new Date, 'dd.MM.yyyy')
        },

        changeOrderItems: (state, action: PayloadAction<IPdpPlanItem[]>) => {
            state.data.pdp.planItems = action.payload
        },

        updatePdp: (state, action: PayloadAction<IPdpUpdateRequest>) => {
            state.data.pdp = {
                ...state.data.pdp,
                ...action.payload,
            }
        },

        updatePdpTotalProgress: (state, action: PayloadAction<IPdpProgressRequest>) => {
            state.data.pdp.progress = action.payload.totalProgress
        },

        createItemPdp: (
            state,
            action: PayloadAction<IPdpUniqueItemCreateRequest & { uuid: string }>,
        ) => {
            state.data.pdp.changeLog = action.payload.changeLog
            state.data.pdp?.planItems?.push(omitObject(action.payload, ['pdpUuid', 'employeeUuid']))
            state.data.pdp.studyTime += action.payload.studyTime
        },

        updateItemPdp: (state, action: PayloadAction<IPdpItemUpdateRequest & IPdpProgressRequest>) => {
            state.data.pdp.changeLog = action.payload.changeLog
            state.data.pdp.planItems.some((el, i) => {
                if (el.uuid !== action.payload.uuid) return false
                state.data.pdp.planItems[i] = action.payload
                return true
            })
        },

        createPdpComment: (state, action: PayloadAction<IComment>) => {
            state.data.pdp.comments.push(action.payload)
        },

        updatePdpComment: (state, action: PayloadAction<IComment>) => {
            state.data.pdp.comments = state.data.pdp.comments.map(el => el.uuid === action.payload.uuid
                ? { ...el, ...action.payload }
                : el,
            )
        },

        deletePdpComment: (state, action: PayloadAction<IPdpCommentDeleteRequest>) => {
            state.data.pdp.comments = state.data.pdp.comments.filter(el => el.uuid !== action.payload.uuid)
        },

        createPdpItemComment: (state, action: PayloadAction<IComment & IPdpItemCommentCreateRequest>) => {
            const { itemUuid, ...comment } = action.payload
            state.data.pdp.planItems.some(el => {
                if (el.uuid !== itemUuid) return false
                el.comments.push(comment)
                return true
            })
        },

        updatePdpItemComment: (state, action: PayloadAction<IPdpItemCommentUpdateRequest>) => {
            const { itemUuid, ...comment } = action.payload
            state.data.pdp.planItems.some(el => {
                if (el.uuid !== itemUuid) return false
                // eslint-disable-next-line no-param-reassign
                el.comments = el.comments.map(commentItem => commentItem.uuid === comment.uuid
                    ? { ...commentItem, ...comment }
                    : commentItem,
                )
                return true
            })
        },

        deletePdpItemComment: (state, action: PayloadAction<IPdpItemCommentDeleteRequest>) => {
            const { itemUuid, ...comment } = action.payload
            state.data.pdp.planItems.some(el => {
                if (el.uuid !== itemUuid) return false
                // eslint-disable-next-line no-param-reassign
                el.comments = el.comments.filter(commentItem => commentItem.uuid !== comment.uuid)
                return true
            })
        },

        setupCompetenceItemsPdp: (state, action: PayloadAction<IPdpSetupCompetenceItemsResponse>) => {
            state.data.pdp.studyTime = action.payload.studyTime
            state.data.pdp.planItems = action.payload.planItems
            state.data.pdp.progress = action.payload.totalProgress
            state.data.pdp.changeLog = action.payload.changeLog
        },

        deleteItemPdp: (state, action: PayloadAction<IPdpDeleteItemRequest & IPdpProgressRequest>) => {
            const idx = state.data.pdp.planItems.findIndex(el => el.uuid === action.payload.itemUuid)
            state.data.pdp.studyTime -= state.data.pdp.planItems[idx].studyTime
            state.data.pdp.planItems.splice(idx, 1)
            state.data.pdp.progress = action.payload.totalProgress
            state.data.pdp.changeLog = action.payload.changeLog
        },

        getCompetenceForPdp: (state, action: PayloadAction<ISetupCompetenceGroup[]>) => {
            state.data.competenceFromBaseSaved = {} as ISpecializationSetupSaveData
            state.data.competenciesFromBase = action.payload
            initSaveDataToSetup(state.data.competenceFromBaseSaved, action.payload)
            state.data.competenciesFromBaseCopy = action.payload
        },

        changeActiveCompetenceGroup: (state, action: PayloadAction<ChangeActiveSpecializationGroup>) => {
            changeActiveSpecializationGroup(
                state.data.competenciesFromBase,
                state.data.competenceFromBaseSaved,
                action.payload.uuid,
                action.payload.value,
            )
        },

        changeActiveCompetence: (state, action: PayloadAction<ChangeActiveSpecialization>) => {
            changeActiveSpecialization(
                state.data.competenciesFromBase,
                state.data.competenceFromBaseSaved,
                action.payload.uuid,
                action.payload.groupUuid,
                action.payload.value,
            )
        },
    },
})

export const pdpReducer = pdpSlice.reducer
