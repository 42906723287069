import { TransferExam } from './transferExam'
import {
    IExamAnswerSaveReq,
    IExamCompleteReq,
    IExamMeta,
    IExamQuestion,
    IExamResultsResp, IExamStatusChangeReq,
} from '../../typing/exam'

export class ExamItemService extends TransferExam {
    public getItem(uuid: string) {
        const path = `/${uuid}`
        return this.request<null, IExamMeta>('GET', path)
    }

    public getQuestion(examUuid: string, questionUuid: string) {
        const path = `/${examUuid}/question/${questionUuid}`
        return this.request<null, IExamQuestion>('GET', path)
    }

    public saveAnswer(data: IExamAnswerSaveReq) {
        const path = `/${data.examUuid}/question/${data.questionUuid}/answer`
        return this.request<IExamAnswerSaveReq, null>('POST', path, { data })
    }

    public completeExam(data: IExamCompleteReq) {
        const path = `/${data.examUuid}/status`
        return this.request<IExamCompleteReq, null>('POST', path, { data })
    }

    public changeExamStatus(data: IExamStatusChangeReq) {
        const path = `/${data.examUuid}/status`
        return this.request<IExamStatusChangeReq, null>('POST', path, { data })
    }

    public getResult(examUuid: string) {
        const path = `/${examUuid}/result`
        return this.request<null, IExamResultsResp>('GET', path)
    }
}
