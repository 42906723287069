import { AxiosStatic } from 'axios'
import { TransferSpecialization } from 'api/services/SpecializationService/transferSpecialization'
import {
    ISpecializationCompetenceChangeOrderMaterialRequest, ISpecializationCompetenceCreateEntity,
    SpecializationCompetenceIncludedUuids,
    SpecializationCompetenceMaterialCreateRequest,
    SpecializationCompetenceMaterialUpdateRequest,
} from 'api/typing/specializationTypes'

export class SpecializationCompetenceService extends TransferSpecialization {
    private static instance: SpecializationCompetenceService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!SpecializationCompetenceService.instance)
            SpecializationCompetenceService.instance = new SpecializationCompetenceService(axiosInstance)
        return SpecializationCompetenceService.instance
    }

    public getSpecializationCompetence(specializationUuid: string, competenceUuid: string, entityType: string) {
        const path = `/${specializationUuid}/competence/${competenceUuid}/${entityType}`
        return this.request<null, ICompetence>('GET', path)
    }

    public specializationCompetenceMaterialCreate(data: SpecializationCompetenceMaterialCreateRequest) {
        const { specializationUuid, competenceUuid } = data
        const path = `/${specializationUuid}/competence/${competenceUuid}/material/new`
        return this.request<SpecializationCompetenceMaterialCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public specializationCompetenceMaterialUpdate(data: SpecializationCompetenceMaterialUpdateRequest) {
        const { specializationUuid, competenceUuid, uuid } = data
        const path = `/${specializationUuid}/competence/${competenceUuid}/material/${uuid}`
        return this.request<SpecializationCompetenceMaterialUpdateRequest, null>('PUT', path, { data })
    }

    public specializationCompetenceMaterialDelete(
        data: SpecializationCompetenceIncludedUuids & { uuid: string, entityType: 'custom' | 'origin' },
    ) {
        const { specializationUuid, competenceUuid, uuid } = data
        const path = `/${specializationUuid}/competence/${competenceUuid}/material/${uuid}`
        return this.request<SpecializationCompetenceIncludedUuids & { uuid: string, entityType: 'custom' | 'origin' }, null>('DELETE', path, { data })
    }

    public specializationCompetenceMaterialChangeOrder(data: ISpecializationCompetenceChangeOrderMaterialRequest) {
        const { specializationUuid, competenceUuid } = data
        const path = `/${specializationUuid}/competence/${competenceUuid}/material/order`
        return this.request<ISpecializationCompetenceChangeOrderMaterialRequest, null>('PUT', path, { data })
    }

    public specializationCompetenceCreateChain(data: ISpecializationCompetenceCreateEntity) {
        const { specializationUuid, competenceUuid } = data
        const path = `/${specializationUuid}/competence/${competenceUuid}/chain`
        return this.request<ISpecializationCompetenceCreateEntity, null>('POST', path, { data })
    }
}
