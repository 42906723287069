import React, { FC, useMemo } from 'react'
import { Step, Stepper, useTheme } from '@mui/material'
import { sortArrayFromSortIndex } from 'helpers/sortArray'
import {
    HistoryPdpItem,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/HistoryPdpContent/components/HistoryPdpItems/components/HistoryPdpItem/HistoryPdpItem'

function getItemStatus(entityType: EntityType, progress: number, isPassed: boolean) {
    return {
        active: entityType === 'unique' ? isPassed : progress === 100,
        disable: entityType === 'unique' ? !isPassed : progress === 0,
    }
}

type HistoryPdpItemsProps = {
    items: IPdpPlanItem[];
    parentPath: string;
}

export const HistoryPdpItems: FC<HistoryPdpItemsProps> = ({ items, parentPath }) => {
    const theme = useTheme()

    const sortedItems = useMemo(
        () => items
            ? sortArrayFromSortIndex(items)
            : [] as IPdpPlanItem[],
        [items?.length],
    )

    return (
        <Stepper
            orientation="vertical"
            nonLinear
            sx={{
                ' .MuiStepConnector-root': {
                    marginLeft: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        width: '100%',
                        height: '1px',
                        position: 'absolute',
                        top: '50%',
                        left: '26px',
                        backgroundColor: '#E2E7E9',
                    },
                },
                '.MuiStepConnector-root .MuiStepConnector-line': {
                    borderColor: theme.palette.primary.main,
                    borderLeftWidth: 2,
                    opacity: 0.2,
                },
                '.MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
                    borderColor: theme.palette.background.default,
                    borderLeftWidth: 2,
                    opacity: 1,
                },
            }}
        >
            {sortedItems.map(step => (<Step
                disabled={getItemStatus(step.entityType, step.progress, step.isPassed).disable}
                active={getItemStatus(step.entityType, step.progress, step.isPassed).active}
                sx={{
                    '.MuiStepLabel-root': {
                        padding: 0,
                    },
                    '& .MuiStepIcon-root': {
                        width: 32,
                        height: 32,
                        color: theme.palette.primary.main,
                    },
                    '& .MuiStepIcon-root.Mui-active': {
                        color: theme.palette.success.main,
                    },
                    '.Mui-disabled .MuiStepIcon-root': {
                        color: theme.palette.background.default,
                    },
                    '.Mui-disabled .MuiStepIcon-text': {
                        fill: theme.palette.text.disabled,
                    },
                }}
                key={step.uuid}
            >
                <HistoryPdpItem item={step} parentPath={parentPath} />
            </Step>))}

        </Stepper>
    )
}
