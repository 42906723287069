import React, { FC, useMemo } from 'react'
import { Checkbox } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { cpaItemEmployeesActions } from 'store/actions'

type CPAEmployeeDrawerEmployeeCheckboxProps = {
    employeeUuid: string;
    hasAssessment: boolean;
}

export const CPAEmployeeDrawerEmployeeCheckbox: FC<CPAEmployeeDrawerEmployeeCheckboxProps> = ({
    hasAssessment,
    employeeUuid,
}) => {
    const dispatch = useAppDispatch()
    const employeeListSaved = useAppSelector(state => state.CPAItemEmployeesReducer.employeeListSaved)
    const currentEmployeeListSaved = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployeeListSaved)

    const employeeListSavedSet = useMemo(() => new Set(employeeListSaved), [employeeListSaved.length])

    const currentEmployeeListSavedSet = useMemo(
        () => new Set(currentEmployeeListSaved),
        [currentEmployeeListSaved.length],
    )

    const wasChanged = useMemo(
        () => employeeListSavedSet.has(employeeUuid) && !currentEmployeeListSavedSet.has(employeeUuid)
            || !employeeListSavedSet.has(employeeUuid) && currentEmployeeListSavedSet.has(employeeUuid),
        [employeeListSavedSet, currentEmployeeListSavedSet],
    )

    const changeStateEmployeeSelect = () => {
        if (hasAssessment) return
        dispatch(cpaItemEmployeesActions.setSelectStateEmployee({
            employeeUuid: employeeUuid,
            isSelected: !employeeListSavedSet.has(employeeUuid),
        }))
    }

    return (
        <Checkbox
            checked={hasAssessment ? undefined : employeeListSavedSet.has(employeeUuid)}
            onChange={changeStateEmployeeSelect}
            color={wasChanged ? 'warning' : 'primary'}
            disabled={hasAssessment}
            sx={{
                color: (theme) => wasChanged ? theme.palette.warning.main : theme.palette.primary.main,
            }}
        />
    )
}
