import React, { FC, useState } from 'react'
import { Button } from '@mui/material'
import { TITLE_BUTTON_SELECT } from 'helpers/enums/titles'
import { EditRounded } from '@mui/icons-material'
import {
    CPACriteriaDrawerItemCompetenceDrawer,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/components/CPACriteriaDrawerItemCompetenceDrawer/CPACriteriaDrawerItemCompetenceDrawer'

type CPACriteriaDrawerItemCompetenceActionDrawerProps = {
    buttonTitle?: string;
    buttonVariant?: 'text' | 'outlined' | 'contained';
}

export const CPACriteriaDrawerItemCompetenceActionDrawer: FC<CPACriteriaDrawerItemCompetenceActionDrawerProps> = ({
    buttonTitle = TITLE_BUTTON_SELECT,
    buttonVariant = 'contained',
}) => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <Button
                variant={buttonVariant}
                startIcon={<EditRounded/>}
                onClick={() => setOpen(true)}
            >
                {buttonTitle}
            </Button>
            {open && <CPACriteriaDrawerItemCompetenceDrawer open={open} handleClose={() => setOpen(false)}/>}
        </div>
    )
}
