import React, { FC } from 'react'
import { Card, CardContent, Chip, Stack, Typography, useTheme } from '@mui/material'

type CPAAnalyticContentSidesProps = {
    competencies: TAnalyticCpaStatisticSummary['competencies'];
}

export const CPAAnalyticContentSides: FC<CPAAnalyticContentSidesProps> = ({ competencies }) => {
    const theme = useTheme()

    if ((!competencies?.lowest || competencies.lowest.length <= 0)
        && (!competencies?.highest || competencies.highest.length <= 0)) return null

    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    {competencies?.lowest && competencies.lowest.length > 0 && <Stack spacing={2}>
                        <Typography variant="h3">
                            Зоны роста
                        </Typography>
                        <Stack direction="row" flexWrap="wrap" gap={2}>
                            {competencies?.lowest.map(el => <Chip
                                key={el.uuid}
                                label={el.title}
                                size="small"
                                sx={{
                                    // @ts-ignore
                                    backgroundColor: theme.palette.warning[50],
                                    color: theme.palette.warning.dark,
                                }}
                            />)}
                        </Stack>
                    </Stack>}
                    {competencies?.highest && competencies.highest.length > 0 && <Stack spacing={2}>
                        <Typography variant="h3">
                            Топ компетенций
                        </Typography>
                        <Stack direction="row" flexWrap="wrap" gap={2}>
                            {competencies?.highest.map(el => <Chip
                                key={el.uuid}
                                label={el.title}
                                size="small"
                                sx={{
                                    // @ts-ignore
                                    backgroundColor: theme.palette.success[50],
                                    color: theme.palette.success.dark,
                                }}
                            />)}
                        </Stack>
                    </Stack>}
                </Stack>
            </CardContent>
        </Card>
    )
}
