import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    changeActiveSpecialization,
    changeActiveSpecializationGroup,
    initSaveDataToSetup, saveChangesSpecializationSetup,
} from 'helpers/specializationCompetenceFunctions'
import { ChangeActiveSpecialization, ChangeActiveSpecializationGroup } from 'store/actions/specializationSetupAction'

const initialState: IState<ISpecializationSetupState> = {
    data: {} as ISpecializationSetupState,
    isLoading: false,
    error: null,
}

export const specializationSetupSlice = createSlice({
    name: 'specializationSetup',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getSetupSpecializationCompetence: (state, action: PayloadAction<ISetupCompetenceGroup[]>) => {
            state.isLoading = false
            state.error = null
            state.data.saveData = {} as ISpecializationSetupSaveData
            state.data.groups = action.payload
            initSaveDataToSetup(state.data.saveData, action.payload)
            state.data.copyGroups = action.payload
        },

        changeActiveSpecialization: (state, action: PayloadAction<ChangeActiveSpecialization>) => {
            state.isLoading = false
            state.error = null
            changeActiveSpecialization(
                state.data.groups,
                state.data.saveData,
                action.payload.uuid,
                action.payload.groupUuid,
                action.payload.value,
            )
        },

        changeActiveSpecializationGroup: (state, action: PayloadAction<ChangeActiveSpecializationGroup>) => {
            state.isLoading = false
            state.error = null
            changeActiveSpecializationGroup(
                state.data.groups,
                state.data.saveData,
                action.payload.uuid,
                action.payload.value,
            )
        },
        restoreSetupState: (state) => {
            state.isLoading = false
            state.error = null
            state.data.groups = [...state.data.copyGroups]
            initSaveDataToSetup(state.data.saveData, state.data.groups)
        },

        saveChanges: (state) => {
            state.isLoading = false
            state.error = null
            saveChangesSpecializationSetup(state.data.groups)
            state.data.copyGroups = [...state.data.groups]
        },
    },
})

export const specializationSetupReducer = specializationSetupSlice.reducer
