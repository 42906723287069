import React, { FC, useState } from 'react'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE, TITLE_DIALOG_PDP_ESTIMATED_COMPLETION_DATE,
    TITLE_DIALOG_PDP_POINT,
    TITLE_FIELD_PDP_DATE_ENDED_AT,
    TITLE_FIELD_PLACEHOLDER_ADD_POINT,
    TITLE_FIELD_POINT,
} from 'helpers/enums/titles'
import { Box, SwipeableDrawer, IconButton, Popover, Stack, Typography, useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import {
    HINT_CHOOSE,
    HINT_EMPLOYEE_CREATE_PDP,
    HINT_PDP_ESTIMATED_COMPLETION_DATE,
    HINT_SUCCESS_PDP_CHANGED,
} from 'helpers/enums/hints'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { developmentPdpActions, pdpActions } from 'store/actions'
import { IPdpUpdateRequest } from 'api/typing/employeeTypes'
import { DateRangeRounded } from '@mui/icons-material'
import { addDays, addYears, format, parse } from 'date-fns'
import { InputForm } from 'components/form/InputForm/InputForm'
import { DatePicker } from '@mui/x-date-pickers'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { useMobile } from 'helpers/hooks/useMobile'
import { Puller } from 'components/common/Puller/Puller'
import { biggerBtnForMobile } from 'styles/styles'

type FormValues = Omit<IPdpUpdateRequest, 'endedAt'> & { endedAt: Date | null }

type DialogPdpPointAndEndedAtProps = {
    point: string;
    endedAt: string;
    studyTime: number;
    pdpUuid: string;
    handleClose: () => void;
    open: boolean;
    employee: IParticipantEmployee;
    idDevelopment?: boolean;
}

export const DialogPdpPointAndEndedAt: FC<DialogPdpPointAndEndedAtProps> = ({
    open,
    handleClose,
    studyTime,
    pdpUuid,
    endedAt,
    point,
    employee,
    idDevelopment,
}) => {
    const theme = useTheme()
    const mobile =  useMobile()
    const notify = useNotify()

    const [swipeableDrawerOpen, setSwipeableDrawerOpen] = useState(false)
    const handleOpenSwipeableDrawer = () =>  setSwipeableDrawerOpen(true)
    const handleCloseSwipeableDrawer = () => setSwipeableDrawerOpen(false)

    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const openPopover = Boolean(anchorEl)

    const { handleSubmit, reset, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            pdpUuid,
            point,
            employeeUuid: employee.uuid,
            endedAt: endedAt && isValidDateFromApi(endedAt) ? parse(endedAt, 'dd.MM.yyyy', new Date()) : null,
        },
    })

    const estimationDateEnd = `${HINT_PDP_ESTIMATED_COMPLETION_DATE}${format(addDays(new Date(), studyTime || 1), 'dd.MM.yyyy')}`

    const cancel = () => {
        reset({
            pdpUuid,
            point,
            employeeUuid: employee.uuid,
            endedAt: endedAt ? parse(endedAt, 'dd.MM.yyyy', new Date()) : null,
        })
        handleClose()
    }

    const onSubmit = async (data: FormValues) => {
        const payload = {
            ...removeWhiteSpaceFromObjectElements(data),
            endedAt: data?.endedAt ? format(data.endedAt, 'dd.MM.yyyy') : '',
        }
        try {
            if (idDevelopment)
                await dispatch(developmentPdpActions.changePdpPointOrDate(payload))
            else
                await dispatch(pdpActions.updatePdp(payload))
            notify(HINT_SUCCESS_PDP_CHANGED, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth
            sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_PDP_POINT}
            </DialogTitle>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={2.5}>
                        <Box>
                            <Typography variant="body2">
                                {HINT_EMPLOYEE_CREATE_PDP}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Box flex={1}>
                                <Controller
                                    control={control}
                                    name="endedAt"
                                    rules={{ ...fieldsRestriction.employees.employee.pdp.endedAt }}
                                    render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                        <DatePicker
                                            {...field}
                                            inputRef={ref}
                                            inputFormat="dd.MM.yyyy"
                                            minDate={new Date()}
                                            maxDate={addYears(new Date(), 2)}
                                            components={{ OpenPickerIcon: DateRangeRounded }}
                                            toolbarPlaceholder={HINT_CHOOSE}
                                            renderInput={(params) => (
                                                <InputForm
                                                    {...params}
                                                    id="endedAt"
                                                    title={TITLE_FIELD_PDP_DATE_ENDED_AT}
                                                    onBlur={onBlur}
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: HINT_CHOOSE,
                                                    }}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>
                            <IconButton
                                color="primary"
                                sx={{
                                    alignSelf: 'flex-start',
                                    mt: 3,
                                }}
                                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                onClick={handleOpenSwipeableDrawer}
                            >
                                <InfoOutlinedIcon />
                            </IconButton>
                            {mobile ?
                                <SwipeableDrawer
                                    anchor="bottom"
                                    open={swipeableDrawerOpen}
                                    onOpen={handleOpenSwipeableDrawer}
                                    onClose={handleCloseSwipeableDrawer}
                                    ModalProps={{
                                        keepMounted: true,
                                        style: {zIndex: theme.zIndex.modal},
                                    }}
                                    PaperProps={{
                                        sx: {
                                            height: 'auto',
                                            borderRadius: '16px 16px 0 0',
                                        },
                                    }}
                                >
                                    <Box
                                        role="presentation"
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            px: 3,
                                            pb: 3,
                                            pt: 2,
                                        }}
                                    >
                                        <Puller mb={3} />
                                        <Typography variant="h3" mb={3}>{TITLE_DIALOG_PDP_ESTIMATED_COMPLETION_DATE}</Typography>
                                        <Typography variant="body2">{estimationDateEnd}</Typography>
                                    </Box>
                                </SwipeableDrawer>
                                :
                                <Popover
                                    id="mouse-over-popover"
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{
                                        pointerEvents: 'none',
                                        '& .MuiPaper-root': {
                                            maxWidth: '400px',
                                            borderRadius: '16px',
                                            p: 2.5,
                                        },
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography variant="caption">{estimationDateEnd}</Typography>
                                </Popover>
                            }
                        </Box>
                        <Box>
                            <Controller
                                name="point"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.pdp.point,
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="point"
                                        name="point"
                                        multiline
                                        rows={mobile ? 9 : 3}
                                        required
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .pdp
                                                .point
                                                .maxLength
                                                .value,
                                        }}
                                        title={TITLE_FIELD_POINT}
                                        placeholder={TITLE_FIELD_PLACEHOLDER_ADD_POINT}
                                        error={!!errors.point}
                                        helperText={errors?.point ? errors.point.message : null}
                                    />
                                )}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={cancel}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
