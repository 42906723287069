import React, { useMemo } from 'react'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_RESULT } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'
import {
    AssessmentResultStrongSide,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultSides/components/AssessmentResultStrongSide/AssessmentResultStrongSide'
import {
    AssessmentResultWeakSide,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultSides/components/AssessmentResultWeakSide/AssessmentResultWeakSide'

type HighAccordanceMaximumCalculate = {
    value: number;
    elements: string[];
}

export const AssessmentResultSides = () => {
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const accordance = useAppSelector(state => state.assessmentResultReducer.data.accordance)
    const highAccordance = useMemo(
        () => {
            if (!accordance?.length) return null
            const res: HighAccordanceMaximumCalculate = {} as HighAccordanceMaximumCalculate

            for (const accordanceVal of accordance) {
                switch (true) {
                    case !res.value || accordanceVal.score > res.value: {
                        res.value = accordanceVal.score
                        res.elements = [`${accordanceVal.grade.title} - ${accordanceVal.score}%`]
                        break
                    }
                    case accordanceVal.score === res.value: {
                        res.value = accordanceVal.score
                        res.elements.push(`${accordanceVal.grade.title} - ${accordanceVal.score}%`)
                        break
                    }
                    default:
                        break
                }
            }
            return res?.elements?.join(', ')
        },
        [accordance],
    )

    return (
        <Stack spacing={4}>
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Box>
                            <Typography variant="h2">
                                {TITLE_NAMESPACE_RESULT}: {highAccordance ?? `${result?.score}%`}
                            </Typography>
                        </Box>
                        <Divider />
                        <AssessmentResultStrongSide />
                        <AssessmentResultWeakSide />
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}
