import React from 'react'
import { Box, Stack } from '@mui/material'
import { FilterListRounded } from '@mui/icons-material'
import { TitleFilter } from '../../../filters/TitleFilter/TitleFilter'
import { TITLE_FIELD_EXAM_TITLE } from '../../../../helpers/enums/titles'
import { StatusFilter } from '../../../filters/StatusFilter/StatusFilter'
import { useAppDispatch } from '../../../../store/hooks/redux'
import { examListAction } from '../../../../store/actions'
import { examStatusesEntity } from '../../../../helpers/exam/examStatuses'

export const ExamListPageFilters = () => {
    const dispatch = useAppDispatch()
    const filterByTitle = (search: string) => dispatch(examListAction.setTitleFilter(search))
    const filterByStatus = (values: IEntity[]) => dispatch(examListAction.setStatusFilter(values))

    return (
        <Stack direction='row' spacing={2} alignItems='center' mb={3}>
            <FilterListRounded color="primary"/>
            <TitleFilter filterByTitle={filterByTitle} filterPlaceholder={TITLE_FIELD_EXAM_TITLE} />
            <Box minWidth={'224px'}>
                <StatusFilter
                    options={examStatusesEntity}
                    filterByStatus={filterByStatus}
                />
            </Box>
        </Stack>
    )
}
