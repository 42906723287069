import React, { FC, useRef, useState } from 'react'
import {
    IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Stack } from '@mui/material'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { HINT_CHOOSE } from 'helpers/enums/hints'
import {
    TITLE_BUTTON_CANCEL, TITLE_BUTTON_SAVE,
    TITLE_FIELD_PROJECT, TITLE_FIELD_ROLE,
} from 'helpers/enums/titles'
import { RangeBetweenTwoDates } from 'components/RangeBetweenTwoDates/RangeBetweenTwoDates'
import { InputForm } from 'components/form/InputForm/InputForm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { format, parse } from 'date-fns'

type FormValues = Omit<IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate, 'endDate' | 'startDate' | 'uuid'>

type PreviousJobsItemFormProps = {
    itemData?: IEmployeeWorkExperienceProjectAndRoleItem;
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate) => void;
}

const isEqualObjectsPreviousProjectsAndRoles = (
    item: IEmployeeWorkExperienceProjectAndRoleItem,
    formData: FormValues & { dateStart: DateState, dateEnd: DateState },
) => {
    const endDateFormatted = formData.dateEnd?.value ? format(formData.dateEnd.value, 'dd.MM.yyyy') : ''
    const startDateFormatted = formData.dateStart?.value ? format(formData.dateStart.value, 'dd.MM.yyyy') : ''

    const equalProject = item?.projectTitle === formData.projectTitle
    const equalRole = item?.roleTitle === formData.roleTitle

    const equalStartDate = item.startDate === startDateFormatted
    const equalEndDate = item.endDate === endDateFormatted

    return equalProject && equalRole && equalStartDate && equalEndDate
}

export const PreviousProjectsAndRolesItemForm: FC<PreviousJobsItemFormProps> = ({
    itemData,
    onSubmitForm,
    onCancelForm,
}) => {
    const datesFields = useRef<any>(null)

    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            projectTitle: itemData?.projectTitle ?? '',
            roleTitle: itemData?.roleTitle ?? '',
        },
    })

    const [dateStart, setDateStart] = useState<DateState>({
        value: itemData?.startDate?.length
            ? parse(itemData.startDate, 'dd.MM.yyyy', new Date())
            : null,
        error: null,
    })

    const [dateEnd, setDateEnd] = useState<DateState>({
        value: itemData?.endDate?.length
            ? parse(itemData.endDate, 'dd.MM.yyyy', new Date())
            : null,
        error: null,
    })

    const onSubmit = (data: FormValues) => {
        if (dateEnd.error
            || dateStart.error
            || !dateStart.value
            || !dateEnd.value
            || itemData && isEqualObjectsPreviousProjectsAndRoles(itemData, { ...data, dateStart, dateEnd })
        ) return
        const payload: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
            startDate: format(dateStart.value, 'dd.MM.yyyy'),
            endDate: format(dateEnd.value, 'dd.MM.yyyy'),
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="projectTitle"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.workExperience.previousProject,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="projectTitle"
                            name="projectTitle"
                            required
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .workExperience
                                    .previousProject
                                    .maxLength
                                    .value,
                            }}
                            title={TITLE_FIELD_PROJECT}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.projectTitle}
                            helperText={errors?.projectTitle ? errors.projectTitle.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="roleTitle"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.workExperience.previousRole,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="roleTitle"
                            name="roleTitle"
                            required
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .workExperience
                                    .previousRole
                                    .maxLength
                                    .value,
                            }}
                            title={TITLE_FIELD_ROLE}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.roleTitle}
                            helperText={errors?.roleTitle ? errors.roleTitle.message : null}
                        />
                    )}
                />
            </Box>
            <RangeBetweenTwoDates
                ref={datesFields}
                requiredDateEnd
                requiredDateStart
                endDate={dateEnd}
                startDate={dateStart}
                maxDateStart={new Date()}
                setEndDate={setDateEnd}
                setStartDate={setDateStart}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    onClick={() => onCancelForm()}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    type="submit"
                    onClick={() => datesFields?.current?.checkDates()}
                    variant="contained"
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}

