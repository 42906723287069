import React, { FC } from 'react'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_ERROR } from 'helpers/enums/titles'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { GetErrorResponse } from 'helpers/getErrorResponse'
import { useLocation } from 'react-router-dom'

type ErrorPageProps = {
    error?: IErrorResponse | unknown;
    errorCode?: number;
}

const getError = (error?: IErrorResponse | unknown, errorCode?: number, state?: any) => {
    switch (true) {
        case !!error: {
            return error
        }
        case !!errorCode: {
            return new GetErrorResponse(errorCode).getError()
        }
        case !!state: {
            return typeof state === 'number' ? new GetErrorResponse(state).getError() : state
        }
        default: {
            return new GetErrorResponse(404).getError()
        }
    }
}

export const ErrorPage: FC<ErrorPageProps> = ({ error, errorCode }) => {
    const location = useLocation()
    const currentError = getError(error, errorCode, location?.state?.error)

    if (!currentError) return null

    return (
        <Box minHeight="100%" display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ border: (theme) => `1px solid ${theme.palette.warning.light}` }}>
                <CardContent>
                    <Stack spacing={3} justifyContent="center" alignItems="center">
                        <ErrorOutlineRounded color="warning" sx={{ width: 100, height: 100 }}/>
                        <Box>
                            <Typography align="center" variant="subtitle2" mb={0.5}>
                                {TITLE_NAMESPACE_ERROR} {currentError.status}
                            </Typography>
                            <Typography align="center" variant="h1">
                                {currentError.message}
                            </Typography>
                        </Box>
                        <Divider flexItem />
                        <Box>
                            <Typography align="center" variant="body2" mb={1}>
                                {currentError.description || currentError.userMessage}
                            </Typography>
                            <Typography align="center" variant="body2">
                                {currentError.actions}
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}

