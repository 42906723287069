import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks/redux'
import {
    TITLE_BUTTON_COMPLETE, TITLE_BUTTON_COMPLETED,
    TITLE_NAMESPACE_INITIATOR,
    TITLE_NAMESPACE_WAS_CREATED_NEUTER,
    TITLE_NAMESPACE_WAS_LAUNCHED_NEUTER,
} from '../../../helpers/enums/titles'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useEmployeePersonalData } from '../../../api/queries/employee/employeeQueries'
import { EmployeeMetaListItem } from '../../EmployeeMetaListItem/EmployeeMetaListItem'

export const ExamResultPageHeader: FC<any> = () => {
    const { massExamUuid, employeeUuid } = useParams()

    const examMeta = useAppSelector(state => state.examItemReducer.meta)
    const { data: employee } = useEmployeePersonalData(employeeUuid || '', !!employeeUuid?.length)

    return (
        <Stack spacing={4}>
            <Box>
                <Typography variant='h1' mb={1}>{examMeta.title}</Typography>
                <Box display='flex' alignItems='center' gap={1}>
                    <Typography variant='body1'>
                        {TITLE_NAMESPACE_WAS_CREATED_NEUTER}: {examMeta.createdAt}
                    </Typography>
                    <Typography variant='body1'>|</Typography>
                    <Typography variant='body1'>
                        {TITLE_NAMESPACE_WAS_LAUNCHED_NEUTER}: {examMeta.startedAt}
                    </Typography>
                </Box>
            </Box>
            {massExamUuid?.length && employee &&
                <EmployeeMetaListItem
                    fullName={`${employee.lastName} ${employee.firstName} ${employee.secondName}`}
                    specialization={employee?.specialization}
                    grade={employee?.grade}
                    isFullNameLink
                    employeeUuid={employee.uuid}
                />
            }
            <Box>
                <Box display='flex' alignItems='center' gap={1} mb={2}>
                    <Box display='flex' gap={1} alignItems='center'>
                        <DateRangeIcon color='warning'/>
                        <Typography variant='body1'>
                            {TITLE_BUTTON_COMPLETE} к {examMeta.endedAt}
                        </Typography>
                    </Box>
                    <Typography variant='body1'>|</Typography>
                    <Typography variant='body1'>
                        {TITLE_BUTTON_COMPLETED} {examMeta.finishedAt}
                    </Typography>
                </Box>
                <Box display='flex' gap={1} alignItems='center'>
                    <AssignmentIcon color='warning'/>
                    <Typography variant="body1">
                        {`${TITLE_NAMESPACE_INITIATOR} - ${examMeta?.author?.lastName} ${examMeta?.author?.firstName?.charAt(0)
                            .toUpperCase()}.`}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    )
}
