import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAssessmentChangeStatusRequest, IAssessmentUpdateRequest } from 'api/typing/assessmentServiceTypes'


interface IAssessmentState {
    assessmentPass: {
        assessment: IAssessment;
        answers: (IEntity & { description: string })[];
    };
    assessmentMeta: IAssessmentMeta;
    assessmentMetaList: IAssessmentMeta[];
    assessmentResult: IAssessmentResult;
}

const initialState: IState<IAssessmentState> = {
    data: {
        assessmentPass: {
            assessment: {} as IAssessment,
            answers: [],
        },
        assessmentMeta: {} as IAssessmentMeta,
        assessmentMetaList: [],
        assessmentResult: {} as IAssessmentResult,
    } as IAssessmentState,
    isLoading: false,
    error: null,
}

export const AssessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setAssessmentMetaList: (state, action: PayloadAction<IAssessmentMeta[]>) => {
            state.data.assessmentMetaList = action.payload
        },
        setAssessmentMeta: (state, action: PayloadAction<IAssessmentMeta>) => {
            state.data.assessmentMeta = action.payload
            if (state.data.assessmentMetaList && action.payload.uuid) {
                state.data.assessmentMetaList = state.data.assessmentMetaList.map(item => {
                    if (item.uuid === action.payload.uuid)
                        return { ...item, ...action.payload }
                    return item
                })
            }
        },
        updateAssessmentMetaEndedAt: (state, action: PayloadAction<IAssessmentUpdateRequest>) => {
            state.data.assessmentMeta.endedAt = action.payload.endedAt
            if (state.data.assessmentMetaList && action.payload.uuid) {
                state.data.assessmentMetaList = state.data.assessmentMetaList.map(item => {
                    if (item.uuid === action.payload.uuid)
                        return { ...item, endedAt: action.payload.endedAt }
                    return item
                })
            }
        },
        deleteAssessmentMetaFormList: (state, action: PayloadAction<string>) => {
            state.data.assessmentMetaList = state.data.assessmentMetaList?.filter(el => el.uuid !== action.payload)
        },
        unshiftAssessmentMetaList: (state, action: PayloadAction<IAssessmentMeta>) => {
            state.data.assessmentMetaList.unshift(action.payload)
        },
        setAssessment: (state, action: PayloadAction<IAssessment>) => {
            state.data.assessmentPass.assessment = action.payload
        },
        assessmentStatusChange: (
            state,
            action: PayloadAction<IAssessmentChangeStatusRequest & { finishedAt?: string }>,
        ) => {
            if (state.data?.assessmentPass?.assessment?.statusUuid)
                state.data.assessmentPass.assessment = { ...state.data.assessmentPass.assessment, ...action.payload }
            if (state.data?.assessmentResult?.statusUuid)
                state.data.assessmentResult = { ...state.data.assessmentResult, ...action.payload }
            if (state.data?.assessmentMeta?.statusUuid) state.data.assessmentMeta.statusUuid = action.payload.statusUuid
            if (state.data?.assessmentMetaList)
                state.data.assessmentMetaList = state.data?.assessmentMetaList?.map(item => {
                    if (item.uuid === action.payload.uuid)
                        return { ...item, ...action.payload }
                    return item
                })
        },
    },
})

export const assessmentReducer = AssessmentSlice.reducer
