import React, { FC, useState } from 'react'
import { Box, Chip, Collapse, Divider, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPETENCIES_GENITIVE } from 'helpers/enums/titles'
import { createBreakpoint } from 'react-use'
import {
    OAAssessmentResultTreeCompetence,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultTree/components/OAAssessmentResultTreeGroup/components/OAAssessmentResultTreeCompetence/OAAssessmentResultTreeCompetence'

type AssessmentResultTreeGroupProps = {
    element: IAssessmentCompetenceGroup;
}

const useBreakPoints = createBreakpoint({ mobile: 0, laptop: 600 })

export const OAAssessmentResultTreeGroup: FC<AssessmentResultTreeGroupProps> = ({ element }) => {
    const breakPoint = useBreakPoints()
    const isMobile = breakPoint === 'mobile'

    const [open, setOpen] = useState(true)

    const handleClick = () => {
        if (element.competencies?.length || element.groupCompetence?.length)
            setOpen(!open)
    }

    return (
        <List disablePadding>
            <ListItem disablePadding sx={{ borderRadius: 4 }}>
                <ListItemButton
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pl: isMobile ? 1 : 2,
                        pr: isMobile ? 2 : 3,
                        py: isMobile ? 0.7 : 1.5,
                        borderRadius: '12px',
                    }}
                    onClick={handleClick}
                >
                    <Box sx={{
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                        <Stack direction="row" mb={0.5} spacing={2} alignItems="center">
                            <Typography maxWidth="500px" variant="subtitle1">
                                {element.title}
                            </Typography>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </Stack>
                        <Typography
                            variant="body1"
                            sx={{
                                color: (theme) => element.competenceCount === 0
                                    ? theme.palette.warning.main
                                    : theme.typography.body1.color,
                            }}
                        >
                            {TITLE_NAMESPACE_COMPETENCIES_GENITIVE}: {element.competenceCount}
                        </Typography>
                    </Box>
                    <Chip
                        sx={{ minWidth: 56 }}
                        label={`${element?.score}%`}
                        color={element?.score > 75 ? 'default' : 'warning'}
                    />
                </ListItemButton>
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    marginLeft: isMobile ? 1 : 2,
                    paddingLeft: isMobile ? 1 : 2,
                    borderLeft: '1px solid #C7D2D3',
                    position: 'relative',
                    '&:after': {
                        position: 'absolute',
                        content: '""',
                        width: 7,
                        height: 7,
                        backgroundColor: '#C7D2D3',
                        borderRadius: '50%',
                        top: -3.5,
                        left: -3.5,
                    },
                }}
            >
                <Divider sx={{ my: 0.5 }} />
                <Stack divider={<Divider sx={{ my: 0.5 }} />}>
                    {element.groupCompetence?.map((item) => (
                        <OAAssessmentResultTreeGroup
                            key={item.uuid}
                            element={item}
                        />
                    ))}
                    {element?.competencies?.length > 0 && element.competencies?.map((item) => (
                        <OAAssessmentResultTreeCompetence
                            key={item.uuid}
                            element={item}
                        />
                    ))}
                </Stack>
            </Collapse>
        </List>
    )
}
