import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_FORMAT,
    TITLE_NAMESPACE_LINK,
    TITLE_NAMESPACE_PDP_LEARNED,
    TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_TITLE,
    TITLE_NAMESPACE_TYPE,
} from 'helpers/enums/titles'
import { DevelopmentPdpCompetenceMaterial } from '../DevelopmentPdpCompetenceMaterial/DevelopmentPdpCompetenceMaterial'
import React, { FC } from 'react'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'

type DevelopmentPdpCompetenceMaterialsTableProps = {
    sortedMaterials: IPdpCompetenceMaterial[];
    skillLevelUuid: string;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpCompetenceMaterialsTable: FC<DevelopmentPdpCompetenceMaterialsTableProps> = ({
    sortedMaterials,
    skillLevelUuid,
    pdpStatus,
}) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width="1%" align="center">{TITLE_NAMESPACE_PDP_LEARNED}</TableCell>
                    <TableCell width="1%" align="center">{TITLE_NAMESPACE_TYPE}</TableCell>
                    <TableCell width="100%" align="left">{TITLE_NAMESPACE_TITLE}</TableCell>
                    <TableCell width="1%" align="center">{TITLE_NAMESPACE_COMMENT}</TableCell>
                    <TableCell width="1%" align="left">{TITLE_NAMESPACE_FORMAT}</TableCell>
                    <TableCell
                        width="1%"
                        sx={{ whiteSpace: 'nowrap' }}
                        align="left"
                    >
                        {TITLE_NAMESPACE_STUDY_TIME}
                    </TableCell>
                    <TableCell
                        width="1%"
                        sx={{ minWidth: 146 }}
                        align="left"
                    >
                        {TITLE_NAMESPACE_LINK}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedMaterials.map(item => (
                    <DevelopmentPdpCompetenceMaterial
                        key={item.uuid}
                        material={item}
                        skillLevelUuid={skillLevelUuid}
                        pdpStatus={pdpStatus}
                    />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)
