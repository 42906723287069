import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAssessmentAnswers } from 'api/queries/form/formQueries'
import { useGetAssessmentPass } from 'api/queries/assessment/assessmentQueries'
import { useCpaScaleNumeric } from 'api/queries/cpa/cpaQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import {
    EmployeeAssessmentPreviewContentHeader,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/components/EmployeeAssessmentPreviewContentHeader/EmployeeAssessmentPreviewContentHeader'
import {
    EmployeeAssessmentPreviewContentCompetencies,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/components/EmployeeAssessmentPreviewContentCompetencies/EmployeeAssessmentPreviewContentCompetencies'
import {
    EmployeeAssessmentPreviewMobileCompetencies,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/components/EmployeeAssessmentPreviewMobileCompetencies/EmployeeAssessmentPreviewMobileCompetencies'

export const EmployeeAssessmentPreviewContent = () => {
    const { assessmentUuid } = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { data: assessment, isFetching, error } = useGetAssessmentPass(assessmentUuid || '')
    const {
        error: errorCpaAnswers,
        isFetching: isFetchingCpaAnswers,
    } = useCpaScaleNumeric(assessment?.cpa?.uuid || '', assessment ? !!assessment?.cpa?.scale.uuid : false)

    const {
        error: errorAssAnswers,
        isFetching: isFetchingAssAnswers,
    } = useGetAssessmentAnswers(assessment ? !assessment?.cpa?.scale.uuid : false)


    if (isFetching || isFetchingCpaAnswers || isFetchingAssAnswers) return <LoadingComponent/>
    if (error || errorCpaAnswers || errorAssAnswers)
        return <ErrorPage error={error || errorCpaAnswers || errorAssAnswers}/>

    return (
        <Stack spacing={4}>
            <EmployeeAssessmentPreviewContentHeader/>
            {mobile ? <EmployeeAssessmentPreviewMobileCompetencies/> : <EmployeeAssessmentPreviewContentCompetencies/>}
        </Stack>
    )
}
