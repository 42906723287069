import React, { FC, useMemo } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { employeeActions } from 'store/actions'
import { Controller, useForm } from 'react-hook-form'
import { IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate } from 'api/typing/employeeTypes'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_DATE_END,
    TITLE_FIELD_DATE_START,
    TITLE_FIELD_PROJECT,
    TITLE_FIELD_ROLE,
} from 'helpers/enums/titles'
import { HINT_CHOOSE, HINT_ERROR_INCORRECT_DATE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { emptySelectItem } from 'helpers/enums/emptySelectItem'
import { useEffectOnce } from 'react-use'
import { addYears, format, isBefore, parse, subYears } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { DateRangeRounded } from '@mui/icons-material'
import { InputForm } from 'components/form/InputForm/InputForm'

type EditPreviousPositionItemFormProps = {
    itemData?: IEmployeeWorkInCompanyProjectsAndRoles;
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate) => void;
}

type FormValues = Omit<IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate, 'endDate' | 'startDate'> & {
    endDate: Date | null;
    startDate: Date | null;
}

const isEqualObjectsProjectsAndRoles = (
    item: IEmployeeWorkInCompanyProjectsAndRoles,
    formData: FormValues,
) => {
    const endDateFormatted = formData.endDate ? format(formData.endDate, 'dd.MM.yyyy') : ''
    const startDateFormatted = formData.startDate ? format(formData.startDate, 'dd.MM.yyyy') : ''

    const equalProject = item?.project?.uuid === formData.projectUuid
    const equalRole = item?.role?.uuid === formData.roleUuid

    const equalStartDate = item.startDate === startDateFormatted
    const equalEndDate = item.endDate === endDateFormatted

    return equalProject && equalRole && equalStartDate && equalEndDate
}

export const EditProjectsAndRolesItemForm: FC<EditPreviousPositionItemFormProps> = ({
    itemData,
    onSubmitForm,
    onCancelForm,
}) => {
    const dispatch = useAppDispatch()
    const {
        data: {
            projects,
            roles,
        },
        isLoading,
    } = useAppSelector(state => state.employeeEditWorkInCompanyProjectAndRolesReducer)

    const { handleSubmit, control, watch, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            projectUuid: itemData?.project?.uuid ?? '',
            roleUuid: itemData?.role?.uuid ?? '',
            endDate: itemData?.endDate ? parse(itemData.endDate, 'dd.MM.yyyy', new Date()) : null,
            startDate: itemData?.startDate ? parse(itemData.startDate, 'dd.MM.yyyy', new Date()) : null,
        },
    })

    const watchDateEnd = watch('endDate')
    const watchDateStart = watch('startDate')

    let isFetching = false

    useEffectOnce(() => {
        const fetchData = async () => {
            isFetching = true
            await dispatch(employeeActions.getEmployeeEditWorkInCompanyProjectsAndRolesRoles())
            await dispatch(employeeActions.getEmployeeEditWorkInCompanyProjectsAndRolesProjects())
            isFetching = false
        }
        if (!isFetching) {
            fetchData().catch(e => e)
        }
    })


    const projectList = useMemo(() => {
        let list = [] as IEntity[]
        if (projects) {
            list = [...projects]
            list.push(emptySelectItem)
        }
        return list
    },
    [projects, projects?.length],
    )
    const roleList = useMemo(() => {
        let list = [] as IEntity[]
        if (roles) {
            list = [...roles]
            list.push(emptySelectItem)
        }
        return list
    },
    [roles, roles?.length],
    )

    const onSubmit = (data: FormValues) => {
        const emptyProject = data.projectUuid === '' || isEmptyUuid(data.projectUuid)
        const emptyRole = data.roleUuid === '' || isEmptyUuid(data.roleUuid)
        if (!data.startDate
            || (emptyProject && emptyRole)
            || itemData && isEqualObjectsProjectsAndRoles(itemData, data)
        ) return
        const payload: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
            startDate: format(data.startDate, 'dd.MM.yyyy'),
            endDate: data.endDate ? format(data.endDate, 'dd.MM.yyyy') : '',
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="projectUuid"
                    control={control}
                    defaultValue={itemData?.project?.uuid ?? ''}
                    rules={{
                        required: HINT_ERROR_REQUIRED,
                    }}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="projectUuid"
                            name="projectUuid"
                            values={projectList ?? []}
                            disabled={isLoading}
                            required
                            title={TITLE_FIELD_PROJECT}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.projectUuid}
                            helperText={errors?.projectUuid ? errors.projectUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="roleUuid"
                    control={control}
                    defaultValue={itemData?.role?.uuid ?? ''}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="roleUuid"
                            name="roleUuid"
                            values={roleList ?? []}
                            disabled={isLoading}
                            title={TITLE_FIELD_ROLE}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.roleUuid}
                            helperText={errors?.roleUuid ? errors.roleUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Box>
                    <Controller
                        control={control}
                        name="startDate"
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            validate: {
                                min: (date: any) =>
                                    isBefore(subYears(new Date(), 200), date) || HINT_ERROR_INCORRECT_DATE,
                                max: (date: any) => isBefore(
                                    date,
                                    watchDateEnd ? watchDateEnd : addYears(new Date(), 10),
                                ) || HINT_ERROR_INCORRECT_DATE,
                            },
                        }}
                        render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                            <DatePicker
                                {...field}
                                inputRef={ref}
                                inputFormat="dd.MM.yyyy"
                                minDate={subYears(new Date(), 200)}
                                maxDate={watchDateEnd ? watchDateEnd : addYears(new Date(), 10)}
                                components={{ OpenPickerIcon: DateRangeRounded }}
                                toolbarPlaceholder={HINT_CHOOSE}
                                renderInput={(params) => (
                                    <InputForm
                                        {...params}
                                        id="startDate"
                                        title={TITLE_FIELD_DATE_START}
                                        onBlur={onBlur}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: HINT_CHOOSE,
                                        }}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <>
                    <Typography variant="body1" mt={4.5} mx={1}>
                        -
                    </Typography>
                    <Box>
                        <Controller
                            control={control}
                            name="endDate"
                            rules={{
                                validate: watchDateEnd ? {
                                    min: (date: any) =>
                                        isBefore(watchDateStart
                                            ? watchDateStart
                                            : subYears(new Date(), 200), date) || HINT_ERROR_INCORRECT_DATE,
                                    max: (date: any) =>
                                        isBefore(date, addYears(new Date(), 10)) || HINT_ERROR_INCORRECT_DATE,
                                } : undefined,
                            }}
                            render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                <DatePicker
                                    {...field}
                                    inputRef={ref}
                                    inputFormat="dd.MM.yyyy"
                                    minDate={watchDateStart ? watchDateStart : subYears(new Date(), 200)}
                                    maxDate={addYears(new Date(), 10)}
                                    components={{ OpenPickerIcon: DateRangeRounded }}
                                    toolbarPlaceholder={HINT_CHOOSE}
                                    renderInput={(params) => (
                                        <InputForm
                                            {...params}
                                            id="endDate"
                                            title={TITLE_FIELD_DATE_END}
                                            onBlur={onBlur}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: HINT_CHOOSE,
                                            }}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                </>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    onClick={() => onCancelForm()}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button type="submit" variant="contained">
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
