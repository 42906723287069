import React, { useMemo } from 'react'
import { Box, Chip, Divider, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
    TITLE_NAMESPACE_CURRENT,
    TITLE_NAMESPACE_DURATION,
    TITLE_NAMESPACE_ENDED_TO,
    TITLE_NAMESPACE_MINUTES_SHORT,
} from 'helpers/enums/titles'
import { AccessTimeRounded, DateRangeRounded, InfoOutlined, SaveRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentTimeToProcess } from 'helpers/assessment/getAssessmentTimeToProcess'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import { HINT_CHIP_DATA_SAVED_AUTOMATICALLY } from 'helpers/enums/hints'

export const EmployeeAssessmentPreviewContentHeader = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const isValidDateEnd = isValidDateFromApi(assessment?.endedAt)
    const minutes = useMemo(() => getAssessmentTimeToProcess(assessment?.indicatorsCount), [assessment])

    const evaluatedSpecializationUuid = assessment?.evaluated?.currentPosition?.specializationUuid
    const evaluatedGradeUuid = assessment?.evaluated?.currentPosition?.gradeUuid

    const positionTitle = assessment?.cpa?.criteria?.title
        || evaluatedSpecializationUuid === assessment?.position?.specializationUuid
        && evaluatedGradeUuid === assessment?.position?.gradeUuid
        && `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || assessment?.position?.title

    return (
        <Stack spacing={{ 'mobile': 3, 'laptop': 4 }}>
            <Stack spacing={1}>
                <Typography variant="h1">
                    {assessment?.title}
                </Typography>
                <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
                    <ChipAssessmentType cpaType={assessment?.cpa?.typeUuid || assessment?.typeUuid}/>
                    {isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                        <DateRangeRounded color="warning"/>
                        <Typography variant="body1">
                            {`${TITLE_NAMESPACE_ENDED_TO} ${assessment?.endedAt}`}
                        </Typography>
                    </Stack>}
                </Stack>
            </Stack>
            <Stack spacing={2}>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {`${assessment?.evaluated?.lastName} ${assessment?.evaluated?.firstName} ${assessment?.evaluated?.secondName}`}
                    </Typography>
                    <Typography variant="body1">
                        {assessment?.evaluated?.currentPosition?.title}
                    </Typography>
                </Stack>
                <Box>
                    <PointPositionSpecializationGrade title={positionTitle}/>
                </Box>
            </Stack>
            <Divider/>
            {mobile
                ? <Paper elevation={0} sx={{ p: 1.5, background: theme.palette.background.default, borderRadius: 4 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack>
                            <Typography variant="body1" fontWeight={500}>
                                {`${TITLE_NAMESPACE_DURATION} ${minutes} ${TITLE_NAMESPACE_MINUTES_SHORT}.`}
                            </Typography>
                            <Typography variant="body1" fontWeight={500}>
                                {HINT_CHIP_DATA_SAVED_AUTOMATICALLY}
                            </Typography>
                        </Stack>
                        <IconButton color="primary">
                            <InfoOutlined/>
                        </IconButton>
                    </Stack>
                </Paper>
                : <Stack spacing={1} alignItems="flex-start">
                    <Chip
                        icon={<AccessTimeRounded/>}
                        label={`${TITLE_NAMESPACE_DURATION} ${minutes} ${TITLE_NAMESPACE_MINUTES_SHORT}.`}
                        sx={{
                            fontSize: 16,
                            lineHeight: '19px',
                            fontWeight: 500,
                            color: theme.palette.text.secondary,
                            background: theme.palette.background.default,
                            '& .MuiChip-icon': {
                                marginLeft: 1.5,
                            },
                        }}
                    />
                    <Chip
                        icon={<SaveRounded/>}
                        label={HINT_CHIP_DATA_SAVED_AUTOMATICALLY}
                        sx={{
                            fontSize: 16,
                            lineHeight: '19px',
                            fontWeight: 500,
                            color: theme.palette.text.secondary,
                            background: theme.palette.background.default,
                            '& .MuiChip-icon': {
                                marginLeft: 1.5,
                            },
                        }}
                    />
                </Stack>}
        </Stack>
    )
}
