import { AppDispatch } from 'store/store'
import { OpenAccessService } from 'api/services/OpenAccessService'
import { oaAssessmentSlice } from 'store/reducers/openAccess/assessment/oaAssessmentSlice'
import { IAssessmentSetAnswerRequest } from 'api/typing/assessmentServiceTypes'
import { oaAssessmentAnswersSlice } from 'store/reducers/openAccess/assessment/oaAssessmentAnswers'


export class OAAssessmentAction {
    private static get oaAssessment() {
        return new OpenAccessService().oaAssessmentService()
    }

    public getAssessment(uuid: string, hash: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(oaAssessmentSlice.actions.fetching())
                const data = await OAAssessmentAction.oaAssessment.getAssessment(uuid, hash)
                dispatch(oaAssessmentSlice.actions.setAssessment(data))
                dispatch(oaAssessmentAnswersSlice.actions.initAssessmentAnswers(data))
                dispatch(oaAssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(oaAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentSave(dto: { uuid: string; hash: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(oaAssessmentSlice.actions.fetching())
                void await OAAssessmentAction.oaAssessment.assessmentSave(dto)
                dispatch(oaAssessmentSlice.actions.setAssessmentStatusComplete())
                dispatch(oaAssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(oaAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // public createAssessmentForAnonymous(payload: IOAAssessmentCreateRequest) {
    //     return async (dispatch: AppDispatch) => {
    //         try {
    //             dispatch(oaAssessmentSlice.actions.fetching())
    //             const { uuid } = await OAAssessmentAction.oaAssessment.createAssessmentForAnonymous(payload)
    //             dispatch(oaAssessmentSlice.actions.fetchingSuccess())
    //             return uuid
    //         } catch (e: any) {
    //             dispatch(oaAssessmentSlice.actions.fetchingError(e))
    //             throw e
    //         }
    //     }
    // }

    public getAssessmentResult(uuid: string, hash: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(oaAssessmentSlice.actions.fetching())
                const data = await OAAssessmentAction.oaAssessment.getAssessmentResult(uuid, hash)
                dispatch(oaAssessmentSlice.actions.setAssessmentResult(data))
                dispatch(oaAssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(oaAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentAddAnswer(payload: IAssessmentSetAnswerRequest & { hash: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(oaAssessmentSlice.actions.fetching())
                const { score } = await OAAssessmentAction.oaAssessment.assessmentAddAnswer(payload)
                dispatch(oaAssessmentAnswersSlice.actions.setAssessmentAnswer(payload))
                dispatch(oaAssessmentSlice.actions.fetchingSuccess())
                return score
            } catch (e: any) {
                dispatch(oaAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public checkAssessmentAnswers(answers: string[]) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(oaAssessmentSlice.actions.fetching())
                dispatch(oaAssessmentSlice.actions.checkAssessmentAnswers(answers))
                dispatch(oaAssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(oaAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
