import { ExamService } from 'api/services/exam'
import { AppDispatch } from '../../store'
import { examItemSlice } from '../../reducers/exam/examItemSlice'
import { IExamAnswerSaveReq, IExamCompleteReq, IExamMeta, IExamResultsResp } from '../../../api/typing/exam'


export class ExamItemAction {
    private static get examItemApi() {
        return new ExamService().itemService()
    }

    public setItem(data: IExamMeta) {
        return (dispatch: AppDispatch) => {
            dispatch(examItemSlice.actions.setExamMeta(data))
        }
    }
    public getCurrentQuestion(data: {examUuid: string, questionUuid: string}) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(examItemSlice.actions.fetching())
                const question = await ExamItemAction.examItemApi.getQuestion(data.examUuid, data.questionUuid)
                dispatch(examItemSlice.actions.setCurrentQuestion(question))
                dispatch(examItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(examItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
    public setAnsweredQuestion(data: IExamAnswerSaveReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(examItemSlice.actions.fetching())
                await ExamItemAction.examItemApi.saveAnswer(data)
                dispatch(examItemSlice.actions.setAnswersToMeta(data))
                dispatch(examItemSlice.actions.setAnswer(data))
                dispatch(examItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(examItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
    public completeExam(data: IExamCompleteReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(examItemSlice.actions.fetching())
                await ExamItemAction.examItemApi.completeExam(data)
                dispatch(examItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(examItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
    public setExamResult(data: IExamResultsResp) {
        return async (dispatch: AppDispatch) => {
            try {
                await dispatch(examItemSlice.actions.setResult(data))
            } catch (e: any) {
                dispatch(examItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
