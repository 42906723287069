import { MassExamService } from 'api/services/massExamService'
import { AppDispatch } from '../../store'
import { massExamQuestionConstructorSlice } from '../../reducers/massExam/massExamQuestionConstructorSlice'
import {
    IMassExamAnswerImgDeleteReq,
    IMassExamAnswerImgUploadReq,
    IMassExamQuestion,
    IMassExamQuestionCreateReq,
    IMassExamQuestionDeleteReq,
    IMassExamQuestionImgDeleteReq,
    IMassExamQuestionImgUploadReq, IMassExamQuestionsFileUploadReq,
    IMassExamQuestionSortUpdateReq,
    IMassExamQuestionUpdateReq, MassExamQuestionsFileUploadErrType,
} from 'api/typing/massExam'
import { emptyQuestion } from '../../../helpers/massExam/massExamConstructor'
import { massExamQuestionConstructorAction } from '../index'
import { UploadService } from '../../../api/services/UploadService'

export class MassExamQuestionConstructorAction {
    private static get questionConstructorApi() {
        return new MassExamService().questionConstructorService()
    }

    private static get questionConstructorUploadApi() {
        return new UploadService().massExamUploadService()
    }

    public getList(payload: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const data = await MassExamQuestionConstructorAction.questionConstructorApi.getList(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setList(data))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setList(data: IMassExamQuestion[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(massExamQuestionConstructorSlice.actions.setList(data))
        }
    }

    public createQuestion(payload: IMassExamQuestionCreateReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const { uuid } = await MassExamQuestionConstructorAction.questionConstructorApi.createQuestion(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setNewQuestion({
                    ...emptyQuestion,
                    uuid,
                    sort: payload.sort,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
                return uuid
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateQuestion(payload: IMassExamQuestionUpdateReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const resp = await MassExamQuestionConstructorAction.questionConstructorApi.updateQuestion(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setUpdatedQuestion(resp))
                dispatch(massExamQuestionConstructorSlice.actions.setDraftEditedQuestion(null))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
                return resp
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteQuestion(payload: {
        deleteQuestionPayload: IMassExamQuestionDeleteReq,
        newSortList: IMassExamQuestion[],
    }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                // eslint-disable-next-line max-len
                await MassExamQuestionConstructorAction.questionConstructorApi.deleteQuestion(payload.deleteQuestionPayload)
                if (payload.newSortList.length) {
                    await dispatch(massExamQuestionConstructorAction.updateSort({
                        newSortList: payload.newSortList,
                        updateSortPayload: payload.newSortList.map(item => ({
                            massExamUuid: payload.deleteQuestionPayload.massExamUuid || '',
                            uuid: item.uuid,
                            sort: item.sort,
                        })),
                    }))
                } else {
                    dispatch(massExamQuestionConstructorSlice.actions.setList([]))
                }
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteAllQuestions(massExamUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                await MassExamQuestionConstructorAction.questionConstructorApi.deleteAllQuestions(massExamUuid)
                dispatch(massExamQuestionConstructorSlice.actions.setList([]))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateSort(
        payload: {
            newSortList: IMassExamQuestion[],
            updateSortPayload: IMassExamQuestionSortUpdateReq[]
        }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                // eslint-disable-next-line max-len
                await MassExamQuestionConstructorAction.questionConstructorApi.updateQuestionsSort(payload.updateSortPayload)
                dispatch(massExamQuestionConstructorSlice.actions.setList(payload.newSortList))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public uploadQuestionImage(payload: IMassExamQuestionImgUploadReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const { link } =
                    await MassExamQuestionConstructorAction.questionConstructorUploadApi.uploadQuestionImage(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setQuestionImageLink({
                    link,
                    uuid: payload.questionUuid,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.setQuestionImgLinkChangesToDraft({
                    imgLink: link,
                    questionUuid: payload.questionUuid,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteQuestionImage(payload: IMassExamQuestionImgDeleteReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                await MassExamQuestionConstructorAction.questionConstructorUploadApi.deleteQuestionImage(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setQuestionImageLink({
                    link: '',
                    uuid: payload.questionUuid,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.setQuestionImgLinkChangesToDraft({
                    imgLink: '',
                    questionUuid: payload.questionUuid,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public uploadAnswerImage(payload: IMassExamAnswerImgUploadReq & {answerSort: number}) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const { answerUuid, link } =
                    await MassExamQuestionConstructorAction.questionConstructorUploadApi.uploadAnswerImage(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setAnswerImageLink({
                    link,
                    questionUuid: payload.questionUuid,
                    answerUuid: answerUuid.length ? answerUuid : payload.answerUuid,
                    answerSort: payload.answerSort,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.setAnswerImgLinkChangesToDraft({
                    imgLink: link,
                    questionUuid: payload.questionUuid,
                    answerUuid: answerUuid.length ? answerUuid : payload.answerUuid,
                    answerSort: payload.answerSort,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteAnswerImage(payload: IMassExamAnswerImgDeleteReq & {answerSort: number}) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                await MassExamQuestionConstructorAction.questionConstructorUploadApi.deleteAnswerImage(payload)
                dispatch(massExamQuestionConstructorSlice.actions.setAnswerImgLinkChangesToDraft({
                    imgLink: '',
                    questionUuid: payload.questionUuid,
                    answerUuid: payload.answerUuid,
                    answerSort: payload.answerSort,
                }))
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setDraftEditedQuestion(data: IMassExamQuestion | null) {
        return (dispatch: AppDispatch) => {
            dispatch(massExamQuestionConstructorSlice.actions.setDraftEditedQuestion(data))
        }
    }

    public uploadQuestionsFile(payload: IMassExamQuestionsFileUploadReq) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamQuestionConstructorSlice.actions.fetching())
                const data = await MassExamQuestionConstructorAction.questionConstructorApi.uploadQuestionsFile(payload)
                if (data.err?.errType !== MassExamQuestionsFileUploadErrType.STRUCTURE) {
                    dispatch(massExamQuestionConstructorSlice.actions.setListFromFile(data.questions))
                }
                dispatch(massExamQuestionConstructorSlice.actions.fetchingSuccess())
                return data
            } catch (e: any) {
                dispatch(massExamQuestionConstructorSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
