import { format, fromUnixTime, getTime, parse } from 'date-fns'

export const convertLocalStringToUtcString = (date?: string): string => {
    const currentDate = date ? parse(date, 'dd.MM.yyyy HH:mm', new Date()) : new Date()
    const offset = new Date().getTimezoneOffset() * 60000

    const milliseconds = getTime(currentDate)
    const unix = fromUnixTime((milliseconds + offset) / 1000)

    return format(unix, 'dd.MM.yyyy HH:mm')
}
