import { TransferMassExam } from './transferMassExam'
import {
    IMassExamChangeStatusRequest,
    IMassExamDeleteEmployeeRequest,
    IMassExamDescriptionUpdateRequest,
    IMassExamGetEmployeeForAddResponse,
    IMassExamGetItemEmployeesResponse,
    IMassExamItemMeta,
    IMassExamPassMarkSaveRequest,
    IMassExamSaveEmployeesRequest,
    IMassExamUpdateRequest,
} from '../../typing/massExam'
import { IExamQuestion } from '../../typing/exam'

export class MassExamItemService extends TransferMassExam {
    public getItem(uuid: string) {
        const path = `/${uuid}/meta`
        return this.request<null, IMassExamItemMeta>('GET', path)
    }

    public updateItem(data: IMassExamUpdateRequest) {
        const path = `/${data.uuid}`
        return this.request<IMassExamUpdateRequest>('PUT', path, { data })
    }

    public deleteItem(uuid: string) {
        const path = `/${uuid}`
        return this.request<{ uuid: string }>('DELETE', path, { data: { uuid } })
    }

    public updateItemDescription(data: IMassExamDescriptionUpdateRequest) {
        const path = `/${data.uuid}/description`
        return this.request<IMassExamDescriptionUpdateRequest>('POST', path, { data })
    }

    public changeStatusMassExam(data: IMassExamChangeStatusRequest) {
        const path = `/${data.uuid}/status`
        return this.request<IMassExamChangeStatusRequest>('POST', path, { data })
    }

    public getEmployeesList(uuid: string) {
        const path = `/${uuid}/employee/setup/all`
        return this.request<null, IMassExamGetEmployeeForAddResponse[]>('GET', path)
    }

    public saveEmployeesToMassExam(data: IMassExamSaveEmployeesRequest) {
        const path = `/${data.uuid}/employee`
        return this.request<IMassExamSaveEmployeesRequest>('POST', path, { data })
    }

    public getMassExamExamedEmployees(uuid: string) {
        const path = `/${uuid}/employee/examed/all`
        return this.request<null, IMassExamGetItemEmployeesResponse[]>('GET', path)
    }

    public deleteMassExamEmployee(data: IMassExamDeleteEmployeeRequest) {
        const path = `/${data.uuid}/employee/${data.employeeUuid}`
        return this.request<IMassExamDeleteEmployeeRequest>('DELETE', path, { data })
    }

    public savePassMark(data: IMassExamPassMarkSaveRequest) {
        const path = `${data.uuid}/pass-mark/point`
        return this.request<IMassExamPassMarkSaveRequest>('POST', path, { data })

    }

    public deletePassMark(massExamUuid: string) {
        const path = `${massExamUuid}/pass-mark`
        return this.request<{ massExamUuid: string }>('DELETE', path, { data: { massExamUuid } })
    }

    public getQuestionPreview(massExamUuid: string, questionUuid: string) {
        const path = `/${massExamUuid}/question/${questionUuid}/preview`
        return this.request<null, IExamQuestion>('GET', path)
    }
}
