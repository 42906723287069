import { AppDispatch } from 'store/store'
import { SpecializationService } from 'api/services/SpecializationService'
import { specializationSlice } from 'store/reducers/SpecializationSlice'
import {
    ISpecializationCompetenceMatrixUpdateRequest,
    ISpecializationGradeOnePayload, ISpecializationListOnePayload,
} from 'api/typing/specializationTypes'


export type ChangeSpecializationCompetence = {
    competenceGroupUuid: string;
    competenceUuid: string;
    grade: ISpecializationCompetenceGradeItem;
}

export type SpecializationGradeMetaPayload = {
    currentGradeTitle: string;
    currentGradeDescription: string;
}

export class SpecializationAction {
    private static get specializationListApi() {
        return new SpecializationService().specializationListService()
    }

    private static get gradeApi() {
        return new SpecializationService().gradeService()
    }

    private static get competenceMatrixApi() {
        return new SpecializationService().competenceMatrixService()
    }

    public getSpecialization(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                const data = await SpecializationAction.specializationListApi.getSpecialization(uuid)
                dispatch(specializationSlice.actions.getSpecialization(data))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteSpecialization(payload: ISpecializationListOnePayload) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                void await SpecializationAction.specializationListApi.deleteOne(payload)
                dispatch(specializationSlice.actions.deleteSpecialization(payload))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getSpecializationGrade(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                const data = await SpecializationAction.gradeApi.getSpecializationGrade(uuid)
                dispatch(specializationSlice.actions.getSpecializationGrade(data))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getSpecializationGradeMeta(specializationUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                const data = await SpecializationAction.gradeApi.getSpecializationGradeMeta(specializationUuid)
                dispatch(specializationSlice.actions.getSpecializationGradeMeta(data))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createSpecializationGrade(
        specializationUuid: string,
        payload: ISpecializationGradeOnePayload,
        metaPayload: SpecializationGradeMetaPayload,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                void await SpecializationAction.gradeApi.createSpecializationGrade(
                    specializationUuid,
                    payload,
                )
                dispatch(specializationSlice.actions.createSpecializationGrade({
                    ...payload,
                    ...metaPayload,
                }))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateSpecializationGrade(
        specializationUuid: string,
        gradeUuid: string,
        payload: ISpecializationGradeOnePayload,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                void await SpecializationAction.gradeApi.updateSpecializationGrade(
                    specializationUuid,
                    gradeUuid,
                    payload,
                )
                await dispatch(specializationSlice.actions.updateSpecializationGrade(payload))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteSpecializationGrade(specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                void await SpecializationAction.gradeApi.deleteSpecializationGrade(
                    specializationUuid,
                    gradeUuid,
                )
                await dispatch(specializationSlice.actions.deleteSpecializationGrade(gradeUuid))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getSpecializationCompetencies(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                const data = await SpecializationAction.competenceMatrixApi.getSpecializationCompetencies(uuid)
                dispatch(specializationSlice.actions.getSpecializationCompetencies(data))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getSpecializationCompetenceGrade(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                const data = await SpecializationAction.competenceMatrixApi.getSpecializationCompetenceGrade(uuid)
                dispatch(specializationSlice.actions.getSpecializationCompetenceGrade(data))
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public saveChangesSpecializationCompetenceMatrix(uuid: string, data: ISpecializationCompetenceMatrixUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationSlice.actions.fetching())
                void await SpecializationAction.competenceMatrixApi.updateSpecializationCompetence(uuid, data)
                await dispatch(specializationSlice.actions.saveChangesSpecializationCompetenceMatrix())
            } catch (e: any) {
                dispatch(specializationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeSpecializationCompetence(
        competenceGroupUuid: string,
        competenceUuid: string,
        grade: ISpecializationCompetenceGradeItem,
    ) {
        return (dispatch: AppDispatch) => {
            dispatch(specializationSlice.actions.fetching())
            dispatch(specializationSlice.actions.changeSpecializationCompetence({
                competenceGroupUuid,
                competenceUuid,
                grade,
            }))
        }
    }

    public cancelChangeSpecializationCompetence() {
        return (dispatch: AppDispatch) => {
            dispatch(specializationSlice.actions.fetching())
            dispatch(specializationSlice.actions.cancelChangeSpecializationCompetence())
        }
    }
}
