import { emptyUuid } from '../isEmptyUuid'
import React from 'react'
import { ClearRounded, PlayArrowRounded, SummarizeRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_WAS_CREATED_NEUTER } from '../enums/titles'
import {
    massExamStatusComplete,
    massExamStatusCreated,
} from '../massExam/massExamStatuses'
import PersonIcon from '@mui/icons-material/Person'
import CheckIcon from '@mui/icons-material/Check'

export const examStatusCreated = emptyUuid // 'Создан', 'new'
export const examStatusAwaitSelf = '21fdd72e-5365-4b08-a629-5faefc063ba4' // 'Назначен', 'await_self'
export const examStatusToPass = '91205e6b-0784-474f-8bcd-d71021a115be' // 'На прохождении', 'to_pass'
export const examStatusComplete = '9815fa00-def5-4d18-8f7e-29a5d5062fd8' // 'Завершено', 'done'
export const examStatusCancel = 'e70e8e11-69c9-4ce5-88c5-6ad1d3e344b2' // 'Не завершено', 'cancel'

export enum ExamStatusCode {
    CREATED = 'created',
    AWAIT_SELF = 'await_self',
    TO_PASS = 'to_pass',
    DONE = 'done',
    CANCEL = 'cancel',
}

type ExamStatuses =
    typeof examStatusCreated
    | typeof examStatusAwaitSelf
    | typeof examStatusToPass
    | typeof examStatusComplete
    | typeof examStatusCancel

export interface IExamStatusChip extends IStatusChip {
    uuid: ExamStatuses;
    code: ExamStatusCode;
}

export const examStatusAwaitSelfEntity = {
    uuid: examStatusAwaitSelf,
    title: 'Назначен',
}
export const examStatusToPassEntity = {
    uuid: examStatusToPass,
    title: 'На прохождении',
}

export const examStatusCompleteEntity = {
    uuid: massExamStatusComplete,
    title: 'Завершен',
}

export const examStatusCancelEntity = {
    uuid: examStatusCancel,
    title: 'Не завершен',
}

export const examStatusesEntity = [
    examStatusAwaitSelfEntity,
    examStatusToPassEntity,
    examStatusCompleteEntity,
    examStatusCancelEntity,
]

const examStatusChips: Record<string, IExamStatusChip> = {
    [examStatusCreated]: {
        uuid: examStatusCreated,
        color: 'default',
        variant: 'filled',
        code: ExamStatusCode.CREATED,
        icon: React.createElement(SummarizeRounded),
        title: TITLE_NAMESPACE_WAS_CREATED_NEUTER,
    },
    [examStatusAwaitSelf]: {
        uuid: examStatusAwaitSelf,
        color: 'primary',
        variant: 'outlined',
        code: ExamStatusCode.AWAIT_SELF,
        icon: React.createElement(PersonIcon),
        title: 'Назначен',
    },
    [examStatusToPass]: {
        uuid: examStatusToPass,
        color: 'primary',
        variant: 'outlined',
        code: ExamStatusCode.TO_PASS,
        icon: React.createElement(PlayArrowRounded),
        title: 'На прохождении',
    },
    [examStatusComplete]: {
        uuid: examStatusComplete,
        color: 'success',
        variant: 'filled',
        code: ExamStatusCode.DONE,
        icon: React.createElement(CheckIcon),
        title: 'Завершен',
    },
    [examStatusCancel]: {
        uuid: examStatusCancel,
        color: 'default',
        variant: 'filled',
        code: ExamStatusCode.CANCEL,
        icon: React.createElement(ClearRounded),
        title: 'Не завершен',
    },
}

export const getExamStatusChip = (status?: string) => status && examStatusChips[status]
    ? examStatusChips[status]
    : examStatusChips[massExamStatusCreated]
