import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { TITLE_BUTTON_BACK_TO_ASSESSMENT } from 'helpers/enums/titles'
import {
    EmployeeAssessmentPreviewContent,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/EmployeeAssessmentPreviewContent'

export const EmployeeAssessmentPreview = () => {
    const { assessmentUuid, employeeUuid } = useParams()
    const navigate = useNavigate()
    const path = `${PATH_EMPLOYEES}/${employeeUuid}/assessment/${assessmentUuid}`

    return (
        <Box>
            <Button
                sx={{ mb: 3 }}
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate(path, { replace: true })}
            >
                {TITLE_BUTTON_BACK_TO_ASSESSMENT}
            </Button>
            <EmployeeAssessmentPreviewContent/>
        </Box>
    )
}
