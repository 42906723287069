import React, { FC, useEffect, useState } from 'react'
import { HINT_EMAIL_RECOVER, HINT_ERROR_EMAIL, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { PATTERN_EMAIL } from 'helpers/enums/regexPatterns'
import { InputForm } from 'components/form/InputForm/InputForm'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch } from 'store/hooks/redux'
import { Box, Stack, Typography } from '@mui/material'
import { authActions } from 'store/actions'
import { TextLink } from 'components/common/TextLink/TextLink'
import { TITLE_BUTTON_AUTH, TITLE_BUTTON_RECOVER_PASSWORD, TITLE_FIELD_EMAIL_LOGIN } from 'helpers/enums/titles'
import { useNavigate } from 'react-router-dom'
import { PATH_LOGIN } from 'helpers/enums/routePath'
import {
    FormLoginForgottenSuccess,
} from 'pages/login/LoginPage/components/FormLoginForgotPassword/components/FormLoginForgottenSuccess/FormLoginForgottenSuccess'
import { LoadingButton } from '@mui/lab'
import { useNotify } from 'store/hooks/useNotify'

const defaultValues = {
    email: '',
}

type FormValues = {
    email: string;
}

export const FormLoginForgotPassword: FC = () => {
    const navigate = useNavigate()
    const notify = useNotify()
    const [isRecoverSending, setIsRecoverSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({ defaultValues })

    useEffect(() => {
        return () => {
            dispatch(authActions.clearError())
        }
    }, [])

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            setLoading(true)
            void await dispatch(authActions.passwordRecoverFromEmail(data))
            setIsRecoverSending(true)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    if (isRecoverSending) return <FormLoginForgottenSuccess/>

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate width="-webkit-fill-available">
            <Stack spacing={4}>
                <Stack spacing={3}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            pattern: {
                                value: PATTERN_EMAIL,
                                message: HINT_ERROR_EMAIL,
                            },
                        }}
                        render={({ field }) => (
                            <InputForm
                                {...field}
                                id="email"
                                title={TITLE_FIELD_EMAIL_LOGIN}
                                autoComplete="username"
                                disabled={loading}
                                autoFocus
                                error={!!errors.email}
                                helperText={errors?.email ? errors.email.message : null}
                            />
                        )}
                    />
                    <Typography variant="body1">
                        {HINT_EMAIL_RECOVER}
                    </Typography>
                </Stack>
                <Stack spacing={3}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                    >
                        {TITLE_BUTTON_RECOVER_PASSWORD}
                    </LoadingButton>
                    <Box alignSelf="center">
                        <TextLink onCustomClickEvent={() => navigate(PATH_LOGIN)}>
                            {TITLE_BUTTON_AUTH}
                        </TextLink>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}
