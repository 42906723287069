import { getLengthRestriction } from 'helpers/getLengthRestriction'
import { HINT_ERROR_INCORRECT_DATE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { addDays, isFuture, isPast, subYears } from 'date-fns'

// Ограничения для полей инпутов
export const fieldsRestriction = {
    assessment: {
        assessment180: {
            comment: {
                maxLength: {
                    value: 500,
                    message: getLengthRestriction(1, 500),
                },
            },
            globalComment: {
                maxLength: {
                    value: 500,
                    message: getLengthRestriction(1, 500),
                },
            },
            default: {
                maxLength: {
                    value: 500,
                    message: getLengthRestriction(1, 500),
                },
            },
        },
    },
    specialization: {
        grade: {
            description: {
                maxLength: {
                    value: 600,
                    message: getLengthRestriction(1, 600),
                },
            },
            requirements: {
                maxLength: {
                    value: 600,
                    message: getLengthRestriction(1, 600),
                },
            },
            salary: {
                maxLength: {
                    value: 9,
                    message: getLengthRestriction(1, 9),
                },
            },
        },
    },
    competence: {
        material: {
            title: {
                maxLength: {
                    value: 300,
                    message: getLengthRestriction(1, 300),
                },
            },
            format: {
                maxLength: {
                    value: 50,
                    message: getLengthRestriction(1, 50),
                },
            },
            studyTime: {
                maxLength: {
                    value: 5,
                    message: getLengthRestriction(1, 5),
                },
            },
            link: {
                maxLength: {
                    value: 300,
                    message: getLengthRestriction(1, 300),
                },
            },
            comment: {
                maxLength: {
                    value: 350,
                    message: getLengthRestriction(1, 350),
                },
            },
        },
    },
    employees: {
        employee: {
            lastName: {
                maxLength: {
                    value: 256,
                    message: getLengthRestriction(1, 256),
                },
                required: HINT_ERROR_REQUIRED,
                validate: (match: any) => {
                    if (match.trim().length) return true
                    return HINT_ERROR_REQUIRED
                },
            },
            firstName: {
                maxLength: {
                    value: 256,
                    message: getLengthRestriction(1, 256),
                },
                required: HINT_ERROR_REQUIRED,
                validate: (match: any) => {
                    if (match.trim().length) return true
                    return HINT_ERROR_REQUIRED
                },
            },
            secondName: {
                maxLength: {
                    value: 256,
                    message: getLengthRestriction(1, 256),
                },
            },
            edit: {
                workExperience: {
                    previousCompany: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    previousPosition: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    previousProject: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    previousRole: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                },
                education: {
                    direction: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    educationalInstitution: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    level: {
                        required: HINT_ERROR_REQUIRED,
                    },
                    yearGraduation: {
                        required: HINT_ERROR_REQUIRED,
                    },
                },
                course: {
                    direction: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    educationalInstitution: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                        validate: (match: any) => {
                            if (match.trim().length) return true
                            return HINT_ERROR_REQUIRED
                        },
                    },
                    yearGraduation: {
                        required: HINT_ERROR_REQUIRED,
                    },
                },
            },
            pdp: {
                point: {
                    maxLength: {
                        value: 350,
                        message: getLengthRestriction(1, 350),
                    },
                    required: HINT_ERROR_REQUIRED,
                },
                endedAt: {
                    required: HINT_ERROR_REQUIRED,
                    validate: {
                        max: (date: any) => isPast(subYears(date, 2)) || HINT_ERROR_INCORRECT_DATE,
                        min: (date: any) => isFuture(addDays(date, 1)) || HINT_ERROR_INCORRECT_DATE,
                    },
                },
                comment: {
                    maxLength: {
                        value: 350,
                        message: getLengthRestriction(0, 350),
                    },
                    // required: HINT_ERROR_REQUIRED,
                },
                item: {
                    title: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                    },
                    description: {
                        maxLength: {
                            value: 350,
                            message: getLengthRestriction(1, 350),
                        },
                        required: HINT_ERROR_REQUIRED,
                    },
                    studyTime: {
                        required: HINT_ERROR_REQUIRED,
                    },
                },
                material: {
                    title: {
                        maxLength: {
                            value: 256,
                            message: getLengthRestriction(1, 256),
                        },
                        required: HINT_ERROR_REQUIRED,
                    },
                    comment: {
                        maxLength: {
                            value: 350,
                            message: getLengthRestriction(1, 350),
                        },
                    },
                    link: {
                        maxLength: {
                            value: 350,
                            message: getLengthRestriction(1, 350),
                        },
                    },
                },
            },
            assessment: {
                endedAt: {
                    required: HINT_ERROR_REQUIRED,
                    validate: {
                        min: (date: any) => isFuture(addDays(date, 1)) || HINT_ERROR_INCORRECT_DATE,
                        max: (date: any) => isPast(subYears(date, 100)) || HINT_ERROR_INCORRECT_DATE,
                    },
                },
            },
        },
    },
    cpa: {
        endedAt: {
            required: HINT_ERROR_REQUIRED,
            validate: {
                min: (date: any) => isFuture(addDays(date, 1)) || HINT_ERROR_INCORRECT_DATE,
                // max: (date: any) => isPast(subYears(date, 100)) || HINT_ERROR_INCORRECT_DATE,
            },
        },
        title: {
            maxLength: {
                value: 256,
                message: getLengthRestriction(1, 256),
            },
            required: HINT_ERROR_REQUIRED,
            validate: (match: any) => {
                if (match.trim().length) return true
                return HINT_ERROR_REQUIRED
            },
        },
        scale: {
            title: {
                maxLength: {
                    value: 256,
                    message: getLengthRestriction(1, 256),
                },
                required: HINT_ERROR_REQUIRED,
                validate: (match: any) => {
                    if (match.trim().length) return true
                    return HINT_ERROR_REQUIRED
                },
            },
            answerTitle: {
                maxLength: {
                    value: 256,
                    message: getLengthRestriction(1, 256),
                },
                required: HINT_ERROR_REQUIRED,
                validate: (match: any) => {
                    if (match.trim().length) return true
                    return HINT_ERROR_REQUIRED
                },
            },
            description: {
                maxLength: {
                    value: 300,
                    message: getLengthRestriction(1, 300),
                },
            },
        },
    },
    massExam: {
        endedAt: {
            required: HINT_ERROR_REQUIRED,
            validate: {
                min: (date: any) => isFuture(addDays(date, 1)) || HINT_ERROR_INCORRECT_DATE,
            },
        },
        title: {
            maxLength: {
                value: 256,
                message: getLengthRestriction(1, 256),
            },
            required: HINT_ERROR_REQUIRED,
            validate: (match: any) => {
                if (match.trim().length) return true
                return HINT_ERROR_REQUIRED
            },
        },
    },
}
