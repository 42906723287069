import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card, CardContent, Divider, Stack } from '@mui/material'
import {
    HistoryPdpHeader,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/HistoryPdpHeader/HistoryPdpHeader'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    HistoryPdpInfo,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/HistoryPdpInfo/HistoryPdpInfo'
import {
    HistoryPdpContent,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/HistoryPdpContent/HistoryPdpContent'
import {
    HistoryPdpItemViewPage,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/ HistoryPdpItemViewPage/ HistoryPdpItemViewPage'

type HistoryPdpProps = {
    pdp: IPdp;
    parentPath: string;
    employeeUuid: string;
}

export const HistoryPdp: FC<HistoryPdpProps> = ({ pdp, parentPath, employeeUuid }) => {
    const statusPdp = getPdpStatus(pdp.status)
    return (
        <Routes>
            <Route index element={
                <Card sx={{ width: '100%' }}>
                    <CardContent>
                        <Stack spacing={6}>
                            <HistoryPdpHeader pdp={pdp} pdpStatus={statusPdp} />
                            <HistoryPdpInfo pdp={pdp} pdpStatus={statusPdp} />
                            <Divider />
                            <HistoryPdpContent pdp={pdp} parentPath={parentPath} />
                        </Stack>
                    </CardContent>
                </Card>
            } />
            <Route path="item/:itemUuid/:entityType" element={<HistoryPdpItemViewPage
                employeeUuid={employeeUuid}
                parentPath={parentPath}
            />} />
        </Routes>
    )
}

