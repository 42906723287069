import React, { FC, ReactNode } from 'react'
import { ButtonProps } from '@mui/material/Button/Button'
import { Button } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

export const AddButton: FC<ButtonProps & { icon?: ReactNode }> = (({ icon, ...props}) => (
    <Button
        {...props}
        startIcon={icon || <AddRoundedIcon/>}
    />
))
