import { Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_FORMAT,
    TITLE_NAMESPACE_LINK,
    TITLE_NAMESPACE_PDP_LEARNED,
    TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_TITLE,
    TITLE_NAMESPACE_TYPE,
} from 'helpers/enums/titles'
import React, { FC, ReactNode } from 'react'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'

type PdpCompetenceMaterialsDesktopProps = {
    children: ReactNode
    isDevelopment?: boolean;
    employee: IEmployee;
    pdpStatus: IPdpStatus;
}

export const PdpCompetenceMaterialsDesktop: FC<PdpCompetenceMaterialsDesktopProps> = ({
    children,
    isDevelopment,
    employee,
    pdpStatus ,
}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <Authenticated
                            service={isDevelopment ? undefined : 'pdp'}
                            accessLevel="w"
                            divisions={[employee?.division?.uuid]}
                        >
                            <TableCell width="1%" padding="none"/>
                        </Authenticated>
                        {pdpStatus.status !== 'default' && pdpStatus.status !== 'approval' &&
                            <TableCell width="1%" align="center">{TITLE_NAMESPACE_PDP_LEARNED}</TableCell>}
                        <TableCell width="1%" align="left">{TITLE_NAMESPACE_TYPE}</TableCell>
                        <TableCell width="100%" align="left">{TITLE_NAMESPACE_TITLE}</TableCell>
                        <TableCell width="1%" align="center">{TITLE_NAMESPACE_COMMENT}</TableCell>
                        <TableCell width="1%" align="left">{TITLE_NAMESPACE_FORMAT}</TableCell>
                        <TableCell
                            sx={{ whiteSpace: 'nowrap' }}
                            align="left"
                            width="1%"
                        >
                            {TITLE_NAMESPACE_STUDY_TIME}
                        </TableCell>
                        <TableCell
                            sx={{ minWidth: 146 }}
                            align="left"
                            width="1%"
                        >
                            {TITLE_NAMESPACE_LINK}
                        </TableCell>
                        <Authenticated
                            service={isDevelopment ? undefined : 'pdp'}
                            accessLevel="w"
                            divisions={[employee?.division?.uuid]}
                        >
                            <TableCell width="1%" padding="checkbox" align="center"/>
                        </Authenticated>
                    </TableRow>
                </TableHead>
                {children}
            </Table>
        </TableContainer>
    )
}
