import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { AssignmentRounded, DateRangeRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPLETE, TITLE_NAMESPACE_ENDED_TO, TITLE_NAMESPACE_INITIATOR } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'
import { CpaButtonNotify } from 'pages/cpa/components/CpaButtonNotify/CpaButtonNotify'

export const CPAMetaInfo = () => {
    const meta = useAppSelector(state => state.CPAItemReducer.meta)
    const isExpire = useAppSelector(state => state.CPAItemReducer.isExpired)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const completeStatus = cpaStatusCode === 'complete'

    return (
        <Box flex={1}>
            <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <DateRangeRounded color="warning"/>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem/>}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: ({ palette, typography }) => isExpire && !completeStatus
                                        ? palette.warning.main
                                        : typography.body1.color,
                                }}
                            >
                                {TITLE_NAMESPACE_ENDED_TO} {meta?.endedAt}
                            </Typography>
                            <CpaButtonNotify
                                uuid={meta.uuid}
                                isExpire={isExpire}
                                cpaStatusCode={cpaStatusCode}
                            />
                        </Stack>
                        {completeStatus && <Typography variant="body1">
                            {TITLE_NAMESPACE_COMPLETE} {meta?.finishedAt}
                        </Typography>}
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <AssignmentRounded color="warning"/>
                    <Typography variant="body1">
                        {`${TITLE_NAMESPACE_INITIATOR} - ${meta?.author?.lastName} ${meta?.author?.firstName?.charAt(0)
                            .toUpperCase()}.`}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}
