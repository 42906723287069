import React from 'react'
import { Card, CardContent, Divider, Stack } from '@mui/material'
import { useCpaItem } from 'api/queries/cpa/cpaQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import {
    CPACriteriaHeader,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/CPACriteriaHeader'
import {
    CPACriteriaContent,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/CPACriteriaContent'
import { useAppSelector } from 'store/hooks/redux'
import { useParams } from 'react-router-dom'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { isEmptyUuid } from 'helpers/isEmptyUuid'

export const CPACriteria = () => {
    const { cpaUuid } = useParams()
    const { isLoading } = useCpaItem(cpaUuid || '')
    const itemMeta = useAppSelector(state => state.CPAItemReducer.meta)

    if (isLoading) return <LoadingComponent/>

    return (
        <Authenticated
            service="cpa"
            accessLevel={itemMeta?.criteria?.uuid && !isEmptyUuid(itemMeta?.criteria?.uuid) ? 'r' : 'wx'}
        >
            <Card>
                <CardContent>
                    <Stack spacing={3} divider={<Divider/>}>
                        <CPACriteriaHeader/>
                        <CPACriteriaContent criteriaUuid={itemMeta?.criteria?.uuid}/>
                    </Stack>
                </CardContent>
            </Card>
        </Authenticated>
    )
}
