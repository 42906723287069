import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import { DateRangeRounded, EventAvailableRounded } from '@mui/icons-material'
import {
    TITLE_BUTTON_ASSESSMENT_RESULTS,
    TITLE_BUTTON_PRELIMINARY_ASSESSMENT_RESULTS,
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_ENDED_TO,
} from 'helpers/enums/titles'
import { ChipAssessmentStatus } from 'components/chips/ChipAssessmentStatus/ChipAssessmentStatus'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { useAppSelector } from 'store/hooks/redux'

export const AssessmentResultHeaderMobile = () => {
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)

    return (
        <Stack spacing={4} alignItems="flex-start" sx={{ overflowWrap: 'anywhere' }}>
            <Stack spacing={2} alignItems="flex-start">
                <Typography variant="h1">
                    {result?.title}
                </Typography>
                <ChipAssessmentType cpaType={result?.cpa?.typeUuid || result?.typeUuid}/>
                <Stack spacing={1}>
                    {resultSetup?.isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                        <DateRangeRounded color={resultSetup?.isExpire ? 'warning' : 'action'}/>
                        <Typography
                            variant="body1"
                            sx={{
                                color: ({ palette, typography }) => resultSetup?.isExpire
                                    ? palette.warning.main
                                    : typography.body1.color,
                            }}
                        >
                            {TITLE_NAMESPACE_ENDED_TO} {result?.endedAt}
                        </Typography>
                    </Stack>}
                    {resultSetup?.isValidFinishedAt && resultSetup?.completeStatus
                        && <Stack spacing={1} direction="row" alignItems="center">
                            <EventAvailableRounded color="action"/>
                            <Typography variant="body1">
                                {TITLE_NAMESPACE_COMPLETE} {result?.finishedAt}
                            </Typography>
                        </Stack>}
                </Stack>
                <ChipAssessmentStatus status={result?.statusUuid}/>
            </Stack>
            <Stack spacing={2}>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {resultSetup?.evaluatedFullName}
                    </Typography>
                    <Typography variant="body1">
                        {result?.evaluated?.currentPosition?.title}
                    </Typography>
                </Stack>
                {resultSetup?.canSeeCriteria && <Box>
                    <PointPositionSpecializationGrade title={resultSetup?.positionTitle}/>
                </Box>}
            </Stack>
            <Divider flexItem sx={{ alignSelf: 'stretch' }}/>
            <Typography variant="h2">
                {resultSetup?.completeStatus
                    ? TITLE_BUTTON_ASSESSMENT_RESULTS
                    : TITLE_BUTTON_PRELIMINARY_ASSESSMENT_RESULTS}
            </Typography>
        </Stack>
    )
}
