import React, { FC, useMemo } from 'react'
import { eventCompleteExam, eventNotCompleteExam, getHistoryEventTypes } from 'helpers/historyEventTypes'
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { PersonRounded } from '@mui/icons-material'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { useAppSelector } from 'store/hooks/redux'
import { PATH_DEVELOPMENT, PATH_EMPLOYEES, PATH_EXAM, PATH_MASS_EXAM } from 'helpers/enums/routePath'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { ExamResultChipForHistory } from 'components/badges/ExamResultChipForHistory/ExamResultChipForHistory'

type EmployeeTabHistoryListItemProps = {
    item: IEmployeeHistory;
    parentPath: string;
}

export const EmployeeTabHistoryListItem: FC<EmployeeTabHistoryListItemProps> = ({ item, parentPath }) => {
    const navigate = useNavigate()
    const { employeeUuid } = useParams()
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const { employee } = useAppSelector(state => state.employeeReducer.data)

    const currentType = getHistoryEventTypes(item.eventUuid)
    const date = item.createdAt?.split(' ')?.[0]
    const time = item.createdAt?.split(' ')?.[1]
    let currentParentPath = parentPath

    if (currentType?.type === 'developmentPlan' && currentType?.eventUuid) {
        const pdpStatus = getPdpStatus({ uuid: item.meta, title: '' })
        if (pdpStatus?.status !== 'completed' && pdpStatus?.status !== 'canceled') {
            const isEmployeePage = !!employee?.uuid
            const selfViewer = isEmployeePage && uuid === employee?.uuid || !isEmployeePage

            switch (true) {
                case isEmployeePage && selfViewer || selfViewer: {
                    currentParentPath = PATH_DEVELOPMENT
                    break
                }
                case isEmployeePage && !selfViewer: {
                    currentParentPath = `${PATH_EMPLOYEES}/${employee?.uuid}`
                    break
                }
                default: {
                    currentParentPath = parentPath
                    break
                }
            }
        }
    }

    const itemLink = useMemo(() => {
        if (!currentType?.link.length) return ''
        if (currentType?.type !== 'exam') return `${currentParentPath}/${currentType.link}/${item.entityUuid}`
        if (employeeUuid?.length) {
            return currentType.eventUuid === eventNotCompleteExam ?
                `${currentParentPath}${PATH_MASS_EXAM}/${item.meta}` :
                `${currentParentPath}${PATH_MASS_EXAM}/${item.meta}/employee/${employeeUuid}/exam/${item.entityUuid}/result`
        }

        return currentType.eventUuid === eventNotCompleteExam ?
            PATH_EXAM :
            `${currentParentPath}/${currentType.link}/${item.entityUuid}/result`
    }, [item])

    if (!currentType) return null

    return (
        <Box display="flex" alignItems="center">
            <Box mr={8}>
                <Stack spacing={0.5}>
                    {date && <Typography variant="body2">
                        {date}
                    </Typography>}
                    {time && <Typography variant="body1">
                        {time}
                    </Typography>}
                </Stack>
            </Box>
            <Box mr={4}>
                {React.createElement(currentType.icon, { color: 'primary' })}
            </Box>
            <Box mr={2}>
                <Typography variant="body2">
                    {item.eventTitle}
                </Typography>
            </Box>
            {item.description && currentType?.description &&
                <Box mr={1}>
                    {React.createElement(currentType.description, { title: item.description })}
                </Box>
            }
            {currentType.type === 'exam' ?
                <>
                    {currentType.eventUuid === eventCompleteExam &&
                        <Box mr={2}>
                            <ExamResultChipForHistory
                                percent={item.sum}
                                result={item.subDescription}
                            />
                        </Box>
                    }
                </>
                :
                <>
                    {item.subDescription && currentType?.subDescription &&
                        <Box mr={2}>
                            {React.createElement(currentType.subDescription, { title: item.subDescription })}
                        </Box>
                    }
                </>
            }

            {item.sum > 0 && currentType?.sum &&
                <Box mr={2}>
                    {React.createElement(currentType.sum, { sum: item.sum })}
                </Box>
            }
            {currentType.link.length > 0 ?
                <Box>
                    <IconButton color='primary' onClick={() => navigate(itemLink)}>
                        <ChevronRightRoundedIcon />
                    </IconButton>
                </Box>
                :
                null
            }
            {item?.author?.lastName &&
                <Box display="flex" flex={1} justifyContent="flex-end">
                    <Chip
                        label={`${item.author.lastName} ${item.author.firstName.charAt(0).toUpperCase()}`}
                        icon={<PersonRounded/>}
                        variant="outlined"
                        color="default"
                        sx={{
                            color: (theme) => theme.palette.text.disabled,
                            '& .MuiChip-icon': {
                                color: (theme) => theme.palette.text.disabled,
                            },
                        }}
                    />
                </Box>
            }
        </Box>
    )
}
