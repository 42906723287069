import { Box, Divider, Drawer, Stack, Typography, useTheme } from '@mui/material'
import { Puller } from '../../common/Puller/Puller'
import React, { FC, ReactElement } from 'react'
import { convertUtcStringToLocalString } from '../../../helpers/dateAndTime/convertUtcStringToLocalString'
import { TITLE_NAMESPACE_IN } from 'helpers/enums/titles'
import { AccountCircleRounded, SchoolRounded } from '@mui/icons-material'

type BadgeChangeLogDrawerProps = {
    handleClose: () => void;
    changeLog: IChangeLog;
    getChangeLogTitle: ({ eventType }: Pick<IChangeLog, 'eventType'>) => string;
    open: boolean;
    chipDateTime?: boolean;
    isEvaluatedAuthor: boolean;
    title?: string | ReactElement;
    subTitle?: string | ReactElement;
}

export const BadgeChangeLogDrawer: FC<BadgeChangeLogDrawerProps> = ({
    open,
    handleClose,
    getChangeLogTitle,
    changeLog,
    title,
    subTitle,
    isEvaluatedAuthor,
    chipDateTime,
}) => {
    const theme = useTheme()

    return(
        <Drawer
            anchor='bottom'
            open={open}
            onClose={handleClose}
            sx={{ '& .MuiPaper-root': { borderRadius: '16px 16px 0 0' }}}
        >
            <Box
                sx={{
                    p: 3,
                    borderRadius: '16px 16px 0 0',
                    height: 'fit-content',
                }}
            >
                <Box
                    display='flex'
                    justifyContent='center'
                    mb={4}
                >
                    <Puller />
                </Box>
                <Box>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Box>
                            <Typography variant='body2' mb={0.5}>{title}</Typography>
                            <Typography variant='body1'>{subTitle}</Typography>
                        </Box>
                        {!chipDateTime &&
                            <>
                                {isEvaluatedAuthor ?
                                    <SchoolRounded color="warning"/>
                                    :
                                    <AccountCircleRounded color="warning"/>
                                }
                            </>
                        }
                    </Stack>
                    <Divider sx={{ my: 2 }}/>
                    <Stack spacing={1}>
                        <Typography variant="caption" color={theme.palette.warning.main}>
                            {getChangeLogTitle({ eventType: changeLog.eventType })}
                        </Typography>
                        <Typography variant="body1">
                            {convertUtcStringToLocalString(changeLog.date).split(' ').join(` ${TITLE_NAMESPACE_IN.toLowerCase()} `)}
                        </Typography>
                        <Typography variant="body1">
                            {changeLog.author}
                        </Typography>
                    </Stack>
                </Box>
            </Box>

        </Drawer>
    )
}
