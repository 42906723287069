import {
    IEmployeePdpCompetenceMaterialCreateRequest,
    IEmployeePdpGetPlanItemRequest,
    IPdpChangeOrderItemRequest,
    IPdpChangePassItemRequest,
    IPdpChangePassMaterialRequest,
    IPdpChangeRequest,
    IPdpChangeStatusRequest,
    IPdpCommentCreateRequest,
    IPdpCommentDeleteRequest,
    IPdpCommentUpdateRequest,
    IPdpCompetenceMaterialChangeOrderRequest,
    IPdpCompetenceMaterialDeleteRequest,
    IPdpCompetenceMaterialsSetupRequest,
    IPdpCompetenceMaterialsSetupResponse,
    IPdpCompetenceMaterialUpdateRequest,
    IPdpCreateRequest,
    IPdpDeleteItemRequest,
    IPdpGetOrDeleteRequest,
    IPdpItemCommentCreateRequest,
    IPdpItemCommentDeleteRequest,
    IPdpItemCommentUpdateRequest,
    IPdpItemMaterialCommentCreateRequest,
    IPdpItemMaterialCommentDeleteRequest,
    IPdpItemMaterialCommentUpdateRequest,
    IPdpItemUpdateRequest,
    IPdpProgressRequest,
    IPdpSetupCompetenceItemsRequest,
    IPdpSetupCompetenceItemsResponse,
    IPdpUniqueItemCreateRequest,
    IPdpUpdateRequest,
} from 'api/typing/employeeTypes'
import { TransferPdp } from 'api/services/PdpService/transferPdp'

export class PdpEmployeeService extends TransferPdp {
    private prefix = 'employee'

    public sendPdp(data: IPdpChangeStatusRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/status/send`
        return this.request<IPdpChangeStatusRequest, { status: IEntity }>('POST', path, { data })
    }

    public rollbackPdp(data: IPdpChangeStatusRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/status/rollback`
        return this.request<IPdpChangeStatusRequest, { status: IEntity; changeLog: IChangeLog | null }>('POST', path, { data })
    }

    public donePdp(data: IPdpChangeStatusRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/status/done`
        return this.request<IPdpChangeStatusRequest, { status: IEntity }>('POST', path, { data })
    }

    public cancelPdp(data: IPdpChangeStatusRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/status/cancel`
        return this.request<IPdpChangeStatusRequest, { status: IEntity }>('POST', path, { data })
    }

    // PDP
    public getPdp(data: IPdpGetOrDeleteRequest) {
        const path = `/${data.pdpUuid}/${this.prefix}/${data.employeeUuid}`
        return this.request<null, IPdp>('GET', path)
    }

    public createPdp(data: IPdpCreateRequest) {
        const path = `/${this.prefix}/${data.employeeUuid}/new`
        return this.request<IPdpCreateRequest, IPdp>('POST', path, { data })
    }

    public changePdp(data: IPdpChangeRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}`
        return this.request<IPdpChangeRequest, IPdp>('POST', path, { data })
    }

    public updatePdp(data: IPdpUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}`
        return this.request<IPdpUpdateRequest, { status: IEntity }>('PUT', path, { data })
    }

    public deletePdp(data: IPdpGetOrDeleteRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}`
        return this.request<IPdpGetOrDeleteRequest, null>('DELETE', path, { data })
    }

    // PDP items
    public createUniqueItemPdp(data: IPdpUniqueItemCreateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/create/one`
        return this.request<IPdpUniqueItemCreateRequest, IPdpProgressRequest & { uuid: string }>('POST', path, { data })
    }

    public updateUniqueItemPdp(data: IPdpItemUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.uuid}`
        return this.request<IPdpItemUpdateRequest, IPdpProgressRequest>('PUT', path, { data })
    }

    // comments for pdp start
    public createPdpComment(data: IPdpCommentCreateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/comment`
        return this.request<IPdpCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpComment(data: IPdpCommentUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/comment/${data.uuid}`
        return this.request<IPdpCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpComment(data: IPdpCommentDeleteRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/comment/${data.uuid}`
        return this.request<IPdpCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // comments for pdp item start
    public createPdpItemComment(data: IPdpItemCommentCreateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/comment`
        return this.request<IPdpItemCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpItemComment(data: IPdpItemCommentUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/comment/${data.uuid}`
        return this.request<IPdpItemCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpItemComment(data: IPdpItemCommentDeleteRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/comment/${data.uuid}`
        return this.request<IPdpItemCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // comments for pdp item start
    public createPdpItemMaterialComment(data: IPdpItemMaterialCommentCreateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment`
        return this.request<IPdpItemMaterialCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpItemMaterialComment(data: IPdpItemMaterialCommentUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment/${data.uuid}`
        return this.request<IPdpItemMaterialCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpItemMaterialComment(data: IPdpItemMaterialCommentDeleteRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment/${data.uuid}`
        return this.request<IPdpItemMaterialCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // comments
    // public updateCommentPdp(data: IPdpCommentUpdateRequest) {
    //     const path = `${data.uuid}/${this.prefix}/${data.employeeUuid}/comment`
    //     return this.request<IPdpCommentUpdateRequest, null>('PUT', path, { data })
    // }
    //
    // public updateCommentItemPdp(data: IPdpCommentItemUpdateRequest) {
    //     const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.uuid}/comment`
    //     return this.request<IPdpCommentItemUpdateRequest, null>('PUT', path, { data })
    // }
    //
    // public updateItemMaterialComments(data: IPdpCommentItemMaterialUpdateRequest) {
    //     const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.uuid}/comment`
    //     return this.request<IPdpCommentItemMaterialUpdateRequest, null>('PUT', path, { data })
    // }

    public setupCompetenceItemsPdp(data: IPdpSetupCompetenceItemsRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/setup`
        return this.request<IPdpSetupCompetenceItemsRequest, IPdpSetupCompetenceItemsResponse>('POST', path, { data })
    }

    public deleteItemPdp(data: IPdpDeleteItemRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}`
        return this.request<IPdpDeleteItemRequest, IPdpProgressRequest>('DELETE', path, { data })
    }

    public changeOrderItemPdp(data: IPdpChangeOrderItemRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/order`
        return this.request<IPdpChangeOrderItemRequest, null>('POST', path, { data })
    }

    public createPdpCompetenceMaterial(data: IEmployeePdpCompetenceMaterialCreateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/new`
        return this.request<IEmployeePdpCompetenceMaterialCreateRequest, { uuid: string } & IPdpProgressRequest>('POST', path, { data })
    }

    public updatePdpCompetenceMaterial(data: IPdpCompetenceMaterialUpdateRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.uuid}`
        return this.request<IPdpCompetenceMaterialUpdateRequest, IPdpProgressRequest>('PUT', path, { data })
    }

    public deletePdpCompetenceMaterial(data: IPdpCompetenceMaterialDeleteRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.uuid}`
        return this.request<IPdpCompetenceMaterialDeleteRequest, IPdpProgressRequest>('DELETE', path, { data })
    }

    public changeOrderPdpCompetenceMaterial(data: IPdpCompetenceMaterialChangeOrderRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/order`
        return this.request<IPdpCompetenceMaterialChangeOrderRequest, null>('POST', path, { data })
    }

    public setupPdpCompetenceMaterials(data: IPdpCompetenceMaterialsSetupRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/setup`
        return this.request<IPdpCompetenceMaterialsSetupRequest, IPdpCompetenceMaterialsSetupResponse>('POST', path, { data })
    }

    public changePassCompetenceMaterial(data: IPdpChangePassMaterialRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/material/${data.materialUuid}/pass`
        return this.request<IPdpChangePassMaterialRequest, IPdpProgressRequest>('POST', path, { data })
    }

    public changePassItem(data: IPdpChangePassItemRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}/pass`
        return this.request<IPdpChangePassItemRequest, IPdpProgressRequest>('POST', path, { data })
    }

    // view unique plan item
    public getPdpPlanItem(data: IEmployeePdpGetPlanItemRequest) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/item/${data.itemUuid}`
        return this.request<null, IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>>('GET', path)
    }

    // history
    public getHistoryPdp(data: { employeeUuid: string, pdpUuid: string }) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/history`
        return this.request<null, IPdp>('GET', path)
    }

    public getHistoryPdpItem(data: { employeeUuid: string, pdpUuid: string, itemUuid: string }) {
        const path = `${data.pdpUuid}/${this.prefix}/${data.employeeUuid}/history/item/${data.itemUuid}`
        return this.request<null, IPdpItem & Pick<IPdp, 'employee' | 'reviewer'>>('GET', path)
    }
}
