import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { TITLE_NAMESPACE_ASSESSMENT_COMPLETE } from 'helpers/enums/titles'
import { Box, Chip, LinearProgress, Snackbar, useMediaQuery, useTheme } from '@mui/material'
import { PauseRounded } from '@mui/icons-material'

export const MyAssessmentPassProgress = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const indicators = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators)
    const uncompletedIndicators = useAppSelector(state => state.MyAssessmentPassReducer.data.uncompletedIndicatorUuids)
    const completeCount = Object.keys(indicators)?.length - uncompletedIndicators.length
    const counter = `${completeCount}/${assessment?.indicatorsCount}`

    const chipLabel = `${TITLE_NAMESPACE_ASSESSMENT_COMPLETE} ${counter}`

    if (!assessmentSetup?.showProgress || !assessment) return null

    const percentageComplete = completeCount / assessment.indicatorsCount * 100

    if (mobile) return (
        <Box position="fixed" top={25} left={0} width="100vw">
            <LinearProgress
                variant="determinate"
                value={percentageComplete}
            />
        </Box>
    )

    return (
        <>
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ zIndex: 1199 }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Chip color="secondary" label={chipLabel}/>
                    {assessmentSetup?.isPaused && <Chip
                        icon={<PauseRounded/>}
                        color="error"
                        label="Оценка остановлена"
                        sx={{ mt: 1 }}
                    />}
                </Box>
            </Snackbar>
        </>
    )
}
