import React, { FC, useState } from 'react'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { employeeActions } from 'store/actions'
import {
    IEmployeeEditEducationEducationCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { Box, Chip, Divider, IconButton, Typography } from '@mui/material'
import { ClearRounded, EditRounded } from '@mui/icons-material'
import {
    EducationItemForm,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabEducation/components/EducationCard/components/EducationItemForm/EducationItemForm'
import { HINT_RECORD_SUCCESS_DELETE, HINT_RECORD_SUCCESS_EDIT } from 'helpers/enums/hints'

type EducationItemProps = {
    education: IEmployeeEditEducationEducation;
    pageViewEmployee?: boolean;
    employeeUuid: string;
}

export const EducationItem: FC<EducationItemProps> = ({ education, pageViewEmployee, employeeUuid }) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const [edit, setEdit] = useState(false)

    const handleEdit = () => {
        setEdit(true)
    }

    const handleDelete = async () => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.deleteEducation(employeeUuid, education.uuid))
            notify(HINT_RECORD_SUCCESS_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    const editComplete = async (payload: IEmployeeEditEducationEducationCreateOrUpdate) => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.updateEducation(employeeUuid, { ...payload, employeeUuid }))
            setEdit(false)
            notify(HINT_RECORD_SUCCESS_EDIT, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            {edit
                ? <EducationItemForm
                    onCancelForm={() => setEdit(false)}
                    onSubmitForm={editComplete}
                    itemData={education}
                />
                : <>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Box overflow="hidden">
                            <Box display="flex" alignItems="center" mb={0.5}>
                                <Typography variant="body2" mr={1}>
                                    {education?.direction}
                                </Typography>
                                <Chip
                                    label={education?.level?.title}
                                    size="small"
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1">
                                    {education?.educationalInstitution} | {education?.yearGraduation}
                                </Typography>
                            </Box>
                        </Box>
                        {!pageViewEmployee && <Box display="flex" flexWrap="nowrap" alignSelf="center">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditRounded />
                            </IconButton>
                            <IconButton color="primary" onClick={handleDelete}>
                                <ClearRounded />
                            </IconButton>
                        </Box>}
                    </Box>
                    {!pageViewEmployee && <Divider sx={{ mt: 2 }} />}
                </>}
        </>
    )
}

