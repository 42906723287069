import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CpaStatusCode, cpaStatusCreated, cpaStatusReady, getCpaStatus } from 'helpers/cpa/cpaStatuses'
import { addDays, format, isPast, parse } from 'date-fns'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import {
    ICPAChangeStatusRequest,
    ICpaSaveEmployeesToCpaRequest,
    ICPASetScaleRequest,
    ICPAUpdateRequest,
} from 'api/typing/cpaTypes'
import { CpaTypeCode, getCpaType } from 'helpers/cpa/cpaTypes'
import { isEmptyUuid } from 'helpers/isEmptyUuid'


interface ICPAItemState {
    meta: ICpaItemMeta;
    cpaStatusCode: CpaStatusCode | null;
    cpaTypeCode: CpaTypeCode | null;
    statusState: ICpaStatusState | null;
    isExpired: boolean;
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAItemState = {
    meta: {} as ICpaItemMeta,
    isExpired: false,
    cpaTypeCode: null,
    cpaStatusCode: null,
    statusState: null,
    isLoading: false,
    error: null,
}

export const CPAItemSlice = createSlice({
    name: 'CPAItem',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setCpaMeta: (state, action: PayloadAction<ICpaItemMeta>) => {
            state.meta = action.payload
            state.cpaStatusCode = getCpaStatus(action.payload.statusUuid)
            state.cpaTypeCode = getCpaType(action.payload.typeUuid)
            state.isExpired = isValidDateFromApi(action.payload.endedAt) && isPast(addDays(parse(action.payload.endedAt, 'dd.MM.yyyy', new Date()), 1))
            if (state.cpaStatusCode === 'created' || state.cpaStatusCode === 'ready' && !!state.meta) {
                const uuidStatus = state.meta.employeesCount
                && !isEmptyUuid(state.meta?.criteria?.uuid)
                && !isEmptyUuid(state.meta.scale?.uuid)
                    ? cpaStatusReady
                    : cpaStatusCreated
                state.meta = {
                    ...state.meta,
                    statusUuid: uuidStatus,
                }
                state.cpaStatusCode = getCpaStatus(uuidStatus)
            }
        },

        setCpaCriteriaUuid: (state, action: PayloadAction<IEntity>) => {
            state.meta.criteria = action.payload
            if (state.cpaStatusCode === 'created' || state.cpaStatusCode === 'ready') {
                const uuidStatus = state.meta.employeesCount
                && !isEmptyUuid(state.meta?.criteria?.uuid)
                && !isEmptyUuid(state.meta.scale?.uuid)
                    ? cpaStatusReady
                    : cpaStatusCreated
                state.meta.statusUuid = uuidStatus
                state.cpaStatusCode = getCpaStatus(uuidStatus)
            }
        },

        updateItem: (state, action: PayloadAction<ICPAUpdateRequest>) => {
            state.meta = {
                ...state.meta,
                ...action.payload,
            }
            state.cpaTypeCode = getCpaType(action.payload.typeUuid)
            state.isExpired = isValidDateFromApi(action.payload.endedAt) && isPast(addDays(parse(action.payload.endedAt, 'dd.MM.yyyy', new Date()), 1))
        },

        setStatusState: (state, action: PayloadAction<ICpaStatusState>) => {
            state.statusState = action.payload
        },

        changeStatusCpa: (state, action: PayloadAction<ICPAChangeStatusRequest & { author?: ICpaParticipant }>) => {
            state.meta.statusUuid = action.payload.cpaStatusUuid
            state.cpaStatusCode = getCpaStatus(action.payload.cpaStatusUuid)
            state.meta.author = action.payload.author ?? state.meta.author
        },

        startCpa: (state) => {
            state.meta.startedAt = format(new Date(), 'dd.MM.yyyy')
        },

        updateEmployees: (state, action: PayloadAction<ICpaSaveEmployeesToCpaRequest>) => {
            state.meta.employeesCount = action.payload.employees.length
            if (state.cpaStatusCode === 'created' || state.cpaStatusCode === 'ready') {
                const uuidStatus = state.meta.employeesCount
                && !isEmptyUuid(state.meta?.criteria?.uuid)
                && !isEmptyUuid(state.meta.scale?.uuid)
                    ? cpaStatusReady
                    : cpaStatusCreated
                state.meta.statusUuid = uuidStatus
                state.cpaStatusCode = getCpaStatus(uuidStatus)
            }
        },
        deleteEmployee: (state) => {
            state.meta.employeesCount -= 1
            if (state.cpaStatusCode === 'created' || state.cpaStatusCode === 'ready') {
                const uuidStatus = state.meta.employeesCount
                && !isEmptyUuid(state.meta?.criteria?.uuid)
                && !isEmptyUuid(state.meta.scale?.uuid)
                    ? cpaStatusReady
                    : cpaStatusCreated
                state.meta.statusUuid = uuidStatus
                state.cpaStatusCode = getCpaStatus(uuidStatus)
            }
        },
        updateScaleUuid: (state, action: PayloadAction<ICPASetScaleRequest>) => {
            state.meta.scale.uuid = action.payload.scaleUuid
            if (state.cpaStatusCode === 'created' || state.cpaStatusCode === 'ready') {
                const uuidStatus = state.meta.employeesCount
                && !isEmptyUuid(state.meta?.criteria?.uuid)
                && !isEmptyUuid(state.meta.scale?.uuid)
                    ? cpaStatusReady
                    : cpaStatusCreated
                state.meta.statusUuid = uuidStatus
                state.cpaStatusCode = getCpaStatus(uuidStatus)
            }
        },
    },
})

export const CPAItemReducer = CPAItemSlice.reducer
