import { AppDispatch } from 'store/store'
import axios from 'axios'
import { authSlice } from 'store/reducers/AuthSlice'
import { AuthService } from 'api/services/AuthService'
import Cookies from 'universal-cookie'
import {
    IHashVerify,
    ILoginRequest,
    IPasswordRecoverFromEmail,
    IPasswordRestore,
    ISendInvite,
} from 'api/typing/authServiceTypes'
import { userSlice } from 'store/reducers/UserSlice'
import { companySlice } from 'store/reducers/CompanySlice'

const cookies = new Cookies()

export class AuthAction {
    private static get authApi() {
        return new AuthService(axios)
    }

    public clearError() {
        return (dispatch: AppDispatch) => {
            dispatch(authSlice.actions.authClearError())
        }
    }

    public login(payload: ILoginRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(authSlice.actions.authPending())
                const data = await AuthAction.authApi.login(payload)
                const secure = document?.location?.protocol === 'https:'
                const sameSite = secure ? 'none' : 'lax'
                cookies.set('accessToken', data.accessToken, { path: '/', sameSite, secure })
                cookies.set('refreshToken', data.refreshToken, { path: '/', sameSite, secure })
                cookies.set('companyUUID', data.companyUUID, { path: '/', sameSite, secure })
                dispatch(authSlice.actions.authSuccess(true))
            } catch (e: any) {
                dispatch(authSlice.actions.authError(e))
                throw e
            }
        }
    }

    public sendInvite(payload: ISendInvite) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(authSlice.actions.authPending())
                await AuthAction.authApi.sendInvite(payload)
                dispatch(authSlice.actions.authFulfilled())
            } catch (e: any) {
                dispatch(authSlice.actions.authError(e))
                throw e
            }
        }
    }

    public verifyHash(payload: IHashVerify) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(authSlice.actions.authPending())
                await AuthAction.authApi.verifyHash(payload)
                dispatch(authSlice.actions.authVerifyHash(true))
            } catch (e: any) {
                dispatch(authSlice.actions.authError(e))
                dispatch(authSlice.actions.authVerifyHash(false))
                throw e
            }
        }
    }

    public passwordRecoverFromEmail(payload: IPasswordRecoverFromEmail) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(authSlice.actions.authPending())
                await AuthAction.authApi.passwordRecoverFromEmail(payload)
                dispatch(authSlice.actions.authFulfilled())
            } catch (e: any) {
                dispatch(authSlice.actions.authError(e))
                throw e
            }
        }
    }

    public passwordRestore(payload: IPasswordRestore) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(authSlice.actions.authPending())
                const data = await AuthAction.authApi.passwordRestore(payload)
                const secure = document?.location?.protocol === 'https:'
                const sameSite = secure ? 'none' : 'lax'
                cookies.set('accessToken', data.accessToken, { path: '/', sameSite, secure })
                cookies.set('refreshToken', data.refreshToken, { path: '/', sameSite, secure })
                cookies.set('companyUUID', data.companyUUID, { path: '/', sameSite, secure })
                dispatch(authSlice.actions.authSuccess(true))
            } catch (e: any) {
                dispatch(authSlice.actions.authError(e))
                throw e
            }
        }
    }

    public logout() {
        return (dispatch: AppDispatch) => {
            this.removeCookies()
            dispatch(authSlice.actions.authSuccess(false))
            dispatch(userSlice.actions.clearState())
            dispatch(companySlice.actions.clearState())
        }
    }

    // why it doesnt work?
    private setCookies(access: string, refresh: string, company: string) {
        cookies.set('accessToken', access, { path: '/', sameSite: 'none', secure: true })
        cookies.set('refreshToken', refresh, { path: '/', sameSite: 'none', secure: true })
        cookies.set('companyUUID', company, { path: '/', sameSite: 'none', secure: true })
    }

    private removeCookies() {
        cookies.remove('accessToken', { path: '/', sameSite: 'none', secure: true })
        cookies.remove('refreshToken', { path: '/', sameSite: 'none', secure: true })
        cookies.remove('companyUUID', { path: '/', sameSite: 'none', secure: true })
    }
}
