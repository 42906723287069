import React, { useEffect } from 'react'
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TITLE_BUTTON_BACK_TO_ASSESSMENTS } from 'helpers/enums/titles'
import { PATH_CPA } from 'helpers/enums/routePath'
import { useNavigate, useParams } from 'react-router-dom'
import { CPAMeta } from 'pages/cpa/CPAPage/components/CPAMeta/CPAMeta'
import { CPACriteria } from 'pages/cpa/CPAPage/components/CPACriteria/CPACriteria'
import { CPAScale } from 'pages/cpa/CPAPage/components/CPAScale/CPAScale'
import { CPAEmployees } from 'pages/cpa/CPAPage/components/CPAEmployees/CPAEmployees'
import { useCpaItem } from 'api/queries/cpa/cpaQueries'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { CPAAnalytic } from 'pages/cpa/CPAPage/components/CPAAnalytic/CPAAnalytic'
import { appAction } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'

export const CPAPage = () => {
    const { cpaUuid } = useParams()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const gtLaptop = useMediaQuery(theme.breakpoints.up('laptop'))
    const { error } = useCpaItem(cpaUuid || '', false)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(appAction.setBackButtonPath(PATH_CPA))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [])

    if (error) return <ErrorPage error={error}/>

    return (
        <Box sx={{
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }}>
            <Stack spacing={4} alignSelf="stretch" flex={1}>
                {gtLaptop && <Box>
                    <Button
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={() => navigate(PATH_CPA, { replace: true })}
                    >
                        {TITLE_BUTTON_BACK_TO_ASSESSMENTS}
                    </Button>
                </Box>}
                <CPAMeta/>
                <CPACriteria/>
                <CPAScale/>
                <CPAAnalytic/>
                <CPAEmployees/>
            </Stack>
        </Box>
    )
}
