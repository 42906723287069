import React, { FC, useCallback, useEffect } from 'react'
import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_CPA_SCALES } from 'helpers/enums/titles'
import { CloseRounded } from '@mui/icons-material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useFormCpaScale } from 'api/queries/form/formQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import {
    CPAScaleDrawerContent,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/CPAScaleDrawerContent'
import {
    CPAScaleDrawerActions,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerActions/CPAScaleDrawerActions'
import { useParams } from 'react-router-dom'
import { useCpaDictionaryScaleNumericWithState, useCpaScaleNumeric } from 'api/queries/cpa/cpaQueries'
import { useAppSelector } from 'store/hooks/redux'

type CPAScaleDrawerProps = {
    open: boolean;
    handleClose: () => void;
}

export const CPAScaleDrawer: FC<CPAScaleDrawerProps> = ({ open, handleClose }) => {
    const { isLoading, error } = useFormCpaScale(open)
    const { cpaUuid } = useParams()
    const cpaStatus = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const currentScaleUuid = useAppSelector(state => state.CPAItemScaleReducer.currentScaleUuid)
    const cpaStatusCreated = cpaStatus === 'created' || cpaStatus === 'ready'
    const { refetch: refetchCreated } = useCpaDictionaryScaleNumericWithState(currentScaleUuid, false)
    const { refetch: refetchStarted } = useCpaScaleNumeric(cpaUuid || '', false)

    const refetchScale = useCallback(
        () => {
            cpaStatusCreated ? refetchCreated() : refetchStarted()
        },
        [cpaStatusCreated, currentScaleUuid],
    )

    useEffect(() => {
        return () => {
            refetchScale()
        }
    }, [])

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 620 },
                },
            }}
            // ModalProps={{ keepMounted: true }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box display="flex" flexDirection="column" px={5} pt={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_CPA_SCALES}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>
                    </Box>
                </Box>
                <Stack spacing={3} flex={1} overflow="auto" px={5} pb={5}>
                    <Divider/>
                    <>
                        {isLoading && <LoadingComponent/>}
                        {error && !isLoading && <ErrorPage error={error}/>}
                        {!error && !isLoading && <CPAScaleDrawerContent/>}
                    </>
                </Stack>
                <Box px={5} pb={5}>
                    <CPAScaleDrawerActions handleClose={handleClose}/>
                </Box>
            </Box>
        </Drawer>
    )
}
