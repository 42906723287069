import { useQuery } from 'react-query'
import { useAppDispatch } from 'store/hooks/redux'
import { DevelopmentService } from 'api/services/DevelopmentService'
import { assessmentActions, myAssessmentActions } from 'store/actions'
import { AssessmentService } from 'api/services/AssessmentService'
import { EmployeeService } from '../../services/EmployeeService'

const developmentApi = new DevelopmentService().developmentAssessmentService()
const assessmentApi = new AssessmentService().assessmentService()
const employeeApi = new EmployeeService().employeeViewService()

const getAssessmentOptionsFn = async (employeeUuid: string) => await assessmentApi.getAssessmentOptions(employeeUuid)
const getMyAssessmentListFn = async () => await developmentApi.getAssessmentList()
export const getAssessmentMetaFn = async (uuid: string) => await assessmentApi.assessmentMetaGet(uuid)
export const getAssessmentPassFn = async (uuid: string, isPass: boolean) => await assessmentApi.assessmentGet(
    uuid,
    isPass,
)
export const getAssessmentResultFn = async (uuid: string) => await assessmentApi.assessmentResult(uuid)
export const getAssessmentResultAccordanceFn = async (uuid: string) =>
    await assessmentApi.assessmentResultAccordance(uuid)

export const getEmployeesAssessmentsFn = async (uuid: string) => await employeeApi.getAssessments(uuid)


// get assessment list
export const useGetAssessmentOptions = (employeeUuid: string) => {
    // const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useGetAssessmentOptions',
        queryFn: () => getAssessmentOptionsFn(employeeUuid),
        refetchOnWindowFocus: false,
        retry: 0,
    })
}
// get assessment list
export const useGetMyAssessmentList = () => {
    const dispatch = useAppDispatch()

    return useQuery({
        queryKey: 'useGetMyAssessmentList',
        queryFn: () => getMyAssessmentListFn(),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setMyAssessmentMetaList(data))
        },
        retry: 0,
    })
}
// get assessment
export const useGetAssessmentMeta = (uuid: string) => {
    const dispatch = useAppDispatch()

    return useQuery({
        queryKey: ['useGetAssessmentMeta', uuid],
        queryFn: () => getAssessmentMetaFn(uuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(assessmentActions.setAssessmentMeta(data))
        },
        retry: 0,
    })
}
// get assessment pass
export const useGetAssessmentPass = (uuid: string, enabled = true, isPass = false) => {
    const dispatch = useAppDispatch()

    return useQuery({
        queryKey: ['useGetAssessmentPass', uuid],
        queryFn: () => getAssessmentPassFn(uuid, isPass),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setAssessmentPass(data))
        },
        retry: 0,
        enabled,
    })
}

// get assessment result
export const useGetAssessmentResult = (uuid: string, enabled = true) => {
    const dispatch = useAppDispatch()

    return useQuery({
        queryKey: ['useGetAssessmentResult', uuid],
        queryFn: () => getAssessmentResultFn(uuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setResult(data))
            dispatch(myAssessmentActions.setResultAccordance([]))
        },
        retry: 0,
        enabled,
    })
}


// get assessment result accordance
export const useGetAssessmentResultAccordance = (uuid: string, enabled = false) => {
    const dispatch = useAppDispatch()

    return useQuery({
        queryKey: ['useGetAssessmentResultAccordance', uuid],
        queryFn: () => getAssessmentResultAccordanceFn(uuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setResultAccordance(data))
        },
        retry: 0,
        enabled,
    })
}

export const useGetEmployeeAssessments = (employeeUuid: string, enabled = false) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useGetEmployeeAssessments', employeeUuid],
        queryFn: () => getEmployeesAssessmentsFn(employeeUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(assessmentActions.setEmployeeAssessments(data))
        },
        retry: 0,
        enabled,
    })
}
