import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { PageTitle } from 'components/PageTitle/PageTitle'
import { TITLE_BUTTON_ADD, TITLE_PAGE_EMPLOYEES } from 'helpers/enums/titles'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { EmployeesTabs } from 'pages/employees/EmployeesPage/components/EmployeesTabs/EmployeesTabs'
import {
    DialogAddNewEmployee,
} from 'pages/employees/EmployeesPage/components/dialogs/DialogAddNewEmployee/DialogAddNewEmployee'
import { useAppSelector } from 'store/hooks/redux'
import { employeeActions } from 'store/actions'
import {
    DialogContinueCreateEmployee,
} from 'pages/employees/EmployeesPage/components/dialogs/DialogContinueCreateEmployee/DialogContinueCreateEmployee'
import { useNotify } from 'store/hooks/useNotify'
import { HINT_WARNING_ADD_EMPLOYEE_DOESNT_HAVE_DIVISION } from 'helpers/enums/hints'
import { SendMetric } from 'utils/sendMetric'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

const tabsValues = ['current', 'reserve', 'ex']

export const EmployeesPage = () => {
    const [dialogCreate, setDialogCreate] = useState(false)
    const [dialogContinue, setDialogContinue] = useState(false)
    const [uuidCreatedUser, setUuidCreatedUser] = useState('')
    const { divisions } = useAppSelector(state => state.employeeReducer.data)
    const notify = useNotify()
    const { tabPanel } = useParams()
    const navigate = useNavigate()
    const hasCorrectTab = tabPanel && tabsValues.includes(tabPanel)

    const { isFetching } = useLayoutFetch(employeeActions.getEmployeesDivisions(), {
        permissions: { service: 'employee', accessLevel: 'w' },
    })

    useEffect(() => {
        !hasCorrectTab && navigate(`${PATH_EMPLOYEES}/current`)
    }, [tabPanel])

    const onCreate = () => {
        if (divisions.length <= 0) {
            notify(HINT_WARNING_ADD_EMPLOYEE_DOESNT_HAVE_DIVISION, 'warning')
            return
        }
        setDialogCreate(true)
        SendMetric.getInstance().sendMetricClick('openDialogAddEmployee')
    }

    const onCreateSuccess = (uuid: string) => {
        setUuidCreatedUser(uuid)
        SendMetric.getInstance().sendMetricClick('addNewEmployee')
        setDialogContinue(true)
    }

    const onRejectContinueCreate = () => {
        setUuidCreatedUser('')
        setDialogContinue(false)
    }

    if (isFetching) return <LoadingComponent />

    return (
        <Box>
            <Authenticated service="employee" accessLevel="w">
                {dialogCreate && divisions?.length > 0 && <DialogAddNewEmployee
                    handleClose={() => setDialogCreate(false)}
                    successCreate={onCreateSuccess}
                    open={dialogCreate}
                    divisions={divisions}
                />}
            </Authenticated>
            {dialogContinue && uuidCreatedUser?.length > 0 && <DialogContinueCreateEmployee
                handleClose={onRejectContinueCreate}
                open={dialogContinue}
                employeeUuid={uuidCreatedUser}
            />}
            <Box sx={{ mb: 6 }}>
                <PageTitle
                    title={TITLE_PAGE_EMPLOYEES}
                    onCreate={onCreate}
                    buttonTitle={TITLE_BUTTON_ADD}
                    service="employee"
                    accessLevel="w"
                />
            </Box>
            {hasCorrectTab && <EmployeesTabs />}
        </Box>
    )
}

