import { ICPAGetCriteriaCompetenceItem, ICPAGetCriteriaCompetenceSkillLevel } from 'api/typing/cpaTypes'

export const initSaveDataCompetencies = (
    current: ICPAGetCriteriaCompetenceSkillLevel[],
    tree: ICpaCriteriaCompetenceGroup[],
) => {
    const saveCompetencies = new Set<string>([])

    current?.forEach(competenceType => {
        competenceType.competencies.forEach(el => {
            saveCompetencies.add(el.uuid)
        })
    })

    const rec = (tree: ICpaCriteriaCompetenceGroup[]): { allSelected: boolean; someSelected: boolean } => {
        let allSelected = false
        let someSelected = false
        tree.forEach(group => {
            const subGroupStatus = rec(group.subGroups)
            const competenceSelectedCount = group.competencies.reduce((acc, el) => {
                // eslint-disable-next-line no-param-reassign
                el.selected = saveCompetencies.has(el.uuid)

                return saveCompetencies.has(el.uuid) ? acc + 1 : acc
            }, 0)
            const allCompetenceSelected = competenceSelectedCount === group.competencies.length
            allSelected = group.subGroups.length
                ? subGroupStatus.allSelected && allCompetenceSelected
                : allCompetenceSelected
            someSelected = group.subGroups.length
                ? subGroupStatus.someSelected || competenceSelectedCount > 0
                : competenceSelectedCount > 0
            // eslint-disable-next-line no-param-reassign
            group.selected = someSelected
            // eslint-disable-next-line no-param-reassign
            group.allSelected = allSelected
        })
        return { allSelected, someSelected }
    }

    rec(tree)
    return Array.from(saveCompetencies)
}

// функция для рекурсивного выделения группы компетенции вверх + всех внутренних элементов вниз
export const changeActiveCompetenceGroup = (
    tree: ICpaCriteriaCompetenceGroup[],
    saveData: string[],
    uuid: string,
    value: boolean,
) => {
    // делаем сет из всех компетенций которые выбраны в данный момент
    const saveCompetencies = new Set<string>(saveData)

    const recForAll = (tree: ICpaCriteriaCompetenceGroup[], val: boolean) => {
        tree.forEach(group => {
            if (group.subGroups.length) recForAll(group.subGroups, val)
            let someCompetenceChange = false
            // eslint-disable-next-line no-param-reassign
            group.competencies = group.competencies.map(competence => {
                val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)

                const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                someCompetenceChange = someCompetenceChange || isChanged
                return {
                    ...competence,
                    selected: val,
                    isChanged,
                }
            })

            // eslint-disable-next-line no-param-reassign
            group.selected = val
            // eslint-disable-next-line no-param-reassign
            group.allSelected = val
            // eslint-disable-next-line no-param-reassign
            group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
        })
    }

    const rec = (
        tree: ICpaCriteriaCompetenceGroup[],
        uuid: string,
        val: boolean,
    ) => {
        return tree.some(group => {
            if (group.uuid === uuid) {
                let someCompetenceChange = false
                // eslint-disable-next-line no-param-reassign
                group.competencies = group.competencies.map(competence => {
                    const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                    someCompetenceChange = someCompetenceChange || isChanged
                    val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)
                    return {
                        ...competence,
                        isChanged,
                        selected: val,
                    }
                })

                recForAll(group.subGroups, val)
                // eslint-disable-next-line no-param-reassign
                group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
                // eslint-disable-next-line no-param-reassign
                group.allSelected = val
                // eslint-disable-next-line no-param-reassign
                group.selected = val
                return true
            }
            if (rec(group.subGroups, uuid, val)) {
                let allSelectedGroups = true,
                    hasSelectGroup = false,
                    allSelectedCompetence = true,
                    hasSelectedCompetence = false,
                    someCompetenceChange = false,
                    someGroupChange = false

                for (let i = 0; i < group.subGroups.length; i++) {
                    allSelectedGroups = Boolean(allSelectedGroups && group.subGroups[i].allSelected)
                    hasSelectGroup = Boolean(hasSelectGroup || group.subGroups[i].selected)
                    someGroupChange = Boolean(someGroupChange || group.subGroups[i].isChanged || false)
                }
                for (let i = 0; i < group.competencies.length; i++) {
                    allSelectedCompetence = Boolean(allSelectedCompetence && group.competencies[i].selected)
                    hasSelectedCompetence = Boolean(hasSelectedCompetence || group.competencies[i].selected)
                    someCompetenceChange = Boolean(someCompetenceChange || group.competencies[i].isChanged)
                }

                // eslint-disable-next-line no-param-reassign
                group.isChanged = someGroupChange || someCompetenceChange
                // eslint-disable-next-line no-param-reassign
                group.allSelected = allSelectedGroups && allSelectedCompetence
                // eslint-disable-next-line no-param-reassign
                group.selected = hasSelectedCompetence || hasSelectGroup
                return true
            }
        })
    }
    rec(tree, uuid, value)
    // возвращаем массив нововыбранных компетенций
    return Array.from(saveCompetencies)
}

export const changeActiveCompetence = (
    tree: ICpaCriteriaCompetenceGroup[],
    saveData: string[],
    uuid: string,
    groupUuid: string,
    value: boolean,
) => {
    // делаем сет из всех компетенций которые выбраны в данный момент
    const saveCompetencies = new Set<string>(saveData)

    const rec = (
        tree: ICpaCriteriaCompetenceGroup[],
        uuid: string,
        val: boolean,
    ) => {
        // для выхода из рекурсивной функции,возвращаем первое совпадение по условию в переданном дереве
        return tree.some(group => {
            //если группа из дерева совпадает с группой компетецнии
            if (group.uuid === groupUuid) {
                /*
                 * Инициализация вспомогательных переменных
                 * allSelected - флаг, что выбраны все дочерние элементы группы
                 * groupSelected - флаг, что группа выбрана
                 * someCompetenceChange - флаг, что какая нибудь из компетенций изменена
                 * */
                let allSelected = true,
                    groupSelected = false,
                    someCompetenceChange = false

                // проходимся по всем компетенциям группы
                group.competencies.forEach(competence => {
                    // Для исходной компетенции,меняем ее состояние
                    if (competence.uuid === uuid) {
                        //сохраняем/удаляем из сохраненных в зависимости от флага выбранности
                        val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)

                        // Рассчитываем состояние измененности
                        const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                        // меняем флаг выбранности
                        // eslint-disable-next-line no-param-reassign
                        competence.selected = value
                        // меняем флаг измененности
                        // eslint-disable-next-line no-param-reassign
                        competence.isChanged = isChanged
                        // обновляем вспомогательную переменную о том,что есть хоть одна измененная компетенция
                        someCompetenceChange = someCompetenceChange || isChanged
                    }
                    // обновляем вспомогательную переменную о том,что есть хоть одна измененная компетенция по остальным
                    someCompetenceChange = competence.isChanged || someCompetenceChange
                    // смотрим, выбрана ли группа (она выбрана когда есть хоть одна выбранная компетения)
                    groupSelected = groupSelected || !!competence.selected
                    // смотрим, выбрана ли группа полностью
                    allSelected = allSelected && !!competence.selected
                })

                // устанавливаем флаг выбранности группы
                // eslint-disable-next-line no-param-reassign
                group.selected = groupSelected
                // устанавливаем флаг полной выбранности группы
                // eslint-disable-next-line no-param-reassign
                group.allSelected = group.subGroups.every(subGroup => subGroup.allSelected) && allSelected
                // устанавливаем флаг измененности группы
                // eslint-disable-next-line no-param-reassign
                group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
                // выходим из рекурсии
                return true
            }
            // поиск компетенции внутри дочерних групп
            if (rec(group.subGroups, uuid, value)) {
                let allSelected = true,
                    someSelected = false,
                    someCompetenceChange = false,
                    someGroupChange = false
                // если внутри рекурсии найдена компетенция,то обновляем вспомогательные переменные
                group.subGroups.forEach(subGroup => {
                    someSelected = someSelected || !!subGroup.selected
                    allSelected = allSelected && !!subGroup.allSelected
                    someGroupChange = subGroup.isChanged || someGroupChange
                })
                group.competencies.forEach(competence => {
                    someSelected = someSelected || !!competence.selected
                    allSelected = allSelected && !!competence.selected
                    someCompetenceChange = competence.isChanged || someCompetenceChange
                })

                // Обновляем состояние  флагов группы
                // eslint-disable-next-line no-param-reassign
                group.allSelected = allSelected
                // eslint-disable-next-line no-param-reassign
                group.selected = someSelected
                // eslint-disable-next-line no-param-reassign
                group.isChanged = someGroupChange || someCompetenceChange
                // выходим из рекурсии
                return true
            }
        })
    }

    rec(tree, uuid, value)
    return Array.from(saveCompetencies)
}

// подготовка списка компетенций (установка изначально выбранного уровня владения)
export const prepareCompetenceListCurrent = (current: ICPAGetCriteriaCompetenceSkillLevel[]) => {
    current.forEach(skillLevel => {
        skillLevel.competencies.forEach(competence => {
            // eslint-disable-next-line no-param-reassign
            competence.initialSkillLevelUuid = competence.currentSkillLevelUuid
        })
    })
}

// получаем сохраненные компетенции для отображения в шторке критериев
export const getCompetenceListToSave = (
    tree: ICpaCriteriaCompetenceGroup[],
    saved: string[],
    current: ICPAGetCriteriaCompetenceSkillLevel[],
): ICPAGetCriteriaCompetenceSkillLevel[] => {
    // мапа для всех возможных типов компетенций
    const competenceTypes = new Map<string, IEntity>([])
    // мапа для компетенций по типам
    const competenceTypesMap = new Map<string, ICPAGetCriteriaCompetenceItem[]>([])
    // Сет сохраненных компетенций
    const savedCompetencies = new Set<string>(saved)
    // мапа для связки компетенции с уровнем
    const currentCompetenceSkillLevelMap = new Map<string, string>()

    for (let i = 0; i < current.length; i++) {
        for (let j = 0; j < current[i].competencies.length; j++) {
            // заполняем мапу уровней по компетенциям
            currentCompetenceSkillLevelMap.set(
                current[i].competencies[j].uuid,
                current[i].competencies[j].currentSkillLevelUuid,
            )
        }
    }

    const checkGroups = (groups: ICpaCriteriaCompetenceGroup[]) => {
        groups.forEach(group => {
            if (group.subGroups.length)
                checkGroups(group.subGroups)
            if (group.competencies.length)
                checkCompetencies(group.competencies)
            // убираем флаг измененности группы по завершению ее обработки
            // eslint-disable-next-line no-param-reassign
            group.isChanged = false
        })
    }
    const checkCompetencies = (competencies: ICpaCriteriaCompetence[]) => {
        competencies.forEach(competence => {
            // если компетенции нет в сете, то пропускаем
            if (!savedCompetencies.has(competence.uuid)) return
            const competenceTypeUuid = competence.competenceType.uuid
            // Сохраняем тип компетенции в сет
            competenceTypes.set(competenceTypeUuid, competence.competenceType)
            // получаем тип компетецнии из мапы
            const competenceType = competenceTypesMap.get(competenceTypeUuid) ?? []
            // получаем текущий уровень компетении или берем самый высший
            const currentSkillLevelUuid = currentCompetenceSkillLevelMap.get(competence.uuid)
                ?? competence.skillLevels[competence.skillLevels.length - 1].uuid

            // устанавливаем тип компетенции
            competenceTypesMap.set(competenceTypeUuid, [
                ...competenceType,
                {
                    ...competence,
                    currentSkillLevelUuid,
                    isChanged: currentCompetenceSkillLevelMap.has(competence.uuid),
                },
            ])
            // убираем флаг измененности компетенции по завершению ее обработки
            // eslint-disable-next-line no-param-reassign
            competence.isChanged = false
        })
    }

    checkGroups(tree)
    // формируем возврат компетенций по типам
    const array = Array.from(competenceTypes).map(el => ({
        ...el[1],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        competencies: competenceTypesMap.get(el[0])!,
    }))

    return array
}
