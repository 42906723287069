import React, { FC, useMemo, useState } from 'react'
import { Box, Divider } from '@mui/material'
import {
    TITLE_DRAWER_ADD_COMPETENCE_IN_PDP_SHORT,
    TITLE_NAMESPACE_SELECTED_COMPETENCE_COUNT,
} from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    DrawerPdpCompetenceGroup,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/dialogs/DrawerCreatePdpItemFromBase/components/DrawerPdpCompetenceGroup/DrawerPdpCompetenceGroup'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { developmentPdpActions, pdpActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useParams } from 'react-router-dom'
import { filterGroupCompetence } from 'helpers/filterGroupCompetence'
import { useNotify } from 'store/hooks/useNotify'
import { HINT_SUCCESS_PDP_COMPETENCE_WAS_ADDED } from 'helpers/enums/hints'
import { CreatePdpCompetenceHeader } from './components/CreatePdpCompetenceHeader/CreatePdpCompetenceHeader'
import { CreatePdpCompetenceActions } from './components/CreatePdpCompetenceActions/CreatePdpCompetenceActions'

import {
    DrawerPdpCompetenceGroupMobile,
} from './components/DrawerPdpCompetenceGroupMobile/DrawerPdpCompetenceGroupMobile'
import { DrawerFiltersMobile } from 'components/drawers/DrawerFiltersMobile/DrawerFiltersMobile'
import { CreatePdpCompetenceFilters } from './components/CreatePdpCompetenceFilters/CreatePdpCompetenceFilters'
import { useDesktop } from 'helpers/hooks/useDesktop'

type DrawerCreatePdpItemFromBaseProps = {
    handleClose: () => void;
    developmentPage?: boolean;
}

export const DrawerCreatePdpItemFromBase: FC<DrawerCreatePdpItemFromBaseProps> = ({ handleClose, developmentPage }) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const desktop =  useDesktop()
    const [search, setSearch] = useState('')
    const [openFilters, setOpenFilters] = useState(false)
    const { pdpUuid } = useParams()
    const {
        pdp: pdpPdp,
        competenciesFromBase: pdpCompetenciesFromBase,
        competenceFromBaseSaved: pdpCompetenceFromBaseSaved,
    } = useAppSelector(state => state.pdpReducer.data)
    const {
        pdp: developmentPdp,
        competenciesFromBase: developmentCompetenciesFromBase,
        competenceFromBaseSaved: developmentCompetenceFromBaseSaved,
    } = useAppSelector(state => state.developmentPdpReducer.data)
    const pdp = developmentPage ? developmentPdp : pdpPdp
    const employeeUuid = pdp?.employee?.uuid
    const competenciesFromBase = developmentPage ? developmentCompetenciesFromBase : pdpCompetenciesFromBase
    const competenceFromBaseSaved = developmentPage ? developmentCompetenceFromBaseSaved : pdpCompetenceFromBaseSaved

    const filteredValues = useMemo(() => competenciesFromBase
        && filterGroupCompetence(competenciesFromBase, search) || [], [search, competenciesFromBase])
    const competenceCountTitle = `${TITLE_NAMESPACE_SELECTED_COMPETENCE_COUNT}: ${competenceFromBaseSaved?.competencies?.length ?? 0}`

    const { isFetching: pdpFetching } = useLayoutFetch(pdpActions.getCompetenceForPdp(employeeUuid ?? '', pdpUuid ?? ''), {
        deps: [pdpUuid, employeeUuid],
        permissions: { service: 'pdp', accessLevel: 'w' },
    })
    const { isFetching: developmentFetching } = useLayoutFetch(developmentPdpActions.getCompetenceForPdp(employeeUuid ?? '', pdpUuid ?? ''), {
        deps: [pdpUuid, employeeUuid],
    })

    const addCompetencies = async () => {
        if (!pdpUuid || !employeeUuid || !pdp) return
        try {
            if (developmentPage)
                await dispatch(developmentPdpActions.addPdpItemsFromBase({
                    pdpUuid,
                    specializationUuid: pdp?.pointPosition?.specializationUuid,
                    // employeeUuid,
                    uuids: competenceFromBaseSaved.competencies,
                }))
            else
                await dispatch(pdpActions.setupCompetenceItemsPdp({
                    pdpUuid,
                    specializationUuid: pdp?.pointPosition?.specializationUuid,
                    employeeUuid,
                    uuids: competenceFromBaseSaved.competencies,
                }))
            handleClose()
            notify(HINT_SUCCESS_PDP_COMPETENCE_WAS_ADDED, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Box
            role="presentation"
            sx={{
                width: !desktop ? '100%' : 'calc(100vw - 320px)',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
        >
            <CreatePdpCompetenceHeader
                search={search}
                setSearch={setSearch}
                competenceCountTitle={competenceCountTitle}
                closeCreateCompetenceDialog={handleClose}
                addCompetencies={addCompetencies}
                setOpenFilters={setOpenFilters}
            />
            {!desktop &&
                <DrawerFiltersMobile
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    applySearchFilter={setSearch}
                    subTitle={TITLE_DRAWER_ADD_COMPETENCE_IN_PDP_SHORT}
                >
                    <CreatePdpCompetenceFilters
                        search={search}
                        setSearch={setSearch}
                    />
                </DrawerFiltersMobile>
            }
            {!pdpFetching && !developmentFetching ?
                <Box sx={{ ml: !desktop ? 3 : 10, mr: !desktop ? 3 : 7 }}>
                    {filteredValues?.map((group, index) => (
                        <>
                            {desktop ?
                                <DrawerPdpCompetenceGroup
                                    key={`${group.uuid}_${index}`}
                                    element={group}
                                    developmentPage={developmentPage}
                                />
                                :
                                <DrawerPdpCompetenceGroupMobile
                                    key={`${group.uuid}_${index}_mobile`}
                                    group={group}
                                    developmentPage={developmentPage}
                                />
                            }
                        </>
                    ),
                    )}
                </Box>
                :
                <Box
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <LoadingComponent/>
                </Box>
            }
            {!desktop &&
                <Box
                    sx={{
                        zIndex: 1,
                        position: 'sticky',
                        bottom: 0,
                        background: 'white',
                    }}
                >
                    <Divider sx={{ mb: 3 }} />
                    <CreatePdpCompetenceActions
                        handleClose={handleClose}
                        addCompetencies={addCompetencies}
                        competenceCountTitle={competenceCountTitle}
                    />
                </Box>
            }
        </Box>
    )
}

