import React from 'react'
import { PreviewRounded, SummarizeRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { useNavigate } from 'react-router-dom'

export const MyAssessmentMetaViewButton = () => {
    const navigate = useNavigate()
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)

    const onViewButtonClick = () => {
        if (assessmentMetaSetup?.estimationStatus || assessmentMetaSetup?.createdStatus)
            navigate('preview')
        else
            navigate('result')
    }

    if (!assessmentMetaSetup?.viewButtonShow) return null

    return (
        <Button
            startIcon={assessmentMetaSetup?.createdStatus
            || assessmentMetaSetup?.estimationStatus
                ? <PreviewRounded/>
                : <SummarizeRounded/>}
            variant={assessmentMetaSetup?.confirmationStatus ? 'contained' : 'outlined'}
            color={assessmentMetaSetup?.confirmationStatus ? 'warning' : 'primary'}
            onClick={onViewButtonClick}
        >
            {assessmentMetaSetup?.viewButtonTitle}
        </Button>
    )
}
