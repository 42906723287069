import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { DoNotDisturbAltRounded } from '@mui/icons-material'
import { TextLink } from 'components/common/TextLink/TextLink'
import { PATH_LOGIN } from 'helpers/enums/routePath'
import { TITLE_BUTTON_AUTH } from 'helpers/enums/titles'

export const FormRecoverOldLink = () => {
    const navigate = useNavigate()
    return (
        <Stack spacing={4} justifyContent="center" alignItems="center">
            <Box display="flex" justifyContent="center">
                <DoNotDisturbAltRounded
                    color="primary"
                    fontSize="large"
                    sx={{ width: 100, height: 100 }}
                />
            </Box>
            <Typography variant="body1">
                Ссылка устарела
            </Typography>
            <Box display="flex" justifyContent="center">
                <TextLink onCustomClickEvent={() => navigate(PATH_LOGIN)}>
                    {TITLE_BUTTON_AUTH}
                </TextLink>
            </Box>
        </Stack>
    )
}
