import React, { CSSProperties, FC, ReactNode, useMemo } from 'react'
import { Box, Card, Link, Stack, StepContent, StepLabel, Typography } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'
import { GFEducationStatusProgress } from 'components/common/GFEducationStatusProgress/GFEducationStatusProgress'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { TITLE_NAMESPACE_PLAN_ITEM, TITLE_NAMESPACE_USER_ACTIVITY } from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { PATH_DEVELOPMENT } from 'helpers/enums/routePath'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { developmentPdpActions } from 'store/actions'
import { useNotify } from 'store/hooks/useNotify'
import { CommentsIconButton } from 'components/common/CommentsIconButton/CommentsIconButton'
import { HINT_SUCCESS_COMMENT_CREATE, HINT_SUCCESS_COMMENT_DELETE } from 'helpers/enums/hints'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useDesktop } from 'helpers/hooks/useDesktop'

type DevelopmentPdpItemProps = {
    item: IPdpPlanItem;
}

export const DevelopmentPdpItem: FC<DevelopmentPdpItemProps> = ({ item }) => {
    const desktop = useDesktop()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { dialogCommentView, toggleDialog } = useDialogsState({
        dialogCommentView: false,
    })
    const { pdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const pdpStatus = getPdpStatus(pdp.status, false)
    const disableEditComments = !(pdpStatus.status === 'inProgress' || pdpStatus.status === 'returned' || pdpStatus.status === 'sent')
    const isApprovalStatus = pdpStatus.status === 'approval'
    const notifyStyles: CSSProperties = useMemo(() => desktop ? {} : {position: 'inherit', bottom: '110px', width: 'fit-content'}, [])

    const deleteComment = async (uuid: string) => {
        try {
            await dispatch(developmentPdpActions.deletePdpItemComment({
                uuid,
                pdpUuid: pdp.currentPdpUuid,
                itemUuid: item.uuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    const createComment = async (comment: IComment) => {
        try {
            await dispatch(developmentPdpActions.createPdpItemComment({
                ...comment,
                pdpUuid: pdp.currentPdpUuid,
                itemUuid: item.uuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    const DevelopmentPdpItemWrap: FC<{ children: ReactNode }> = ({ children }) => (
        <>
            {!desktop ?
                <Card sx={{ p: 3, mb: 3 }}>{children}</Card>
                :
                <>{ children }</>
            }
        </>
    )

    return (
        <DevelopmentPdpItemWrap>
            <StepLabel sx={{ mb: !desktop ? 3 : 0 }}>
                <DrawerComments
                    open={dialogCommentView}
                    disableEdit={disableEditComments}
                    handleClose={() => toggleDialog('dialogCommentView', false)}
                    entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                    itemTitle={item.title}
                    comments={item.comments}
                    onDeleteComment={deleteComment}
                    onCreateComment={createComment}
                />
                {desktop ?
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={!desktop ? 'column' : 'row'}
                        px={2}
                    >
                        <Box display="flex" flex={1} alignItems="center">
                            {!isApprovalStatus &&
                                <Box mr={3} display="flex" alignItems="center">
                                    <GFEducationStatusProgress
                                        progress={item.progress}
                                        entityType={item.entityType}
                                        isPassed={item.isPassed}
                                    />
                                </Box>
                            }
                            <Box flex={1}>
                                <Box display="flex" mb={0.5} alignItems="center">
                                    <Link
                                        component={RouterLink}
                                        to={`${PATH_DEVELOPMENT}/pdp/${pdp?.currentPdpUuid}/item/${item.uuid}/${item.entityType}`}
                                        variant="body1"
                                    >
                                        {item.title}
                                    </Link>
                                </Box>
                                <Typography variant="body1" fontSize="smaller">
                                    {item.typeTitle || TITLE_NAMESPACE_USER_ACTIVITY}
                                </Typography>
                            </Box>
                            {!!item?.changeLog &&
                                <BadgeChangeLog changeLog={item.changeLog} evaluatedUuid={pdp?.employee?.uuid}/>
                            }
                        </Box>
                        <Box display="flex">
                            <Box display="flex" justifyContent="center" alignItems="center" width={156}>
                                <Typography variant="body2">
                                    {formatHours(item.studyTime)}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center" width={156}>
                                <CommentsIconButton
                                    hasComments={item.comments?.length > 0}
                                    canEdit={!disableEditComments}
                                    onClick={() => toggleDialog('dialogCommentView', true)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    :
                    <>
                        {!isApprovalStatus &&
                            <Stack direction='row' alignItems='center'>
                                <GFEducationStatusProgress
                                    progress={item.progress}
                                    entityType={item.entityType}
                                    isPassed={item.isPassed}
                                />
                                {!!item?.changeLog &&
                                    <BadgeChangeLog
                                        changeLog={item.changeLog}
                                        evaluatedUuid={pdp?.employee?.uuid}
                                        badgeDrawerSubTitle={item.title}
                                        badgeDrawerTitle={item.typeTitle || TITLE_NAMESPACE_USER_ACTIVITY}
                                    />
                                }
                            </Stack>
                        }

                    </>
                }
            </StepLabel>
            {!desktop &&
                <StepContent sx={{ p: 0, m: 0, border: 'none' }}>
                    <Box mb={3}>
                        <Box display="flex" mb={0.5} alignItems="center">
                            <Link
                                component={RouterLink}
                                to={`${PATH_DEVELOPMENT}/pdp/${pdp?.currentPdpUuid}/item/${item.uuid}/${item.entityType}`}
                                variant="body1"
                            >
                                {item.title}
                            </Link>
                        </Box>
                        <Typography variant="body1" fontSize="smaller">
                            {item.typeTitle || TITLE_NAMESPACE_USER_ACTIVITY}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent='space-between'>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <AccessTimeIcon color='disabled' sx={{ mr: 1 }} />
                            <Typography variant="body1">
                                {formatHours(item.studyTime)}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <CommentsIconButton
                                hasComments={item.comments?.length > 0}
                                canEdit={!disableEditComments}
                                onClick={() => toggleDialog('dialogCommentView', true)}
                            />
                        </Box>
                    </Box>
                </StepContent>
            }
        </DevelopmentPdpItemWrap>
    )
}

