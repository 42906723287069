import React, { FC } from 'react'
import { Box, Divider, Stack, styled, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_PERCENTAGE_DEVELOPMENT,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'
import { ImportExportRounded } from '@mui/icons-material'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { useAppSelector } from 'store/hooks/redux'

const GFListHeader = styled(Box)(() => ({
    paddingLeft: 16,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 6.5,
    paddingBottom: 6.5,
}))

type PdpPlanListItemsHeaderProps = {
    pdpStatus: IPdpStatus;
    isDevelopment?: boolean;
}

//TODO: need refactoring margin and paddings
export const PdpPlanListItemsHeader: FC<PdpPlanListItemsHeaderProps> = ({ pdpStatus, isDevelopment }) => {
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    return (
        <Box mb={0.5}>
            <Box display="flex" justifyContent="space-between" px={2}>
                <Box display="flex" alignItems="center">
                    <Authenticated
                        service={isDevelopment ? undefined : 'pdp'}
                        accessLevel="w"
                        divisions={[employee?.division?.uuid]}
                    >
                        <ImportExportRounded color="action" sx={{ mr: 2 }}/>
                    </Authenticated>
                    {pdpStatus.status !== 'default' &&
                        <Typography variant="body1" mr={2}>
                            {TITLE_NAMESPACE_PERCENTAGE_DEVELOPMENT}
                        </Typography>}
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_PLAN_ITEM}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={1} mr="38px">
                    <GFListHeader>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_STUDY_TIME}
                        </Typography>
                    </GFListHeader>
                    <GFListHeader>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_COMMENT}
                        </Typography>
                    </GFListHeader>
                </Stack>
            </Box>
            <Divider />
        </Box>
    )
}

