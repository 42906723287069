import React, { FC, useMemo } from 'react'
import { Checkbox } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { cpaItemEmployeesActions } from 'store/actions'
import { ICpaGetEmployeesForAddEmployee } from 'api/typing/cpaTypes'

type CPAEmployeeDrawerDivisionCheckboxProps = {
    divisionUuid: string;
    employees: ICpaGetEmployeesForAddEmployee[];
}

export const CPAEmployeeDrawerDivisionCheckbox: FC<CPAEmployeeDrawerDivisionCheckboxProps> = ({
    divisionUuid,
    employees,
}) => {
    const dispatch = useAppDispatch()
    const employeeListSaved = useAppSelector(state => state.CPAItemEmployeesReducer.employeeListSaved)
    const currentEmployeeListSaved = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployeeListSaved)
    const employeeListSavedSet = useMemo(() => new Set(employeeListSaved), [employeeListSaved.length])
    const currentEmployeeListSavedSet = useMemo(
        () => new Set(currentEmployeeListSaved),
        [currentEmployeeListSaved.length],
    )
    const halfSelected = useMemo(
        () => employees.some(el => employeeListSavedSet.has(el.uuid)),
        [employeeListSavedSet],
    )
    const allSelected = useMemo(
        () => employees.every(el => employeeListSavedSet.has(el.uuid) || !!el.role),
        [employeeListSavedSet],
    )
    const wasChanged = useMemo(
        () => employees.some(
            el => employeeListSavedSet.has(el.uuid) && !currentEmployeeListSavedSet.has(el.uuid)
                || !employeeListSavedSet.has(el.uuid) && currentEmployeeListSavedSet.has(el.uuid),
        ),
        [employeeListSavedSet, currentEmployeeListSavedSet],
    )

    const changeSelectDivision = () => {
        dispatch(cpaItemEmployeesActions.setSelectStateDivision({
            divisionUuid,
            isSelected: !halfSelected,
        }))
    }

    return (
        <Checkbox
            indeterminate={halfSelected && !allSelected}
            checked={allSelected}
            color={wasChanged ? 'warning' : 'primary'}
            onChange={changeSelectDivision}
            sx={{
                color: (theme) => wasChanged ? theme.palette.warning.main : theme.palette.primary.main,
            }}
        />
    )
}
