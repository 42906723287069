import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IEmployeeEditEducationEducationCreateOrUpdate,
} from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeEditEducationEducationState> = {
    data: {} as IEmployeeEditEducationEducationState,
    isLoading: false,
    error: null,
}

export const employeeEditEducationEducationSlice = createSlice({
    name: 'employeeEditEducationEducation',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getEducationList: (state, action: PayloadAction<IEmployeeEditEducationEducation[]>) => {
            state.data.educations = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        createEducation: (state, action: PayloadAction<IEmployeeEditEducationEducationCreateOrUpdate>) => {
            const { educationLevelUuid, uuid } = action.payload
            if (!uuid) return
            state.data.educations.push({
                ...action.payload,
                uuid,
                level: state.data.educationLevels.find(el => el.uuid === educationLevelUuid) || {} as IEntity,
            })
            state.isLoading = false
            state.error = null
        },

        updateEducation: (state, action: PayloadAction<IEmployeeEditEducationEducationCreateOrUpdate>) => {
            const { educationLevelUuid } = action.payload
            state.data.educations = state.data.educations.map(education => {
                if (education.uuid === action.payload.uuid)
                    return {
                        ...education,
                        ...action.payload,
                        level: state.data.educationLevels.find(el =>
                            el.uuid === educationLevelUuid) || {} as IEntity,
                    }

                return education
            })
            state.isLoading = false
            state.error = null
        },

        getEducationLevelList: (state, action: PayloadAction<IEntity[]>) => {
            state.data.educationLevels = action.payload
            state.isLoading = false
            state.error = null
        },

        deleteEducation: (state, action: PayloadAction<string>) => {
            state.data.educations = state.data.educations.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data.educations = []
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditEducationEducationReducer = employeeEditEducationEducationSlice.reducer
