import axios, { AxiosInstance } from 'axios'
import Cookies from 'universal-cookie'
import { IAuthResponse } from 'api/typing/authServiceTypes'
import { GetErrorResponse } from 'helpers/getErrorResponse'

const cookies = new Cookies()

const clearCookie = () => {
    cookies.remove('accessToken', { path: '/', sameSite: 'none', secure: true })
    cookies.remove('refreshToken', { path: '/', sameSite: 'none', secure: true })
    cookies.remove('companyUUID', { path: '/', sameSite: 'none', secure: true })
}

const apiPrefix = process.env.REACT_APP_API_PREFIX as string
const refreshPrefix = '/auth'
const refreshBaseUrl = process.env.REACT_APP_BASE_URL as string

const refreshAxiosConfig = {
    baseURL: `${refreshBaseUrl}${apiPrefix}${refreshPrefix}`,
}

const refreshToken = (http: AxiosInstance) => async (error: any) => {
    const originalRequest = error.config
    const refreshToken = cookies.get('refreshToken')
    const accessToken = cookies.get('accessToken')
    const companyUUID = cookies.get('companyUUID')
    const hasCookieData = refreshToken && accessToken && companyUUID
    const currentError = new GetErrorResponse(error).getError()

    // Если ошибка не является ошибкой авторизации - прокидываем исходную
    if (error.response?.status !== 401) throw currentError

    // Если повтор запроса или нет авторизационных кук, выкидываем в страницу логина
    if (originalRequest._isRetry || !hasCookieData) {
        clearCookie()
        const requestedPath = window.location.pathname
        window.location.replace(`/login?requestedPath=${requestedPath}`)
        return
    }

    // TODO: Можно упростить if удалив ненужные проверки
    // Если удоволетворяет всем условиям, запрашиваем обновление токенов
    if (
        error.response?.status === 401
        && hasCookieData
        && !originalRequest._isRetry
        && error.config
    ) {
        originalRequest._isRetry = true
        try {
            const { data } = await axios.post<Omit<IAuthResponse, 'companyUUID'>>(
                '/refresh',
                { refreshToken },
                refreshAxiosConfig,
            )
            const secure = document?.location?.protocol === 'https:'
            const sameSite = secure ? 'none' : 'lax'
            cookies.set('accessToken', data.accessToken, { path: '/', sameSite, secure })
            cookies.set('refreshToken', data.refreshToken, { path: '/', sameSite, secure })
            return http.request(originalRequest)
        } catch (e) {
            clearCookie()
            const requestedPath = window.location.pathname
            window.location.replace(`/login?requestedPath=${requestedPath}`)
        }
    }
    throw new GetErrorResponse(error).getError()
}

export const addRefreshingAccessToken = (http: AxiosInstance) => {
    http.interceptors.response.use(res => res, refreshToken(http))
}
