import { ExamService } from '../../services/exam'
import { useAppDispatch } from '../../../store/hooks/redux'
import { useQuery } from 'react-query'
import { examItemAction, examListAction } from '../../../store/actions'

const examListApi = new ExamService().listService()
const examItemApi = new ExamService().itemService()

const getExamListFn = async (employeeUuid: string) => await examListApi.getList(employeeUuid)
const getExamItemFn = async (uuid: string) => await examItemApi.getItem(uuid)
const getResultFn = async (uuid: string) => await examItemApi.getResult(uuid)

export const useExamList = (employeeUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useExamList',
        queryFn: () => getExamListFn(employeeUuid),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(examListAction.setList(data))
        },
        retry: 0,
        enabled,
    })
}

export const useExamItem = (itemUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useExamItem', itemUuid],
        queryFn: () => getExamItemFn(itemUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(examItemAction.setItem(data))
        },
        retry: 0,
        enabled,
    })
}

export const useExamResult = (itemUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useExamResult', itemUuid],
        queryFn: () => getResultFn(itemUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(examItemAction.setExamResult(data))
        },
        retry: 0,
        enabled,
    })
}
