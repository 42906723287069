import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'
import {
    DevelopmentPdpUpdateUniqueRequest,
    DevelopmentPdpUpdateUniqueResponse,
    IDevelopmentMaterialPassRequest,
    IDevelopmentPdpAddMaterialsFromBaseResponse,
    IDevelopmentPdpItemCommentDeleteRequest,
    IDevelopmentPdpItemCommentUpdateRequest,
    IDevelopmentPdpItemMaterialCommentCreateRequest,
    IDevelopmentPdpItemMaterialCommentDeleteRequest,
    IDevelopmentPdpItemMaterialCommentUpdateRequest,
    IDevelopmentPdpItemPassRequest,
    IDevelopmentPdpItemPassResponse,
    IDevelopmentPdpMaterialCreateRequest,
    IDevelopmentPdpMaterialCreateResponse,
    IDevelopmentPdpMaterialDeleteRequest,
    IDevelopmentPdpMaterialUpdateRequest,
    IDevelopmentPdpProgressStatusItem,
} from 'api/typing/developmentPdpService'
import { changeActivePdpCompetenceMaterialSetup } from 'helpers/pdp/pdpMaterialSetup'
import { LoggerFactory } from 'utils/logger'

const logger = LoggerFactory.createLogger()

interface IDevelopmentPdpItemState {
    item: IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>;
    competenceBaseMaterials: IPdpMaterialFromBase[];
}

const initialState: IState<IDevelopmentPdpItemState> = {
    data: {} as IDevelopmentPdpItemState,
    isLoading: false,
    error: null,
}

export const developmentPdpItemSlice = createSlice({
    name: 'developmentPdpItem',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        getItem: (state, action: PayloadAction<IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>>) => {
            state.data.item = action.payload
        },

        updateItem: (
            state,
            action: PayloadAction<DevelopmentPdpUpdateUniqueRequest & DevelopmentPdpUpdateUniqueResponse>,
        ) => {
            state.data.item = { ...state.data.item, ...action.payload }
        },

        createItemMaterial: (
            state,
            action: PayloadAction<IDevelopmentPdpMaterialCreateRequest & IDevelopmentPdpMaterialCreateResponse>,
        ) => {
            const skillLevel = state.data.item.skillLevels.find(el => el.uuid === action.payload.skillLevelUuid)

            if (!skillLevel) {
                logger.error('Skill level not found')
                return
            }

            state.data.item.studyTime += action.payload.studyTime
            skillLevel.studyTime += action.payload.studyTime
            skillLevel.materials.push({
                ...action.payload,
                isPassed: false,
                comments: [],
                selected: false,
            })
        },

        updateItemMaterial: (
            state,
            action: PayloadAction<IDevelopmentPdpMaterialUpdateRequest & IDevelopmentPdpProgressStatusItem>,
        ) => {
            state.data.item.progress = action.payload.progress
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const oldSkillLevel = state.data.item.skillLevels.find(level =>
                level.materials.some(material =>
                    material.uuid === action.payload.uuid))!

            if (oldSkillLevel.uuid === action.payload.skillLevelUuid) {
                oldSkillLevel.materials = oldSkillLevel.materials.map(material => {
                    if (material.uuid !== action.payload.uuid) return material
                    oldSkillLevel.studyTime = oldSkillLevel.studyTime - material.studyTime + action.payload.studyTime
                    return {
                        ...material,
                        ...action.payload,
                    }
                })
            } else {
                state.data.item.skillLevels = state.data.item.skillLevels.map(level => {
                    if (level.uuid === oldSkillLevel.uuid) return {
                        ...level,
                        studyTime: level.studyTime - action.payload.studyTime,
                        materials: level.materials.filter(material => material.uuid !== action.payload.uuid),
                    }
                    if (level.uuid === action.payload.skillLevelUuid) {
                        // eslint-disable-next-line no-param-reassign
                        level.studyTime += action.payload.studyTime
                        // @ts-ignore
                        level.materials.push(action.payload)
                    }
                    return level
                })
            }
        },

        deleteItemMaterial: (
            state,
            action: PayloadAction<IDevelopmentPdpMaterialDeleteRequest & IDevelopmentPdpProgressStatusItem>,
        ) => {
            // TODO: need check how it works
            const { skillLevelUuid, uuid, changeLog, totalProgress } = action.payload
            state.data.item.changeLog = changeLog
            state.data.item.progress = totalProgress
            const skillLevel = state.data.item?.skillLevels?.find(el => el.uuid === skillLevelUuid)
            const material = skillLevel?.materials?.find(el => el.uuid === uuid)

            if (!skillLevel || !material) {
                logger.error('Material or skill level not found')
                return
            }

            state.data.item.studyTime -= material.studyTime
            skillLevel.studyTime -= material.studyTime
            skillLevel.materials = skillLevel.materials.filter(el => el.uuid !== uuid)
        },

        changeOrderItems: (state, action: PayloadAction<{
            materials: IPdpCompetenceMaterial[];
            skillLevelUuid: string;
        }>) => {
            state.data.item.skillLevels.some(level => {
                if (level.uuid === action.payload.skillLevelUuid) {
                    // eslint-disable-next-line no-param-reassign
                    level.materials = action.payload.materials
                    return true
                }
                return false
            })
        },

        createPdpItemComment: (state, action: PayloadAction<IComment>) => {
            state.data.item.comments.push(action.payload)
        },

        updatePdpItemComment: (state, action: PayloadAction<IDevelopmentPdpItemCommentUpdateRequest & IComment>) => {
            state.data.item.comments = state.data.item.comments.map(el => el.uuid === action.payload.uuid
                ? { ...el, ...action.payload }
                : el,
            )
        },

        deletePdpItemComment: (state, action: PayloadAction<IDevelopmentPdpItemCommentDeleteRequest>) => {
            state.data.item.comments = state.data.item.comments.filter(el => el.uuid !== action.payload.uuid)
        },

        createPdpItemMaterialComment: (
            state,
            action: PayloadAction<IDevelopmentPdpItemMaterialCommentCreateRequest & IComment>,
        ) => {
            const { skillLevelUuid, materialUuid, ...comment } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    material.comments.push(comment)
                    return true
                })
            })
        },

        updatePdpItemMaterialComment: (
            state,
            action: PayloadAction<IDevelopmentPdpItemMaterialCommentUpdateRequest>,
        ) => {
            const { skillLevelUuid, materialUuid, ...comment } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    material.comments = material.comments.map(commentItem => commentItem.uuid === comment.uuid
                        ? { ...commentItem, ...comment }
                        : commentItem,
                    )
                    return true
                })
            })
        },

        deletePdpItemMaterialComment: (
            state,
            action: PayloadAction<IDevelopmentPdpItemMaterialCommentDeleteRequest>,
        ) => {
            const { skillLevelUuid, materialUuid, uuid } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    material.comments = material.comments.filter(comment => comment.uuid !== uuid)
                    return true
                })
            })
        },

        passPdpCompetenceMaterial: (
            state,
            action: PayloadAction<IDevelopmentMaterialPassRequest & IDevelopmentPdpItemPassResponse>,
        ) => {
            const { materialUuid, skillLevelUuid, isPassed, progress, status } = action.payload
            state.data.item.progress = progress
            state.data.item.pdpStatus = status
            state.data.item.skillLevels.find(level => {
                if (level.uuid !== skillLevelUuid) return false
                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false
                    // eslint-disable-next-line no-param-reassign
                    material.isPassed = isPassed
                    return true
                })
            })
        },

        passItemUnique: (
            state,
            action: PayloadAction<IDevelopmentPdpItemPassRequest & IDevelopmentPdpItemPassResponse>,
        ) => {
            state.data.item = { ...state.data.item, ...action.payload }
        },

        changeActivePdpCompetenceMaterialSetup: (state, action: PayloadAction<{ uuid: string; flag: boolean; }>) => {
            const { uuid, flag } = action.payload
            changeActivePdpCompetenceMaterialSetup(state.data.competenceBaseMaterials, uuid, flag)
        },
        getMaterialsForPdpCompetence: (state, action: PayloadAction<IPdpMaterialFromBase[]>) => {
            state.data.competenceBaseMaterials = action.payload
        },

        setupPdpCompetenceMaterials: (state, action: PayloadAction<IDevelopmentPdpAddMaterialsFromBaseResponse>) => {
            state.data.item = { ...state.data.item, ...action.payload }
        },
    },
})

export const developmentPdpItemReducer = developmentPdpItemSlice.reducer
