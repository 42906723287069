import React, { FC } from 'react'
import { Button, Stack } from '@mui/material'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_SAVE } from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { cpaItemScaleActions } from 'store/actions'
import { useParams } from 'react-router-dom'

type CPAScaleDrawerActionsProps = {
    handleClose: () => void;
}

export const CPAScaleDrawerActions: FC<CPAScaleDrawerActionsProps> = ({ handleClose }) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { cpaUuid } = useParams()
    const scaleUuid = useAppSelector(state => state.CPAItemScaleReducer.selectedForSaveScaleUuid)
    const currentScaleUuid = useAppSelector(state => state.CPAItemScaleReducer.currentScaleUuid)

    const saveScale = async () => {
        if (!cpaUuid || !scaleUuid) return
        if (currentScaleUuid === scaleUuid) {
            handleClose()
            return
        }
        try {
            await dispatch(cpaItemScaleActions.saveScale({
                cpaUuid,
                scaleUuid,
            }))
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    return (
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
                variant="text"
                onClick={handleClose}
            >
                {TITLE_BUTTON_CANCEL}
            </Button>
            <Button
                variant="contained"
                onClick={saveScale}
            >
                {TITLE_BUTTON_SAVE}
            </Button>
        </Stack>
    )
}
