import React from 'react'
import { Box } from '@mui/material'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            sx={{ p: 0, marginTop: 3 }}
            role="tabpanel"
            hidden={value !== index}
            flex={1}
            display={value === index ? 'flex' : undefined}
            flexDirection="column"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    )
}
