import React from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import {
    EmployeeAssessmentPreviewIndicator,
} from 'pages/employees/EmployeeAssessmentPreview/components/EmployeeAssessmentPreviewContent/components/EmployeeAssessmentPreviewContentCompetencies/components/EmployeeAssessmentPreviewIndicator/EmployeeAssessmentPreviewIndicator'

export const EmployeeAssessmentPreviewMobileCompetencies = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    return (
        <Stack spacing={8}>
            {assessment?.competencies?.map(competence => <Stack
                key={competence?.uuid}
                spacing={4}
            >
                <Box>
                    <Typography variant="caption">
                        {competence.typeTitle}
                    </Typography>
                    <Typography variant="h3">
                        {competence.title}
                    </Typography>
                </Box>
                <Stack spacing={4}>
                    {competence.indicators.map((indicator, idx) =>
                        <Paper key={indicator.uuid} variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                            <EmployeeAssessmentPreviewIndicator
                                idx={idx + 1}
                                indicator={indicator}
                            />
                        </Paper>)}
                </Stack>
            </Stack>)}
        </Stack>
    )
}
