import axios, { AxiosStatic } from 'axios'
import { DevelopmentPdpService } from 'api/services/DevelopmentService/developmentPdpService'
import { DevelopmentAssessmentService } from 'api/services/DevelopmentService/developmentAssessmentService'

interface IEmployeeService {
    developmentPdpService: () => DevelopmentPdpService;
    developmentAssessmentService: () => DevelopmentAssessmentService;
}

export class DevelopmentService implements IEmployeeService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public developmentPdpService() {
        return DevelopmentPdpService.getInstance(this.axiosInstance)
    }

    public developmentAssessmentService() {
        return new DevelopmentAssessmentService(this.axiosInstance)
    }
}
