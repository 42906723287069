import { containsText } from 'helpers/containsText'

// Функция фильтрации дерева компетенций
export function filterGroupCompetence(
    groups: ISetupCompetenceGroup[],
    search: string,
): ISetupCompetenceGroup[] {

    // Вспомогательная функция по фильтрации компетенций внутри группы
    const filterCompetence = (competencies: ISetupCompetence[], search: string) => {
        return competencies.filter(competence => containsText(competence.title, search))
    }

    // возвращаем новый отсортированный массив
    return groups.reduce((arr: ISetupCompetenceGroup[], group) => {
        if (containsText(group.title, search)) {
            arr.push(group)
            return arr
        }

        const competencies = filterCompetence(group.competencies, search)
        const subGroups = filterGroupCompetence(group.subGroups, search)

        if (competencies.length || subGroups.length) {
            arr.push({
                ...group,
                subGroups,
                competencies,
            })
            return arr
        }

        return arr
    }, [])
}
