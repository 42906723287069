import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<IGradeAssessment> = {
    data: {} as IGradeAssessment,
    isLoading: false,
    error: null,
}

export const gradeAssessmentSlice = createSlice({
    name: 'gradeAssessment',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.isLoading = false
        },

        setAssessment: (state, action: PayloadAction<IGradeAssessment>) => {
            state.data = action.payload
        },
    },
})

export const gradeAssessmentReducer = gradeAssessmentSlice.reducer
