import { Button, Stack, Typography } from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
} from 'helpers/enums/titles'
import React, { FC } from 'react'
import { useMobile } from 'helpers/hooks/useMobile'

type PdpSetupMaterialsActionsProps = {
    countTitle: string;
    handleClose: () => void;
    addMaterials: () => void;
}

export const PdpSetupMaterialsActions: FC<PdpSetupMaterialsActionsProps> = ({
    countTitle,
    handleClose,
    addMaterials,
}) => {
    const mobile = useMobile()

    return (
        <Stack
            spacing={2}
            sx={{
                pl: 3,
                pr: 3,
                pb: 3,
            }}
        >
            <Typography variant="body1" textAlign={mobile ? 'center' : 'right'}>
                {countTitle}
            </Typography>
            <Stack direction="row" spacing={2}>
                <Button onClick={handleClose}>
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button variant="contained" onClick={addMaterials}>
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
