import React, { FC, useState } from 'react'
import { Box, Chip, Divider, ListItem, Popover, Stack, Typography } from '@mui/material'
import { MoreHorizRounded } from '@mui/icons-material'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    TITLE_NAMESPACE_RESULTS_FOR_LEVELS,
    TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL,
} from 'helpers/enums/titles'
import { createBreakpoint } from 'react-use'

type AssessmentResultTreeCompetenceProps = {
    element: IAssessmentCompetenceGroupCompetence;
}
const useBreakPoints = createBreakpoint({ mobile: 0, laptop: 600 })
export const OAAssessmentResultTreeCompetence: FC<AssessmentResultTreeCompetenceProps> = ({ element }) => {
    const timerId = React.useRef<null | NodeJS.Timeout>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [open, setOpen] = useState(false)


    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        timerId.current = setTimeout(() => {
            setOpen(true)
        }, 500)
    }

    const closePopover = () => {
        if (timerId.current) clearTimeout(timerId.current)
        setOpen(false)
        setAnchorEl(null)
    }

    const popoverId = `popover-grade-${element.uuid}}`
    const breakPoint = useBreakPoints()
    const isMobile = breakPoint === 'mobile'

    return (
        <ListItem disablePadding sx={{ borderRadius: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: isMobile ? 1 : 2,
                    pr: isMobile ? 2 : 3,
                    borderRadius: '12px',
                }}
            >
                <Box display="flex" flex={1}>
                    <Box sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                        <Typography mr={0.5} mb={0.5} variant="body2">
                            {element.title}
                        </Typography>
                        <Typography variant="body1">
                            {element.typeTitle}
                        </Typography>
                    </Box>
                    {/*{indicatorsComments?.length ? <IconButton*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => toggleDialog('dialogComments', true)}*/}
                    {/*    sx={{ alignSelf: 'center' }}*/}
                    {/*>*/}
                    {/*    <CommentOutlined/>*/}
                    {/*</IconButton> : null}*/}
                </Box>
                <Stack spacing={3} direction="row" alignItems="center" alignSelf="center">
                    {element?.skillLevels?.length > 0 && <Box
                        aria-owns={open ? popoverId : undefined}
                        aria-haspopup="true"
                        onMouseEnter={openPopover}
                        onMouseLeave={closePopover}
                    >
                        <MoreHorizRounded color="primary"/>
                    </Box>}
                    <ChipSkillLevel
                        skillLevelTitle={element.skillLevelTitle}
                    />
                    <Chip
                        sx={{ minWidth: 56, marginLeft: 3 }}
                        label={`${element?.score}%`}
                        color={element?.score > 75 ? 'default' : 'warning'}
                    />
                </Stack>
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    sx={{
                        pointerEvents: 'none',
                        '& Mui-Paper': {
                            pointerEvents: 'auto',
                        },
                    }}
                    disableRestoreFocus
                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                    PaperProps={{ style: { borderRadius: '16px' } }}
                >
                    <Box p={2} maxWidth={377}>
                        <Stack spacing={2} mb={4}>
                            <Typography variant="body2">
                                {TITLE_NAMESPACE_RESULTS_FOR_LEVELS}
                            </Typography>
                            <Stack spacing={2} divider={<Divider/>}>
                                {element?.skillLevels?.map(el => <Stack
                                    key={el.uuid}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <ChipSkillLevel skillLevelTitle={el.title}/>
                                    <Chip
                                        sx={{ minWidth: 56, marginLeft: 3 }}
                                        label={`${el?.score}%`}
                                        color={el?.score > 75 ? 'default' : 'warning'}
                                    />
                                </Stack>)}
                            </Stack>
                            <Divider/>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="body2">
                                {TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL}
                            </Typography>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <ChipSkillLevel skillLevelTitle={element.skillLevelTitle}/>
                                <Chip
                                    sx={{ minWidth: 56, marginLeft: 3 }}
                                    label={`${element?.score}%`}
                                    color={element?.score > 75 ? 'default' : 'warning'}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </Popover>
            </Box>
        </ListItem>
    )
}
