import React, { useState } from 'react'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { HINT_NO_DATA } from 'helpers/enums/hints'
import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    CPAScaleDrawerContentItemActions,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/components/CPAScaleDrawerContentItemActions/CPAScaleDrawerContentItemActions'
import { cpaItemScaleActions } from 'store/actions'
import {
    CPAScaleDrawerContentAddItemAction,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/components/CPAScaleDrawerContentAddItemAction/CPAScaleDrawerContentAddItemAction'

export const CPAScaleDrawerContent = () => {
    const dispatch = useAppDispatch()
    const currentScaleUuid = useAppSelector(state => state.CPAItemScaleReducer.currentScaleUuid)
    const items = useAppSelector(state => state.CPAItemScaleReducer.scaleList)
    const [scaleUuid, setScaleUuid] = useState(currentScaleUuid || '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScaleUuid(e.target.value as string)
        dispatch(cpaItemScaleActions.selectForSaveScaleUuid(e.target.value as string))
    }

    return (
        <Stack spacing={2} divider={<Divider/>} flex={1}>
            {items
                ? <FormControl>
                    <RadioGroup onChange={handleChange} value={scaleUuid}>
                        <Stack spacing={2}>
                            {items?.map(el => <Box
                                key={el.uuid}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <FormControlLabel
                                    key={el.uuid}
                                    value={el.uuid}
                                    control={<Radio/>}
                                    label={el.title}
                                    sx={{ m: 0 }}
                                />
                                <CPAScaleDrawerContentItemActions
                                    scaleUuid={el.uuid}
                                    scaleType={el.entityType}
                                />
                            </Box>)}
                        </Stack>
                    </RadioGroup>
                </FormControl>
                : <EmptyPage contentText={HINT_NO_DATA}/>}
            <CPAScaleDrawerContentAddItemAction/>
        </Stack>
    )
}
