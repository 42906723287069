import { TransferLayer } from 'api/services/OpenAccessService/transferLayer'
import { IAssessmentSetAnswerRequest } from 'api/typing/assessmentServiceTypes'
import { IOAAssessmentCreateRequest } from 'api/typing/openAccessTypes'

export class OAAssessmentService extends TransferLayer {
    private prefix = 'assessment'

    public createAssessmentForAnonymous(data: IOAAssessmentCreateRequest) {
        const path = `${this.prefix}/new`
        return this.request<IOAAssessmentCreateRequest, { uuid: string }>('POST', path, {
            data,
            // params: { hash: data.hash },
        })
    }

    public getAssessment(uuid: string, hash: string) {
        const path = `${this.prefix}/${uuid}`
        return this.request<null, IAssessment>('GET', path, { params: { hash } })
    }

    public getAssessmentResult(uuid: string, hash: string) {
        const path = `${this.prefix}/${uuid}/result`
        return this.request<null, IAssessmentResult>('GET', path, { params: { hash } })
    }

    public assessmentSave(data: { uuid: string; hash: string }) {
        const path = `${this.prefix}/${data.uuid}/save`
        return this.request<{ uuid: string }, null>('POST', path, { data })
    }

    public assessmentAddAnswer(data: IAssessmentSetAnswerRequest & { hash: string }) {
        const path = `${this.prefix}/${data.assessmentUuid}/indicator/${data.indicatorUuid}`
        return this.request<IAssessmentSetAnswerRequest & { hash: string }, { score: number, skillLevelScore: number }>(
            'POST',
            path,
            { data },
        )
    }
}
