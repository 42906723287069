import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Divider, Stack, Typography } from '@mui/material'
import {
    AssessmentResultTreeGroup,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultTree/components/AssessmentResultTreeGroup/AssessmentResultTreeGroup'
import {
    TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE,
    TITLE_NAMESPACE_GROUP_OF_COMPETENCIES,
    TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY,
    TITLE_NAMESPACE_TOTAL,
} from 'helpers/enums/titles'

export const AssessmentResultTree = () => {
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const accordance = useAppSelector(state => state.assessmentResultReducer.data.accordance)

    if (!result) return null

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Box flex="1" display="flex" justifyContent="space-between" mr={3}>
                    <Box px={2}>
                        {!accordance && <Typography variant="body1">
                            {TITLE_NAMESPACE_GROUP_OF_COMPETENCIES} / {TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE}
                        </Typography>}
                    </Box>
                    <Box display="flex" px={2}>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY}
                        </Typography>
                    </Box>
                </Box>
                <Box width={92} display="flex" justifyContent="center">
                    <Typography variant="body1">{TITLE_NAMESPACE_TOTAL}</Typography>
                </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Stack divider={<Divider sx={{ my: 0.5 }} />}>
                {result.groupCompetence?.map(item => <AssessmentResultTreeGroup
                    key={item.uuid}
                    element={item}
                />)}
            </Stack>
            <Divider sx={{ my: 0.5 }} />
        </Box>
    )
}
