import React, { Divider, Typography } from '@mui/material'
import { FC } from 'react'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_DELETE, TITLE_DELETE_COMMENT } from 'helpers/enums/titles'
import { HINT_SURE_TO_DELETE_COMMENT } from 'helpers/enums/hints'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { DialogTitle } from '../../../dialogs/DialogTitle/DialogTitle'
import { DrawerCommentItem } from '../components/DrawerCommentItem/DrawerCommentItem'
import DialogContent from '@mui/material/DialogContent'

type DrawerCommentItemDeleteProps = {
    open: boolean;
    handleClose: () => void;
    deleteComment: (value: string) => void;
    comment: IComment;
}
export const DialogCommentItemDelete: FC<DrawerCommentItemDeleteProps> = ({
    open,
    handleClose,
    comment,
    deleteComment,
}) => {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle onClose={handleClose}>
                <Typography variant='body2' mb={3} fontSize='20px'>{ TITLE_DELETE_COMMENT }</Typography>
                <Typography variant='body2'>{ HINT_SURE_TO_DELETE_COMMENT }</Typography>
            </DialogTitle>
            <DialogContent>
                <Divider sx={{ mb: 3 }}/>
                <DrawerCommentItem comment={comment}/>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: 3,
                    m: 0,
                    gap: 1,
                    flexDirection: 'column',
                    alignSelf: 'center',
                    width: '100%',
                    maxWidth: '640px',
                    '& .MuiButtonBase-root': {
                        width: '100%',
                        m: 'auto',
                    },
                }}
                disableSpacing
            >
                <Button
                    variant="contained"
                    onClick={() => deleteComment(comment.uuid)}
                    color="error"
                >
                    {TITLE_BUTTON_DELETE}
                </Button>
                <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
            </DialogActions>
        </Dialog>
    )
}
