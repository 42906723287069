import React, { FC, useState } from 'react'
import { Box, Checkbox, Collapse, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPETENCIES_GENITIVE } from 'helpers/enums/titles'
import { cpaItemCriteriaActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import {
    CPACriteriaDrawerItemCompetenceDrawerCompetence,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/components/CPACriteriaDrawerItemCompetenceDrawer/componenst/CPACriteriaDrawerItemCompetenceDrawerCompetence/CPACriteriaDrawerItemCompetenceDrawerCompetence'

type CPACriteriaDrawerItemCompetenceDrawerCompetenceGroupProps = {
    item:  ICpaCriteriaCompetenceGroup;
    changeItemState?: React.MouseEvent;
}

export const CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup:
    FC<CPACriteriaDrawerItemCompetenceDrawerCompetenceGroupProps> = ({
        item,
    }) => {
        const dispatch = useAppDispatch()
        const [open, setOpen] = useState(true)
        const hasCollapsedItems = item.competencies?.length > 0 || item.subGroups?.length > 0

        const handleClick = () => {
            if (!hasCollapsedItems) return

            setOpen(prev => !prev)
        }

        const handleChange = () => {
            dispatch(cpaItemCriteriaActions.criteriaCompetenceChangeActiveGroup({
                uuid: item.uuid,
                active: !item.selected,
            }))
        }

        if (item.competenceCount <= 0) return null

        return (
            <List disablePadding>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ py: 1.5, px: 2, borderRadius: '12px' }}
                        onClick={handleClick}
                    >
                        <Box onClick={e => e.stopPropagation()} mr={2}>
                            <Checkbox
                                indeterminate={item?.selected && !item?.allSelected}
                                checked={item?.allSelected}
                                color={item.isChanged ? 'warning' : 'primary'}
                                onChange={handleChange}
                                sx={{
                                    color: (theme) => item.isChanged
                                        ? theme.palette.warning.main
                                        : theme.palette.primary.main,
                                }}
                            />
                        </Box>
                        <Box sx={{
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                            <Box display="flex" mb={0.5}>
                                <Typography maxWidth="435px" mr={0.5} variant="subtitle1">
                                    {item.title}
                                </Typography>
                                {hasCollapsedItems && <>
                                    {open ? <ExpandLess/> : <ExpandMore/>}
                                </>}
                            </Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: (theme) => item.competenceCount === 0
                                        ? theme.palette.warning.main
                                        : theme.typography.body1.color,
                                }}
                            >
                                {TITLE_NAMESPACE_COMPETENCIES_GENITIVE}: {item.competenceCount}
                            </Typography>
                        </Box>
                    </ListItemButton>
                </ListItem>
                <Divider sx={{ marginLeft: open ? 4 : 0 }} />
                {hasCollapsedItems && <Collapse
                    in={open}
                    timeout="auto"
                    sx={{
                        marginLeft: 2,
                        paddingLeft: 2,
                        borderLeft: '1px solid #C7D2D3',
                        position: 'relative',
                        '&:after': {
                            position: 'absolute',
                            content: '""',
                            width: 7,
                            height: 7,
                            backgroundColor: '#C7D2D3',
                            borderRadius: '50%',
                            top: -3.5,
                            left: -3.5,
                        },
                    }}
                >
                    {item.subGroups?.map((group) => (
                        <CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup
                            key={group.uuid}
                            item={group}
                        />
                    ))}
                    {item.competencies?.map(competence => (
                        <CPACriteriaDrawerItemCompetenceDrawerCompetence
                            key={competence.uuid}
                            groupUuid={item.uuid}
                            item={competence}
                        />
                    ))}
                </Collapse>}
            </List>
        )
    }
