import React, { FC } from 'react'
import { ICpaGetEmployeesForAddResponse } from 'api/typing/cpaTypes'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { HINT_NOTHING_WAS_FOUND } from 'helpers/enums/hints'
import { TITLE_BUTTON_REMOVE_SELECTED, TITLE_BUTTON_SELECT_ALL } from 'helpers/enums/titles'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemEmployeesActions } from 'store/actions'
import {
    CPAEmployeesDrawerContentDivision,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerContent/components/CPAEmployeesDrawerContentDivision/CPAEmployeesDrawerContentDivision'

type CPAEmployeesDrawerContentProps = {
    divisions: ICpaGetEmployeesForAddResponse[];
}

export const CPAEmployeesDrawerContent: FC<CPAEmployeesDrawerContentProps> = ({ divisions }) => {
    const dispatch = useAppDispatch()

    const selectAll = () => {
        dispatch(cpaItemEmployeesActions.selectAllEmployeeList())
    }
    const removeAllSelected = () => {
        dispatch(cpaItemEmployeesActions.removeAllEmployeeList())
    }

    if (!divisions.length) return <Typography variant="body2" sx={{ color: (theme) => theme.palette.warning.main }}>
        {HINT_NOTHING_WAS_FOUND}
    </Typography>

    return (
        <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={selectAll}
                >
                    {TITLE_BUTTON_SELECT_ALL}
                </Button>
                <Button
                    variant="text"
                    onClick={removeAllSelected}
                >
                    {TITLE_BUTTON_REMOVE_SELECTED}
                </Button>
            </Stack>
            <Stack spacing={3} divider={<Divider/>}>
                {divisions.map(division => <CPAEmployeesDrawerContentDivision
                    key={division.uuid}
                    division={division}
                />)}
            </Stack>
        </Stack>
    )
}
