import React, { FC } from 'react'
import { ChevronRightRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_CPA } from 'helpers/enums/routePath'

type CPAEmployeesAssessmentProps = {
    employeeUuid: string;
}

export const CPAEmployeesAssessment: FC<CPAEmployeesAssessmentProps> = ({ employeeUuid }) => {
    const { cpaUuid } = useParams()
    const navigate = useNavigate()

    const goToAssessment = () => {
        navigate(`${PATH_CPA}/${cpaUuid}/employee/${employeeUuid}`)
    }

    if (!cpaUuid || !employeeUuid) return null

    return (
        <Tooltip
            placement="bottom-start"
            title="Перейти к сотруднику"
            enterDelay={300}
            enterNextDelay={300}
        >
            <IconButton
                color="primary"
                onClick={goToAssessment}
            >
                <ChevronRightRounded/>
            </IconButton>
        </Tooltip>
    )
}
