import React, { FC } from 'react'
import { Outlet, RouteProps } from 'react-router-dom'
import { useAuthentication } from 'store/hooks/authentication'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'

type ProtectedRouteProps = RouteProps & {
    service: PermissionServiceCode;
    accessLevel: AccessLevel;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ service, accessLevel }) => {
    // Проверка на соответствия правам доступа
    const accessGranted = useAuthentication({ service, accessLevel })
    // В случае ошибки - возврат страницы 403
    return accessGranted ? <Outlet /> : <ErrorPage errorCode={403} />
}
