import { AppDispatch } from 'store/store'
import { SpecializationService } from 'api/services/SpecializationService'
import { gradeSlice } from 'store/reducers/grade/GradeSlice'
import { gradeDevelopmentPlanSlice } from 'store/reducers/grade/GradeDevelopmentPlanSlice'
import { IGradeUpdate } from 'api/typing/specializationTypes'
import { gradeAssessmentSlice } from 'store/reducers/grade/GradeAssessmentSlice'


export class GradeAction {
    private static get gradeApi() {
        return new SpecializationService().gradeService()
    }

    public getGrade(specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeSlice.actions.fetching())
                const data = await GradeAction.gradeApi.getGrade(specializationUuid, gradeUuid)
                dispatch(gradeSlice.actions.getGrade(data))
            } catch (e: any) {
                dispatch(gradeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getGradeDevelopmentPlan(specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeDevelopmentPlanSlice.actions.fetching())
                const data = await GradeAction.gradeApi.getGradeDevelopmentPlan(specializationUuid, gradeUuid)
                dispatch(gradeDevelopmentPlanSlice.actions.getGradeDevelopmentPlan(data))
            } catch (e: any) {
                dispatch(gradeDevelopmentPlanSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getGradeDevelopmentPlanCompetence(
        specializationUuid: string,
        gradeUuid: string,
        competenceUuid: string,
        entityType: string,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeDevelopmentPlanSlice.actions.fetching())
                const data = await GradeAction.gradeApi.getGradeDevelopmentPlanCompetence(
                    specializationUuid,
                    gradeUuid,
                    competenceUuid,
                    entityType,
                )
                dispatch(gradeDevelopmentPlanSlice.actions.getGradeDevelopmentPlanCompetence(data))
            } catch (e: any) {
                dispatch(gradeDevelopmentPlanSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateGrade(specializationUuid: string, gradeUuid: string, grade: IGradeUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeSlice.actions.fetching())
                void await GradeAction.gradeApi.updateGrade(
                    specializationUuid,
                    gradeUuid,
                    grade,
                )
                dispatch(gradeSlice.actions.updateGrade(grade))
            } catch (e: any) {
                dispatch(gradeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteGrade(specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeSlice.actions.fetching())
                void await GradeAction.gradeApi.deleteGrade(
                    specializationUuid,
                    gradeUuid,
                )
                dispatch(gradeSlice.actions.deleteGrade())
            } catch (e: any) {
                dispatch(gradeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getGradeAssessment(specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(gradeAssessmentSlice.actions.fetching())
                const data = await GradeAction.gradeApi.getGradeAssessment(specializationUuid, gradeUuid)
                dispatch(gradeAssessmentSlice.actions.setAssessment(data))
                dispatch(gradeAssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(gradeAssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
