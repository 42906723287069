import { TransferEmployee } from 'api/services/EmployeeService/transferEmployee'
import { IEmployeeCreate } from 'api/typing/employeeTypes'

export class EmployeeListService extends TransferEmployee {

    public getEmployeesCurrent() {
        return this.request<null, IEmployeesDepartment[]>('GET', '/current/all')
    }

    public addNewEmployee(data: IEmployeeCreate) {
        return this.request<IEmployeeCreate, { uuid: string }>('POST', '/create/one', { data })
    }
}
