import React from 'react'
import {
    MyAssessmentPassMobileHeader,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/components/MyAssessmentPassMobileHeader/MyAssessmentPassMobileHeader'
import { Stack } from '@mui/material'
import {
    MyAssessmentPassMobileCompetencies,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/components/MyAssessmentPassMobileCompetencies/MyAssessmentPassMobileCompetencies'
import {
    EmployeeAssessmentComments,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentComments/EmployeeAssessmentComments'
import {
    MyAssessmentPassProgress,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassProgress/MyAssessmentPassProgress'
import {
    MyAssessmentPassAction,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassAction/MyAssessmentPassAction'

export const MyAssessmentPassMobile = () => {
    return (
        <Stack spacing={4}>
            <MyAssessmentPassMobileHeader/>
            <EmployeeAssessmentComments isPageMyAssessment/>
            <MyAssessmentPassMobileCompetencies/>
            <MyAssessmentPassProgress/>
            <MyAssessmentPassAction/>
        </Stack>
    )
}
