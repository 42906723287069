import React, { FC } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import { EmployeeMetaListItem } from 'components/EmployeeMetaListItem/EmployeeMetaListItem'
import { ChipAssessmentStatus } from 'components/chips/ChipAssessmentStatus/ChipAssessmentStatus'
import {
    CPAEmployeesAssessment,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesAssessment/CPAEmployeesAssessment'
import { InfoOutlined } from '@mui/icons-material'

type CPAEmployeesStatisticEmployeeDrawerEmployeeProps = {
    employee: ICpaItemDivisionEmployee;
    isWithoutParticipants: boolean;
}

export const CPAEmployeesStatisticEmployeeDrawerEmployee: FC<CPAEmployeesStatisticEmployeeDrawerEmployeeProps> = ({
    employee,
    isWithoutParticipants,
}) => {
    if (!employee) return null

    return (
        <Stack flex={1} direction="row" alignItems="center" spacing={2}>
            <Box flex={1}>
                <EmployeeMetaListItem
                    fullName={`${employee.lastName} ${employee.firstName} ${employee.secondName}`}
                    specialization={employee.specialization}
                    grade={employee.grade}
                />
            </Box>
            {isWithoutParticipants
                ? <Tooltip
                    placement="bottom-start"
                    title="Оценки сотрудников, где не добавлены дополнительные участники для оценки 360"
                    enterDelay={300}
                    enterNextDelay={300}
                >
                    <InfoOutlined color="warning"/>
                </Tooltip>
                : <ChipAssessmentStatus status={employee.assessment?.statusUuid}/>}
            <CPAEmployeesAssessment employeeUuid={employee.uuid}/>
        </Stack>
    )
}
