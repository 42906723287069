import { ArrowForwardRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { TITLE_BUTTON_MORE } from 'helpers/enums/titles'
import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { useNavigate } from 'react-router-dom'

export const CPAAnalyticHeader = () => {
    const navigate = useNavigate()
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">
                Аналитика по оценке
            </Typography>
            <Button
                disabled={cpaStatusCode !== 'complete'}
                variant="contained"
                endIcon={<ArrowForwardRounded/>}
                onClick={() => navigate('analytics')}
            >
                {TITLE_BUTTON_MORE}
            </Button>
        </Box>
    )
}
