import React, { FC } from 'react'
import { CheckBoxOutlineBlankRounded, DateRangeRounded, InfoOutlined, PlayArrowRounded } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import { Tooltip } from '@mui/material'

type CPAEmployeesStatisticEmployeeIconProps = {
    iconType: 'progress' | 'expired' | 'notStarted' | 'withoutParticipants';
}

export const CPAEmployeesStatisticEmployeeIcon: FC<CPAEmployeesStatisticEmployeeIconProps & SvgIconProps> = ({
    iconType,
    ...props
}) => {
    switch (iconType) {
        case 'expired': {
            return <Tooltip
                placement="bottom-start"
                title="Оценки сотрудников, которые не завершены в срок"
                enterDelay={300}
                enterNextDelay={300}
            >
                <DateRangeRounded color="warning" {...props}/>
            </Tooltip>
        }
        case 'progress': {
            return <Tooltip
                placement="bottom-start"
                title="Оценки сотрудников, в которых не все участники успели пройти оценку"
                enterDelay={300}
                enterNextDelay={300}
            >
                <PlayArrowRounded color="warning" {...props}/>
            </Tooltip>
        }
        case 'notStarted': {
            return <Tooltip
                placement="bottom-start"
                title="Оценки сотрудников, в которых ни один участник не успел пройти оценку"
                enterDelay={300}
                enterNextDelay={300}
            >
                <CheckBoxOutlineBlankRounded color="warning" {...props}/>
            </Tooltip>
        }
        case 'withoutParticipants': {
            return <Tooltip
                placement="bottom-start"
                title="Оценки сотрудников, где не добавлены дополнительные участники для оценки 360"
                enterDelay={300}
                enterNextDelay={300}
            >
                <InfoOutlined color="warning" {...props}/>
            </Tooltip>
        }
        default: {
            return null
        }
    }
}
