import React, { FC, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { Divider, Paper, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_EMPLOYEE, TITLE_NAMESPACE_SHARED_COMMENTS } from 'helpers/enums/titles'
import {
    EmployeeAssessmentComment,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentComments/components/EmployeeAssessmentComment/EmployeeAssessmentComment'
import { myAssessmentActions } from 'store/actions'
import { useNotify } from 'store/hooks/useNotify'

type EmployeeAssessmentCommentsProps = {
    isPageMyAssessment?: boolean;
}

export const EmployeeAssessmentComments: FC<EmployeeAssessmentCommentsProps> = ({ isPageMyAssessment }) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const cpaRoles = useAppSelector(state => state.formReducer.data.cpaRoles)

    const rolesComment = useMemo(() => {
        if (!cpaRoles) return [] as (IEntity & { comments: IAssessmentComment[] })[]
        const rolesComments = new Map<string, IEntity & { comments: IAssessmentComment[] }>()
        cpaRoles.forEach(role => {
            rolesComments.set(role.uuid, { ...role, comments: [] })
        })
        rolesComments.set('employee', { uuid: 'employee', title: TITLE_NAMESPACE_EMPLOYEE, comments: [] })
        assessment?.comments.forEach(comment => {
            // if (!comment?.roleUuid)
            //     rolesComments.get('employee')?.comments.push(comment)
            // else
            rolesComments.get(comment.roleUuid || 'employee')?.comments.push(comment)
        })
        return Array.from(rolesComments.values()).filter(role => role.comments.length > 0)
    }, [cpaRoles, assessment?.comments])

    const deleteComment = async (deletedUuid: string) => {
        if (!assessment?.uuid) return
        try {
            await dispatch(myAssessmentActions.deleteAssessmentComment({
                assessmentUuid: assessment?.uuid,
                uuid: deletedUuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!assessment?.comments?.length || isPageMyAssessment && !assessmentSetup?.completeStatus) return null

    return (
        <Stack spacing={3}>
            <Typography variant="h3">
                {TITLE_NAMESPACE_SHARED_COMMENTS}
            </Typography>
            {rolesComment?.map(role => (
                <Stack key={role.uuid} spacing={1}>
                    <Typography variant="body2">
                        {role.title}
                    </Typography>
                    <Paper
                        key={role.uuid}
                        elevation={0}
                        sx={{
                            p: 2,
                            borderRadius: 4,
                            background: (theme) => theme.palette.background.default,
                        }}
                    >
                        <Stack spacing={2} divider={<Divider/>}>
                            {role.comments.map(comment => <EmployeeAssessmentComment
                                key={comment.uuid}
                                comment={comment}
                                deleteComment={deleteComment}
                            />)}
                        </Stack>
                    </Paper>
                </Stack>
            ))}
        </Stack>
    )
}
