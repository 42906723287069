import { TransferMassExam } from './transferMassExam'
import {
    IMassExamQuestion,
    IMassExamQuestionCreateReq,
    IMassExamQuestionDeleteReq, IMassExamQuestionsFileUploadReq, IMassExamQuestionsFileUploadResp,
    IMassExamQuestionSortUpdateReq,
    IMassExamQuestionUpdateReq,
} from '../../typing/massExam'

export class MassExamQuestionConstructorService extends TransferMassExam {
    public getList(uuid: string) {
        const path = `${uuid}/question/all`
        return this.request<null, IMassExamQuestion[]>('GET', path)
    }

    public createQuestion(data: IMassExamQuestionCreateReq) {
        const path = `${data.massExamUuid}/question/new`
        return this.request<IMassExamQuestionCreateReq, { uuid: string }>('POST', path, { data })
    }

    public updateQuestion(data: IMassExamQuestionUpdateReq) {
        const path = `${data.massExamUuid}/question/${data.uuid}`
        return this.request<IMassExamQuestionUpdateReq, IMassExamQuestion>('PUT', path, { data })
    }

    public deleteQuestion(data: IMassExamQuestionDeleteReq) {
        const path = `${data.massExamUuid}/question/${data.uuid}`
        return this.request<IMassExamQuestionDeleteReq>('DELETE', path, { data })
    }

    public deleteAllQuestions(massExamUuid: string) {
        const path = `${massExamUuid}/question`
        return this.request<{ massExamUuid: string }>('DELETE', path, { data: { massExamUuid } })
    }

    public updateQuestionsSort(data: IMassExamQuestionSortUpdateReq[]) {
        const path = `${data[0].massExamUuid}/question/sort`
        return this.request<IMassExamQuestionSortUpdateReq[]>('POST', path, { data })
    }

    public uploadQuestionsFile(data: IMassExamQuestionsFileUploadReq) {
        const path = `${data.massExamUuid}/questions/upload`
        return this.request<IMassExamQuestionsFileUploadReq, IMassExamQuestionsFileUploadResp>('POST', path, { data })
    }
}
