import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeeWorkExperiencePreviousJobCreateOrUpdate } from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeWorkExperiencePreviousJobItem[]> = {
    data: [] as IEmployeeWorkExperiencePreviousJobItem[],
    isLoading: false,
    error: null,
}

export const employeeEditWorkExperiencePreviousJobSlice = createSlice({
    name: 'employeeEditWorkExperiencePreviousJob',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getPreviousJobs: (state, action: PayloadAction<IEmployeeWorkExperiencePreviousJobItem[]>) => {
            state.data = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        createPreviousJobs: (state, action: PayloadAction<IEmployeeWorkExperiencePreviousJobItem>) => {
            state.data.push(action.payload)
            state.isLoading = false
            state.error = null
        },

        updatePreviousJobs: (state, action: PayloadAction<IEmployeeWorkExperiencePreviousJobCreateOrUpdate>) => {
            state.data = state.data.map(el => {
                if (el.uuid === action.payload.uuid)
                    return {
                        ...el,
                        ...action.payload,
                    }
                return el
            })
            state.isLoading = false
            state.error = null
        },

        deletePreviousJobs: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = []
            state.lastQuery = null
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditWorkExperiencePreviousJobReducer = employeeEditWorkExperiencePreviousJobSlice.reducer
