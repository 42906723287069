import React, { FC, useMemo } from 'react'
import { Box, Card, CardContent, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_EMPLOYEE_EDIT_WORK_IN_COMPANY,
    TITLE_NAMESPACE_POSITION_IN_COMPANY,
    TITLE_NAMESPACE_PROJECTS_AND_ROLES,
} from 'helpers/enums/titles'
import { PATH_EMPLOYEES, PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import { EditOffRounded, EditRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
    EditPreviousPositionItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabWorkInCompany/components/EditPreviousPositionCard/components/EditPreviousPositionItem/EditPreviousPositionItem'
import {
    EditProjectsAndRolesItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabWorkInCompany/components/EditProjectsAndRolesCard/components/EditProjectsAndRolesItem/EditProjectsAndRolesItem'
import { useAppSelector } from 'store/hooks/redux'
import { emptyUuid, isEmptyUuid } from 'helpers/isEmptyUuid'
import { HINT_NO_DATA } from 'helpers/enums/hints'
import {
    areIntervalsOverlapping,
    compareAsc,
    differenceInDays,
    getOverlappingDaysInIntervals,
    max,
    min,
    parse,
    subDays,
} from 'date-fns'
import { useFetch } from 'store/hooks/useFetch'
import { employeeActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { formatTimeToStringDuration } from 'helpers/dateAndTime/formatTimeToStringDuration'

type EmployeeTabInfoWorkInCompanyProps = {
    employeeUuid: string;
    parentPath: string;
    isProfilePage: boolean;
    hasNotPermissionEdit: boolean;
}

interface DatesString {
    start: string;
    end: string;
}

const getRangeDaysWithoutOverlap = (datesRange: DatesString[]): number | null => {
    if (!datesRange || datesRange.length <= 0) return null

    const normalizeRange = datesRange.map(el => ({
        start: el?.start ? parse(el.start, 'dd.MM.yyyy', new Date()) : new Date(),
        end: el?.end ? parse(el.end, 'dd.MM.yyyy', new Date()) : new Date(),
    })).sort((a, b) => compareAsc(a.start, b.start))

    let count = 0
    let intersectionStart = normalizeRange[0].start
    let intersectionEnd = normalizeRange[0].end

    for (let i = 0; i < normalizeRange.length; i++) {
        const { start, end } = normalizeRange[i]

        let daysInRange = differenceInDays(end, start)
        if (i !== 0 && !(start <= end && intersectionStart <= intersectionEnd))
            return 0

        if (i > 0 && areIntervalsOverlapping(
            { start, end }, { start: intersectionStart, end: intersectionEnd },
        )) {
            daysInRange -= getOverlappingDaysInIntervals(
                { start, end },
                { start: intersectionStart, end: intersectionEnd },
            )
            intersectionStart = min([start, intersectionStart])
            intersectionEnd = max([end, intersectionEnd])
        } else {
            intersectionStart = start
            intersectionEnd = end
        }
        count += daysInRange
    }

    return count
}

export const EmployeeTabInfoWorkInCompany: FC<EmployeeTabInfoWorkInCompanyProps> = ({
    employeeUuid,
    isProfilePage,
    hasNotPermissionEdit,
}) => {
    const navigate = useNavigate()
    const {
        data: { currentPosition },
    } = useAppSelector(state => state.employeeEditWorkInCompanyCurrentPositionReducer)
    const {
        data: { positionList },
    } = useAppSelector(state => state.employeeEditWorkInCompanyPreviousPositionReducer)
    const {
        data: { projectsAndRoles },
    } = useAppSelector(state => state.employeeEditWorkInCompanyProjectAndRolesReducer)

    const { isFetching: loadingPreviousPosition } =
        useFetch(employeeActions.getEmployeeWorkInCompanyPreviousPosition(employeeUuid), [employeeUuid])
    const { isFetching: loadingWorkInCompany } =
        useFetch(employeeActions.getEmployeeWorkInCompanyCurrentPosition(employeeUuid), [employeeUuid])
    const { isFetching: loadingProjectsAndRoles } =
        useFetch(employeeActions.getEmployeeWorkInCompanyProjectsAndRoles(employeeUuid), [employeeUuid])
    const loading = loadingPreviousPosition || loadingWorkInCompany || loadingProjectsAndRoles

    const positions = useMemo(
        () => {
            if (!currentPosition || !positionList) return []
            if (!currentPosition?.specialization?.uuid || isEmptyUuid(currentPosition?.specialization?.uuid)
                || !currentPosition?.grade?.uuid || isEmptyUuid(currentPosition?.grade?.uuid)
                || !currentPosition?.division?.uuid || isEmptyUuid(currentPosition?.division?.uuid)
            ) return [...positionList]
            return [
                { ...currentPosition, uuid: emptyUuid },
                ...positionList,
            ]
        },
        [currentPosition, positionList, positionList?.length],
    )

    const workExperience = useMemo(() => {
        if (!currentPosition || !positionList) return null
        const arr = positionList.map(el => ({ start: el.startDate, end: el.endDate }))

        if (currentPosition?.specialization?.uuid && !isEmptyUuid(currentPosition?.specialization?.uuid)
            && currentPosition?.grade?.uuid && !isEmptyUuid(currentPosition?.grade?.uuid)
        )
            arr.unshift({ start: currentPosition.startDate, end: currentPosition.endDate })
        const calcWorkDaysDuration = getRangeDaysWithoutOverlap(arr)
        if (!calcWorkDaysDuration) return null
        const startCalcDate = subDays(new Date(), calcWorkDaysDuration)
        return formatTimeToStringDuration(startCalcDate, new Date())
    }, [currentPosition, positionList, positionList?.length])

    if (loading) return <LoadingComponent/>

    return (
        <Card>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Box display="flex">
                        <Typography variant="h2" mr={2}>
                            {TITLE_NAMESPACE_EMPLOYEE_EDIT_WORK_IN_COMPANY}
                        </Typography>
                        {workExperience && <Chip
                            label={workExperience}
                            size="small"
                            color="secondary"
                        />}
                    </Box>
                    <IconButton
                        color="primary"
                        disabled={isProfilePage || hasNotPermissionEdit}
                        onClick={() => navigate(`${PATH_EMPLOYEES}/${employeeUuid}/${PATH_EMPLOYEES_EDIT}/company`)}
                    >
                        {isProfilePage ? <EditOffRounded/> : <EditRounded/>}
                    </IconButton>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2} divider={<Divider sx={{ my: 2 }} />}>
                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_POSITION_IN_COMPANY}
                        </Typography>

                        {positions.length > 0
                            ? <Stack spacing={2}>{positions.map((position, index) => <EditPreviousPositionItem
                                key={position.uuid}
                                position={position}
                                isCurrentPosition={index === 0}
                                pageViewEmployee
                            />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>

                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_PROJECTS_AND_ROLES}
                        </Typography>
                        {projectsAndRoles && projectsAndRoles.length > 0
                            ? <Stack spacing={2}>{projectsAndRoles.map((projectAndRole, index) =>
                                <EditProjectsAndRolesItem
                                    key={projectAndRole.uuid}
                                    projectAndRole={projectAndRole}
                                    isCurrentPosition={index === 0}
                                    pageViewEmployee
                                />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}
