import React, { FC } from 'react'
import { Step, Stepper, useTheme } from '@mui/material'

import { useAppSelector } from 'store/hooks/redux'
import { sortArrayFromSortIndexAndTitle } from 'helpers/sortArray'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { DevelopmentPdpItem } from '../DevelopmentPdpItem/DevelopmentPdpItem'

function getItemStatus(entityType: EntityType, progress: number, isPassed: boolean) {
    return {
        active: entityType === 'unique' ? isPassed : progress === 100,
        disable: entityType === 'unique' ? !isPassed : progress === 0,
    }
}


export const DevelopmentPdpItems: FC = () => {
    const desktop = useDesktop()
    const theme = useTheme()
    const { pdp } = useAppSelector(state => state.developmentPdpReducer.data)

    const sortedItems = pdp?.planItems
        ? sortArrayFromSortIndexAndTitle(pdp.planItems)
        : [] as IPdpPlanItem[]

    return (
        <Stepper
            orientation="vertical"
            nonLinear
            sx={{
                ' .MuiStepConnector-root': {
                    marginLeft: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        width: '100%',
                        height: '1px',
                        position: 'absolute',
                        top: '50%',
                        left: '26px',
                        backgroundColor: '#E2E7E9',
                    },
                    display: !desktop? 'none' : 'initial',
                },
                '.MuiStepConnector-root .MuiStepConnector-line': {
                    borderColor: theme.palette.primary.main,
                    borderLeftWidth: 2,
                    opacity: 0.2,
                },
                '.MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
                    borderColor: theme.palette.background.default,
                    borderLeftWidth: 2,
                    opacity: 1,
                },
            }}
        >
            {sortedItems.map(step => (
                <Step
                    disabled={getItemStatus(step.entityType, step.progress, step.isPassed).disable}
                    active={getItemStatus(step.entityType, step.progress, step.isPassed).active}
                    expanded
                    sx={{
                        '.MuiStepLabel-root': {
                            padding: 0,
                        },
                        '& .MuiStepIcon-root': {
                            width: 32,
                            height: 32,
                            color: theme.palette.primary.main,
                            border: 'solid 2px #FFF',
                            borderRadius: '50%',
                            boxShadow: `0px 10px 40px 0px ${theme.palette.primary.light}, 0px 2px 8px -1px ${theme.palette.primary.light}`,
                        },
                        '& .MuiStepIcon-root.Mui-active': {
                            color: theme.palette.success.main,
                            boxShadow: `0px 10px 40px 0px ${theme.palette.success.light}, 0px 2px 8px -1px ${theme.palette.success.light}`,
                        },
                        '.Mui-disabled .MuiStepIcon-root': {
                            color: theme.palette.background.default,
                            boxShadow: `0px 10px 40px 0px ${theme.palette.background.default}, 0px 2px 8px -1px ${theme.palette.background.default}`,
                        },
                        '.Mui-disabled .MuiStepIcon-text': {
                            fill: theme.palette.text.disabled,
                        },
                    }}
                    key={step.uuid}
                >
                    <DevelopmentPdpItem item={step} />
                </Step>
            ))}
        </Stepper>
    )
}
