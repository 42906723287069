import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import {
    CPAEmployeesStatisticEmployeeCard,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeCard/CPAEmployeesStatisticEmployeeCard'
import { Stack } from '@mui/material'
import { useGetAnalyticCpaStatisticEmployee } from 'api/queries/analytics/analytics'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'

export const CPAEmployeesStatisticEmployee = () => {
    const cpaItem = useAppSelector(state => state.CPAItemReducer.meta)
    const cpaStatus = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const showBlock = cpaStatus === 'complete' || cpaStatus === 'progress' || cpaStatus === 'approval'
    const { data, isLoading, error } = useGetAnalyticCpaStatisticEmployee(cpaItem.uuid, showBlock)

    if (!showBlock) return null
    if (isLoading) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    if (!data) return null

    return (
        <Stack gap={2} direction="row" justifyContent="stretch" flexWrap="wrap">
            <CPAEmployeesStatisticEmployeeCard
                title={cpaStatus === 'complete' ? 'Не все оценили' : 'В процессе'}
                iconType="progress"
                employeeList={data.inProgress}
            />
            <CPAEmployeesStatisticEmployeeCard
                title={cpaStatus === 'complete' ? 'Не завершены' : 'Не начаты'}
                iconType="notStarted"
                employeeList={data.notStarted}
            />
            <CPAEmployeesStatisticEmployeeCard
                title="Нет участников 360"
                iconType="withoutParticipants"
                employeeList={data.withoutParticipants}
            />
            <CPAEmployeesStatisticEmployeeCard
                title="Просрочены"
                iconType="expired"
                employeeList={data.expired}
            />
        </Stack>
    )
}
