import React, { useEffect } from 'react'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { TITLE_NAMESPACE_CURRENT_SPECIALIZATION_EMPLOYEES } from 'helpers/enums/titles'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemCriteriaActions } from 'store/actions'
import { Box } from '@mui/material'

export const CPACriteriaCurrent = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(cpaItemCriteriaActions.setCriteriaCurrent())
    }, [])

    return (
        <Box>
            <PointPositionSpecializationGrade
                title={TITLE_NAMESPACE_CURRENT_SPECIALIZATION_EMPLOYEES}
            />
        </Box>
    )
}
