import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { GraphicSpider } from 'components/graphics/GraphicSpider/GraphicSpider'
import { Box } from '@mui/material'

const getParticipantsScores = (scores: IAssessmentScore[]) => {
    const res: Record<string, number> = {}
    scores.forEach(score => {
        let title = ''
        switch (true) {
            case score.userUuid === 'final': {
                // title = TITLE_NAMESPACE_TOTAL
                title = 'total'
                break
            }
            default: {
                title = ''
                break
            }
        }
        res[title] = score.score
    })
    return res
}

const prepareDataFromTreeToSpider = (
    tree: IAssessmentCompetenceGroup[],
) => {
    if (!tree) return { data: [], keys: [] }
    const competencies = getFlatListResult(tree)?.sort((a, b) => a.score - b.score)?.slice(0, 10)
    const data = []

    const keys = new Set<string>()

    for (let i = 0; i < competencies.length; i++) {
        const participantsScores = getParticipantsScores(competencies[i].scores)
        Object.keys(participantsScores).forEach(participant => participant && keys.add(participant))
        data.push({
            ...participantsScores,
            title: competencies[i].title,
            ['total-color']: '#F47560',
        })
    }

    return { data, keys: Array.from(keys) }
}

const getFlatListResult = (tree: IAssessmentCompetenceGroup[]) => {
    if (!tree) return []
    return tree.reduce((acc, el) => {
        if (el.groupCompetence.length)
            acc.push(...getFlatListResult(el.groupCompetence))
        acc.push(...el.competencies)
        return acc
    }, [] as IAssessmentCompetenceGroupCompetence[])
}

export const OAAssessmentGraphic = () => {
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    const { data, keys } = prepareDataFromTreeToSpider(assessmentResult.groupCompetence)

    if (data.length < 3) return null

    return (
        <Box width="100%" height={500}>
            <GraphicSpider data={data} keys={keys} indexBy="title" maxValue={100}/>
        </Box>
    )
}
