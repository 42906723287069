import { omitObject } from 'helpers/omitObject'
import { isEmptyUuid } from 'helpers/isEmptyUuid'

export const updateCompetencies = (
    tree: ISpecializationCompetenceGroup[],
    competenceGroupUuid: string,
    competenceUuid: string,
    grade: ISpecializationCompetenceGradeItem,
    matrixCompetenceToSave: ISpecializationCompetenceMatrixUpdateHash,
): boolean => {
    const newSkillLevelIndex = grade.skillLevels.findIndex(el => el.uuid === grade.skillLevelCurrentUuid)
    const newSkillLevel = grade.skillLevels[newSkillLevelIndex]
    return tree.some(group => {
        if (group.uuid === competenceGroupUuid) {
            return group.competencies.some(competence => {
                if (competence.uuid !== competenceUuid) return false
                let isFindGrade = false
                const grades = competence.grades

                for (let i = 0; i < grades.length; i++) {
                    if (grades[i].uuid === grade.uuid) {
                        isFindGrade = true
                        // eslint-disable-next-line no-param-reassign
                        grades[i] = grade
                        if (grade?.isChanged) {
                            // eslint-disable-next-line no-param-reassign
                            matrixCompetenceToSave[`${grade.uuid}.${competence.uuid}`] = {
                                gradeUuid: grade.uuid,
                                skillLevelUuid: grade.skillLevelCurrentUuid,
                                required: !!grade.skillLevels.find(level =>
                                    level.uuid === grade.skillLevelCurrentUuid)?.required,
                                competenceUuid: competence.uuid,
                            }
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            delete matrixCompetenceToSave[`${grade.uuid}.${competence.uuid}`]
                        }
                        continue
                    }

                    const { skillLevelCurrentUuid } = grades[i]
                    const currentSkillLevelIndex =
                        grades[i].skillLevels.findIndex(level => level.uuid === skillLevelCurrentUuid)

                    const newSkillLevelCurrentUuid = newSkillLevel?.uuid
                    const previousSkillLevelCurrentUuid = grades[i].previousSkillLevelCurrentUuid
                        ? grades[i].previousSkillLevelCurrentUuid
                        : grades[i].skillLevelCurrentUuid

                    if (isFindGrade) {
                        if (isEmptyUuid(grade.skillLevelCurrentUuid)) {
                            continue
                        }
                        if (currentSkillLevelIndex <= newSkillLevelIndex) {
                            // if (newSkillLevel.required) {
                            //     if (!grades[i].skillLevels[newSkillLevelIndex].required) {
                            //         if (!grades[i].skillLevels[newSkillLevelIndex].isChanged) {
                            //             // eslint-disable-next-line no-param-reassign
                            //             matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`] = {
                            //                 gradeUuid: grades[i].uuid,
                            //                 skillLevelUuid: grades[i].skillLevelCurrentUuid,
                            //                 required: true,
                            //                 competenceUuid: competence.uuid,
                            //             }
                            //         } else {
                            //             // eslint-disable-next-line no-param-reassign
                            //             delete matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`]
                            //         }
                            //         // eslint-disable-next-line no-param-reassign
                            //         grades[i].skillLevels[newSkillLevelIndex].isChanged =
                            //             !grades[i].skillLevels[newSkillLevelIndex].isChanged
                            //         // eslint-disable-next-line no-param-reassign
                            //         grades[i].skillLevels[newSkillLevelIndex].required = true
                            //
                            //     }
                            //
                            // }

                            if (newSkillLevelCurrentUuid !== previousSkillLevelCurrentUuid
                                || grades[i].skillLevels[newSkillLevelIndex].isChanged) {
                                // eslint-disable-next-line no-param-reassign
                                matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`] = {
                                    gradeUuid: grades[i].uuid,
                                    skillLevelUuid: newSkillLevelCurrentUuid,
                                    required: grades[i].skillLevels[newSkillLevelIndex]?.required || false,
                                    competenceUuid: competence.uuid,
                                }
                            } else {
                                // eslint-disable-next-line no-param-reassign
                                delete matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`]
                            }

                            grades[i] = {
                                ...grades[i],
                                previousSkillLevelCurrentUuid: previousSkillLevelCurrentUuid,
                                skillLevelCurrentUuid: newSkillLevelCurrentUuid,
                                isChanged: newSkillLevelCurrentUuid !== previousSkillLevelCurrentUuid
                                    || grades[i].skillLevels[newSkillLevelIndex].isChanged,
                            }
                        }
                    } else {
                        if (isEmptyUuid(grade.skillLevelCurrentUuid)) {
                            if (grades[i].previousSkillLevelCurrentUuid !== grades[i].skillLevelCurrentUuid) {
                                // eslint-disable-next-line no-param-reassign
                                matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`] = {
                                    gradeUuid: grades[i].uuid,
                                    skillLevelUuid: grade.skillLevelCurrentUuid,
                                    required: grades[i].skillLevels.find(level =>
                                        level.uuid === grade.skillLevelCurrentUuid)?.required || false,
                                    competenceUuid: competence.uuid,
                                }
                            } else {
                                // eslint-disable-next-line no-param-reassign
                                delete matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`]
                            }
                            // eslint-disable-next-line no-param-reassign
                            grades[i].previousSkillLevelCurrentUuid = previousSkillLevelCurrentUuid
                            // eslint-disable-next-line no-param-reassign
                            grades[i].skillLevelCurrentUuid = grade.skillLevelCurrentUuid
                            // eslint-disable-next-line no-param-reassign
                            grades[i].isChanged =
                                grades[i].previousSkillLevelCurrentUuid !== grades[i].skillLevelCurrentUuid
                            continue
                        }
                        if (currentSkillLevelIndex >= newSkillLevelIndex) {
                            // if (!newSkillLevel.required) {
                            //     if (grades[i].skillLevels[newSkillLevelIndex].required) {
                            //         if (!grades[i].skillLevels[newSkillLevelIndex].isChanged) {
                            //             // eslint-disable-next-line no-param-reassign
                            //             matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`] = {
                            //                 gradeUuid: grades[i].uuid,
                            //                 skillLevelUuid: grades[i].skillLevelCurrentUuid,
                            //                 required: false,
                            //                 competenceUuid: competence.uuid,
                            //             }
                            //         } else {
                            //             // eslint-disable-next-line no-param-reassign
                            //             delete matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`]
                            //         }
                            //
                            //         // eslint-disable-next-line no-param-reassign
                            //         grades[i].skillLevels[newSkillLevelIndex].isChanged =
                            //             !grades[i].skillLevels[newSkillLevelIndex].isChanged
                            //         // eslint-disable-next-line no-param-reassign
                            //         grades[i].skillLevels[newSkillLevelIndex].required = false
                            //     }
                            //
                            // }
                            if (newSkillLevelCurrentUuid !== previousSkillLevelCurrentUuid
                                || grades[i].skillLevels[newSkillLevelIndex].isChanged) {
                                // eslint-disable-next-line no-param-reassign
                                matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`] = {
                                    gradeUuid: grades[i].uuid,
                                    skillLevelUuid: newSkillLevelCurrentUuid,
                                    required: grades[i].skillLevels[newSkillLevelIndex].required,
                                    competenceUuid: competence.uuid,
                                }
                            } else {
                                // eslint-disable-next-line no-param-reassign
                                delete matrixCompetenceToSave[`${grades[i].uuid}.${competence.uuid}`]
                            }
                            // eslint-disable-next-line no-param-reassign
                            grades[i].previousSkillLevelCurrentUuid = previousSkillLevelCurrentUuid
                            // eslint-disable-next-line no-param-reassign
                            grades[i].skillLevelCurrentUuid = newSkillLevelCurrentUuid
                            // eslint-disable-next-line no-param-reassign
                            grades[i].isChanged = newSkillLevelCurrentUuid !== previousSkillLevelCurrentUuid
                                || grades[i].skillLevels[newSkillLevelIndex].isChanged
                        }
                    }
                }
                return true
            })
        }
        return updateCompetencies(
            group.subGroups,
            competenceGroupUuid,
            competenceUuid,
            grade,
            matrixCompetenceToSave,
        )
    })
}

export const saveChangesSpecializationCompetenceMatrix = (tree: ISpecializationCompetenceGroup[]) => {
    tree.forEach(group => {
        if (group?.subGroups.length) saveChangesSpecializationCompetenceMatrix(group.subGroups)
        group.competencies.forEach(competence => {
            // eslint-disable-next-line no-param-reassign
            competence.grades = competence.grades.map(grade => {
                return {
                    ...omitObject(grade, ['previousSkillLevelCurrentUuid', 'isChanged']),
                    skillLevels: grade.skillLevels.map(level => omitObject(level, ['isChanged'])),
                }
            },
            )
        })
    })
}

// export const restoreToInitialSpecializationCompetenceMatrix = (
//     tree: ISpecializationCompetenceGroup[],
//     initialTree: ISpecializationCompetenceGroup[],
// ) => {
//     tree.forEach((group, groupIdx) => {
//         if (group?.subGroups.length)
//             restoreToInitialSpecializationCompetenceMatrix(
//                 group.subGroups,
//                 initialTree[groupIdx].subGroups,
//             )
//         group.competencies.forEach((competence, competenceIdx) => {
//             // eslint-disable-next-line no-param-reassign
//             competence.grades = Object.assign([], initialTree[groupIdx].competencies[competenceIdx].grades)
//         })
//     })
// }

export const initSaveDataToSetup = (
    saveData: ISpecializationSetupSaveData,
    tree: ISetupCompetenceGroup[],
) => {
    const saveCompetencies = new Set(saveData.competencies || [])
    const saveCompetenciesGroup = new Set(saveData.competenceGroups || [])

    const rec = (tree: ISetupCompetenceGroup[]) => {
        tree.forEach(group => {
            group.selected ? saveCompetenciesGroup.add(group.uuid) : saveCompetenciesGroup.delete(group.uuid)
            group.competencies.forEach(competence => {
                competence.selected ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)
            })
            rec(group.subGroups)
        })
    }

    rec(tree)

    // eslint-disable-next-line no-param-reassign
    saveData.competencies = Array.from(saveCompetencies)
    // eslint-disable-next-line no-param-reassign
    saveData.competenceGroups = Array.from(saveCompetenciesGroup)
}


export const changeActiveSpecialization = (
    tree: ISetupCompetenceGroup[],
    saveData: ISpecializationSetupSaveData,
    uuid: string,
    groupUuid: string,
    value: boolean,
) => {
    const saveCompetencies = new Set(saveData.competencies)
    const saveCompetenciesGroup = new Set(saveData.competenceGroups)

    const rec = (
        tree: ISetupCompetenceGroup[],
        uuid: string,
        val: boolean,
    ) => {
        return tree.some(group => {
            if (group.uuid === groupUuid) {
                let allSelected = true,
                    groupSelected = false,
                    someCompetenceChange = false
                group.competencies.forEach(competence => {
                    if (competence.uuid === uuid) {
                        val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)
                        const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                        // eslint-disable-next-line no-param-reassign
                        competence.selected = value
                        // eslint-disable-next-line no-param-reassign
                        competence.isChanged = isChanged
                        someCompetenceChange = someCompetenceChange || isChanged
                    }
                    someCompetenceChange = competence.isChanged || someCompetenceChange
                    groupSelected = groupSelected || competence.selected
                    allSelected = allSelected && competence.selected
                })
                groupSelected ? saveCompetenciesGroup.add(group.uuid) : saveCompetenciesGroup.delete(group.uuid)

                // eslint-disable-next-line no-param-reassign
                group.selected = groupSelected
                // eslint-disable-next-line no-param-reassign
                group.allSelected = group.subGroups.every(subGroup => subGroup.allSelected) && allSelected
                // eslint-disable-next-line no-param-reassign
                group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
                return true
            }
            if (rec(group.subGroups, uuid, value)) {
                let allSelected = true,
                    someSelected = false,
                    someCompetenceChange = false,
                    someGroupChange = false
                group.subGroups.forEach(subGroup => {
                    someSelected = someSelected || subGroup.selected
                    allSelected = allSelected && subGroup.allSelected
                    someGroupChange = subGroup.isChanged || someGroupChange
                })
                group.competencies.forEach(competence => {
                    someSelected = someSelected || competence.selected
                    allSelected = allSelected && competence.selected
                    someCompetenceChange = competence.isChanged || someCompetenceChange
                })
                someSelected ? saveCompetenciesGroup.add(group.uuid) : saveCompetenciesGroup.delete(group.uuid)

                // eslint-disable-next-line no-param-reassign
                group.allSelected = allSelected
                // eslint-disable-next-line no-param-reassign
                group.selected = someSelected
                // eslint-disable-next-line no-param-reassign
                group.isChanged = someGroupChange || someCompetenceChange
                return true
            }
        })
    }

    rec(tree, uuid, value)
    // eslint-disable-next-line no-param-reassign
    saveData.competencies = Array.from(saveCompetencies)
    // eslint-disable-next-line no-param-reassign
    saveData.competenceGroups = Array.from(saveCompetenciesGroup)
}

export const changeActiveSpecializationGroup = (
    tree: ISetupCompetenceGroup[],
    saveData: ISpecializationSetupSaveData,
    uuid: string,
    value: boolean,
) => {
    const saveCompetencies = new Set(saveData.competencies)
    const saveCompetenciesGroup = new Set(saveData.competenceGroups)

    const recForAll = (tree: ISetupCompetenceGroup[], val: boolean) => {
        tree.forEach(group => {
            if (group.subGroups.length) recForAll(group.subGroups, val)
            let someCompetenceChange = false
            val ? saveCompetenciesGroup.add(group.uuid) : saveCompetenciesGroup.delete(group.uuid)
            // eslint-disable-next-line no-param-reassign
            group.competencies = group.competencies.map(competence => {
                val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)
                const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                someCompetenceChange = someCompetenceChange || isChanged
                return {
                    ...competence,
                    selected: val,
                    isChanged,
                }
            })

            // eslint-disable-next-line no-param-reassign
            group.selected = val
            // eslint-disable-next-line no-param-reassign
            group.allSelected = val
            // eslint-disable-next-line no-param-reassign
            group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
        })
    }

    const rec = (
        tree: ISetupCompetenceGroup[],
        uuid: string,
        val: boolean,
    ) => {
        return tree.some(group => {
            if (group.uuid === uuid) {
                let someCompetenceChange = false
                // eslint-disable-next-line no-param-reassign
                group.competencies = group.competencies.map(competence => {
                    const isChanged = competence.selected === val ? !!competence?.isChanged : !competence?.isChanged
                    someCompetenceChange = someCompetenceChange || isChanged
                    val ? saveCompetencies.add(competence.uuid) : saveCompetencies.delete(competence.uuid)
                    return {
                        ...competence,
                        isChanged,
                        selected: val,
                    }
                })
                val ? saveCompetenciesGroup.add(group.uuid) : saveCompetenciesGroup.delete(group.uuid)

                recForAll(group.subGroups, val)
                // eslint-disable-next-line no-param-reassign
                group.isChanged = group.subGroups.some(subGroup => subGroup.isChanged) || someCompetenceChange
                // eslint-disable-next-line no-param-reassign
                group.allSelected = val
                // eslint-disable-next-line no-param-reassign
                group.selected = val
                return true
            }
            if (rec(group.subGroups, uuid, val)) {
                let allSelectedGroups = true,
                    hasSelectGroup = false,
                    allSelectedCompetence = true,
                    hasSelectedCompetence = false,
                    someCompetenceChange = false,
                    someGroupChange = false

                for (let i = 0; i < group.subGroups.length; i++) {
                    allSelectedGroups = allSelectedGroups && group.subGroups[i].allSelected
                    hasSelectGroup = hasSelectGroup || group.subGroups[i].selected
                    someGroupChange = someGroupChange || group.subGroups[i].isChanged || false
                }
                for (let i = 0; i < group.competencies.length; i++) {
                    allSelectedCompetence = allSelectedCompetence && group.competencies[i].selected
                    hasSelectedCompetence = hasSelectedCompetence || group.competencies[i].selected
                    someCompetenceChange = someCompetenceChange || group.competencies[i].isChanged || false
                }
                hasSelectedCompetence || hasSelectGroup
                    ? saveCompetenciesGroup.add(group.uuid)
                    : saveCompetenciesGroup.delete(group.uuid)

                // eslint-disable-next-line no-param-reassign
                group.isChanged = someGroupChange || someCompetenceChange
                // eslint-disable-next-line no-param-reassign
                group.allSelected = allSelectedGroups && allSelectedCompetence
                // eslint-disable-next-line no-param-reassign
                group.selected = hasSelectedCompetence || hasSelectGroup
                return true
            }
        })
    }
    rec(tree, uuid, value)
    // eslint-disable-next-line no-param-reassign
    saveData.competencies = Array.from(saveCompetencies)
    // eslint-disable-next-line no-param-reassign
    saveData.competenceGroups = Array.from(saveCompetenciesGroup)
}

export const saveChangesSpecializationSetup = (tree: ISetupCompetenceGroup[]) => {
    tree.forEach(group => {
        // eslint-disable-next-line no-param-reassign
        delete group.isChanged
        // eslint-disable-next-line no-param-reassign
        group.competencies.forEach(competence => delete competence.isChanged)
        if (group?.subGroups.length) saveChangesSpecializationSetup(group.subGroups)
    })
}
