import React, { FC } from 'react'
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { TITLE_BUTTON_BACK_TO_SCALES, TITLE_NAMESPACE_CPA_SCALE } from 'helpers/enums/titles'
import { ArrowBackRounded, CloseRounded } from '@mui/icons-material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { useCpaDictionaryScaleNumeric } from 'api/queries/cpa/cpaQueries'
import {
    CPAScaleDrawerContentItemDrawerData,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/components/CPASceleDrawerContentItemDrawer/components/CPAScaleDrawerContentItemDrawerData/CPAScaleDrawerContentItemDrawerData'

type CPAScaleDrawerContentItemDrawerProps = {
    open: boolean;
    handleClose: () => void;
    scaleUuid?: string;
}


export const CPAScaleDrawerContentItemDrawer: FC<CPAScaleDrawerContentItemDrawerProps> = ({
    scaleUuid,
    open,
    handleClose,
}) => {
    const { data, isFetching, error } = useCpaDictionaryScaleNumeric(scaleUuid || '', open && !!scaleUuid)

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 720 },
                },
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Stack spacing={3} px={5} pt={5} pb={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_CPA_SCALE}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>
                    </Box>
                    <Button
                        variant="text"
                        startIcon={<ArrowBackRounded/>}
                        onClick={handleClose}
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        {TITLE_BUTTON_BACK_TO_SCALES}
                    </Button>
                    <Divider/>
                </Stack>
                <Stack spacing={3} flex={1} overflow="auto" px={5} pb={5}>
                    <>
                        {isFetching && <LoadingComponent/>}
                        {error && !isFetching && <ErrorPage error={error}/>}
                        {!error && !isFetching && <CPAScaleDrawerContentItemDrawerData
                            handleClose={handleClose}
                            scaleItem={scaleUuid ? data : undefined}
                        />}
                    </>
                </Stack>
            </Box>
        </Drawer>
    )
}
