import { EmployeeFormService } from 'api/services/FormService/employeeFormService'
import axios, { AxiosStatic } from 'axios'
import { SharedFormService } from 'api/services/FormService/SharedFormService'
import { CpaFormService } from 'api/services/FormService/cpaFormService'
import { OAFormService } from 'api/services/FormService/OAFormService'

interface IFormService {
    employeeFormService: () => EmployeeFormService;
    sharedFormService: () => SharedFormService;
    oAFormService: () => OAFormService;
    cpaFormService: () => CpaFormService;
}

export class FormService implements IFormService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public employeeFormService(): EmployeeFormService {
        return EmployeeFormService.getInstance(this.axiosInstance)
    }

    public cpaFormService(): CpaFormService {
        return new CpaFormService(this.axiosInstance)
    }

    public sharedFormService(): SharedFormService {
        return new SharedFormService(this.axiosInstance)
    }

    public oAFormService(): OAFormService {
        return new OAFormService(this.axiosInstance)
    }
}
