import React, { FC, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { convertLocalStringToUtcString } from 'helpers/dateAndTime/convertLocalStringToUtcString'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_COMMENT_REVIEWER,
    TITLE_BUTTON_SAVE,
    TITLE_NAMESPACE_COMMENT,
} from 'helpers/enums/titles'
import { InputForm } from 'components/form/InputForm/InputForm'
import { LoadingButton } from '@mui/lab'
import { cpaRoleReviewer } from 'helpers/cpa/cpaRoles'

type EmployeeAssessmentIndicatorFinalCommentProps = {
    indicatorComments: IAssessmentComment[];
    indicatorUuid: string;
    competenceUuid: string;
}

export const EmployeeAssessmentIndicatorFinalComment: FC<EmployeeAssessmentIndicatorFinalCommentProps> = ({
    indicatorComments,
    indicatorUuid,
    competenceUuid,
}) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { uuid, firstName, lastName } = useAppSelector(state => state.userReducer.data)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const currentComment = indicatorComments.find(el => el.roleUuid === cpaRoleReviewer)
    const [edit, setEdit] = useState(false)
    const [comment, setComment] = useState(() => currentComment?.text || '')
    const [loading, setLoading] = useState(false)
    const currentSummarize = useMemo(
        () => assessmentSetup?.summarizingStatus && assessmentSetup.viewerIsCurrentReviewer,
        [assessmentSetup],
    )

    const deleteComment = async () => {
        if (!assessmentSetup?.uuid || !currentComment) return
        try {
            await dispatch(myAssessmentActions.deleteAssessmentComment({
                assessmentUuid: assessmentSetup?.uuid,
                uuid: currentComment?.uuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const changeComment = async () => {
        if (!assessmentSetup?.uuid) return
        const hasOldComment = !!currentComment?.text.length
        const hasNewComment = !!comment.length
        try {
            setLoading(true)
            switch (true) {
                // delete
                case !hasNewComment && hasOldComment: {
                    if (!currentComment?.uuid) return
                    await dispatch(myAssessmentActions.deleteIndicatorComment({
                        assessmentUuid: assessmentSetup?.uuid,
                        indicatorUuid,
                        competenceUuid,
                        uuid: currentComment?.uuid,
                    }))
                    break
                }
                // update
                case hasNewComment && hasOldComment: {
                    if (!currentComment?.uuid) return
                    await dispatch(myAssessmentActions.updateIndicatorComment({
                        competenceUuid,
                        assessmentUuid: assessmentSetup?.uuid,
                        indicatorUuid,
                        uuid: currentComment?.uuid,
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: currentComment.createdAt,
                        roleUuid: 'reviewer',
                        updatedAt: convertLocalStringToUtcString(),
                    }))
                    break
                }
                // create
                case hasNewComment && !hasOldComment: {
                    await dispatch(myAssessmentActions.createIndicatorComment({
                        competenceUuid,
                        indicatorUuid,
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: convertLocalStringToUtcString(),
                        updatedAt: convertLocalStringToUtcString(),
                        assessmentUuid: assessmentSetup?.uuid,
                        roleUuid: 'reviewer',
                        uuid: currentComment?.uuid,
                    }))
                    break
                }
                default: {
                    break
                }
            }
            setEdit(false)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }
    const restoreDefault = () => {
        setComment(currentComment?.text || '')
        setEdit(false)
    }

    if (!currentComment && !currentSummarize) return null

    return (
        <Box display="flex">
            {!edit
                ? currentComment
                    ? <Stack>
                        <Typography variant="body1" mb={1}>{TITLE_BUTTON_COMMENT_REVIEWER}</Typography>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                flex: 1,
                                borderRadius: 4,
                                background: (theme) => theme.palette.background.default,
                            }}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <CommentOutlined color="action"/>
                                <Box flex={1}>
                                    <Typography variant="body2">
                                        {currentComment.text}
                                    </Typography>
                                </Box>
                                {assessmentSetup?.summarizingStatus && <SecondaryActions
                                    onEdit={() => setEdit(true)}
                                    onDelete={deleteComment}
                                />}
                            </Stack>
                        </Paper>
                    </Stack>
                    : <Button
                        variant="text"
                        startIcon={<AddCommentOutlined/>}
                        onClick={() => setEdit(true)}
                    >
                        {TITLE_BUTTON_COMMENT_REVIEWER}
                    </Button>
                :<Stack spacing={2}>
                    <InputForm
                        id={'comment-assessment'}
                        title={TITLE_NAMESPACE_COMMENT}
                        value={comment}
                        multiline
                        onChange={(e) => setComment(e.target.value as string)}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button
                            variant="text"
                            onClick={restoreDefault}
                        >
                            {TITLE_BUTTON_CANCEL}
                        </Button>
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            disabled={(currentComment?.text ?? '') === comment}
                            startIcon={<CommentOutlined/>}
                            onClick={changeComment}
                        >
                            {TITLE_BUTTON_SAVE}
                        </LoadingButton>
                    </Stack>
                </Stack>}
        </Box>
    )
}
