import React from 'react'
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { ExitToAppRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_EXIT } from 'helpers/enums/titles'
import { useProfile } from 'api/queries/profile/profileQueries'
import { useAppDispatch } from 'store/hooks/redux'
import { useNavigate } from 'react-router-dom'
import { authActions } from 'store/actions'
import { PATH_LOGIN } from 'helpers/enums/routePath'

export const AppMenuMobileDrawerFooter = () => {
    const { remove } = useProfile(false)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const logout = () => {
        dispatch(authActions.logout())
        navigate(PATH_LOGIN)
        remove()
    }

    return (
        <Stack spacing={2} mt={2}>
            <Divider/>
            <MenuItem onClick={logout} sx={{ px: 3, py: 2 }}>
                <ListItemIcon>
                    <ExitToAppRounded/>
                </ListItemIcon>
                <ListItemText>
                    {TITLE_NAMESPACE_EXIT}
                </ListItemText>
            </MenuItem>
        </Stack>
    )
}
