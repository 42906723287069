import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { QueryHeaders } from 'utils/queryHeaders'
import { SendMetric } from 'utils/sendMetric'

export const Metric = () => {
    const { pathname } = useLocation()
    const queryHeaders = QueryHeaders.getInstance()

    const parentPathName = pathname.split('/').filter(Boolean)[0]

    useEffect(() => {
        SendMetric.getInstance().sendMetricRoute(pathname)
        queryHeaders.setCookieHeader({ page: parentPathName })

        return () => queryHeaders.clearCookieHeader()
    }, [pathname])

    return null
}
