import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { StyledComponent } from '@emotion/styled'
import { TITLE_NAMESPACE_FEEDBACK, TITLE_NAMESPACE_PRACTICE, TITLE_NAMESPACE_THEORY } from 'helpers/enums/titles'
import { AutoStoriesRounded, BuildRounded, ForumRounded } from '@mui/icons-material'
import { mainTheme } from 'theme/mainTheme'

const materialTypeTheoryUuid = 'f047ff2a-8a7b-4353-bb6c-4dce5d292194'
const materialTypePracticeUuid = '59ecdb07-2faa-4e39-949d-77228591fa18'
const materialTypeFeedbackUuid = '6a18bf99-d30e-4f3f-87bd-9c9d19314897'

export const materialsTypes: IEntity[] = [
    {
        uuid: materialTypeTheoryUuid,
        title: TITLE_NAMESPACE_THEORY,
    },
    {
        uuid: materialTypePracticeUuid,
        title: TITLE_NAMESPACE_PRACTICE,
    },
    {
        uuid: materialTypeFeedbackUuid,
        title: TITLE_NAMESPACE_FEEDBACK,
    },
]

type MaterialTypeUuid = typeof materialTypeTheoryUuid
    | typeof materialTypePracticeUuid
    | typeof materialTypeFeedbackUuid

interface IMaterialType {
    uuid: MaterialTypeUuid;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & { muiName: string } | StyledComponent<any>;
    color: any;
}

// const theme = useTheme()


const materialTypes: Record<string, IMaterialType> = {
    [materialTypeTheoryUuid]: {
        uuid: materialTypeTheoryUuid,
        title: TITLE_NAMESPACE_THEORY,
        color: mainTheme.palette.primary.light,
        icon: AutoStoriesRounded,
    },
    [materialTypePracticeUuid]: {
        uuid: materialTypePracticeUuid,
        title: TITLE_NAMESPACE_PRACTICE,
        color: mainTheme.palette.secondary.light,
        icon: BuildRounded,
    },
    [materialTypeFeedbackUuid]: {
        uuid: materialTypeFeedbackUuid,
        title: TITLE_NAMESPACE_FEEDBACK,
        color: mainTheme.palette.warning.light,
        icon: ForumRounded,
    },
}

export const getMaterialType = (typeUuid?: string) => {
    if (typeof typeUuid === 'undefined') return null
    return materialTypes[typeUuid]
}
