import React from 'react'
import { Stack } from '@mui/material'
import {
    MyAssessmentPassHeader,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassHeader/MyAssessmentPassHeader'
import {
    MyAssessmentPassCompetencies,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/MyAssessmentPassCompetencies'
import {
    MyAssessmentPassProgress,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassProgress/MyAssessmentPassProgress'
import {
    MyAssessmentPassAction,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassAction/MyAssessmentPassAction'
import {
    EmployeeAssessmentComments,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentComments/EmployeeAssessmentComments'


export const MyAssessmentPassDesktop = () => {
    return (
        <Stack spacing={4}>
            <MyAssessmentPassHeader/>
            <EmployeeAssessmentComments isPageMyAssessment/>
            <MyAssessmentPassCompetencies/>
            <MyAssessmentPassProgress/>
            <MyAssessmentPassAction/>
        </Stack>
    )
}
