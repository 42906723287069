import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IAssessmentChangeStatusRequest,
    IAssessmentCommentCreateUpdate,
    IAssessmentCommentDelete,
    IAssessmentIndicatorCommentCreateUpdate,
    IAssessmentIndicatorCommentDelete,
    IAssessmentSetAnswerRequest,
} from 'api/typing/assessmentServiceTypes'
import {
    assessmentStatusAwaitComplete,
    assessmentStatusAwaitingConfirmation,
    assessmentStatusSummarizing,
    getAssessmentStatus,
} from 'helpers/assessment/getAssessmentStatus'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'
import { addDays, isPast, parse } from 'date-fns'
import { TITLE_NAMESPACE_CURRENT, TITLE_NAMESPACE_DURATION, TITLE_NAMESPACE_MINUTES_SHORT } from 'helpers/enums/titles'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { getAssessmentTimeToProcess } from 'helpers/assessment/getAssessmentTimeToProcess'

interface IAssessmentSetup {
    uuid: string;
    viewerUuid: string;
    redirectPath: string;
    viewerIsEvaluated: boolean;
    evaluatedIsInitiator: boolean;
    viewerIsCurrentReviewer: boolean;
    isCompleteByRequestingUser: boolean;
    showAnswer: boolean;
    canSetAnswer: boolean;
    canSeeCriteria: boolean;
    canAddComment: boolean;
    showComment: boolean;
    showCriteria: boolean
    isValidDateEnd: boolean;
    isValidFinishedAt: boolean;
    createdStatus: boolean;
    estimationStatus: boolean;
    confirmationStatus: boolean;
    summarizingStatus: boolean;
    awaitCompleteStatus: boolean;
    completeStatus: boolean;
    noDataStatus: boolean;

    showProgress: boolean;
    isPaused: boolean;
    disableSaveResults: boolean;

    allUncompleted: boolean;
    gtHalfUncompleted: boolean;

    isExpire: boolean;
    positionTitle: string | undefined;
    durationTitle: string;
}

interface IAssessmentState {
    assessment: IAssessment | null;
    assessmentSetup: IAssessmentSetup | null;
    competencies: Record<string, IAssessmentCompetence>;
    competenceIndicatorUuids: Record<string, string[]>
    competenceSkillLevel: Record<string, IAssessmentSkillLevel>
    competenceSkillLevelUuids: Record<string, string[]>
    competenceSkillLevelIndicatorUuids: Record<string, string[]>

    indicators: Record<string, IAssessmentIndicator>;


    uncompletedIndicatorUuids: string[];
    answers: Array<IEntity & { description: string; weight: number; }>;
}

const initialState: IState<IAssessmentState> = {
    data: {
        assessment: null,
        assessmentSetup: null,
        competencies: {},
        competenceSkillLevel: {},
        indicators: {},

        competenceIndicatorUuids: {},
        competenceSkillLevelUuids: {},
        competenceSkillLevelIndicatorUuids: {},

        uncompletedIndicatorUuids: [],
        answers: [],
    },
    isLoading: false,
    error: null,
}

const prepareAssessmentSetup = (
    assessment: IAssessment,
    userUuid: string,
    answers: Array<IEntity & { description: string; weight: number }>,
): IAssessmentSetup => {
    const uuid = assessment.uuid
    const assessmentStatus = getAssessmentStatus(assessment?.statusUuid)
    const createdStatus = assessmentStatus?.code === 'created'
    const estimationStatus = assessmentStatus?.code === 'estimation'
    const confirmationStatus = assessmentStatus?.code === 'confirmation'
    const summarizingStatus = assessmentStatus?.code === 'summarizing'
    const completeStatus = assessmentStatus?.code === 'complete'
    const awaitCompleteStatus = assessmentStatus?.code === 'awaitComplete'
    const noDataStatus = assessmentStatus?.code === 'noData'
    const answerDontKnow = answers.find(el => el.weight < 0)

    const evaluatedIsInitiator = assessment?.initiator?.uuid === assessment?.evaluated?.uuid
    const viewerIsEvaluated = assessment?.evaluated?.uuid === userUuid
    const viewerIsCurrentReviewer = Boolean(assessment?.currentReviewer?.find(el => el.uuid === userUuid))

    const canSetAnswer = !assessment?.isCompleteByRequestingUser && (summarizingStatus
        || noDataStatus
        || estimationStatus
        || awaitCompleteStatus
        || completeStatus)

    const canAddComment = canSetAnswer

    const showAnswer = canSetAnswer
    const showComment = canSetAnswer
    const showProgress = canSetAnswer

    const showCriteria = viewerIsEvaluated && evaluatedIsInitiator || completeStatus

    const isCompleteByRequestingUser = assessment.isCompleteByRequestingUser

    const isValidDateEnd = isValidDateFromApi(assessment?.endedAt)
    const isValidFinishedAt = isValidDateFromApi(assessment.finishedAt)

    const redirectPath = completeStatus
        ? `${PATH_ASSESSMENT}/${assessment?.uuid}/result`
        : viewerIsEvaluated
            ? `${PATH_ASSESSMENT}/${assessment?.uuid}`
            : PATH_ASSESSMENT

    const canSeeCriteria = viewerIsEvaluated && evaluatedIsInitiator || completeStatus

    const isPaused = summarizingStatus
    const disableSaveResults = assessment?.isCompleteByRequestingUser
        || isPaused
        || !canSetAnswer

    let uncompletedCount = 0
    let allCounts = 0

    assessment.competencies.forEach(competence => {
        if (!answerDontKnow) return
        competence.indicators.forEach(indicator => {
            allCounts++
            uncompletedCount += indicator.finalAnswerUuid === answerDontKnow.uuid ? 1 : 0
        })
    })

    const allUncompleted = uncompletedCount === allCounts && allCounts !== 0
    const gtHalfUncompleted = allCounts / 2 <= uncompletedCount

    const isExpire = isValidDateEnd && assessment && !completeStatus && isPast(addDays(parse(
        assessment?.endedAt,
        'dd.MM.yyyy',
        new Date(),
    ), 1))

    const evaluatedSpecializationUuid = assessment?.evaluated?.currentPosition?.specializationUuid
    const evaluatedGradeUuid = assessment?.evaluated?.currentPosition?.gradeUuid

    const assessmentPointPositionTitle = evaluatedSpecializationUuid === assessment?.position?.specializationUuid
        && evaluatedGradeUuid === assessment?.position?.gradeUuid
        && `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || assessment?.position?.title

    const positionTitle = assessment?.cpa?.criteria?.uuid === cpaCriteriaCurrentUuid
        ? `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        : assessmentPointPositionTitle || assessment?.cpa?.criteria.title

    const minutes = getAssessmentTimeToProcess(assessment?.indicatorsCount)
    const durationTitle = `${TITLE_NAMESPACE_DURATION} ${minutes} ${TITLE_NAMESPACE_MINUTES_SHORT}`

    return {
        uuid,
        canSeeCriteria,
        redirectPath,
        viewerUuid: userUuid,
        canAddComment,
        showComment,
        showAnswer,
        canSetAnswer,
        awaitCompleteStatus,
        evaluatedIsInitiator,
        showCriteria,
        isCompleteByRequestingUser,
        viewerIsCurrentReviewer,
        viewerIsEvaluated,
        isValidDateEnd,
        isValidFinishedAt,
        createdStatus,
        estimationStatus,
        confirmationStatus,
        summarizingStatus,
        completeStatus,
        showProgress,
        isPaused,
        disableSaveResults,
        allUncompleted,
        gtHalfUncompleted,
        noDataStatus,
        isExpire,
        positionTitle,
        durationTitle,
    }
}

const parseAssessmentToState = (assessment: IAssessment) => {
    const competencies = new Map<string, IAssessmentCompetence>()
    const competenceSkillLevel = new Map<string, IAssessmentSkillLevel>()
    const indicators = new Map<string, IAssessmentIndicator>()

    const competenceIndicatorUuids = new Map<string, string[]>()
    const competenceSkillLevelUuids = new Map<string, string[]>()
    const competenceSkillLevelIndicatorUuids = new Map<string, string[]>()

    const uncompletedIndicatorUuids = new Set<string>()

    assessment.competencies.forEach(competence => {
        competencies.set(competence.uuid, competence)

        competence.skillLevels.forEach(skillLevel => {
            competenceSkillLevel.set(`${competence.uuid}-${skillLevel.uuid}`, skillLevel)

            if (competenceSkillLevelUuids.has(competence.uuid)) {
                competenceSkillLevelUuids.get(competence.uuid)?.push(skillLevel.uuid)
            } else {
                competenceSkillLevelUuids.set(competence.uuid, [skillLevel.uuid])
            }
        })

        competence.indicators.forEach(indicator => {
            indicators.set(indicator.uuid, indicator)

            if (competenceIndicatorUuids.has(competence.uuid)) {
                competenceIndicatorUuids.get(competence.uuid)?.push(indicator.uuid)
            } else {
                competenceIndicatorUuids.set(competence.uuid, [indicator.uuid])
            }
            const competenceSkillLevelIndicatorKey = `${competence.uuid}-${indicator.skillLevelUuid}`

            if (competenceSkillLevelIndicatorUuids.has(competenceSkillLevelIndicatorKey)) {
                competenceSkillLevelIndicatorUuids.get(competenceSkillLevelIndicatorKey)?.push(indicator.uuid)
            } else {
                competenceSkillLevelIndicatorUuids.set(competenceSkillLevelIndicatorKey, [indicator.uuid])
            }
            if (!indicator.finalAnswerUuid)
                uncompletedIndicatorUuids.add(indicator.uuid)
        })
    })
    return {
        competencies: Object.fromEntries(competencies),
        competenceSkillLevel: Object.fromEntries(competenceSkillLevel),
        competenceIndicatorUuids: Object.fromEntries(competenceIndicatorUuids),
        competenceSkillLevelUuids: Object.fromEntries(competenceSkillLevelUuids),
        competenceSkillLevelIndicatorUuids: Object.fromEntries(competenceSkillLevelIndicatorUuids),
        indicators: Object.fromEntries(indicators),
        uncompletedIndicatorUuids: Array.from(uncompletedIndicatorUuids),
    }
}

export const MyAssessmentPassSlice = createSlice({
    name: 'MyAssessmentPass',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        changeStatus: (state, action: PayloadAction<IAssessmentChangeStatusRequest & { finishedAt?: string }>) => {
            const { finishedAt, statusUuid } = action.payload

            if (!state.data.assessment) return

            state.data.assessment.statusUuid = statusUuid
            state.data.assessment.finishedAt = finishedAt ?? state.data.assessment.finishedAt

            if (!state.data.assessmentSetup) return

            state.data.assessmentSetup = prepareAssessmentSetup(
                state.data.assessment,
                state.data.assessmentSetup.viewerUuid,
                state.data.answers,
            )
        },

        setAssessment: (state, action: PayloadAction<{ data: IAssessment; userUuid: string }>) => {
            state.data.assessment = action.payload.data
            const {
                indicators,
                competencies,
                competenceSkillLevelIndicatorUuids,
                competenceIndicatorUuids,
                uncompletedIndicatorUuids,
                competenceSkillLevelUuids,
                competenceSkillLevel,
            } = parseAssessmentToState(action.payload.data)
            state.data.assessmentSetup = prepareAssessmentSetup(
                state.data.assessment,
                action.payload.userUuid,
                state.data.answers,
            )

            state.data.competencies = competencies
            state.data.competenceSkillLevel = competenceSkillLevel
            state.data.indicators = indicators
            state.data.competenceSkillLevelIndicatorUuids = competenceSkillLevelIndicatorUuids
            state.data.competenceIndicatorUuids = competenceIndicatorUuids
            state.data.uncompletedIndicatorUuids = uncompletedIndicatorUuids
            state.data.competenceSkillLevelUuids = competenceSkillLevelUuids
        },

        setAssessmentAnswers: (
            state,
            action: PayloadAction<Array<IEntity & { description: string; weight: number }>>,
        ) => {
            state.data.answers = action.payload
        },

        setAnswer: (
            state,
            action: PayloadAction<IAssessmentSetAnswerRequest & { score: number, skillLevelScore: number }>,
        ) => {
            const { competenceUuid, indicatorUuid, answerUuid, skillLevelUuid, skillLevelScore, score } = action.payload
            state.data.indicators[indicatorUuid].finalAnswerUuid = answerUuid
            state.data.indicators[indicatorUuid].error = false
            const finalAnswer = state.data.indicators[indicatorUuid].answers.find(answer => answer.respondentUuid === 'final')
            if (finalAnswer)
                finalAnswer.isCalibrated = true
            else
                state.data.indicators[indicatorUuid].answers.push({
                    isCalibrated: true,
                    isCalculated: true,
                    uuid: answerUuid,
                    role: { uuid: 'final', divergent: false },
                    respondentUuid: 'final',
                })

            // set  final answer calibrated
            state.data.indicators[indicatorUuid].answers.some(answer => {
                if (answer.respondentUuid !== 'final') return false

                // eslint-disable-next-line no-param-reassign
                answer.isCalibrated = true
                return true
            })

            state.data.competencies[competenceUuid].score = score

            state.data.uncompletedIndicatorUuids = state
                .data
                .uncompletedIndicatorUuids
                .filter(el => el !== indicatorUuid)

            state.data.competenceSkillLevel[`${competenceUuid}-${skillLevelUuid}`].score = skillLevelScore
        },

        checkAnswers: (state) => {
            if (state.data.uncompletedIndicatorUuids.length <= 0) return

            state.data.uncompletedIndicatorUuids.forEach(uuid => {
                state.data.indicators[uuid].error = true
            })
        },

        assessmentIndicatorCommentCreate: (
            state,
            action: PayloadAction<IAssessmentIndicatorCommentCreateUpdate & { uuid: string }>,
        ) => {
            const { indicatorUuid, ...comment } = action.payload
            state.data.indicators[indicatorUuid].comments.push(comment)
        },
        assessmentIndicatorCommentUpdate: (
            state,
            action: PayloadAction<IAssessmentIndicatorCommentCreateUpdate>,
        ) => {
            const { indicatorUuid, ...newComment } = action.payload
            state.data.indicators[indicatorUuid].comments =
                state.data.indicators[indicatorUuid].comments.map(comment => {
                    if (comment.uuid !== newComment.uuid) return comment
                    return {
                        ...comment,
                        ...newComment,
                    }
                })
        },
        assessmentIndicatorCommentDelete: (state, action: PayloadAction<IAssessmentIndicatorCommentDelete>) => {
            const { indicatorUuid, uuid } = action.payload
            state.data.indicators[indicatorUuid].comments = state
                .data
                .indicators[indicatorUuid]
                .comments
                .filter(comment => comment.uuid !== uuid)
        },

        assessmentCommentCreate: (
            state,
            action: PayloadAction<IAssessmentCommentCreateUpdate & { uuid: string }>,
        ) => {
            if (!state.data.assessment) return
            state.data.assessment.comments.push(action.payload)
        },
        assessmentCommentUpdate: (
            state,
            action: PayloadAction<IAssessmentCommentCreateUpdate>,
        ) => {
            if (!state.data.assessment) return
            state.data.assessment.comments = state.data.assessment?.comments.map(comment => {
                if (comment.uuid !== action.payload.uuid) return comment
                return {
                    ...comment,
                    ...action.payload,
                }
            })
        },
        assessmentCommentDelete: (state, action: PayloadAction<IAssessmentCommentDelete>) => {
            if (!state.data.assessment) return
            state.data.assessment.comments = state
                .data
                .assessment
                .comments
                .filter(comment => comment.uuid !== action.payload.uuid)
        },

        setSummarizing: (state, action: PayloadAction<{ userUuid: string; userName: string }>) => {
            const { userUuid, userName } = action.payload

            if (!state.data.assessment) return

            state.data.assessment.statusUuid = assessmentStatusSummarizing
            state.data.assessment.currentReviewer.push({ uuid: userUuid, name: userName })

            state.data.assessmentSetup = prepareAssessmentSetup(
                state.data.assessment,
                userUuid,
                state.data.answers,
            )
        },
        cancelSummarizing: (state) => {
            if (!state.data.assessment) return

            state.data.assessment.statusUuid = assessmentStatusAwaitingConfirmation
            state.data.assessment.currentReviewer = []

            state.data.assessmentSetup = prepareAssessmentSetup(
                state.data.assessment,
                state.data.assessmentSetup?.viewerUuid || '',
                state.data.answers,
            )
        },
        saveSummarizing: (state) => {
            if (!state.data.assessment) return

            state.data.assessment.statusUuid = assessmentStatusAwaitComplete
            state.data.assessment.currentReviewer = []

            state.data.assessmentSetup = prepareAssessmentSetup(
                state.data.assessment,
                state.data.assessmentSetup?.viewerUuid || '',
                state.data.answers,
            )
        },
    },
})

export const MyAssessmentPassReducer = MyAssessmentPassSlice.reducer
