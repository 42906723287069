import {
    TITLE_NAMESPACE_CURRENT_POSITION_EMPLOYEES,
    TITLE_NAMESPACE_INDIVIDUAL_COMPETENCIES,
    TITLE_NAMESPACE_POINT_POSITION,
} from 'helpers/enums/titles'

export const cpaCriteriaCurrentUuid = '6790a2ac-2f35-46ae-816a-38e6e68ea672' // ,Текущие позиции сотрудников
export const cpaCriteriaPointUuid = '3414c174-be38-4224-a68b-035752bc305a' // ,Целевая позиция
export const cpaCriteriaCompetenceUuid = '77643e8b-264f-49b0-ba34-6a63bad91117' // ,Отдельные компетенции

export const cpaCriteriaUuidsEntity = [
    {
        uuid: cpaCriteriaCurrentUuid,
        title: TITLE_NAMESPACE_CURRENT_POSITION_EMPLOYEES,
    },
    {
        uuid: cpaCriteriaPointUuid,
        title: TITLE_NAMESPACE_POINT_POSITION,
    },
    {
        uuid: cpaCriteriaCompetenceUuid,
        title: TITLE_NAMESPACE_INDIVIDUAL_COMPETENCIES,
    },
]

interface ICPACriteria extends IEntity {
    code: 'current' | 'point' | 'competence',
}

export const getCpaCriteria = (criteriaUuid?: string): ICPACriteria | null => {
    switch (criteriaUuid) {
        case cpaCriteriaCurrentUuid: {
            return {
                uuid: cpaCriteriaCurrentUuid,
                code: 'current',
                title: TITLE_NAMESPACE_CURRENT_POSITION_EMPLOYEES,
            }
        }
        case cpaCriteriaPointUuid: {
            return {
                uuid: cpaCriteriaPointUuid,
                code: 'point',
                title: TITLE_NAMESPACE_POINT_POSITION,
            }
        }
        case cpaCriteriaCompetenceUuid: {
            return {
                uuid: cpaCriteriaCompetenceUuid,
                code: 'competence',
                title: TITLE_NAMESPACE_INDIVIDUAL_COMPETENCIES,
            }
        }
        default: {
            return null
        }
    }
}
