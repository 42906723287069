import React, { FC } from 'react'
import { getCpaCriteria } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import {
    CPACriteriaDrawerItemPoint,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemPoint/CPACriteriaDrawerItemPoint'
import {
    CPACriteriaDrawerItemCurrent,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCurrent/CPACriteriaDrawerItemCurrent'
import {
    CPACriteriaDrawerItemCompetence,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/CPACriteriaDrawerItemCompetence'

type CPACriteriaDrawerItemProps = {
    currentCriteriaUuid: string;
    handleClose: () => void;
}

export const CPACriteriaDrawerItem: FC<CPACriteriaDrawerItemProps> = ({ currentCriteriaUuid, handleClose }) => {
    const criteriaCode = getCpaCriteria(currentCriteriaUuid)
    switch (criteriaCode?.code) {
        case 'current':
            return <CPACriteriaDrawerItemCurrent handleClose={handleClose}/>
        case 'point':
            return <CPACriteriaDrawerItemPoint handleClose={handleClose}/>
        case 'competence':
            return <CPACriteriaDrawerItemCompetence handleClose={handleClose}/>
        default:
            return null
    }
}
