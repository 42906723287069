import React, { FC } from 'react'
import { Chip, ChipProps } from '@mui/material'
import { getSkillLevelColor } from 'helpers/skillLevel/getSkillLevelColor'

type ChipSkillLevelProps = {
    skillLevelTitle: string | undefined;
}

export const ChipSkillLevel: FC<ChipSkillLevelProps & ChipProps> = ({ skillLevelTitle, ...props }) => {
    if (!skillLevelTitle) return null
    return (
        <Chip
            size="small"
            label={skillLevelTitle}
            sx={{ backgroundColor: getSkillLevelColor(skillLevelTitle) }}
            {...props}
        />
    )
}
