import React, { FC, ReactNode } from 'react'
import { IExamAnswerData } from '../../../api/typing/exam'
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Theme, useTheme } from '@mui/material'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { ExamAnswerLabel } from '../ExamAnswerLabel/ExamAnswerLabel'
import { SxProps } from '@mui/system'

interface ExamManyFromListAnswerProps {
    answers: IExamAnswerData[];
    selectedAnswers: string[];
    isPreviewMode?: boolean;
    disableRipple?: boolean;
    showCorrectAnswer?: boolean;
    disabled?: boolean;
    checkedIcon?: ReactNode;
    inputProps?: ControllerRenderProps<FieldValues, string>;
    formControlLabelStyles?: SxProps<Theme>;
    openImageDialog: (value: string) => void;
    handleChange?: (value: string) => string[];
}

export const ExamManyFromListAnswer: FC<ExamManyFromListAnswerProps> = ({
    answers,
    selectedAnswers,
    isPreviewMode = false,
    disableRipple = false,
    showCorrectAnswer = false,
    disabled = false,
    checkedIcon,
    inputProps,
    formControlLabelStyles,
    openImageDialog,
    handleChange,
}) => {
    const theme = useTheme()
    return (
        <FormGroup sx={{ gap: 2 }}>
            {answers.map(
                answer => (
                    <Stack
                        key={answer.uuid}
                        direction='row'
                        alignItems='center'
                    >
                        <Box>
                            <FormControlLabel
                                label={<ExamAnswerLabel
                                    answer={answer}
                                    openImageDialog={openImageDialog}
                                    showCorrectAnswer={showCorrectAnswer}
                                />
                                }
                                control={
                                    <Checkbox
                                        {...inputProps}
                                        id={answer.uuid}
                                        value={answer.uuid}
                                        checked={selectedAnswers.includes(answer.uuid) && !isPreviewMode}
                                        disabled={disabled}
                                        disableRipple={disableRipple}
                                        checkedIcon={checkedIcon}
                                        onChange={(e) => {
                                            inputProps ? inputProps.onChange(e) : null
                                            handleChange ? handleChange(e.target.value): null
                                        }}
                                        sx={showCorrectAnswer ?
                                            answer.isAnswered ?
                                                {
                                                    color: answer.isCorrect ?
                                                        theme.palette.success.main :
                                                        theme.palette.error.main,
                                                    '&.Mui-checked': {
                                                        color: answer.isCorrect ?
                                                            theme.palette.success.main :
                                                            theme.palette.error.main,
                                                    },
                                                }
                                                :
                                                {
                                                    color: `${theme.palette.text.disabled} !important`,
                                                }
                                            :
                                            {}
                                        }
                                    />
                                }
                                sx={{
                                    alignItems: answer.title.length && !answer.imgLink.length ? 'center' : 'flex-start',
                                    ...formControlLabelStyles,
                                }}
                            />
                        </Box>
                    </Stack>
                ))
            }
        </FormGroup>
    )
}
