import React, { FC, useState } from 'react'
import { ChevronRight, StarRounded } from '@mui/icons-material'
import { Card, CardActionArea, CardContent, Chip, Stack, Typography } from '@mui/material'
import {
    CPAAnalyticContentEmployeesDrawer,
} from '../CPAAnalyticContentEmployeesDrawer/CPAAnalyticContentEmployeesDrawer'
import { TITLE_NAMESPACE_NO } from 'helpers/enums/titles'

type CPAAnalyticContentEmployeesBestProps = {
    employees?: TAnalyticCpaStatisticSummaryEmployee[];
}

export const CPAAnalyticContentEmployeesBest: FC<CPAAnalyticContentEmployeesBestProps> = ({ employees }) => {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return (
        <Card sx={{ flex: 1, display: 'flex', alignItems: 'stretch', height: 'auto' }}>
            <CardActionArea onClick={handleOpen} disabled={!employees || !employees.length}>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack direction="row" alignItems="center" spacing={1} mr={2}>
                        <StarRounded color={!employees?.length ? 'disabled' : 'success'} />
                        <Typography variant="body2">
                            Лучшие сотрудники
                        </Typography>
                        <Chip
                            variant="outlined"
                            color={!employees?.length ? 'default' : 'success'}
                            size="small"
                            label={!employees?.length ? TITLE_NAMESPACE_NO : employees.length}
                        />
                    </Stack>
                    <ChevronRight color={!employees?.length ? 'disabled' : 'action'} />
                </CardContent>
            </CardActionArea>
            <CPAAnalyticContentEmployeesDrawer
                alertIcon={<StarRounded />}
                alertText="Эти сотрудники отлично владеют требуемыми компетенциями"
                employees={employees}
                handleClose={handleClose}
                open={open}
                severity="success"
                title="Лучшие сотрудники"
            />
        </Card>
    )
}
