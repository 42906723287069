import React from 'react'
import { useCpaCriteriaCompetence } from 'api/queries/cpa/cpaQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { Stack } from '@mui/material'
import {
    CPACriteriaCompetenceItem,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaContent/components/CPACriteriaCompetence/components/CPACriteriaCompetenceItem/CPACriteriaCompetenceItem'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useParams } from 'react-router-dom'

export const CPACriteriaCompetence = () => {
    const { cpaUuid } = useParams()
    const { isLoading, data, error } = useCpaCriteriaCompetence(cpaUuid || '')

    if (isLoading) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    return (
        <Stack spacing={6}>
            {data?.map(el => (<CPACriteriaCompetenceItem
                key={el.uuid}
                item={el}
            />))}
        </Stack>
    )
}
