import React, { FC, useState } from 'react'
import { DrawerCommentsAssessment } from 'components/drawers/DrawerCommentsAssessment/DrawerCommentsAssessment'
import { IconButton } from '@mui/material'
import { CommentOutlined } from '@mui/icons-material'

type AssessmentResultTreeItemCommentsMobileProps = {
    competence: IAssessmentCompetenceGroupCompetence;
}

export const AssessmentResultTreeItemCommentsMobile: FC<AssessmentResultTreeItemCommentsMobileProps> = ({
    competence,
}) => {
    const indicatorsComments = competence.indicators.filter(el => el.comments.length > 0)
    const [open, setOpen] = useState(false)

    if (!indicatorsComments?.length) return null

    return (
        <>
            <DrawerCommentsAssessment
                open={open}
                handleClose={() => setOpen(false)}
                skillLevelTitle={competence.skillLevelTitle}
                competenceTypeTitle={competence.typeTitle}
                competenceTitle={competence.title}
                indicators={indicatorsComments}
            />
            <IconButton
                color="primary"
                size="small"
                onClick={() => setOpen(true)}
            >
                <CommentOutlined/>
            </IconButton>
        </>
    )
}
