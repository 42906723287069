import React, { FC, useEffect, useMemo } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    InputLabel,
    ListSubheader,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useFormGrades, useFormSpecializations } from 'api/queries/form/formQueries'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CLEAR,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_GRADE,
    TITLE_NAMESPACE_ALL_GRADES,
    TITLE_NAMESPACE_SPECIALIZATION,
} from 'helpers/enums/titles'
import { HINT_CHOOSE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { useNotify } from 'store/hooks/useNotify'
import { cpaItemCriteriaActions } from 'store/actions'
import { ChevronRightRounded, ClearRounded, MoreHorizRounded } from '@mui/icons-material'
import { emptyUuid } from 'helpers/isEmptyUuid'
import { useCpaCriteriaPoint } from 'api/queries/cpa/cpaQueries'
import { cpaCriteriaPointUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { useParams } from 'react-router-dom'

type FormValues = {
    specialization: IFormSpecialization | null;
    gradeUuid: string
}

type CPACriteriaDrawerItemPointProps = {
    handleClose: () => void;
}

export const CPACriteriaDrawerItemPoint: FC<CPACriteriaDrawerItemPointProps> = ({ handleClose }) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { cpaUuid } = useParams()
    const { refetch } = useCpaCriteriaPoint(cpaUuid || '', false)
    const currentPosition = useAppSelector(state => state.CPAItemCriteriaReducer.pointCriteria)
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const { data: specializationList } = useFormSpecializations()
    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            specialization: specializationList?.find(el => el.uuid === currentPosition?.specialization?.uuid) || null,//currentPosition?.specialization?.uuid ?? '',
            gradeUuid: currentPosition?.grade?.uuid ?? '',
        },
    })
    const watchSpecialization = watch('specialization')
    const watchGrade = watch('gradeUuid')
    const disableSaveButton = !watchGrade
        || !watchSpecialization
        || currentPosition?.specialization?.uuid == watchSpecialization.uuid
        && currentPosition?.grade?.uuid == watchGrade

    const { data: gradeList, refetch: refetchGradeList } = useFormGrades(watchSpecialization?.uuid || '')

    const gradeListWithAllGrades = useMemo(
        () => gradeList ? [...gradeList, { uuid: emptyUuid, title: TITLE_NAMESPACE_ALL_GRADES }] : [],
        [gradeList],
    )

    useEffect(() => {
        if (watchSpecialization?.uuid)
            refetchGradeList().catch(e => e)
    }, [watchSpecialization])

    useEffect(() => {
        reset({
            specialization: specializationList?.find(el => el.uuid === currentPosition?.specialization?.uuid) || null,//currentPosition?.specialization?.uuid ?? '',
            gradeUuid: currentPosition?.grade?.uuid ?? '',
        })
    }, [specializationList])


    const onClearForm = () => {
        reset({
            specialization: null,
            gradeUuid: '',
        })
    }

    const onSubmit = async (payload: FormValues) => {
        if (!cpaUuid) return
        try {
            await dispatch(cpaItemCriteriaActions.saveCriteriaPoint({
                cpaUuid,
                specializationUuid: payload.specialization?.uuid || '',
                gradeUuid: payload.gradeUuid,
            }))
            currentCriteriaUuid === cpaCriteriaPointUuid && await refetch()
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <Box flex={1} mb={2}>
                <Card>
                    <CardContent>
                        <Stack spacing={2}>
                            <Box>
                                <InputLabel htmlFor="specialization" sx={{ mb: 0.5 }}>
                                    {TITLE_NAMESPACE_SPECIALIZATION} *
                                </InputLabel>
                                <Controller
                                    name="specialization"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            id="specialization"
                                            options={specializationList || []}
                                            getOptionLabel={(option) => option.title}
                                            filterSelectedOptions
                                            isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
                                            groupBy={option => option.parentDivisionTitle}
                                            onChange={(e, d) => field.onChange(d)}
                                            renderGroup={({ group, key, children }) => {
                                                const currentDivision =
                                                    specializationList?.find(el =>
                                                        el.parentDivisionTitle === group)
                                                return (
                                                    <div key={key}>
                                                        <ListSubheader sx={{ position: 'sticky', top: '-8px' }}>
                                                            <Tooltip
                                                                title={currentDivision?.fullPath || ''}
                                                                placement="bottom-start"
                                                                PopperProps={{
                                                                    sx: {
                                                                        '& .MuiTooltip-tooltip': {
                                                                            // TODO: Need fix. Need important to override custom slyles
                                                                            margin: '-4px !important',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    <MoreHorizRounded
                                                                        color="disabled"
                                                                        fontSize="small"
                                                                    />
                                                                    <ChevronRightRounded
                                                                        color="disabled"
                                                                        fontSize="small"
                                                                        sx={{ mr: 0.5 }}
                                                                    />
                                                                    {group}
                                                                </Box>
                                                            </Tooltip>
                                                        </ListSubheader>
                                                        {children}
                                                    </div>
                                                )
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} value={option.title}>
                                                    <Typography
                                                        variant="body2"
                                                        ml={7}
                                                    >
                                                        {option.title}
                                                    </Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    title={TITLE_NAMESPACE_SPECIALIZATION}
                                                    placeholder={HINT_CHOOSE}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>
                            <Controller
                                name="gradeUuid"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                defaultValue={currentPosition?.grade?.uuid ?? ''}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="gradeUuid"
                                        name="gradeUuid"
                                        values={gradeListWithAllGrades}
                                        required
                                        disabled={!watchSpecialization}
                                        title={TITLE_FIELD_GRADE}
                                        placeholder={HINT_CHOOSE}
                                        error={!!errors.gradeUuid}
                                        helperText={errors?.gradeUuid ? errors.gradeUuid.message : null}
                                    />
                                )}
                            />
                            <Button
                                variant="text"
                                startIcon={<ClearRounded/>}
                                onClick={onClearForm}
                                sx={{ alignSelf: 'flex-start' }}
                            >
                                {TITLE_BUTTON_CLEAR}
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    variant="text"
                    onClick={handleClose}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={disableSaveButton}
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Box>
    )
}
