import React, { useMemo, useState } from 'react'
import { Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { HowToRegRounded, PlaylistAddCheckRounded } from '@mui/icons-material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_COMPLETE,
    TITLE_BUTTON_END_ASSESSMENT,
    TITLE_BUTTON_GO_TO_APPROVE,
} from 'helpers/enums/titles'
import { cpaItemActions } from 'store/actions'
import { cpaStatusApproval, cpaStatusComplete } from 'helpers/cpa/cpaStatuses'
import { useNotify } from 'store/hooks/useNotify'
import { useCpaItem } from 'api/queries/cpa/cpaQueries'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

type DialogContent = {
    title: string;
    text: string;
    titleButtonConfirm: string;
    helperText?: string;
}

export const CPAMetaProgressButton = () => {
    const [dialogConfirmApproval, setDialogConfirmApproval] = useState(false)
    const [dialogConfirmComplete, setDialogConfirmComplete] = useState(false)
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { uuid, firstName, lastName } = useAppSelector(state => state.userReducer.data)
    const meta = useAppSelector(state => state.CPAItemReducer.meta)
    const { refetch: refetchCpaMeta } = useCpaItem(meta.uuid, false)
    const divisions = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)

    const dialogContent = useMemo(() => {
        const setup = {
            noData: false,
            hasSomeNotPassed: false,
            someStatusEstimation: false,
        }
        divisions.forEach(division => division.employees.forEach(employee => {
            const assessmentStatusCode = getAssessmentStatus(employee.assessment?.statusUuid)?.code
            setup.noData = setup.noData
                || !employee.assessment?.isCompleted && employee.participants.every(el => !el.isCompleted)
            setup.hasSomeNotPassed = setup.hasSomeNotPassed
                || !employee.assessment?.isCompleted || employee.participants.some(el => !el.isCompleted)
            setup.someStatusEstimation = setup.someStatusEstimation
                || assessmentStatusCode === 'confirmation'
                || assessmentStatusCode === 'summarizing'
        }))

        const approvalDialogContent: DialogContent = {
            title: 'Завершение оценки сотрудников',
            text: 'Вы уверены, что хотите завершить оценку для сотрудников?',
            titleButtonConfirm: TITLE_BUTTON_COMPLETE,
        }
        const progressDialogContent: DialogContent = {
            title: 'Переход к утверждению оценок',
            text: 'Вы уверены, что хотите приступить к подведению итогов оценки?',
            titleButtonConfirm: 'К утверждению',
        }

        switch (true) {
            case setup.noData: {
                approvalDialogContent.helperText = 'Еще не по всем оцениваемым сотрудникам есть данные для формирования результатов.'
                break
            }
            case setup.hasSomeNotPassed: {
                approvalDialogContent.helperText = 'Еще не все участники успели оценить сотрудников.'
                break
            }
            case setup.someStatusEstimation: {
                approvalDialogContent.helperText = 'Еще не по всем оцениваемым сотрудникам были подведены итоги.'
                break
            }
        }
        switch (true) {
            case setup.noData: {
                progressDialogContent.helperText = 'Еще не по всем оцениваемым сотрудникам есть данные для формирования результатов оценки.'
                break
            }
            case setup.hasSomeNotPassed: {
                progressDialogContent.helperText = 'Не все участники успели оценить сотрудников.'
                break
            }
        }
        return { approvalDialogContent, progressDialogContent }
    }, [divisions, cpaStatusCode])

    const sendToApproval = async () => {
        try {
            await dispatch(cpaItemActions.changeCpaStatus({
                cpaUuid: meta.uuid,
                cpaStatusUuid: cpaStatusApproval,
                author: {
                    firstName,
                    lastName,
                    uuid,
                },
            }))
            await refetchCpaMeta()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    const sendToComplete = async () => {
        try {
            await dispatch(cpaItemActions.changeCpaStatus({
                cpaUuid: meta.uuid,
                cpaStatusUuid: cpaStatusComplete,
                author: {
                    firstName,
                    lastName,
                    uuid,
                },
            }))
            await refetchCpaMeta()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (cpaStatusCode !== 'progress' && cpaStatusCode !== 'approval') return null

    return (
        <>
            <DialogConfirm
                open={dialogConfirmComplete}
                dialogTitle={dialogContent.approvalDialogContent.title}
                dialogText={dialogContent.approvalDialogContent.text}
                titleButtonConfirm={dialogContent.approvalDialogContent.titleButtonConfirm}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                dialogHelperText={dialogContent.approvalDialogContent.helperText}
                onSubmit={sendToComplete}
                handleClose={() => setDialogConfirmComplete(false)}
            />
            <DialogConfirm
                open={dialogConfirmApproval}
                dialogTitle={dialogContent.progressDialogContent.title}
                dialogText={dialogContent.progressDialogContent.text}
                titleButtonConfirm={dialogContent.progressDialogContent.titleButtonConfirm}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                dialogHelperText={dialogContent.progressDialogContent.helperText}
                onSubmit={sendToApproval}
                handleClose={() => setDialogConfirmApproval(false)}
            />
            {cpaStatusCode === 'approval' && <Button
                sx={{ alignSelf: 'flex-end' }}
                variant="contained"
                startIcon={<PlaylistAddCheckRounded/>}
                onClick={() => setDialogConfirmComplete(true)}
            >
                {TITLE_BUTTON_END_ASSESSMENT}
            </Button>}
            {cpaStatusCode === 'progress' && <Button
                sx={{ alignSelf: 'flex-end' }}
                variant="contained"
                startIcon={<HowToRegRounded/>}
                onClick={() => setDialogConfirmApproval(true)}
            >
                {TITLE_BUTTON_GO_TO_APPROVE}
            </Button>}
        </>
    )
}
