import React, { FC } from 'react'
import { ICPAGetCriteriaCompetenceItem } from 'api/typing/cpaTypes'
import { Box, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemCriteriaActions } from 'store/actions'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import { CloseRounded } from '@mui/icons-material'

type CPACriteriaDrawerItemCompetenceItemProps = {
    item: ICPAGetCriteriaCompetenceItem;
    competenceTypeUuid: string
}

export const CPACriteriaDrawerItemCompetenceItem: FC<CPACriteriaDrawerItemCompetenceItemProps> = ({
    item,
    competenceTypeUuid,
}) => {
    const dispatch = useAppDispatch()
    const changeSkillLevel = (e: SelectChangeEvent<unknown>) => {
        dispatch(cpaItemCriteriaActions.setCriteriaCompetenceItemSkillLevel({
            competenceTypeUuid,
            competenceUuid: item.uuid,
            skillLevelUuid: e.target.value as string,
        }))
    }
    const deleteItem = () => {
        dispatch(cpaItemCriteriaActions.deleteCriteriaCompetenceItem({
            competenceTypeUuid,
            competenceUuid: item.uuid,
        }))
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Box flex={1}>
                <Typography variant="body2">
                    {item.title}
                </Typography>
            </Box>
            <Box>
                <SelectForm
                    id="type"
                    value={item.currentSkillLevelUuid}
                    variant="standard"
                    disableUnderline
                    renderValue={(val: any) => <ChipSkillLevel
                        skillLevelTitle={item.skillLevels?.find(el => el.uuid === val)?.title}
                    />}
                    onChange={changeSkillLevel}
                    values={item.skillLevels}
                />
            </Box>
            <IconButton color="primary" onClick={deleteItem}>
                <CloseRounded/>
            </IconButton>
        </Stack>
    )
}
