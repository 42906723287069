import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Stack } from '@mui/material'
import {
    EmployeeAssessmentCompetence,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/EmployeeAssessmentCompetence'

export const EmployeeAssessmentCompetencies = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)

    return (
        <Stack spacing={8}>
            {assessment?.competencies.map(competence => <EmployeeAssessmentCompetence
                key={competence.uuid}
                competenceUuid={competence.uuid}
            />)}
        </Stack>
    )
}
