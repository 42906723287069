import { AppDispatch } from 'store/store'
import { SpecializationService } from 'api/services/SpecializationService'
import { specializationListSlice } from 'store/reducers/SpecializationListSlice'
import { ISpecializationListOnePayload } from 'api/typing/specializationTypes'
import { specializationSlice } from 'store/reducers/SpecializationSlice'

export class SpecializationListAction {
    private static get specializationListApi() {
        return new SpecializationService().specializationListService()
    }

    public getMetaForCreateOne() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationListSlice.actions.fetching())
                const data = await SpecializationListAction.specializationListApi.getMetaForCreateOne()
                dispatch(specializationListSlice.actions.getMetaForCreateOne(data))
            } catch (e: any) {
                dispatch(specializationListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createOne(payload: ISpecializationListOnePayload, meta: ISpecializationMetaForCreate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationListSlice.actions.fetching())
                void await SpecializationListAction.specializationListApi.createOne(payload)
                await dispatch(specializationListSlice.actions.createOne({ ...payload, meta }))
            } catch (e: any) {
                dispatch(specializationListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateOne(
        payload: ISpecializationListOnePayload,
        specialization: Omit<ISpecializationListSpecialization, 'employeesCount'>,
        divisionsList: ISpecializationMetaForCreateDivision[],
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationListSlice.actions.fetching())
                void await SpecializationListAction.specializationListApi.updateOne(payload)
                await dispatch(specializationListSlice.actions.updateOne({ ...payload, specialization }))
                await dispatch(specializationSlice.actions.editSpecialization({
                    ...payload,
                    divisionsList,
                }))
            } catch (e: any) {
                dispatch(specializationListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteOne(payload: ISpecializationListOnePayload) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationListSlice.actions.fetching())
                void await SpecializationListAction.specializationListApi.deleteOne(payload)
                await dispatch(specializationListSlice.actions.deleteOne(payload))
            } catch (e: any) {
                dispatch(specializationListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getList() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(specializationListSlice.actions.fetching())
                const data = await SpecializationListAction.specializationListApi.getList()
                dispatch(specializationListSlice.actions.getList(data))
            } catch (e: any) {
                dispatch(specializationListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
