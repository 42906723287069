import React, { FC, useState } from 'react'
import { Button, Divider, IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material'
import { Puller } from 'components/common/Puller/Puller'
import { useAppSelector } from 'store/hooks/redux'
import { ArrowForwardRounded, CloseRounded } from '@mui/icons-material'
import { TITLE_BUTTON_ALL_COMPETENCIES, TITLE_NAMESPACE_COMPETENCIES } from 'helpers/enums/titles'
import {
    AssessmentResultTreeItemMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeItemMobile/AssessmentResultTreeItemMobile'

type AssessmentResultTreeShowMoreMobileProps = {
    previewItemsCount: number;
}

export const AssessmentResultTreeShowMoreMobile: FC<AssessmentResultTreeShowMoreMobileProps> = ({
    previewItemsCount,
}) => {
    const competenciesList = useAppSelector(state => state.assessmentResultReducer.data.competenciesFlatList)

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    if (previewItemsCount >= competenciesList.length) return null
    return (
        <>
            <Stack spacing={4}>
                <Typography variant="body1" textAlign="center">
                    Ещё {competenciesList.length - previewItemsCount}
                </Typography>
                <Button
                    variant="outlined"
                    endIcon={<ArrowForwardRounded/>}
                    onClick={handleOpen}
                >
                    {TITLE_BUTTON_ALL_COMPETENCIES}
                </Button>
            </Stack>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                ModalProps={{
                    keepMounted: false,
                }}
                PaperProps={{
                    sx: {
                        height: 'calc(100dvh - 60px)',
                        borderRadius: '16px 16px 0 0',
                    },
                }}
            >
                <Stack
                    role="presentation"
                    sx={{
                        height: '100%',
                        flex: 1,
                        px: 3,
                        pt: 2,
                    }}
                >
                    <Stack spacing={3} flex={1} pb={3}>
                        <Puller/>
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_COMPETENCIES}
                            </Typography>
                            <IconButton color="primary" onClick={handleClose}>
                                <CloseRounded/>
                            </IconButton>
                        </Stack>
                        <Divider/>
                        <Stack spacing={2} divider={<Divider/>}>
                            {competenciesList.map(competence => <AssessmentResultTreeItemMobile
                                key={competence.uuid}
                                competence={competence}
                            />)}
                        </Stack>
                    </Stack>
                </Stack>
            </SwipeableDrawer>
        </>
    )
}
