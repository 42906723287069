import { LoggerFactory } from 'utils/logger'

const logger = LoggerFactory.createLogger()

export class SendMetric {
    private static instance: SendMetric
    private ymId = process.env.REACT_APP_YANDEX_METRIC_ID
    private previousPath = ''
    private actionTypePageView = 'hit'
    private actionTypeReachGoal = 'reachGoal'

    static getInstance() {
        if (!SendMetric.instance)
            SendMetric.instance = new SendMetric()
        return SendMetric.instance
    }

    public sendMetricClick(actionId: string, options?: Record<string, any>) {
        this.sendMetric(this.actionTypeReachGoal, actionId, options)
    }

    public sendMetricScroll(actionId: string, options?: Record<string, any>) {
        this.sendMetric(this.actionTypeReachGoal, actionId, options)
    }

    public sendMetricRoute(pathUrl: string, options?: Record<string, any>) {
        if (this.previousPath === pathUrl) return
        this.previousPath = pathUrl
        this.sendMetric(this.actionTypePageView, pathUrl, options)
    }

    private sendMetric(actionType: string, actionId: string, options?: Record<string, any>) {
        if (process.env.NODE_ENV !== 'production') {
            logger.warn('Metric doesnt send, because is not production mode')
            return
        }
        //@ts-ignore
        window.ym(this.ymId, actionType, actionId, options)
        logger.debug('Send metric type ', actionType, ' and action ', actionId)
    }
}

