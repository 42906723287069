import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMassExamListItem } from '../../../api/typing/massExam'
import { containsText } from '../../../helpers/containsText'

interface IMassExamListState {
    list: IMassExamListItem[];
    filteredList: IMassExamListItem[];
    filterTitle: string;
    filterType: IEntity[];
    filterStatus: IEntity[];
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: IMassExamListState = {
    list: [],
    filteredList: [],
    filterTitle: '',
    filterType: [],
    filterStatus: [],
    isLoading: false,
    error: null,
}

const filterValues = (state: IMassExamListState) => {
    state.filteredList = state.list.filter(item => {
        const acceptTitle = containsText(item.title, state.filterTitle)
        const acceptStatus = !state.filterStatus.length || state.filterStatus.some(el => el.uuid === item.statusUuid)
        return acceptTitle && acceptStatus
    })
}

export const massExamListSlice = createSlice({
    name: 'massExamList',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        setList: (state, action: PayloadAction<IMassExamListItem[]>) => {
            state.list = action.payload
            filterValues(state)
        },
        setTitleFilter: (state, action: PayloadAction<string>) => {
            state.filterTitle = action.payload
            filterValues(state)
        },
        setStatusFilter: (state, action: PayloadAction<IEntity[]>) => {
            state.filterStatus = action.payload
            filterValues(state)
        },
    },
})

export const massExamListSliceReducer = massExamListSlice.reducer
