import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { IAssessmentResultSetup } from 'helpers/assessment/resultStateFunction'
import { Stack } from '@mui/material'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'

const getResultAlertText = (
    assSetup: IAssessmentResultSetup | null,
    // assType: CpaTypeCode | null,
) => {
    // const is360 = assType === '360'
    switch (true) {
        case assSetup?.estimationStatus && !assSetup.isAllComplete: {
            return 'Результат сформирован на основе ответов участников, которые завершили оценку на текущий момент.'
        }
        case assSetup?.confirmationStatus && !assSetup.isAllComplete: {
            return 'Результат сформирован на основе ответов участников,которые завершили оценку на текущий момент. Вы можете внести корректировки, если видите недочеты в ответах, или согласовать оценку сразу.'
        }
        case assSetup?.confirmationStatus && assSetup.isAllComplete: {
            return 'Результат сформирован на основе ответов участников. Вы можете внести корректировки, если видите недочеты в ответах, или согласовать оценку сразу.'
        }
        case assSetup?.summarizingStatus && !assSetup.viewerIsCurrentReviewer: {
            return 'В процессе подведения итогов. Пока вы можете ознакомиться с предварительными результатами.'
        }
        case assSetup?.awaitCompeteStatus && !assSetup.isAllComplete: {
            return 'Итоги были подведены на основе ответов участников, которые успели оценить сотрудника. Ответы больше не принимаются. Вы можете завершить оценку.'
        }
        case assSetup?.awaitCompeteStatus && assSetup.isAllComplete: {
            return 'Итоги были подведены на основе ответов участников. Вы можете завершить оценку.'
        }
        case assSetup?.completeStatus && !assSetup.isAllComplete && !assSetup.isSelfAssessment: {
            return 'Не все участники смогли оценить сотрудника. Результат сформирован на основе ответов участников, которые успели завершить оценку.'
        }
        default: {
            return ''
        }
    }
}

const getDivergentAlertText = (resultSetup: IAssessmentResultSetup | null) => {
    switch (true) {
        case resultSetup?.someCompetenceHasDivergent && resultSetup.confirmationStatus: {
            return 'Оценки участников значительно расходятся. Рекомендуется калибровка.'
        }
        case resultSetup?.someCompetenceHasDivergent: {
            return 'Оценки участников значительно расходятся.'
        }
        default: {
            return ''
        }
    }
}
const getNoDataAlertText = (resultSetup: IAssessmentResultSetup | null) => {
    if (resultSetup?.isSelfAssessment) return ''
    switch (true) {
        case !resultSetup?.isCompleteData: {
            return 'Недостаточно данных для объективной оценки. Большинство компетенций с неопределенным результатом.'
        }
        case resultSetup?.isAllUnComplete: {
            return 'Недостаточно данных для объективной оценки. Все компетенции с неопределенным результатом.'
        }
        case !resultSetup?.isAllComplete: {
            return 'Недостаточно данных для объективной оценки. Есть компетенции с неопределенным результатом'
        }
        default: {
            return ''
        }
    }
}

export const AssessmentResultAlertMain = () => {
    // const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)
    // const assType = getCpaType(result?.cpa?.typeUuid ?? result?.typeUuid)
    const statusAlertText = getResultAlertText(resultSetup)
    const divergentAlertText = getDivergentAlertText(resultSetup)
    const noDataAlertText = getNoDataAlertText(resultSetup)


    return (
        <Stack spacing={2}>
            {statusAlertText && <GFAlertInfo
                severity="warning"
                text={statusAlertText}
            />}
            {divergentAlertText && <GFAlertInfo
                severity="error"
                text={divergentAlertText}
            />}
            {noDataAlertText && <GFAlertInfo
                severity="error"
                text={noDataAlertText}
            />}
        </Stack>
    )
}

