import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { HINT_IMPOSSIBLE_TO_CANCEL_ACTION } from 'helpers/enums/hints'
import { biggerBtnForMobile } from 'styles/styles'

type DialogConfirmProps = {
    open: boolean;
    dialogTitle: string;
    dialogText: string;
    titleButtonConfirm: string;
    onSubmit: (e: any) => void;
    handleClose: (e: any) => void;
    impossibleToCancel?: boolean;
    colorButtonConfirm?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
    dialogHelperText?: string | string[];
    titleButtonCancel?: string;
    variantButtonConfirm?: 'text' | 'outlined' | 'contained';
}

// TODO: need use confirmation dialog in global state
export const DialogConfirm: FC<DialogConfirmProps> = ({
    open,
    handleClose,
    onSubmit,
    dialogText,
    dialogTitle,
    titleButtonConfirm,
    dialogHelperText,
    titleButtonCancel,
    impossibleToCancel,
    colorButtonConfirm = 'primary',
    variantButtonConfirm = 'contained',
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const handleSubmit = async (e: any) => {
        await onSubmit(e)
        await handleClose(e)
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box display="flex" flex={1} flexDirection="column">
                <DialogTitle onClose={handleClose}>
                    {dialogTitle}
                </DialogTitle>
                <Box display="flex" flex={1} flexDirection="column">
                    <DialogContent>
                        <Typography variant="body2">
                            {dialogText}
                        </Typography>
                        <Box mt={1}>
                            {dialogHelperText
                                ? Array.isArray(dialogHelperText)
                                    ? dialogHelperText.map(el => <Typography key={el} variant="body2">
                                        {el}
                                    </Typography>)
                                    : <Typography variant="body2">
                                        {dialogHelperText}
                                    </Typography>
                                : null}
                            {impossibleToCancel ? <Typography variant="body2">
                                {HINT_IMPOSSIBLE_TO_CANCEL_ACTION}
                            </Typography> : null}
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                        disableSpacing={mobile}
                    >
                        {titleButtonCancel &&
                            <Button
                                variant="text"
                                onClick={handleClose}
                                sx={mobile ? biggerBtnForMobile : {}}
                            >
                                {titleButtonCancel}
                            </Button>
                        }
                        <Button
                            variant={variantButtonConfirm}
                            onClick={handleSubmit}
                            color={colorButtonConfirm}
                            sx={mobile ? biggerBtnForMobile : {}}
                        >
                            {titleButtonConfirm}
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    )
}
