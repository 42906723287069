import React, { FC } from 'react'
import { TITLE_NAMESPACE_AUTHOR, TITLE_NAMESPACE_DEVELOPMENT_PLAN, TITLE_NAMESPACE_SENT } from 'helpers/enums/titles'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'

type HistoryPdpHeaderProps = {
    pdp: IPdp;
    pdpStatus: IPdpStatus;
}

export const HistoryPdpHeader: FC<HistoryPdpHeaderProps> = ({ pdp, pdpStatus }) => {
    return (
        <Box display="flex" justifyContent="space-between">
            <Stack spacing={1}>
                <Box display="flex">
                    <Typography variant="h2" mr={3}>
                        {TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                    </Typography>
                    <PointPositionSpecializationGrade title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}/>
                </Box>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>} spacing={1}>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_SENT}: {pdp?.sentAt}
                    </Typography>
                    {pdp?.author?.name && <Typography variant="body1">
                        {TITLE_NAMESPACE_AUTHOR}: {pdp?.author?.name}
                    </Typography>}
                </Stack>
                {!!pdp?.changeLog && <BadgeChangeLog changeLog={pdp?.changeLog} chipDateTime/>}
            </Stack>
            <Box display="flex" alignItems="center">
                <Chip
                    label={pdpStatus.title}
                    icon={pdpStatus.chipIcon}
                    color={pdpStatus.color}
                    variant={pdpStatus.chipVariant}
                />
            </Box>
        </Box>
    )
}

