import React, { FC } from 'react'
import { StarOutlineRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { useDesktop } from 'helpers/hooks/useDesktop'

type PointPositionSpecializationGradeProps = {
    title: React.ReactNode;
}

export const PointPositionSpecializationGrade: FC<PointPositionSpecializationGradeProps> = ({ title }) => {
    const desktop = useDesktop()
    return (
        <Chip
            label={title}
            size="medium"
            icon={<StarOutlineRounded />}
            sx={{
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '19px',
                padding: '4px 4px 4px 12px',
                height: 'auto',
                maxWidth: !desktop ? 'fit-content' : '100%',
                // @ts-ignore
                backgroundColor: (theme) => theme.palette.warning[50],
                color: (theme) => theme.palette.secondary.main,
                '& .MuiChip-icon': {
                    color: (theme) => theme.palette.secondary.main,
                    mx: 0,
                },

                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'pre-wrap',
                },
            }}
        />
    )
}
