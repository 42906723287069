import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { addDays, isPast, parse } from 'date-fns'
import { TITLE_NAMESPACE_COMPLETE, TITLE_NAMESPACE_CURRENT, TITLE_NAMESPACE_ENDED_TO } from 'helpers/enums/titles'
import { Divider, Stack, Typography } from '@mui/material'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import { ArrowForwardRounded, DateRangeRounded } from '@mui/icons-material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { AssessmentInstruction } from 'components/assessment/AssessmentInstruction/AssessmentInstruction'
import {
    AssessmentPassAlerts,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentHeader/components/AssessmentPassAlerts/AssessmentPassAlerts'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'

export const EmployeeAssessmentHeader = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const answers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    const isExpire = assessmentSetup?.isValidDateEnd && assessment && !assessmentSetup?.completeStatus && isPast(addDays(parse(assessment?.endedAt, 'dd.MM.yyyy', new Date()), 1))

    const evaluatedSpecializationUuid = assessment?.evaluated?.currentPosition?.specializationUuid
    const evaluatedGradeUuid = assessment?.evaluated?.currentPosition?.gradeUuid
    const assessmentPointPositionTitle = evaluatedSpecializationUuid === assessment?.position?.specializationUuid
        && evaluatedGradeUuid === assessment?.position?.gradeUuid
        && `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || assessment?.position?.title

    const positionTitle = assessment?.cpa?.criteria?.uuid === cpaCriteriaCurrentUuid
        ? `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        : assessmentPointPositionTitle || assessment?.cpa?.criteria.title

    const pageTitle = assessmentSetup?.viewerIsCurrentReviewer && assessmentSetup?.summarizingStatus ? 'Подведение результатов оценки' : 'Подробные результаты оценки'

    return (
        <>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="h1">
                        {assessment?.title}
                    </Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <ChipAssessmentType cpaType={assessment?.cpa?.typeUuid || assessment?.typeUuid}/>
                        {assessmentSetup?.isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                            <DateRangeRounded color="warning"/>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: ({ palette, typography }) => isExpire
                                        ? palette.warning.main
                                        : typography.body1.color,
                                }}
                            >
                                {assessmentSetup?.completeStatus
                                    ? `${TITLE_NAMESPACE_COMPLETE} ${assessment?.finishedAt}`
                                    : `${TITLE_NAMESPACE_ENDED_TO} ${assessment?.endedAt}`}
                            </Typography>
                        </Stack>}
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {`${assessment?.evaluated?.lastName} ${assessment?.evaluated?.firstName} ${assessment?.evaluated?.secondName}`}
                    </Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="body1">
                            {assessment?.evaluated?.currentPosition?.title}
                        </Typography>
                        <ArrowForwardRounded color="warning"/>
                        <PointPositionSpecializationGrade title={positionTitle}/>
                    </Stack>
                </Stack>
                <Divider/>
                <Stack spacing={1} alignItems="flex-start">
                    <Typography variant="h2">
                        {pageTitle}
                    </Typography>
                    <AssessmentInstruction
                        assessmentTypeUuid={assessment?.cpa?.typeUuid ?? assessment?.typeUuid}
                        assessmentStatusUuid={assessment?.statusUuid}
                        evaluatedUuid={assessment?.evaluated.uuid}
                        answers={answers}
                    />
                    <AssessmentPassAlerts/>
                </Stack>
            </Stack>
        </>

    )
}
