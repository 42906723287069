import React, { FC, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    FormHelperText,
    InputLabel,
    ListSubheader,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_SPECIALIZATION_EDIT,
    TITLE_NAMESPACE_DIVISION,
    TITLE_NAMESPACE_SPECIALIZATION,
    TITLE_NOT_FOUND,
} from 'helpers/enums/titles'
import { useNotify } from 'store/hooks/useNotify'
import { HINT_CHOOSE, HINT_ERROR_REQUIRED, HINT_SUCCESS_EDIT_SPECIALIZATION } from 'helpers/enums/hints'
import { specializationListActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { ChevronRightRounded, MoreHorizRounded } from '@mui/icons-material'

type EditSpecializationDialogProps = {
    element: Omit<ISpecializationListSpecialization, 'employeesCount'>;
    selectedDivisions: IEntity[];
    handleClose: () => void;
    open: boolean;
}

type FormValues<T> = {
    value: T;
    error: boolean | null;
    hint: string;
}

export const EditSpecializationDialog: FC<EditSpecializationDialogProps> = ({
    open,
    handleClose,
    element,
    selectedDivisions,
}) => {
    const notify = useNotify()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { createOneMeta } = useAppSelector(state => state.specializationListReducer.data)

    const [divisions, setDivisions] = useState<FormValues<any[]>>(() => ({
        value: selectedDivisions,
        error: null,
        hint: HINT_ERROR_REQUIRED,
    }))

    const changeDivision = (value: any[]) => {
        setDivisions({
            ...divisions,
            value,
            error: null,
        })
    }

    const checkFormValues = () => {
        let hasError = false
        if (!divisions.value.length) {
            hasError = true
            setDivisions({
                ...divisions,
                error: true,
            })
        }
        return hasError
    }

    const handleSubmit = async () => {
        if (checkFormValues()) return
        try {
            await dispatch(specializationListActions.updateOne({
                specializationUuid: element.uuid,
                divisionsUuid: divisions.value.map((el) => el.uuid),
            },
            element,
            createOneMeta.divisions,
            ))
            notify(HINT_SUCCESS_EDIT_SPECIALIZATION, 'success')
            await handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!createOneMeta) return null
    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth
            sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_SPECIALIZATION_EDIT}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <Box mb={2.5}>
                        <Typography variant="body1" mb={1}>
                            {TITLE_NAMESPACE_SPECIALIZATION}
                        </Typography>
                        <Typography variant="body2">
                            {element.title}
                        </Typography>
                    </Box>
                    <InputLabel htmlFor="divisions" sx={{ mb: 0.5 }}>
                        {TITLE_NAMESPACE_DIVISION} *
                    </InputLabel>
                    <Autocomplete
                        options={createOneMeta.divisions}
                        disableCloseOnSelect
                        multiple
                        limitTags={1}
                        value={divisions.value}
                        noOptionsText={TITLE_NOT_FOUND}
                        isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                {option.title}
                            </li>
                        )}
                        groupBy={option => option.parentDivisionTitle}
                        renderGroup={({ group, key, children }) => {
                            const currentDivision =
                                createOneMeta?.divisions?.find(el => el.parentDivisionTitle === group)
                            return (
                                <div key={key}>
                                    <ListSubheader sx={{ position: 'sticky', top: '-8px' }}>
                                        <Box>
                                            <Tooltip
                                                title={currentDivision?.fullPath || ''}
                                                placement="bottom-start"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            // TODO: Need fix. Need important to override custom slyles
                                                            margin: '-4px !important',
                                                        },
                                                    },
                                                }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <MoreHorizRounded color="disabled" fontSize="small" />
                                                    <ChevronRightRounded color="disabled" fontSize="small"
                                                        sx={{ mr: 0.5 }} />
                                                    {group}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </ListSubheader>
                                    {children}
                                </div>
                            )
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                // has kays in getTagProps
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                    label={option.title}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        onChange={(event, value) => changeDivision(value)}
                        renderInput={(params) => (
                            <TextField{...params} placeholder={HINT_CHOOSE} />
                        )}
                    />
                    {divisions.error && <FormHelperText>
                        <span
                            style={{
                                color: theme.palette.error.main,
                            }}
                        >
                            {divisions.hint}
                        </span>
                    </FormHelperText>}
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button variant="contained" onClick={handleSubmit}>{TITLE_BUTTON_SAVE}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
