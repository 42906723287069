import React, { FC } from 'react'
import { Box, Checkbox, Chip, Divider, Link, ListItem, ListItemButton, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { developmentPdpActions, pdpActions } from 'store/actions'
import { PATH_SPECIALIZATION } from 'helpers/enums/routePath'
import { NavLink as RouterLink } from 'react-router-dom'
import { useMobile } from 'helpers/hooks/useMobile'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
type DrawerPdpCompetenceProps = {
    element: ISetupCompetence;
    developmentPage?: boolean;
    groupName?: string;
}

export const DrawerPdpCompetence: FC<DrawerPdpCompetenceProps> = ({ element, developmentPage, groupName }) => {
    const mobile =  useMobile()
    const { pdp } = useAppSelector(state => state.pdpReducer.data)
    const link = developmentPage
        ? '#'
        : `${PATH_SPECIALIZATION}/${pdp?.pointPosition?.specializationUuid}/competence/${element.uuid}/${element.entityType}`
    const dispatch = useAppDispatch()
    const handleChange = () => {
        if (developmentPage)
            dispatch(developmentPdpActions.changeActiveCompetence(
                element.uuid,
                element.competenceGroupUuid,
                !element.selected,
            ))
        else
            dispatch(pdpActions.changeActiveCompetence(
                element.uuid,
                element.competenceGroupUuid,
                !element.selected,
            ))
    }
    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    sx={{
                        py: mobile ? 2 : 0.5,
                        px: mobile ? 0 : 2,
                        borderRadius: '12px',
                    }}
                    onClick={handleChange}
                >
                    <Box mr={2}>
                        <Checkbox
                            checked={element.selected}
                            color={element.isChanged ? 'warning' : 'primary'}
                            onChange={handleChange}
                            sx={{
                                color: (theme) => element.isChanged
                                    ? theme.palette.warning.main
                                    : theme.palette.primary.main,
                            }}
                        />
                    </Box>
                    <Box display="flex">
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5 }}>
                            <Box mr={0.5} mb={0.5} onClick={e => e.stopPropagation()}>
                                <Link
                                    component={developmentPage ? Typography : RouterLink}
                                    target={developmentPage ? undefined : '_blank'}
                                    underline={developmentPage ? 'none' : 'hover'}
                                    to={link}
                                    variant="body1"
                                    color={mobile ? '#222' : 'inherit'}
                                >
                                    {element.title}
                                </Link>
                            </Box>
                            <Typography variant="body1">
                                {element.competenceTypeTitle}
                            </Typography>
                            {mobile && developmentPage && groupName?.length &&
                                <Chip
                                    label={groupName}
                                    variant="outlined"
                                    size="small"
                                    icon={<MoreHorizIcon />}
                                    sx={{
                                        height: 'auto',
                                        '& .MuiChip-label': {
                                            display: 'block',
                                            whiteSpace: 'normal',
                                        },
                                    }}
                                />
                            }
                        </Box>
                    </Box>
                </ListItemButton>
            </ListItem>
            <Divider />
        </>
    )
}

