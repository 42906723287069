import React from 'react'
import {useAppSelector} from 'store/hooks/redux'
import {
    TITLE_BUTTON_ASSESSMENT_RESULTS,
    TITLE_BUTTON_PRELIMINARY_ASSESSMENT_RESULTS,
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_ENDED_TO,
} from 'helpers/enums/titles'
import {Box, Divider, Stack, Typography} from '@mui/material'
import { ChipAssessmentStatus } from 'components/chips/ChipAssessmentStatus/ChipAssessmentStatus'
import { ArrowForwardRounded, DateRangeRounded } from '@mui/icons-material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import {AssessmentAnalyticReport} from 'components/export/AssessmentAnalyticReport/AssessmentAnalyticReport'

export const AssessmentResultHeader = () => {
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)
    return (
        <Stack spacing={4}>
            <Box>
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Box>
                        <Typography variant="h1">
                            {result?.title}
                        </Typography>
                    </Box>
                    <ChipAssessmentStatus status={result?.statusUuid} />
                </Box>
                <Stack spacing={1} direction="row" alignItems="center">
                    <ChipAssessmentType cpaType={result?.cpa?.typeUuid || result?.typeUuid}/>
                    {resultSetup?.isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                        <DateRangeRounded color="warning"/>
                        <Typography
                            variant="body1"
                            sx={{
                                color: ({ palette, typography }) => resultSetup?.isExpire
                                    ? palette.warning.main
                                    : typography.body1.color,
                            }}
                        >
                            {resultSetup?.completeStatus
                                ? `${TITLE_NAMESPACE_COMPLETE} ${result?.finishedAt}`
                                : `${TITLE_NAMESPACE_ENDED_TO} ${result?.endedAt}`}
                        </Typography>
                    </Stack>}
                </Stack>
            </Box>
            <Stack spacing={1}>
                <Typography variant="h3">
                    {resultSetup?.evaluatedFullName}
                </Typography>
                <Stack spacing={1} direction="row" alignItems="center">
                    <Typography variant="body1">
                        {result?.evaluated?.currentPosition?.title}
                    </Typography>
                    {resultSetup?.canSeeCriteria && <>
                        <ArrowForwardRounded color="warning"/>
                        <PointPositionSpecializationGrade title={resultSetup?.positionTitle}/>
                    </>}
                </Stack>
            </Stack>
            {resultSetup?.completeStatus && <AssessmentAnalyticReport/>}
            <Divider />
            <Box>
                <Typography variant="h2">
                    {resultSetup?.completeStatus
                        ? TITLE_BUTTON_ASSESSMENT_RESULTS
                        : TITLE_BUTTON_PRELIMINARY_ASSESSMENT_RESULTS}
                </Typography>
            </Box>
        </Stack>
    )
}
