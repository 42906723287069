import React from 'react'
import { SnackbarContent, SnackbarContentProps, SnackbarKey, SnackbarMessage } from 'notistack'
import { notifierActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { Box, IconButton, Typography } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface ReportCompleteProps {
    id: SnackbarKey;
    message: SnackbarMessage;
}

export const ReportComplete = React.forwardRef<HTMLDivElement, ReportCompleteProps & SnackbarContentProps>(
    function ReportComplete(
        props,
        ref,
    ) {
        const { id, message,  ...other } = props
        const dispatch = useAppDispatch()
        const notifyClose = (key?: SnackbarKey) => dispatch(notifierActions.notifyClose(key))
        return (
            <SnackbarContent ref={ref} role="alert" {...other}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#fff',
                        px: 2,
                        py: 0.5,
                        borderRadius: 4,
                        width: '100%',
                        maxWidth: 530,
                        backgroundColor: (theme) => theme.palette.success.main,
                    }}
                >
                    <CheckCircleOutlineRoundedIcon
                        sx={{
                            color:'inherit',
                            marginRight: 1,
                        }}
                    />
                    <Typography
                        sx={{
                            color: 'inherit',
                            marginRight: 1,
                            my: 1,
                            flex: 1,
                        }}
                    >
                        {message}
                    </Typography>
                    <IconButton sx={{ color:'inherit' }} onClick={() => notifyClose(id)}>
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </SnackbarContent>
        )
    })
