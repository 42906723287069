import { TransferCPA } from 'api/services/CPAService/transferCPA'
import {
    ICPAChangeStatusRequest,
    ICPACreateForUnCompletedRequest,
    ICpaDeleteEmployeeRequest,
    ICPAGetCriteriaCompetenceSkillLevel,
    ICPAGetCriteriaPointResponse,
    ICpaGetEmployeeAssessmentPreviewRequest,
    ICpaGetEmployeeAssessmentPreviewResponse,
    ICpaGetEmployeeRequest,
    ICpaGetEmployeesForAddRequest,
    ICpaGetEmployeesForAddResponse,
    ICpaGetEmployeesResponse,
    ICpaSaveEmployeesToCpaRequest,
    ICPASetCriteriaCompetenceRequest,
    ICPASetCriteriaPointRequest,
    ICpaSetEmployeeParticipantsRequest,
    ICPASetScaleRequest,
    ICPAUpdateRequest,
} from 'api/typing/cpaTypes'

export class CPAItemService extends TransferCPA {

    public getItem(uuid: string) {
        const path = `/${uuid}`
        return this.request<null, ICpaItemMeta>('GET', path)
    }

    public deleteItem(uuid: string) {
        const path = `/${uuid}`
        return this.request<{ uuid: string }>('DELETE', path, { data: { uuid } })
    }

    public updateItem(data: ICPAUpdateRequest) {
        const path = `/${data.uuid}`
        return this.request<ICPAUpdateRequest>('PUT', path, { data })
    }

    public createForUncompleted(data: ICPACreateForUnCompletedRequest) {
        const path = `/${data.initialCpaUuid}/duplicate/create`
        return this.request<ICPACreateForUnCompletedRequest, { uuid: string }>('POST', path, { data })
    }

    public changeStatusCpa(data: ICPAChangeStatusRequest) {
        const path = `/${data.cpaUuid}/status`
        return this.request<ICPAChangeStatusRequest>('POST', path, { data })
    }

    public getCpaStatusState(uuid: string) {
        const path = `/${uuid}/status/state`
        return this.request<null, ICpaStatusState>('GET', path)
    }

    public refetchCpaStatus(cpaUuid: string) {
        const path = `/${cpaUuid}/status/refetch`
        return this.request<{ cpaUuid: string }, ICpaStatusState>('POST', path, { data: { cpaUuid } })
    }

    // criteria
    public setItemCriteriaCurrent(cpaUuid: string) {
        const path = `/${cpaUuid}/criteria/current`
        return this.request<{ cpaUuid: string; }>('POST', path, { data: { cpaUuid } })
    }

    public getItemCriteriaPoint(uuid: string) {
        const path = `/${uuid}/criteria/point`
        return this.request<null, ICPAGetCriteriaPointResponse>('GET', path)
    }

    public setItemCriteriaPoint(data: ICPASetCriteriaPointRequest) {
        const path = `/${data.cpaUuid}/criteria/point`
        return this.request<ICPASetCriteriaPointRequest>('POST', path, { data })
    }

    public getItemCriteriaCompetence(uuid: string) {
        const path = `/${uuid}/criteria/competence`
        return this.request<null, ICPAGetCriteriaCompetenceSkillLevel[]>('GET', path)
    }

    public setItemCriteriaCompetence(data: ICPASetCriteriaCompetenceRequest) {
        const path = `/${data.cpaUuid}/criteria/competence`
        return this.request<ICPASetCriteriaCompetenceRequest>('POST', path, { data })
    }

    // scale
    public getItemScale(cpaUuid: string) {
        const path = `/${cpaUuid}/scale/numeric`
        return this.request<null, IDictionaryScaleItem>('GET', path)
    }

    public setItemScale(data: ICPASetScaleRequest) {
        const path = `/${data.cpaUuid}/scale/${data.scaleUuid}`
        return this.request<ICPASetScaleRequest>('POST', path, { data })
    }

    // employees
    public getItemEmployees(uuid: string) {
        const path = `/${uuid}/employee/assessed/all`
        return this.request<null, ICpaGetEmployeesResponse>('GET', path)
    }

    public getCpaEmployeesList(data: ICpaGetEmployeesForAddRequest) {
        const params = data?.employeeUuid ? { employeeUuid: data?.employeeUuid } : undefined
        const path = `/${data.cpaUuid}/setup/employee/all`
        return this.request<null, ICpaGetEmployeesForAddResponse[]>('GET', path, { params })
    }

    public saveEmployeesToCpa(data: ICpaSaveEmployeesToCpaRequest) {
        const path = `/${data.cpaUuid}/employee`
        return this.request<ICpaSaveEmployeesToCpaRequest>('POST', path, { data })
    }

    public getCpaPreviewAssessment(data: ICpaGetEmployeeAssessmentPreviewRequest) {
        const path = `/${data.cpaUuid}/employee/${data.employeeUuid}/assessment/preview`
        return this.request<null, ICpaGetEmployeeAssessmentPreviewResponse>('GET', path, { params: { criteriaUuid: data.criteriaUuid } })
    }

    public getCpaEmployee(data: ICpaGetEmployeeRequest) {
        const path = `/${data.cpaUuid}/employee/${data.employeeUuid}`
        return this.request<null, ICpaEmployee>('GET', path)
    }

    public deleteCpaEmployee(data: ICpaDeleteEmployeeRequest) {
        const path = `/${data.cpaUuid}/employee/${data.employeeUuid}`
        return this.request<ICpaDeleteEmployeeRequest>('DELETE', path, { data })
    }

    public setCpaEmployeeParticipants(data: ICpaSetEmployeeParticipantsRequest) {
        const path = `/${data.cpaUuid}/employee/${data.employeeUuid}/participant`
        return this.request<ICpaSetEmployeeParticipantsRequest>('POST', path, { data })
    }
}
