import React, { useEffect } from 'react'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_BACK_TO_ASSESSMENT,
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_ENDED_TO,
} from 'helpers/enums/titles'
import { useNavigate } from 'react-router-dom'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { ArrowForwardRounded, DateRangeRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import {
    MyAssessmentPassHeaderHelperChips,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassHeaderHelperChips/MyAssessmentPassHeaderHelperChips'
import {AssessmentAnalyticReport}from 'components/export/AssessmentAnalyticReport/AssessmentAnalyticReport'

export const MyAssessmentPassHeader = () => {
    const navigate = useNavigate()
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    useEffect(() => {
        if (!assessmentSetup?.viewerIsEvaluated && assessment?.isCompleteByRequestingUser)
            navigate(PATH_ASSESSMENT)
    }, [assessment, assessmentSetup])

    const redirectBack = () => {
        navigate(assessmentSetup?.redirectPath || '', { replace: true })
    }

    return (
        <Stack spacing={3}>
            <Box>
                <Button
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={redirectBack}
                >
                    {TITLE_BUTTON_BACK_TO_ASSESSMENT}
                </Button>
            </Box>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="h1">
                        {assessment?.title}
                    </Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <ChipAssessmentType cpaType={assessment?.cpa?.typeUuid || assessment?.typeUuid}/>
                        {assessmentSetup?.isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                            <DateRangeRounded color="warning"/>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: ({ palette, typography }) => assessmentSetup?.isExpire
                                        ? palette.warning.main
                                        : typography.body1.color,
                                }}
                            >
                                {assessmentSetup?.completeStatus
                                    ? `${TITLE_NAMESPACE_COMPLETE} ${assessment?.finishedAt}`
                                    : `${TITLE_NAMESPACE_ENDED_TO} ${assessment?.endedAt}`}
                            </Typography>
                        </Stack>}
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {`${assessment?.evaluated?.lastName} ${assessment?.evaluated?.firstName} ${assessment?.evaluated?.secondName}`}
                    </Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="body1">
                            {assessment?.evaluated?.currentPosition?.title}
                        </Typography>
                        {assessmentSetup?.canSeeCriteria && <>
                            <ArrowForwardRounded color="warning"/>
                            <PointPositionSpecializationGrade title={assessmentSetup?.positionTitle}/>
                        </>}
                    </Stack>
                </Stack>
                {assessmentSetup?.completeStatus && <AssessmentAnalyticReport/>}
                <Divider/>
                <Stack spacing={1} alignItems="flex-start">
                    {assessmentSetup?.completeStatus && <Typography variant="h2">
                        Подробные результаты оценки
                    </Typography>}
                    <MyAssessmentPassHeaderHelperChips/>
                </Stack>
            </Stack>
        </Stack>
    )
}
