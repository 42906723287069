import React, { FC } from 'react'
import { DialogTitle as MuiDialogTitle } from '@mui/material'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface DialogTitleProps {
    children?: React.ReactNode;
    onClose?: (e: any) => void;
}

export const DialogTitle: FC<DialogTitleProps> = ({ children, onClose, ...other }) => (
    <MuiDialogTitle sx={{ m: 0, p: 3 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
    </MuiDialogTitle>
)
