import React, { FC } from 'react'
import { Box, Checkbox, Divider, Link, ListItem, ListItemButton, Typography } from '@mui/material'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemCriteriaActions } from 'store/actions'

type CPACriteriaDrawerItemCompetenceDrawerCompetenceProps = {
    item:  ICpaCriteriaCompetence;
    groupUuid: string;
}

export const CPACriteriaDrawerItemCompetenceDrawerCompetence:
    FC<CPACriteriaDrawerItemCompetenceDrawerCompetenceProps> = ({
        item,
        groupUuid,
    }) => {

        const dispatch = useAppDispatch()
        // const link = `${PATH_SPECIALIZATION}/${pdp?.pointPosition?.specializationUuid}/competence/${item.uuid}`
        const handleChange = () => {
            dispatch(cpaItemCriteriaActions.criteriaCompetenceChangeActiveCompetence({
                uuid: item.uuid,
                groupUuid,
                active: !item.selected,
            }))
        }
        return (
            <>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{
                            py: 0.5,
                            px: 2,
                            borderRadius: '12px',
                        }}
                        onClick={handleChange}
                    >
                        <Box mr={2}>
                            <Checkbox
                                checked={item.selected}
                                color={item.isChanged ? 'warning' : 'primary'}
                                // onChange={handleChange}
                                sx={{
                                    color: (theme) => item.isChanged
                                        ? theme.palette.warning.main
                                        : theme.palette.primary.main,
                                }}
                            />
                        </Box>
                        <Box display="flex">
                            <Box sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Box mr={0.5} mb={0.5} onClick={e => e.stopPropagation()}>
                                    <Link
                                        component={Typography}
                                        target={undefined}
                                        underline='none'
                                        variant="body1"
                                    >
                                        {item.title}
                                    </Link>
                                </Box>
                                <Typography variant="body1">
                                    {item.competenceType.title}
                                </Typography>
                            </Box>
                        </Box>
                    </ListItemButton>
                </ListItem>
                <Divider />
            </>
        )
    }
