import React from 'react'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CANCEL_ASSESSMENT,
    TITLE_BUTTON_DELETE,
    TITLE_DIALOG_CANCEL_ASSESSMENT,
    TITLE_DIALOG_DELETE_ASSESSMENT,
    TITLE_NAMESPACE_ASSESSMENT_GENITIVE,
} from 'helpers/enums/titles'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import {
    HINT_ASSESSMENT_CANCEL,
    HINT_ASSESSMENT_CANCEL_HELPER_TEXT,
    HINT_SUCCESS_ASSESSMENT_CANCEL,
    HINT_SUCCESS_ASSESSMENT_DELETE,
} from 'helpers/enums/hints'
import {
    DialogUpdateAssessment,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabAssessment/components/gialogs/DialogUpdateAssessment/DialogUpdateAssessment'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { myAssessmentActions } from 'store/actions'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNavigate } from 'react-router-dom'
import { useNotify } from 'store/hooks/useNotify'

export const MyAssessmentMetaSecondaryActions = () => {
    const navigate = useNavigate()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { deleteAssessment, editAssessment, cancelAssessment, toggleDialog } = useDialogsState({
        editAssessment: false,
        deleteAssessment: false,
        cancelAssessment: false,
    })
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const assessmentMeta = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMeta)
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)

    const onDeleteAssessment = async () => {
        if (!assessmentMeta?.uuid) return
        try {
            await dispatch(myAssessmentActions.deleteMyAssessmentMeta(assessmentMeta?.uuid))
            navigate(PATH_ASSESSMENT)
            const messageSuccess = assessmentMetaSetup?.createdStatus || assessmentMetaSetup?.estimationStatus
                ? HINT_SUCCESS_ASSESSMENT_DELETE
                : HINT_SUCCESS_ASSESSMENT_CANCEL
            notify(messageSuccess, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!assessmentMetaSetup?.canManageAssessment || !assessmentMeta) return null

    return (
        <>
            <DialogConfirmDelete
                open={deleteAssessment}
                handleClose={() => toggleDialog('deleteAssessment', false)}
                onSubmit={onDeleteAssessment}
                title={TITLE_DIALOG_DELETE_ASSESSMENT}
                elementType={TITLE_NAMESPACE_ASSESSMENT_GENITIVE}
                helperText={HINT_ASSESSMENT_CANCEL_HELPER_TEXT}
            />
            {editAssessment && <DialogUpdateAssessment
                viewerIsEvaluated={assessmentMetaSetup?.viewerIsEvaluated}
                assessmentMeta={assessmentMeta}
                open={editAssessment}
                handleClose={() => toggleDialog('editAssessment', false)}
            />}
            <DialogConfirm
                open={cancelAssessment}
                onSubmit={onDeleteAssessment}
                dialogTitle={TITLE_DIALOG_CANCEL_ASSESSMENT}
                dialogText={HINT_ASSESSMENT_CANCEL}
                titleButtonConfirm={TITLE_BUTTON_DELETE}
                colorButtonConfirm="error"
                dialogHelperText={HINT_ASSESSMENT_CANCEL_HELPER_TEXT}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                impossibleToCancel
                handleClose={() => toggleDialog('cancelAssessment', false)}
            />
            <Authenticated
                service="my_development"
                accessLevel="w"
                divisions={[employee?.division?.uuid]}
            >
                <SecondaryActions
                    onEdit={!assessmentMetaSetup?.completeStatus
                        ? () => toggleDialog('editAssessment', true)
                        : undefined}
                    onDelete={() => {
                        assessmentMetaSetup?.createdStatus
                            ? toggleDialog('deleteAssessment', true)
                            : toggleDialog('cancelAssessment', true)
                    }}
                    deleteTitle={assessmentMetaSetup?.createdStatus
                        ? undefined
                        : TITLE_BUTTON_CANCEL_ASSESSMENT}
                />
            </Authenticated>
        </>
    )
}
