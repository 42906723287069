import React from 'react'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useNotify } from 'store/hooks/useNotify'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_CPA } from 'helpers/enums/routePath'
import { cpaItemActions } from 'store/actions'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_DELETE, TITLE_DIALOG_DELETE_ASSESSMENT } from 'helpers/enums/titles'
import {
    DialogCpaEdit,
} from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaSecondaryAction/components/DialogCpaEdit/DialogCpaEdit'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'

export const CPAMetaSecondaryAction = () => {
    const { cpaUuid } = useParams()
    const notify = useNotify()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const statusComplete = cpaStatusCode === 'complete'
    const { dialogDelete, dialogEdit, toggleDialog } = useDialogsState({
        dialogDelete: false,
        dialogEdit: false,
    })

    const deleteCpa = async () => {
        if (!cpaUuid) return
        try {
            await dispatch(cpaItemActions.deleteItem(cpaUuid))
            navigate(PATH_CPA)
            dispatch(cpaItemActions.clearState())
        } catch (e: any) {
            notify(e.userMessage, 'error')
            throw e
        }
    }

    if (statusComplete) return null

    return (
        <>
            <DialogConfirm
                open={dialogDelete}
                dialogTitle={TITLE_DIALOG_DELETE_ASSESSMENT}
                dialogText="Вы уверены, что хотите удалить оценочную процедуру?"
                dialogHelperText={cpaStatusCode !== 'created' && cpaStatusCode !== 'ready' ? 'При удалении оценочной процедуры все незавершенные оценки добавленных сотрудников будут также удалены.' : undefined}
                titleButtonConfirm={TITLE_BUTTON_DELETE}
                colorButtonConfirm="error"
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={deleteCpa}
                handleClose={() => toggleDialog('dialogDelete', false)}
            />
            <DialogCpaEdit
                open={dialogEdit}
                handleClose={() => toggleDialog('dialogEdit', false)}
            />
            <SecondaryActions
                onEdit={() => toggleDialog('dialogEdit', true)}
                onDelete={() => toggleDialog('dialogDelete', true)}
            />
        </>
    )
}
