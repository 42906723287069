import { TITLE_NAMESPACE_EMPLOYEE, TITLE_NAMESPACE_REVIEWER, TITLE_NAMESPACE_TOTAL } from 'helpers/enums/titles'

// Дополнительная кастомизация легенд для ниво
export const titlesLegendsNivo: Record<string, Record<string, string>> = {
    radar: {
        total: TITLE_NAMESPACE_TOTAL,
        employee: TITLE_NAMESPACE_EMPLOYEE,
        reviewer: TITLE_NAMESPACE_REVIEWER,
    },
}
