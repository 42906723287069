import React, { FC } from 'react'
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material'
import {
    OAAssessmentIndicator,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentCompetence/components/OAAssessmentIndicator/OAAssessmentIndicator'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

type OAAssessmentCompetenceProps = {
    competence: IAssessmentCompetence;
}

export const OAAssessmentCompetence: FC<OAAssessmentCompetenceProps> = ({ competence }) => {
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const assessmentStatus = getAssessmentStatus(assessment?.statusUuid)
    const showScores = assessmentStatus?.code === 'complete'

    return (
        <Stack spacing={3}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Box>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="caption">
                            {competence.typeTitle}
                        </Typography>
                        {/*<ChipSkillLevel skillLevelTitle={competence.skillLevelTitle} />*/}
                    </Stack>
                    <Typography variant="h3">
                        {competence.title}
                    </Typography>
                </Box>
                {showScores && <Chip
                    color="warning"
                    label={`${competence.score}%`}
                />}
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                <Stack spacing={2} divider={<Divider/>}>
                    {competence.indicators.map((indicator, idx) => <OAAssessmentIndicator
                        key={indicator.uuid}
                        idx={idx + 1}
                        indicator={indicator}
                        competenceUuid={competence.uuid}
                    />)}
                </Stack>
            </Paper>
        </Stack>
    )
}
