import React from 'react'
import { Button } from '@mui/material'
import { PlayArrowRounded } from '@mui/icons-material'
import { myAssessmentActions } from 'store/actions'
import { assessmentStatusAwaitingEstimation } from 'helpers/assessment/getAssessmentStatus'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useNavigate } from 'react-router-dom'

export const MyAssessmentMetaActionButton = () => {
    const notify = useNotify()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const assessmentMeta = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMeta)
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)

    const onActionButtonClick = async () => {
        if (!assessmentMeta?.uuid) return

        if (!assessmentMetaSetup?.createdStatus) {
            navigate('pass')
            return
        }

        try {
            await dispatch(myAssessmentActions.startMyAssessment({
                uuid: assessmentMeta.uuid,
                statusUuid: assessmentStatusAwaitingEstimation,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!assessmentMetaSetup?.actionButtonShow) return null

    return (
        <Button
            startIcon={<PlayArrowRounded/>}
            variant="contained"
            onClick={onActionButtonClick}
        >
            {assessmentMetaSetup?.actionButtonTitle}
        </Button>
    )
}
