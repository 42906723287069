import axios, { AxiosStatic } from 'axios'
import { CPAListService } from 'api/services/CPAService/CPAListService'
import { CPAItemService } from 'api/services/CPAService/CPAItemService'
import { CPADictionaryService } from 'api/services/CPAService/CPADictionaryService'
import { CPANotifyService } from 'api/services/CPAService/CPANotifyService'

interface ICPAService {
    listService: () => CPAListService;
    itemService: () => CPAItemService;
    dictionaryService: () => CPADictionaryService;
    notifyService: () => CPANotifyService;
}

export class CPAService implements ICPAService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public listService(): CPAListService {
        return new CPAListService(this.axiosInstance)
    }

    public itemService(): CPAItemService {
        return new CPAItemService(this.axiosInstance)
    }

    public dictionaryService(): CPADictionaryService {
        return new CPADictionaryService(this.axiosInstance)
    }

    public notifyService(): CPANotifyService {
        return new CPANotifyService(this.axiosInstance)
    }
}
