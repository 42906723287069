import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IFormState {
    formSpecializations: IFormSpecialization[];
    formGrades: IEntity[];
    formAnswers: IEntity[];
    oaAnswers: IEntity[];
    cpaRoles: IEntity[];
}

const initialState: IState<IFormState> = {
    data: {} as IFormState,
    isLoading: false,
    error: null,
}

export const FormSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,
        setSpecializations: (state, action: PayloadAction<IFormSpecialization[]>) => {
            state.data.formSpecializations = action.payload
        },
        setGrades: (state, action: PayloadAction<IEntity[]>) => {
            state.data.formGrades = action.payload
        },
        setAnswers: (state, action: PayloadAction<IEntity[]>) => {
            state.data.formAnswers = action.payload
        },
        setOAAnswers: (state, action: PayloadAction<IEntity[]>) => {
            state.data.oaAnswers = action.payload
        },
        setCpaRoles: (state, action: PayloadAction<IEntity[]>) => {
            state.data.cpaRoles = action.payload
        },
    },
})

export const formReducer = FormSlice.reducer
