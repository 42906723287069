import React from 'react'
import { useReactToPrint } from 'react-to-print'
import { useNotify } from 'store/hooks/useNotify'
import { Button } from '@mui/material'
import { NotPrintable } from 'components/NotPrintable/NotPrintable'
import { TITLE_BUTTON_PRINT_DOWNLOAD } from 'helpers/enums/titles'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export const ButtonPrint = () => {
    const notify = useNotify()
    const printElement = React.useRef<HTMLElement | null>(null)
    const print = useReactToPrint({
        content: () => printElement.current,
    })

    const handlePrint = () => {
        printElement.current = document.getElementById('print-page')
        if (!printElement.current) {
            notify('Can\'t print yet, try again later', 'error')
            return
        }

        print()
    }

    return (
        <NotPrintable>
            <Button
                variant="contained"
                color="primary"
                startIcon={<PictureAsPdfIcon/>}
                onClick={handlePrint}
            >
                {TITLE_BUTTON_PRINT_DOWNLOAD}
            </Button>
        </NotPrintable>
    )
}
