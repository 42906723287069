import { AppDispatch } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import { CPAItemScaleSlice } from 'store/reducers/cpa/item/CPAItemScaleSlice'
import { IGetCpaScale } from 'api/typing/formTypes'
import { ICPASetScaleRequest } from 'api/typing/cpaTypes'
import { TDictionaryScaleNumericItemCreateRequest } from 'api/typing/dictionaryTypes'
import { CPAItemSlice } from 'store/reducers/cpa/item/CPAItemSlice'


export class CpaItemScaleAction {
    private static get cpaItemApi() {
        return new CPAService().itemService()
    }

    private static get cpaDictionaryApi() {
        return new CPAService().dictionaryService()
    }

    public setScaleNumeric(data: IDictionaryScaleItem) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemScaleSlice.actions.setScaleNumeric(data))
        }
    }

    public createNewScale(data: TDictionaryScaleNumericItemCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemScaleSlice.actions.fetching())
                const { uuid } = await CpaItemScaleAction.cpaDictionaryApi.createScaleNumericItem(data)
                dispatch(CPAItemScaleSlice.actions.createNewScale({ ...data, uuid }))
                dispatch(CPAItemScaleSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemScaleSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateScale(data: IDictionaryScaleItem & { answersUuidToDelete: string[] }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemScaleSlice.actions.fetching())
                void await CpaItemScaleAction.cpaDictionaryApi.updateScaleNumericItem(data)
                dispatch(CPAItemScaleSlice.actions.updateScale(data))
                dispatch(CPAItemScaleSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemScaleSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteScale(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemScaleSlice.actions.fetching())
                void await CpaItemScaleAction.cpaDictionaryApi.deleteScaleNumericItem(uuid)
                dispatch(CPAItemScaleSlice.actions.deleteScale(uuid))
                dispatch(CPAItemScaleSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemScaleSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public selectForSaveScaleUuid(uuid: string) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemScaleSlice.actions.selectForSaveScaleUuid(uuid))
        }
    }

    public saveScale(payload: ICPASetScaleRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemScaleSlice.actions.fetching())
                void await CpaItemScaleAction.cpaItemApi.setItemScale(payload)
                dispatch(CPAItemScaleSlice.actions.setItemScale(payload))
                dispatch(CPAItemSlice.actions.updateScaleUuid(payload))
                dispatch(CPAItemScaleSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemScaleSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setScaleList(data: IGetCpaScale[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemScaleSlice.actions.setScaleList(data))
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemScaleSlice.actions.clearState())
        }
    }
}
