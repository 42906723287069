import React, { FC } from 'react'
import { Alert, AlertProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'

type GFAlertProps = {
    text?: string;
    colorIcon?: string;
    colorText?: string;
    styles?: SxProps<Theme>;
}

export const GFAlertInfo: FC<GFAlertProps & AlertProps> = ({
    text,
    colorIcon: colorIconProp,
    colorText: colorTextProp,
    styles,
    ...props
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    let colorIcon = ''
    let colorText = ''
    switch (true) {
        case props.severity === 'warning': {
            colorText = theme.palette.warning.dark
            colorIcon = theme.palette.warning.dark
            break
        }
        case props.severity === 'info': {
            colorText = theme.palette.primary.dark
            colorIcon = theme.palette.primary.main
            break
        }
        case props.severity === 'error': {
            colorText = theme.palette.error.dark
            colorIcon = theme.palette.error.main
            break
        }
        case props.severity === 'success': {
            colorText = theme.palette.success.dark
            colorIcon = theme.palette.success.main
            break
        }
        default: {
            colorText = theme.palette.primary.dark
            colorIcon = theme.palette.primary.main
            break
        }
    }

    return (
        <Alert
            severity="info"
            sx={{
                alignItems: 'center',
                alignSelf: 'stretch',
                flexWrap: 'wrap',
                gap: 2,
                '& .MuiAlert-icon': {
                    color: colorIconProp || colorIcon,
                },
                '& .MuiAlert-action': {
                    p: 0,
                    flex: mobile ? 1 : 'inherit',
                    my: 'auto',
                    alignSelf: 'flex-end',
                },
                '& .MuiAlert-message': {
                    flex: 1,
                    minWidth: 200,
                },
                ...styles,
            }}
            {...props}
        >
            {text &&
                <Typography variant="body1" sx={{ color: colorTextProp || colorText }}>
                    {text}
                </Typography>
            }
        </Alert>
    )
}
