import React, { FC, useEffect, useMemo, useState } from 'react'
import { employeeActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    EmployeeCompetenciesGroup,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabCompetencies/components/EmployeeCompetenciesGroup/EmployeeCompetenciesGroup'
import { Autocomplete, Box, Chip, Divider, Stack, Typography } from '@mui/material'
import {
    TITLE_EMPTY_PAGE_EMPLOYEE_COMPETENCIES,
    TITLE_FIELD_TYPE,
    TITLE_NAMESPACE_ALL,
    TITLE_NAMESPACE_OWNS_POSITION,
    TITLE_NOT_FOUND,
} from 'helpers/enums/titles'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import {
    CompetenceTreeHeaderWithTotal,
} from 'components/competence/tree/CompetenceTreeHeaderWithTotal/CompetenceTreeHeaderWithTotal'
import {
    EmployeeStrongSides,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabCompetencies/components/EmployeeStrongSides/EmployeeStrongSides'
import { FilterListRounded, PsychologyRounded } from '@mui/icons-material'
import { InputForm } from 'components/form/InputForm/InputForm'
import { isEmptyUuid } from 'helpers/isEmptyUuid'

const filterByType = (groups: IGradeCompetenciesGroup[], search: string | null) => {
    if (!search || isEmptyUuid(search)) return groups

    const filterCompetence = (competencies: IGradeCompetence[], search: string) => {
        return competencies.filter(competence => competence.competenceTypeTitle === search)
    }

    return groups.reduce((arr: IGradeCompetenciesGroup[], group) => {
        const competencies = filterCompetence(group.competencies, search)
        const subGroups = filterByType(group.subGroups, search)

        if (competencies.length || subGroups.length) {
            arr.push({
                ...group,
                subGroups,
                competencies,
            })
            return arr
        }

        return arr
    }, [])
}

type EmployeeTabCompetenciesProps = {
    employeeUuid: string;
    blockLink?: boolean;
}

export const EmployeeTabCompetencies: FC<EmployeeTabCompetenciesProps> = ({ employeeUuid, blockLink }) => {
    const { data: { employee } } = useAppSelector(state => state.employeeReducer)
    const [filterType, setFilterType] = useState<string | null>(null)
    const {
        data: { groupCompetencies, totalScore, existingCompetenceTypes },
        isLoading,
    } = useAppSelector(state => state.employeeViewCompetenciesReducer)
    const dispatch = useAppDispatch()
    const specializationUuid = employee?.specialization?.uuid
    const gradeUuid = employee?.grade?.uuid

    const searchValues = existingCompetenceTypes ? existingCompetenceTypes : []

    const competencies = useMemo(() => specializationUuid
            && gradeUuid
            && groupCompetencies
            && filterByType(groupCompetencies, filterType) || [],
    [filterType, specializationUuid, gradeUuid, groupCompetencies])

    const { isFetching, error } = useLayoutFetch(employeeActions.getCompetencies(
        employeeUuid || '',
        specializationUuid,
        gradeUuid,
    ), {
        deps: [employeeUuid, specializationUuid, gradeUuid],
    })

    useEffect(() => {
        return () => {
            dispatch(employeeActions.clearStateCompetence())
        }
    }, [])

    if (isFetching) return <LoadingComponent />
    if (error) return <ErrorPage error={error} />

    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        }}>
            {groupCompetencies && groupCompetencies.length > 0
                ? <Stack spacing={3}>
                    <EmployeeStrongSides/>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Stack spacing={2} direction="row" alignItems="center">
                            <FilterListRounded color="primary"/>
                            <Autocomplete
                                options={searchValues}
                                sx={{ width: 224 }}
                                value={filterType}
                                noOptionsText={TITLE_NOT_FOUND}
                                getOptionLabel={(el) => `${TITLE_FIELD_TYPE} (${el})`}
                                onChange={(event, value) => setFilterType(value as string)}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props}>
                                            <Typography
                                                variant="body1"
                                                sx={{ color: ({ palette }) => palette.text.primary }}
                                            >
                                                {option}
                                            </Typography>
                                        </li>
                                    )
                                }}
                                renderInput={(params) => (
                                    <InputForm
                                        {...params}
                                        placeholder={`${TITLE_FIELD_TYPE} (${TITLE_NAMESPACE_ALL})`}
                                    />
                                )}
                            />
                        </Stack>
                        <Chip
                            label={`${TITLE_NAMESPACE_OWNS_POSITION} ${totalScore}%`}
                            icon={<PsychologyRounded/>}
                            color="warning"
                        />
                    </Box>
                    <Box>
                        <CompetenceTreeHeaderWithTotal/>
                        <Divider sx={{ my: 2 }}/>
                        {competencies && competencies?.map(group =>
                            <EmployeeCompetenciesGroup key={group.uuid} blockLink={blockLink} element={group}/>)}
                    </Box>
                </Stack>
                : !isLoading && <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                    }}
                >
                    <EmptyPage contentText={TITLE_EMPTY_PAGE_EMPLOYEE_COMPETENCIES} />
                </Box>}
        </Box>

    )
}

