import { Button, Stack, Typography } from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
} from 'helpers/enums/titles'
import React, { FC } from 'react'
import { useMobile } from 'helpers/hooks/useMobile'

type CreatePdpCompetenceActionsProps = {
    handleClose: () => void;
    addCompetencies: () => void;
    competenceCountTitle: string;
}


export const CreatePdpCompetenceActions: FC<CreatePdpCompetenceActionsProps> = ({
    handleClose,
    addCompetencies,
    competenceCountTitle,
}) => {
    const mobile =  useMobile()

    return (
        <Stack
            spacing={2}
            sx={{
                pl: 3,
                pr: 3,
                pb: 3,
            }}
        >
            <Typography variant="body1" textAlign={mobile ? 'center' : 'right'}>
                {competenceCountTitle}
            </Typography>
            <Stack direction="row" spacing={2}>
                <Button
                    sx={mobile ? {
                        fontSize: 16,
                        lineHeight: '26px',
                        padding: '12px 20px',
                    } : {}}
                    onClick={handleClose}>
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    sx={mobile ? {
                        fontSize: 16,
                        lineHeight: '26px',
                        padding: '12px 20px',
                    } : {}}
                    onClick={addCompetencies}
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
