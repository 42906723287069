import React from 'react'
import { Card, CardContent, Divider, Stack } from '@mui/material'
import {
    CPAEmployeesHeader,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/CPAEmployeesHeader'
import {
    CPAEmployeesContent,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/CPAEmployeesContent'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useUpdateCpaEmployeesFormState } from 'store/hooks/useUpdateCpaEmployeesFormState'
import {
    CPAEmployeesCreateAlert,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesCreateAlert/CPAEmployeesCreateAlert'
import { useParams } from 'react-router-dom'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import {
    CPAEmployeeCreateUnCompleteAlert,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeeCreateUnCompleteAlert/CPAEmployeeCreateUnCompleteAlert'
import {
    CPAEmployeesStatisticEmployee,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/CPAEmployeesStatisticEmployee'


export const CPAEmployees = () => {
    const { cpaUuid } = useParams()
    const { isLoading, error } = useUpdateCpaEmployeesFormState(cpaUuid)

    if (isLoading) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    <CPAEmployeesHeader/>
                    <Authenticated service="cpa" accessLevel="wx">
                        <CPAEmployeesCreateAlert/>
                    </Authenticated>
                    <Authenticated service="cpa" accessLevel="wx">
                        <CPAEmployeeCreateUnCompleteAlert/>
                    </Authenticated>
                    <Divider/>
                    <CPAEmployeesStatisticEmployee/>
                    <CPAEmployeesContent/>
                </Stack>
            </CardContent>
        </Card>
    )
}
