import React, { useMemo } from 'react'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { CpaStatusCode } from 'helpers/cpa/cpaStatuses'
import { CpaTypeCode } from 'helpers/cpa/cpaTypes'
import { useAppSelector } from 'store/hooks/redux'
import { useAuthentication } from 'store/hooks/authentication'

const getAlertText = (
    cpaStatusCode: CpaStatusCode | null,
    cpaTypeCode: CpaTypeCode | null,
    accessLevel: AccessLevel,
    someHasNotParticipants: boolean,
    isExpired: boolean,
) => {
    const fullAccess = accessLevel === 'wx'
    const halfAccess = accessLevel === 'w'
    const statusCreated = cpaStatusCode === 'created'
    const statusReady = cpaStatusCode === 'ready'
    const statusProgress = cpaStatusCode === 'progress'
    const statusApproval = cpaStatusCode === 'approval'
    const statusComplete = cpaStatusCode === 'complete'

    const typeSelf = cpaTypeCode === 'self'
    const type180 = cpaTypeCode === '180'
    const type360 = cpaTypeCode === '360'

    if (statusComplete) return ''

    switch (true) {
        case isExpired && (statusProgress || statusApproval) && fullAccess:
            return 'Срок завершения оценки прошел. Вы можете напомнить участникам об этом или подвести итоги по имеющимся результатам.'
        case isExpired && (statusProgress || statusApproval) && halfAccess:
            return 'Срок завершения оценки прошел. Успейте подвести итоги по имеющимся результатам или обратитесь к инициатору оценки.'

        case statusCreated && (typeSelf || type180) && fullAccess:
            return 'До запуска оценки выберите критерии оценивания, настройте шкалу и добавьте оцениваемых сотрудников.'

        case statusCreated && type360 && fullAccess:
            return 'До запуска оценки выберите критерии оценивания, настройте шкалу, добавьте оцениваемых сотрудников и дополнительных участников.'

        case statusReady && type360 && fullAccess && someHasNotParticipants:
            return 'Не у всех сотрудников добавлены дополнительные участники'

        case (statusReady || statusCreated) && type360 && halfAccess && someHasNotParticipants:
            return 'Не у всех сотрудников добавлены дополнительные участники'

        case statusReady && fullAccess && !someHasNotParticipants:
            return 'Оценка готова к запуску. После запуска все участники получат опросы на прохождение.'

        case statusProgress && fullAccess:
            return 'Отслеживайте прогресс оценки сотрудников. Посмотреть предварительные результаты и сформировать итоговые можно уже после получения первых оценок.'

        case (statusCreated || statusReady) && (typeSelf || type180) && halfAccess:
            return ''
        case (statusCreated || statusReady) && type360 && halfAccess:
            return 'До запуска оценки вы можете добавить дополнительных участников оценки.'

        case statusProgress && halfAccess:
            return 'Отслеживайте прогресс оценки сотрудников. Посмотреть предварительные результаты и сформировать итоговые можно уже после получения первых оценок.'

        case statusApproval && (halfAccess || fullAccess):
            return 'Сформированы предварительные итоги оценок. Осталось проверить и утвердить результаты по каждому сотруднику.'

        default:
            return ''
    }
}

export const CPAMetaAlert = () => {
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const isExpired = useAppSelector(state => state.CPAItemReducer.isExpired)
    const cpaTypeCode = useAppSelector(state => state.CPAItemReducer.cpaTypeCode)
    const employees = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const permissionFull = useAuthentication({ accessLevel: 'wx', service: 'cpa' })
    const permissionHalf = useAuthentication({ accessLevel: 'w', service: 'cpa' })
    const permissionAccessLevel: AccessLevel = permissionFull ? 'wx' : permissionHalf ? 'w' : 'r'
    const someHasNotParticipants = useMemo(
        () => employees?.some(division => division.employees.some(employee =>
            cpaTypeCode === '360' && employee.participantCount < 2
            || cpaTypeCode === '180' && employee.participantCount < 1)),
        [employees, cpaTypeCode],
    )
    const alertTitle = getAlertText(
        cpaStatusCode,
        cpaTypeCode,
        permissionAccessLevel,
        someHasNotParticipants,
        isExpired,
    )

    if (!alertTitle) return null

    return (
        <GFAlertInfo text={alertTitle}/>
    )
}
