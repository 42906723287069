import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { PATH_DEVELOPMENT } from 'helpers/enums/routePath'
import { TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN } from 'helpers/enums/titles'
import { DevelopmentPdpItemViewUnique } from './DevelopmentPdpItemViewUnique/DevelopmentPdpItemViewUnique'
import { DevelopmentPdpItemViewCompetence } from './DevelopmentPdpItemViewCompetence/DevelopmentPdpItemViewCompetence'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { useSaveBackButtonPath } from 'helpers/hooks/useSaveBackButtonPath'

export const DevelopmentPdpItemViewPage = () => {
    const desktop = useDesktop()
    const navigate = useNavigate()
    const { pdpUuid, entityType } = useParams()
    const isUniqueItem = entityType === 'unique'

    useSaveBackButtonPath(`${PATH_DEVELOPMENT}/pdp/${pdpUuid}`)

    const developmentPdpItemViewWrapStyles = desktop ? {  width: '100%', p: 3 } : {
        width: '100%',
        border: 'none',
        boxShadow: 'none',
        overflow: 'unset',
        borderRadius: 0,
    }

    return (
        <Card sx={ developmentPdpItemViewWrapStyles }>
            {desktop &&
                <Button
                    sx={{ mb: 3 }}
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate(`${PATH_DEVELOPMENT}/pdp/${pdpUuid}`, { replace: true })}
                >
                    {TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN}
                </Button>
            }
            {isUniqueItem ?
                <DevelopmentPdpItemViewUnique />
                :
                <DevelopmentPdpItemViewCompetence />
            }
        </Card>
    )
}

