import React, { FC, useState } from 'react'
import { IconButton, Stack, SwipeableDrawer, useTheme } from '@mui/material'
import { MenuRounded } from '@mui/icons-material'
import {
    AppMenuMobileDrawerHeader,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/components/AppMenuMobileDrawerHeader/AppMenuMobileDrawerHeader'
import {
    AppMenuMobileDrawerContent,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/components/AppMenuMobileDrawerContent/AppMenuMobileDrawerContent'
import {
    AppMenuMobileDrawerFooter,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/components/AppMenuMobileDrawerFooter/AppMenuMobileDrawerFooter'


export const AppMenuMobileDrawer: FC = () => {
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleOpen}
                edge="start"
            >
                <MenuRounded/>
            </IconButton>
            <SwipeableDrawer
                variant="temporary"
                open={open}
                anchor="right"
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        background: theme.palette.background.default,
                    },
                }}
                onOpen={handleOpen}
                onClose={handleClose}
            >
                <Stack height="100%" spacing={3}>
                    <AppMenuMobileDrawerHeader handleCloseDrawer={handleClose}/>
                    <AppMenuMobileDrawerContent handleCloseDrawer={handleClose}/>
                    <AppMenuMobileDrawerFooter/>
                </Stack>
            </SwipeableDrawer>
        </>
    )
}
