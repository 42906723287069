import { AppDispatch } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import { CPAListSlice } from 'store/reducers/cpa/list/CPAListSlice'
import { ICPACreateOneRequest } from 'api/typing/cpaTypes'


export class CpaListAction {
    private static get cpaListApi() {
        return new CPAService().listService()
    }

    public getList() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAListSlice.actions.fetching())
                const data = await CpaListAction.cpaListApi.getList()
                dispatch(CPAListSlice.actions.setList(data))
                dispatch(CPAListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setList(data: ICpaListItem[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAListSlice.actions.setList(data))
        }
    }

    public createOne(payload: ICPACreateOneRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAListSlice.actions.fetching())
                const { uuid } = await CpaListAction.cpaListApi.createOne(payload)
                dispatch(CPAListSlice.actions.fetchingSuccess())
                return uuid
            } catch (e: any) {
                dispatch(CPAListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setTitleFilter(payload: string) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAListSlice.actions.setTitleFilter(payload))
        }
    }

    public setTypeFilter(payload: IEntity[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAListSlice.actions.setTypeFilter(payload))
        }
    }

    public setStatusFilter(payload: IEntity[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAListSlice.actions.setStatusFilter(payload))
        }
    }
}
