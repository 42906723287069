import { Box, Card, Chip, Divider, Stack, Typography } from '@mui/material'
import React, { FC, useMemo, useState } from 'react'
import {
    MassExamEnlargedImageDialog,
} from '../massExam/dialogs/MassExamEnlargedImageDialog/MassExamEnlargedImageDialog'
import { IExamQuestion } from '../../api/typing/exam'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getQuestionTypeCode, questionTypeData } from '../../helpers/massExam/massExamConstructor'
import { ExamQuestionCardAnswers } from './ExamQuestionCardAnswers/ExamQuestionCardAnswers'
import { ExamQuestionType } from '../../api/typing/massExam'

interface ExamQuestionCardProps {
    question:  IExamQuestion;
}

export const ExamQuestionCard: FC<ExamQuestionCardProps> = ({ question }) => {
    const [openImage, setOpenImage] = useState(false)
    const [openedImageLink, setOpenedImageLink] = useState('')
    const questionTypeCode = useMemo(() => getQuestionTypeCode(question.typeUuid), [question.typeUuid])
    const answers = useMemo(() => question.answers[questionTypeCode] || [], [questionTypeCode])
    const correctAnswersLength = useMemo(() => answers.filter(item => item.isCorrect).length, [answers])
    const correctAnsweredAnswersLength = useMemo(
        () => {
            if (questionTypeCode === ExamQuestionType.ONE_FROM_LIST) {
                return answers.filter(item => item.isCorrect && item.isAnswered).length
            }

            let correctAnswers = 0
            let incorrectAnswers = 0

            for (let i = 0; i < answers.length; i++) {
                const answer = answers[i]
                if (!answer.isAnswered) continue

                if (answer.isCorrect) {
                    correctAnswers = correctAnswers + 1
                } else {
                    incorrectAnswers = incorrectAnswers + 1
                }
            }

            return correctAnswers - incorrectAnswers > 0 ? correctAnswers - incorrectAnswers : 0
        },
        [answers],
    )

    const questionTypeChipTitle = useMemo(() => {
        if (!question) return ''

        return  questionTypeData[question.typeUuid]?.title || ''
    }, [question?.typeUuid])

    const openImageDialog = (imageLink: string) => {
        setOpenedImageLink(imageLink)
        setOpenImage(true)
    }

    const closeImageDialog = () => {
        setOpenImage(false)
        setOpenedImageLink('')
    }

    return (
        <>
            <MassExamEnlargedImageDialog
                openImage={openImage}
                closeImageDialog={closeImageDialog}
                imgLink={openedImageLink}
            />
            <Card
                sx={{
                    p: 3,
                }}
            >
                <Stack spacing={2}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Typography variant='body2' fontSize={24}>{question.sort + 1}</Typography>
                            <Chip
                                avatar={<CheckCircleIcon color='action'/>}
                                label={questionTypeChipTitle}
                            />
                        </Stack>
                        <Chip
                            label={`Итог: ${correctAnsweredAnswersLength}/${correctAnswersLength}`}
                        />
                    </Stack>
                    {!!question?.title?.length && <Typography variant='body2'>{question?.title}</Typography>}
                    {!!question.imgLink?.length &&
                        <Box>
                            <img
                                src={question.imgLink}
                                alt='question-img'
                                style={{
                                    width: 'auto',
                                    height: '200px',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => openImageDialog(question.imgLink)}
                            />
                        </Box>
                    }
                    <Divider/>
                    <ExamQuestionCardAnswers
                        question={question}
                        openImageDialog={openImageDialog}
                    />
                </Stack>
            </Card>
        </>
    )
}
