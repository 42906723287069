import React, { FC, useEffect, useMemo } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useParams, useSearchParams } from 'react-router-dom'
import {
    EmployeeTabHistoryFilter,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistory/components/EmployeeTabHistoryFilter/EmployeeTabHistoryFilter'
import { employeeActions } from 'store/actions'
import {
    EmployeeTabHistoryListItem,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistory/components/EmployeeTabHistoryListItem/EmployeeTabHistoryListItem'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import {
    TITLE_EMPTY_PAGE_EMPLOYEE_HISTORY, TITLE_EMPTY_PAGE_MY_HISTORY,
    TITLE_NAMESPACE_ACTION,
    TITLE_NAMESPACE_AUTHOR_ACTION,
    TITLE_NAMESPACE_DATE,
} from 'helpers/enums/titles'
import { HINT_NOTHING_WAS_FOUND } from 'helpers/enums/hints'

type EmployeeHistoryProps = {
    parentPath: string
    isPathMyHistory?: boolean;
}

export const EmployeeHistory: FC<EmployeeHistoryProps> = ({ parentPath, isPathMyHistory }) => {
    const { employeeUuid } = useParams()
    const { uuid: profileUuid } = useAppSelector(state => state.userReducer.data)
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const {
        data: {
            historyEventTypes,
            historyList,
        },
        isLoading,
    } = useAppSelector(state => state.employeeViewHistoryReducer)

    let isFetching = false
    let isFetchingEventTypes = false

    const recently = searchParams.get('recently') || ''
    const eventTypeUuid = searchParams.get('eventTypeUuid') || ''
    const hasQueryParams = useMemo(() => recently?.length > 0 || eventTypeUuid?.length > 0, [recently, eventTypeUuid])

    useEffect(() => {
        const fetchData = async () => {
            isFetchingEventTypes = true
            await dispatch(employeeActions.getEmployeeHistoryFilterEventTypes())
            isFetchingEventTypes = false
        }
        if (!isFetchingEventTypes) {
            fetchData().catch(e => e)
        }
    }, [])


    useEffect(() => {
        const fetchData = async () => {
            if (!employeeUuid && !profileUuid) return
            isFetching = true
            await dispatch(employeeActions.getEmployeeHistory(employeeUuid || profileUuid, { recently, eventTypeUuid }))
            isFetching = false
        }
        if (!isFetching) {
            fetchData().catch(e => e)
        }
    }, [recently, eventTypeUuid])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        }}>
            {(!isLoading && historyList?.length > 0 || hasQueryParams) &&
                <Stack spacing={3}>
                    {(!isLoading || historyEventTypes) && <EmployeeTabHistoryFilter/>}
                    <Stack spacing={1}>
                        <Box display="flex" py={0.8}>
                            <Box mr={13.5}>
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_DATE}
                                </Typography>
                            </Box>
                            <Box flex={1}>
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_ACTION}
                                </Typography>
                            </Box>
                            <Box mr={2}>
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_AUTHOR_ACTION}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider/>
                        <Stack spacing={2} divider={<Divider/>}>
                            {historyList?.map((history, i) =>
                                <EmployeeTabHistoryListItem
                                    key={`${history.createdAt}-${i}`}
                                    parentPath={parentPath}
                                    item={history}
                                />,
                            )}
                        </Stack>
                    </Stack>
                </Stack>}
            {(!isLoading && historyList?.length <= 0 && !hasQueryParams) &&
                <Box sx={{
                    flex: 1,
                    alignItems: 'center',
                    justifyItems: 'center',
                    display: 'flex',
                }}>
                    <EmptyPage
                        contentText={isPathMyHistory ? TITLE_EMPTY_PAGE_MY_HISTORY : TITLE_EMPTY_PAGE_EMPLOYEE_HISTORY}
                    />
                </Box>}
            {(!isLoading && historyList?.length <= 0 && hasQueryParams) &&
                <Box>
                    <Typography>
                        {HINT_NOTHING_WAS_FOUND}
                    </Typography>
                </Box>}
        </Box>

    )
}
