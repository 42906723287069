import { AppDispatch } from 'store/store'
import { menuSlice } from 'store/reducers/app/menu/MenuSlice'
import { AlertData, alertsSlice } from '../../reducers/app/alerts/AlertsSlice'
import { MassExamService } from '../../../api/services/massExamService'
import { AssessmentService } from '../../../api/services/AssessmentService'
import { CPAService } from '../../../api/services/CPAService'
import { ExamService } from '../../../api/services/exam'
import { DevelopmentService } from '../../../api/services/DevelopmentService'
import { FormService } from '../../../api/services/FormService'
import { MenuItemsCodesWithAlerts } from '../../../helpers/enums/menuItemsPosition'


export class AppAction {
    private static get developmentApi() {
        return new DevelopmentService().developmentPdpService()
    }
    private static get formApiEmployee() {
        return new FormService().employeeFormService()
    }
    private static get assessmentApi() {
        return new AssessmentService().assessmentService()
    }
    private static get cpaItemApi() {
        return new CPAService().itemService()
    }
    private static get massExamListApi() {
        return new MassExamService().listService()
    }
    private static get examListApi() {
        return new ExamService().listService()
    }

    public setBackButtonPath(path: string | null) {
        return (dispatch: AppDispatch) => {
            dispatch(menuSlice.actions.setBackButtonPath(path))
        }
    }

    public setAlertData(data: { menuItemCode: MenuItemsCodesWithAlerts, alertData: AlertData | null}) {
        return (dispatch: AppDispatch) => {
            dispatch(alertsSlice.actions.setAlertData(data))
        }
    }
}
