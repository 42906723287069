import { AxiosStatic } from 'axios'
import { TransferDevelopment } from 'api/services/DevelopmentService/transferDevelopment'
import {
    DevelopmentPdpUpdateUniqueRequest,
    DevelopmentPdpUpdateUniqueResponse,
    IDevelopmentCreatePdp,
    IDevelopmentMaterialPassRequest,
    IDevelopmentPdpAddItemsFromBaseRequest,
    IDevelopmentPdpAddItemsFromBaseResponse,
    IDevelopmentPdpAddMaterialsFromBaseRequest,
    IDevelopmentPdpAddMaterialsFromBaseResponse,
    IDevelopmentPdpChangeOrderItemsRequest,
    IDevelopmentPdpChangeOrderMaterialsRequest,
    IDevelopmentPdpChangePointOrDateRequest,
    IDevelopmentPdpChangePointOrDateResponse,
    IDevelopmentPdpCommentCreateRequest,
    IDevelopmentPdpCommentDeleteRequest,
    IDevelopmentPdpCommentUpdateRequest,
    IDevelopmentPdpCreateUniqueRequest,
    IDevelopmentPdpCreateUniqueResponse,
    IDevelopmentPdpDeleteItemRequest,
    IDevelopmentPdpItemCommentCreateRequest,
    IDevelopmentPdpItemCommentDeleteRequest,
    IDevelopmentPdpItemCommentUpdateRequest,
    IDevelopmentPdpItemMaterialCommentCreateRequest,
    IDevelopmentPdpItemMaterialCommentDeleteRequest,
    IDevelopmentPdpItemMaterialCommentUpdateRequest,
    IDevelopmentPdpItemPassRequest,
    IDevelopmentPdpItemPassResponse,
    IDevelopmentPdpMaterialCreateRequest,
    IDevelopmentPdpMaterialCreateResponse,
    IDevelopmentPdpMaterialDeleteRequest,
    IDevelopmentPdpMaterialUpdateRequest,
    IDevelopmentPdpProgressStatusItem,
    IDevelopmentPdpUpdate,
} from 'api/typing/developmentPdpService'

export class DevelopmentPdpService extends TransferDevelopment {
    private static instance: DevelopmentPdpService
    private prefix = 'pdp'

    static getInstance(axiosInstance: AxiosStatic) {
        if (!DevelopmentPdpService.instance)
            DevelopmentPdpService.instance = new DevelopmentPdpService(axiosInstance)
        return DevelopmentPdpService.instance
    }

    public sendToApproval(data: { pdpUuid: string }) {
        const path = `/${this.prefix}/${data.pdpUuid}/status/approval`
        return this.request<{ pdpUuid: string }, { status: IEntity }>('POST', path, { data })
    }

    public sendToReview(data: { pdpUuid: string }) {
        const path = `/${this.prefix}/${data.pdpUuid}/status/review`
        return this.request<{ pdpUuid: string }, { status: IEntity }>('POST', path, { data })
    }

    // PDP
    public getPdp() {
        const path = `/${this.prefix}`
        return this.request<null, IDevelopmentPdp>('GET', path)
    }

    public createPdp(data: IDevelopmentCreatePdp) {
        const path = `/${this.prefix}/new`
        return this.request<IDevelopmentCreatePdp, IPdp>('POST', path, { data })
    }

    public updatePdp(data: IDevelopmentPdpUpdate) {
        const path = `${this.prefix}/${data.pdpUuid}`
        return this.request<IDevelopmentPdpUpdate, IPdp>('POST', path, { data })
    }

    // delete pdp
    public deletePdp(data: { pdpUuid: string }) {
        const path = `${this.prefix}/${data.pdpUuid}`
        return this.request<{ pdpUuid: string }, null>('DELETE', path, { data })
    }

    public changePdpPointOrDate(data: IDevelopmentPdpChangePointOrDateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}`
        return this.request<IDevelopmentPdpChangePointOrDateRequest, IDevelopmentPdpChangePointOrDateResponse>('PUT', path, { data })
    }

    // comments for pdp
    public createPdpComment(data: IDevelopmentPdpCommentCreateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/comment`
        return this.request<IDevelopmentPdpCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpComment(data: IDevelopmentPdpCommentUpdateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpComment(data: IDevelopmentPdpCommentDeleteRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // Comments for item pdp
    public createPdpItemComment(data: IDevelopmentPdpItemCommentCreateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/comment`
        return this.request<IDevelopmentPdpItemCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpItemComment(data: IDevelopmentPdpItemCommentUpdateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpItemCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpItemComment(data: IDevelopmentPdpItemCommentDeleteRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpItemCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // Comments for material of pdp item
    public createPdpItemMaterialComment(data: IDevelopmentPdpItemMaterialCommentCreateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment`
        return this.request<IDevelopmentPdpItemMaterialCommentCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public updatePdpItemMaterialComment(data: IDevelopmentPdpItemMaterialCommentUpdateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpItemMaterialCommentUpdateRequest, null>('PUT', path, { data })
    }

    public deletePdpItemMaterialComment(data: IDevelopmentPdpItemMaterialCommentDeleteRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.materialUuid}/comment/${data.uuid}`
        return this.request<IDevelopmentPdpItemMaterialCommentDeleteRequest, null>('DELETE', path, { data })
    }

    // PDP items
    // view unique plan item
    public getPdpPlanItem(data: { pdpUuid: string; itemUuid: string }) {
        const path = `/${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}`
        return this.request<null, IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>>('GET', path)
    }

    // create unique pdp item
    public createUniquePdpItem(data: IDevelopmentPdpCreateUniqueRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/create/one`
        return this.request<IDevelopmentPdpCreateUniqueRequest, IDevelopmentPdpCreateUniqueResponse>('POST', path, { data })
    }

    // add pdp items from base
    public addPdpItemsFromBase(data: IDevelopmentPdpAddItemsFromBaseRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/setup`
        return this.request<IDevelopmentPdpAddItemsFromBaseRequest, IDevelopmentPdpAddItemsFromBaseResponse>('POST', path, { data })
    }

    // update unique pdp item
    public updateUniquePdpItem(data: DevelopmentPdpUpdateUniqueRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.uuid}`
        return this.request<DevelopmentPdpUpdateUniqueRequest, DevelopmentPdpUpdateUniqueResponse>('PUT', path, { data })
    }

    // delete unique pdp item
    public deletePdpItem(data: IDevelopmentPdpDeleteItemRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}`
        return this.request<IDevelopmentPdpDeleteItemRequest, IDevelopmentPdpProgressStatusItem>('DELETE', path, { data })
    }

    // change order pdp items
    public changeOrderPdpItems(data: IDevelopmentPdpChangeOrderItemsRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/order`
        return this.request<IDevelopmentPdpChangeOrderItemsRequest, null>('POST', path, { data })
    }

    public passItemUnique(data: IDevelopmentPdpItemPassRequest) {
        const path = `/${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/pass`
        return this.request<IDevelopmentPdpItemPassRequest, IDevelopmentPdpItemPassResponse>('PUT', path, { data })
    }

    public passPdpCompetenceMaterial(data: IDevelopmentMaterialPassRequest) {
        const path = `/${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.materialUuid}/pass`
        return this.request<IDevelopmentMaterialPassRequest, IDevelopmentPdpItemPassResponse>('PUT', path, { data })
    }

    // create unique material
    public createItemMaterial(data: IDevelopmentPdpMaterialCreateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/new`
        return this.request<IDevelopmentPdpMaterialCreateRequest, IDevelopmentPdpMaterialCreateResponse>('POST', path, { data })
    }

    // add materials from base
    public setupPdpCompetenceMaterials(data: IDevelopmentPdpAddMaterialsFromBaseRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/setup`
        return this.request<IDevelopmentPdpAddMaterialsFromBaseRequest, IDevelopmentPdpAddMaterialsFromBaseResponse>('POST', path, { data })
    }

    // update unique material
    public updateItemMaterial(data: IDevelopmentPdpMaterialUpdateRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.uuid}`
        return this.request<IDevelopmentPdpMaterialUpdateRequest, IDevelopmentPdpProgressStatusItem>('PUT', path, { data })
    }

    // delete material
    public deleteItemMaterial(data: IDevelopmentPdpMaterialDeleteRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/${data.uuid}`
        return this.request<IDevelopmentPdpMaterialDeleteRequest, IDevelopmentPdpProgressStatusItem>('DELETE', path, { data })
    }

    // change order pdp items
    public changeOrderPdpMaterials(data: IDevelopmentPdpChangeOrderMaterialsRequest) {
        const path = `${this.prefix}/${data.pdpUuid}/item/${data.itemUuid}/material/order`
        return this.request<IDevelopmentPdpChangeOrderMaterialsRequest, null>('POST', path, { data })
    }
}
