import { BaseApi } from 'api/index'
import {
    IAssessmentChangeStatusRequest,
    IAssessmentCommentCreateUpdate,
    IAssessmentCommentDelete,
    IAssessmentCreateRequest,
    IAssessmentIndicatorCommentCreateUpdate,
    IAssessmentIndicatorCommentDelete,
    IAssessmentSetAnswerRequest,
    IAssessmentUpdateRequest,
} from 'api/typing/assessmentServiceTypes'

export class Assessment extends BaseApi {
    protected endpoint = 'assessment'

    public getAssessmentOptions(employeeUuid: string) {
        const path = `/employee/${employeeUuid}/options`
        return this.request<null, IAssessmentOptions>('GET', path)
    }

    public assessmentGet(uuid: string, isPass: boolean) {
        const path = `/${uuid}`
        return this.request<null, IAssessment>('GET', path, { params: { isPass } })
    }

    public assessmentMetaGet(uuid: string) {
        const path = `/${uuid}/meta`
        return this.request<null, IAssessmentMeta>('GET', path)
    }

    // Создание опросника, возможно без даты окончания (для сотрудника)
    public assessmentCreate(data: IAssessmentCreateRequest) {
        const path = '/new'
        return this.request<IAssessmentCreateRequest, IAssessmentMeta>('POST', path, { data })
    }

    public assessmentDelete(uuid: string) {
        const path = `/${uuid}`
        return this.request<{ uuid: string }, null>('DELETE', path, { data: { uuid } })
    }

    // Изменение опросника (дата окончания и связки специализация-грейд, если он ещё не был отправлен)
    public assessmentUpdate(data: IAssessmentUpdateRequest) {
        const path = `/${data.uuid}`
        return this.request<IAssessmentUpdateRequest, IAssessmentMeta>('PUT', path, { data })
    }

    // Продвижение опросника по статусам
    public assessmentStatus(data: IAssessmentChangeStatusRequest) {
        const path = `/${data.uuid}/status`
        return this.request<IAssessmentChangeStatusRequest, null>('POST', path, { data })
    }

    // Работа с комментариями к assessment
    public assessmentCommentCreateUpdate(data: IAssessmentCommentCreateUpdate) {
        const path = `/${data.assessmentUuid}/comment`
        return this.request<IAssessmentCommentCreateUpdate, { uuid: string }>('POST', path, { data })
    }

    // Работа с комментариями к assessment
    public assessmentCommentDelete(data: IAssessmentCommentDelete) {
        const path = `/${data.assessmentUuid}/comment`
        return this.request<IAssessmentCommentDelete, null>('DELETE', path, { data })
    }

    public assessmentSetAnswer(data: IAssessmentSetAnswerRequest) {
        const path = `/${data.assessmentUuid}/indicator/${data.indicatorUuid}`
        return this.request<IAssessmentSetAnswerRequest, { score: number, skillLevelScore: number }>('POST', path, { data })
    }

    // Работа с комментариями к индикатору
    public assessmentIndicatorCommentCreateUpdate(data: IAssessmentIndicatorCommentCreateUpdate) {
        const path = `/${data.assessmentUuid}/indicator/${data.indicatorUuid}/comment`
        return this.request<IAssessmentIndicatorCommentCreateUpdate, { uuid: string }>('POST', path, { data })
    }

    // Работа с комментариями к индикатору
    public assessmentIndicatorCommentDelete(data: IAssessmentIndicatorCommentDelete) {
        const path = `/${data.assessmentUuid}/indicator/${data.indicatorUuid}/comment`
        return this.request<IAssessmentIndicatorCommentDelete, null>('DELETE', path, { data })
    }

    // Сохранение откалиброванных ответов
    public assessmentCalibrateDone(uuid: string) {
        const path = `/${uuid}/calibrate/done`
        return this.request<{ uuid: string }, { statusUuid: string }>('POST', path, { data: { uuid } })
    }

    // Сохранение откалиброванных ответов
    public assessmentCalibrateCancel(uuid: string) {
        const path = `/${uuid}/calibrate/cancel`
        return this.request<{ uuid: string }, { statusUuid: string }>('POST', path, { data: { uuid } })
    }

    // Получение результата
    public assessmentResult(uuid: string) {
        const path = `/${uuid}/result`
        return this.request<null, IAssessmentResult>('GET', path)
    }

    // Получение соответсвия результата уровням
    public assessmentResultAccordance(uuid: string) {
        const path = `/${uuid}/criteria/point/level-compliance`
        return this.request<null, IAssessmentAccordance[]>('GET', path)
    }

    // Создание ипр на основании асс
    public assessmentCreatePdp(assessmentUuid: string) {
        const path = `/${assessmentUuid}/pdp`
        return this.request<{ assessmentUuid: string }, { uuid: string }>('POST', path, { data: { assessmentUuid } })
    }

    // Уведомление о просроченой оценке
    public getLastAssessmentNotifyDeadline(assessmentUuid: string) {
        const path = `/${assessmentUuid}/notify/deadline/last`
        return this.request<null, { date: string }>('GET', path)
    }    // Уведомление о просроченой оценке
    public assessmentNotifyDeadline(assessmentUuid: string) {
        const path = `/${assessmentUuid}/notify/deadline`
        return this.request('POST', path, { data: { assessmentUuid } })
    }

    // Уведомление о необходимости калибровки оценке
    public getLastAssessmentNotifyReview(assessmentUuid: string) {
        const path = `/${assessmentUuid}/notify/review/last`
        return this.request<null, { date: string }>('GET', path)
    }

    public assessmentNotifyReview(assessmentUuid: string) {
        const path = `/${assessmentUuid}/notify/review`
        return this.request('POST', path, { data: { assessmentUuid } })
    }
}
