import { TransferCPA } from 'api/services/CPAService/transferCPA'
import { ICPACreateOneRequest } from 'api/typing/cpaTypes'

export class CPAListService extends TransferCPA {

    public getList() {
        return this.request<null, ICpaListItem[]>('GET', '/all')
    }

    public createOne(data: ICPACreateOneRequest) {
        const path = '/create/one'
        return this.request<ICPACreateOneRequest, { uuid: string }>('POST', path, { data })
    }
}
