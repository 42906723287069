import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICpaGetEmployeesResponse } from 'api/typing/cpaTypes'


interface ICPAItemAnalyticsState {
    filters: {
        divisions: {
            initial: ISpecializationMetaForCreateDivision[];
            filtered: ISpecializationMetaForCreateDivision[];
            selectedUuids: string[];
        };
        specializations: {
            initial: IFormSpecialization[];
            filtered: IFormSpecialization[];
            selectedUuids: string[];
        };
        competencies: {
            initial: IEntity[];
            selectedUuids: string[];
        };
        employees: {
            filtered: TAnalyticCpaCurrentPosition[];
            selectedUuids: string[];
        };
    }
    summary: Partial<TAnalyticCpaStatisticSummary> & {
        employeesFlatForSummary: Record<string, ICpaItemFlatAssessedEmployees>;
    };
    currentPosition: {
        initial: TAnalyticCpaCurrentPosition[];
        filtered: TAnalyticCpaCurrentPosition[];
    };
    competencePoint: {
        initialCompetence: string[];
        initialEmployees: string[];

        filteredCompetence: string[];
        filteredEmployees: string[];

        initialCompetenceMap: Record<string, TAnalyticCpaCompetence>;
        initialEmployeesMap: Record<string, TAnalyticCpaCompetenceEmployee>;

        initialEmployeesScore: Record<string, Record<string, TAnalyticCpaCompetenceEmployeeItem>>;
        initialGrades: TAnalyticCpaCriteriaCompetenceGradeEmployees[];

        gradeState: {
            grades: { uuid: string; title: string; score: number }[];
            maxValue: number;
        };
    };
    competenceTree: TAnalyticCpaCompetenceTreeGroup[];
    isLoading: boolean;
    error: IErrorResponse | null;
}

const initialState: ICPAItemAnalyticsState = {
    filters: {
        divisions: {
            initial: [],
            filtered: [],
            selectedUuids: [],
        },
        specializations: {
            initial: [],
            filtered: [],
            selectedUuids: [],
        },
        competencies: {
            initial: [],
            selectedUuids: [],
        },
        employees: {
            // initial: [],
            filtered: [],
            selectedUuids: [],
        },
    },
    currentPosition: {
        initial: [],
        filtered: [],
    },
    competencePoint: {
        filteredCompetence: [],
        filteredEmployees: [],

        initialCompetence: [],
        initialEmployees: [],
        initialCompetenceMap: {} as Record<string, TAnalyticCpaCompetence>,
        initialEmployeesMap: {} as Record<string, TAnalyticCpaCompetenceEmployee>,

        initialEmployeesScore: {} as Record<string, Record<string, TAnalyticCpaCompetenceEmployeeItem>>,
        initialGrades: [],
        gradeState: {} as {
            grades: { uuid: string; title: string; score: number }[]
            maxValue: number
        },
    },
    competenceTree: [],
    summary: {
        employeesFlatForSummary: {} as Record<string, ICpaItemFlatAssessedEmployees>,
    },
    isLoading: false,
    error: null,
}

const calculateGradeState = (
    gradeList: TAnalyticCpaCriteriaCompetenceGradeEmployees[],
    initialEmployeesScore: Record<string, Record<string, TAnalyticCpaCompetenceEmployeeItem>>,
    filteredEmployees: string[],
    filteredCompetence: string[],
): {
    grades: { uuid: string; title: string; score: number }[]
    maxValue: number
} => {
    const gradesScore: { uuid: string; title: string; score: number }[] = []
    gradeList.forEach(grade => {
        const scores = []
        for (const employeeUuid of filteredEmployees) {
            const employeeScore = []
            for (const competenceUuid of filteredCompetence) {
                const score = initialEmployeesScore?.[competenceUuid]?.[employeeUuid]
                    ?.levelCompliance
                    ?.find(el => el.uuid === grade.uuid)?.score
                if (score !== null && typeof score !== 'undefined')
                    employeeScore.push(score ?? 0)
            }
            if (!employeeScore.length) continue
            scores.push(Math.floor(employeeScore.reduce((acc, el) => acc + el, 0) / employeeScore.length))
        }
        const gradeData = {
            ...grade,
            score: scores.length === 0 ? 0 : Math.floor(scores.reduce((acc, el) => acc + el, 0) / scores.length),
        }
        gradesScore.push(gradeData)
    })
    const maxValue = Math.max(...gradesScore.map(g => g.score))

    return {
        grades: gradesScore,
        maxValue,
    }
}

const filterCompetenceCriteriaList = (
    list: string[],
    employeesMap: Record<string, TAnalyticCpaCompetenceEmployee>,
    filterDivisions: string[],
    filterEmployees: string[],
): string[] => {
    if (!filterDivisions.length && !filterEmployees.length) return list

    const filterByDivisions = (divisions: string[], uuid: string) => {
        if (!divisions.length) return true

        return filterDivisions.includes(uuid)
    }

    const filterByEmployees = (employees: string[] | undefined, uuid: string) => {
        if (!employees?.length) return true

        return employees.includes(uuid)
    }

    return list.filter(el => filterByDivisions(filterDivisions, employeesMap[el].division.uuid)
        && filterByEmployees(filterEmployees, el))
}

const filterCurrentPositionList = (
    list: TAnalyticCpaCurrentPosition[] | Record<string, TAnalyticCpaCompetenceEmployee>,
    filterDivisions: string[],
    filterSpecializations: string[],
    filterEmployees?: string[],
) => {
    const currentList: TAnalyticCpaCurrentPosition[] = Array.isArray(list)
        ? list
        : Object.values(list).map(({ division, specialization, grade, ...employee }) => ({
            division,
            specialization,
            grade,
            employee,
            score: 0,
            assessmentUuid: '',
        }))

    if (filterDivisions.length <= 0
        && filterSpecializations.length <= 0
        && filterEmployees && filterEmployees?.length <= 0
    )
        return [...currentList]

    const filterByDivisions = (divisions: string[], uuid: string) => {
        if (!divisions.length) return true

        return filterDivisions.includes(uuid)
    }

    const filterBySpecializations = (specializations: string[], uuid: string) => {
        if (!specializations.length) return true

        return specializations.includes(uuid)
    }
    const filterByEmployees = (employees: string[] | undefined, uuid: string) => {
        if (!employees?.length) return true

        return employees.includes(uuid)
    }

    return currentList.filter(el => filterByDivisions(filterDivisions, el.division.uuid)
        && filterBySpecializations(filterSpecializations, el.specialization.uuid)
        && filterByEmployees(filterEmployees, el.employee.uuid))
}

export const CPAItemAnalyticsSlice = createSlice({
    name: 'CPAItemAnalytics',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setDivisionListInitial: (state, action: PayloadAction<ISpecializationMetaForCreateDivision[]>) => {
            if (state.currentPosition.initial.length) {
                state.filters.divisions.initial = action
                    .payload
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.division.uuid === el.uuid))
                state.filters.divisions.filtered = [...state.filters.divisions.initial]
            } else if (Object.keys(state.competencePoint.initialEmployeesMap).length) {
                state.filters.divisions.initial = action
                    .payload
                    .filter(el => Object.values(state
                        .competencePoint
                        .initialEmployeesMap)
                        .some(employee => employee.division.uuid === el.uuid))
                state.filters.divisions.filtered = [...state.filters.divisions.initial]
            } else {
                state.filters.divisions.initial = [...action.payload]
                state.filters.divisions.filtered = [...action.payload]
            }
        },

        setSpecializationListInitial: (state, action: PayloadAction<IFormSpecialization[]>) => {
            if (state.currentPosition.initial.length) {
                state.filters.specializations.initial = action
                    .payload
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.specialization.uuid === el.uuid))
                state.filters.specializations.filtered = [...state.filters.specializations.initial]
            } else {
                state.filters.specializations.initial = [...action.payload]
                state.filters.specializations.filtered = [...action.payload]
            }
        },

        selectFilterDivision: (state, action: PayloadAction<string[]>) => {
            state.filters.divisions.selectedUuids = action.payload
            const setTitlesFromDivisions = new Set(state
                .filters
                .divisions
                .initial
                .filter(el => state.filters.divisions.selectedUuids.includes(el.uuid)).map(el => el.title))

            state.filters.specializations.filtered = state
                .filters
                .specializations
                .initial
                .filter(el => setTitlesFromDivisions.size ? setTitlesFromDivisions.has(el.parentDivisionTitle) : true)

            const employeesForFilter = state.currentPosition.initial.length > 0
                ? state.currentPosition.initial
                : state.competencePoint.initialEmployeesMap

            state.filters.employees.filtered = filterCurrentPositionList(
                employeesForFilter,
                state.filters.divisions.selectedUuids,
                state.filters.specializations.selectedUuids,
            )
            state.currentPosition.filtered = filterCurrentPositionList(
                state.currentPosition.initial,
                state.filters.divisions.selectedUuids,
                state.filters.specializations.selectedUuids,
                state.filters.employees.selectedUuids,
            )
            // state.competencePoint.filteredEmployees = state.filters.employees.filtered.map(el => el.employee.uuid)
            state.competencePoint.filteredEmployees = filterCompetenceCriteriaList(
                state.competencePoint.initialEmployees,
                state.competencePoint.initialEmployeesMap,
                state.filters.divisions.selectedUuids,
                state.filters.employees.selectedUuids,
            )
        },

        selectFilterSpecialization: (state, action: PayloadAction<string[]>) => {
            state.filters.specializations.selectedUuids = action.payload
            state.filters.employees.filtered = filterCurrentPositionList(
                state.currentPosition.initial,
                state.filters.divisions.selectedUuids,
                state.filters.specializations.selectedUuids,
            )
            state.currentPosition.filtered = filterCurrentPositionList(
                state.currentPosition.initial,
                state.filters.divisions.selectedUuids,
                state.filters.specializations.selectedUuids,
            )
        },

        selectFilterCompetence: (state, action: PayloadAction<string[]>) => {
            state.filters.competencies.selectedUuids = action.payload
            state.competencePoint.filteredCompetence = action.payload.length > 0
                ? action.payload
                : state.competencePoint.initialCompetence

            state.competencePoint.gradeState = calculateGradeState(
                state.competencePoint.initialGrades,
                state.competencePoint.initialEmployeesScore,
                state.competencePoint.filteredEmployees,
                state.competencePoint.filteredCompetence,
            )
        },

        selectFilterEmployee: (state, action: PayloadAction<string[]>) => {
            state.filters.employees.selectedUuids = action.payload

            state.currentPosition.filtered = filterCurrentPositionList(
                state.currentPosition.initial,
                state.filters.divisions.selectedUuids,
                state.filters.specializations.selectedUuids,
                state.filters.employees.selectedUuids,
            )

            state.competencePoint.filteredEmployees = filterCompetenceCriteriaList(
                state.competencePoint.initialEmployees,
                state.competencePoint.initialEmployeesMap,
                state.filters.divisions.selectedUuids,
                state.filters.employees.selectedUuids,
            )

            state.competencePoint.gradeState = calculateGradeState(
                state.competencePoint.initialGrades,
                state.competencePoint.initialEmployeesScore,
                state.competencePoint.filteredEmployees,
                state.competencePoint.filteredCompetence,
            )
        },

        setSummary: (state, action: PayloadAction<TAnalyticCpaStatisticSummary>) => {
            state.summary.average = action.payload.average
            state.summary.competencies = action.payload.competencies
            state.summary.employees = action.payload.employees
        },

        setCpaCurrentPositionFlatEmployees: (state, action: PayloadAction<TAnalyticCpaCurrentPosition[]>) => {
            action.payload.forEach(el => {
                state.summary.employeesFlatForSummary[el.employee.uuid] = {
                    uuid: el.employee.uuid,
                    fullName: `${el.employee.lastName} ${el.employee.firstName} ${el.employee.secondName}`,
                    grade: el.grade,
                    specialization: el.specialization,
                    divisionUuid: el.division.uuid,
                    divisionTitle: el.division.title,
                }
            })
        },

        setCpaPointCompetenceFlatEmployees: (state, action: PayloadAction<TAnalyticCpaCriteriaCompetence>) => {
            action.payload.employees.forEach(el => {
                state.summary.employeesFlatForSummary[el.uuid] = {
                    uuid: el.uuid,
                    fullName: `${el.lastName} ${el.firstName} ${el.secondName}`,
                    grade: el.grade,
                    specialization: el.specialization,
                    divisionUuid: el.division.uuid,
                    divisionTitle: el.division.title,
                }
            })
        },

        employeesFlatForSummary: (state, action: PayloadAction<ICpaGetEmployeesResponse>) => {
            action.payload.divisions.forEach(division => {
                division.employees.forEach(employee => {
                    state.summary.employeesFlatForSummary[employee.uuid] = {
                        uuid: employee.uuid,
                        fullName: `${employee.lastName} ${employee.firstName} ${employee.secondName}`,
                        grade: employee.grade,
                        specialization: employee.specialization,
                        divisionUuid: division.uuid,
                        divisionTitle: division.title,
                    }
                })
            })
        },

        setCpaCurrentPosition: (state, action: PayloadAction<TAnalyticCpaCurrentPosition[]>) => {
            state.currentPosition.initial = action.payload
            state.currentPosition.filtered = action.payload
            state.filters.employees.filtered = action.payload

            if (state.filters.divisions.initial.length) {
                state.filters.divisions.initial = state
                    .filters
                    .divisions
                    .initial
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.division.uuid === el.uuid))
                state.filters.divisions.filtered = [...state.filters.divisions.initial]
            }

            if (state.filters.specializations.initial.length) {
                state.filters.specializations.initial = state
                    .filters
                    .specializations
                    .initial
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.specialization.uuid === el.uuid))
                state.filters.specializations.filtered = [...state.filters.specializations.initial]
            }
        },

        setCpaCurrentPositionV2: (state, action: PayloadAction<TAnalyticCpaCriteriaCompetence>) => {
            state.filters.employees.filtered = []
            state.currentPosition.initial = []
            state.currentPosition.filtered = []
            state.competencePoint.initialEmployeesScore =
                {} as Record<string, Record<string, TAnalyticCpaCompetenceEmployeeItem>>

            action.payload.employees.forEach(el => {
                state.competencePoint.initialEmployeesMap[el.uuid] = el
                state.competencePoint.filteredEmployees.push(el.uuid)
                state.competencePoint.initialEmployees.push(el.uuid)
                const item = {
                    division: el.division,
                    grade: el.grade,
                    specialization: el.specialization,
                    assessmentUuid: '',
                    score: 0,
                    employee: el,
                }
                state.currentPosition.initial.push(item)
                state.currentPosition.filtered.push(item)
                state.filters.employees.filtered.push(item)
            })
            if (state.filters.divisions.initial.length) {
                state.filters.divisions.initial = state
                    .filters
                    .divisions
                    .initial
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.division.uuid === el.uuid))
                state.filters.divisions.filtered = [...state.filters.divisions.initial]
            }

            if (state.filters.specializations.initial.length) {
                state.filters.specializations.initial = state
                    .filters
                    .specializations
                    .initial
                    .filter(el => state
                        .currentPosition
                        .initial
                        .some(employee => employee.specialization.uuid === el.uuid))
                state.filters.specializations.filtered = [...state.filters.specializations.initial]
            }

            action.payload.competencies.forEach(el => {
                state.competencePoint.initialCompetenceMap[el.uuid] = el
                state.competencePoint.filteredCompetence.push(el.uuid)
                state.competencePoint.initialCompetence.push(el.uuid)
                state.filters.competencies.initial.push({ uuid: el.uuid, title: el.title })

                el.employees.forEach(employee => state.competencePoint.initialEmployeesScore[el.uuid] = {
                    ...state.competencePoint.initialEmployeesScore[el.uuid],
                    [employee.uuid]: employee,
                })
            })
        },

        setCpaPointCompetence: (state, action: PayloadAction<TAnalyticCpaCriteriaCompetence>) => {
            state.currentPosition.initial = []
            state.currentPosition.filtered = []
            state.competencePoint.initialEmployeesMap = {} as Record<string, TAnalyticCpaCompetenceEmployee>
            state.competencePoint.filteredEmployees = []
            state.competencePoint.initialEmployees = []
            state.filters.employees.filtered = []

            state.competencePoint.initialCompetenceMap = {} as Record<string, TAnalyticCpaCompetence>
            state.competencePoint.filteredCompetence = []
            state.competencePoint.initialCompetence = []
            state.filters.competencies.initial = []

            state.competencePoint.initialEmployeesScore =
                {} as Record<string, Record<string, TAnalyticCpaCompetenceEmployeeItem>>

            action.payload.employees.forEach(el => {
                state.competencePoint.initialEmployeesMap[el.uuid] = el
                state.competencePoint.filteredEmployees.push(el.uuid)
                state.competencePoint.initialEmployees.push(el.uuid)
                state.filters.employees.filtered.push({
                    division: el.division,
                    grade: el.grade,
                    specialization: el.specialization,
                    assessmentUuid: '',
                    score: 0,
                    employee: el,
                })
            })

            action.payload.competencies.forEach(el => {
                state.competencePoint.initialCompetenceMap[el.uuid] = el
                state.competencePoint.filteredCompetence.push(el.uuid)
                state.competencePoint.initialCompetence.push(el.uuid)
                state.filters.competencies.initial.push({ uuid: el.uuid, title: el.title })

                el.employees.forEach(employee => state.competencePoint.initialEmployeesScore[el.uuid] = {
                    ...state.competencePoint.initialEmployeesScore[el.uuid],
                    [employee.uuid]: employee,
                })
            })

            state.competencePoint.initialGrades = [...action.payload?.grades ?? []]
                .sort((a, b) => a.sortIndex - b.sortIndex)

            state.competencePoint.gradeState = calculateGradeState(
                state.competencePoint.initialGrades,
                state.competencePoint.initialEmployeesScore,
                state.competencePoint.filteredEmployees,
                state.competencePoint.filteredCompetence,
            )
        },

        setCpaCompetenceTree: (state, action: PayloadAction<TAnalyticCpaCompetenceTreeGroup[]>) => {
            state.competenceTree = action.payload
        },
    },
})

export const CPAItemAnalyticsReducer = CPAItemAnalyticsSlice.reducer
