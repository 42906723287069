import React, { useMemo } from 'react'
import { Box, Drawer, Stack, Typography, Dialog, Divider } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { sortArrayFromSortIndex } from 'helpers/sortArray'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    TITLE_BUTTON_ADD_FROM_DATABASE,
    TITLE_BUTTON_CREATE_MATERIAL,
    TITLE_NAMESPACE_KNOWLEDGE_BASE,
} from 'helpers/enums/titles'
import { useDialogsState } from 'store/hooks/useDialogsState'
import {
    PdpCompetenceMaterialCreateUpdate,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewCompetence/components/PdpItemViewCompetenceContent/dialogs/PdpCompetenceMaterialCreateUpdate/PdpCompetenceMaterialCreateUpdate'
import {
    DrawerPdpSetupMaterials,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewCompetence/components/PdpItemViewCompetenceContent/dialogs/DrawerPdpSetupMaterials/DrawerPdpSetupMaterials'
import { AddButton } from 'components/common/AddButton/AddButton'
import { useParams } from 'react-router-dom'
import { useMobile } from 'helpers/hooks/useMobile'
import {
    DevelopmentPdpCompetenceLevel,
} from './components/DevelopmentPdpCompetenceLevel/DevelopmentPdpCompetenceLevel'
import { useDesktop } from 'helpers/hooks/useDesktop'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'

export const DevelopmentPdpItemViewCompetenceContent = () => {
    const mobile = useMobile()
    const desktop = useDesktop()
    const { pdpUuid } = useParams()
    const { dialogCreateMaterial, dialogSetupMaterial, toggleDialog } = useDialogsState({
        dialogCreateMaterial: false,
        dialogSetupMaterial: false,
    })
    const { item } = useAppSelector(state => state.developmentPdpItemReducer.data)

    const pdpStatus = getPdpStatus(item?.pdpStatus, false)

    const canManagePdp = pdpStatus.status === 'default' // && item.author.uuid === item.employee.uuid

    const filteredLevels = useMemo(
        () => item?.skillLevels
            ? sortArrayFromSortIndex(item?.skillLevels).filter(level => level.materials.length > 0)
            : [],
        [item, item?.skillLevels],
    )

    return (
        <Stack spacing={6}>
            {pdpUuid && dialogCreateMaterial &&
                <PdpCompetenceMaterialCreateUpdate
                    employee={item?.employee}
                    pdpUuid={pdpUuid}
                    item={item}
                    isDevelopment
                    open={dialogCreateMaterial}
                    handleClose={() => toggleDialog('dialogCreateMaterial', false)}
                />
            }
            {mobile ?
                <Dialog
                    fullScreen
                    open={dialogSetupMaterial}
                    onClose={() => toggleDialog('dialogSetupMaterial', false)}
                >
                    <DrawerPdpSetupMaterials
                        isDevelopment
                        handleClose={() => toggleDialog('dialogSetupMaterial', false)}
                    />
                </Dialog>
                :
                <Drawer
                    anchor="right"
                    open={dialogSetupMaterial}
                    onClose={() => toggleDialog('dialogSetupMaterial', false)}
                >
                    <DrawerPdpSetupMaterials
                        isDevelopment
                        handleClose={() => toggleDialog('dialogSetupMaterial', false)}
                    />
                </Drawer>
            }
            {!desktop && <Divider />}
            <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={desktop ? 'row' : 'column'}
                gap={desktop ? 0 : 3}
            >
                <Box>
                    <Typography variant={desktop ? 'h3' : 'h6'}>
                        {TITLE_NAMESPACE_KNOWLEDGE_BASE}
                    </Typography>
                </Box>
                {canManagePdp &&
                    <Box
                        display="flex"
                        flexDirection={desktop ? 'row' : 'column'}
                        gap={desktop ? 0 : 1}
                        width={desktop ? 'initial' : 'fit-content'}
                    >
                        <AddButton
                            icon={!desktop && <AddToPhotosIcon />}
                            sx={{ pl: desktop ? 'initial' : 0 }}
                            onClick={() => toggleDialog('dialogCreateMaterial', true)}
                        >
                            {TITLE_BUTTON_CREATE_MATERIAL}
                        </AddButton>
                        <AddButton
                            variant="outlined"
                            onClick={() => toggleDialog('dialogSetupMaterial', true)}
                        >
                            {TITLE_BUTTON_ADD_FROM_DATABASE}
                        </AddButton>
                    </Box>
                }
            </Box>
            {filteredLevels.map((level, index) => (
                <>
                    <DevelopmentPdpCompetenceLevel key={level.uuid} level={level} pdpStatus={pdpStatus}/>
                    {!desktop && filteredLevels.length !== index + 1 && <Divider/>}
                </>
            ))}
        </Stack>
    )
}
