import React from 'react'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { useAppSelector } from 'store/hooks/redux'
import {
    AssessmentResultPdpButton,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultAlertPdp/components/AssessmentResultPdpButton/AssessmentResultPdpButton'

export const AssessmentResultAlertPdp = () => {
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)

    if (!resultSetup?.showAlertPdp) return null
    return (
        <GFAlertInfo
            action={<AssessmentResultPdpButton />}
            text={resultSetup.alertPdpText}
        />
    )
}
