import React, { FC, useEffect, useMemo } from 'react'
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { employeeActions } from 'store/actions'
import { Controller, useForm } from 'react-hook-form'
import { IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate } from 'api/typing/employeeTypes'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_DATE_END,
    TITLE_FIELD_DATE_START,
    TITLE_FIELD_DIVISION,
    TITLE_FIELD_GRADE,
    TITLE_FIELD_SPECIALIZATION,
} from 'helpers/enums/titles'
import { HINT_CHOOSE, HINT_ERROR_INCORRECT_DATE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { emptySelectItem } from 'helpers/enums/emptySelectItem'
import { useEffectOnce } from 'react-use'
import { addYears, format, isBefore, parse, subYears } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { DateRangeRounded } from '@mui/icons-material'
import { InputForm } from 'components/form/InputForm/InputForm'

type EditPreviousPositionItemFormProps = {
    itemData?: IEmployeeWorkInCompanyPreviousPosition;
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate) => void;
}

type FormValues = Omit<IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate, 'endDate' | 'startDate'> & {
    endDate: Date | null;
    startDate: Date | null;
}

const isEqualObjectsPreviousPosition = (
    position: IEmployeeWorkInCompanyPreviousPosition,
    formData: FormValues,
) => {
    const endDateFormatted = formData.endDate ? format(formData.endDate, 'dd.MM.yyyy') : ''
    const startDateFormatted = formData.startDate ? format(formData.startDate, 'dd.MM.yyyy') : ''

    const equalDivision = position.division.uuid === formData.divisionUuid
    const equalSpecialization = position.specialization.uuid === formData.specializationUuid
    const equalGrade = position.grade.uuid === formData.gradeUuid
    const equalStartDate = position.startDate === startDateFormatted
    const equalEndDate = position.endDate === endDateFormatted
    return equalDivision && equalSpecialization && equalGrade && equalStartDate && equalEndDate
}

export const EditPreviousPositionItemForm: FC<EditPreviousPositionItemFormProps> = ({
    itemData,
    onSubmitForm,
    onCancelForm,
}) => {
    const dispatch = useAppDispatch()
    const {
        data: {
            grades,
            specializations,
            divisions,
        },
        isLoading,
    } = useAppSelector(state => state.employeeEditWorkInCompanyPreviousPositionReducer)

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            divisionUuid: itemData?.division?.uuid ?? '',
            gradeUuid: itemData?.grade?.uuid ?? '',
            specializationUuid: itemData?.specialization?.uuid ?? '',
            startDate: itemData?.startDate ? parse(itemData?.startDate, 'dd.MM.yyyy', new Date()) : null,
            endDate: itemData?.endDate ? parse(itemData?.endDate, 'dd.MM.yyyy', new Date()) : null,
        },
    })

    const watchDateEnd = watch('endDate')
    const watchDateStart = watch('startDate')

    let isFetching = false

    useEffectOnce(() => {
        const fetchData = async () => {
            isFetching = true
            await dispatch(employeeActions.getEmployeeEditWorkInCompanyPreviousPositionDivisions())
            isFetching = false
        }
        if (!isFetching) {
            fetchData().catch(e => e)
        }
    })

    const specializationWatch = watch('specializationUuid')
    const divisionWatch = watch('divisionUuid')

    const hasSpecialization = useMemo(() =>
        !isEmptyUuid(getValues('specializationUuid')) && getValues('specializationUuid')?.length > 0, [specializationWatch])

    const specializationList = useMemo(() => {
        let list = [] as IEntity[]
        if (specializations) {
            list = [...specializations]
            list.push(emptySelectItem)
        }
        return list
    },
    [specializations, specializations?.length],
    )
    const gradeList = useMemo(() => {
        let list = [] as IEntity[]
        if (grades) {
            list = [...grades]
            list.push(emptySelectItem)
        }
        return list
    },
    [grades, grades?.length],
    )

    useEffect(() => {
        if (!divisionWatch) return
        dispatch(employeeActions.getEmployeeEditWorkInCompanyPreviousPositionSpecializations(divisionWatch))
    }, [divisionWatch])

    useEffect(() => {
        if (!specializationWatch) return
        dispatch(employeeActions.getEmployeeEditWorkInCompanyPreviousPositionGrades(specializationWatch))
    }, [specializationWatch])

    const onDivisionChange = (event: SelectChangeEvent<unknown>) => {
        const divisionUuid = event?.target?.value as string
        if (!divisionUuid) return

        setValue('specializationUuid', '')
        setValue('gradeUuid', '')
    }

    const onSpecializationChange = (event: SelectChangeEvent<unknown>) => {
        const specializationUuid = event?.target?.value as string
        if (!specializationUuid) return

        setValue('gradeUuid', '')
    }

    const onSubmit = (data: FormValues) => {
        if (!data.startDate
            || !data.endDate
            || itemData && isEqualObjectsPreviousPosition(itemData, data)
        ) return
        const payload: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
            startDate: format(data.startDate, 'dd.MM.yyyy'),
            endDate: format(data.endDate, 'dd.MM.yyyy'),
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="divisionUuid"
                    control={control}
                    rules={{ required: HINT_ERROR_REQUIRED }}
                    defaultValue={itemData?.division?.uuid ?? ''}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="divisionUuid"
                            name="divisionUuid"
                            values={divisions ?? []}
                            disabled={isLoading}
                            required
                            onChange={(e) => {
                                onDivisionChange(e)
                                field.onChange(e)
                            }}
                            title={TITLE_FIELD_DIVISION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.divisionUuid}
                            helperText={errors?.divisionUuid ? errors.divisionUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="specializationUuid"
                    control={control}
                    rules={{ required: HINT_ERROR_REQUIRED }}
                    defaultValue={itemData?.specialization?.uuid ?? ''}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="specializationUuid"
                            name="specializationUuid"
                            values={specializationList}
                            disabled={isLoading}
                            required
                            onChange={e => {
                                onSpecializationChange(e)
                                field.onChange(e)
                            }}
                            title={TITLE_FIELD_SPECIALIZATION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.specializationUuid}
                            helperText={errors?.specializationUuid ? errors.specializationUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="gradeUuid"
                    control={control}
                    rules={{ required: HINT_ERROR_REQUIRED }}
                    defaultValue={itemData?.grade?.uuid ?? ''}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="gradeUuid"
                            name="gradeUuid"
                            values={gradeList}
                            required
                            disabled={!hasSpecialization}
                            title={TITLE_FIELD_GRADE}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.gradeUuid}
                            helperText={errors?.gradeUuid ? errors.gradeUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Box>
                    <Controller
                        control={control}
                        name="startDate"
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            validate: {
                                min: (date: any) =>
                                    isBefore(subYears(new Date(), 200), date) || HINT_ERROR_INCORRECT_DATE,
                                max: (date: any) => isBefore(
                                    date,
                                    watchDateEnd ? watchDateEnd : addYears(new Date(), 10),
                                ) || HINT_ERROR_INCORRECT_DATE,
                            },
                        }}
                        render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                            <DatePicker
                                {...field}
                                inputRef={ref}
                                inputFormat="dd.MM.yyyy"
                                minDate={subYears(new Date(), 200)}
                                maxDate={watchDateEnd ? watchDateEnd : addYears(new Date(), 10)}
                                components={{ OpenPickerIcon: DateRangeRounded }}
                                toolbarPlaceholder={HINT_CHOOSE}
                                renderInput={(params) => (
                                    <InputForm
                                        {...params}
                                        id="startDate"
                                        title={TITLE_FIELD_DATE_START}
                                        onBlur={onBlur}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: HINT_CHOOSE,
                                        }}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <>
                    <Typography variant="body1" mt={4.5} mx={1}>
                        -
                    </Typography>
                    <Box>
                        <Controller
                            control={control}
                            name="endDate"
                            rules={{
                                required: HINT_ERROR_REQUIRED,
                                validate: {
                                    min: (date: any) =>
                                        isBefore(watchDateStart
                                            ? watchDateStart
                                            : subYears(new Date(), 200), date) || HINT_ERROR_INCORRECT_DATE,
                                    max: (date: any) =>
                                        isBefore(date, addYears(new Date(), 10)) || HINT_ERROR_INCORRECT_DATE,
                                },
                            }}
                            render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                <DatePicker
                                    {...field}
                                    inputRef={ref}
                                    inputFormat="dd.MM.yyyy"
                                    minDate={watchDateStart ? watchDateStart : subYears(new Date(), 200)}
                                    maxDate={addYears(new Date(), 10)}
                                    components={{ OpenPickerIcon: DateRangeRounded }}
                                    toolbarPlaceholder={HINT_CHOOSE}
                                    renderInput={(params) => (
                                        <InputForm
                                            {...params}
                                            id="endDate"
                                            title={TITLE_FIELD_DATE_END}
                                            onBlur={onBlur}
                                            required
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: HINT_CHOOSE,
                                            }}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                </>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={() => onCancelForm()}>
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button type="submit" variant="contained">
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
