import React, { memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Chip, Divider, Paper, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_QUESTIONS_FOR_LEVEL } from 'helpers/enums/titles'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    EmployeeAssessmentIndicator,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/EmployeeAssessmentIndicator'

type EmployeeAssessmentSkillLevelProps = {
    competenceUuid: string;
    skillLevelUuid: string;
}

export const EmployeeAssessmentSkillLevel = memo(
    function EmployeeAssessmentSkillLevel({
        competenceUuid,
        skillLevelUuid,
    }: EmployeeAssessmentSkillLevelProps) {
        const skillLevel = useAppSelector(state => state.MyAssessmentPassReducer.data.competenceSkillLevel[`${competenceUuid}-${skillLevelUuid}`])
        const competenceSkillLevelIndicatorUuids = useAppSelector(state => state
            .MyAssessmentPassReducer
            .data
            .competenceSkillLevelIndicatorUuids[`${competenceUuid}-${skillLevelUuid}`],
        )

        if (!skillLevel) return null

        return (
            <Stack spacing={3}>
                <Stack spacing={1} direction="row" alignItems="center">
                    <Typography variant="body2">
                        {TITLE_NAMESPACE_QUESTIONS_FOR_LEVEL}
                    </Typography>
                    <ChipSkillLevel skillLevelTitle={skillLevel.title}/>
                    <Chip color="warning" label={`${skillLevel.score}%`}/>
                </Stack>
                <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                    <Stack spacing={2} divider={<Divider/>}>
                        {competenceSkillLevelIndicatorUuids?.map((indicatorUuid, idx) =>
                            <EmployeeAssessmentIndicator
                                key={indicatorUuid}
                                indicatorUuid={indicatorUuid}
                                competenceUuid={competenceUuid}
                                idx={idx + 1}
                            />)}
                    </Stack>
                </Paper>
            </Stack>
        )
    },
)
