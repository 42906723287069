import { AppDispatch } from 'store/store'
import { OpenAccessService } from 'api/services/OpenAccessService'
import { openAccessDevelopmentPlanSlice } from 'store/reducers/openAccess/OpenAccessDevelopmentPlanSlice'


export class DevelopmentPlanAction {
    private static get developmentPlanApi() {
        return new OpenAccessService().developmentPlanService()
    }

    public getDevelopmentPlan(hash: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(openAccessDevelopmentPlanSlice.actions.fetching())
                const data = await DevelopmentPlanAction.developmentPlanApi.getDevelopmentPlan(hash)
                dispatch(openAccessDevelopmentPlanSlice.actions.getDevelopmentPlan(data))
            } catch (e: any) {
                dispatch(openAccessDevelopmentPlanSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getPdpCompetence(hash: string, competenceUuid: string, entityType: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(openAccessDevelopmentPlanSlice.actions.fetching())
                const data = await DevelopmentPlanAction.developmentPlanApi.getDevelopmentPlanCompetence(
                    hash,
                    competenceUuid,
                    entityType,
                )
                dispatch(openAccessDevelopmentPlanSlice.actions.getDevelopmentPlanCompetence(data))
            } catch (e: any) {
                dispatch(openAccessDevelopmentPlanSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
