import React, { memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Chip, Paper, Stack, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import {
    MyAssessmentIndicatorAnswers,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/components/MyAssessmentPassCompetence/components/MyAssessmentCompetenceIndicator/components/MyAssessmentIndicatorAnswers/MyAssessmentIndicatorAnswers'
import {
    MyAssessmentIndicatorComments,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/components/MyAssessmentPassCompetence/components/MyAssessmentCompetenceIndicator/components/MyAssessmentIndicatorComments/MyAssessmentIndicatorComments'

type MyAssessmentPassIndicatorMobileProps = {
    indicatorUuid: string;
    competenceUuid: string;
    idx: number;
}

export const MyAssessmentPassIndicatorMobile = memo(
    function MyAssessmentPassIndicatorMobile({
        indicatorUuid,
        competenceUuid,
        idx,
    }: MyAssessmentPassIndicatorMobileProps) {
        const indicator = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators[indicatorUuid])
        const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

        const showPassingError = indicator.error
            && (assessmentSetup?.estimationStatus || assessmentSetup?.confirmationStatus)

        if (!indicatorUuid) return null

        return (
            <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                <Stack
                    p={2}
                    spacing={2}
                    id={indicator.uuid}
                    sx={{ backgroundColor: showPassingError ? red[50] : undefined, borderRadius: 4 }}
                >
                    {showPassingError && <Chip
                        label="Выберите ответ"
                        color="error"
                        size="small"
                        sx={{alignSelf: 'flex-start'}}
                    />}
                    <Stack direction="row" spacing={2} alignItems="flex-start">
                        <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                            <Box>
                                <Typography variant="body2">{idx}.</Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">{indicator.title}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <MyAssessmentIndicatorAnswers
                        competenceUuid={competenceUuid}
                        skillLevelUuid={indicator.skillLevelUuid}
                        indicator={indicator}
                    />
                    <Box>
                        <MyAssessmentIndicatorComments
                            indicatorUuid={indicatorUuid}
                            competenceUuid={competenceUuid}
                        />
                    </Box>
                </Stack>
            </Paper>
        )
    },
)
