import React, { CSSProperties, FC, ReactNode, useMemo } from 'react'
import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { TITLE_NAMESPACE_GRADE, TITLE_NAMESPACE_SPECIALIZATION } from 'helpers/enums/titles'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { PATH_EMPLOYEES } from '../../helpers/enums/routePath'
import { NavLink as RouterLink } from 'react-router-dom'
import { viewEmployeeTabs } from 'helpers/enums/tabs/employee/viewEmployeeTabs'

type EmployeeMetaListItemProps = {
    fullName: string;
    positionTitle?: string;
    specialization?: IEntity | null;
    grade?: IEntity | null;
    isFullNameLink?: boolean;
    employeeUuid?: string;
    employeeChip?: ReactNode;
    customStyles?: CSSProperties & { [key: string]: CSSProperties};
}

export const EmployeeMetaListItem: FC<EmployeeMetaListItemProps> = ({
    grade,
    specialization,
    positionTitle,
    fullName,
    isFullNameLink = false,
    employeeUuid,
    employeeChip = null,
    customStyles,
}) => {
    const theme = useTheme()
    const hasNotSpecialization = useMemo(
        () => !specialization?.uuid || isEmptyUuid(specialization?.uuid),
        [specialization],
    )
    const hasNotGrade = useMemo(() => !grade?.uuid || isEmptyUuid(grade?.uuid), [grade])
    const specializationTitle = specialization?.title && !hasNotSpecialization
        ? specialization.title
        : TITLE_NAMESPACE_SPECIALIZATION
    const gradeTitle = grade?.title && !hasNotGrade
        ? grade.title
        : TITLE_NAMESPACE_GRADE

    return (
        // <Box sx={{ '& p': { color: 'gray '}}}>
        <Box sx={customStyles}>
            <Box mb={0.5} display='flex' gap={1}>
                {isFullNameLink && employeeUuid ?
                    <Link
                        component={RouterLink}
                        to={`${PATH_EMPLOYEES}/${employeeUuid}/${viewEmployeeTabs[0]}`}
                        variant="subtitle1"
                        underline="none"
                        target='_blank'
                    >
                        {fullName}
                    </Link>
                    :
                    <Typography variant="body2">
                        {fullName}
                    </Typography>
                }
                {employeeChip}
            </Box>
            <Box display="flex" alignItems="center">
                {positionTitle ?
                    <Typography variant="body1">
                        {positionTitle}
                    </Typography>
                    :
                    <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                        <Typography
                            variant="body1"
                            sx={{
                                color: hasNotSpecialization ? theme.palette.secondary.main : '',
                            }}
                        >
                            {specializationTitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: hasNotSpecialization || hasNotGrade
                                    ? theme.palette.secondary.main
                                    : '',
                            }}
                        >
                            -
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: hasNotGrade ? theme.palette.secondary.main : '',
                            }}
                        >
                            {gradeTitle}
                        </Typography>
                    </Stack>}
            </Box>
        </Box>
    )
}
