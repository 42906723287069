import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box, Stack, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { TITLE_BUTTON_OK, TITLE_DIALOG_ASSESSMENT_INSTRUCTION } from 'helpers/enums/titles'

type DialogOAAssessmentInstructionProps = {
    handleClose: () => void;
    open: boolean;
}

export const DialogOAAssessmentInstruction: FC<DialogOAAssessmentInstructionProps> = ({ open, handleClose }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_ASSESSMENT_INSTRUCTION}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={4}>
                    <Box>
                        <Typography variant="body2">
                            Для оценки своих компетенций пройдите опросник, где представлены утверждения, позволяющие
                            определить степень владения компетенциями.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            Внимательно прочитайте утверждение, вспомните, как часто указанное утверждение вы проявляли
                            при выполнении рабочих обязанностей, и выберите наиболее подходящий ответ:
                        </Typography>
                    </Box>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>Никогда</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>-</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>
                                    не характерно, никогда не проявлялось, хотя для этого была возможность.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>Редко</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>-</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>проявляется редко.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>Часто</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>-</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>проявляется в половине
                                    ситуаций.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>Всегда</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>-</TableCell>
                                <TableCell sx={{ verticalAlign: 'baseline', border: 0 }}>проявляется почти всегда,
                                    всегда.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box>
                        <Typography variant="body2">
                            После того как будете уверены в проставленных ответах, сохраните результаты.
                            После сохранения будет сформирован итоговый результат оценки с подробным отчетом.
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
                <Button onClick={handleClose}>{TITLE_BUTTON_OK}</Button>
            </DialogActions>
        </Dialog>
    )
}
