import React from 'react'
import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'
import { TITLE_NAMESPACE_STRONG_SIDES } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'

const getStrongSides = (tree: IAssessmentCompetenceGroup[] | undefined) => {
    if (!tree) return []
    return tree.reduce((acc, group) => {
        acc.push(
            ...getStrongSides(group.groupCompetence),
            ...group.competencies.reduce((a, el) => {
                if (el.score > 75) a.push(el.title.replace(/^(\d+\.\s)/gm, ''))
                return a
            }, [] as string[]),
        )
        return acc
    }, [] as string[])
}

export const OAAssessmentResultStrongSide = () => {
    const theme = useTheme()
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    const strongSides = getStrongSides(assessmentResult?.groupCompetence)

    if (strongSides.length <= 0) return null

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h2">
                    {TITLE_NAMESPACE_STRONG_SIDES}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {strongSides.map((side, idx) =>
                    <Grid key={`${side}-${idx}`} item mobile="auto" maxWidth="100%">
                        <Chip
                            label={side}
                            color="success"
                            sx={{
                                // @ts-ignore
                                backgroundColor: theme.palette.success[50],
                                color: theme.palette.success.dark,
                            }}
                            size="small"
                        />
                    </Grid>)}
            </Grid>
        </Box>
    )
}
