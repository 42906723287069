import { AppDispatch } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import { CPAItemSlice } from 'store/reducers/cpa/item/CPAItemSlice'
import { ICPAChangeStatusRequest, ICPACreateForUnCompletedRequest, ICPAUpdateRequest } from 'api/typing/cpaTypes'
import { CPAItemCriteriaSlice } from 'store/reducers/cpa/item/CPAItemCriteriaSlice'
import { CPAListSlice } from 'store/reducers/cpa/list/CPAListSlice'


export class CpaItemAction {
    private static get cpaItemApi() {
        return new CPAService().itemService()
    }

    public setItem(data: ICpaItemMeta) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemSlice.actions.setCpaMeta(data))
            dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(data?.criteria?.uuid || ''))
        }
    }

    public setStatusState(data: ICpaStatusState) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemSlice.actions.setStatusState(data))
        }
    }

    public deleteItem(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemSlice.actions.fetching())
                void await CpaItemAction.cpaItemApi.deleteItem(uuid)
                dispatch(CPAItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateItem(data: ICPAUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemSlice.actions.fetching())
                void await CpaItemAction.cpaItemApi.updateItem(data)
                dispatch(CPAItemSlice.actions.updateItem(data))
                dispatch(CPAItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public startCpa(data: ICPAChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemSlice.actions.fetching())
                void await CpaItemAction.cpaItemApi.changeStatusCpa(data)
                dispatch(CPAItemSlice.actions.changeStatusCpa(data))
                dispatch(CPAItemSlice.actions.startCpa())
                dispatch(CPAItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeCpaStatus(data: ICPAChangeStatusRequest & { author?: ICpaParticipant }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemSlice.actions.fetching())
                void await CpaItemAction.cpaItemApi.changeStatusCpa(data)
                dispatch(CPAItemSlice.actions.changeStatusCpa(data))
                dispatch(CPAItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createForUnCompleted(payload: ICPACreateForUnCompletedRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                const { uuid } = await CpaItemAction.cpaItemApi.createForUncompleted(payload)
                return uuid
            } catch (e: any) {
                dispatch(CPAListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateStatus(data: ICPAChangeStatusRequest) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemSlice.actions.changeStatusCpa(data))
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemSlice.actions.clearState())
        }
    }
}
