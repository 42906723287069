import React, { FC, memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Chip, Divider, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_REQUIRED_LEVEL } from 'helpers/enums/titles'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    EmployeeAssessmentCompetenceWarning,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentCompetenceWarning/EmployeeAssessmentCompetenceWarning'
import {
    MyAssessmentPassIndicatorMobile,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/components/MyAssessmentPassMobileCompetencies/components/MyAssessmentPassCompetenceMobile/components/MyAssessmentPassIndicatorMobile/MyAssessmentPassIndicatorMobile'
import {
    MyAssessmentPassSkillLevelMobile,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/components/MyAssessmentPassMobileCompetencies/components/MyAssessmentPassCompetenceMobile/components/MyAssessmentPassSkillLevelMobile/MyAssessmentPassSkillLevelMobile'

type MyAssessmentPassCompetenceMobileProps = {
    competenceUuid: string;
}

export const MyAssessmentPassCompetenceMobile: FC<MyAssessmentPassCompetenceMobileProps> = memo(
    function MyAssessmentPassCompetenceMobile({ competenceUuid }: MyAssessmentPassCompetenceMobileProps) {
        const competence = useAppSelector(state => state.MyAssessmentPassReducer.data.competencies[competenceUuid])
        const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
        const competenceIndicatorUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceIndicatorUuids[competenceUuid])

        const competenceSkillLevelUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceSkillLevelUuids[competenceUuid])

        if (!competence) return null

        return (
            <Stack spacing={3}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Stack>
                            <Typography variant="caption">
                                {competence.typeTitle}
                            </Typography>
                            <Typography variant="h3">
                                {competence.title}
                            </Typography>
                        </Stack>
                        <EmployeeAssessmentCompetenceWarning competenceUuid={competenceUuid}/>
                    </Stack>
                    {assessmentSetup?.completeStatus && <>
                        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                            <Stack spacing={1} alignItems="flex-start">
                                <Typography variant="body2">
                                    {TITLE_NAMESPACE_REQUIRED_LEVEL}
                                </Typography>
                                <ChipSkillLevel skillLevelTitle={competence.skillLevelTitle}/>
                            </Stack>
                            <Chip color="warning" label={`${competence.score}%`}/>
                        </Stack>
                    </>}
                </Stack>
                {assessmentSetup?.completeStatus && competenceSkillLevelUuids?.length > 0
                    ? <Stack spacing={4}>
                        <Divider/>
                        {competenceSkillLevelUuids?.map(el => <MyAssessmentPassSkillLevelMobile
                            key={el}
                            skillLevelUuid={el}
                            competenceUuid={competenceUuid}
                        />)}
                    </Stack>
                    : <Stack spacing={4}>
                        {competenceIndicatorUuids.map((indicatorUuid, idx) => <MyAssessmentPassIndicatorMobile
                            key={indicatorUuid}
                            competenceUuid={competenceUuid}
                            indicatorUuid={indicatorUuid}
                            idx={idx + 1}
                        />)}
                    </Stack>}
            </Stack>
        )
    },
)
