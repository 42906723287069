import { AppDispatch } from 'store/store'
import { FormSlice } from 'store/reducers/forms/FormSlice'
import { FormService } from 'api/services/FormService'


export class FormAction {
    private static get formApi() {
        return new FormService().sharedFormService()
    }

    public getSpecializations() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(FormSlice.actions.fetching())
                const data = await FormAction.formApi.getSpecializationList()
                dispatch(FormSlice.actions.setSpecializations(data))
                dispatch(FormSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(FormSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getGrades(specializationUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(FormSlice.actions.fetching())
                const data = await FormAction.formApi.getGradeList(specializationUuid)
                dispatch(FormSlice.actions.setGrades(data))
                dispatch(FormSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(FormSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getAssessmentAnswers() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(FormSlice.actions.fetching())
                const data = await FormAction.formApi.getAssessmentAnswers()
                dispatch(FormSlice.actions.setAnswers(data))
                dispatch(FormSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(FormSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getOAAssessmentAnswers() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(FormSlice.actions.fetching())
                const data = await FormAction.formApi.getOAAssessmentAnswers()
                dispatch(FormSlice.actions.setOAAnswers(data))
                dispatch(FormSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(FormSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setCpaRoles(data: IEntity[]) {
        return (dispatch: AppDispatch) => {
            dispatch(FormSlice.actions.setCpaRoles(data))
        }
    }
}
