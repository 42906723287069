import React, { FC } from 'react'
import { getCpaChipFromType } from 'helpers/cpa/cpaTypes'
import { Chip } from '@mui/material'

type ChipAssessmentProps = {
    cpaType?: string;
}

export const ChipAssessmentType: FC<ChipAssessmentProps> = ({ cpaType }) => {
    const assessmentChip = getCpaChipFromType(cpaType)
    return (
        <Chip
            label={assessmentChip.title}
            variant={assessmentChip.variant}
            color={assessmentChip.color}
            icon={assessmentChip.icon}
        />
    )
}
