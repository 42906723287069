import React, { FC, useMemo } from 'react'
import { IMenuListItem } from 'helpers/enums/menuItemsPosition'
import { Icon, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { NavLink, useLocation } from 'react-router-dom'
import { PATH_HOME } from 'helpers/enums/routePath'
import { AlertData } from '../../../../store/reducers/app/alerts/AlertsSlice'

type AppMenuListItemLinkProps = {
    item: IMenuListItem;
    handleCloseDrawer?: () => void;
    alert: AlertData | null;
}

const isActivePath = (itemPath: string, locationPath: string) => {
    const isHomePath = itemPath === PATH_HOME
    const currentParentPath = locationPath.split('/').filter(Boolean)[0]
    const itemParentPath = itemPath.split('/').filter(Boolean)[0]
    return isHomePath ? locationPath === PATH_HOME : currentParentPath === itemParentPath
}

export const AppMenuListItemLink: FC<AppMenuListItemLinkProps> = ({
    handleCloseDrawer,
    item,
    alert,
}) => {
    const location = useLocation()
    const isActive = isActivePath(item.path, location.pathname)

    const menuItemBackgroundColor = useMemo(
        () => {
            switch (true) {
                case !!alert?.backgroundColor:
                    return alert?.backgroundColor
                case isActive:
                    return 'white'
                default:
                    return 'initial'
            }
        },
        [alert, isActive],
    )

    return (
        <Authenticated service={item.service} accessLevel="r">
            <MenuItem
                component={NavLink}
                to={item.path}
                onClick={handleCloseDrawer}
                sx={{
                    px: 3,
                    py: 2,
                    background: menuItemBackgroundColor,
                    borderRight: alert ? `solid 5px ${alert?.badgeColor}` : 'none',
                }}
            >
                <ListItemIcon>
                    <Icon color={isActive ? 'primary' : 'inherit'}>
                        {React.createElement(item.icon)}
                    </Icon>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        whiteSpace: 'nowrap',
                        variant: isActive ? 'body2' : 'body1',
                    }}
                >
                    {item.text}
                </ListItemText>
                {!!alert &&
                    <Tooltip title={alert?.tooltipTitle}>
                        <div
                            style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: alert?.badgeColor,
                                borderRadius: '50%',
                            }}
                        />
                    </Tooltip>
                }
            </MenuItem>
        </Authenticated>
    )
}
