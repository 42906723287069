import { AppDispatch } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import { CPAEmployeeSlice } from 'store/reducers/cpa/item/CPAEmployeeSlice'
import { ICpaGetEmployeesForAddResponse, ICpaSetEmployeeParticipantsRequest } from 'api/typing/cpaTypes'
import { CPAItemEmployeesSlice } from 'store/reducers/cpa/item/CPAItemEmployeesSlice'


export class CpaEmployeeAction {
    private static get cpaItemApi() {
        return new CPAService().itemService()
    }

    public setEmployee(data: ICpaEmployee) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setEmployee(data))
            dispatch(CPAItemEmployeesSlice.actions.setParticipantCount({
                employeeUuid: data.uuid,
                participantCount: data.roles?.reduce((acc, role) => acc + role.participant.length, 0),
            }))
        }
    }

    public setEmployeeDivisionInfo(data: {
        divisionUuid: string;
        divisionManagerUuid: string;
    }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setEmployeeDivisionInfo(data))
        }
    }

    public setParticipantList(data: ICpaGetEmployeesForAddResponse[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setParticipantList(data))
        }
    }

    public setAllParticipantsState(state: boolean) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setAllParticipantsState(state))
        }
    }

    public setSelectStateDivision(payload: { divisionUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setSelectStateDivision(payload))
        }
    }

    public setSelectStateEmployee(payload: { employeeUuid: string; divisionUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setSelectStateEmployee(payload))
        }
    }

    public saveParticipantsToEmployee(payload: ICpaSetEmployeeParticipantsRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAEmployeeSlice.actions.fetching())
                void await CpaEmployeeAction.cpaItemApi.setCpaEmployeeParticipants(payload)
                dispatch(CPAEmployeeSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAEmployeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteParticipantsFromEmployee(payload: ICpaSetEmployeeParticipantsRequest & { deletedUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAEmployeeSlice.actions.fetching())
                void await CpaEmployeeAction.cpaItemApi.setCpaEmployeeParticipants(payload)
                dispatch(CPAEmployeeSlice.actions.deleteParticipantsFromEmployee(payload))
                dispatch(CPAEmployeeSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAEmployeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setDialogParticipantsState(roleUuid: IEntity | null) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.setDialogParticipantsState(roleUuid))
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAEmployeeSlice.actions.clearState())
        }
    }
}
