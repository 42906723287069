import { TransferForm } from './transferForm'
import {
    IMassExamAnswerImgDeleteReq,
    IMassExamAnswerImgUploadReq,
    IMassExamQuestionImgDeleteReq,
    IMassExamQuestionImgUploadReq,
} from '../../typing/massExam'
import { AxiosStatic } from 'axios'

export class MassExamUploadService extends TransferForm {
    private static instance: MassExamUploadService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!MassExamUploadService.instance)
            MassExamUploadService.instance = new MassExamUploadService(axiosInstance)
        return MassExamUploadService.instance
    }

    public uploadQuestionImage(data: IMassExamQuestionImgUploadReq) {
        const path = `mass-exam/${data.massExamUuid}/question/${data.questionUuid}`
        return this.request<IMassExamQuestionImgUploadReq, { link: string }>('POST', path, { data })
    }

    public deleteQuestionImage(data: IMassExamQuestionImgDeleteReq) {
        const path = `mass-exam/${data.massExamUuid}/question/${data.questionUuid}`
        return this.request<IMassExamQuestionImgDeleteReq, { link: string }>('DELETE', path, { data })
    }

    public uploadAnswerImage(data: IMassExamAnswerImgUploadReq) {
        const path = `mass-exam/${data.massExamUuid}/question/${data.questionUuid}/answer`
        return this.request<IMassExamAnswerImgUploadReq, { link: string, answerUuid: string }>('POST', path, { data })
    }

    public deleteAnswerImage(data: IMassExamAnswerImgDeleteReq) {
        const path = `mass-exam/${data.massExamUuid}/question/${data.questionUuid}/answer`
        return this.request<IMassExamAnswerImgDeleteReq, { link: string }>('DELETE', path, { data })
    }
}
