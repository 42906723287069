import { BaseApi } from 'api/index'

export class Analytic extends BaseApi {
    protected endpoint = 'analytics'

    public getAnalyticAssessment() {
        const path = 'assessment'
        return this.request<null, IAnalyticAssessment>('GET', path)
    }

    public getAnalyticPdp() {
        const path = 'pdp'
        return this.request<null, IAnalyticPdp>('GET', path)
    }

    public getCpaStatisticSummary(cpaUuid: string) {
        const path = `cpa/${cpaUuid}/statistic/summary`
        return this.request<null, TAnalyticCpaStatisticSummary>('GET', path)
    }

    public getCpaStatisticEmployee(cpaUuid: string) {
        const path = `cpa/${cpaUuid}/statistic/employee`
        return this.request<null, TAnalyticCpaStatisticEmployee>('GET', path)
    }

    // TODO: delete
    // public getAnalyticCpaCurrentPosition(cpaUuid: string) {
    //     const path = `cpa/${cpaUuid}/current-position/employees`
    //     return this.request<null, TAnalyticCpaCurrentPosition[]>('GET', path)
    // }

    public getAnalyticCpaPointCompetence(cpaUuid: string) {
        const path = `cpa/${cpaUuid}/competence/employees`
        return this.request<null, TAnalyticCpaCriteriaCompetence>('GET', path)
    }

    public getAnalyticCpaCompetenceTree(cpaUuid: string) {
        const path = `cpa/${cpaUuid}/competence-tree`
        return this.request<null, TAnalyticCpaCompetenceTreeGroup[]>('GET', path)
    }

    public getAnalyticCpaLevelCompliance(cpaUuid: string) {
        const path = `cpa/${cpaUuid}/level-compliance`
        return this.request<null, TAnalyticCpaLevelCompliance[]>('GET', path)
    }
}
