import { AppDispatch } from 'store/store'
import { DevelopmentService } from 'api/services/DevelopmentService'
import { developmentPdpSlice } from 'store/reducers/development/pdp/DevelopmentPdpSlice'
import { developmentPdpItemSlice } from 'store/reducers/development/pdp/DevelopmentPdpItemSlice'
import { IPdpCompetenceMaterialsSetupRequest } from 'api/typing/employeeTypes'
import {
    DevelopmentPdpUpdateUniqueRequest,
    IDevelopmentMaterialPassRequest,
    IDevelopmentPdpChangeOrderMaterialsRequest,
    IDevelopmentPdpDeleteItemRequest,
    IDevelopmentPdpItemCommentCreateRequest,
    IDevelopmentPdpItemCommentDeleteRequest,
    IDevelopmentPdpItemCommentUpdateRequest,
    IDevelopmentPdpItemMaterialCommentCreateRequest,
    IDevelopmentPdpItemMaterialCommentDeleteRequest,
    IDevelopmentPdpItemMaterialCommentUpdateRequest,
    IDevelopmentPdpItemPassRequest,
    IDevelopmentPdpMaterialCreateRequest,
    IDevelopmentPdpMaterialDeleteRequest,
    IDevelopmentPdpMaterialUpdateRequest,
} from 'api/typing/developmentPdpService'
import { FormService } from 'api/services/FormService'


export class DevelopmentPdpItemAction {

    private static get api() {
        return new DevelopmentService().developmentPdpService()
    }

    private static get formApi() {
        return new FormService().employeeFormService()
    }

    public getPdpItem(payload: { pdpUuid: string; itemUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.getPdpPlanItem(payload)
                dispatch(developmentPdpItemSlice.actions.getItem(data))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpItem(payload: DevelopmentPdpUpdateUniqueRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.updateUniquePdpItem(payload)
                dispatch(developmentPdpItemSlice.actions.updateItem({ ...payload, ...data }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpItem(payload: IDevelopmentPdpDeleteItemRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                await DevelopmentPdpItemAction.api.deletePdpItem(payload)
                dispatch(developmentPdpItemSlice.actions.clearState())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // public updateItemComments(payload: Omit<IPdpCommentItemUpdateRequest, 'employeeUuid'>) {
    //     return async (dispatch: AppDispatch) => {
    //         try {
    //             dispatch(developmentPdpItemSlice.actions.fetching())
    //             void await DevelopmentPdpItemAction.api.updateCommentItemPdp(payload)
    //             dispatch(developmentPdpItemSlice.actions.updateCommentItemPdp(payload))
    //         } catch (e: any) {
    //             dispatch(developmentPdpItemSlice.actions.fetchingError(e))
    //             throw e
    //         }
    //     }
    // }
    //
    // public updateItemMaterialComments(payload: Omit<IPdpCommentItemMaterialUpdateRequest, 'employeeUuid'>) {
    //     return async (dispatch: AppDispatch) => {
    //         try {
    //             dispatch(developmentPdpItemSlice.actions.fetching())
    //             void await DevelopmentPdpItemAction.api.updateItemMaterialComments(payload)
    //             dispatch(developmentPdpItemSlice.actions.updateItemMaterialComments(payload))
    //         } catch (e: any) {
    //             dispatch(developmentPdpItemSlice.actions.fetchingError(e))
    //             throw e
    //         }
    //     }
    // }

    // create comment for item
    public createPdpItemComment(payload: IDevelopmentPdpItemCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const { uuid } = await DevelopmentPdpItemAction.api.createPdpItemComment(payload)
                dispatch(developmentPdpItemSlice.actions.createPdpItemComment({ ...payload, uuid }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // update comment for item
    public updatePdpItemComment(payload: IDevelopmentPdpItemCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                void await DevelopmentPdpItemAction.api.updatePdpItemComment(payload)
                dispatch(developmentPdpItemSlice.actions.updatePdpItemComment(payload))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // delete comment for item
    public deletePdpItemComment(payload: IDevelopmentPdpItemCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                void await DevelopmentPdpItemAction.api.deletePdpItemComment(payload)
                dispatch(developmentPdpItemSlice.actions.deletePdpItemComment(payload))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // create comment for item Material
    public createPdpItemMaterialComment(payload: IDevelopmentPdpItemMaterialCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const { uuid } = await DevelopmentPdpItemAction.api.createPdpItemMaterialComment(payload)
                dispatch(developmentPdpItemSlice.actions.createPdpItemMaterialComment({ ...payload, uuid }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // update comment for item Material
    public updatePdpItemMaterialComment(payload: IDevelopmentPdpItemMaterialCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                void await DevelopmentPdpItemAction.api.updatePdpItemMaterialComment(payload)
                dispatch(developmentPdpItemSlice.actions.updatePdpItemMaterialComment(payload))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // delete comment for item Material
    public deletePdpItemMaterialComment(payload: IDevelopmentPdpItemMaterialCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                void await DevelopmentPdpItemAction.api.deletePdpItemMaterialComment(payload)
                dispatch(developmentPdpItemSlice.actions.deletePdpItemMaterialComment(payload))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public passPdpCompetenceMaterial(payload: IDevelopmentMaterialPassRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.passPdpCompetenceMaterial(payload)
                dispatch(developmentPdpItemSlice.actions.passPdpCompetenceMaterial({ ...data, ...payload }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public passItemUnique(payload: IDevelopmentPdpItemPassRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.passItemUnique(payload)
                dispatch(developmentPdpItemSlice.actions.passItemUnique({ ...payload, ...data }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createItemMaterial(payload: IDevelopmentPdpMaterialCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.createItemMaterial(payload)
                dispatch(developmentPdpItemSlice.actions.createItemMaterial({ ...payload, ...data }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateItemMaterial(payload: IDevelopmentPdpMaterialUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.updateItemMaterial(payload)
                dispatch(developmentPdpItemSlice.actions.updateItemMaterial({ ...payload, ...data }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteItemMaterial(payload: IDevelopmentPdpMaterialDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.deleteItemMaterial(payload)
                dispatch(developmentPdpItemSlice.actions.deleteItemMaterial({ ...payload, ...data }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeOrderPdpMaterials(
        payload: IDevelopmentPdpChangeOrderMaterialsRequest & { newMaterialsArray: IPdpCompetenceMaterial[] },
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                const { newMaterialsArray, ...apiPayload } = payload
                dispatch(developmentPdpItemSlice.actions.fetching())
                void await DevelopmentPdpItemAction.api.changeOrderPdpMaterials(apiPayload)
                dispatch(developmentPdpItemSlice.actions.changeOrderItems({
                    materials: newMaterialsArray,
                    skillLevelUuid: payload.skillLevelUuid,
                }))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getMaterialsForPdpCompetence(
        pdpUuid: string,
        specializationUuid: string,
        competenceUuid: string,
        entityType: EntityType,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.formApi.getMaterialsForPdpCompetence(
                    pdpUuid,
                    specializationUuid,
                    competenceUuid,
                    entityType,
                )
                dispatch(developmentPdpItemSlice.actions.getMaterialsForPdpCompetence(data))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeActivePdpCompetenceMaterialSetup(payload: { uuid: string; flag: boolean; }) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                dispatch(developmentPdpItemSlice.actions.changeActivePdpCompetenceMaterialSetup(payload))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setupPdpCompetenceMaterials(payload: IPdpCompetenceMaterialsSetupRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpItemSlice.actions.fetching())
                const data = await DevelopmentPdpItemAction.api.setupPdpCompetenceMaterials(payload)
                dispatch(developmentPdpItemSlice.actions.setupPdpCompetenceMaterials(data))
                dispatch(developmentPdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(developmentPdpSlice.actions.clearState())
        }
    }

}
