import React from 'react'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import { notifierActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { Box, IconButton, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { WarningAmberRounded } from '@mui/icons-material'

interface ReportCompleteProps {
    id: SnackbarKey;
    message: SnackbarMessage;
}

export const ReportWarning = React.forwardRef<HTMLDivElement, ReportCompleteProps>(function ReportWarning(
    props,
    ref,
) {
    const { id, message, ...other } = props
    const dispatch = useAppDispatch()
    const notifyClose = (key?: SnackbarKey) => dispatch(notifierActions.notifyClose(key))
    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    px: 2,
                    py: 0.5,
                    borderRadius: 4,
                    width: '100%',
                    maxWidth: 530,
                    backgroundColor: (theme) => theme.palette.warning.main,
                }}
            >
                <WarningAmberRounded
                    sx={{
                        color: 'inherit',
                        marginRight: 1,
                    }}
                />
                <Typography
                    sx={{
                        color: 'inherit',
                        marginRight: 1,
                        my: 1,
                        flex: 1,
                    }}
                >
                    {message}
                </Typography>
                <IconButton sx={{ color: 'inherit' }} onClick={() => notifyClose(id)}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
        </SnackbarContent>
    )
})
