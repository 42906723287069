import React, { FC, useMemo } from 'react'
import {
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_COMMENTS,
    TITLE_NAMESPACE_DONE,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_USER_ACTIVITY,
} from 'helpers/enums/titles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, CommentOutlined } from '@mui/icons-material'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'

type HistoryPdpItemViewUniqueProps = {
    item: IPdpItem & Pick<IPdp, 'employee' | 'reviewer'>;
}

export const HistoryPdpItemViewUnique: FC<HistoryPdpItemViewUniqueProps> = ({ item }) => {
    const { dialogCommentView, toggleDialog } = useDialogsState({ dialogCommentView: false })

    const pdpStatus = useMemo(() => getPdpStatus(item?.pdpStatus, false), [item?.pdpStatus?.uuid])

    return (
        <Box>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={true}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
            />
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <Stack spacing={1}>
                    <Typography variant="h2">
                        {item?.title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_USER_ACTIVITY}
                        </Typography>
                        {!!item?.changeLog &&
                            <BadgeChangeLog changeLog={item?.changeLog} evaluatedUuid={item?.employee?.uuid}/>}
                    </Stack>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_STUDY_TIME}: {formatHours(item?.studyTime)}
                    </Typography>
                </Stack>
            </Box>
            <Stack direction="row" spacing={2} mb={6} alignItems="center">
                <Button
                    startIcon={item?.isPassed ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                    variant={item?.isPassed ? 'contained' : 'outlined'}
                    disabled={pdpStatus.disableChangeItemStatus}
                    color="success"
                >
                    {TITLE_NAMESPACE_DONE}
                </Button>
                <Button
                    startIcon={<CommentOutlined />}
                    variant="outlined"
                    disabled={item.comments.length <= 0}
                    onClick={() => toggleDialog('dialogCommentView', true)}
                >
                    {item.comments.length > 0 ? TITLE_NAMESPACE_COMMENTS : TITLE_NAMESPACE_COMMENT}
                </Button>
            </Stack>
            <Typography variant="body2">
                {item.description}
            </Typography>
        </Box>
    )
}

