import React, { FC, ReactNode, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { mainTheme } from 'theme/mainTheme'
import axios from 'axios'

type ThemeLayerProps = {
    children: ReactNode;
}

export const ThemeLayer: FC<ThemeLayerProps> = ({children}) => {
    const companyState = useAppSelector(state => state.companyReducer.data)
    const [theme, setTheme] = useState(mainTheme)

    // Хук, который следит за изменением состояния компании для брендирования
    React.useEffect(() => {
        // Если нет уида компании, то нечего брендировать
        if (!companyState?.company?.uuid) return
        // Делаем запрос для получения темы из cdn
        const fetchTheme = async () => {
            let customThem = {}
            try {
                // Путь до файла json темы, обязательно должен быть такой и зависеть от uuid компании. Имя файла статичное
                // Структура файла также должна соответствовать примеру и быть корректрным json
                const { data } = await axios.get(`${process.env.REACT_APP_CDN_URL}branding/${companyState?.company?.uuid}/theme.json`)
                customThem = { ...data }
            } catch (e: any) {
                console.error(e)
            } finally {
                // Слияние дефолтной темы приложения с брендированной
                const currentTheme = createTheme(mainTheme, customThem)
                // Установка темы в стейт компонента (именно компонента,
                // для того,чтобы перерендерить приложение и отобразить новые стили)
                setTheme(currentTheme)
            }
        }

        fetchTheme().catch()

        // В случае выхода из жц прилодения ставим дефолт тему
        return () => setTheme(mainTheme)
    }, [companyState])

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}
