import React, { FC } from 'react'
import { Box, Divider, Drawer, Typography, Dialog } from '@mui/material'
import {
    TITLE_BUTTON_ADD_FROM_DATABASE,
    TITLE_BUTTON_CREATE,
    TITLE_NAMESPACE_CONTENT_OF_PLAN,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import {
    DialogCreatePdpItem,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/dialogs/DialogCreatePdpItem/DialogCreatePdpItem'
import {
    DrawerCreatePdpItemFromBase,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/dialogs/DrawerCreatePdpItemFromBase/DrawerCreatePdpItemFromBase'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { AddButton } from 'components/common/AddButton/AddButton'
import { useAppSelector } from 'store/hooks/redux'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    PdpPlanListItems,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/PdpPlanListItems/PdpPlanListItems'
import {
    PdpPlanListItemsHeader,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/PdpPlanListItemsHeader/PdpPlanListItemsHeader'
import { useDesktop } from 'helpers/hooks/useDesktop'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import { DevelopmentPdpItemsHeader } from './components/DevelopmentPdpItemsHeader/DevelopmentPdpItemsHeader'
import { DevelopmentPdpItems } from './components/DevelopmentPdpItems/DevelopmentPdpItems'

type DevelopmentPdpContentProps = {
    studyTime: number;
}

export const DevelopmentPdpContent: FC<DevelopmentPdpContentProps> = ({ studyTime }) => {
    const desktop = useDesktop()

    const { pdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const pdpStatus = getPdpStatus(pdp?.status)
    const canManagePdp = pdpStatus.status === 'default' // && pdp.author.uuid === pdp.employee.uuid

    const { dialogCreate, dialogCreateFromBase, toggleDialog } = useDialogsState({
        dialogCreate: false,
        dialogCreateFromBase: false,
    })

    return (
        <Box>
            {dialogCreate &&
                <Authenticated service="my_development" accessLevel="w">
                    <DialogCreatePdpItem
                        open={dialogCreate}
                        developmentPage
                        handleClose={() => toggleDialog('dialogCreate', false)}
                    />
                </Authenticated>
            }
            <Authenticated service="my_development" accessLevel="w">
                {!desktop ?
                    <Dialog
                        fullScreen
                        open={dialogCreateFromBase}
                        onClose={() => toggleDialog('dialogCreateFromBase', false)}
                    >
                        <DrawerCreatePdpItemFromBase
                            developmentPage
                            handleClose={() => toggleDialog('dialogCreateFromBase', false)}
                        />
                    </Dialog>
                    :
                    <Drawer
                        anchor="right"
                        open={dialogCreateFromBase}
                        onClose={() => toggleDialog('dialogCreateFromBase', false)}
                    >
                        <DrawerCreatePdpItemFromBase
                            developmentPage
                            handleClose={() => toggleDialog('dialogCreateFromBase', false)}
                        />
                    </Drawer>
                }
            </Authenticated>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems={!desktop ? 'flex-start' : 'center'}
                flexDirection={!desktop ? 'column' : 'row'}
                mb={!desktop ? 3 : 2}
            >
                <Box mb={!desktop ? 2 : 0}>
                    <Typography variant="h3" mb={0.5}>
                        {TITLE_NAMESPACE_CONTENT_OF_PLAN}
                    </Typography>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_STUDY_TIME} {formatHours(studyTime)}
                    </Typography>
                </Box>
                {canManagePdp &&
                    <Authenticated service="my_development" accessLevel="w">
                        <Box sx={{ width: !desktop ? '100%' : 'auto' }}>
                            <AddButton
                                sx={{ mr: 1, p: !desktop ? '6px 8px' : '8px 20px' }}
                                onClick={() => toggleDialog('dialogCreate', true)}
                                icon={!desktop && <AddToPhotosIcon />}
                            >
                                {TITLE_BUTTON_CREATE}
                            </AddButton>
                            <AddButton
                                variant="outlined"
                                onClick={() => toggleDialog('dialogCreateFromBase', true)}
                            >
                                {TITLE_BUTTON_ADD_FROM_DATABASE}
                            </AddButton>
                        </Box>
                    </Authenticated>
                }
            </Box>
            {pdpStatus?.status === 'default' ?
                <>
                    {desktop && <PdpPlanListItemsHeader isDevelopment pdpStatus={pdpStatus}/>}
                    <PdpPlanListItems isDevelopment/>
                </>
                :
                <>
                    {desktop && <DevelopmentPdpItemsHeader/>}
                    <DevelopmentPdpItems/>
                    {desktop && <Divider sx={{ marginTop: 2 }}/>}
                </>
            }
        </Box>
    )
}

