import React, { FC } from 'react'
import { Box, Divider, IconButton, Typography, Button } from '@mui/material'
import { CloseRounded, FilterListRounded } from '@mui/icons-material'
import {
    TITLE_BUTTON_FILTER,
    TITLE_DRAWER_ADD_COMPETENCE_IN_PDP,
    TITLE_DRAWER_ADD_COMPETENCE_IN_PDP_SHORT,
} from 'helpers/enums/titles'
import { CreatePdpCompetenceFilters } from '../CreatePdpCompetenceFilters/CreatePdpCompetenceFilters'
import { CreatePdpCompetenceActions } from '../CreatePdpCompetenceActions/CreatePdpCompetenceActions'
import { useMobile } from 'helpers/hooks/useMobile'

type CreatePdpCompetenceHeaderProps = {
    search: string;
    setSearch: (value: string) => void;
    closeCreateCompetenceDialog: () => void;
    addCompetencies: () => void;
    competenceCountTitle: string;
    setOpenFilters: (value: boolean) => void;
}

export const CreatePdpCompetenceHeader: FC<CreatePdpCompetenceHeaderProps> = ({
    search,
    setSearch,
    closeCreateCompetenceDialog,
    addCompetencies,
    competenceCountTitle,
    setOpenFilters,
}) => {
    const mobile =  useMobile()

    return (
        <Box
            sx={{
                pt: mobile ? 3 : 5,
                pr:  mobile ? 0 : 5,
                pl: mobile ? 0 : 2.5,
                zIndex: 1,
                position: 'sticky',
                top: 0,
                background: 'white',
            }}
        >
            <Box
                display="flex"
                flexDirection={mobile ? 'row-reverse' : 'initial'}
                sx={{
                    pl:  mobile ? 3 : 0,
                    pr:  mobile ? 3 : 0,
                    pb:  mobile ? 2 : 0,
                }}
            >
                <IconButton
                    color="primary"
                    sx={{ mr: !mobile ? 2.5 : 0, alignSelf: 'flex-start' }}
                    onClick={closeCreateCompetenceDialog}
                >
                    <CloseRounded />
                </IconButton>
                <Box flex={1}>
                    <Typography variant="h1">
                        {mobile ? TITLE_DRAWER_ADD_COMPETENCE_IN_PDP_SHORT : TITLE_DRAWER_ADD_COMPETENCE_IN_PDP}
                    </Typography>
                    {!mobile &&
                        <Box mb={5} display="flex" justifyContent="space-between" alignItems="flex-end">
                            <CreatePdpCompetenceFilters
                                search={search}
                                setSearch={setSearch}
                            />
                            <CreatePdpCompetenceActions
                                handleClose={closeCreateCompetenceDialog}
                                addCompetencies={addCompetencies}
                                competenceCountTitle={competenceCountTitle}
                            />
                        </Box>
                    }
                    {mobile &&
                        <Button
                            variant="text"
                            sx={{ alignSelf: 'flex-start' }}
                            startIcon={<FilterListRounded/>}
                            onClick={() => setOpenFilters(true)}
                        >
                            {TITLE_BUTTON_FILTER}
                        </Button>
                    }
                </Box>
            </Box>
            <Divider/>
        </Box>
    )
}
