import React, { FC, useState } from 'react'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { HINT_NO_DATA } from 'helpers/enums/hints'
import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import {
    CPACriteriaDrawerItem,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/CPACriteriaDrawerItem'
import { useAppSelector } from 'store/hooks/redux'

type CPACriteriaDrawerContentProps = {
    items?: IEntity[];
    handleClose: () => void;
}

export const CPACriteriaDrawerContent: FC<CPACriteriaDrawerContentProps> = ({
    handleClose,
    items,
}) => {
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const [criteriaUuid, setCriteriaUuid] = useState(currentCriteriaUuid || '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCriteriaUuid(e.target.value as string)
    }


    if (!items) return <EmptyPage contentText={HINT_NO_DATA}/>

    return (
        <Stack spacing={3} divider={<Divider/>} flex={1}>
            <FormControl>
                <RadioGroup onChange={handleChange} value={criteriaUuid}>
                    <Stack spacing={2}>
                        {items?.map(el => <Box
                            key={el.uuid}
                        >
                            <FormControlLabel
                                key={el.uuid}
                                value={el.uuid}
                                control={<Radio/>}
                                label={el.title}
                                sx={{ m: 0 }}
                            />
                        </Box>)}
                    </Stack>
                </RadioGroup>
            </FormControl>
            <CPACriteriaDrawerItem handleClose={handleClose} currentCriteriaUuid={criteriaUuid}/>
        </Stack>
    )
}
