import React, { FC } from 'react'
import { Box, Chip, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import {
    CPAEmployeesStatisticEmployeeIcon,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeIcon/CPAEmployeesStatisticEmployeeIcon'
import {
    CPAEmployeesStatisticEmployeeDrawerDivision,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesStatisticEmployee/components/CPAEmployeesStatisticEmployeeCard/components/CPAEmployeesStatisticEmployeeDrawer/components/CPAEmployeesStatisticEmployeeDrawerDivision/CPAEmployeesStatisticEmployeeDrawerDivision'

type CPAEmployeesStatisticEmployeeDrawerProps = {
    open: boolean;
    handleClose: () => void;

    title: string;
    employeeList: string[];
    iconType: 'progress' | 'expired' | 'notStarted' | 'withoutParticipants';
}


export const CPAEmployeesStatisticEmployeeDrawer: FC<CPAEmployeesStatisticEmployeeDrawerProps> = ({
    handleClose,
    open,
    employeeList,
    title,
    iconType,
}) => {
    const itemCpa = useAppSelector(state => state.CPAItemReducer.meta)
    const divisions = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 520 },
                },
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" px={5} pt={5} pb={7}>
                    <Stack spacing={2} flex={1} direction="row" alignItems="center">
                        <CPAEmployeesStatisticEmployeeIcon iconType={iconType}/>
                        <Typography variant="h1">
                            {title}
                        </Typography>
                        <Chip
                            label={`${employeeList.length}/${itemCpa.employeesCount}`}
                            variant="outlined"
                            color="warning"
                        />
                    </Stack>
                    <IconButton color="primary" onClick={handleClose}>
                        <CloseRounded/>
                    </IconButton>
                </Box>
                <Stack spacing={5} flex={1} overflow="auto" px={5} pb={5}>
                    {divisions.map(division => <CPAEmployeesStatisticEmployeeDrawerDivision
                        key={division.uuid}
                        division={division}
                        employeeList={employeeList}
                        isWithoutParticipants={iconType === 'withoutParticipants'}
                    />)}
                </Stack>
            </Box>
        </Drawer>
    )
}
