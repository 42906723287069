import React, { FC } from 'react'
import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'
import { Box, Button, Checkbox, IconButton, TableCell, TableRow } from '@mui/material'
import { TITLE_BUTTON_OPEN } from 'helpers/enums/titles'
import { DrawerMaterialCommentView } from 'components/drawers/DrawerMaterialCommentView/DrawerMaterialCommentView'
import { CommentOutlined } from '@mui/icons-material'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useAppDispatch } from 'store/hooks/redux'
import { developmentPdpItemActions, pdpItemActions } from 'store/actions'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { MaterialTypeIcon } from 'components/common/MaterialTypeIcon/MaterialTypeIcon'

type PdpSetupMaterialProps = {
    material: IPdpMaterialFromBase;
    isDevelopment?: boolean;
}

export const PdpSetupMaterial: FC<PdpSetupMaterialProps> = ({ material, isDevelopment }) => {
    const dispatch = useAppDispatch()
    const { commentView, toggleDialog } = useDialogsState({ commentView: false })

    const handleChange = () => {
        if (isDevelopment)
            dispatch(developmentPdpItemActions.changeActivePdpCompetenceMaterialSetup({
                uuid: material.uuid,
                flag: !material.selected,
            }))
        else
            dispatch(pdpItemActions.changeActivePdpCompetenceMaterialSetup({
                uuid: material.uuid,
                flag: !material.selected,
            }))
    }

    return (
        <TableRow style={{ height: 52 }}>
            <DrawerMaterialCommentView
                open={commentView}
                comment={material.comment}
                materialTitle={material.title}
                materialFormat={material.format}
                handleClose={() => toggleDialog('commentView', false)}
            />
            <TableCell>
                <Box display="flex" alignItems="center" onClick={e => e.stopPropagation()}>
                    <Checkbox
                        checked={material.selected}
                        disableRipple
                        color={material.isChanged ? 'warning' : 'primary'}
                        onChange={handleChange}
                        sx={{
                            p: 0,
                            color: (theme) => material.isChanged
                                ? theme.palette.warning.main
                                : theme.palette.primary.main,
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell>
                <MaterialTypeIcon materialTypeUuid={material.materialTypeUuid} />
            </TableCell>
            <TableCell>
                {material.title}
            </TableCell>
            <TableCell>
                {material.skillLevel.title}
            </TableCell>
            <TableCell align="center">
                {material?.comment && <IconButton
                    color="primary"
                    onClick={() => toggleDialog('commentView', true)}
                >
                    <CommentOutlined />
                </IconButton>}
            </TableCell>
            <TableCell align="left">
                {material.format}
            </TableCell>
            <TableCell align="left">
                {formatHours(material.studyTime)}
            </TableCell>
            <TableCell sx={{ paddingRight: 0 }} align="left">
                {material.link && <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    endIcon={<ArrowForwardRoundedIcon />}
                    target="_blank"
                    href={material.link}
                >
                    {TITLE_BUTTON_OPEN}
                </Button>}
            </TableCell>
        </TableRow>
    )
}

