import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useAppSelector } from '../../../store/hooks/redux'
import { ExamQuestionCard } from '../../ExamQuestionCard/ExamQuestionCard'
import { ExamResultProgress } from '../ExamResultProgress/ExamResultProgress'

export const ExamResultPageContent = () => {
    const examResultQuestions = useAppSelector(state => state.examItemReducer.result?.questions)

    return (
        <Stack spacing={6} width='60%'>
            <ExamResultProgress/>

            <Stack spacing={2}>
                <Typography variant='subtitle1'>Ответы</Typography>
                <Stack spacing={2}>
                    {examResultQuestions?.map(question => (
                        <ExamQuestionCard
                            key={question.uuid}
                            question={question}
                        />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    )
}
