import React, { FC } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_CONTENT_OF_PLAN,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import {
    HistoryPdpItems,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistoryPdp/components/HistoryPdp/components/HistoryPdpContent/components/HistoryPdpItems/HistoryPdpItems'
import {
    DevelopmentPdpItemsHeader,
} from 'pages/development/DevelopmentPage/DevelopmentPdp/components/DevelopmentPdpContent/components/DevelopmentPdpItemsHeader/DevelopmentPdpItemsHeader'

type HistoryPdpContentProps = {
    pdp: IPdp;
    parentPath: string;
}

export const HistoryPdpContent: FC<HistoryPdpContentProps> = ({ pdp, parentPath }) => {
    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3" mb={0.5}>
                    {TITLE_NAMESPACE_CONTENT_OF_PLAN}
                </Typography>
                <Typography variant="body1">
                    {TITLE_NAMESPACE_STUDY_TIME} {formatHours(pdp.studyTime)}
                </Typography>
            </Box>
            <DevelopmentPdpItemsHeader />
            <HistoryPdpItems items={pdp.planItems} parentPath={parentPath} />
            <Divider sx={{ marginTop: 2 }} />
        </Box>
    )
}

