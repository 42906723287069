import { useEffect } from 'react'
import { appAction } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'

export const useSaveBackButtonPath = (path: string) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(appAction.setBackButtonPath(path))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [])
}
