import React, { FC, useMemo } from 'react'
import { Box } from '@mui/material'
import { sortArrayFromSortIndex } from 'helpers/sortArray'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    DevelopmentPdpCompetenceMaterialsTable,
} from '../DevelopmentPdpCompetenceMaterialsTable/DevelopmentPdpCompetenceMaterialsTable'
import { useDesktop } from 'helpers/hooks/useDesktop'
import {
    DevelopmentPdpCompetenceMaterialsMobile,
} from '../DevelopmentPdpCompetenceMaterialsMobile/DevelopmentPdpCompetenceMaterialsMobile'

type DevelopmentPdpCompetenceMaterialsProps = {
    materials: IPdpCompetenceMaterial[];
    skillLevelUuid: string;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpCompetenceMaterials: FC<DevelopmentPdpCompetenceMaterialsProps> = ({
    materials,
    skillLevelUuid,
    pdpStatus,
}) => {
    const sortedMaterials = useMemo(() => sortArrayFromSortIndex(materials), [materials])
    const desktop = useDesktop()

    return (
        <Box>
            {desktop ?
                <DevelopmentPdpCompetenceMaterialsTable
                    sortedMaterials={sortedMaterials}
                    skillLevelUuid={skillLevelUuid}
                    pdpStatus={pdpStatus}
                />
                :
                <DevelopmentPdpCompetenceMaterialsMobile
                    sortedMaterials={sortedMaterials}
                    skillLevelUuid={skillLevelUuid}
                    pdpStatus={pdpStatus}
                />
            }
        </Box>
    )
}
