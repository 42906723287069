import React, { FC } from 'react'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_PDP_ITEM_EDIT,
    TITLE_FIELD_DESCRIPTION,
    TITLE_FIELD_PLACEHOLDER_PDP_ITEM_DESCRIPTION,
    TITLE_FIELD_STUDY_TIME,
    TITLE_FIELD_TITLE_PDP_ITEM,
    TITLE_NAMESPACE_EMPLOYEE,
} from 'helpers/enums/titles'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { HINT_SUCCESS_PDP_ITEM_EDIT } from 'helpers/enums/hints'
import { InputForm } from 'components/form/InputForm/InputForm'
import { Controller, useForm } from 'react-hook-form'
import { IPdpItemUpdateRequest } from 'api/typing/employeeTypes'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { useAppDispatch } from 'store/hooks/redux'
import { developmentPdpActions, developmentPdpItemActions, pdpActions, pdpItemActions } from 'store/actions'
import { useNotify } from 'store/hooks/useNotify'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { InputMask } from 'components/form/InputMask/InputMask'
import { biggerBtnForMobile } from 'styles/styles'

interface IFormValues extends Omit<IPdpItemUpdateRequest, 'studyTime'> {
    studyTime: string
}

type DialogEditPdpItemUniqueProps = {
    handleClose: () => void;
    open: boolean;
    item: IPdpPlanItem | IPdpItem;
    itemPage?: boolean;
    isDevelopment?: boolean;
    pdpUuid: string;
    employee: IParticipantEmployee;
}

export const DialogEditPdpItemUnique: FC<DialogEditPdpItemUniqueProps> = ({
    handleClose,
    open,
    item,
    itemPage,
    employee,
    pdpUuid,
    isDevelopment,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { handleSubmit, control, reset, formState: { errors } } = useForm<IFormValues>({
        shouldFocusError: false,
        defaultValues: {
            ...item,
            pdpUuid,
            employeeUuid: employee.uuid,
            studyTime: String(item.studyTime),
        },
    })

    const onSubmit = async (data: IFormValues) => {
        const payload: IPdpItemUpdateRequest = {
            ...removeWhiteSpaceFromObjectElements(data),
            studyTime: Number(data.studyTime),
        }
        try {
            if (itemPage) {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.updatePdpItem(payload))
                else
                    await dispatch(pdpItemActions.updatePdpPlanItem(payload))
            } else {
                if (isDevelopment)
                    await dispatch(developmentPdpActions.updateUniquePdpItem(payload))
                else
                    await dispatch(pdpActions.updateUniqueItemPdp(payload))
            }
            notify(HINT_SUCCESS_PDP_ITEM_EDIT, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const onCancel = () => {
        handleClose()
        reset({
            ...item,
            pdpUuid,
            employeeUuid: employee.uuid,
            studyTime: String(item.studyTime),
        })
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={onCancel}>
                {TITLE_DIALOG_PDP_ITEM_EDIT}
            </DialogTitle>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogContent>
                    <Box mb={2.5}>
                        <Typography variant="body1" mb={1}>
                            {TITLE_NAMESPACE_EMPLOYEE}
                        </Typography>
                        <Typography variant="body2">
                            {employee.lastName} {employee.firstName} {employee.secondName}
                        </Typography>
                    </Box>
                    <Box>
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.title,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="title"
                                    title={TITLE_FIELD_TITLE_PDP_ITEM}
                                    required
                                    inputProps={{
                                        maxLength: fieldsRestriction
                                            .employees
                                            .employee
                                            .pdp
                                            .item
                                            .title
                                            .maxLength
                                            .value,
                                    }}
                                    autoFocus
                                    error={!!errors.title}
                                    helperText={errors?.title ? errors.title.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.description,
                            }}
                            render={({ field }) => (
                                <InputForm
                                    {...field}
                                    id="description"
                                    title={TITLE_FIELD_DESCRIPTION}
                                    multiline
                                    required
                                    inputProps={{
                                        maxLength: fieldsRestriction
                                            .employees
                                            .employee
                                            .pdp
                                            .item
                                            .description
                                            .maxLength
                                            .value,
                                    }}
                                    placeholder={TITLE_FIELD_PLACEHOLDER_PDP_ITEM_DESCRIPTION}
                                    autoFocus
                                    error={!!errors.description}
                                    helperText={errors?.description ? errors.description.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                        <Controller
                            name="studyTime"
                            control={control}
                            rules={{
                                ...fieldsRestriction.employees.employee.pdp.item.studyTime,
                            }}
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    id="studyTime"
                                    mask={Number}
                                    scale={0}
                                    min={0}
                                    max={999}
                                    variant="outlined"
                                    required
                                    title={TITLE_FIELD_STUDY_TIME}
                                    error={!!errors.studyTime}
                                    helperText={errors?.studyTime ? errors.studyTime.message : null}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={onCancel}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
