import React, { FC, useMemo, useState } from 'react'
import { Box, Collapse, Stack, Tooltip, Typography } from '@mui/material'
import { ChevronRightRounded, ExpandLess, ExpandMore, MoreHorizRounded } from '@mui/icons-material'
import {
    CPAEmployeesTable,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/CPAEmployeesTable'
import { TITLE_NAMESPACE_COMPLETED, TITLE_NAMESPACE_MANAGER } from 'helpers/enums/titles'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { useAppSelector } from 'store/hooks/redux'

type CPAEmployeesDivisionProps = {
    division: ICpaItemDivision;
}

export const CPAEmployeesDivision: FC<CPAEmployeesDivisionProps> = ({ division }) => {
    const [open, setOpen] = useState(true)
    const cpaStatus = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const cpaStatusCreated = useMemo(() => cpaStatus === 'created' || cpaStatus === 'ready', [cpaStatus])
    const handleClick = () => setOpen(prev => !prev)
    const currentProgress = useMemo(
        () => {
            const completedCount = division?.progress?.completedCount
            const assessmentsCount = division?.progress?.assessmentsCount
            if (completedCount === undefined || assessmentsCount === undefined || completedCount === 0) return 0
            return Math.floor(completedCount / assessmentsCount * 100)
        },
        [division],
    )
    const managerTitle = useMemo(
        () => `${TITLE_NAMESPACE_MANAGER}: ${division.manager.lastName} ${division.manager.firstName?.charAt(0)
            .toUpperCase()}.`,
        [division])


    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Box display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick}>
                    <Tooltip placement="bottom-start" title={division.fullPath} enterDelay={300} enterNextDelay={300}>
                        <Stack direction="row" spacing={0.5}>
                            <MoreHorizRounded color="disabled"/>
                            <ChevronRightRounded color="disabled"/>
                            <Typography variant="subtitle1">
                                {division.title}
                            </Typography>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </Stack>
                    </Tooltip>
                </Box>
                <Typography variant="body1">
                    {managerTitle}
                </Typography>
            </Stack>
            {division.progress && !cpaStatusCreated && <Stack direction="row" alignItems="center" spacing={2}>
                <GFProgressBar value={currentProgress} sx={{ maxWidth: 300 }}/>
                <Typography variant="body1">
                    {TITLE_NAMESPACE_COMPLETED}: {division.progress.completedCount}/{division.progress.assessmentsCount}
                </Typography>
            </Stack>}
            <Collapse in={open} timeout="auto">
                <CPAEmployeesTable employees={division.employees} divisionUuid={division.uuid}/>
            </Collapse>
        </Stack>
    )
}
