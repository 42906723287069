import React, { FC, useMemo } from 'react'
import {
    TITLE_BUTTON_APPROVE,
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SEND_RESULTS,
    TITLE_DIALOG_APPROVE_ASSESSMENT,
} from 'helpers/enums/titles'
import { Box, Button } from '@mui/material'
import { CheckRounded } from '@mui/icons-material'
import { oaAssessmentActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { QueueQueueRequestsController } from 'utils/QueueRequests'
import { useParams } from 'react-router-dom'

interface OAAssessmentActionsProps {
    setOpenViewPassedModal: (val: boolean) => void;
}

export const OAAssessmentActions: FC<OAAssessmentActionsProps> = ({ setOpenViewPassedModal }) => {
    const { hash } = useParams()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const {
        dialogConfirmAssessment,
        toggleDialog,
    } = useDialogsState({ dialogConfirmSave: false, dialogConfirmAssessment: false })
    const { assessment } = useAppSelector(state => state.oaAssessmentReducer)
    const { data: assessmentAnswers } = useAppSelector(state => state.oaAssessmentAnswersReducer)
    const answers = useMemo(() => assessmentAnswers
        ? new Set<string>(assessmentAnswers)
        : new Set(), [assessmentAnswers])
    const hasUncompletedAnswers = assessmentAnswers.length !== assessment.indicatorsCount
    const assessmentStatusComplete = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'
    const assessmentQueue = QueueQueueRequestsController.getInstance().addNewQueue(assessment?.uuid)

    const saveResults = async () => {
        if (assessmentQueue && assessmentQueue.getLength() > 0 || !hash) return
        try {
            if (hasUncompletedAnswers) {
                await dispatch(oaAssessmentActions.checkAssessmentAnswers(assessmentAnswers))
                assessment.competencies.some(el => {
                    return el.indicators.some(indicator => {
                        if (answers.has(indicator.uuid)) return false

                        const docEl = document.getElementById(indicator.uuid)
                        docEl?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        })
                        return true
                    })
                })
            } else {
                await dispatch(oaAssessmentActions.assessmentSave({
                    uuid: assessment.uuid,
                    hash,
                }))
                await dispatch(oaAssessmentActions.getAssessment(assessment.uuid, hash))
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
                setOpenViewPassedModal(true)
            }
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (assessmentStatusComplete) return null

    return (
        <Box>
            <DialogConfirm
                open={dialogConfirmAssessment}
                dialogTitle={TITLE_DIALOG_APPROVE_ASSESSMENT}
                dialogText="Вы уверены, что хотите утвердить оценку?"
                dialogHelperText="После утверждения будет сформирован итоговый результат оценки без возможности изменения."
                titleButtonConfirm={TITLE_BUTTON_APPROVE}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={saveResults}
                handleClose={() => toggleDialog('dialogConfirmAssessment', false)}
            />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckRounded/>}
                    onClick={saveResults}
                >
                    {TITLE_BUTTON_SEND_RESULTS}
                </Button>
            </Box>
        </Box>
    )
}
