import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ISpecializationCompetenceChangeOrderMaterialRequest,
    SpecializationCompetenceIncludedUuids,
} from 'api/typing/specializationTypes'

const initialState: IState<ICompetence> = {
    data: {} as ICompetence,
    isLoading: false,
    error: null,
}

export const competenceSlice = createSlice({
    name: 'competence',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        getCompetence: (state, action: PayloadAction<ICompetence>) => {
            state.error = null
            state.isLoading = false
            state.data = action.payload
        },
        createMaterial: (state, action: PayloadAction<ICompetenceMaterial & SpecializationCompetenceIncludedUuids>) => {
            state.error = null
            state.isLoading = false
            const { skillLevelUuid, ...material } = action.payload
            state.data.levels.some(level => {
                if (level.uuid === skillLevelUuid) {
                    level.materials.push(material)
                    // eslint-disable-next-line no-param-reassign
                    level.studyTime += material.studyTime
                    return true
                }
            })
        },
        updateMaterial: (state, action: PayloadAction<ICompetenceMaterial & SpecializationCompetenceIncludedUuids>) => {
            state.error = null
            state.isLoading = false
            const { skillLevelUuid, ...material } = action.payload
            state.data.levels.some(level => {
                if (level.uuid === skillLevelUuid) {
                    level.materials.some((el, idx) => {
                        if (el.uuid === material.uuid) {
                            // eslint-disable-next-line no-param-reassign
                            level.studyTime += material.studyTime - level.materials[idx].studyTime
                            // eslint-disable-next-line no-param-reassign
                            level.materials[idx] = material
                        }
                    })
                    return true
                }
            })
        },
        deleteMaterial: (state, action: PayloadAction<{ uuid: string } & SpecializationCompetenceIncludedUuids>) => {
            state.isLoading = false
            state.error = null
            const { skillLevelUuid, uuid } = action.payload

            state.data.levels.some(level => {
                if (level.uuid === skillLevelUuid) {
                    const materialIndex = level.materials.findIndex(el => el.uuid === uuid)

                    // eslint-disable-next-line no-param-reassign
                    level.studyTime -= level.materials[materialIndex].studyTime
                    level.materials.splice(materialIndex, 1)
                    return true
                }
            })
        },
        createChain: (state, action: PayloadAction<ISpecializationCompetenceChangeOrderMaterialRequest>) => {
            state.data.levels.some(level => {
                if (level.uuid === action.payload.skillLevelUuid) {
                    // eslint-disable-next-line no-param-reassign
                    level.isCustom = true
                    return true
                }
            })
            state.isLoading = false
            state.error = null
        },
    },
})

export const competenceReducer = competenceSlice.reducer
