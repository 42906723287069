import React from 'react'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import { notifierActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { Box, Button, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { TITLE_BUTTON_OK } from 'helpers/enums/titles'


interface ReportCompleteProps {
    id: SnackbarKey;
    message: SnackbarMessage;
}

export const ReportError = React.forwardRef<HTMLDivElement, ReportCompleteProps>(function ReportError(
    props,
    ref,
) {
    const { id, message, ...other } = props
    const dispatch = useAppDispatch()
    const notifyClose = (key?: SnackbarKey) => dispatch(notifierActions.notifyClose(key))
    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    px: 2,
                    py: 0.5,
                    borderRadius: 4,
                    width: '100%',
                    maxWidth: 530,
                    backgroundColor: (theme) => theme.palette.error.main,
                }}
            >
                <InfoOutlinedIcon
                    sx={{
                        color: 'inherit',
                        marginRight: 1,
                    }}
                />
                <Typography
                    sx={{
                        color: 'inherit',
                        marginRight: 1,
                        my: 1,
                        flex: 1,
                    }}
                >
                    {message}
                </Typography>
                <Button
                    onClick={() => notifyClose(id)}
                    sx={{
                        color: 'inherit',
                        flex: '0 !important',
                    }}
                >
                    {TITLE_BUTTON_OK}
                </Button>
            </Box>
        </SnackbarContent>
    )
})
