import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { developmentPlanActions, openAccessActions } from 'store/actions'
import { TITLE_NAMESPACE_DEVELOPMENT_PLAN } from 'helpers/enums/titles'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { Loading } from 'components/Loading/Loading'
import {
    DevelopmentPlanCompetence,
} from 'pages/openAccess/OpenAccessPage/components/OAPdpPage/components/DevelopmentPlanPage/components/DevelopmentPlanCompetence/DevelopmentPlanCompetence'
import {
    DevelopmentPlanPage,
} from 'pages/openAccess/OpenAccessPage/components/OAPdpPage/components/DevelopmentPlanPage/DevelopmentPlanPage'

export const OAPdpPage = () => {
    const { pdpHash } = useParams()
    const dispatch = useAppDispatch()
    const { developmentPlan } = useAppSelector(state => state.openAccessDevelopmentPlanReducer.data)
    const { error, isFetching } = useLayoutFetch(
        developmentPlanActions.getDevelopmentPlan(pdpHash || ''),
        { deps: [pdpHash]},
    )

    useEffect(() => {
        dispatch(openAccessActions.setTitle(TITLE_NAMESPACE_DEVELOPMENT_PLAN))
    }, [dispatch])

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    if (!pdpHash) return <ErrorPage errorCode={404}/>
    if (!developmentPlan) return null

    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route index element={<DevelopmentPlanPage />} />
                <Route path="competence/:competenceUuid/:entityType" element={<DevelopmentPlanCompetence />} />
            </Routes>
        </Suspense>
    )
}
