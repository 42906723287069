import React, { FC } from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import {
    AssessmentResultTreeItemCommentsMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeItemMobile/components/AssessmentResultTreeItemCommentsMobile/AssessmentResultTreeItemCommentsMobile'
import {
    AssessmentResultTreeItemSkillLevelChipMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeItemMobile/components/AssessmentResultTreeItemSkillLevelChipMobile/AssessmentResultTreeItemSkillLevelChipMobile'

type AssessmentResultTreeItemMobileProps = {
    competence: IAssessmentCompetenceGroupCompetence;
    isDrawer?: boolean;
}

export const AssessmentResultTreeItemMobile: FC<AssessmentResultTreeItemMobileProps> = ({ competence, isDrawer }) => {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Stack spacing={0.5} flex={1} alignItems="flex-start">
                <Typography variant="body2">
                    {competence.title}
                </Typography>
                <Typography variant="body1">
                    {competence.typeTitle}
                </Typography>
                <AssessmentResultTreeItemSkillLevelChipMobile
                    isDrawer={isDrawer}
                    competence={competence}
                />
            </Stack>
            <AssessmentResultTreeItemCommentsMobile competence={competence}/>
            <Chip
                sx={{ minWidth: 56, marginLeft: 3 }}
                label={`${competence?.score}%`}
                color={competence?.score > 75 && !competence?.isUncompleted ? 'default' : 'warning'}
            />
        </Stack>
    )
}
