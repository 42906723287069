import React from 'react'
import { TITLE_NAMESPACE_DURATION, TITLE_NAMESPACE_MINUTES_SHORT } from 'helpers/enums/titles'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { AccessTimeRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import {
    OAAssessmentInstruction,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentHeader/components/OAAssessmentInstruction/OAAssessmentInstruction'
import { ButtonPrint } from 'components/common/ButtonPrint/ButtonPrint'
import { getAssessmentTimeToProcess } from 'helpers/assessment/getAssessmentTimeToProcess'

export const OAAssessmentHeader = () => {
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const assessmentStatus = getAssessmentStatus(assessment?.statusUuid)

    const createStatus = assessmentStatus?.code === 'created'
    const completeStatus = assessmentStatus?.code === 'complete'
    const minutes = getAssessmentTimeToProcess(assessment?.indicatorsCount)
    const durationTitle = `${TITLE_NAMESPACE_DURATION} ${minutes} ${TITLE_NAMESPACE_MINUTES_SHORT}`
    const evaluatedData = `${assessment?.evaluated?.firstName} ${assessment?.evaluated?.lastName}`

    return (
        <Stack spacing={4} divider={<Divider/>}>
            <Stack spacing={2}>
                <Typography variant="body2">
                    {evaluatedData}
                </Typography>
                <Box>
                    <PointPositionSpecializationGrade title={assessment?.position?.title}/>
                </Box>
                {completeStatus && <ButtonPrint/>}
            </Stack>
            <Stack spacing={1} alignItems="flex-start">
                {!createStatus && <Typography variant="h2">
                    Подробные результаты оценки
                </Typography>}
                <Stack spacing={1} direction="row" alignItems="center">
                    {createStatus && <Chip
                        icon={<AccessTimeRounded/>}
                        label={durationTitle}
                        sx={{
                            fontSize: 16,
                            lineHeight: '19px',
                            fontWeight: 500,
                            color: ({ palette }) => palette.text.secondary,
                            background: ({ palette }) => palette.background.default,
                            '& .MuiChip-icon': {
                                marginLeft: 1.5,
                            },
                        }}
                    />}
                    {!completeStatus && <OAAssessmentInstruction/>}
                </Stack>
            </Stack>
        </Stack>
    )
}
