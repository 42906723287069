import { TransferDevelopment } from 'api/services/DevelopmentService/transferDevelopment'

export class DevelopmentAssessmentService extends TransferDevelopment {
    private prefix = 'assessment'

    public getAssessmentList() {
        const path = `/${this.prefix}/all`
        return this.request<null, IAssessmentMeta[]>('GET', path)
    }
}
