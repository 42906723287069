import React, { FC } from 'react'
import { useCpaDictionaryScaleNumericWithState, useCpaScaleNumeric } from 'api/queries/cpa/cpaQueries'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { Chip, Grid, Typography } from '@mui/material'
import { TITLE_NAMESPACE_NOT_SELECTED_YET } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'
import { useParams } from 'react-router-dom'

type CPAScaleContentProps = {
    scaleUuid: string;
}

export const CPAScaleContent: FC<CPAScaleContentProps> = ({ scaleUuid }) => {
    const { cpaUuid } = useParams()
    const cpaStatus = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const cpaStatusCreated = cpaStatus === 'created' || cpaStatus === 'ready'
    const {
        data: data1,
        isFetching: isFetching1,
        error: error1,
    } = useCpaDictionaryScaleNumericWithState(scaleUuid, !!scaleUuid && cpaStatusCreated)
    const {
        data: data2,
        isFetching: isFetching2,
        error: error2,
    } = useCpaScaleNumeric(cpaUuid || '', !!cpaUuid && !cpaStatusCreated)

    const data = data1 || data2
    const isFetching = isFetching1 || isFetching2
    const error = error1 || error2

    if (error) return <ErrorPage error={error}/>
    if (isFetching) return <LoadingComponent/>
    if (!data) return <Typography variant="body1" sx={{ color: (theme) => theme.palette.warning.main }}>
        {TITLE_NAMESPACE_NOT_SELECTED_YET}
    </Typography>

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item mobile="auto">
                <Typography variant="body2">
                    {data.title}
                </Typography>
            </Grid>
            {data.answers.map(answer => <Grid key={answer.uuid} item mobile="auto">
                <Chip
                    color="default"
                    size="small"
                    label={answer.title}
                />
            </Grid>)}
        </Grid>
    )
}
