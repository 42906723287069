import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<ICompanyState> = {
    data: {} as ICompanyState,
    isLoading: false,
    error: null,
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        companyPending: (state) => {
            state.isLoading = true
        },
        companyFulfilled: (state) => {
            state.isLoading = true
        },
        companySuccess: (state, action: PayloadAction<ICompany>) => {
            state.isLoading = false
            state.error = null
            state.data.company = action.payload
        },
        clearState: () => initialState,

        companyError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        companyClearError: (state) => {
            state.error = null
            state.isLoading = false
        },
    },
})

export const companyReducer = companySlice.reducer
