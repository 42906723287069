import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { developmentPdpItemActions, pdpItemActions } from 'store/actions'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult, PreDragActions, SensorAPI, SnapDragActions,
} from '@hello-pangea/dnd'
import { Box, TableBody } from '@mui/material'
import { sortArrayFromSortIndex } from 'helpers/sortArray'
import { useParams } from 'react-router-dom'
import {
    PdpCompetenceMaterial,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewCompetence/components/PdpItemViewCompetenceContent/components/PdpCompetenceLevel/components/PdpCompetenceMaterial/PdpCompetenceMaterial'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { PdpCompetenceMaterialsDesktop } from '../PdpCompetenceMaterialsDesktop/PdpCompetenceMaterialsDesktop'
import { useDesktop } from 'helpers/hooks/useDesktop'

type PdpCompetenceMaterialsProps = {
    materials: IPdpCompetenceMaterial[];
    isDevelopment?: boolean;
    skillLevelUuid: string;
}

export const PdpCompetenceMaterials: FC<PdpCompetenceMaterialsProps> = ({
    isDevelopment,
    materials,
    skillLevelUuid,
}) => {
    const desktop = useDesktop()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { pdpUuid, itemUuid } = useParams()
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { item: pdpItem } = useAppSelector(state => state.pdpItemReducer.data)
    const { item: developmentItem } = useAppSelector(state => state.developmentPdpItemReducer.data)

    const item = pdpItem || developmentItem
    const employeeUuid = item?.employee?.uuid

    const pdpStatus = useMemo(() => getPdpStatus(item?.pdpStatus), [item?.pdpStatus?.uuid])

    const [arrMaterials, setArrMaterials] = useState<IPdpCompetenceMaterial[]>(materials)
    const sensorAPIRef = useRef<SensorAPI | undefined | null>(null)
    const [ isControlDragging, setIsControlDragging ] = useState(false)

    useEffect(() => {
        setArrMaterials(materials)
    }, [materials])

    const memoArrayMaterials: IPdpCompetenceMaterial[] = useMemo(
        () => sortArrayFromSortIndex(arrMaterials)?.map((el, idx) => ({
            ...el,
            sortIndex: idx,
        })),
        [arrMaterials],
    )


    const handleDragEnd = async (result: DropResult) => {
        setIsControlDragging(false)
        if (!result.destination
            || result.destination.index === result.source.index
            || !pdpUuid
            || !employeeUuid
            || !itemUuid
        ) return

        const newMaterialsArray = [...memoArrayMaterials]
        const destinationMaterial = newMaterialsArray[result.destination.index]

        const startIndex = Math.min(result.destination.index, result.source.index)
        const endIndex = Math.max(result.destination.index, result.source.index)

        for (let i = startIndex; i <= endIndex; i++) {
            let delta = 0
            result.source.index > result.destination.index ? delta = 1 : delta = -1
            if (i === result.source.index) {
                newMaterialsArray[i] = {
                    ...newMaterialsArray[i],
                    sortIndex: destinationMaterial.sortIndex,
                }
                continue
            }
            newMaterialsArray[i] = {
                ...newMaterialsArray[i],
                sortIndex: memoArrayMaterials[i + delta].sortIndex,
            }
        }

        try {
            setArrMaterials(newMaterialsArray)
            if (isDevelopment)
                void await dispatch(developmentPdpItemActions.changeOrderPdpMaterials({
                    items: newMaterialsArray.map(({ uuid, sortIndex, entityType }) => ({
                        uuid,
                        sortIndex,
                        entityType,
                    })),
                    pdpUuid,
                    itemUuid,
                    skillLevelUuid,
                    newMaterialsArray,
                }))
            else
                void await dispatch(pdpItemActions.changeOrderPdpCompetenceMaterial({
                    items: newMaterialsArray.map(({ uuid, sortIndex, entityType }) => ({
                        uuid,
                        sortIndex,
                        entityType,
                    })),
                    employeeUuid,
                    pdpUuid,
                    itemUuid,
                    skillLevelUuid,
                    newMaterialsArray,
                }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    function lift(quoteId: string): SnapDragActions | undefined | null {
        setIsControlDragging(true)
        const api: SensorAPI | undefined | null = sensorAPIRef.current
        if (!api) {
            return null
        }

        const preDrag: PreDragActions | undefined | null = api.tryGetLock(quoteId)
        if (!preDrag) {
            return null
        }
        return preDrag.snapLift()
    }

    return (
        <Box>
            {desktop ?
                <PdpCompetenceMaterialsDesktop
                    employee={employee}
                    isDevelopment={isDevelopment}
                    pdpStatus={pdpStatus}
                >
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(droppableProvided: DroppableProvided) => (
                                <TableBody
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {memoArrayMaterials.map((item, index) => (
                                        <Draggable
                                            key={item.uuid}
                                            draggableId={item.uuid}
                                            index={index}
                                        >
                                            {(draggableProvided: DraggableProvided,
                                                snapshot: DraggableStateSnapshot,
                                            ) => (
                                                <PdpCompetenceMaterial
                                                    material={item}
                                                    pdpStatus={pdpStatus}
                                                    isDevelopment={isDevelopment}
                                                    draggableProvided={draggableProvided}
                                                    snapshot={snapshot}
                                                    skillLevelUuid={skillLevelUuid}
                                                    lift={lift}
                                                    isControlDragging={isControlDragging}
                                                    firstItem={false}
                                                    lastItem={false}
                                                />
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </PdpCompetenceMaterialsDesktop>
                :
                <DragDropContext
                    onDragEnd={handleDragEnd}
                    sensors={[
                        (api) => {
                            sensorAPIRef.current = api
                        },
                    ]}
                >
                    <Droppable droppableId="droppable" direction="vertical">
                        {(droppableProvided: DroppableProvided) => (
                            <Box
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {memoArrayMaterials.map((item, index) => (
                                    <Draggable
                                        key={item.uuid}
                                        draggableId={item.uuid}
                                        index={index}
                                    >
                                        {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                            <PdpCompetenceMaterial
                                                ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps}
                                                isControlDragging={isControlDragging}
                                                material={item}
                                                pdpStatus={pdpStatus}
                                                isDevelopment={isDevelopment}
                                                draggableProvided={draggableProvided}
                                                snapshot={snapshot}
                                                skillLevelUuid={skillLevelUuid}
                                                firstItem={index === 0}
                                                lastItem={index === memoArrayMaterials.length - 1}
                                                lift={lift}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </Box>
    )
}

