import { AppDispatch } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import { CPAItemCriteriaSlice } from 'store/reducers/cpa/item/CPAItemCriteriaSlice'
import {
    ICPAGetCriteriaCompetenceSkillLevel,
    ICPAGetCriteriaPointResponse,
    ICPASetCriteriaCompetenceRequest,
    ICPASetCriteriaPointRequest,
} from 'api/typing/cpaTypes'
import {
    cpaCriteriaCompetenceUuid,
    cpaCriteriaCurrentUuid,
    cpaCriteriaPointUuid,
} from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { CPAItemSlice } from 'store/reducers/cpa/item/CPAItemSlice'


export class CpaItemCriteriaAction {
    private static get cpaItemCriteriaApi() {
        return new CPAService().itemService()
    }

    public setCriteriaPoint(data: ICPAGetCriteriaPointResponse) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setCpaCriteriaPoint(data))
            dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaPointUuid))
        }
    }

    public saveCriteriaPoint(data: ICPASetCriteriaPointRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemCriteriaSlice.actions.fetching())
                void await CpaItemCriteriaAction.cpaItemCriteriaApi.setItemCriteriaPoint(data)
                dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaPointUuid))
                dispatch(CPAItemSlice.actions.setCpaCriteriaUuid({
                    title: '',
                    uuid: cpaCriteriaPointUuid,
                }))
                dispatch(CPAItemCriteriaSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemCriteriaSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setCriteriaCurrent() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaCurrentUuid))
        }
    }

    public saveCriteriaCurrent(cpaUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemCriteriaSlice.actions.fetching())
                void await CpaItemCriteriaAction.cpaItemCriteriaApi.setItemCriteriaCurrent(cpaUuid)
                dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaCurrentUuid))
                dispatch(CPAItemSlice.actions.setCpaCriteriaUuid({
                    title: '',
                    uuid: cpaCriteriaCurrentUuid,
                }))
                dispatch(CPAItemCriteriaSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemCriteriaSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setCriteriaCompetence(data: ICPAGetCriteriaCompetenceSkillLevel[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setCpaCriteriaCompetence(data))
            dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaCompetenceUuid))
        }
    }

    public setCriteriaCompetenceList(data: ICpaCriteriaCompetenceGroup[]) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setCriteriaCompetenceList(data))
        }
    }

    public criteriaCompetenceChangeActiveGroup(data: { uuid: string; active: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.criteriaCompetenceChangeActiveGroup(data))
        }
    }

    public criteriaCompetenceChangeActiveCompetence(data: { uuid: string; groupUuid: string; active: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.criteriaCompetenceChangeActiveCompetence(data))
        }
    }

    public setNewCompetenciesInCriteria() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setNewCompetenciesInCriteria())
        }
    }

    public setCriteriaCompetenceItemSkillLevel(data: {
        competenceUuid: string;
        skillLevelUuid: string;
        competenceTypeUuid: string;
    }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.setCriteriaCompetenceItemSkillLevel(data))

        }
    }

    public deleteCriteriaCompetenceItem(data: { competenceUuid: string; competenceTypeUuid: string }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.deleteCriteriaCompetenceItem(data))
        }
    }

    public saveCriteriaCompetence(payload: ICPASetCriteriaCompetenceRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemCriteriaSlice.actions.fetching())
                void await CpaItemCriteriaAction.cpaItemCriteriaApi.setItemCriteriaCompetence(payload)
                dispatch(CPAItemCriteriaSlice.actions.saveCriteriaCompetence())
                dispatch(CPAItemCriteriaSlice.actions.setCpaCurrentCriteriaUuid(cpaCriteriaCompetenceUuid))
                dispatch(CPAItemSlice.actions.setCpaCriteriaUuid({
                    title: '',
                    uuid: cpaCriteriaCompetenceUuid,
                }))
                dispatch(CPAItemCriteriaSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemCriteriaSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearCompetenceCriteria() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.clearCompetenceCriteria())
        }
    }

    public cancelEditCompetenceCriteria() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.cancelEditCompetenceCriteria())
        }
    }

    public restoreInitialCriteriaCompetence() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.restoreInitialCriteriaCompetence())
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemCriteriaSlice.actions.clearState())
        }
    }
}
