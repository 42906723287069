import React, { FC, useMemo, useState } from 'react'
import { Box, Button, Drawer, IconButton, Stack, TextField, Typography } from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_PLACEHOLDER_SEARCH_COMPETENCE_OR_GROUP,
    TITLE_NAMESPACE_CPA_CRITERIA_SELECT_FOR_COMPETENCE,
    TITLE_NAMESPACE_SELECTED_COMPETENCE_COUNT,
} from 'helpers/enums/titles'
import { ClearRounded, CloseRounded, SearchRounded } from '@mui/icons-material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useFormCpaCriteriaCompetencies } from 'api/queries/form/formQueries'
import { containsText } from 'helpers/containsText'
import {
    CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/components/CPACriteriaDrawerItemCompetenceDrawer/componenst/CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup/CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { cpaItemCriteriaActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'

type CPACriteriaDrawerItemCompetenceDrawerProps = {
    open: boolean;
    handleClose: () => void;
}


export function filterGroupCompetence(
    groups: ICpaCriteriaCompetenceGroup[],
    search: string,
): ICpaCriteriaCompetenceGroup[] {

    const filterCompetence = (competencies: ICpaCriteriaCompetence[], search: string) => {
        return competencies.filter(competence => containsText(competence.title, search))
    }

    return groups.reduce((arr: ICpaCriteriaCompetenceGroup[], group) => {
        if (containsText(group.title, search)) {
            arr.push(group)
            return arr
        }

        const competencies = filterCompetence(group.competencies, search)
        const subGroups = filterGroupCompetence(group.subGroups, search)

        if (competencies.length || subGroups.length) {
            arr.push({
                ...group,
                subGroups,
                competencies,
            })
            return arr
        }

        return arr
    }, [])
}

export const CPACriteriaDrawerItemCompetenceDrawer: FC<CPACriteriaDrawerItemCompetenceDrawerProps> = ({
    open,
    handleClose,
}) => {
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState('')
    const { error, data, isFetching } = useFormCpaCriteriaCompetencies()
    const { list: dataList, saved } = useAppSelector(state => state.CPAItemCriteriaReducer.competenceCriteria)
    const filteredData = useMemo(() => data && dataList
        ? filterGroupCompetence(dataList, search)
        : [], [search, data, dataList])

    const saveCompetencies = () => {
        dispatch(cpaItemCriteriaActions.setNewCompetenciesInCriteria())
        handleClose()
    }

    const competenceCountTitle = `${TITLE_NAMESPACE_SELECTED_COMPETENCE_COUNT}: ${saved.length}`

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 'calc(100% - 320px)' },
                },
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box display="flex" flexDirection="column" px={5} pb={3} pt={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_CPA_CRITERIA_SELECT_FOR_COMPETENCE}
                            </Typography>
                            <Box mb={5} display="flex" justifyContent="space-between" alignItems="flex-end">
                                <Box flex={1} maxWidth={600}>
                                    <TextField
                                        fullWidth
                                        placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_COMPETENCE_OR_GROUP}
                                        InputProps={{
                                            endAdornment: search.length > 0
                                                ? <IconButton
                                                    size="small"
                                                    onClick={() => setSearch('')}
                                                >
                                                    <ClearRounded color="disabled"/>
                                                </IconButton>
                                                : <SearchRounded color="disabled"/>,
                                        }}
                                        value={search}
                                        onChange={({ target: { value } }) => setSearch(value)}
                                    />
                                </Box>
                                <Stack spacing={2}>
                                    <Typography variant="body1" textAlign="right">
                                        {competenceCountTitle}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        <Button onClick={handleClose}>
                                            {TITLE_BUTTON_CANCEL}
                                        </Button>
                                        <Button variant="contained" onClick={saveCompetencies}>
                                            {TITLE_BUTTON_SAVE}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" flex={1} overflow="auto" px={5} pb={5}>
                    <>
                        {error && !isFetching && <ErrorPage error={error}/>}
                        {isFetching && <LoadingComponent/>}
                        {!error && !isFetching && filteredData.map(group =>
                            <CPACriteriaDrawerItemCompetenceDrawerCompetenceGroup
                                key={group.uuid}
                                item={group}
                            />)}
                    </>
                </Box>
            </Box>
        </Drawer>
    )
}
