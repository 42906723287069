import React, { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { sortArrayFromSortIndex } from 'helpers/sortArray'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { TITLE_NAMESPACE_KNOWLEDGE_BASE } from 'helpers/enums/titles'
import {
    DevelopmentPdpCompetenceLevel,
} from 'pages/development/DevelopmentPage/DevelopmentPdpItemViewPage/DevelopmentPdpItemViewCompetence/DevelopmentPdpItemViewCompetenceContent/components/DevelopmentPdpCompetenceLevel/DevelopmentPdpCompetenceLevel'

export const HistoryPdpItemViewCompetenceContent = () => {
    const { item: competence } = useAppSelector(state => state.employeeHistoryPdpReducer.data)
    const pdpStatus = getPdpStatus(competence?.pdpStatus)
    const filteredLevels = useMemo(
        () => competence?.skillLevels ? sortArrayFromSortIndex(competence?.skillLevels)
            .filter(level => level.materials.length > 0) : [],
        [competence, competence?.skillLevels],
    )

    return (
        <Stack spacing={6}>
            <Typography variant="h3">
                {TITLE_NAMESPACE_KNOWLEDGE_BASE}
            </Typography>
            {filteredLevels.map(level => (
                <DevelopmentPdpCompetenceLevel key={level.uuid} level={level} pdpStatus={pdpStatus}/>))}
        </Stack>
    )
}
