import React from 'react'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { ChipStatus } from 'components/chips/ChipStatus/ChipStatus'
import {
    CPAMetaSecondaryAction,
} from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaSecondaryAction/CPAMetaSecondaryAction'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import { TITLE_NAMESPACE_WAS_CREATED, TITLE_NAMESPACE_WAS_LAUNCHED } from 'helpers/enums/titles'
import { CPAMetaAlert } from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaAlert/CPAMetaAlert'
import { CPAMetaInfo } from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaInfo/CPAMetaInfo'
import { useCpaItem } from 'api/queries/cpa/cpaQueries'
import { useParams } from 'react-router-dom'
import { CPAMetaSkeleton } from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaSkeleton/CPAMetaSkeleton'
import {
    CPAMetaButtonAction,
} from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaButtonAction/CPAMetaButtonAction'
import { useAppSelector } from 'store/hooks/redux'
import { CPAMetaProgress } from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaProgress/CPAMetaProgress'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { getCpaStatusChip } from 'helpers/cpa/cpaStatuses'

export const CPAMeta = () => {
    const { cpaUuid } = useParams()
    const { isFetching } = useCpaItem(cpaUuid || '')
    const itemMeta = useAppSelector(state => state.CPAItemReducer.meta)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)

    if (isFetching) return <CPAMetaSkeleton/>

    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box flex={1} overflow="hidden">
                                <Typography variant="h1">
                                    {itemMeta.title}
                                </Typography>
                            </Box>
                            <Authenticated service="cpa" accessLevel="wx">
                                <CPAMetaButtonAction/>
                            </Authenticated>
                            <ChipStatus status={getCpaStatusChip(itemMeta.statusUuid)}/>
                            <Authenticated service="cpa" accessLevel="wx">
                                <CPAMetaSecondaryAction/>
                            </Authenticated>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <ChipAssessmentType cpaType={itemMeta.typeUuid}/>
                            <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem/>}>
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_WAS_CREATED} {itemMeta.createdAt}
                                </Typography>
                                {cpaStatusCode !== 'created' && cpaStatusCode !== 'ready' &&
                                    <Typography variant="body1">
                                        {TITLE_NAMESPACE_WAS_LAUNCHED} {itemMeta.startedAt}
                                    </Typography>}
                            </Stack>
                        </Stack>
                    </Stack>
                    <CPAMetaAlert/>
                    <Divider/>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                        divider={<Divider orientation="vertical" flexItem/>}
                    >
                        <CPAMetaInfo/>
                        {cpaStatusCode !== 'created' && cpaStatusCode !== 'ready' && <CPAMetaProgress/>}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}
