import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { TITLE_NAMESPACE_EMPLOYEE, TITLE_NAMESPACE_STATUS } from 'helpers/enums/titles'
import {
    CPAEmployeesTableItem,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/CPAEmployeesTableItem'
import { useAppSelector } from 'store/hooks/redux'

type CPAEmployeesTableProps = {
    employees: ICpaItemDivisionEmployee[];
    divisionUuid: string;
}

export const CPAEmployeesTable: FC<CPAEmployeesTableProps> = ({ employees, divisionUuid }) => {
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const showStatus = cpaStatusCode !== 'created' && cpaStatusCode !== 'ready'

    return (
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell width="100%" align="left">{TITLE_NAMESPACE_EMPLOYEE}</TableCell>
                        {showStatus && <TableCell width="1%" align="left">
                            {TITLE_NAMESPACE_STATUS}
                        </TableCell>}
                        <TableCell width="1%" align="left"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees?.map((employee) => (
                        <CPAEmployeesTableItem
                            key={employee.uuid}
                            employee={employee}
                            divisionUuid={divisionUuid}
                            showStatus={showStatus}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
