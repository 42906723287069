import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {
    TITLE_NAMESPACE_COMMENT, TITLE_NAMESPACE_FORMAT,
    TITLE_NAMESPACE_LEVEL, TITLE_NAMESPACE_LINK, TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_TITLE,
    TITLE_NAMESPACE_TYPE,
} from 'helpers/enums/titles'
import { PdpSetupMaterial } from '../PdpSetupMaterial/PdpSetupMaterial'
import React, { FC } from 'react'
import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'

type PdpSetupMaterialsTableProps = {
    isDevelopment?: boolean;
    filteredValues: IPdpMaterialFromBase[];
}
export const PdpSetupMaterialsTable: FC<PdpSetupMaterialsTableProps> = ({ filteredValues, isDevelopment}) => (
    <TableContainer sx={{ maxHeight: '100%' }}>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell width="1%" align="center"/>
                    <TableCell width="1%" align="left">{TITLE_NAMESPACE_TYPE}</TableCell>
                    <TableCell width="100%" align="left">{TITLE_NAMESPACE_TITLE}</TableCell>
                    <TableCell width="1%" align="left">{TITLE_NAMESPACE_LEVEL}</TableCell>
                    <TableCell width="1%" align="center">{TITLE_NAMESPACE_COMMENT}</TableCell>
                    <TableCell width="1%" align="left">{TITLE_NAMESPACE_FORMAT}</TableCell>
                    <TableCell width="1%" sx={{ whiteSpace: 'nowrap' }} align="left">
                        {TITLE_NAMESPACE_STUDY_TIME}
                    </TableCell>
                    <TableCell padding="checkbox" align="left">{TITLE_NAMESPACE_LINK}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredValues?.map(material => (
                    <PdpSetupMaterial
                        key={material.uuid}
                        material={material}
                        isDevelopment={isDevelopment}
                    />))}
            </TableBody>
        </Table>
    </TableContainer>
)
