import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { Box, Button } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT } from 'helpers/enums/titles'
import {
    EmployeeAssessment,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/EmployeeAssessment'

export const EmployeeAssessmentPage = () => {
    const { assessmentUuid, employeeUuid } = useParams()
    const navigate = useNavigate()
    const parentPath = `${PATH_EMPLOYEES}/${employeeUuid}/assessment/${assessmentUuid}/result`

    return (
        <Box>
            <Button
                sx={{ mb: 3 }}
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate(parentPath, { replace: true })}
            >
                {TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT}
            </Button>
            <EmployeeAssessment/>
        </Box>
    )
}
