import React, { useMemo } from 'react'
import {
    Card,
    Divider,
    Stack,
} from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { developmentPdpActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { DevelopmentPdpInfoMobile } from '../DevelopmentPdpInfoMobile/DevelopmentPdpInfoMobile'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { DevelopmentPdpHeader } from '../DevelopmentPdpHeader/DevelopmentPdpHeader'
import { DevelopmentPdpInfo } from '../DevelopmentPdpInfo/DevelopmentPdpInfo'
import { DevelopmentPdpContent } from '../DevelopmentPdpContent/DevelopmentPdpContent'

export const DevelopmentPdpPlan = () => {
    const desktop = useDesktop()

    const { pdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const statusPdp = useMemo(() => getPdpStatus(pdp?.status, false), [pdp, pdp?.status?.uuid])
    const { isFetching, error } = useLayoutFetch(developmentPdpActions.getPdp(), {
        permissions: { service: 'my_development', accessLevel: 'r' },
    })

    const developmentPdpPlanWrapStyles = desktop ? {  width: '100%', p: 3 } : {
        width: '100%',
        border: 'none',
        boxShadow: 'none',
        overflow: 'unset',
        borderRadius: 0,
    }

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Card
            sx={developmentPdpPlanWrapStyles}
        >
            <Stack spacing={desktop ? 6 : 0}>
                <DevelopmentPdpHeader pdp={pdp} pdpStatus={statusPdp} />
                {!desktop ?
                    <DevelopmentPdpInfoMobile pdp={pdp} pdpStatus={statusPdp} />
                    :
                    <DevelopmentPdpInfo pdp={pdp} pdpStatus={statusPdp} />
                }
                <Divider sx={{ my: !desktop ? 6 : 0 }} />
                <DevelopmentPdpContent studyTime={pdp?.studyTime} />
            </Stack>
        </Card>
    )
}
