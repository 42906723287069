import React, { useMemo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'

export const CPAEmployeesCreateAlert = () => {
    const statusState = useAppSelector(state => state.CPAItemReducer.statusState)
    const valueInPercent = useMemo(() => statusState
        ? Math.floor(statusState?.process.completedCount / statusState?.process.assessmentsCount * 100)
        : 0, [statusState])

    const textAlert = `Обновление статусов оценок сотрудников. Готово ${statusState?.process.completedCount} из ${statusState?.process.assessmentsCount}.`

    if (!statusState || statusState.fulfilled && !statusState.hasError) return null
    return (
        <GFAlertInfo
            text={textAlert}
            action={<GFProgressBar width={160} value={valueInPercent}/>}
        />
    )
}
