
export const logRequestTiming = (
    name: string,
    method?: string,
    url?: string,
    time = 0,
    error = false,
) => {
    let urlToLog
    try {
        urlToLog = decodeURIComponent(url || '<URL>')
    } catch (e) {
        urlToLog = e
    }
    // eslint-disable-next-line no-console
    console[error ? 'error' : 'log'](
        '[%s]: %s -> [%s] [%o]ms',
        name,
        method?.toUpperCase() || '<METHOD>',
        urlToLog,
        +time.toFixed(0),
    )
}

export const logResponseError = (
    baseURL: string,
    url: string,
    status?: number | string,
    data = '',
) => {
    const reason = typeof data === 'string' && data?.includes('<title>')
        ? data.match(/<title>(.*?)<\/title>/g)?.[0]
        : data
    let urlToLog
    try {
        urlToLog = decodeURIComponent(url || '<URL>')
    } catch (e) {
        urlToLog = e
    }

    // eslint-disable-next-line no-console
    console.error(
        '[Ошибка в запросе] %s [%s] [%o] %s',
        '',
        urlToLog,
        status || '<STATUS>',
        reason,
    )
}
