import React, { FC, useState } from 'react'
import { Box, ImageListItem } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from 'store/hooks/redux'
import logo from 'assets/icons/logo.svg'


export const AppMenuDesktopHeader: FC = () => {
    const companyState = useAppSelector(state => state.companyReducer.data)
    const { pathname } = useLocation()
    const [imgSrc, setImgSrc] = useState<string>()

    React.useEffect(() => {
        if (!companyState?.company) return
        setImgSrc(`${process.env.REACT_APP_CDN_URL}branding/${companyState?.company?.uuid}/logo.png`)
    }, [companyState])

    const linkUrl = '/'

    const isCurrentPath = pathname === linkUrl

    return (
        <Box px={1} my={4} display="flex" justifyContent="center" alignItems="center" minHeight={92}>
            <Link to={isCurrentPath ? '#' : linkUrl}>
                <ImageListItem sx={{ maxWidth: 196 }}>
                    <img
                        loading="lazy"
                        src={imgSrc}
                        srcSet={imgSrc}
                        onError={() => setImgSrc(logo)}
                    />
                </ImageListItem>
            </Link>
        </Box>
    )
}
