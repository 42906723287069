import React, { FC, useMemo, useState } from 'react'
import {
    SpecializationListDivision,
} from 'pages/specialization/SpecializationList/components/SpecializationListContent/components/SpecializationListDivision/SpecializationListDivision'
import { Box, IconButton } from '@mui/material'
import { containsText } from 'helpers/containsText'
import { TITLE_FIELD_PLACEHOLDER_SEARCH_SPECIALIZATION } from 'helpers/enums/titles'
import { ClearRounded, SearchRounded } from '@mui/icons-material'
import { InputForm } from 'components/form/InputForm/InputForm'

type SpecializationListContentProps = {
    list: ISpecializationListDivision[];
}
export const SpecializationListContent: FC<SpecializationListContentProps> = ({ list }) => {
    const [search, setSearch] = useState('')
    const filteredList = useMemo(() => list?.map(division => ({
        ...division,
        specializations: division?.specializations?.filter(specialization =>
            containsText(specialization.title, search)),
    })).filter((el) => el?.specializations?.length) || [],
    [search, list])

    return (
        <Box mt={4.5}>
            <Box mb={5}>
                <InputForm
                    id="search-specialization"
                    placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_SPECIALIZATION}
                    variant="outlined"
                    value={search}
                    sx={{
                        maxWidth: 600,
                        mr: 2,
                    }}
                    InputProps={{
                        endAdornment: search.length > 0
                            ? <IconButton
                                size="small"
                                onClick={() => setSearch('')}
                            >
                                <ClearRounded color="disabled"/>
                            </IconButton>
                            : <SearchRounded color="disabled"/>,
                    }}
                    onChange={({ target: { value } }) => setSearch(value)}
                />
            </Box>
            {filteredList.map(division => <SpecializationListDivision key={division.uuid} element={division}/>)}
        </Box>
    )
}

