import { Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CheckIcon from '@mui/icons-material/Check'
import React, { FC, useState } from 'react'
import { REPORT_EXPORT_FILE_AWAIT } from 'helpers/enums/hints'
import { useNotify } from 'store/hooks/useNotify'

type AnalyticReportButtonProps = {
    buttonTitle: string;
    getReportLink: () => Promise<string>;
}

export const AnalyticReportButton: FC<AnalyticReportButtonProps> = ({ buttonTitle, getReportLink }) => {
    const [loading, setLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const notify = useNotify()

    const analyticExport =  async () => {
        setLoading(true)
        notify(REPORT_EXPORT_FILE_AWAIT, 'success')
        try {
            const link = document.createElement('a')
            document.body.appendChild(link)
            const generatedFileUrl = await getReportLink()
            if (generatedFileUrl) {
                link.href = generatedFileUrl
                setIsLoaded(true)
                link.click()
            }
        } catch (e: any) {
            setLoading(false)
            notify(e.userMessage, 'error')
            return
        }
        setLoading(false)
    }

    return (
        <Stack spacing={2} alignSelf="stretch" direction="row" >
            <LoadingButton
                onClick={analyticExport}
                startIcon={<InsertDriveFileIcon/>}
                color="primary"
                variant="outlined"
                loading={loading}
                sx={{whiteSpace: 'nowrap'}}
            >
                {buttonTitle}
            </LoadingButton>
            {isLoaded && <CheckIcon fontSize="large" color="success"/>}
        </Stack>
    )
}
