import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Stack } from '@mui/material'
import {
    MyAssessmentPassCompetence,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/components/MyAssessmentPassCompetence/MyAssessmentPassCompetence'
import {
    EmployeeAssessmentCompetence,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/EmployeeAssessmentCompetence'

export const MyAssessmentPassCompetencies = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    const showAssessmentDetailed = assessmentSetup?.completeStatus && assessmentSetup?.viewerIsEvaluated

    return (
        <Stack spacing={8}>
            {assessment?.competencies.map(competence => showAssessmentDetailed
                ? <EmployeeAssessmentCompetence
                    key={competence.uuid}
                    competenceUuid={competence.uuid}
                />
                : <MyAssessmentPassCompetence
                    key={competence.uuid}
                    competenceUuid={competence.uuid}
                />)}
        </Stack>
    )
}
