import { BaseApi } from 'api/index'
import {
    IPasswordRecoverFromEmail,
    IPasswordRestore,
    IAuthResponse,
    ILoginRequest,
    IHashVerify,
    ISendInvite,
} from 'api/typing/authServiceTypes'

export class AuthService extends BaseApi {
    protected endpoint = 'auth'

    public login(data: ILoginRequest) {
        return this.request<ILoginRequest, IAuthResponse>('POST', '/login', { data })
    }

    public sendInvite(data: ISendInvite) {
        return this.request<ISendInvite, null>('POST', '/invite', { data })
    }

    public passwordRecoverFromEmail(data: IPasswordRecoverFromEmail) {
        return this.request<IPasswordRecoverFromEmail, null>('POST', '/recover-password/email', { data })
    }

    public verifyHash(data: IHashVerify) {
        return this.request<IHashVerify, null>('POST', '/verify', { data })
    }

    public passwordRestore(data: IPasswordRestore) {
        return this.request<IPasswordRestore, IAuthResponse>('POST', '/restore-password', { data })
    }

    public logout(): Promise<void> {
        return this.request('POST', '/logout')
    }
}
