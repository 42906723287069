import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReportAssessment, IReportCPA } from 'api/typing/reportServiceTypes'


interface IReportState {
    report: IReportAssessment | IReportCPA
}

const initialState: IState<IReportState> = {
    data: {} as IReportState,
    isLoading: false,
    error: null,
}

export const ReportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setAssessmentReport: (state, action:PayloadAction<IReportAssessment>) => {
            state.data.report = action.payload
        },
        setAssessmentLink: (state, action:PayloadAction<string>) => {
            state.data.report.reportLink = action.payload
        },

        setCPAReport: (state, action:PayloadAction<IReportCPA>) => {
            state.data.report = action.payload
        },
        setCPALink: (state, action:PayloadAction<string>) => {
            state.data.report.reportLink = action.payload
        },
        setAnalyticReport: (state, action:PayloadAction<IReportCPA>) => {
            state.data.report = action.payload
        },
        setAnalyticLink: (state, action:PayloadAction<string>) => {
            state.data.report.reportLink = action.payload
        },
    },
})

export const reportReducer = ReportSlice.reducer
