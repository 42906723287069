import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { getSpiderData } from 'helpers/assessment/resultStateFunction'
import { Box, Chip, Stack, useMediaQuery, useTheme } from '@mui/material'
import { GraphicSpider } from 'components/graphics/GraphicSpider/GraphicSpider'
import { InfoOutlined } from '@mui/icons-material'

export const AssessmentResultSpider = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const cpaRoles = useAppSelector(state => state.formReducer.data.cpaRoles)
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)

    const { data, keys, isSliced } = getSpiderData(result?.groupCompetence, cpaRoles, isMobile)

    if (data.length < 3) return null

    return (
        <Stack spacing={3}>
            <Box>
                {isSliced && <Chip
                    label="На диаграмме представлены компетенции с наибольшим расхождением в ответах"
                    variant="outlined"
                    color="primary"
                    size="small"
                    icon={<InfoOutlined/>}
                />}
            </Box>
            <Box width="100%" height={500}>
                <GraphicSpider data={data} keys={keys} indexBy="title" maxValue={100}/>
            </Box>
        </Stack>
    )
}
