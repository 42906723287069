import React, { FC, useMemo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Card, CardContent, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_EMPLOYEE_EDIT_PREVIOUS_WORK_EXPERIENCE,
    TITLE_NAMESPACE_PREVIOUS_JOBS,
    TITLE_NAMESPACE_PROJECTS_AND_ROLES,
} from 'helpers/enums/titles'
import { EditRounded } from '@mui/icons-material'
import { HINT_NO_DATA } from 'helpers/enums/hints'
import {
    PreviousJobsItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabExperience/components/PreviousJobsCard/components/PreviousJobsItem/PreviousJobsItem'
import {
    PreviousProjectsAndRolesItem,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabExperience/components/PreviousProjectsAndRolesCard/components/PreviousProjectsAndRolesItem/PreviousProjectsAndRolesItem'
import { useNavigate } from 'react-router-dom'
import { PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import {
    addDays,
    differenceInDays,
    parse,
} from 'date-fns'
import { formatTimeToStringDuration } from 'helpers/dateAndTime/formatTimeToStringDuration'
import { useFetch } from 'store/hooks/useFetch'
import { employeeActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'

type EmployeeTabInfoExperienceProps = {
    employeeUuid: string;
    parentPath: string;
    hasNotPermissionEdit: boolean;
}

export const EmployeeTabInfoExperience: FC<EmployeeTabInfoExperienceProps> = ({
    employeeUuid,
    parentPath,
    hasNotPermissionEdit,
}) => {
    const navigate = useNavigate()
    const {
        data: jobs,
    } = useAppSelector(state => state.employeeEditWorkExperiencePreviousJobReducer)
    const {
        data: projectsAndRoles,
    } = useAppSelector(state => state.employeeEditWorkExperienceProjectsAndRolesReducer)

    const { isFetching: loadingPreviousJob } =
        useFetch(employeeActions.getPreviousJobs(employeeUuid), [employeeUuid])
    const { isFetching: loadingProjectsAndRoles } =
        useFetch(employeeActions.getPreviousProjectAndRoles(employeeUuid), [employeeUuid])
    const loading = loadingPreviousJob || loadingProjectsAndRoles

    const daysOfWork = useMemo(
        () => jobs?.reduce((acc, el) => {
            const timeToParseStart = el?.startDate && parse(el?.startDate, 'dd.MM.yyyy', new Date())
            const timeToParseEnd = el?.endDate && parse(el?.endDate, 'dd.MM.yyyy', new Date())

            if (!timeToParseStart || !timeToParseEnd) return acc

            const daysCount = differenceInDays(timeToParseEnd, timeToParseStart)
            return acc + daysCount
        }, 0),
        [jobs],
    )

    const timeDuration = useMemo(() => {
        if (daysOfWork < 0) return ''
        const startCalcDate = new Date()
        const endCalcDate = addDays(startCalcDate, daysOfWork)
        return formatTimeToStringDuration(startCalcDate, endCalcDate)
    }, [daysOfWork])

    if (loading) return <LoadingComponent />

    return (
        <Card>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Box display="flex">
                        <Typography variant="h2" mr={2}>
                            {TITLE_NAMESPACE_EMPLOYEE_EDIT_PREVIOUS_WORK_EXPERIENCE}
                        </Typography>
                        {timeDuration && <Chip
                            label={timeDuration}
                            size="small"
                        />}
                    </Box>
                    <IconButton
                        color="primary"
                        disabled={hasNotPermissionEdit}
                        onClick={() => navigate(`${parentPath}/${PATH_EMPLOYEES_EDIT}/experience`)}
                    >
                        <EditRounded />
                    </IconButton>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2} divider={<Divider sx={{ my: 2 }} />}>
                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_PREVIOUS_JOBS}
                        </Typography>

                        {jobs && jobs.length > 0
                            ? <Stack spacing={2}>{jobs.map(job => <PreviousJobsItem
                                key={job.uuid}
                                previousJob={job}
                                pageViewEmployee
                                employeeUuid={employeeUuid}
                            />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>

                    <Box>
                        <Typography variant="subtitle1">
                            {TITLE_NAMESPACE_PROJECTS_AND_ROLES}
                        </Typography>
                        {projectsAndRoles && projectsAndRoles.length > 0
                            ? <Stack spacing={2}>{projectsAndRoles.map(projectAndRole =>
                                <PreviousProjectsAndRolesItem
                                    key={projectAndRole.uuid}
                                    previousProjectAndRole={projectAndRole}
                                    employeeUuid={employeeUuid}
                                    pageViewEmployee
                                />)}
                            </Stack>
                            : <Chip
                                sx={{ mt: 2 }}
                                label={HINT_NO_DATA}
                                size="small"
                                disabled
                            />}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

