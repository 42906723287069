import React, { FC } from 'react'
import { Chip } from '@mui/material'

type ChipStatusProps = {
    status: IStatusChip;
}

export const ChipStatus: FC<ChipStatusProps> = ({ status }) => {
    return (
        <Chip
            label={status.title}
            icon={status.icon}
            color={status.color}
            variant={status.variant}
        />
    )
}
