import React, { FC } from 'react'
import {
    AppMenuDesktopList,
} from 'components/AppMenu/components/AppMenuDesktop/components/AppMenuDesktopList/AppMenuDesktopList'
import { additionalMenuItems, mainMenuItems } from 'helpers/enums/menuItemsPosition'
import { Box, Divider } from '@mui/material'

export const AppMenuDesktopContent: FC = () => {
    return (
        <Box mt={2}>
            <AppMenuDesktopList menuItems={mainMenuItems}/>
            <Divider variant="middle" sx={{ my: 1 }}/>
            <AppMenuDesktopList menuItems={additionalMenuItems}/>
        </Box>

    )
}

