import React, { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { TITLE_NAMESPACE_COMPLETED } from 'helpers/enums/titles'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import {
    CPAMetaProgressButton,
} from 'pages/cpa/CPAPage/components/CPAMeta/components/CPAMetaProgress/components/CPAMetaProgressButton/CPAMetaProgressButton'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

export const CPAMetaProgress = () => {
    const meta = useAppSelector(state => state.CPAItemReducer.meta)
    const currentProgress = useMemo(
        () => {
            const completedCount = meta?.progress?.completedCount
            const assessmentsCount = meta?.progress?.assessmentsCount
            if (completedCount === undefined || assessmentsCount === undefined || completedCount === 0) return 0
            return Math.floor(completedCount / assessmentsCount * 100)
        },
        [meta],
    )

    return (
        <Authenticated service="cpa" accessLevel="r">
            <Stack flex={1} spacing={2}>
                <Stack spacing={1}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                            Прогресс оценки
                        </Typography>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_COMPLETED} {meta.progress?.completedCount || 0}
                            /
                            {meta.progress?.assessmentsCount || meta.employeesCount}
                        </Typography>
                    </Box>
                    <GFProgressBar value={currentProgress}/>
                </Stack>
                <Authenticated service="cpa" accessLevel="wx">
                    <CPAMetaProgressButton/>
                </Authenticated>
            </Stack>
        </Authenticated>
    )
}
