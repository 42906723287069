import React, { FC } from 'react'
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_CPA_CRITERIA } from 'helpers/enums/titles'
import { CloseRounded } from '@mui/icons-material'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import {
    CPACriteriaDrawerContent,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/CPACriteriaDrawerContent'
import { useFormCpaCriteria } from 'api/queries/form/formQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'

type CPACriteriaDrawerProps = {
    open: boolean;
    handleClose: () => void;
}

export const CPACriteriaDrawer: FC<CPACriteriaDrawerProps> = ({ open, handleClose }) => {
    const { data, isLoading, error } = useFormCpaCriteria(open)

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 620 },
                },
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box display="flex" flexDirection="column" px={5} pb={3} pt={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {TITLE_NAMESPACE_CPA_CRITERIA}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>
                    </Box>
                    <Stack spacing={1}>
                        <GFAlertInfo
                            text="Выберите на что оценивать сотрудников. Можно на специализацию или отдельные компетенции."
                        />
                    </Stack>
                </Box>
                <Box display="flex" flexDirection="column" flex={1} overflow="auto" px={5} pb={5}>
                    <>
                        {isLoading && <LoadingComponent/>}
                        {error && !isLoading && <ErrorPage error={error}/>}
                        {!error && !isLoading && <CPACriteriaDrawerContent handleClose={handleClose} items={data}/>}
                    </>
                </Box>
            </Box>
        </Drawer>
    )
}
