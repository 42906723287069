import axios, { AxiosStatic } from 'axios'
import { ExamListService } from './examListService'
import { ExamItemService } from './examItemService'

interface IExamService {
    listService: () => ExamListService;
    itemService: () => ExamItemService;
}

export class ExamService implements IExamService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public listService(): ExamListService {
        return new ExamListService(this.axiosInstance)
    }

    public itemService(): ExamItemService {
        return new ExamItemService(this.axiosInstance)
    }
}
