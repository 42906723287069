import React, { FC, useMemo } from 'react'
import {
    TITLE_NAMESPACE_DURATION,
    TITLE_NAMESPACE_ENDED_TO,
    TITLE_NAMESPACE_MINUTES_SHORT,
} from 'helpers/enums/titles'
import { Chip, Divider, Stack, Typography } from '@mui/material'
import { AccessTimeRounded, DateRangeRounded, SaveRounded } from '@mui/icons-material'
import { HINT_CHIP_DATA_SAVED_AUTOMATICALLY } from 'helpers/enums/hints'
import { getAssessmentTimeToProcess } from 'helpers/assessment/getAssessmentTimeToProcess'
import { useAppSelector } from 'store/hooks/redux'
import {
    CPAEmployeeDrawerPreviewAssessmentCriteria,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentHeader/components/CPAEmployeeDrawerPreviewAssessmentCriteria/CPAEmployeeDrawerPreviewAssessmentCriteria'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'

type CPAEmployeeDrawerPreviewAssessmentHeaderProps = {
    employee: ICpaItemDivisionEmployee | ICpaEmployee;
    indicatorsCount: number;
}

export const CPAEmployeeDrawerPreviewAssessmentHeader: FC<CPAEmployeeDrawerPreviewAssessmentHeaderProps> = ({
    employee,
    indicatorsCount,
}) => {
    const { endedAt, typeUuid } = useAppSelector(state => state.CPAItemReducer.meta)
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const minutes = useMemo(() => getAssessmentTimeToProcess(indicatorsCount), [indicatorsCount])

    return (
        <Stack spacing={4}>
            <Stack spacing={1}>
                <Stack spacing={1} direction="row" alignItems="center">
                    <ChipAssessmentType cpaType={typeUuid}/>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <DateRangeRounded color="warning"/>
                        <Typography variant="body1">
                            {`${TITLE_NAMESPACE_ENDED_TO} ${endedAt}`}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h3">
                    {`${employee?.lastName} ${employee?.firstName} ${employee?.secondName}`}
                </Typography>
                <CPAEmployeeDrawerPreviewAssessmentCriteria
                    specialization={employee.specialization}
                    grade={employee.grade}
                    criteriaUuid={currentCriteriaUuid}
                />
            </Stack>
            <Divider/>
            <Stack spacing={1} alignItems="flex-start">
                <Chip
                    icon={<AccessTimeRounded/>}
                    label={`${TITLE_NAMESPACE_DURATION} ${minutes} ${TITLE_NAMESPACE_MINUTES_SHORT}`}
                    sx={{
                        fontSize: 16,
                        lineHeight: '19px',
                        fontWeight: 500,
                        color: ({ palette }) => palette.text.secondary,
                        background: ({ palette }) => palette.background.default,
                        '& .MuiChip-icon': {
                            marginLeft: 1.5,
                        },
                    }}
                />
                <Chip
                    icon={<SaveRounded/>}
                    label={HINT_CHIP_DATA_SAVED_AUTOMATICALLY}
                    sx={{
                        fontSize: 16,
                        lineHeight: '19px',
                        fontWeight: 500,
                        color: ({ palette }) => palette.text.secondary,
                        background: ({ palette }) => palette.background.default,
                        '& .MuiChip-icon': {
                            marginLeft: 1.5,
                        },
                    }}
                />
            </Stack>
        </Stack>
    )
}
