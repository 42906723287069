import React from 'react'
import { Chip, Snackbar } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { TITLE_NAMESPACE_ASSESSMENT_COMPLETE } from 'helpers/enums/titles'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

export const OAAssessmentProgress = () => {
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const answers = useAppSelector(state => state.oaAssessmentAnswersReducer.data)
    const chipLabel = `${TITLE_NAMESPACE_ASSESSMENT_COMPLETE} ${answers.length}/${assessment?.indicatorsCount}`
    const assessmentStatusComplete = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'

    if (assessmentStatusComplete) return null

    return (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ zIndex: 1199 }}>
            <Chip color="secondary" label={chipLabel}/>
        </Snackbar>
    )
}
