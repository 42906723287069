import React, { FC, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    FormHelperText,
    IconButton,
    InputLabel,
    ListSubheader,
    Popover,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    TITLE_BUTTON_ADD,
    TITLE_BUTTON_CANCEL,
    TITLE_DIALOG_SPECIALIZATION_ADD,
    TITLE_NAMESPACE_DIVISION,
    TITLE_NAMESPACE_SPECIALIZATION,
    TITLE_NOT_FOUND,
} from 'helpers/enums/titles'
import { useNotify } from 'store/hooks/useNotify'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { HINT_CHOOSE, HINT_ERROR_REQUIRED, HINT_SUCCESS_CREATE_SPECIALIZATION } from 'helpers/enums/hints'
import { specializationListActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ChevronRightRounded, MoreHorizRounded } from '@mui/icons-material'
import { SendMetric } from 'utils/sendMetric'

type AddNewSpecializationDialogProps = {
    handleClose: () => void;
    open: boolean;
}
type FormValues<T> = {
    value: T;
    error: boolean | null;
    hint: string;
}

export const AddNewSpecializationDialog: FC<AddNewSpecializationDialogProps> = ({ open, handleClose }) => {
    const notify = useNotify()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { createOneMeta, list } = useAppSelector(state => state.specializationListReducer.data)
    const [specialization, setSpecialization] = useState<FormValues<string>>({
        value: '',
        error: null,
        hint: HINT_ERROR_REQUIRED,
    })
    const [divisions, setDivisions] = useState<FormValues<ISpecializationMetaForCreateDivision[]>>({
        value: [],
        error: null,
        hint: HINT_ERROR_REQUIRED,
    })
    const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLButtonElement | null>(null)
    const selectedSpecialization = useMemo(() =>
        createOneMeta?.specializations?.find(el => el.uuid === specialization.value), [specialization.value])

    const includedDivisions = useMemo(() => list?.map(division => {
        if (division?.specializations?.some(spec => spec.uuid === specialization.value))
            return division.uuid
        return ''
    }).filter(Boolean), [list, specialization.value])

    const includedDivisionsMeta = useMemo(
        () => createOneMeta?.divisions?.filter(division => includedDivisions.includes(division.uuid)),
        [createOneMeta, includedDivisions])

    React.useEffect(() => {
        setDivisions({
            ...divisions,
            value: includedDivisionsMeta,
        })
    }, [includedDivisionsMeta])


    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElPopover(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorElPopover(null)
    }

    const openPopover = Boolean(anchorElPopover)
    const id = openPopover ? 'simple-popover' : undefined


    const changeSpecialization = (value: string) => {
        setSpecialization({
            ...specialization,
            value,
            error: null,
        })
    }
    const changeDivision = (value: ISpecializationMetaForCreateDivision[]) => {
        setDivisions({
            ...divisions,
            value,
            error: null,
        })
    }

    const checkFormValues = () => {
        let hasError = false
        if (!specialization.value.length) {
            hasError = true
            setSpecialization({
                ...specialization,
                error: true,
            })
        }
        if (!divisions.value.length) {
            hasError = true
            setDivisions({
                ...divisions,
                error: true,
            })
        }
        return hasError
    }

    const handleSubmit = async () => {
        if (checkFormValues()) return

        try {
            await dispatch(specializationListActions.createOne({
                specializationUuid: specialization.value,
                divisionsUuid: divisions.value.map((el) => el.uuid),
            }, createOneMeta))
            SendMetric.getInstance().sendMetricClick('addNewSpecialization')
            await handleClose()
            notify(HINT_SUCCESS_CREATE_SPECIALIZATION, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!createOneMeta) return null

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth
            sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_SPECIALIZATION_ADD}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <Box mb={2.5} display="flex" justifyContent="space-between">
                        <Box flex={1}>
                            <SelectForm
                                id="specialization"
                                title={TITLE_NAMESPACE_SPECIALIZATION}
                                required
                                fullWidth
                                placeholder={HINT_CHOOSE}
                                value={specialization.value}
                                error={!!specialization.error}
                                helperText={specialization.error ? specialization.hint : null}
                                onChange={({ target: { value } }) => changeSpecialization(value as string)}
                                values={createOneMeta.specializations}
                            />
                        </Box>
                        {selectedSpecialization?.description ?
                            <IconButton
                                aria-describedby={id}
                                color="primary"
                                sx={{ mt: 3, alignSelf: 'center' }}
                                onClick={handleClickPopover}
                            >
                                <InfoOutlinedIcon
                                    color="primary"
                                />
                            </IconButton> : null}
                        <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorElPopover}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    maxWidth: '400px',
                                    borderRadius: '16px',
                                    p: 2.5,
                                },
                            }}
                        >
                            <Typography variant="caption">
                                {selectedSpecialization?.description}
                            </Typography>
                        </Popover>
                    </Box>
                    <InputLabel
                        htmlFor="divisions"
                        sx={{
                            mb: 0.5,
                        }}
                    >
                        {TITLE_NAMESPACE_DIVISION} *
                    </InputLabel>
                    <Autocomplete
                        options={createOneMeta.divisions || []}
                        disableCloseOnSelect
                        multiple
                        limitTags={1}
                        value={divisions.value}
                        noOptionsText={TITLE_NOT_FOUND}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                {option.title}
                            </li>
                        )}
                        groupBy={option => option.parentDivisionTitle}
                        renderGroup={({ group, key, children }) => {
                            const currentDivision =
                                createOneMeta?.divisions?.find(el => el.parentDivisionTitle === group)
                            return (
                                <div key={key}>
                                    <ListSubheader sx={{ position: 'sticky', top: '-8px' }}>
                                        <Tooltip
                                            title={currentDivision?.fullPath || ''}
                                            placement="bottom-start"
                                            PopperProps={{
                                                sx: {
                                                    '& .MuiTooltip-tooltip': {
                                                        // TODO: Need fix. Need important to override custom slyles
                                                        margin: '-4px !important',
                                                    },
                                                },
                                            }}
                                        >
                                            <Box display="flex" alignItems="center">
                                                <MoreHorizRounded color="disabled" fontSize="small" />
                                                <ChevronRightRounded color="disabled" fontSize="small"
                                                    sx={{ mr: 0.5 }} />
                                                {group}
                                            </Box>
                                        </Tooltip>
                                    </ListSubheader>
                                    {children}
                                </div>
                            )
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                // has kays in getTagProps
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                    label={option.title}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        onChange={(event, value) => changeDivision(value)}
                        renderInput={(params) => (
                            <TextField{...params} placeholder={HINT_CHOOSE} />
                        )}
                    />
                    {divisions.error && <FormHelperText>
                        <span
                            style={{
                                color: theme.palette.error.main,
                            }}
                        >
                            {divisions.hint}
                        </span>
                    </FormHelperText>}
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button variant="contained" onClick={handleSubmit}>{TITLE_BUTTON_ADD}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
