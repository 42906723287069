import React, { FC } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { CloseRounded, CommentOutlined } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'

type EmployeeAssessmentCommentProps = {
    comment: IAssessmentComment;
    deleteComment?: (uuid: string) => void;
}

export const EmployeeAssessmentComment: FC<EmployeeAssessmentCommentProps> = ({
    comment,
    deleteComment,
}) => {
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    const onDeleteComment = () => {
        if (!deleteComment) return
        deleteComment(comment.uuid)
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <CommentOutlined color="action"/>
            <Box flex={1}>
                <Typography variant="body2">
                    {comment.text}
                </Typography>
            </Box>
            {assessmentSetup?.summarizingStatus && assessmentSetup?.viewerIsCurrentReviewer && <IconButton color="primary" onClick={onDeleteComment}>
                <CloseRounded/>
            </IconButton>}
        </Stack>
    )
}
