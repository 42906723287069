import React, { FC } from 'react'
import { RadarSliceTooltipProps, ResponsiveRadar } from '@nivo/radar'
import { titlesLegendsNivo } from 'helpers/enums/titlesLegendsNivo'
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

type GraphicSpiderProps = {
    data: IGraphicData[];
    keys: string[],
    indexBy: string;
    maxValue?: number;
}

const LabelComponent = (props: any) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))

    const { id } = props
    const maxWordLength = isMobile ? 13 : 50
    const title = isMobile ? /^\d+\. /gm.exec(id)?.[0].replace(/\D/, '') ?? id : id
    const text = id.length > maxWordLength
        ? title.replace(/^(\d+\.\s)/gm, '').substring(0, maxWordLength - 3).concat('...')
        : title
    const words = text.split(' ')
    const lines = []
    for (let i = 0; i < words.length; i += 2) {
        const row = [words[i], words[i + 1]].filter(Boolean).join(' ')
        if (row.length > 20) {
            lines.push(words[i])
            lines.push(words[i + 1])
        } else {
            lines.push(row)
        }
    }
    // lines = [id.match(/^(\d+\.\s)/gm)]

    return (
        <g>
            <text {...props} textAnchor={props.anchor} style={{
                fontFamily: 'Roboto',
                fontSize: 14,
                lineHeight: '16px',
                fontWeight: 400,
                letterSpacing: '0.4px',
                color: theme.palette.text.secondary,
            }}>
                {lines.map((word, idx) => (
                    <tspan
                        x={props.x}
                        y={isMobile ? props.y : props.y + idx * 15 - 15}
                        key={`${word}-${idx}`}>{word} </tspan>
                ))}
            </text>
        </g>
    )
}


const Component: FC<GraphicSpiderProps> = ({ data, keys, indexBy, ...props }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const margin = {
        top: isMobile ? 20 : 80,
        bottom: 110,
        right: isMobile ? 10 : 100,
        left: isMobile ? 10 : 100,
    }

    return (
        <ResponsiveRadar
            data={data}
            keys={keys}
            indexBy={indexBy}
            valueFormat=">-.2f"
            margin={margin}
            borderColor={{ from: 'color' }}
            gridShape="linear"
            gridLabel={LabelComponent}
            gridLabelOffset={isMobile ? 10 : 35}
            gridLevels={4}
            dotSize={7}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            // colors={{ scheme: 'nivo' }}
            colors={(barData) => data[0][`${barData.key}-color`] as string}
            blendMode="multiply"
            motionConfig="wobbly"
            sliceTooltip={({ index, data }: RadarSliceTooltipProps) => {
                const chips = React.useMemo(
                    () => data.map(datum => ({
                        chip: (<Box key={datum.id} sx={{
                            backgroundColor: datum.color,
                            width: 10,
                            height: 10,
                            mx: 1,
                        }}/>),
                        id: datum.id,
                        value: datum.value,
                    })).sort((a, b) => a.id > b.id ? 1 : -1),
                    [data],
                )
                return (
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="body2" fontWeight={500} mb={2}>
                            {index}
                        </Typography>
                        {chips.map(el => (
                            <Box key={el.id} display="flex" alignItems="center" mb={1} justifyContent="space-between">
                                <Box display="flex" mr={2}>
                                    {el.chip}
                                    <Typography variant="caption">
                                        {titlesLegendsNivo.radar[el.id] ?? el.id}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption">
                                        {el.value !== undefined ? `${el.value}%` : 'Нет данных'}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Paper>
                )
            }}
            legends={[
                {
                    data: keys.map(key => ({ id: key, label: titlesLegendsNivo.radar[key] ?? key })),
                    anchor: 'bottom',
                    direction: isMobile ? 'column' : 'row',
                    translateX: isMobile ? -40 : -160,
                    translateY: isMobile ? -36 : -80,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemTextColor: theme.palette.text.primary,
                    symbolSize: 16,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                            },
                        },
                    ],
                },
            ]}
            {...props}
        />
    )
}

export const GraphicSpider = React.memo(Component)
