import { AppDispatch } from 'store/store'
import { SpecializationService } from 'api/services/SpecializationService'
import { competenceSlice } from 'store/reducers/CompetenceSlice'
import {
    ISpecializationCompetenceChangeOrderMaterialRequest, SpecializationCompetenceIncludedUuids,
    SpecializationCompetenceMaterialCreateRequest,
    SpecializationCompetenceMaterialUpdateRequest,
} from 'api/typing/specializationTypes'


export class CompetenceAction {
    private static get specializationApi() {
        return new SpecializationService().competenceService()
    }

    public getCompetence(specializationUuid: string, competenceUuid: string, entityType: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                const data = await CompetenceAction.specializationApi.getSpecializationCompetence(
                    specializationUuid,
                    competenceUuid,
                    entityType,
                )
                dispatch(competenceSlice.actions.getCompetence(data))
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createMaterial(payload: SpecializationCompetenceMaterialCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                const data = await CompetenceAction.specializationApi.specializationCompetenceMaterialCreate(payload)
                dispatch(competenceSlice.actions.createMaterial({ ...payload, ...data }))
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateMaterial(payload: SpecializationCompetenceMaterialUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                void await CompetenceAction.specializationApi.specializationCompetenceMaterialUpdate(payload)
                dispatch(competenceSlice.actions.updateMaterial(payload))
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteMaterial(payload: {
        uuid: string,
        entityType: 'custom' | 'origin',
    } & SpecializationCompetenceIncludedUuids) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                void await CompetenceAction.specializationApi.specializationCompetenceMaterialDelete(payload)
                dispatch(competenceSlice.actions.deleteMaterial(payload))
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeOrderMaterial(payload: ISpecializationCompetenceChangeOrderMaterialRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                // don't need change state because is state change in component
                void await CompetenceAction.specializationApi.specializationCompetenceMaterialChangeOrder(payload)
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createChain(payload: ISpecializationCompetenceChangeOrderMaterialRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(competenceSlice.actions.fetching())
                void await CompetenceAction.specializationApi.specializationCompetenceCreateChain(payload)
                dispatch(competenceSlice.actions.createChain(payload))
            } catch (e: any) {
                dispatch(competenceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
