import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    getFlatListCompetence,
    getResultSides,
    IAssessmentResultSetup,
    prepareGraphicBar,
    prepareResultState,
} from 'helpers/assessment/resultStateFunction'
import { IAssessmentChangeStatusRequest } from 'api/typing/assessmentServiceTypes'


interface IAssessmentState {
    result: IAssessmentResult | null;
    competenciesFlatList: IAssessmentCompetenceGroupCompetence[];
    accordance: IAssessmentAccordance[] | null;
    accordanceGraphic: {
        data: Record<string, string | number>[];
        keys: string[];
    } | null;
    sides: {
        strong: { score: number; title: string }[];
        weak: { score: number; title: string }[];
    } | null;
    resultSetup: IAssessmentResultSetup | null;
}

const initialState: IState<IAssessmentState> = {
    data: {
        result: null,
        competenciesFlatList: [],
        sides: null,
        resultSetup: null,
        accordance: null,
        accordanceGraphic: null,
    },
    isLoading: false,
    error: null,
}

export const assessmentResultSlice = createSlice({
    name: 'assessmentResult',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setResult: (state, action: PayloadAction<IAssessmentResult & { viewerUuid: string }>) => {
            state.data.result = action.payload
            state.data.competenciesFlatList = getFlatListCompetence(action.payload)
            state.data.resultSetup = prepareResultState(state.data.result, action.payload.viewerUuid)
            state.data.accordance = null
            state.data.accordanceGraphic = null
            state.data.sides = getResultSides(action.payload.groupCompetence)
        },
        setResultAccordance: (state, action: PayloadAction<IAssessmentAccordance[]>) => {
            state.data.accordance = action.payload
            state.data.accordanceGraphic = prepareGraphicBar(action.payload)
        },
        setApproveResults: (state, action: PayloadAction<IAssessmentChangeStatusRequest & { finishedAt?: string }>) => {
            const { finishedAt, statusUuid } = action.payload

            if (!state.data.result) return

            state.data.result.statusUuid = statusUuid
            state.data.result.finishedAt = finishedAt ?? state.data.result.finishedAt

            if (!state.data.resultSetup) return

            state.data.resultSetup = prepareResultState(state.data.result, state.data.resultSetup.viewerUuid)
        },
    },
})

export const assessmentResultReducer = assessmentResultSlice.reducer
