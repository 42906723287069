import React, { FC } from 'react'
import { IEmployeeEditEducationCourseCreateOrUpdate } from 'api/typing/employeeTypes'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Stack } from '@mui/material'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { HINT_CHOOSE } from 'helpers/enums/hints'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_DIRECTION,
    TITLE_FIELD_EDUCATIONAL_INSTITUTION,
    TITLE_FIELD_YEAR_GRADUATION,
} from 'helpers/enums/titles'
import { InputForm } from 'components/form/InputForm/InputForm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { getYearsValuesArray } from 'helpers/dateAndTime/getYearsValuesArray'

type FormValues = Omit<IEmployeeEditEducationCourseCreateOrUpdate, 'uuid'>

type CourseItemFormProps = {
    itemData?: IEmployeeEditEducationCourse
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeEditEducationCourseCreateOrUpdate) => void;
}

const isEqualObjectsCourse = (
    item: IEmployeeEditEducationCourse,
    formData: FormValues,
) => {
    const equalEducationalInstitution = item?.educationalInstitution === formData.educationalInstitution
    const equalDirection = item?.direction === formData.direction
    const equalPosition = item?.yearGraduation === formData.yearGraduation

    return equalEducationalInstitution && equalDirection && equalPosition
}

export const CourseItemForm: FC<CourseItemFormProps> = ({
    itemData,
    onSubmitForm,
    onCancelForm,
}) => {
    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            direction: itemData?.direction ?? '',
            educationalInstitution: itemData?.educationalInstitution ?? '',
            yearGraduation: itemData?.yearGraduation ?? '',
        },
    })

    const yearsValuesArray = getYearsValuesArray()

    const onSubmit = (data: FormValues) => {
        if (itemData && isEqualObjectsCourse(itemData, data)) return
        const payload: IEmployeeEditEducationCourseCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="direction"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.course.direction,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="direction"
                            name="direction"
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .course
                                    .direction
                                    .maxLength
                                    .value,
                            }}
                            required
                            title={TITLE_FIELD_DIRECTION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.direction}
                            helperText={errors?.direction ? errors.direction.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="educationalInstitution"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.course.educationalInstitution,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="educationalInstitution"
                            name="educationalInstitution"
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .course
                                    .educationalInstitution
                                    .maxLength
                                    .value,
                            }}
                            required
                            title={TITLE_FIELD_EDUCATIONAL_INSTITUTION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.educationalInstitution}
                            helperText={errors?.educationalInstitution ? errors.educationalInstitution.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="yearGraduation"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.course.yearGraduation,
                    }}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="yearGraduation"
                            name="yearGraduation"
                            required
                            values={yearsValuesArray}
                            title={TITLE_FIELD_YEAR_GRADUATION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.yearGraduation}
                            helperText={errors?.yearGraduation ? errors.yearGraduation.message : null}
                        />
                    )}
                />
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={() => onCancelForm()}>
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button type="submit" variant="contained">
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}

