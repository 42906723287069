import { createTheme, experimental_sx as sx, ThemeOptions } from '@mui/material'

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

const { breakpoints } = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
})

export const mainThemeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
    palette: {
        text: {
            primary: '#222222',
            secondary: '#667275',
            disabled: '#899395',
        },
        primary: {
            main: '#0989A5',
            light: '#84C4D2',
            dark: '#087B95',
            contrastText: '#FFFFFF',
            A100: '#84FFFF',
            A200: '#18FFFF',
            A400: '#00E5FF',
            A700: '#00B8D4',
            '50': '#E2F7FF',
            '100': '#B7ECFE',
            '200': '#88E0FD',
            '300': '#58D3F9',
            '400': '#39CAF4',
            '500': '#2CC1EE',
            '600': '#25B1DA',
            '700': '#179CBE',
            '800': '#0988A5',
            '900': '#006678',
        },
        secondary: {
            main: '#E76B18',
            dark: '#D06016',
            light: '#F3B58B',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#DA8300',
            dark: '#C47600',
            light: '#ECC180',
            contrastText: '#FFFFFF',
            '50': '#FAF1DF',
        },
        success: {
            main: '#1F9C5C',
            dark: '#1C8C53',
            light: '#8FCDAD',
            contrastText: '#FFFFFF',
            '50': '#E5F5EB',
        },
        error: {
            main: '#D32F2F',
            dark: '#C62828',
            contrastText: '#FFFFFF',
            '50': '#FFEBEE',
        },
        background: {
            default: '#F8F8F8',
            paper: '#FFFFFF',
        },
        common: {
            white: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Roboto',
        allVariants: {
            color: '#222222',
            letterSpacing: '0.15px',
            overflow: 'hidden',
            // textOverflow: 'ellipsis',
            fontStyle: 'normal',
            whiteSpace: 'pre-wrap',
        },
        h1: {
            fontSize: 36,
            fontWeight: 300,
            lineHeight: '42px',
            letterSpacing: '0.01em',

            [breakpoints.only('mobile')]: {
                fontSize: 28,
                lineHeight: '32px',
            },
        },
        h2: {
            fontSize: 24,
            fontWeight: 300,
            lineHeight: '28px',
            letterSpacing: '0.01em',
            color: '#000000',

            [breakpoints.only('mobile')]: {
                fontSize: 24,
                lineHeight: '28px',
            },
        },
        h3: {
            fontSize: 22,
            fontWeight: 400,
            lineHeight: '26px',

            [breakpoints.only('mobile')]: {
                fontSize: 20,
                lineHeight: '23px',
            },
        },
        body1: {
            fontSize: 16,
            lineHeight: '19px',
            color: '#667275',

            [breakpoints.only('mobile')]: {
                fontSize: 16,
            },
        },
        body2: {
            fontSize: 16,
            lineHeight: '19px',

            [breakpoints.only('mobile')]: {
                fontSize: 16,
            },
        },
        h5: {
            fontWeight: 700,
            fontSize: 32,
            lineHeight: '36px',
        },
        subtitle1: {
            fontSize: 20,
            lineHeight: '23px',
            letterSpacing: '0.1px',
            fontWeight: 500,

            [breakpoints.only('mobile')]: {
                fontSize: 18,
                lineHeight: '21px',
            },
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '23px',
            color: '#667275',

            [breakpoints.only('mobile')]: {
                fontSize: 18,
                lineHeight: '21px',
            },
        },
        caption: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 400,
            letterSpacing: '0.4px',

            [breakpoints.only('mobile')]: {
                fontSize: 12,
                lineHeight: '14px',
            },
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: '0px 5px 6px 1px rgba(63, 86, 91, 0.05), 0px 8px 56px 2px rgba(63, 86, 91, 0.08)',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                multiple: {
                    padding: '8.5px 12px',
                },
                outlined: {
                    padding: '8.5px 12px',
                },
            },
            defaultProps: {
                MenuProps: {
                    // TODO: need fix, it doesnt work
                    PaperProps: {
                        sx: {
                            maxHeight: 600,
                            borderRadius: '16px',
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    maxHeight: 600,
                    borderRadius: '16px',
                },
                inputRoot: {
                    // minHeight: 45,
                    padding: '8.5px 12px',
                    '& .MuiOutlinedInput-input ': {
                        padding: 0,
                        width: 10,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
                multiline: {
                    padding: 0,
                    minHeight: 80,
                },
                input: {
                    padding: '8.5px 12px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
        // TODO: maybe delete "MuiTextField" because has styles in MuiOutlinedInput
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-input': {
                        padding: '8.5px 12px',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    letterSpacing: '-0.02em',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '8px 20px',
                    fontStyle: 'normal',
                    overflow: 'inherit',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '24px',
                    textTransform: 'uppercase',
                    borderRadius: '16px',
                    alignSelf: 'flex-start',
                    [breakpoints.only('mobile')]: {
                        alignSelf: 'stretch',
                        flex: 1,
                    },
                },
                sizeSmall: {
                    fontSize: 13,
                    lineHeight: '22px',
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                anchorOriginTopCenter: {
                    [breakpoints.up('tablet')]: {
                        top: 48,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
                standardInfo: {
                    backgroundColor: 'rgba(9, 137, 165, 0.05)',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: sx({
                    px: 3,
                    '&.Mui-selected': {
                        backgroundColor: '#fff',
                    },
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperFullScreen: {
                    borderRadius: 0,
                },
                paper: {
                    borderRadius: 16,
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: '1px solid #E2E7E9',
                    borderRadius: '16px',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    // border: '1px solid #E2E7E9',
                    borderCollapse: 'unset',
                    // borderRadius: '16px',
                    // TODO: need comment to create sticky header
                    // overflow: 'hidden',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderRadius: '16px 0',
                    color: '#667375',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td': {
                        borderBottom: 0,
                    },
                },
                head: {
                    borderRadius: '16px',
                    backgroundColor: '#F8F8F8',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: '#667375',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '19px',
                    letterSpacing: '0.25px',
                    padding: 16,
                },
                root: {
                    verticalAlign: 'middle',
                },
            },
        },
    },
}

export const mainTheme = createTheme(mainThemeOptions)
