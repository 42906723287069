import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<IEmployeeTabCompetencies> = {
    data: {} as IEmployeeTabCompetencies,
    isLoading: false,
    error: null,
}

export const employeeViewCompetenciesSlice = createSlice({
    name: 'employeeViewCompetencies',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getCompetencies: (state, action: PayloadAction<IEmployeeTabCompetencies>) => {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployeeTabCompetencies
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeViewCompetenciesReducer = employeeViewCompetenciesSlice.reducer
