import { emptyUuid } from '../isEmptyUuid'
import {
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_COMPLETED,
    TITLE_NAMESPACE_IN_PROGRESS,
    TITLE_NAMESPACE_READY_TO_START,
    TITLE_NAMESPACE_READY_TO_START_NEUTER,
    TITLE_NAMESPACE_WAS_CREATED,
    TITLE_NAMESPACE_WAS_CREATED_NEUTER,
} from '../enums/titles'

import React from 'react'
import { PlayArrowRounded, PlaylistAddCheckRounded, SummarizeRounded } from '@mui/icons-material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'

export enum MassExamStatusCode {
    CREATED = 'created',
    READY_TO_START = 'ready_to_start',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export const massExamStatusCreated = emptyUuid // 'Создан', 'new'
export const massExamStatusReady = '7d17d0d9-e2e9-47cc-be29-95dd19929e6f' // 'Готов к запуску', 'ready_to_start'
export const massExamStatusProgress = 'cb4dbeef-9f7e-4c07-8f56-dda1184ec8fe' // 'В процессе', 'in_progress'
export const massExamStatusComplete = '29766118-ec40-443d-8016-7a8080faa615' // 'Завершена', 'done'

export type MassExamStatuses =
    typeof massExamStatusCreated
    | typeof massExamStatusReady
    | typeof massExamStatusProgress
    | typeof massExamStatusComplete

export interface IMassExamStatusChip extends IStatusChip {
    uuid: MassExamStatuses;
    code: MassExamStatusCode;
}

export const massExamStatusCreatedEntity = {
    uuid: massExamStatusCreated,
    title: TITLE_NAMESPACE_WAS_CREATED,
}
export const massExamStatusReadyEntity = {
    uuid: massExamStatusReady,
    title: TITLE_NAMESPACE_READY_TO_START,
}
export const massExamStatusProgressEntity = {
    uuid: massExamStatusProgress,
    title: TITLE_NAMESPACE_IN_PROGRESS,
}

export const massExamStatusCompleteEntity = {
    uuid: massExamStatusComplete,
    title: TITLE_NAMESPACE_COMPLETE,
}

export const massExamStatusesEntity = [
    massExamStatusCreatedEntity,
    massExamStatusReadyEntity,
    massExamStatusProgressEntity,
    massExamStatusCompleteEntity,
]

export const getMassExamStatusCode = (statusUuid: string): MassExamStatusCode | null => {
    switch (statusUuid) {
        case massExamStatusCreated:
            return MassExamStatusCode.CREATED
        case massExamStatusReady:
            return MassExamStatusCode.READY_TO_START
        case massExamStatusProgress:
            return MassExamStatusCode.IN_PROGRESS
        case massExamStatusComplete:
            return MassExamStatusCode.DONE
        default:
            return null
    }
}

const massExamStatusChips: Record<string, IMassExamStatusChip> = {
    [massExamStatusCreated]: {
        uuid: massExamStatusCreated,
        color: 'default',
        variant: 'filled',
        code: MassExamStatusCode.CREATED,
        icon: React.createElement(SummarizeRounded),
        title: TITLE_NAMESPACE_WAS_CREATED_NEUTER,
    },
    [massExamStatusReady]: {
        uuid: massExamStatusReady,
        color: 'primary',
        variant: 'outlined',
        code: MassExamStatusCode.READY_TO_START,
        icon: React.createElement(CheckCircleOutlineRoundedIcon),
        title: TITLE_NAMESPACE_READY_TO_START_NEUTER,
    },
    [massExamStatusProgress]: {
        uuid: massExamStatusProgress,
        color: 'primary',
        variant: 'outlined',
        code: MassExamStatusCode.IN_PROGRESS,
        icon: React.createElement(PlayArrowRounded),
        title: TITLE_NAMESPACE_IN_PROGRESS,
    },
    [massExamStatusComplete]: {
        uuid: massExamStatusComplete,
        color: 'success',
        variant: 'filled',
        code: MassExamStatusCode.DONE,
        icon: React.createElement(PlaylistAddCheckRounded),
        title: TITLE_NAMESPACE_COMPLETED,
    },
}

export const getMassExamStatusChip = (status?: string) => status && massExamStatusChips[status]
    ? massExamStatusChips[status]
    : massExamStatusChips[massExamStatusCreated]
