import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { TITLE_BUTTON_INSTRUCTION } from 'helpers/enums/titles'
import { Button } from '@mui/material'
import {
    DialogOAAssessmentInstruction,
} from 'components/dialogs/DialogOAAssessmentInstruction/DialogOAAssessmentInstruction'
import { useLocalStorage } from 'react-use'

export const OAAssessmentInstruction = () => {
    const [open, setOpen] = useLocalStorage('viewInstruction', true)
    return (
        <div>
            <DialogOAAssessmentInstruction
                handleClose={() => setOpen(false)}
                open={!!open}
            />
            <Button
                startIcon={<InfoOutlinedIcon/>}
                onClick={() => setOpen(true)}
            >
                {TITLE_BUTTON_INSTRUCTION}
            </Button>
        </div>
    )
}
