import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { ArrowForwardRounded } from '@mui/icons-material'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { TITLE_NAMESPACE_ALL_GRADES } from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'

type CPAEmployeeDrawerPreviewAssessmentCriteriaPointProps = {
    specialization: IEntity | null | undefined;
    grade: IEntity | null | undefined;
}

export const CPAEmployeeDrawerPreviewAssessmentCriteriaPoint:
    FC<CPAEmployeeDrawerPreviewAssessmentCriteriaPointProps> = ({
        specialization,
        grade,
    }) => {
        const pointCriteria = useAppSelector(state => state.CPAItemCriteriaReducer.pointCriteria)
        const specializationTitle = pointCriteria?.specialization?.title
        const gradeTitle = pointCriteria?.grade?.uuid && isEmptyUuid(pointCriteria?.grade?.uuid)
            ? TITLE_NAMESPACE_ALL_GRADES
            : pointCriteria?.grade?.title
        const pointPosition = `${specializationTitle} - ${gradeTitle}`

        return (
            <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body1">
                    {specialization?.title} - {grade?.title}
                </Typography>
                <ArrowForwardRounded color="warning"/>
                <PointPositionSpecializationGrade
                    title={pointPosition}
                />
            </Stack>
        )
    }
