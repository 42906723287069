import React, { FC, useRef, useState } from 'react'
import {
    IEmployeeWorkExperiencePreviousJobCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Stack } from '@mui/material'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { HINT_CHOOSE } from 'helpers/enums/hints'
import {
    TITLE_BUTTON_CANCEL, TITLE_BUTTON_SAVE, TITLE_FIELD_COMPANY,
    TITLE_FIELD_POSITION,
} from 'helpers/enums/titles'
import { RangeBetweenTwoDates } from 'components/RangeBetweenTwoDates/RangeBetweenTwoDates'
import { InputForm } from 'components/form/InputForm/InputForm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { format, parse } from 'date-fns'

type FormValues = Omit<IEmployeeWorkExperiencePreviousJobCreateOrUpdate, 'endDate' | 'startDate' | 'uuid'>

type PreviousJobsItemFormProps = {
    itemData?: IEmployeeWorkExperiencePreviousJobItem;
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) => void;
}

const isEqualObjectsPreviousJobs = (
    item: IEmployeeWorkExperiencePreviousJobItem,
    formData: FormValues & { dateStart: DateState, dateEnd: DateState },
) => {
    const endDateFormatted = formData.dateEnd?.value ? format(formData.dateEnd.value, 'dd.MM.yyyy') : ''
    const startDateFormatted = formData.dateStart?.value ? format(formData.dateStart.value, 'dd.MM.yyyy') : ''

    const equalCompany = item?.companyTitle === formData.companyTitle
    const equalPosition = item?.positionTitle === formData.positionTitle

    const equalStartDate = item.startDate === startDateFormatted
    const equalEndDate = item.endDate === endDateFormatted

    return equalCompany && equalPosition && equalStartDate && equalEndDate
}

export const PreviousJobsItemForm: FC<PreviousJobsItemFormProps> = ({ itemData, onSubmitForm, onCancelForm }) => {
    const datesFields = useRef<any>(null)

    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            companyTitle: itemData?.companyTitle ?? '',
            positionTitle: itemData?.positionTitle ?? '',
        },
    })

    const [dateStart, setDateStart] = useState<DateState>({
        value: itemData?.startDate
            ? parse(itemData.startDate, 'dd.MM.yyyy', new Date())
            : null,
        error: null,
    })

    const [dateEnd, setDateEnd] = useState<DateState>({
        value: itemData?.endDate
            ? parse(itemData.endDate, 'dd.MM.yyyy', new Date())
            : null,
        error: null,
    })

    const onSubmit = (data: FormValues) => {
        if (dateEnd.error
            || dateStart.error
            || !dateStart.value
            || !dateEnd.value
            || itemData && isEqualObjectsPreviousJobs(itemData, { ...data, dateStart, dateEnd })
        ) return
        const payload: IEmployeeWorkExperiencePreviousJobCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
            startDate: format(dateStart.value, 'dd.MM.yyyy'),
            endDate: format(dateEnd.value, 'dd.MM.yyyy'),
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="companyTitle"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.workExperience.previousCompany,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="companyTitle"
                            name="companyTitle"
                            required
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .workExperience
                                    .previousCompany
                                    .maxLength
                                    .value,
                            }}
                            title={TITLE_FIELD_COMPANY}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.companyTitle}
                            helperText={errors?.companyTitle ? errors.companyTitle.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="positionTitle"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.workExperience.previousPosition,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="positionTitle"
                            name="positionTitle"
                            required
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .workExperience
                                    .previousPosition
                                    .maxLength
                                    .value,
                            }}
                            title={TITLE_FIELD_POSITION}
                            placeholder={HINT_CHOOSE}
                            error={!!errors.positionTitle}
                            helperText={errors?.positionTitle ? errors.positionTitle.message : null}
                        />
                    )}
                />
            </Box>
            <RangeBetweenTwoDates
                ref={datesFields}
                requiredDateEnd
                requiredDateStart
                endDate={dateEnd}
                startDate={dateStart}
                maxDateStart={new Date()}
                setEndDate={setDateEnd}
                setStartDate={setDateStart}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    onClick={() => onCancelForm()}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    type="submit"
                    onClick={() => datesFields?.current?.checkDates()}
                    variant="contained"
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}

