import { TITLE_NAMESPACE_CPA_SCALE_NUMERIC, TITLE_NAMESPACE_CPA_SCALE_RANGE } from 'helpers/enums/titles'

export const cpaScaleNumericUuid = '4ec07204-21c0-4bd8-92f5-01f6254249d3' // ,Числовая,numeric

export const cpaScaleRangeUuid = '008f407f-7276-410e-b958-eaaa404be8c5' // ,Диапазон,range

export const cpaScaleUuidsEntity = [
    {
        uuid: cpaScaleNumericUuid,
        title: TITLE_NAMESPACE_CPA_SCALE_NUMERIC,
    },
    {
        uuid: cpaScaleRangeUuid,
        title: TITLE_NAMESPACE_CPA_SCALE_RANGE,
    },
]

interface ICPAScale extends IEntity {
    code: 'numeric' | 'range',
}

export const getCpaScale = (scaleUuid: string): ICPAScale | null => {
    switch (scaleUuid) {
        case cpaScaleNumericUuid: {
            return {
                uuid: cpaScaleNumericUuid,
                code: 'numeric',
                title: TITLE_NAMESPACE_CPA_SCALE_NUMERIC,
            }
        }
        case cpaScaleRangeUuid: {
            return {
                uuid: cpaScaleRangeUuid,
                code: 'range',
                title: TITLE_NAMESPACE_CPA_SCALE_RANGE,
            }
        }
        default: {
            return null
        }
    }
}
