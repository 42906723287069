import React, { FC } from 'react'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { HighlightAltRounded, InfoOutlined } from '@mui/icons-material'
import { TITLE_BUTTON_ADD } from 'helpers/enums/titles'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

type EmptyPageProps = {
    contentText: string;
    buttonTitle?: string;
    variant?: 'attention' | 'info';
    onButtonClick?: () => void;
    startIcon?: React.ReactNode;
    service?: PermissionServiceCode,
    disableButton?: boolean;
    accessLevel?: AccessLevel;
    divisions?: string[];
    children?: React.ReactElement | null;
}

export const EmptyPage: FC<EmptyPageProps> = ({
    contentText,
    buttonTitle,
    onButtonClick,
    startIcon,
    service,
    accessLevel,
    disableButton,
    divisions,
    children,
    variant = 'info',
}) => {

    const Icon = variant === 'info'
        ? <HighlightAltRounded
            sx={{
                width: 100,
                height: 100,
                mb: 3,
            }}
            fontSize="large"
            color="primary"
        />
        : <InfoOutlined
            sx={{
                width: 100,
                height: 100,
                mb: 3,
            }}
            fontSize="large"
            color="warning"
        />

    return (
        <Box width="100%" height="100%" display="flex" flex="1" justifyContent="center" alignItems="center">
            <Card sx={{ maxWidth: 500, width: '100%', minWidth: { 'mobile': 200, 'tablet': 300 } }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {Icon}
                    <Typography variant="body2" mb={3} align="center">
                        {contentText}
                    </Typography>
                    <Authenticated service={service} accessLevel={accessLevel} divisions={divisions}>
                        {onButtonClick && <Button
                            onClick={onButtonClick}
                            disabled={disableButton}
                            variant="contained"
                            startIcon={startIcon}
                            sx={{
                                alignSelf: { 'mobile': 'stretch', 'tablet': 'center' },
                            }}
                        >
                            {buttonTitle ?? TITLE_BUTTON_ADD}
                        </Button>}
                    </Authenticated>
                    {children}
                </CardContent>
            </Card>
        </Box>
    )
}
