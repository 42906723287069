import { Box, Chip, Stack, Typography, useTheme } from '@mui/material'
import { TITLE_NAMESPACE_GRADE, TITLE_NAMESPACE_SPECIALIZATION } from 'helpers/enums/titles'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import {
    CPAEmployeesAssessment,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesAssessment/CPAEmployeesAssessment'
import React, { FC, useMemo } from 'react'
import { useAppSelector } from 'store/hooks/redux'

type CPAAnalyticContentEmployeesDrawerItemEmployeeProps = {
    employee: TAnalyticCpaStatisticSummaryEmployee;
}

export const CPAAnalyticContentEmployeesDrawerItemEmployee: FC<CPAAnalyticContentEmployeesDrawerItemEmployeeProps> = ({
    employee,
}) => {
    const theme = useTheme()
    const assessedEmployee = useAppSelector(state => state
        .CPAItemAnalyticsReducer
        .summary
        .employeesFlatForSummary[employee.uuid])
    const hasNotSpecialization = useMemo(
        () => !assessedEmployee?.specialization?.uuid || isEmptyUuid(assessedEmployee?.specialization?.uuid),
        [assessedEmployee?.specialization],
    )
    const hasNotGrade = useMemo(
        () => !assessedEmployee?.grade?.uuid || isEmptyUuid(assessedEmployee?.grade?.uuid),
        [assessedEmployee?.grade],
    )
    const specializationTitle = assessedEmployee?.specialization?.title && !hasNotSpecialization
        ? assessedEmployee?.specialization.title
        : TITLE_NAMESPACE_SPECIALIZATION
    const gradeTitle = assessedEmployee?.grade?.title && !hasNotGrade
        ? assessedEmployee?.grade.title
        : TITLE_NAMESPACE_GRADE

    if (!assessedEmployee) return null

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack spacing={0.5}>
                <Typography variant="body2">
                    {assessedEmployee.fullName}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                        variant="body1"
                        sx={{ color: hasNotSpecialization ? theme.palette.secondary.main : '' }}
                    >
                        {specializationTitle}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: hasNotSpecialization || hasNotGrade ? theme.palette.secondary.main : '',
                        }}
                    >
                        -
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: hasNotGrade ? theme.palette.secondary.main : '' }}
                    >
                        {gradeTitle}
                    </Typography>
                </Stack>
                <Typography variant="body1">
                    {assessedEmployee.divisionTitle}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
                <Chip
                    label={`${employee.score}%`}
                    color={employee.score > 75 ? 'success' : 'warning'}
                />
                <CPAEmployeesAssessment employeeUuid={employee.uuid}/>
            </Stack>
        </Box>
    )
}
