import React from 'react'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import { notifierActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { Box, Button, IconButton, Typography } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { TITLE_BUTTON_MORE } from 'helpers/enums/titles'

interface ReportCompleteProps {
    id: SnackbarKey;
    message: SnackbarMessage;
    link?: string;
}

export const ReportDefault = React.forwardRef<HTMLDivElement, ReportCompleteProps>(function ReportComplete(
    props,
    ref,
) {
    const { id, message, link, ...other } = props
    const dispatch = useAppDispatch()
    const notifyClose = (key?: SnackbarKey) => dispatch(notifierActions.notifyClose(key))
    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    px: 2,
                    py: 0.5,
                    borderRadius: 4,
                    width: '100%',
                    maxWidth: 530,
                    backgroundColor: (theme) => theme.palette.primary.main,
                }}
            >
                <CheckCircleOutlineRoundedIcon
                    sx={{
                        color:'inherit',
                        marginRight: 1,
                    }}
                />
                <Typography
                    sx={{
                        color: 'inherit',
                        marginRight: 1,
                        my: 1,
                        flex: 1,
                    }}
                >
                    {message}
                </Typography>
                {link && <Button href={link} sx={{ color: 'inherit' }}>
                    {TITLE_BUTTON_MORE}
                </Button>}
                <IconButton sx={{ color:'inherit' }} onClick={() => notifyClose(id)}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
        </SnackbarContent>
    )
})
