import React, { FC, useState } from 'react'
import { Box, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ClearRounded, EditRounded } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { useParams } from 'react-router-dom'
import { employeeActions } from 'store/actions'
import { IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate } from 'api/typing/employeeTypes'
import { formatTimeToStringDuration } from 'helpers/dateAndTime/formatTimeToStringDuration'
import {
    EditProjectsAndRolesItemForm,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabWorkInCompany/components/EditProjectsAndRolesCard/components/EditProjectsAndRolesItemForm/EditProjectsAndRolesItemForm'
import { format, parse } from 'date-fns'
import { HINT_RECORD_SUCCESS_DELETE, HINT_RECORD_SUCCESS_EDIT } from 'helpers/enums/hints'
import { emptyUuid } from 'helpers/isEmptyUuid'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'

type EditProjectsAndRolesItemProps = {
    projectAndRole: IEmployeeWorkInCompanyProjectsAndRoles;
    isCurrentPosition?: boolean;
    pageViewEmployee?: boolean;
}

export const EditProjectsAndRolesItem: FC<EditProjectsAndRolesItemProps> = ({
    projectAndRole, isCurrentPosition,
    pageViewEmployee,
}) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { employeeUuid } = useParams()
    const [edit, setEdit] = useState(false)
    const showChip = projectAndRole?.startDate

    const dateEndOnProject = isValidDateFromApi(projectAndRole?.endDate)
        ? projectAndRole?.endDate
        : format(new Date(), 'dd.MM.yyyy')

    const handleEdit = () => {
        setEdit(true)
    }

    const handleDelete = async () => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.deleteEmployeeWorkInCompanyProjectsAndRoles(
                employeeUuid,
                projectAndRole.uuid,
            ))
            notify(HINT_RECORD_SUCCESS_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    const editComplete = async (payload: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate) => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.updateEmployeeWorkInCompanyProjectsAndRoles(employeeUuid, {
                ...payload,
                roleUuid: payload.roleUuid.length > 0 ? payload.roleUuid : emptyUuid,
                projectUuid: payload.projectUuid.length > 0 ? payload.projectUuid : emptyUuid,
                employeeUuid,
            }))
            setEdit(false)
            notify(HINT_RECORD_SUCCESS_EDIT, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const hasRole = !!projectAndRole?.role?.title
    const hasProject = !!projectAndRole?.project?.title

    return (
        <>
            {edit
                ? <EditProjectsAndRolesItemForm
                    onCancelForm={() => setEdit(false)}
                    onSubmitForm={editComplete}
                    itemData={projectAndRole}
                />
                : <>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Stack spacing={0.5} overflow="hidden">
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2" mr={1}>
                                    {projectAndRole?.project?.title}
                                    {hasProject && hasRole && ' - '}
                                    {projectAndRole?.role?.title}
                                </Typography>
                                {showChip && <Chip
                                    label={formatTimeToStringDuration(
                                        parse(projectAndRole?.startDate, 'dd.MM.yyyy', new Date()),
                                        parse(dateEndOnProject, 'dd.MM.yyyy', new Date()),
                                    )}
                                    size="small"
                                    color={isCurrentPosition ? 'secondary' : 'default'}
                                />}
                            </Box>
                            <Typography variant="body1">
                                {projectAndRole?.startDate} - {projectAndRole?.endDate ? projectAndRole?.endDate : 'н.в'}
                            </Typography>
                        </Stack>
                        {!pageViewEmployee && <Box display="flex" flexWrap="nowrap" alignSelf="center">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditRounded />
                            </IconButton>
                            <IconButton color="primary" onClick={handleDelete}>
                                <ClearRounded />
                            </IconButton>
                        </Box>}
                    </Box>
                    {!pageViewEmployee && <Divider sx={{ mt: 2 }} />}
                </>}
        </>
    )
}

