import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useNavigate } from 'react-router-dom'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { PATH_DEVELOPMENT, PATH_EMPLOYEES, PATH_HISTORY } from 'helpers/enums/routePath'
import { myAssessmentActions } from 'store/actions'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CONFIRM,
    TITLE_BUTTON_GO_TO_PDP,
    TITLE_BUTTON_MAKE_PDP_FORM_ASSESSMENT,
    TITLE_DIALOG_CONFIRM_ACTION,
} from 'helpers/enums/titles'
import { Button } from '@mui/material'
import { FormatListBulletedRounded } from '@mui/icons-material'

export const AssessmentResultPdpButton = () => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const navigate = useNavigate()
    const { dialogConfirm, toggleDialog } = useDialogsState({ dialogConfirm: false })
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const pdpStatus = getPdpStatus({ title: '', uuid: result?.pdp?.status || '' })
    const pdpStatusComplete = pdpStatus?.status === 'completed'
    const pdpStatusCancel = pdpStatus?.status === 'canceled'

    const navigatePathHistoryPdp = resultSetup?.viewerIsEvaluated
        ? `${PATH_HISTORY}/pdp/${result?.pdp?.uuid}`
        : `${PATH_EMPLOYEES}/${result?.evaluated.uuid}/history/pdp/${result?.pdp?.uuid}`

    const navigatePathPdp = resultSetup?.viewerIsEvaluated
        ? `${PATH_DEVELOPMENT}/pdp/${result?.pdp?.uuid}`
        : `${PATH_EMPLOYEES}/${result?.evaluated.uuid}/pdp/${result?.pdp?.uuid}`

    const actionWithPdp = async () => {
        if (!result) return
        switch (true) {
            case resultSetup?.hasCurrentPdp: {
                navigate(pdpStatusComplete || pdpStatusCancel ? navigatePathHistoryPdp : navigatePathPdp)
                break
            }
            case resultSetup?.canCreatePdp: {
                try {
                    const pdpUuid = await dispatch(myAssessmentActions.assessmentCreatePdp(result?.uuid))
                    navigate(resultSetup?.viewerIsEvaluated
                        ? `${PATH_DEVELOPMENT}/pdp/${pdpUuid}`
                        : `${PATH_EMPLOYEES}/${result?.evaluated.uuid}/pdp/${pdpUuid}`,
                    )
                } catch (e: any) {
                    notify(e.userMessage, 'error')
                }
                break
            }
            default: {
                break
            }
        }
    }

    if (!resultSetup?.completeStatus || !resultSetup?.canCreatePdp) return null

    return (
        <Authenticated
            service={resultSetup.viewerIsEvaluated ? 'my_development' : 'pdp'}
            accessLevel={resultSetup?.hasCurrentPdp ? 'r' : 'w'}
        >
            <>
                <DialogConfirm
                    open={dialogConfirm}
                    dialogTitle={TITLE_DIALOG_CONFIRM_ACTION}
                    dialogText="На основании данной оценки будет сформирован план развития сотрудника"
                    titleButtonConfirm={TITLE_BUTTON_CONFIRM}
                    titleButtonCancel={TITLE_BUTTON_CANCEL}
                    onSubmit={actionWithPdp}
                    handleClose={() => toggleDialog('dialogConfirm', false)}
                />
                <Button
                    color="primary"
                    variant="contained"
                    disabled={resultSetup?.hasAnyPdp && !resultSetup?.hasCurrentPdp
                        || !resultSetup?.canCreatePdp && !resultSetup?.hasCurrentPdp}
                    startIcon={<FormatListBulletedRounded/>}
                    onClick={resultSetup?.hasCurrentPdp ? actionWithPdp : () => toggleDialog('dialogConfirm', true)}
                >
                    {resultSetup?.hasCurrentPdp ? TITLE_BUTTON_GO_TO_PDP : TITLE_BUTTON_MAKE_PDP_FORM_ASSESSMENT}
                </Button>
            </>
        </Authenticated>
    )
}
