import React, { FC, useState } from 'react'
import { Box, Chip, Divider, IconButton, ListItem, Popover, Stack, Tooltip, Typography } from '@mui/material'
import { CommentOutlined, ErrorOutlineRounded, MoreHorizRounded, WarningAmberRounded } from '@mui/icons-material'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DrawerCommentsAssessment } from 'components/drawers/DrawerCommentsAssessment/DrawerCommentsAssessment'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    TITLE_NAMESPACE_RESULTS_FOR_LEVELS,
    TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL,
} from 'helpers/enums/titles'
import { useAppSelector } from 'store/hooks/redux'

type AssessmentResultTreeCompetenceProps = {
    element: IAssessmentCompetenceGroupCompetence;
}
export const AssessmentResultTreeCompetence: FC<AssessmentResultTreeCompetenceProps> = ({ element }) => {
    const accordance = useAppSelector(state => state.assessmentResultReducer.data.accordance)
    const { dialogComments, toggleDialog } = useDialogsState({ dialogComments: false })
    const indicatorsComments = element.indicators.filter(el => el.comments.length > 0)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [open, setOpen] = useState(false)
    const timerId = React.useRef<null | NodeJS.Timeout>(null)
    const showWarningIcon = !element.isUncompleted && element.indicators.some(el => {
        if (el.needAction) return true
        return el.answers.some(answer => answer.role?.divergent)
    })
    const isUnCompleted = !element.scores.some(el => el.userUuid === 'final' || el.roleUuid === 'final')
    // const isUnCompleted = element.indicators.length / 2
    //     <= element.indicators.filter(el => el.finalAnswerUuid === 'cb56d054-d771-40a0-b09b-032acdc3f586').length

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        timerId.current = setTimeout(() => {
            setOpen(true)
        }, 500)
    }

    const closePopover = () => {
        if (timerId.current) clearTimeout(timerId.current)
        setOpen(false)
        setAnchorEl(null)
    }

    const popoverId = `popover-tree-competence-${element.uuid}}`

    return (
        <ListItem disablePadding sx={{ borderRadius: 4 }}>
            <DrawerCommentsAssessment
                open={dialogComments}
                handleClose={() => toggleDialog('dialogComments', false)}
                skillLevelTitle={element.skillLevelTitle}
                competenceTypeTitle={element.typeTitle}
                competenceTitle={element.title}
                indicators={indicatorsComments}
            />
            <Stack
                direction="row"
                spacing={2}
                flex={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ pl: 2, pr: 3, borderRadius: '12px' }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography mr={0.5} mb={0.5} variant="body2">
                            {element.title}
                        </Typography>
                        <Typography variant="body1">
                            {element.typeTitle}
                        </Typography>
                    </Box>
                    {isUnCompleted && <Tooltip title="Недостаточно данных для объективной оценки">
                        <ErrorOutlineRounded color="error"/>
                    </Tooltip>}
                    {showWarningIcon && <Tooltip title="Оценки участников сильно расходятся">
                        <WarningAmberRounded color="warning"/>
                    </Tooltip>}
                    {indicatorsComments?.length ? <IconButton
                        color="primary"
                        size="small"
                        onClick={() => toggleDialog('dialogComments', true)}
                        sx={{ alignSelf: 'center' }}
                    >
                        <CommentOutlined/>
                    </IconButton> : null}
                </Stack>
                <Stack spacing={3} direction="row" alignItems="center" alignSelf="center">
                    {element.skillLevels.length > 0 && <Box
                        aria-owns={open ? popoverId : undefined}
                        aria-haspopup="true"
                        onMouseEnter={openPopover}
                        onMouseLeave={closePopover}
                    >
                        <MoreHorizRounded color="primary"/>
                    </Box>}
                    {!accordance && <ChipSkillLevel skillLevelTitle={element.skillLevelTitle}/>}
                    <Chip
                        sx={{ minWidth: 56, marginLeft: 3 }}
                        label={`${element?.score}%`}
                        color={element?.score > 75 && !element?.isUncompleted ? 'default' : 'warning'}
                    />
                </Stack>
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    sx={{
                        pointerEvents: 'none',
                        '& Mui-Paper': {
                            pointerEvents: 'auto',
                        },
                    }}
                    disableRestoreFocus
                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                    PaperProps={{ style: { borderRadius: '16px' } }}
                >
                    <Box p={2} maxWidth={377}>
                        <Stack spacing={2} mb={4}>
                            <Typography variant="body2">
                                {TITLE_NAMESPACE_RESULTS_FOR_LEVELS}
                            </Typography>
                            <Stack spacing={2} divider={<Divider/>}>
                                {element.skillLevels.map(el => <Stack
                                    key={el.uuid}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <ChipSkillLevel skillLevelTitle={el.title}/>
                                    <Chip
                                        sx={{ minWidth: 56, marginLeft: 3 }}
                                        label={`${el?.score}%`}
                                        color={el?.score > 75 ? 'default' : 'warning'}
                                    />
                                </Stack>)}
                            </Stack>
                            <Divider/>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="body2">
                                {TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL}
                            </Typography>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <ChipSkillLevel skillLevelTitle={element.skillLevelTitle}/>
                                <Chip
                                    sx={{ minWidth: 56, marginLeft: 3 }}
                                    label={`${element?.score}%`}
                                    color={element?.score > 75 ? 'default' : 'warning'}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </Popover>
            </Stack>
        </ListItem>
    )
}
