import React, { JSXElementConstructor } from 'react'
import {
    TITLE_ALERT_INFO_EXPIRED,
    TITLE_ALERT_INFO_ON_APPROVAL,
    TITLE_ALERT_INFO_ON_PROGRESS,
    TITLE_ALERT_INFO_READY_TO_STUDY, TITLE_ALERT_INFO_RETURNED,
    TITLE_ALERT_INFO_REVIEW, TITLE_ALERT_SEND_TO_APPROVAL,
    TITLE_BUTTON_SEND_TO_APPROVAL,
    TITLE_BUTTON_SEND_TO_EMPLOYEE,
    TITLE_NAMESPACE_CANCELED,
    TITLE_NAMESPACE_PDP_COMPLETED,
    TITLE_NAMESPACE_PDP_EXPIRED,
    TITLE_NAMESPACE_PDP_ON_APPROVAL,
    TITLE_NAMESPACE_PDP_ON_PROGRESS,
    TITLE_NAMESPACE_PDP_READY_TO_STUDY,
    TITLE_NAMESPACE_PDP_RETURNED,
    TITLE_NAMESPACE_PDP_REVIEW,
} from 'helpers/enums/titles'
import {
    FactCheckRounded,
    PeopleAltRounded,
    PersonRounded,
    PlaylistAddCheckRounded,
    PlaylistRemoveRounded,
    SummarizeRounded,
    TimelapseRounded,
} from '@mui/icons-material'
import {
    HINT_PDP_ALERT_DEVELOPMENT_APPROVAL,
    HINT_PDP_ALERT_DEVELOPMENT_EXPIRED,
    HINT_PDP_ALERT_DEVELOPMENT_GO_REVIEW_WHEN_READY,
    HINT_PDP_ALERT_DEVELOPMENT_ON_STUDY,
    HINT_PDP_ALERT_DEVELOPMENT_READY_TO_STUDY,
    HINT_PDP_ALERT_DEVELOPMENT_REVIEW,
    HINT_PDP_ALERT_DEVELOPMENT_ROLLBACK,
    HINT_PDP_ALERT_EMPLOYEE_COMPLETED,
    HINT_PDP_ALERT_EMPLOYEE_EXPIRED,
    HINT_PDP_ALERT_EMPLOYEE_ON_STUDY,
    HINT_PDP_ALERT_EMPLOYEE_ROLLBACK,
    HINT_PDP_ALERT_EMPLOYEE_WAS_APPROVAL,
    HINT_PDP_ALERT_EMPLOYEE_WAS_CREATED_FROM_SPECIALIZATION,
    HINT_PDP_ALERT_EMPLOYEE_WAS_SENT,
} from 'helpers/enums/hints'
import { emptyUuid } from 'helpers/isEmptyUuid'

export const pdpStatusCreated = emptyUuid // создан,  default
export const pdpStatusApproval = '0e463555-0144-4be7-9d77-dbbf31a9d576' // На согласовании,       agreement
export const pdpStatusSent = '47dfd434-cf83-45b7-9044-95f4ca3dfab3' // Отправлен сотруднику,  send
export const pdpStatusInProgress = '840f8d3a-f9f5-4734-994a-6d5d776f14f0' // На изучении,           on_study
export const pdpStatusExpired = '3722ca0c-0da4-4529-b1de-12fbba412607' // Не завершен в срок,    expired
export const pdpStatusReview = '023a095b-3afe-4490-806b-81428e3e1d00' // На проверке,           review
export const pdpStatusComplete = '8a5331a7-afcb-4fed-aa52-be5cf30ee430' // Изучен,                studied
export const pdpStatusCanceled = '967801be-ac1a-4ac5-a0a8-26e7902b1436' // Отменен,               cancel
export const pdpStatusReturned = 'd13011ee-6cd8-478a-ae9b-7d13cdcb2353' // Возвращён на изучение, returned

export interface IPdpStatus {
    title: string;
    alertTextTitle: string;
    alertText: string;
    status: 'default' | 'expired' | 'sent' | 'completed' | 'inProgress' | 'canceled' | 'review' | 'approval' | 'returned';
    color: 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info' | 'default';
    chipColor: 'default' | 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info';
    disableChangeItemStatus: boolean;
    chipVariant: 'outlined' | 'filled';
    chipIcon?: React.ReactElement<any, string | JSXElementConstructor<any>>;
    isClosed?: boolean;
}

export const getPdpStatus = (status: IEntity | null, employeePage = true): IPdpStatus => {

    switch (status?.uuid) {
        case pdpStatusSent: {
            return {
                title: TITLE_NAMESPACE_PDP_READY_TO_STUDY,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_WAS_SENT : HINT_PDP_ALERT_DEVELOPMENT_READY_TO_STUDY,
                alertTextTitle: TITLE_ALERT_INFO_READY_TO_STUDY,
                status: 'sent',
                chipVariant: 'outlined',
                disableChangeItemStatus: false,
                color: 'warning',
                chipColor: 'warning',
                chipIcon: React.createElement(SummarizeRounded),
                isClosed: true,
            }
        }
        case pdpStatusInProgress: {
            return {
                title: TITLE_NAMESPACE_PDP_ON_PROGRESS,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_ON_STUDY : HINT_PDP_ALERT_DEVELOPMENT_ON_STUDY,
                alertTextTitle: TITLE_ALERT_INFO_ON_PROGRESS,
                status: 'inProgress',
                color: 'primary',
                disableChangeItemStatus: false,
                chipVariant: 'outlined',
                chipColor: 'primary',
                chipIcon: React.createElement(PersonRounded),
            }
        }
        case pdpStatusExpired: {
            return {
                title: TITLE_NAMESPACE_PDP_EXPIRED,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_EXPIRED : HINT_PDP_ALERT_DEVELOPMENT_EXPIRED,
                alertTextTitle: TITLE_ALERT_INFO_EXPIRED,
                status: 'expired',
                color: 'warning',
                disableChangeItemStatus: true,
                chipVariant: 'filled',
                chipColor: 'warning',
                chipIcon: React.createElement(TimelapseRounded),
                isClosed: true,
            }
        }
        case pdpStatusReview: {
            return {
                title: TITLE_NAMESPACE_PDP_REVIEW,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_COMPLETED : HINT_PDP_ALERT_DEVELOPMENT_REVIEW,
                alertTextTitle: TITLE_ALERT_INFO_REVIEW,
                status: 'review',
                chipVariant: 'outlined',
                disableChangeItemStatus: true,
                color: 'warning',
                chipColor: 'warning',
                chipIcon: React.createElement(FactCheckRounded),
            }
        }
        case pdpStatusComplete: {
            return {
                title: TITLE_NAMESPACE_PDP_COMPLETED,
                alertText: '',
                alertTextTitle: '',
                status: 'completed',
                color: 'success',
                disableChangeItemStatus: true,
                chipVariant: 'filled',
                chipColor: 'success',
                chipIcon: React.createElement(PlaylistAddCheckRounded),
            }
        }
        case pdpStatusCanceled: {
            return {
                title: TITLE_NAMESPACE_CANCELED,
                alertText: '',
                alertTextTitle: '',
                status: 'canceled',
                disableChangeItemStatus: true,
                chipVariant: 'filled',
                color: 'error',
                chipColor: 'error',
                chipIcon: React.createElement(PlaylistRemoveRounded),
            }
        }
        case pdpStatusApproval: {
            return {
                title: TITLE_NAMESPACE_PDP_ON_APPROVAL,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_WAS_APPROVAL : HINT_PDP_ALERT_DEVELOPMENT_APPROVAL,
                alertTextTitle: TITLE_ALERT_INFO_ON_APPROVAL,
                status: 'approval',
                chipVariant: 'outlined',
                disableChangeItemStatus: true,
                color: 'warning',
                chipColor: 'warning',
                chipIcon: React.createElement(PeopleAltRounded),
            }
        }
        case pdpStatusReturned: {
            return {
                title: TITLE_NAMESPACE_PDP_RETURNED,
                alertText: employeePage ? HINT_PDP_ALERT_EMPLOYEE_ROLLBACK : HINT_PDP_ALERT_DEVELOPMENT_ROLLBACK,
                alertTextTitle: TITLE_ALERT_INFO_RETURNED,
                status: 'returned',
                chipVariant: 'outlined',
                disableChangeItemStatus: false,
                color: 'warning',
                chipColor: 'warning',
                chipIcon: React.createElement(SummarizeRounded),
            }
        }
        default: {
            return {
                title: employeePage ? TITLE_BUTTON_SEND_TO_EMPLOYEE : TITLE_BUTTON_SEND_TO_APPROVAL,
                alertText: employeePage
                    ? HINT_PDP_ALERT_EMPLOYEE_WAS_CREATED_FROM_SPECIALIZATION
                    : HINT_PDP_ALERT_DEVELOPMENT_GO_REVIEW_WHEN_READY,
                alertTextTitle: employeePage ? '' : TITLE_ALERT_SEND_TO_APPROVAL,
                status: 'default',
                chipVariant: 'outlined',
                disableChangeItemStatus: true,
                color: 'primary',
                chipColor: 'primary',
            }
        }
    }
}
