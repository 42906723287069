import React, { cloneElement, FC } from 'react'
import { useAuthentication } from 'store/hooks/authentication'

type AuthenticatedProps = {
    service?: PermissionServiceCode;
    accessLevel?: AccessLevel;
    divisions?: string[];
    children?: React.ReactElement<any> | false,
    // Заглушка для компонента если требуется
    stubElement?: React.ReactElement<any>,
}

export const Authenticated: FC<AuthenticatedProps> = ({
    children,
    service,
    accessLevel,
    divisions,
    stubElement,
    ...rest
}) => {
    // В случае если не указан сервис или уровень, проверка не производится
    if (!service || !accessLevel) return children ? cloneElement(children, rest) : null

    // Вызов функции проверки прав доступа по сервису, уровню и подразделениям
    const accessGranted = useAuthentication({ service, accessLevel, divisions })

    // Если права доступа есть, возвращаем компонент, если нет, то заглушку(если передали) или ничего
    return accessGranted && children ? cloneElement(children, rest) : stubElement ?? null
}
