export const PATH_ANALYTICS = '/analytics'
export const PATH_SPECIALIZATION = '/specialization'
export const PATH_EMPLOYEES = '/employee'
export const PATH_ERROR = '/error'
export const PATH_ASSESSMENT = '/assessment'
export const PATH_PROFILE = '/profile'
export const PATH_HISTORY = '/history'
export const PATH_DEVELOPMENT = '/development'
export const PATH_HOME = '/'
export const PATH_LOGIN = '/login'
export const PATH_OPEN_ACCESS = '/oa'
export const PATH_MASS_EXAM = '/mass-exam'
export const PATH_EXAM = '/exam'
/*
 * @description the variable of comprehensive performance assessment
 * */
export const PATH_CPA = '/cpa'
export const PATH_CPA_ANALYTICS = 'analytics'

export const PATH_GRADE = 'grade'

export const PATH_EMPLOYEES_EDIT = 'edit'

export const PATH_DEVELOPMENT_PLAN = 'development-plan'
