import { ExamService } from 'api/services/exam'
import { AppDispatch } from '../../store'
import { examListSlice } from '../../reducers/exam/examListSlice'
import { IExamListItem } from 'api/typing/exam'

export class ExamListAction {
    private static get examListApi() {
        return new ExamService().listService()
    }

    public getList(payload: { employeeUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(examListSlice.actions.fetching())
                const data = await ExamListAction.examListApi.getList(payload.employeeUuid)
                dispatch(examListSlice.actions.setList(data))
                dispatch(examListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(examListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setList(data: IExamListItem[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(examListSlice.actions.setList(data))
        }
    }

    public setTitleFilter(payload: string) {
        return (dispatch: AppDispatch) => {
            dispatch(examListSlice.actions.setTitleFilter(payload))
        }
    }

    public setStatusFilter(payload: IEntity[]) {
        return (dispatch: AppDispatch) => {
            dispatch(examListSlice.actions.setStatusFilter(payload))
        }
    }
}
