import React, { memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Divider, Stack, Typography } from '@mui/material'
import {
    EmployeeAssessmentIndicatorAnswer,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/components/EmployeeAssessmentIndicatorAnswer/EmployeeAssessmentIndicatorAnswer'
import {
    EmployeeAssessmentIndicatorFinalAnswer,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/components/EmployeeAssessmentIndicatorFinalAnswer/EmployeeAssessmentIndicatorFinalAnswer'
import {
    EmployeeAssessmentIndicatorDivergent,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/components/EmployeeAssessmentIndicatorDivergent/EmployeeAssessmentIndicatorDivergent'
import {
    EmployeeAssessmentIndicatorFinalComment,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/components/EmployeeAssessmentIndicatorFinalComment/EmployeeAssessmentIndicatorFinalComment'

type EmployeeAssessmentIndicatorProps = {
    indicatorUuid: string;
    competenceUuid: string;
    idx: number;
}

export const EmployeeAssessmentIndicator = memo(
    function EmployeeAssessmentIndicator({
        indicatorUuid,
        competenceUuid,
        idx,
    }: EmployeeAssessmentIndicatorProps) {
        const indicator = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators[indicatorUuid])

        if (!indicatorUuid) return null

        return (
            <Stack
                p={2}
                spacing={2}
                id={indicator.uuid}
                sx={{ borderRadius: 4 }}
            >
                <Stack direction="row" spacing={2} alignItems="flex-start">
                    <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Box>
                            <Typography variant="body2">{idx}.</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2">{indicator.title}</Typography>
                        </Box>
                    </Stack>
                </Stack>
                <EmployeeAssessmentIndicatorDivergent indicatorUuid={indicatorUuid}/>
                {indicator.answers.map(answer => <EmployeeAssessmentIndicatorAnswer
                    key={`${answer.uuid}-respondent-${answer.respondentUuid}-role-${answer?.role?.uuid}`}
                    competenceUuid={competenceUuid}
                    answer={answer}
                    indicator={indicator}
                />)}
                <Divider sx={{ maxWidth: 364 }}/>
                <EmployeeAssessmentIndicatorFinalAnswer
                    competenceUuid={competenceUuid}
                    indicator={indicator}
                />
                <EmployeeAssessmentIndicatorFinalComment
                    indicatorComments={indicator.comments}
                    indicatorUuid={indicatorUuid}
                    competenceUuid={competenceUuid}
                />
            </Stack>
        )
    },
)
