import { EmployeeEditService } from 'api/services/EmployeeService/employeeEditService'
import { EmployeeListService } from 'api/services/EmployeeService/employeeListService'
import { EmployeeViewService } from 'api/services/EmployeeService/employeeViewService'
import axios, { AxiosStatic } from 'axios'

interface IEmployeeService {
    employeeListService: () => EmployeeListService;
    employeeEditService: () => EmployeeEditService;
    employeeViewService: () => EmployeeViewService;
}

export class EmployeeService implements IEmployeeService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public employeeListService() {
        return new EmployeeListService(this.axiosInstance)
    }

    public employeeEditService() {
        return new EmployeeEditService(this.axiosInstance)
    }

    public employeeViewService() {
        return new EmployeeViewService(this.axiosInstance)
    }
}
