import { TransferMassExam } from './transferMassExam'
import { IMassExamCreateRequest, IMassExamListItem } from '../../typing/massExam'

export class MassExamListService extends TransferMassExam {
    public getList() {
        return this.request<null, IMassExamListItem[]>('GET', '/all')
    }

    public createOne(data: IMassExamCreateRequest) {
        const path = '/create/new'
        return this.request<IMassExamCreateRequest, { uuid: string }>('POST', path, { data })
    }
}
