import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IExamAnswerSaveReq,
    IExamMeta,
    IExamQuestion,
    IExamResultsResp,
} from '../../../api/typing/exam'
import { getQuestionTypeCode } from '../../../helpers/massExam/massExamConstructor'

interface IExamItemState {
    isLoading: boolean;
    error: IErrorResponse | null;
    meta: IExamMeta;
    currentQuestion: IExamQuestion | null;
    result: IExamResultsResp | null;
}

const initialState: IExamItemState = {
    isLoading: false,
    error: null,
    meta: {} as IExamMeta,
    currentQuestion: null,
    result: null,
}

export const examItemSlice = createSlice({
    name: 'examItem',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        setExamMeta: (state, action: PayloadAction<IExamMeta>) => {
            state.meta = action.payload
        },
        setCurrentQuestion: (state, action: PayloadAction<IExamQuestion>) => {
            state.currentQuestion = action.payload
        },
        setAnswersToMeta: (state, action: PayloadAction<IExamAnswerSaveReq>) => {
            state.meta.questions = state.meta.questions.map(item =>
                item.uuid === action.payload.questionUuid ? { ...item, isAnswered: true } : item)
        },
        setAnswer: (state, action: PayloadAction<IExamAnswerSaveReq>) => {
            if (!state.currentQuestion) return
            const typeCode = getQuestionTypeCode(state.currentQuestion.typeUuid)
            const newAnswers = state.currentQuestion?.answers[typeCode]?.filter(answer => ({
                ...answer,
                isAnswered: action.payload.answersUuid.includes(answer.uuid),
            }))

            state.currentQuestion =  {
                ...state.currentQuestion,
                answers: {
                    [typeCode]: newAnswers,
                },
            }
        },
        setResult: (state, action: PayloadAction<IExamResultsResp>) => {
            state.result = action.payload
        },
    },
})

export const examItemReducer = examItemSlice.reducer
