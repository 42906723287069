import React, { forwardRef } from 'react'
import {
    TITLE_DIALOG_DELETE_MATERIAL,
    TITLE_DIALOG_DELETE_MATERIAL_WITH_PDP_ITEM,
} from 'helpers/enums/titles'
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import {
    HINT_DELETE_PDP_MATERIAL,
    HINT_DELETE_PDP_MATERIAL_LAST,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
    HINT_SUCCESS_PDP_ITEM_DELETE,
    HINT_SUCCESS_PDP_MATERIAL_DELETE,
} from 'helpers/enums/hints'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { developmentPdpItemActions, pdpActions, pdpItemActions } from 'store/actions'
import { useNavigate, useParams } from 'react-router-dom'
import {
    PdpCompetenceMaterialCreateUpdate,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewCompetence/components/PdpItemViewCompetenceContent/dialogs/PdpCompetenceMaterialCreateUpdate/PdpCompetenceMaterialCreateUpdate'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { PATH_DEVELOPMENT, PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { PdpCompetenceMaterialDesktop } from '../PdpCompetenceMaterialDesktop/PdpCompetenceMaterialDesktop'
import { PdpCompetenceMaterialMobile } from '../PdpCompetenceMaterialMobile/PdpCompetenceMaterialMobile'
import { Box } from '@mui/material'

type PdpCompetenceMaterialProps = {
    material: IPdpCompetenceMaterial;
    pdpStatus: IPdpStatus;
    draggableProvided: DraggableProvided;
    snapshot: DraggableStateSnapshot
    skillLevelUuid: string;
    isDevelopment?: boolean;
    firstItem: boolean;
    lastItem: boolean;
    isControlDragging: boolean;
    lift: (value: string) => any;
}

export const PdpCompetenceMaterial =
    forwardRef<HTMLDivElement, PdpCompetenceMaterialProps>(function PdpCompetenceMaterial(inProps, ref) {
        const {
            material,
            skillLevelUuid,
            snapshot,
            pdpStatus,
            draggableProvided,
            isDevelopment,
            firstItem,
            lastItem,
            lift,
            isControlDragging,
            ...props
        } = inProps

        const desktop = useDesktop()
        const dispatch = useAppDispatch()
        const notify = useNotify()
        const navigate = useNavigate()
        const { pdpUuid, itemUuid } = useParams()
        const { employee } = useAppSelector(state => state.employeeReducer.data)
        const { item: pdpItem } = useAppSelector(state => state.pdpItemReducer.data)
        const { item: developmentItem } = useAppSelector(state => state.developmentPdpItemReducer.data)
        const {
            dialogCommentEdit,
            dialogCompetenceDelete,
            dialogItemEdit,
            dialogItemDelete,
            toggleDialog,
        } = useDialogsState({
            dialogCommentEdit: false,
            dialogItemEdit: false,
            dialogItemDelete: false,
            dialogCompetenceDelete: false,
        })
        const item = pdpItem || developmentItem
        const employeeUuid = item?.employee?.uuid
        const totalCountsMaterials = item?.skillLevels.reduce((acc, val) => acc + val.materials.length, 0)
        const isUniqueMaterial = material.entityType === 'unique'
        const pdpStatusCreated = pdpStatus.status === 'default'
        const pdpStatusReview = pdpStatus.status === 'review'
        const pdpStatusApproval = pdpStatus.status === 'approval'

        const openDeleteMaterial = () => {
            if (totalCountsMaterials === 1 && !pdpStatusCreated && !pdpStatusApproval)
                toggleDialog('dialogCompetenceDelete', true)
            else
                toggleDialog('dialogItemDelete', true)
        }
        const openEditMaterial = () => {
            toggleDialog('dialogItemEdit', true)
        }

        const deleteMaterial = async () => {
            if (!pdpUuid || !employeeUuid || !itemUuid) return
            try {
                if (isDevelopment)
                    void await dispatch(developmentPdpItemActions.deleteItemMaterial({
                        uuid: material.uuid,
                        entityType: material.entityType,
                        pdpUuid,
                        skillLevelUuid,
                        itemUuid,
                    }))
                else
                    void await dispatch(pdpItemActions.deletePdpCompetenceMaterial({
                        uuid: material.uuid,
                        entityType: material.entityType,
                        pdpUuid,
                        employeeUuid,
                        skillLevelUuid,
                        studyTime: material.studyTime,
                        itemUuid,
                    }))
                notify(HINT_SUCCESS_PDP_MATERIAL_DELETE, 'success')
                toggleDialog('dialogItemDelete', false)
            } catch (e: any) {
                notify(e.userMessage, 'error')
            }
        }

        const deleteCompetence = async () => {
            if (!employeeUuid || !pdpUuid || !itemUuid) return
            try {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.deletePdpItem({
                        pdpUuid,
                        itemUuid,
                    }))
                else
                    await dispatch(pdpActions.deleteItemPdp({
                        employeeUuid,
                        pdpUuid,
                        itemUuid,
                    }))
            } catch (e: any) {
                notify(e.userMessage, 'error')
            } finally {
                await isDevelopment
                    ? navigate(`${PATH_DEVELOPMENT}/pdp/${pdpUuid}`, { replace: true })
                    : navigate(`${PATH_EMPLOYEES}/${employeeUuid}/pdp/${pdpUuid}`, { replace: true })
                notify(HINT_SUCCESS_PDP_ITEM_DELETE, 'success')
            }
        }

        const deleteComment = async (uuid: string) => {
            if (!pdpUuid || !employeeUuid || !itemUuid) return
            try {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.deletePdpItemMaterialComment({
                        pdpUuid,
                        itemUuid,
                        skillLevelUuid,
                        uuid,
                        materialUuid: material.uuid,
                    }))
                else
                    await dispatch(pdpItemActions.deletePdpItemMaterialComment({
                        pdpUuid,
                        itemUuid,
                        employeeUuid,
                        skillLevelUuid,
                        uuid,
                        materialUuid: material.uuid,
                    }))
                notify(HINT_SUCCESS_COMMENT_DELETE, 'success')
            } catch (e: any) {
                notify(e.userMessage, 'error')
            }
        }

        const changePassMaterial = async () => {
            if (!pdpUuid || !employeeUuid || !itemUuid || !skillLevelUuid) return
            try {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.passPdpCompetenceMaterial({
                        isPassed: !material.isPassed,
                        pdpUuid,
                        itemUuid,
                        materialUuid: material.uuid,
                        skillLevelUuid,
                    }))
                else
                    await dispatch(pdpItemActions.changePassCompetenceMaterial({
                        isPassed: !material.isPassed,
                        pdpUuid,
                        employeeUuid,
                        itemUuid,
                        materialUuid: material.uuid,
                        skillLevelUuid,
                    }))
            } catch (e: any) {
                notify(e.userMessage, 'error')
            }
        }

        const createComment = async (comment: IComment) => {
            if (!pdpUuid || !employeeUuid || !itemUuid) return
            try {
                if (isDevelopment)
                    await dispatch(developmentPdpItemActions.createPdpItemMaterialComment({
                        ...comment,
                        pdpUuid,
                        skillLevelUuid,
                        itemUuid,
                        materialUuid: material.uuid,
                    }))
                else
                    await dispatch(pdpItemActions.createPdpItemMaterialComment({
                        ...comment,
                        pdpUuid,
                        employeeUuid,
                        skillLevelUuid,
                        itemUuid,
                        materialUuid: material.uuid,
                    }))
                notify(HINT_SUCCESS_COMMENT_CREATE, 'success')
            } catch (e: any) {
                notify(e.userMessage, 'error')
            }
        }

        return (
            <>
                <DialogConfirmDelete
                    open={dialogItemDelete}
                    title={TITLE_DIALOG_DELETE_MATERIAL}
                    onSubmit={deleteMaterial}
                    elementType={material.format}
                    elementTitle={material.title}
                    helperText={HINT_DELETE_PDP_MATERIAL}
                    handleClose={() => toggleDialog('dialogItemDelete', false)}
                />
                <DialogConfirmDelete
                    open={dialogCompetenceDelete}
                    title={TITLE_DIALOG_DELETE_MATERIAL_WITH_PDP_ITEM}
                    onSubmit={deleteCompetence}
                    elementType={material.format}
                    elementTitle={material.title}
                    helperText={HINT_DELETE_PDP_MATERIAL_LAST}
                    handleClose={() => toggleDialog('dialogCompetenceDelete', false)}
                />
                {pdpUuid && <PdpCompetenceMaterialCreateUpdate
                    open={dialogItemEdit}
                    pdpUuid={pdpUuid}
                    isDevelopment={isDevelopment}
                    item={item}
                    employee={item.employee}
                    material={material}
                    handleClose={() => toggleDialog('dialogItemEdit', false)}
                />}
                <DrawerComments
                    service={isDevelopment ? undefined : 'pdp'}
                    divisions={[employee?.division?.uuid]}
                    open={dialogCommentEdit}
                    entityTitle={material.format}
                    itemTitle={material.title}
                    comments={material.comments}
                    onDeleteComment={deleteComment}
                    onCreateComment={createComment}
                    handleClose={() => toggleDialog('dialogCommentEdit', false)}
                />
                <>
                    {desktop ?
                        <PdpCompetenceMaterialDesktop
                            draggableProvided={draggableProvided}
                            snapshot={snapshot}
                            material={material}
                            employee={employee}
                            pdpStatus={pdpStatus}
                            pdpStatusReview={pdpStatusReview}
                            isUniqueMaterial={isUniqueMaterial}
                            isDevelopment={isDevelopment}
                            openDeleteMaterial={openDeleteMaterial}
                            changePassMaterial={changePassMaterial}
                            openEditMaterial={openEditMaterial}
                            toggleDialog={toggleDialog}
                            item={item}
                        />
                        :
                        <Box ref={ref} {...props}>
                            <PdpCompetenceMaterialMobile
                                material={material}
                                pdpStatus={pdpStatus}
                                skillLevelUuid={skillLevelUuid}
                                toggleDialog={toggleDialog}
                                lift={lift}
                                isControlDragging={isControlDragging}
                                firstItem={firstItem}
                                lastItem={lastItem}
                                isDevelopment={isDevelopment}
                                openDeleteMaterial={openDeleteMaterial}
                                openEditMaterial={openEditMaterial}
                                isUniqueMaterial={isUniqueMaterial}
                            />
                        </Box>
                    }
                </>
            </>
        )})
