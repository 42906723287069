import { MassExamService } from 'api/services/massExamService'
import { MassExamItemEmployeesSlice } from '../../reducers/massExam/MassExamItemEmployeesSlice'
import { AppDispatch } from '../../store'
import {
    IMassExamDeleteEmployeeRequest,
    IMassExamGetEmployeeForAddResponse,
    IMassExamGetItemEmployeesResponse,
    IMassExamSaveEmployeesRequest,
} from 'api/typing/massExam'
import { massExamItemSlice } from '../../reducers/massExam/massExamItemSlice'
import { massExamItemActions } from '../index'

export class MassExamItemEmployeesAction {
    private static get massExamItemApi() {
        return new MassExamService().itemService()
    }

    public setAllEmployees(data: IMassExamGetEmployeeForAddResponse[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.setAllEmployees(data))
        }
    }

    public setExamedEmployees(data: IMassExamGetItemEmployeesResponse[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.setExamedEmployees(data))
        }
    }

    public restoreEmployeeList() {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.restoreEmployeeList())
        }
    }

    public selectAllEmployeeList() {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.selectAllEmployeeList())
        }
    }

    public removeAllEmployeeList(selectedAndBlockedEmployeeList: string[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.removeAllEmployeeList(selectedAndBlockedEmployeeList))
        }
    }

    public saveEmployeesToMassExam(data: IMassExamSaveEmployeesRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MassExamItemEmployeesSlice.actions.fetching())
                void await MassExamItemEmployeesAction.massExamItemApi.saveEmployeesToMassExam(data)
                await dispatch(massExamItemActions.getItem(data.uuid))
                dispatch(MassExamItemEmployeesSlice.actions.saveEmployeesToMassExam())
                dispatch(massExamItemSlice.actions.updateEmployeesCount({ employeesCount: data.employees.length }))
                dispatch(MassExamItemEmployeesSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MassExamItemEmployeesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setSelectStateEmployee(payload: { employeeUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.setSelectStateEmployee(payload))
        }
    }

    public setSelectStateDivision(payload: { divisionUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(MassExamItemEmployeesSlice.actions.setSelectStateDivision(payload))
        }
    }

    public deleteEmployee(data: IMassExamDeleteEmployeeRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MassExamItemEmployeesSlice.actions.fetching())
                void await MassExamItemEmployeesAction.massExamItemApi.deleteMassExamEmployee(data)
                await dispatch(massExamItemActions.getItem(data.uuid))
                dispatch(MassExamItemEmployeesSlice.actions.deleteEmployee(data))
                dispatch(MassExamItemEmployeesSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MassExamItemEmployeesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
