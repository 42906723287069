import React, { FC, ReactNode } from 'react'
import { ru } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

type LocalizationLayerProps = {
    children: ReactNode;
}

export const LocalizationLayer: FC<LocalizationLayerProps> = ({children}) => {
    return (
        <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
            {children}
        </LocalizationProvider>
    )
}
