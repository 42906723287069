const localeSum = (num: number, maximumFractionDigits = 0) => num.toLocaleString('ru-RU', {
    currency: 'RUB',
    style: 'currency',
    maximumFractionDigits,
})

export const getMoneyString = (num: number | null | undefined, maximumFractionDigits = 0) => {
    if (num === undefined || num === null)
        return localeSum(0, maximumFractionDigits)
    return localeSum(num, maximumFractionDigits)
}
