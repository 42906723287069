import React, { FC, useState } from 'react'
import { AddButton } from 'components/common/AddButton/AddButton'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CREATE,
    TITLE_DIALOG_CREATE_ASSESSMENT,
    TITLE_FIELD_ASSESSMENT_ENDED_AT,
    TITLE_FIELD_CPA_TITLE,
    TITLE_FIELD_CPA_TYPE,
} from 'helpers/enums/titles'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { HINT_CHOOSE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { InputForm } from 'components/form/InputForm/InputForm'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { DatePicker } from '@mui/x-date-pickers'
import { DateRangeRounded } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useFormCpaTypes } from 'api/queries/form/formQueries'
import { cpaItemActions } from 'store/actions'
import { format } from 'date-fns'
import { ICPACreateOneRequest } from 'api/typing/cpaTypes'
import { useNavigate } from 'react-router-dom'
import { PATH_CPA } from 'helpers/enums/routePath'

type FormValues = Omit<ICPACreateOneRequest, 'endedAt'> & {
    endedAt: Date | null;
}

type CPACreateForUnCompletedProps = {
    initialCpaUuid: string;
    cpaTitle: string;
    cpaTypeUuid: string;
    employees: string[];
}

export const CPACreateForUnCompletedDialog: FC<CPACreateForUnCompletedProps> = ({
    cpaTitle,
    cpaTypeUuid,
    initialCpaUuid,
    employees,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { data: cpaTypes, error, isFetching } = useFormCpaTypes()
    const notify = useNotify()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false)
    const [isCreating, setIsCreating] = useState(false)

    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            title: cpaTitle,
            typeUuid: cpaTypeUuid,
            endedAt: null,
        },
    })

    const onSubmit: SubmitHandler<FormValues> = async (payload) => {
        if (!payload.endedAt) return
        try {
            setIsCreating(true)
            const uuid = await dispatch(cpaItemActions.createForUnCompleted({
                initialCpaUuid,
                ...payload,
                employees,
                endedAt: format(payload.endedAt, 'dd.MM.yyyy'),
            }))
            navigate(`${PATH_CPA}/${uuid}`)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setIsCreating(false)
        }
    }

    return (
        <>
            <AddButton
                variant="contained"
                onClick={() => setOpen(true)}
            >
                сформировать новую оценку
            </AddButton>
            <Dialog
                open={open}
                fullScreen={mobile}
                onClose={() => setOpen(false)}
                fullWidth
                sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                    component="form"
                    flex={1}
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                >
                    <DialogTitle onClose={() => setOpen(false)}>
                        {TITLE_DIALOG_CREATE_ASSESSMENT}
                    </DialogTitle>
                    {isFetching && <LoadingComponent/>}
                    {error && !isFetching ? <Box overflow="auto" py={2}>
                        <ErrorPage error={error}/>
                    </Box> : null}
                    {!error && !isFetching && <DialogContent>
                        <Stack spacing={2}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.cpa.title,
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="title"
                                        title={TITLE_FIELD_CPA_TITLE}
                                        inputProps={{
                                            maxLength: fieldsRestriction.cpa.title.maxLength.value,
                                        }}
                                        required
                                        autoFocus
                                        error={!!errors.title}
                                        helperText={errors?.title ? errors.title.message : null}
                                    />
                                )}
                            />
                            <Controller
                                name="typeUuid"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="typeUuid"
                                        name="typeUuid"
                                        values={cpaTypes || []}
                                        required
                                        disabled={isFetching || !cpaTypes}
                                        title={TITLE_FIELD_CPA_TYPE}
                                        placeholder={HINT_CHOOSE}
                                        error={!!errors.typeUuid}
                                        helperText={errors?.typeUuid ? errors.typeUuid.message : null}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="endedAt"
                                rules={{ ...fieldsRestriction.cpa.endedAt }}
                                render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        inputRef={ref}
                                        inputFormat="dd.MM.yyyy"
                                        minDate={new Date()}
                                        components={{ OpenPickerIcon: DateRangeRounded }}
                                        toolbarPlaceholder={HINT_CHOOSE}
                                        renderInput={(params) => (
                                            <InputForm
                                                {...params}
                                                id="endedAt"
                                                title={TITLE_FIELD_ASSESSMENT_ENDED_AT}
                                                onBlur={onBlur}
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: HINT_CHOOSE,
                                                }}
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                    </DialogContent>}
                    <DialogActions
                        sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                        disableSpacing={mobile}
                    >
                        <Button variant="text" onClick={() => setOpen(false)}>{TITLE_BUTTON_CANCEL}</Button>
                        <Button
                            variant="contained"
                            disabled={!!error || isCreating}
                            type="submit"
                        >
                            {TITLE_BUTTON_CREATE}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}
