import React, { useState } from 'react'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import {
    TITLE_NAMESPACE_CURRENT,
    TITLE_NAMESPACE_SHARED_COMMENTS,
    TITLE_NAMESPACE_SHARED_COMMENTS_ASSESSMENT,
} from 'helpers/enums/titles'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { Button } from '@mui/material'
import { CommentOutlined } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'

export const AssessmentResultComments = () => {
    const [open, setOpen] = useState(false)
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)

    const evaluatedSpecializationUuid = result?.evaluated.currentPosition.specializationUuid
    const evaluatedGradeUuid = result?.evaluated.currentPosition.gradeUuid

    const assessmentPointPositionTitle = evaluatedSpecializationUuid === result?.position?.specializationUuid
        && evaluatedGradeUuid === result?.position?.gradeUuid
        && `${result?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || result?.position?.title

    const positionTitle = result?.cpa?.criteria?.uuid === cpaCriteriaCurrentUuid
        ? `${result?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        : assessmentPointPositionTitle || result?.cpa?.criteria.title

    if (!result) return null

    return (
        <>
            <DrawerComments
                open={open}
                handleClose={() => setOpen(false)}
                entityTitle={TITLE_NAMESPACE_SHARED_COMMENTS_ASSESSMENT}
                itemTitle={<PointPositionSpecializationGrade title={positionTitle}/>}
                comments={result?.comments}
            />
            <Button
                startIcon={<CommentOutlined/>}
                variant="outlined"
                disabled={result.comments.length <= 0}
                onClick={() => setOpen(true)}
            >
                {result?.comments?.length ?? 0 > 0
                    ? `${TITLE_NAMESPACE_SHARED_COMMENTS} (${result?.comments?.length})`
                    : TITLE_NAMESPACE_SHARED_COMMENTS}
            </Button>
        </>
    )
}
