import React, { FC } from 'react'
import { Box, Button, Card, CardContent } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TITLE_BUTTON_BACK_HISTORY, TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT } from 'helpers/enums/titles'
import { useNavigate, useParams } from 'react-router-dom'
import { MyAssessmentPass } from 'pages/assessment/MyAssessmentPass/MyAssessmentPass'

type HistoryAssessmentProps = {
    parentPath: string;
}

export const HistoryAssessment: FC<HistoryAssessmentProps> = ({parentPath}) => {
    const {assessmentUuid} = useParams()
    const navigate = useNavigate()
    return (
        <Box>
            <Button
                sx={{ mb: 3 }}
                startIcon={<ArrowBackRoundedIcon />}
                onClick={() => navigate(parentPath, { replace: true })}
            >
                {TITLE_BUTTON_BACK_HISTORY}
            </Button>
            <Card>
                <CardContent>
                    <Button
                        sx={{ mb: 3 }}
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={() => navigate(`${parentPath}/assessment/${assessmentUuid}/result`, { replace: true })}
                    >
                        {TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT}
                    </Button>
                    <MyAssessmentPass/>
                </CardContent>
            </Card>
        </Box>
    )
}
