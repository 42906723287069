import axios, { AxiosStatic } from 'axios'
import { EmployeeUploadService } from 'api/services/UploadService/employeeUploadService'
import { MassExamUploadService } from './massExamUploadService'

interface IUploadService {
    employeeUploadService: () => EmployeeUploadService;
}

export class UploadService implements IUploadService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public employeeUploadService(): EmployeeUploadService {
        return EmployeeUploadService.getInstance(this.axiosInstance)
    }

    public massExamUploadService(): MassExamUploadService {
        return MassExamUploadService.getInstance(this.axiosInstance)
    }

}
