import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetAssessmentPass } from 'api/queries/assessment/assessmentQueries'
import { useCpaScaleNumeric } from 'api/queries/cpa/cpaQueries'
import { useFormCpaRoles, useGetAssessmentAnswers } from 'api/queries/form/formQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Stack } from '@mui/material'
import {
    EmployeeAssessmentHeader,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentHeader/EmployeeAssessmentHeader'
import {
    EmployeeAssessmentCompetencies,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/EmployeeAssessmentCompetencies'
import {
    EmployeeAssessmentComments,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentComments/EmployeeAssessmentComments'
import {
    EmployeeAssessmentActions,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentActions/EmployeeAssessmentActions'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'


export const EmployeeAssessment = () => {
    const navigate = useNavigate()
    const { assessmentUuid, employeeUuid } = useParams()
    const { isFetching: fetchingRoles, error: errorRoles } = useFormCpaRoles()
    const { data: assessment, isFetching, error } = useGetAssessmentPass(assessmentUuid || '')
    const { error: errorCpaAnswers, isFetching: isFetchingCpaAnswers } = useCpaScaleNumeric(
        assessment?.cpa?.uuid || '',
        assessment ? !!assessment?.cpa?.scale.uuid : false,
    )
    const {
        error: errorAssAnswers,
        isFetching: isFetchingAssAnswers,
    } = useGetAssessmentAnswers(assessment ? !assessment?.cpa?.scale.uuid : false)

    useEffect(() => {
        if (!assessment || isFetching) return
        const assessmentStatusCode = getAssessmentStatus(assessment.statusUuid)?.code
        const statusCreated = assessmentStatusCode === 'created'
        const statusEstimation = assessmentStatusCode === 'estimation'
        if (!statusCreated && !statusEstimation)
            return

        navigate(`${PATH_EMPLOYEES}/${employeeUuid}/assessment/${assessmentUuid}/preview`)
    }, [assessment, isFetching])


    if (isFetching || isFetchingCpaAnswers || isFetchingAssAnswers || fetchingRoles) return <LoadingComponent/>
    if (error || errorCpaAnswers || errorAssAnswers)
        return <ErrorPage error={error || errorCpaAnswers || errorAssAnswers || errorRoles}/>

    return (
        <Stack spacing={4}>
            <EmployeeAssessmentHeader/>
            <EmployeeAssessmentComments/>
            <EmployeeAssessmentCompetencies/>
            <EmployeeAssessmentActions/>
        </Stack>
    )
}
