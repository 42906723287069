import React, { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button, Stack } from '@mui/material'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { InputForm } from 'components/form/InputForm/InputForm'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_SEND, TITLE_NAMESPACE_COMMENT } from 'helpers/enums/titles'
import { AddButton } from 'components/common/AddButton/AddButton'
import { HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { useAppSelector } from 'store/hooks/redux'
import { convertLocalStringToUtcString } from 'helpers/dateAndTime/convertLocalStringToUtcString'

type DrawerCommentCreateProps = {
    createComment: (payload: IComment) => void;
}

export const DrawerCommentAssessmentCreate: FC<DrawerCommentCreateProps> = ({ createComment }) => {
    const [addingComment, setAddingComment] = useState(false)
    const { firstName, lastName, uuid: authorUuid } = useAppSelector(state => state.userReducer.data)
    const { handleSubmit, setValue, control } = useForm<IComment>({
        defaultValues: {
            text: '',
            author: `${lastName} ${Array.from(firstName)[0]}.`,
            authorUuid,
        },
    })

    const onSubmit: SubmitHandler<IComment> = async (payload) => {
        await createComment({
            ...payload,
            createdAt: convertLocalStringToUtcString(),
            updatedAt: convertLocalStringToUtcString(),
        })
        setValue('text', '')
        setAddingComment(false)
    }

    return (
        <Box>
            {addingComment
                ? <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Controller
                        name="text"
                        control={control}
                        rules={{
                            ...fieldsRestriction.employees.employee.pdp.comment,
                            required: HINT_ERROR_REQUIRED,
                        }}
                        render={({ field }) => (
                            <InputForm
                                {...field}
                                id="text"
                                placeholder={'Введите комментарий'}
                                fullWidth
                                multiline
                                inputProps={{
                                    maxLength: fieldsRestriction.employees.employee.pdp.comment.maxLength.value,
                                }}
                            />
                        )}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 1 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setAddingComment(false)}
                        >
                            {TITLE_BUTTON_CANCEL}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            {TITLE_BUTTON_SEND}
                        </Button>
                    </Stack>
                </Box>
                : <Box display="flex" justifyContent="flex-end">
                    <AddButton
                        variant="contained"
                        onClick={() => setAddingComment(true)}
                    >
                        {TITLE_NAMESPACE_COMMENT}
                    </AddButton>
                </Box>}
        </Box>
    )
}
