import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface IDevelopmentPlanState {
    developmentPlan: IDevelopmentPlan;
    competence: IGradeDevelopmentPlanCompetence;
}

const initialState: IState<IDevelopmentPlanState> = {
    data: {} as IDevelopmentPlanState,
    isLoading: false,
    error: null,
}

export const openAccessDevelopmentPlanSlice = createSlice({
    name: 'openAccessDevelopmentPlan',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getDevelopmentPlan: (state, action: PayloadAction<IDevelopmentPlan>) => {
            state.isLoading = false
            state.error = null
            state.data.developmentPlan = action.payload
        },

        getDevelopmentPlanCompetence: (state, action: PayloadAction<IGradeDevelopmentPlanCompetence>) => {
            state.isLoading = false
            state.error = null
            state.data.competence = action.payload
        },
    },
})

export const openAccessDevelopmentPlanReducer = openAccessDevelopmentPlanSlice.reducer
