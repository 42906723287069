import React, { useEffect } from 'react'
import { useGetAssessmentPass } from 'api/queries/assessment/assessmentQueries'
import { useCpaScaleNumeric } from 'api/queries/cpa/cpaQueries'
import { useFormCpaRoles, useGetAssessmentAnswers } from 'api/queries/form/formQueries'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useNavigate, useParams } from 'react-router-dom'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { PATH_ASSESSMENT } from 'helpers/enums/routePath'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    MyAssessmentPassDesktop,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/MyAssessmentPassDesktop'
import { useMediaQuery, useTheme } from '@mui/material'
import {
    MyAssessmentPassMobile,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassMobile/MyAssessmentPassMobile'
import { appAction } from 'store/actions'


export const MyAssessmentPass = () => {
    const { assessmentUuid } = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const { data: assessment, isFetching, error } = useGetAssessmentPass(assessmentUuid || '', true, true)
    const { isFetching: fetchingRoles, error: errorRoles } = useFormCpaRoles()
    const {
        error: errorCpaAnswers,
        isFetching: isFetchingCpaAnswers,
    } = useCpaScaleNumeric(assessment?.cpa?.uuid || '', !!assessment?.cpa?.scale.uuid)
    const {
        error: errorAssAnswers,
        isFetching: isFetchingAssAnswers,
    } = useGetAssessmentAnswers(assessment ? !assessment?.cpa?.scale.uuid : false)

    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)

    useEffect(() => {
        if (!assessmentSetup) return
        dispatch(appAction.setBackButtonPath(assessmentSetup?.redirectPath ?? PATH_ASSESSMENT))
        return () => dispatch(appAction.setBackButtonPath(null))
    }, [assessmentSetup])

    useEffect(() => {
        if (!assessment || isFetching) return
        const assessmentStatusCode = getAssessmentStatus(assessment.statusUuid)?.code
        const statusEstimation = assessmentStatusCode === 'estimation'
        const statusConfirmation = assessmentStatusCode === 'confirmation'
        const statusComplete = assessmentStatusCode === 'complete'
        const viewerIsEvaluated = assessment?.evaluated?.uuid === uuid
        const canViewParticipant = !assessment.isCompleteByRequestingUser
            && (statusEstimation || statusConfirmation)

        if (viewerIsEvaluated)
            !(statusEstimation || statusComplete) && navigate(`${PATH_ASSESSMENT}/${assessmentUuid}/preview`)
        else
            !canViewParticipant && navigate(`${PATH_ASSESSMENT}/${assessmentUuid}`)

    }, [assessment, isFetching])

    if (isFetching || isFetchingCpaAnswers || isFetchingAssAnswers || fetchingRoles) return <LoadingComponent/>
    if (error || errorCpaAnswers || errorAssAnswers || errorRoles)
        return <ErrorPage error={error || errorCpaAnswers || errorAssAnswers || errorRoles}/>

    if (mobile) return <MyAssessmentPassMobile/>

    return <MyAssessmentPassDesktop/>
}
