import React, { FC } from 'react'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { useAppSelector } from 'store/hooks/redux'
import { getYear, parse } from 'date-fns'
import { convertUtcStringToLocalString } from 'helpers/dateAndTime/convertUtcStringToLocalString'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DialogCommentItemDelete } from '../../DialogCommentItemDelete/DialogCommentItemDelete'
import { useDesktop } from 'helpers/hooks/useDesktop'

type DrawerCommentItemProps = {
    comment: IComment;
    disableEdit?: boolean
    deleteComment?: (uuid: string) => void;
}

export const DrawerCommentItem: FC<DrawerCommentItemProps> = ({ comment, deleteComment, disableEdit }) => {
    const desktop = useDesktop()
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const canEdit = uuid === comment.authorUuid
    const commentDate = comment?.createdAt
    const isValidDate = getYear(parse(commentDate, 'dd.MM.yyyy HH:mm', new Date())) > 1
    const formattedData = isValidDate ? convertUtcStringToLocalString(commentDate) : ''
    const { dialogCommentDelete, toggleDialog } = useDialogsState({
        dialogCommentDelete: false,
    })
    return (
        <Box display="flex">
            {dialogCommentDelete && !desktop &&
                <DialogCommentItemDelete
                    comment={comment}
                    open={dialogCommentDelete}
                    handleClose={() => toggleDialog('dialogCommentDelete', false)}
                    deleteComment={() => deleteComment && deleteComment(comment.uuid)}
                />
            }
            <Box flex={1}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Chip
                        label={comment.author}
                        size="small"
                        sx={{ mr: 1 }}
                    />
                    {isValidDate &&
                        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem/>}>
                            {formattedData?.split(' ')?.map(el =>
                                <Typography
                                    key={el}
                                    variant="body1"
                                >
                                    {el}
                                </Typography>)
                            }
                        </Stack>
                    }
                </Box>
                <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
                    {comment.text}
                </Typography>
            </Box>
            <Box>
                {canEdit && !disableEdit && deleteComment &&
                    <SecondaryActions onDelete={() => desktop ? deleteComment(comment.uuid) : toggleDialog('dialogCommentDelete', true)}/>
                }
            </Box>
        </Box>
    )
}

