import React, { FC, useState } from 'react'
import { useDebounce } from 'react-use'
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material'
import { TITLE_NAMESPACE_ALL, TITLE_NAMESPACE_STATUS, TITLE_NOT_FOUND } from 'helpers/enums/titles'

type StatusFilterProps = {
    options: IEntity[];
    filterByStatus: (statuses: IEntity[]) => void;
}

export const StatusFilter: FC<StatusFilterProps> = ({ options, filterByStatus }) => {
    const [statuses, setStatuses] = useState<IEntity[]>([])

    useDebounce(
        () => {
            filterByStatus(statuses)
        },
        300,
        [statuses],
    )

    return (
        <Autocomplete
            options={options || []}
            disableCloseOnSelect
            multiple
            limitTags={1}
            value={statuses}
            noOptionsText={TITLE_NOT_FOUND}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected}/>
                    {option.title}
                </li>
            )}
            renderTags={value =>
                <Typography>
                    {TITLE_NAMESPACE_STATUS} ({value.length})
                </Typography>
            }
            onChange={(event, value) => setStatuses(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={!statuses.length ? `${TITLE_NAMESPACE_STATUS} (${TITLE_NAMESPACE_ALL})` : undefined}
                />
            )}
        />
    )
}
