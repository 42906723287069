import React, { Suspense } from 'react'
import { Box, Container } from '@mui/material'
import { Loading } from 'components/Loading/Loading'
import { Route, Routes } from 'react-router-dom'
import {
    OpenAccessPageHeader,
} from 'pages/openAccess/OpenAccessPage/components/OpenAccessPageHeader/OpenAccessPageHeader'
import { PATH_ASSESSMENT, PATH_DEVELOPMENT_PLAN } from 'helpers/enums/routePath'
import { OAPdpPage } from 'pages/openAccess/OpenAccessPage/components/OAPdpPage/OAPdpPage'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { OAAssessmentPage } from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/OAAssessmentPage'

export const OpenAccessPage = () => {
    return (
        <Container id="print-page" maxWidth="desktop" sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box component="header" mb={4.5}>
                    <OpenAccessPageHeader/>
                </Box>
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Suspense fallback={<Loading/>}>
                        <Routes>
                            <Route path="/*" element={<ErrorPage errorCode={404}/>}/>
                            <Route path={`${PATH_DEVELOPMENT_PLAN}/:pdpHash/*`} element={<OAPdpPage/>}/>
                            <Route path={`${PATH_ASSESSMENT}/:hash/*`} element={<OAAssessmentPage/>}/>
                        </Routes>
                    </Suspense>
                </Box>
            </Box>
        </Container>
    )
}

