import { AppDispatch } from 'store/store'
import {
    IEmployeePdpCompetenceMaterialCreateRequest,
    IEmployeePdpGetPlanItemRequest,
    IPdpChangePassItemRequest,
    IPdpChangePassMaterialRequest,
    IPdpCompetenceMaterialChangeOrderRequest,
    IPdpCompetenceMaterialDeleteRequest,
    IPdpCompetenceMaterialsSetupRequest,
    IPdpCompetenceMaterialUpdateRequest,
    IPdpItemCommentCreateRequest,
    IPdpItemCommentDeleteRequest,
    IPdpItemCommentUpdateRequest,
    IPdpItemMaterialCommentCreateRequest,
    IPdpItemMaterialCommentDeleteRequest,
    IPdpItemMaterialCommentUpdateRequest,
    IPdpItemUpdateRequest,
} from 'api/typing/employeeTypes'
import { pdpItemSlice } from 'store/reducers/employee/view/tabs/pdp/PdpItemSlice'
import { FormService } from 'api/services/FormService'
import { PdpService } from 'api/services/PdpService'


export class PdpItemAction {

    private static get pdpApi() {
        return new PdpService().pdpService()
    }

    private static get formApi() {
        return new FormService().employeeFormService()
    }


    public getPdpPlanItem(payload: IEmployeePdpGetPlanItemRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.getPdpPlanItem(payload)
                dispatch(pdpItemSlice.actions.getPdpPlanItem(data))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpPlanItem(payload: IPdpItemUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.updateUniqueItemPdp(payload)
                dispatch(pdpItemSlice.actions.updateItem(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // create comment for item
    public createPdpItemComment(payload: IPdpItemCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const { uuid } = await PdpItemAction.pdpApi.createPdpItemComment(payload)
                dispatch(pdpItemSlice.actions.createPdpItemComment({ ...payload, uuid }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // update comment for item
    public updatePdpItemComment(payload: IPdpItemCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.updatePdpItemComment(payload)
                dispatch(pdpItemSlice.actions.updatePdpItemComment(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // delete comment for item
    public deletePdpItemComment(payload: IPdpItemCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.deletePdpItemComment(payload)
                dispatch(pdpItemSlice.actions.deletePdpItemComment(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // create comment for item Material
    public createPdpItemMaterialComment(payload: IPdpItemMaterialCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const { uuid } = await PdpItemAction.pdpApi.createPdpItemMaterialComment(payload)
                dispatch(pdpItemSlice.actions.createPdpItemMaterialComment({ ...payload, uuid }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // update comment for item Material
    public updatePdpItemMaterialComment(payload: IPdpItemMaterialCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.updatePdpItemMaterialComment(payload)
                dispatch(pdpItemSlice.actions.updatePdpItemMaterialComment(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    // delete comment for item Material
    public deletePdpItemMaterialComment(payload: IPdpItemMaterialCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.deletePdpItemMaterialComment(payload)
                dispatch(pdpItemSlice.actions.deletePdpItemMaterialComment(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdpCompetenceMaterial(payload: IEmployeePdpCompetenceMaterialCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.createPdpCompetenceMaterial(payload)
                dispatch(pdpItemSlice.actions.createPdpCompetenceMaterial({ ...payload, ...data }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpCompetenceMaterial(payload: IPdpCompetenceMaterialUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.updatePdpCompetenceMaterial(payload)
                dispatch(pdpItemSlice.actions.updatePdpCompetenceMaterial({ ...payload, ...data }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpCompetenceMaterial(payload: IPdpCompetenceMaterialDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.deletePdpCompetenceMaterial(payload)
                dispatch(pdpItemSlice.actions.deletePdpCompetenceMaterial({ ...payload, ...data }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeOrderPdpCompetenceMaterial(
        payload: IPdpCompetenceMaterialChangeOrderRequest & { newMaterialsArray: IPdpCompetenceMaterial[] },
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                const { newMaterialsArray, ...data } = payload
                dispatch(pdpItemSlice.actions.fetching())
                void await PdpItemAction.pdpApi.changeOrderPdpCompetenceMaterial(data)
                dispatch(pdpItemSlice.actions.changeOrderItems({
                    materials: newMaterialsArray,
                    skillLevelUuid: data.skillLevelUuid,
                }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setupPdpCompetenceMaterials(payload: IPdpCompetenceMaterialsSetupRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.setupPdpCompetenceMaterials(payload)
                dispatch(pdpItemSlice.actions.setupPdpCompetenceMaterials(data))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getMaterialsForPdpCompetence(
        pdpUuid: string,
        specializationUuid: string,
        competenceUuid: string,
        entityType: EntityType,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.formApi.getMaterialsForPdpCompetence(
                    pdpUuid,
                    specializationUuid,
                    competenceUuid,
                    entityType,
                )
                dispatch(pdpItemSlice.actions.getMaterialsForPdpCompetence(data))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeActivePdpCompetenceMaterialSetup(payload: { uuid: string; flag: boolean; }) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                dispatch(pdpItemSlice.actions.changeActivePdpCompetenceMaterialSetup(payload))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changePassCompetenceMaterial(payload: IPdpChangePassMaterialRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.changePassCompetenceMaterial(payload)
                dispatch(pdpItemSlice.actions.changePassCompetenceMaterial({ ...payload, ...data }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changePassItem(payload: IPdpChangePassItemRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpItemSlice.actions.fetching())
                const data = await PdpItemAction.pdpApi.changePassItem(payload)
                dispatch(pdpItemSlice.actions.changePassItem({ ...payload, ...data }))
                dispatch(pdpItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(pdpItemSlice.actions.clearState())
        }
    }

}
