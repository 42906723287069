import React, { FC, useEffect, useImperativeHandle, useState } from 'react'
import { addYears, isBefore, isDate, isValid, isWithinInterval, subYears } from 'date-fns'
import { HINT_CHOOSE, HINT_ERROR_INCORRECT_DATE, HINT_ERROR_REQUIRED } from 'helpers/enums/hints'
import { Box, IconButton, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ClearRounded, DateRangeRounded } from '@mui/icons-material'
import { InputForm } from 'components/form/InputForm/InputForm'
import { TITLE_BUTTON_INPUT_DATE_END, TITLE_FIELD_DATE_END, TITLE_FIELD_DATE_START } from 'helpers/enums/titles'
import { AddButton } from 'components/common/AddButton/AddButton'

type RangeBetweenTwoDatesProps = {
    showDateEnd?: boolean;
    addDateEndBtn?: boolean;
    isOptionalDateEnd?: boolean
    addDateEndBtnTitle?: string;
    requiredDateStart?: boolean;
    requiredDateEnd?: boolean;

    minDateStart?: Date,
    maxDateStart?: Date,
    minDateEnd?: Date,
    maxDateEnd?: Date,

    startDate: DateState;
    setStartDate: (date: any) => void;
    endDate?: DateState;
    setEndDate?: (date: any) => void;
    ref?: React.Ref<any> | null,
}

export const RangeBetweenTwoDates: FC<RangeBetweenTwoDatesProps> = React.forwardRef(function RangeBetweenTwoDates(
    inProps,
    ref,
) {
    const {
        setEndDate,
        isOptionalDateEnd,
        endDate,
        startDate,
        setStartDate,
        requiredDateStart,
        requiredDateEnd,
        addDateEndBtnTitle,
        minDateStart,
        maxDateStart,
        minDateEnd,
        maxDateEnd,
    } = inProps

    const [isOpenDateEnd, setIsOpenDateEnd] = useState(!isOptionalDateEnd || (!!endDate && endDate?.value))

    const [dateStart, setDateStart] = useState<DateState>({
        value: startDate?.value && isValid(startDate.value) ? new Date(startDate.value) : null,
        error: null,
    })
    const [dateEnd, setDateEnd] = useState<DateState>({
        value: endDate?.value && isValid(endDate?.value) ? new Date(endDate?.value) : null,
        error: null,
    })

    useEffect(() => {
        setStartDate(dateStart)
    }, [dateStart.value, dateStart.error])

    useEffect(() => {
        if (dateStart.value
            && isValid(dateStart.value)
            && isBefore(subYears(new Date(), 200), dateStart.value)
        ) checkDateStart()
    }, [dateStart.value])

    useEffect(() => {
        setEndDate && setEndDate(dateEnd)
    }, [dateEnd.value, dateEnd.error])

    // TODO: mb need fix to replace with checkDateEnd or add double check
    useEffect(() => {
        if (dateEnd.value
            && isValid(dateEnd.value)
            && isBefore(addYears(new Date(), 200), dateEnd.value)
        ) checkDateStart()
    }, [dateStart.value])

    useImperativeHandle(ref, () => ({
        checkDates() {
            checkDateStart()
            checkDateEnd()
        },
    }))

    const changeDateStart = (date: Date | null) => {
        setDateStart({
            ...dateStart,
            value: date,
        })
    }

    const checkDateStart = () => {
        const { value: valueStart } = dateStart
        const { value: valueEnd } = dateEnd
        let isValidInterval = false

        if (!valueStart && requiredDateStart) {
            setDateStart({
                ...dateStart,
                error: HINT_ERROR_REQUIRED,
            })
            return
        }

        const invalidDateMax = valueStart && maxDateStart && isBefore(maxDateStart, valueStart)
        const invalidDateMin = valueStart && minDateStart && isBefore(valueStart, minDateStart)


        const isValidDate = isDate(valueStart) && isValid(valueStart)
        if (isValidDate) {
            isValidInterval = isWithinInterval(valueStart as Date, {
                start: subYears(new Date(), 200),
                end: addYears(new Date(), 10),
            })
        }

        if (valueEnd && isValidDate && isBefore(valueEnd, valueStart as Date)) {
            setDateEnd({
                ...dateEnd,
                error: HINT_ERROR_INCORRECT_DATE,
            })
        }

        setDateStart({
            ...dateStart,
            error: valueStart
            && (!isValidDate
                || !isValidInterval
                || invalidDateMin
                || invalidDateMax)
                ? HINT_ERROR_INCORRECT_DATE
                : null,
        })
    }

    const changeDateEnd = (date: Date | null) => {
        setDateEnd({
            ...dateEnd,
            value: date,
        })
    }

    const checkDateEnd = () => {
        const { value: valueStart } = dateStart
        const { value: valueEnd } = dateEnd
        let isValidInterval = false

        if (!valueEnd && requiredDateEnd) {
            setDateEnd({
                ...dateEnd,
                error: HINT_ERROR_REQUIRED,
            })
            return
        }

        const invalidDateMax = valueEnd && maxDateEnd && isBefore(maxDateEnd, valueEnd)
        const invalidDateMin = valueEnd && minDateEnd && isBefore(valueEnd, minDateEnd)

        const intervalStart = !valueStart || !isValid(valueStart) || !isDate(valueStart)
            ? subYears(new Date(), 200)
            : valueStart

        const isValidDate = isDate(valueEnd)
        if (isValidDate) {
            isValidInterval = isWithinInterval(valueEnd as Date, {
                start: intervalStart,
                end: addYears(new Date(), 10),
            })
        }

        setDateEnd({
            ...dateEnd,
            error: valueEnd
            && (!isValidDate
                || !isValidInterval
                || invalidDateMin
                || invalidDateMax)
                ? HINT_ERROR_INCORRECT_DATE
                : null,
        })
    }

    const closeDateEndInput = () => {
        setIsOpenDateEnd(false)
        setDateEnd({
            value: null,
            error: null,
        })
    }

    return (
        <Box ref={ref} display="flex" justifyContent="space-between">
            <Box>
                <DatePicker
                    inputFormat="dd.MM.yyyy"
                    value={dateStart.value}
                    toolbarPlaceholder={HINT_CHOOSE}
                    components={{
                        OpenPickerIcon: DateRangeRounded,
                    }}
                    onChange={changeDateStart}
                    minDate={subYears(new Date(), 200)}
                    maxDate={addYears(new Date(), 10)}
                    renderInput={(params) => <InputForm
                        {...params as any}
                        fullWidth={false}
                        required={!!requiredDateStart}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: HINT_CHOOSE,
                        }}
                        onBlur={checkDateStart}
                        error={!!dateStart.error}
                        helperText={dateStart.error ? dateStart.error : null}
                        title={TITLE_FIELD_DATE_START}
                    />}
                />
            </Box>
            {!isOpenDateEnd
                ? <AddButton
                    sx={{ alignSelf: 'flex-start', mt: 3.5 }}
                    onClick={() => setIsOpenDateEnd(true)}
                >
                    {addDateEndBtnTitle ?? TITLE_BUTTON_INPUT_DATE_END}
                </AddButton>
                : <>
                    <Typography variant="body1" mt={4.5} mx={1}>
                        -
                    </Typography>
                    <Box>
                        <DatePicker
                            inputFormat="dd.MM.yyyy"
                            value={dateEnd.value}
                            toolbarPlaceholder={HINT_CHOOSE}
                            components={{
                                OpenPickerIcon: DateRangeRounded,
                            }}
                            disabled={!setEndDate}
                            onChange={changeDateEnd}
                            renderInput={(params) => <InputForm
                                {...params as any}
                                fullWidth={false}
                                error={!!dateEnd.error}
                                disabled={!setEndDate}
                                required={!!requiredDateEnd}
                                helperText={dateEnd.error ? dateEnd.error : null}
                                onBlur={checkDateEnd}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: HINT_CHOOSE,
                                }}
                                title={TITLE_FIELD_DATE_END}
                            />}
                        />
                    </Box>
                    {isOptionalDateEnd && <IconButton
                        color="primary"
                        size="small"
                        sx={{ alignSelf: 'flex-start', mt: 4 }}
                        onClick={closeDateEndInput}
                    >
                        <ClearRounded
                            fontSize="small"
                        />
                    </IconButton>}
                </>}
        </Box>
    )
})
