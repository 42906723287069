import React, { FC } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Chip, Divider, Link, ListItem, ListItemButton, Typography } from '@mui/material'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { useAppSelector } from 'store/hooks/redux'

type EmployeeCompetenciesCompetenceProps = {
    element: IGradeCompetence;
    blockLink?: boolean;
}

export const EmployeeCompetenciesCompetence: FC<EmployeeCompetenciesCompetenceProps> = ({ element, blockLink }) => {
    // const timerId = React.useRef<null | NodeJS.Timeout>(null)
    const { data: { employee } } = useAppSelector(state => state.employeeReducer)
    const linkUrl = blockLink
        ? '#'
        : `${PATH_EMPLOYEES}/${employee.uuid}/specialization/${employee?.specialization?.uuid}/competence/${element.uuid}/${element.entityType}`
    // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    // const [open, setOpen] = useState(false)


    // const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget)
    //     timerId.current = setTimeout(() => {
    //         setOpen(true)
    //     }, 500)
    // }
    //
    // const closePopover = () => {
    //     if (timerId.current) clearTimeout(timerId.current)
    //     setOpen(false)
    //     setAnchorEl(null)
    // }
    //
    // const popoverId = `popover-grade-${element.uuid}}`

    return (
        <>
            {/*{element?.indicators?.length > 0 && <Popover*/}
            {/*    id={popoverId}*/}
            {/*    open={open}*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    onClose={closePopover}*/}
            {/*    sx={{*/}
            {/*        pointerEvents: 'none',*/}
            {/*        '& Mui-Paper': {*/}
            {/*            pointerEvents: 'auto',*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    disableRestoreFocus*/}
            {/*    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}*/}
            {/*    transformOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
            {/*    PaperProps={{ style: { borderRadius: '16px' } }}*/}
            {/*>*/}
            {/*    <Box p={2} maxWidth={663}>*/}
            {/*        <Typography variant="body1" mb={2}>*/}
            {/*            {TITLE_NAMESPACE_INDICATORS}*/}
            {/*        </Typography>*/}
            {/*        <Box display="flex" flexDirection="column">*/}
            {/*            {element?.indicators?.map((indicator, idx) => (*/}
            {/*                <Box*/}
            {/*                    key={indicator}*/}
            {/*                    display="flex"*/}
            {/*                    sx={{*/}
            {/*                        py: 1,*/}
            {/*                        borderBottom: idx + 1 < element?.indicators?.length*/}
            {/*                            ? '1px solid #E2E7E9'*/}
            {/*                            : 'none',*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <Box>*/}
            {/*                        <Typography*/}
            {/*                            color={theme => theme.palette.text.primary}*/}
            {/*                            variant="body1"*/}
            {/*                            mr={1}*/}
            {/*                        >*/}
            {/*                            {idx + 1}.*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                    <Box>*/}
            {/*                        <Typography*/}
            {/*                            color={theme => theme.palette.text.primary}*/}
            {/*                            variant="body1"*/}
            {/*                        >*/}
            {/*                            {indicator}*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Box>*/}
            {/*            ))}*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*</Popover>}*/}
            <ListItem disablePadding>
                <ListItemButton
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 0.5,
                        px: 2,
                        borderRadius: '12px',
                    }}
                >
                    <Box display="flex" flex={1}>
                        <Box sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Box mr={0.5} mb={0.5}>
                                <Link
                                    component={blockLink ? Typography : RouterLink}
                                    to={linkUrl}
                                    underline={blockLink ? 'none' : 'hover'}
                                    variant="body1"
                                >
                                    {element.title}
                                </Link>
                            </Box>
                            <Typography variant="body1">
                                {element.competenceTypeTitle}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ alignSelf: 'center' }}
                    >
                        {/*<ChipSkillLevel*/}
                        {/*    skillLevelTitle={element.skillLevelTitle}*/}
                        {/*    aria-owns={open ? popoverId : undefined}*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    onMouseEnter={openPopover}*/}
                        {/*    onMouseLeave={closePopover}*/}
                        {/*/>*/}
                        <Chip
                            sx={{ minWidth: 56 }}
                            label={`${element?.score}%`}
                            color={element?.score > 75 ? 'default' : 'warning'}
                        />
                    </Box>
                </ListItemButton>
            </ListItem>
            <Divider />
        </>
    )
}

