import React, { FC, ReactElement, useState } from 'react'
import { Box, Chip, Popover, Stack, Typography, useTheme } from '@mui/material'
import { AccountCircleRounded, SchoolRounded } from '@mui/icons-material'
import {
    TITLE_NAMESPACE_ADDED,
    TITLE_NAMESPACE_CHANGED,
    TITLE_NAMESPACE_IN,
    TITLE_NAMESPACE_RETURNED_FOR_PROGRESS,
} from 'helpers/enums/titles'
import { convertUtcStringToLocalString } from 'helpers/dateAndTime/convertUtcStringToLocalString'
import { BadgeChangeLogDrawer } from '../BadgeChangeLogDrawer/BadgeChangeLogDrawer'
import { useDesktop } from '../../../helpers/hooks/useDesktop'

type BadgeChangeLogProps = {
    changeLog: IChangeLog;
    chipDateTime?: boolean;
    evaluatedUuid?: string;
    badgeDrawerTitle?: string | ReactElement;
    badgeDrawerSubTitle?: string | ReactElement;
}

const getChangeLogTitle = ({ eventType }: Pick<IChangeLog, 'eventType'>) => {
    switch (eventType) {
        case 'changed': {
            return TITLE_NAMESPACE_CHANGED
        }
        case 'added': {
            return TITLE_NAMESPACE_ADDED
        }
        case 'returned': {
            return TITLE_NAMESPACE_RETURNED_FOR_PROGRESS
        }
        default: {
            return ''
        }
    }
}

export const BadgeChangeLog: FC<BadgeChangeLogProps> = ({
    changeLog,
    chipDateTime,
    evaluatedUuid,
    badgeDrawerTitle,
    badgeDrawerSubTitle,
}) => {
    const desktop = useDesktop()
    const theme = useTheme()
    const timerId = React.useRef<null | NodeJS.Timeout>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [open, setOpen] = useState(false)
    const popoverId = `popover-change-log-${changeLog.authorUuid}-${changeLog.date}-${chipDateTime && 'chip-date-time'}`
    const isEvaluatedAuthor = changeLog.authorUuid === evaluatedUuid


    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        timerId.current = setTimeout(() => {
            setOpen(true)
        }, 500)
    }

    const closePopover = () => {
        if (timerId.current) clearTimeout(timerId.current)
        setOpen(false)
        setAnchorEl(null)
    }

    return (
        <Box>
            {!desktop &&
                <BadgeChangeLogDrawer
                    open={open}
                    handleClose={closePopover}
                    changeLog={changeLog}
                    getChangeLogTitle={getChangeLogTitle}
                    title={badgeDrawerTitle}
                    subTitle={badgeDrawerSubTitle}
                    isEvaluatedAuthor={isEvaluatedAuthor}
                    chipDateTime={chipDateTime}
                />
            }
            <Box
                sx={{ cursor: 'pointer', mr: 1, ml: chipDateTime ? 0 : 1 }}
                aria-owns={open ? popoverId : undefined}
                aria-haspopup="true"
                onMouseEnter={openPopover}
                onMouseLeave={closePopover}
            >
                {chipDateTime ?
                    <Chip
                        label={`${TITLE_NAMESPACE_CHANGED}: ${changeLog.date.split(' ')[0]}`}
                        size="small"
                        color="warning"
                    />
                    : isEvaluatedAuthor
                        ? <SchoolRounded color="warning"/>
                        : <AccountCircleRounded color="warning"/>}
            </Box>
            {desktop &&
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    sx={{
                        pointerEvents: 'none',
                        '& Mui-Paper': {
                            pointerEvents: 'auto',
                        },
                    }}
                    disableRestoreFocus
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    PaperProps={{ style: { borderRadius: '16px' } }}
                >
                    <Box p={2} maxWidth={220}>
                        <Stack spacing={1}>
                            <Typography variant="caption" color={theme.palette.warning.main}>
                                {getChangeLogTitle({ eventType: changeLog.eventType })}
                            </Typography>
                            <Typography variant="body1">
                                {convertUtcStringToLocalString(changeLog.date).split(' ').join(` ${TITLE_NAMESPACE_IN.toLowerCase()} `)}
                            </Typography>
                            <Typography variant="body1">
                                {changeLog.author}
                            </Typography>
                        </Stack>
                    </Box>
                </Popover>
            }
        </Box>
    )
}
