import React, { FC, useState } from 'react'
import { Box, Chip, Collapse, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPETENCIES_GENITIVE } from 'helpers/enums/titles'
import {
    EmployeeCompetenciesCompetence,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabCompetencies/components/EmployeeCompetenciesCompetence/EmployeeCompetenciesCompetence'

type EmployeeCompetenciesGroupProps = {
    element: IGradeCompetenciesGroup;
    blockLink?: boolean;
}

export const EmployeeCompetenciesGroup: FC<EmployeeCompetenciesGroupProps> = ({ element, blockLink }) => {
    const [open, setOpen] = useState(true)

    const handleClick = () => {
        if (element.competencies?.length || element.subGroups?.length)
            setOpen(!open)
    }

    return (
        <List disablePadding>
            <ListItem disablePadding>
                <ListItemButton
                    sx={{ py: 1.5, px: 2, borderRadius: '12px', justifyContent: 'space-between', alignItems: 'center' }}
                    onClick={handleClick}
                >
                    <Box sx={{
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                        <Box display="flex" mb={0.5}>
                            <Typography maxWidth="435px" mr={0.5} variant="subtitle1">
                                {element.title}
                            </Typography>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                color: (theme) => element.competenceCount === 0
                                    ? theme.palette.warning.main
                                    : theme.typography.body1.color,
                            }}
                        >
                            {TITLE_NAMESPACE_COMPETENCIES_GENITIVE}: {element.competenceCount}
                        </Typography>
                    </Box>
                    <Chip
                        sx={{ minWidth: 56, marginLeft: 3 }}
                        label={`${element?.score}%`}
                        color={element?.score > 75 ? 'default' : 'warning'}
                    />
                </ListItemButton>
            </ListItem>
            <Divider sx={{ marginLeft: open ? 4 : 0 }} />
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    marginLeft: 2,
                    paddingLeft: 2,
                    borderLeft: '1px solid #C7D2D3',
                    position: 'relative',
                    '&:after': {
                        position: 'absolute',
                        content: '""',
                        width: 7,
                        height: 7,
                        backgroundColor: '#C7D2D3',
                        borderRadius: '50%',
                        top: -3.5,
                        left: -3.5,
                    },
                }}
            >
                {element.subGroups?.map((item) => (
                    <EmployeeCompetenciesGroup
                        key={item.uuid}
                        element={item}
                        blockLink={blockLink}
                    />
                ))}
                {element.competencies?.map((item) => (
                    <EmployeeCompetenciesCompetence key={item.uuid} element={item} blockLink={blockLink} />
                ))}
            </Collapse>
        </List>
    )
}

