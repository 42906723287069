import React, { FC } from 'react'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface MassExamEnlargedImageDialogProps {
    openImage: boolean;
    closeImageDialog: () => void;
    imgLink: string;
}

export const MassExamEnlargedImageDialog: FC<MassExamEnlargedImageDialogProps> = ({
    openImage,
    closeImageDialog,
    imgLink,
}) => {
    return (
        <Dialog
            open={openImage}
            fullScreen
            PaperProps={{
                sx: {backgroundColor: 'transparent'},
            }}
        >
            <DialogContent sx={{ p: 5 }}>
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: '40px',
                        top: '40px',
                        backgroundColor: '#FFF',
                        borderRadius: '50%',
                    }}
                    onClick={closeImageDialog}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={imgLink}
                        alt='question-img'
                        style={{
                            borderRadius: '8px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}
