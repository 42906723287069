import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICPAGetCriteriaCompetenceSkillLevel, ICPAGetCriteriaPointResponse } from 'api/typing/cpaTypes'
import {
    changeActiveCompetence,
    changeActiveCompetenceGroup,
    getCompetenceListToSave,
    initSaveDataCompetencies,
    prepareCompetenceListCurrent,
} from 'helpers/cpa/criteria/cpaCompetence'


interface ICPAItemCriteriaState {
    currentCriteriaUuid: string;
    pointCriteria: ICPAGetCriteriaPointResponse | null;
    competenceCriteria: {
        isChanged: boolean;
        current: ICPAGetCriteriaCompetenceSkillLevel[] | [];
        initial: ICPAGetCriteriaCompetenceSkillLevel[] | [];
        list: ICpaCriteriaCompetenceGroup[] | null;
        saved: string[];
    }
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAItemCriteriaState = {
    currentCriteriaUuid: '',
    pointCriteria: null,
    competenceCriteria: {
        isChanged: false,
        current: [],
        initial: [],
        list: [],
        saved: [],
    },
    isLoading: false,
    error: null,
}

export const CPAItemCriteriaSlice = createSlice({
    name: 'CPAItemCriteria',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setCpaCurrentCriteriaUuid: (state, action: PayloadAction<string>) => {
            state.currentCriteriaUuid = action.payload
        },

        setCpaCriteriaPoint: (state, action: PayloadAction<ICPAGetCriteriaPointResponse>) => {
            state.competenceCriteria.current = []
            state.competenceCriteria.initial = []
            state.competenceCriteria.list = []
            state.competenceCriteria.saved = []
            state.pointCriteria = action.payload
        },

        setCpaCriteriaCompetence: (state, action: PayloadAction<ICPAGetCriteriaCompetenceSkillLevel[]>) => {
            state.competenceCriteria.current = action.payload
            state.competenceCriteria.initial = action.payload
            prepareCompetenceListCurrent(state.competenceCriteria.current)
            state.pointCriteria = null
        },

        setCriteriaCompetenceList: (state, action: PayloadAction<ICpaCriteriaCompetenceGroup[]>) => {
            state.competenceCriteria.list = action.payload
            state.competenceCriteria.saved = initSaveDataCompetencies(
                state.competenceCriteria.current,
                state.competenceCriteria.list,
            )
        },

        criteriaCompetenceChangeActiveGroup: (state, action: PayloadAction<{ uuid: string; active: boolean }>) => {
            if (!state.competenceCriteria.list) return
            state.competenceCriteria.saved = changeActiveCompetenceGroup(
                state.competenceCriteria.list,
                state.competenceCriteria.saved,
                action.payload.uuid,
                action.payload.active,
            )
        },

        criteriaCompetenceChangeActiveCompetence: (
            state,
            action: PayloadAction<{ uuid: string; groupUuid: string; active: boolean }>,
        ) => {
            if (!state.competenceCriteria.list) return
            state.competenceCriteria.saved = changeActiveCompetence(
                state.competenceCriteria.list,
                state.competenceCriteria.saved,
                action.payload.uuid,
                action.payload.groupUuid,
                action.payload.active,
            )
        },

        setNewCompetenciesInCriteria: (state) => {
            if (!state.competenceCriteria.list) return
            state.competenceCriteria.current = getCompetenceListToSave(
                state.competenceCriteria.list,
                state.competenceCriteria.saved,
                state.competenceCriteria.current,
            )
            state.competenceCriteria.list = []
            state.competenceCriteria.isChanged = true
        },

        setCriteriaCompetenceItemSkillLevel: (
            state,
            action: PayloadAction<{ competenceUuid: string; skillLevelUuid: string; competenceTypeUuid: string }>,
        ) => {
            if (!state.competenceCriteria.current) return
            state.competenceCriteria.current.some(level => {
                if (level.uuid !== action.payload.competenceTypeUuid) return false
                return level.competencies.some(competence => {
                    if (competence.uuid !== action.payload.competenceUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    competence.initialSkillLevelUuid = competence?.initialSkillLevelUuid
                        ?? competence.currentSkillLevelUuid
                    // eslint-disable-next-line no-param-reassign
                    competence.currentSkillLevelUuid = action.payload.skillLevelUuid
                    // eslint-disable-next-line no-param-reassign
                    competence.isChanged = competence.currentSkillLevelUuid !== competence.initialSkillLevelUuid
                    return true
                })
            })
            state.competenceCriteria.isChanged = state.competenceCriteria.current.some(el => {
                return el.competencies.some(competence => !!competence.isChanged)
            })
        },

        deleteCriteriaCompetenceItem: (
            state,
            action: PayloadAction<{ competenceUuid: string; competenceTypeUuid: string }>,
        ) => {
            if (!state.competenceCriteria.current) return
            const competenceTypeIndex = state
                .competenceCriteria
                .current
                .findIndex(el => el.uuid === action.payload.competenceTypeUuid)

            // clear from list in type criteria
            state.competenceCriteria.current[competenceTypeIndex].competencies =
                state
                    .competenceCriteria
                    .current[competenceTypeIndex]
                    .competencies
                    .filter(el => el.uuid !== action.payload.competenceUuid)

            // clear from saved
            state.competenceCriteria.saved = state
                .competenceCriteria
                .saved
                .filter(el => el !== action.payload.competenceUuid)

            // state.competenceCriteria.isChanged = state.competenceCriteria.current.some(el => {
            //     return el.competencies.some(competence => !!competence.isChanged)
            // })
            state.competenceCriteria.isChanged = true
        },

        saveCriteriaCompetence: (state) => {
            if (!state.competenceCriteria.current) return
            state.competenceCriteria.current.forEach(level => {
                level.competencies.forEach(competence => {
                    // eslint-disable-next-line no-param-reassign
                    competence.isChanged = false
                    // eslint-disable-next-line no-param-reassign
                    competence.initialSkillLevelUuid = competence.currentSkillLevelUuid
                    return true
                })
            })
            state.competenceCriteria.initial = [...state.competenceCriteria.current]
            state.competenceCriteria.isChanged = false
        },

        cancelEditCompetenceCriteria: (state) => {
            if (!state.competenceCriteria.current) return
            state.competenceCriteria.current.forEach(level => {
                level.competencies.forEach(competence => {
                    // eslint-disable-next-line no-param-reassign
                    competence.isChanged = false
                    // eslint-disable-next-line no-param-reassign
                    competence.currentSkillLevelUuid = competence.initialSkillLevelUuid
                        ?? competence.currentSkillLevelUuid
                    return true
                })
            })
            state.competenceCriteria.isChanged = false
        },

        clearCompetenceCriteria: (state) => {
            state.competenceCriteria.current = []
            state.competenceCriteria.saved = []
            state.competenceCriteria.list = []
            state.competenceCriteria.isChanged = true
        },

        restoreInitialCriteriaCompetence: (state) => {
            state.competenceCriteria.current = [...state.competenceCriteria.initial]
            prepareCompetenceListCurrent(state.competenceCriteria.current)
        },
    },
})

export const CPAItemCriteriaReducer = CPAItemCriteriaSlice.reducer
