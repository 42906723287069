import { useQuery } from 'react-query'
import { companyActions, userActions } from 'store/actions'
import { UserService } from 'api/services/UserService'
import axios from 'axios'
import { useAppDispatch } from 'store/hooks/redux'
import { CompanyService } from 'api/services/CompanyService'
import { useGetAlertsData } from '../../../store/hooks/useGetAlertsData'

const userApi = new UserService(axios)
const companyApi = new CompanyService(axios)

const getProfileFn = async () => await userApi.fetchUser()
const getCompanyFn = async () => await companyApi.getCompany()

export const useProfile = (enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'profile',
        queryFn: getProfileFn,
        // refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(userActions.setUser(data))
        },
        retry: 0,
        enabled,
    })
}
export const useCompany = () => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'company',
        queryFn: getCompanyFn,
        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        onSuccess: (data) => {
            dispatch(companyActions.setCompany(data))
        },
        retry: 0,
    })
}

export const useApp = () => {
    const dispatch = useAppDispatch()
    const { isLoading: isLoadingProfile, isFetching: isFetchingProfile, error: errorProfile, data } = useQuery({
        queryKey: 'profile',
        queryFn: getProfileFn,
        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        onSuccess: (data) => {
            dispatch(userActions.setUser(data))
        },
        retry: 0,
        refetchInterval: 1000 * 60 * 5,
    })
    const { isLoading: isLoadingCompany, isFetching: isFetchingCompany, error: errorCompany } = useQuery({
        queryKey: 'company',
        queryFn: getCompanyFn,
        // refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(companyActions.setCompany(data))
        },
        retry: 0,
        enabled: !!data,
    })

    useGetAlertsData()

    return {
        isFetching: isFetchingProfile || isFetchingCompany,
        error: errorProfile || errorCompany,
        isLoading: isLoadingProfile || isLoadingCompany,
    }
}
