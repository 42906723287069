import { AppDispatch, RootState } from 'store/store'
import { MyAssessmentListSlice } from 'store/reducers/assessment/MyAssessmentListSlice'
import { MyAssessmentPassSlice } from 'store/reducers/assessment/MyAssessmentPassSlice'
import {
    IAssessmentChangeStatusRequest,
    IAssessmentCommentCreateUpdate,
    IAssessmentCommentDelete,
    IAssessmentIndicatorCommentCreateUpdate,
    IAssessmentIndicatorCommentDelete,
    IAssessmentSetAnswerRequest,
} from 'api/typing/assessmentServiceTypes'
import { AssessmentService } from 'api/services/AssessmentService'
import { assessmentStatusSummarizing } from 'helpers/assessment/getAssessmentStatus'
import { assessmentResultSlice } from 'store/reducers/assessment/assessmentResultSlice'
import { DevelopmentService } from '../../../api/services/DevelopmentService'
import { myAssessmentActions } from '../index'

export class MyAssessmentAction {

    private static get assessmentApi() {
        return new AssessmentService().assessmentService()
    }

    private static get assessmentDevelopApi() {
        return new DevelopmentService().developmentAssessmentService()
    }

    public getMyAssessmentMetaList() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentListSlice.actions.fetching())
                const data = await MyAssessmentAction.assessmentDevelopApi.getAssessmentList()
                dispatch(myAssessmentActions.setMyAssessmentMetaList(data))
                dispatch(MyAssessmentListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setMyAssessmentMetaList(data: IAssessmentMeta[]) {
        return (dispatch: AppDispatch, getState: () => RootState) => {
            const userUuid = getState().userReducer.data.uuid
            dispatch(MyAssessmentListSlice.actions.setAssessmentMetaList({ list: data, userUuid }))
        }
    }

    public setMyAssessmentMeta(data: IAssessmentMeta) {
        return (dispatch: AppDispatch, getState: () => RootState) => {
            const userUuid = getState().userReducer.data.uuid
            dispatch(MyAssessmentListSlice.actions.setAssessmentMeta({ assessment: data, userUuid }))
        }
    }

    public deleteMyAssessmentMeta(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentListSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentDelete(uuid)
                dispatch(MyAssessmentListSlice.actions.deleteAssessmentMeta())
                dispatch(MyAssessmentListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public startMyAssessment(payload: IAssessmentChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentListSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentStatus(payload)
                dispatch(MyAssessmentListSlice.actions.startMyAssessment(payload))
                dispatch(MyAssessmentListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearScoresAndResultsFilters() {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.clearScoresAndResultsFilters())
        }
    }

    public clearNeedToPassFilters() {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.clearNeedToPassFilters())
        }
    }

    public setSearchScoresAndResults(search: string) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.setSearchScoresAndResults(search))
        }
    }

    public setStatusesScoresAndRoles(data: string[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.setStatusesScoresAndResults(data))
        }
    }
    public setTypesScoresAndRoles(data: string[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.setTypesScoresAndResults(data))
        }
    }

    public setSearchNeedToPass(search: string) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.setSearchNeedToPass(search))
        }
    }

    public setTypesNeedToPass(data: string[]) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentListSlice.actions.setTypesNeedToPass(data))
        }
    }

    public setAssessmentPass(data: IAssessment) {
        return (dispatch: AppDispatch, getState: () => RootState) => {
            const userUuid = getState().userReducer.data.uuid
            dispatch(MyAssessmentPassSlice.actions.setAssessment({ data, userUuid }))
        }
    }

    public setAssessmentPassAnswers(data: Array<IEntity & { description: string; weight: number }>) {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentPassSlice.actions.setAssessmentAnswers(data))
        }
    }

    public assessmentPassCheckAnswers() {
        return (dispatch: AppDispatch) => {
            dispatch(MyAssessmentPassSlice.actions.checkAnswers())
        }
    }

    public setAssessmentPassAnswer(payload: IAssessmentSetAnswerRequest & { isCalibrate?: boolean }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                const data = await MyAssessmentAction.assessmentApi.assessmentSetAnswer(payload)
                dispatch(MyAssessmentPassSlice.actions.setAnswer({ ...payload, ...data }))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createIndicatorComment(payload: IAssessmentIndicatorCommentCreateUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                const data = await MyAssessmentAction.assessmentApi.assessmentIndicatorCommentCreateUpdate(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentIndicatorCommentCreate({ ...payload, ...data }))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateIndicatorComment(payload: IAssessmentIndicatorCommentCreateUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentIndicatorCommentCreateUpdate(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentIndicatorCommentUpdate(payload))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteIndicatorComment(payload: IAssessmentIndicatorCommentDelete) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentIndicatorCommentDelete(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentIndicatorCommentDelete(payload))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createAssessmentComment(payload: IAssessmentCommentCreateUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                const data = await MyAssessmentAction.assessmentApi.assessmentCommentCreateUpdate(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentCommentCreate({ ...payload, ...data }))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateAssessmentComment(payload: IAssessmentCommentCreateUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentCommentCreateUpdate(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentCommentUpdate(payload))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteAssessmentComment(payload: IAssessmentCommentDelete) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                await MyAssessmentAction.assessmentApi.assessmentCommentDelete(payload)
                dispatch(MyAssessmentPassSlice.actions.assessmentCommentDelete(payload))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentPassChangeStatus(payload: IAssessmentChangeStatusRequest & { finishedAt?: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                void await MyAssessmentAction.assessmentApi.assessmentStatus(payload)
                dispatch(MyAssessmentPassSlice.actions.changeStatus(payload))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public goSummarize(assessmentUuid: string) {
        return async (dispatch: AppDispatch, getState: () => RootState) => {
            const { uuid, lastName, firstName } = getState().userReducer.data
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                void await MyAssessmentAction.assessmentApi.assessmentStatus({
                    uuid: assessmentUuid,
                    statusUuid: assessmentStatusSummarizing,
                })
                dispatch(MyAssessmentPassSlice.actions.setSummarizing({
                    userName: `${lastName} ${Array.from(firstName)[0]}.`,
                    userUuid: uuid,
                }))
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public cancelSummarize(assessmentUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                void await MyAssessmentAction.assessmentApi.assessmentCalibrateCancel(assessmentUuid)
                dispatch(MyAssessmentPassSlice.actions.cancelSummarizing())
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public saveSummarize(assessmentUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(MyAssessmentPassSlice.actions.fetching())
                void await MyAssessmentAction.assessmentApi.assessmentCalibrateDone(assessmentUuid)
                dispatch(MyAssessmentPassSlice.actions.saveSummarizing())
                dispatch(MyAssessmentPassSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(MyAssessmentPassSlice.actions.fetchingError(e))
                throw e
            }
        }
    }


    // results
    public setResult(data: IAssessmentResult) {
        return (dispatch: AppDispatch, getState: () => RootState) => {
            const viewerUuid = getState().userReducer.data?.uuid
            dispatch(assessmentResultSlice.actions.setResult({ ...data, viewerUuid }))
        }
    }
    // results
    public setResultAccordance(data: IAssessmentAccordance[]) {
        return (dispatch: AppDispatch) => {
            dispatch(assessmentResultSlice.actions.setResultAccordance(data))
        }
    }

    public assessmentCreatePdp(assessmentUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(assessmentResultSlice.actions.fetching())
                const { uuid } = await MyAssessmentAction.assessmentApi.assessmentCreatePdp(assessmentUuid)
                dispatch(assessmentResultSlice.actions.fetchingSuccess())
                return uuid
            } catch (e: any) {
                dispatch(assessmentResultSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentResultApprove(payload: IAssessmentChangeStatusRequest & { finishedAt?: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(assessmentResultSlice.actions.fetching())
                void await MyAssessmentAction.assessmentApi.assessmentStatus(payload)
                dispatch(assessmentResultSlice.actions.setApproveResults(payload))
                dispatch(assessmentResultSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(assessmentResultSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
