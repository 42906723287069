import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ICpaDeleteEmployeeRequest,
    ICpaGetEmployeesForAddResponse,
    ICpaGetEmployeesResponse,
} from 'api/typing/cpaTypes'
import { assessmentStatusComplete } from 'helpers/assessment/getAssessmentStatus'


interface ICPAItemEmployeesState {
    isCpaReady: boolean;
    cpaUuid: string;
    currentEmployees: ICpaItemDivision[];
    employeesFlat: Record<string, ICpaItemFlatAssessedEmployees>;
    employeesList: ICpaGetEmployeesForAddResponse[];
    currentEmployeeListSaved: string[];
    employeeListSaved: string[];
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAItemEmployeesState = {
    isCpaReady: false,
    cpaUuid: '',
    currentEmployees: [],
    employeesList: [],
    employeesFlat: {} as Record<string, ICpaItemFlatAssessedEmployees>,
    currentEmployeeListSaved: [],
    employeeListSaved: [],
    isLoading: false,
    error: null,
}

export const CPAItemEmployeesSlice = createSlice({
    name: 'CPAItemEmployees',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setCurrentEmployees: (state, action: PayloadAction<ICpaGetEmployeesResponse>) => {
            state.currentEmployees = action.payload.divisions
            const savedSet = new Set<string>()
            action.payload.divisions.forEach(division => {
                division.employees?.forEach(employee => savedSet.add(employee.uuid))
            })
            state.employeeListSaved = Array.from(savedSet)
            state.currentEmployeeListSaved = Array.from(savedSet)
            state.isCpaReady = action.payload.isCpaReady

            action.payload.divisions.forEach(division => {
                division.employees.forEach(employee => {
                    state.employeesFlat[employee.uuid] = {
                        uuid: employee.uuid,
                        fullName: `${employee.lastName} ${employee.firstName} ${employee.secondName}`,
                        grade: employee.grade,
                        specialization: employee.specialization,
                        divisionUuid: division.uuid,
                        divisionTitle: division.title,
                    }
                })
            })
        },

        deleteEmployee: (state, action: PayloadAction<ICpaDeleteEmployeeRequest>) => {
            const { employeeUuid, divisionUuid } = action.payload
            const divisionIdx = state.currentEmployees.findIndex(el => el.uuid === divisionUuid)
            state.currentEmployees[divisionIdx].employees = state
                .currentEmployees[divisionIdx]
                .employees
                .filter(el => el.uuid !== employeeUuid)

            state.currentEmployeeListSaved = state.currentEmployeeListSaved.filter(el => el !== employeeUuid)
            state.employeeListSaved = state.employeeListSaved.filter(el => el !== employeeUuid)
            if (state.currentEmployees[divisionIdx].employees.length <= 0)
                state.currentEmployees.splice(divisionIdx, 1)
        },

        setEmployeesList: (
            state,
            action: PayloadAction<{ list: ICpaGetEmployeesForAddResponse[]; cpaUuid: string }>,
        ) => {
            state.employeesList = action.payload.list
            state.cpaUuid = action.payload.cpaUuid
        },

        restoreEmployeeList: (state) => {
            state.employeeListSaved = [...state.currentEmployeeListSaved]
        },

        updateEmployeeStatusFromPage: (state, action: PayloadAction<ICpaEmployee>) => {
            state.currentEmployees.some(division => {
                return division.employees.some(employee => {
                    if (employee.uuid !== action.payload.uuid) return false
                    const isNewStatus = action.payload.assessment?.statusUuid !== employee?.assessment?.statusUuid
                    if (isNewStatus && action.payload.assessment?.statusUuid === assessmentStatusComplete)
                        // eslint-disable-next-line no-param-reassign
                        division.progress.completedCount++

                    const needUpdateStatus = action.payload?.assessment?.statusUuid !== employee?.assessment?.statusUuid

                    if (needUpdateStatus && employee.assessment) {
                        // eslint-disable-next-line no-param-reassign
                        employee.assessment.statusUuid = action.payload?.assessment?.statusUuid
                    }

                    return true
                })
            })
        },

        selectAllEmployeeList: (state) => {
            const savedSet = new Set('')
            state.employeesList.forEach(division => {
                division.employees.forEach(employee => {
                    if (employee.assessment?.uuid && employee.assessment.cpaUuid !== state.cpaUuid) return
                    savedSet.add(employee.uuid)
                })
            })
            state.employeeListSaved = Array.from(savedSet)
        },

        removeAllEmployeeList: (state) => {
            const savedSet = new Set(state.employeeListSaved)
            if (savedSet.size <= 0) return

            state.employeeListSaved = []
        },

        setSelectStateDivision: (state, action: PayloadAction<{ divisionUuid: string; isSelected: boolean }>) => {
            const savedSet = new Set(state.employeeListSaved)

            state.employeesList.forEach(division => {
                if (division.uuid !== action.payload.divisionUuid) return
                division.employees.forEach(employee => {
                    if (employee.assessment?.uuid && employee.assessment.cpaUuid !== state.cpaUuid) return
                    action.payload.isSelected ? savedSet.add(employee.uuid) : savedSet.delete(employee.uuid)
                })
            })

            state.employeeListSaved = Array.from(savedSet)
        },

        setSelectStateEmployee: (state, action: PayloadAction<{ employeeUuid: string; isSelected: boolean }>) => {
            const savedSet = new Set(state.employeeListSaved)

            action.payload.isSelected
                ? savedSet.add(action.payload.employeeUuid)
                : savedSet.delete(action.payload.employeeUuid)

            state.employeeListSaved = Array.from(savedSet)
        },

        saveEmployeesToCpa: (state) => {
            state.currentEmployeeListSaved = [...state.employeeListSaved]
        },

        setParticipantCount: (state, action: PayloadAction<{ employeeUuid: string; participantCount: number }>) => {
            state.currentEmployees.some(division => {
                return division.employees.some(el => {
                    if (el.uuid !== action.payload.employeeUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    el.participantCount = action.payload.participantCount
                })
            })
        },
    },
})

export const CPAItemEmployeesReducer = CPAItemEmployeesSlice.reducer
