import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { authActions } from 'store/actions'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Stack, Typography } from '@mui/material'
import {
    HINT_ERROR_PASSWORD,
    HINT_ERROR_PASSWORD_CONFIRM,
    HINT_ERROR_REQUIRED,
    HINT_PASSWORD_RULE,
} from 'helpers/enums/hints'
import { PATTERN_PASSWORD } from 'helpers/enums/regexPatterns'
import { InputPassword } from 'components/form/InputPassword/InputPassword'
import { TextLink } from 'components/common/TextLink/TextLink'
import { Loading } from 'components/Loading/Loading'
import { PATH_HOME, PATH_LOGIN } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_AUTH,
    TITLE_BUTTON_SAVE_PASSWORD,
    TITLE_FIELD_NEW_PASSWORD,
    TITLE_FIELD_PASSWORD_CONFIRM,
} from 'helpers/enums/titles'
import { FormRecoverOldLink } from 'pages/login/LoginPage/components/FormRecover/components/FormRecoverOldLink'
import { useNotify } from 'store/hooks/useNotify'
import { LoadingButton } from '@mui/lab'


const defaultValues = {
    password: '',
    passwordConfirm: '',
}

type FormValues = {
    password: string;
    passwordConfirm: string;
}

export const FormRecover: FC = () => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const navigate = useNavigate()
    const { hash } = useParams()
    const [loading, setLoading] = useState(false)
    const { data: { hashVerified }, isLoading } = useAppSelector((state) => state.authReducer)

    const { handleSubmit, getValues, control, setError, formState: { errors } }
        = useForm<FormValues>({ defaultValues })

    const setCustomError = () => {
        setError('password', { type: 'manual' })
        setError('passwordConfirm', { type: 'manual' })
    }

    useEffect(() => {
        dispatch(authActions.clearError())
        hash && dispatch(authActions.verifyHash({ hash }))
    }, [hash])

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        if (!hash) {
            setCustomError()
            return
        }
        try {
            setLoading(true)
            await dispatch(authActions.passwordRestore({ ...data, hash }))
            navigate(PATH_HOME)
        } catch (e: any) {
            setCustomError()
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    if (isLoading && !hashVerified) return <Loading/>
    if (!hashVerified && !isLoading) return <FormRecoverOldLink/>

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate width="-webkit-fill-available">
            <Stack spacing={4}>
                <Stack spacing={3}>
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            minLength: { value: 8, message: HINT_ERROR_PASSWORD },
                            pattern: {
                                value: PATTERN_PASSWORD,
                                message: HINT_ERROR_PASSWORD,
                            },
                        }}
                        render={({ field }) => (
                            <InputPassword
                                {...field}
                                id="password"
                                name="password"
                                title={TITLE_FIELD_NEW_PASSWORD}
                                disabled={loading}
                                autoComplete="current-password"
                                error={!!errors.password}
                                helperText={errors?.password ? errors.password.message : null}
                            />
                        )}
                    />
                    <Controller
                        name="passwordConfirm"
                        control={control}
                        rules={{
                            required: HINT_ERROR_REQUIRED,
                            minLength: { value: 8, message: HINT_ERROR_PASSWORD },
                            validate: (match) => {
                                const password = getValues('password')
                                return match === password || HINT_ERROR_PASSWORD_CONFIRM
                            },
                        }}
                        render={({ field }) => (
                            <InputPassword
                                {...field}
                                id="passwordConfirm"
                                title={TITLE_FIELD_PASSWORD_CONFIRM}
                                autoComplete="current-password"
                                disabled={loading}
                                error={!!errors.passwordConfirm}
                                helperText={errors?.passwordConfirm ? errors.passwordConfirm.message : null}
                            />
                        )}
                    />
                    <Typography variant="body1">
                        {HINT_PASSWORD_RULE}
                    </Typography>
                </Stack>
                <Stack spacing={3}>
                    <LoadingButton
                        disabled={loading}
                        loading={loading}
                        type="submit"
                        variant="contained"
                    >
                        {TITLE_BUTTON_SAVE_PASSWORD}
                    </LoadingButton>
                    <Box alignSelf="center">
                        <TextLink onCustomClickEvent={() => navigate(PATH_LOGIN)}>
                            {TITLE_BUTTON_AUTH}
                        </TextLink>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

