import React, { FC, useMemo } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Chip } from '@mui/material'
import { IExamPassMark, IExamResult, MassExamPassMarkCode } from '../../../api/typing/massExam'
import { addDays, isPast, parse } from 'date-fns'
import { isValidDateFromApi } from '../../../helpers/dateAndTime/isValidDateFromApi'
import { ExamStatusCode, getExamStatusChip } from '../../../helpers/exam/examStatuses'

interface ExamResultChipProps {
    examResult: IExamResult | null;
    passMark: IExamPassMark | null;
    endedAt: string;
    statusUuid: string;
    onClickOnChip?: () => void;
}

export const ExamResultChip: FC<ExamResultChipProps> = ({
    examResult,
    passMark,
    onClickOnChip,
    endedAt,
    statusUuid,
}) => {
    const isValidDate = isValidDateFromApi(endedAt)
    const examStatus = getExamStatusChip(statusUuid)
    const completeStatus = examStatus.code === ExamStatusCode.DONE
    const isExpire = isValidDate && !completeStatus && isPast(addDays(parse(endedAt, 'dd.MM.yyyy', new Date()), 1))

    const examResultLabel = useMemo(() => {
        if (passMark?.code === MassExamPassMarkCode.TOTAL_POINTS) {
            return examResult?.score
        }
        return `${examResult?.percent}%`
    }, [passMark, examResult])

    const resultIconColor = useMemo(() => {
        switch (true) {
            case !passMark:
                return 'primary'
            case isExpire:
                return 'warning'
            case examResult && examResult.isPassed:
                return 'success'
            case examResult && !examResult.isPassed:
                return 'error'
            default:
                return 'primary'
        }
    }, [isExpire, examResult])
    return (
        <Chip
            label={examResultLabel}
            variant='outlined'
            color={resultIconColor}
            deleteIcon={<ArrowForwardIosIcon color={resultIconColor} sx={{width: '13px', height: '13px'}}/>}
            onDelete={onClickOnChip}
            onClick={onClickOnChip}
        />
    )
}
