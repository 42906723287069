export const TITLE_DRAWER_ADD_COMPETENCE_IN_PDP = 'Добавление компетенций в план развития сотрудника'
export const TITLE_DRAWER_ADD_COMPETENCE_IN_PDP_SHORT = 'Компетенции из базы'
export const TITLE_DRAWER_ADD_MATERIALS_IN_PDP = 'Добавление материалов из базы компетенций'
export const TITLE_DRAWER_ADD_MATERIALS_IN_PDP_MOBILE = 'Материалы из базы'

export const TITLE_DIALOG_EDIT_COMPETENCE = 'Редактирование компетенции'
export const TITLE_DIALOG_DELETE_COMPETENCE = 'Удаление компетенции'
export const TITLE_DIALOG_EDIT_COMPETENCE_GROUP = 'Редактирование группы компетенций'
export const TITLE_DIALOG_DELETE_COMPETENCE_GROUP = 'Редактирование группы компетенций'
export const TITLE_DIALOG_EDIT_RECORD = 'Редактирование записи'
export const TITLE_DIALOG_CREATE_RECORD = 'Создание новой записи'
export const TITLE_DIALOG_CREATE_MATERIAL = 'Создание нового материала'
export const TITLE_DIALOG_EDIT_MATERIAL = 'Редактирование материала'
export const TITLE_DIALOG_DELETE_MATERIAL = 'Удаление материала'
export const TITLE_DIALOG_DELETE_MATERIAL_WITH_PDP_ITEM = 'Удаление материала вместе пунктом плана'

export const TITLE_DIALOG_PDP_CREATE = 'Создание индивидуального плана развития'
export const TITLE_DIALOG_PDP_INSTRUCTION = 'Инструкция по работе с планом развития'
export const TITLE_DIALOG_PDP_EDIT = 'Редактирование индивидуального плана развития'
export const TITLE_DIALOG_PDP_POINT = 'Срок завершения и цель к плану развития'
export const TITLE_DIALOG_PDP_DELETE = 'Удаление индивидуального плана развития'
export const TITLE_DIALOG_PDP_ITEM_DELETE = 'Удаление пункта плана развития'
export const TITLE_DIALOG_PDP_ITEM_COMMENT = 'Комментарий к пункту плана развития'
export const TITLE_DIALOG_PDP_ITEM_CREATE = 'Добавление пункта плана развития сотрудника'
export const TITLE_DIALOG_PDP_ITEM_CREATE_MOBILE = 'Добавление пункта плана развития'
export const TITLE_DIALOG_PDP_ITEM_EDIT = 'Редактирование пункта плана развития'
export const TITLE_DIALOG_PDP_SEND_TO_EMPLOYEE = 'Отправка плана развития сотруднику'
export const TITLE_DIALOG_PDP_COMPLETE = 'Завершение плана развития сотрудника'
export const TITLE_DIALOG_PDP_SEND_TO_REVIEWER = 'Отправка плана развития руководителю'
export const TITLE_DIALOG_PDP_CANCEL = 'Отмена индивидуального плана развития'
export const TITLE_DIALOG_PDP_ROLLBACK = 'Возврат плана развития сотруднику'
export const TITLE_DIALOG_CONFIRM_ACTION = 'Подтвердите действие'
export const TITLE_DIALOG_PDP_ESTIMATED_COMPLETION_DATE = 'Рекомендации по сроку завершения'

export const TITLE_DIALOG_DELETE_RECORD = 'Удаление записи'
export const TITLE_DIALOG_DELETE_SPECIALIZATION = 'Удаление специализации'
export const TITLE_DIALOG_DELETE_GRADE = 'Удаление грейда'

export const TITLE_DIALOG_SAVE_CHANGES = 'Сохранение изменений'
export const TITLE_DIALOG_SAVE_RESULTS = 'Сохраненить результаты'
export const TITLE_DIALOG_ASSESSMENT_RESULT_INDICATORS_COMMENTS = 'Комментарии к индикаторам компетенции'
export const TITLE_DIALOG_COMMENT_FOR_MATERIAL = 'Комментарий к материалу'
export const TITLE_DIALOG_SPECIALIZATION_ADD = 'Добавление специализации'
export const TITLE_DIALOG_SPECIALIZATION_EDIT = 'Редактирование специализации'
export const TITLE_DIALOG_GRADE_EDIT = 'Редактирование грейда'
export const TITLE_DIALOG_GRADE_ADD = 'Создание грейда'
export const TITLE_DIALOG_EMPLOYEE_ADD = 'Добавление сотрудника'
export const TITLE_DIALOG_EMPLOYEE_INVITE = 'Приглашение сотрудника'
export const TITLE_DIALOG_APPROVE_ASSESSMENT = 'Утверждение результатов оценки'

export const TITLE_DIALOG_ASSESSMENT_INSTRUCTION = 'Инструкция по оценке компетенций'

export const TITLE_DIALOG_CREATE_ASSESSMENT = 'Создание оценки'
export const TITLE_DIALOG_UPDATE_ASSESSMENT = 'Редактирование оценки'
export const TITLE_DIALOG_DELETE_ASSESSMENT = 'Удаление оценки'
export const TITLE_DIALOG_CANCEL_ASSESSMENT = 'Отмена оценки'
export const TITLE_DIALOG_RUN_ASSESSMENT = 'Запуск оценки'
export const TITLE_DIALOG_RUN_MASS_EXAM = 'Запуск тестирования'

export const TITLE_MAIN_MENU_HR = 'Кабинет HR-специалиста'
export const TITLE_MAIN_MENU_MANAGER = 'Кабинет руководителя'
export const TITLE_MAIN_MENU_EMPLOYEE = 'Кабинет сотрудника'

export const TITLE_NO_RECORDS_YET_RECORD = 'Еще не добавлена ни одна запись'
export const TITLE_NO_RECORDS_INCORRECT_FILTERS = 'Записей не найдено, попробуйте изменить фильтры'

export const TITLE_PAGE_DICTIONARY_ROLE = 'Роли компании'
export const TITLE_PAGE_USERS = 'Пользователи компании'
export const TITLE_PAGE_PERMISSIONS = 'Права доступа'
export const TITLE_PAGE_COMPETENCE_CONFIG = 'Настройка компетенций'
export const TITLE_PAGE_COMPETENCE_CONFIG_ADD = 'Добавление компетенций'
export const TITLE_PAGE_ASSESSMENT_180 = 'Оценка компетенций'
export const TITLE_PAGE_ASSESSMENT_RESULT = 'Результат оценки компетенций'
export const TITLE_PAGE_ANALYTIC = 'Аналитика'
export const TITLE_PAGE_EMPLOYEES = 'Сотрудники'
export const TITLE_PAGE_HISTORY = 'Моя история'
// export const TITLE_PAGE_EMPLOYEES_DEPARTMENT = 'Сотрудники подразделения'
export const TITLE_PAGE_SPECIALIZATIONS = 'Специализации'
export const TITLE_PAGE_EMPLOYEE_EDIT = 'Редактирование профиля сотрудника'
export const TITLE_PAGE_PROFILE_EDIT = 'Редактирование профиля'
export const TITLE_PAGE_DEVELOPMENT = 'Мое развитие'
export const TITLE_PAGE_MY_ASSESSMENT = 'Мои оценки'
export const TITLE_PAGE_MY_EXAMS = 'Мои тесты'
export const TITLE_PAGE_EXAM = 'Тестирование'

export const TITLE_EMPTY_PAGE_GRADE = 'Компетенции для данного грейда еще не настроены'
export const TITLE_EMPTY_PAGE_EMPLOYEE_HISTORY = 'Здесь будет отображаться история развития сотрудника'
export const TITLE_EMPTY_PAGE_MY_HISTORY = 'История пока пустая. Скоро здесь появятся события, произошедшие с Вами в системе'
export const TITLE_EMPTY_SPECIALIZATIONS = 'В вашем подразделении ещё нет специализаций'
export const TITLE_EMPTY_SPECIALIZATION = 'Для настройки специализации добавьте хотя бы один грейд'
export const TITLE_EMPTY_SPECIALIZATION_COMPETENCE_SETUP = 'В этой специализации ещё нет компетенций'
export const TITLE_EMPTY_PAGE_EMPLOYEE_COMPETENCIES = 'Здесь будут отображаться компетенции после назначения специализации и грейда'
export const TITLE_EMPTY_PAGE_EMPLOYEE_PDP_USER_DATA = 'Работа с разделом станет доступна после заполнения информации о сотруднике'
export const TITLE_EMPTY_PAGE_EMPLOYEE_PDP = 'У сотрудника нет активного плана развития'
export const TITLE_EMPTY_PAGE_DEVELOPMENT_PDP_NOT_OLD_EXIST = 'План развития еще не создан'
export const TITLE_EMPTY_PAGE_DEVELOPMENT_PDP_OLD_EXIST = 'Хотите продолжить обучение?'

export const TITLE_EMPTY_PAGE_HAS_NOT_CURRENT_POSITION = 'У вас не назначена текущая позиция'
export const TITLE_EMPTY_PAGE_PDP_CREATING = 'План развития создается'
export const TITLE_EMPTY_PAGE_WANT_TO_CONTINUE = 'Хотите продолжить обучение?'

export const TITLE_BUTTON_CANCEL = 'Отменить'
export const TITLE_BUTTON_COMPLETE = 'Завершить'
export const TITLE_BUTTON_COMPLETED = 'Завершен'
export const TITLE_BUTTON_ROLLBACK = 'Вернуть'
export const TITLE_BUTTON_LATER = 'Позже'
export const TITLE_BUTTON_SAVE = 'Сохранить'
export const TITLE_BUTTON_ADD = 'Добавить'
export const TITLE_BUTTON_OPEN = 'Открыть'
export const TITLE_BUTTON_OK = 'ОК'
export const TITLE_BUTTON_SEND = 'Отправить'
export const TITLE_BUTTON_CREATE = 'Создать'
export const TITLE_BUTTON_EDIT = 'Редактировать'
export const TITLE_BUTTON_DELETE = 'Удалить'
export const TITLE_BUTTON_DELETE_ALL = 'Удалить все'
export const TITLE_BUTTON_MORE = 'Подробнее'
export const TITLE_BUTTON_CONFIRM = 'Подтвердить'
export const TITLE_BUTTON_NOTIFY = 'Напомнить'
export const TITLE_BUTTON_APPROVE = 'Утвердить'
export const TITLE_BUTTON_GO = 'Перейти'
export const TITLE_BUTTON_START = 'Начать'
export const TITLE_BUTTON_RUN = 'Запустить'
export const TITLE_BUTTON_SELECT = 'Выбрать'
export const TITLE_BUTTON_CLEAR = 'Очистить'
export const TITLE_BUTTON_COLLAPSE = 'Свернуть'
export const TITLE_BUTTON_CHANGE = 'Изменить'
export const TITLE_BUTTON_SELECT_ALL = 'Выбрать все'
export const TITLE_BUTTON_REMOVE_SELECTED = 'Снять выбранные'
export const TITLE_BUTTON_FILTER = 'Фильтр'
export const TITLE_BUTTON_RESET = 'Сбросить'
export const TITLE_BUTTON_SHOW = 'Показать'
export const TITLE_BUTTON_APPLY = 'Применить'
export const TITLE_DELETE_COMMENT = 'Удалить комментарий'
export const TITLE_BUTTON_RESULTS_EXPORT = 'Экспорт результатов'
export const TITLE_BUTTON_DATA_EXPORT = 'Экспорт данных'

export const TITLE_BUTTON_PRINT_DOWNLOAD = 'Печать/Сохранить'
export const TITLE_BUTTON_ADD_FROM_DATABASE = 'Добавить из базы'

export const TITLE_BUTTON_RECOVER_PASSWORD = 'Восстановить пароль'
export const TITLE_BUTTON_AUTH = 'Авторизация'
export const TITLE_BUTTON_LOG_IN = 'Войти'
export const TITLE_BUTTON_FORGOT_PASSWORD = 'Забыли пароль?'
export const TITLE_BUTTON_SAVE_PASSWORD = 'Сохранить пароль'
export const TITLE_BUTTON_SUBMIT_CHANGES = 'Сохранить изменения'
export const TITLE_BUTTON_CONTINUE_NOW = 'Продолжить сейчас'
export const TITLE_BUTTON_EXPAND_ALL = 'Раскрыть все'
export const TITLE_BUTTON_COLLAPSE_ALL = 'Свернуть все'
export const TITLE_BUTTON_VIEW_MORE = 'Смотреть еще'
export const TITLE_BUTTON_UPLOAD_PHOTO = 'Загрузить фото'
export const TITLE_BUTTON_INPUT_DATE_END = 'Ввести дату окончания'
export const TITLE_BUTTON_FILL_PROFILE = 'Заполнить профиль'
export const TITLE_BUTTON_CREATE_PDP = 'Создать план развития'
export const TITLE_BUTTON_SEND_INVITE = 'Отправить приглашение'
export const TITLE_BUTTON_SEND_TO_REVIEW = 'Отправить на проверку'
export const TITLE_BUTTON_SEND_TO_EMPLOYEE = 'Отправить сотруднику'
export const TITLE_BUTTON_SEND_TO_MANAGER = 'Отправить руководителю'
export const TITLE_BUTTON_SEND_TO_APPROVAL = 'Отправить на согласование'
export const TITLE_BUTTON_SEND_TO_APPROVAL_SHORT = 'На согласование'
export const TITLE_BUTTON_CREATE_FOR_CURRENT = 'Создать для текущего'
export const TITLE_BUTTON_CREATE_MATERIAL = 'создать материал'
export const TITLE_BUTTON_ADD_POINT_AND_ENDED_AT = 'Добавить срок завершения и цель'
export const TITLE_BUTTON_CHANGE_POINT_AND_ENDED_AT = 'Изменить срок завершения и цель'
export const TITLE_BUTTON_CANCEL_PLAN = 'Отменить план'
export const TITLE_BUTTON_CANCEL_ASSESSMENT = 'Отменить оценку'
export const TITLE_BUTTON_ROLLBACK_TO_EMPLOYEE = 'вернуть сотруднику'
export const TITLE_BUTTON_COMMENT_PDP = 'Комментарий к плану'
export const TITLE_BUTTON_COMMENT_REVIEWER = 'Комментарий проверяющего'
export const TITLE_BUTTON_GO_TO_ASSESSMENT = 'К опросу'
export const TITLE_BUTTON_ALL_COMPETENCIES = 'Все компетенции'

export const TITLE_BUTTON_OPEN_LATEST_PDP = 'Открыть последний план развития'
export const TITLE_BUTTON_OPEN_LATEST_PDP_MOBILE = 'Открыть последний'

export const TITLE_BUTTON_TO_CALIBRATE = 'К калибровке'
export const TITLE_BUTTON_BACK_TO_DICTIONARY = 'Ко всем справочникам'
export const TITLE_BUTTON_BACK_TO_SPECIALIZATIONS = 'Ко всем специализациям'
export const TITLE_BUTTON_BACK_TO_GRADE = 'К грейду'
export const TITLE_BUTTON_BACK_TO_EMPLOYEE = 'К профилю сотрудника'
export const TITLE_BUTTON_BACK_TO_PROFILE = 'К профилю'
export const TITLE_BUTTON_BACK_TO_SPECIALIZATION = 'К специализации'
export const TITLE_BUTTON_BACK_TO_SPECIALIZATION_CONFIG = 'К настройке компетенции'
export const TITLE_BUTTON_BACK_TO_ALL_EMPLOYEES = 'Ко всем сотрудникам'
export const TITLE_BUTTON_BACK_TO_EMPLOYEES = 'К сотрудникам'
export const TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN = 'К плану развития'
export const TITLE_BUTTON_BACK_TO_EDIT_PDP = 'К редактированию плана развития'
export const TITLE_BUTTON_BACK_HISTORY = 'К списку событий'
export const TITLE_BUTTON_BACK_TO_ASSESSMENTS = 'Ко всем оценкам'
export const TITLE_BUTTON_BACK_TO_ASSESSMENT = 'К оценке'
export const TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT = 'К результату оценки'
export const TITLE_BUTTON_BACK_TO_SCALES = 'Ко всем шкалам'
export const TITLE_BUTTON_BACK_TO_MASS_EXAM_LIST = 'Ко всем тестированиям'
export const TITLE_BUTTON_BACK_TO_MASS_EXAM = 'К тестированию'

export const TITLE_BUTTON_ADD_GRADE = 'Добавить грейд'
export const TITLE_BUTTON_ADD_COMPETENCE_FROM_BASE = 'Добавить компетенции из базы'
export const TITLE_BUTTON_ADD_SELECTED = 'Добавить выбранные'
export const TITLE_BUTTON_ADD_MATERIAL = 'Добавить материал'


export const TITLE_BUTTON_CONFIGURE_COMPETENCE = 'Настроить компетенции'
export const TITLE_BUTTON_COPY_LINK_DEVELOPMENT_PLAN = 'Копировать ссылку на план развития'
export const TITLE_BUTTON_COPY_LINK_ASSESSMENT = 'Копировать ссылку на оценку'
export const TITLE_BUTTON_INSTRUCTION = 'Инструкция'
export const TITLE_BUTTON_END_ASSESSMENT = 'Завершить оценку'
export const TITLE_BUTTON_ASSESSMENT_INDICATOR_COMMENT = 'Комментарий к ответу'
export const TITLE_BUTTON_ASSESSMENT_SHARED_COMMENT = 'Общий комментарий'
export const TITLE_BUTTON_ASSESSMENT_RESULT_DETAILED_RESULT = 'Подробные результаты'
export const TITLE_BUTTON_DISPLAY_DATA = 'Отобразить данные'
export const TITLE_BUTTON_OPEN_MATERIAL = 'Открыть материал'
export const TITLE_BUTTON_PERFORM_ASSESSMENT = 'Выполнить оценку'
export const TITLE_BUTTON_CREATE_DEVELOPMENT_PLAN = 'Создать план развития'
export const TITLE_BUTTON_ADD_OR_UPDATE_COMMENT = 'Оставить/изменить комментарий'
export const TITLE_BUTTON_SEND_RESULTS = 'Отправить результаты'
export const TITLE_BUTTON_SAVE_CHANGES = 'Сохранить изменения'
export const TITLE_BUTTON_CORRECT_ANSWERS = 'Скорректировать ответы'
export const TITLE_BUTTON_APPROVE_ASSESSMENT = 'Утвердить оценку'
export const TITLE_BUTTON_GO_TO_APPROVE = 'Перевести на утверждение'
export const TITLE_BUTTON_MAKE_ADJUSTMENTS = 'Внести корректировки'
export const TITLE_BUTTON_CALIBRATE_RESULT = 'Откалибровать результат'
export const TITLE_BUTTON_CORRECT_RESULT = 'Скорректировать результат'
export const TITLE_BUTTON_MAKE_PDP_FORM_ASSESSMENT = 'Сформировать план развития'
export const TITLE_BUTTON_GO_TO_PDP = 'Перейти к плану развития'

export const TITLE_BUTTON_CREATE_ASSESSMENT = 'Создать оценку'
export const TITLE_BUTTON_CREATE_SELF_ASSESSMENT = 'Создать самооценку'
export const TITLE_BUTTON_PRELIMINARY_RESULTS = 'Предварительные результаты'
export const TITLE_BUTTON_PRELIMINARY_ASSESSMENT_RESULTS = 'Предварительные результаты оценки'
export const TITLE_BUTTON_PREVIEW_QUESTIONS = 'Предпросмотр вопросов'
export const TITLE_BUTTON_PREVIEW = 'Предпросмотр'
export const TITLE_BUTTON_ASSESSMENT_RESULTS = 'Результаты оценки'
export const TITLE_BUTTON_ASSESSMENT_START = 'Запустить оценку'
export const TITLE_BUTTON_ASSESSMENT_GO_TO = 'Перейти к оценке'

export const TITLE_BUTTON_CREATE_EXAM = 'Создать тестирование'

export const TITLE_CHECKBOX_COMPETENCE_GROUP_FORBID = 'Запретить редактировать клиенту на этом уровне'
export const TITLE_CHECKBOX_ACTIVE_WILL_SEE_ALL = 'Активна (будет видна сотрудникам, руководителям, HR-специалистам)'
export const TITLE_CHECKBOX_REMEMBER_ME = 'Запомнить меня'

export const TITLE_NAMESPACE_EXIT = 'Выйти'
export const TITLE_NAMESPACE_RECORD = 'Запись'
export const TITLE_NAMESPACE_YES = 'Да'
export const TITLE_NAMESPACE_NO = 'Нет'
export const TITLE_NAMESPACE_STATUS = 'Статус'
export const TITLE_NAMESPACE_PERMISSIONS = 'Права доступа'
export const TITLE_NAMESPACE_ACTIVITY = 'Активность'
export const TITLE_NAMESPACE_ALL_COUNTS = 'Всего:'
export const TITLE_NAMESPACE_ALL = 'Все'
export const TITLE_NAMESPACE_ALL_GRADES = 'Все грейды'
export const TITLE_NAMESPACE_IN = 'В'
export const TITLE_NAMESPACE_REQUIREMENTS = 'Требования'
export const TITLE_NAMESPACE_SALARY = 'Оклад'
export const TITLE_NAMESPACE_COMPETENCIES = 'Компетенции'
export const TITLE_NAMESPACE_COMPETENCIES_GENITIVE = 'Компетенций'
export const TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE = 'Компетенция'
export const TITLE_NAMESPACE_COMPETENCE_ACCUSATIVE = 'Компетенцию'
export const TITLE_NAMESPACE_GROUP_OF_COMPETENCIES = 'Группа компетенций'
export const TITLE_NAMESPACE_GRADES = 'Грейды'
export const TITLE_NAMESPACE_GRADE = 'Грейд'
export const TITLE_NAMESPACE_DEVELOPMENT_PLAN_FROM_GRADE = 'План развития по грейду'
export const TITLE_NAMESPACE_ASSESSMENT_FROM_GRADE = 'Оценка на грейд'
export const TITLE_NAMESPACE_INDIVIDUAL_DEVELOPMENT_PROGRESS = 'Текущее развитие'
export const TITLE_NAMESPACE_ASSESSMENTS = 'Оценки'
export const TITLE_NAMESPACE_INFO_ABOUT_EMPLOYEE = 'Информация о сотруднике'
export const TITLE_NAMESPACE_INFO_ABOUT_SELF = 'Информация обо мне'
export const TITLE_NAMESPACE_HISTORY = 'История'
export const TITLE_NAMESPACE_DEVELOPMENT_PLAN = 'План развития'
export const TITLE_NAMESPACE_PROGRESS_COMPLETE = 'Прогресс прохождения'
export const TITLE_NAMESPACE_CREATED_DEVELOPMENT_PLAN = 'Созданный индивидуальный план развития'
export const TITLE_NAMESPACE_PLAN_ITEM = 'Пункт плана'
export const TITLE_NAMESPACE_PERCENTAGE_DEVELOPMENT = '% освоения'
export const TITLE_NAMESPACE_STATUSES_PLAN_DEVELOPMENT = 'Статусы планов развития'
export const TITLE_NAMESPACE_TEAM_COMPETENCIES = 'Компетенции команды'
export const TITLE_NAMESPACE_BRIEF_OF_DEVELOPMENT_PLANS = 'Сводка по планам развития'
export const TITLE_NAMESPACE_SELECTED_COMPETENCE_COUNT = 'Компетенций выбрано'
export const TITLE_NAMESPACE_SELECTED_EMPLOYEES_COUNT = 'Сотрудников выбрано'
export const TITLE_NAMESPACE_SELECTED_PARTICIPANTS_COUNT = 'Участников выбрано'
export const TITLE_NAMESPACE_SELECTED_MATERIALS_COUNT = 'Материалов выбрано'
export const TITLE_NAMESPACE_SKILL_LEVEL = 'Уровень владения'
export const TITLE_NAMESPACE_LEVEL = 'Уровень'
export const TITLE_NAMESPACE_STUDY_TIME = 'Время изучения'
export const TITLE_NAMESPACE_MATERIAL = 'Материал'
export const TITLE_NAMESPACE_COMMENT = 'Комментарий'
export const TITLE_NAMESPACE_SHARED_COMMENT = 'Общий комментарий'
export const TITLE_NAMESPACE_STRONG_SIDES = 'Сильные стороны'
export const TITLE_NAMESPACE_WEAK_SIDES = 'Зоны для роста'
export const TITLE_NAMESPACE_COMMENTS = 'Комментарии'
export const TITLE_NAMESPACE_SHARED_COMMENTS = 'Общие комментарии'
export const TITLE_NAMESPACE_SHARED_COMMENTS_ASSESSMENT = 'Общие комментарии к оценке'
export const TITLE_NAMESPACE_FORMAT = 'Формат'
export const TITLE_NAMESPACE_LINK = 'Ссылка'
export const TITLE_NAMESPACE_EMPLOYEES_CURRENT = 'Текущий состав'
export const TITLE_NAMESPACE_EMPLOYEES_EX = 'Бывшие сотрудники'
export const TITLE_NAMESPACE_EMPLOYEES_RESERVE = 'Резерв'
export const TITLE_NAMESPACE_EMPLOYEE_EDIT_PERSONAL_DATA = 'Личные данные'

export const TITLE_NAMESPACE_EMPLOYEE_EDIT_WORK_IN_COMPANY = 'Работа в компании'
export const TITLE_NAMESPACE_EMPLOYEE_EDIT_INCOME_GENERATION = 'Формирование дохода'
export const TITLE_NAMESPACE_EMPLOYEE_EDIT_PREVIOUS_WORK_EXPERIENCE = 'Предыдущий опыт работы'
export const TITLE_NAMESPACE_EMPLOYEE_EDIT_EDUCATION = 'Образование'
export const TITLE_NAMESPACE_SPECIALIZATION = 'Специализация'
export const TITLE_NAMESPACE_SPECIALIZATIONS = 'Специализации'

export const TITLE_NAMESPACE_SPECIALIZATION_ACCUSATIVE = 'Специализацию'
export const TITLE_NAMESPACE_DIVISION = 'Подразделение'

export const TITLE_NAMESPACE_DIVISIONS = 'Подразделения'
export const TITLE_NAMESPACE_EMPLOYEES_GENITIVE = 'Сотрудников'
export const TITLE_NAMESPACE_GRADES_GENITIVE = 'Грейдов'
export const TITLE_NAMESPACE_EMPLOYEES = 'Сотрудники'

export const TITLE_NAMESPACE_EMPLOYEE = 'Сотрудник'
export const TITLE_NAMESPACE_MANAGER = 'Руководитель'

export const TITLE_NAMESPACE_MATERIALS = 'Материалы'
export const TITLE_NAMESPACE_KNOWLEDGE_BASE = 'База знаний'
export const TITLE_NAMESPACE_TITLE = 'Название'
export const TITLE_NAMESPACE_THEORY = 'Теория'
export const TITLE_NAMESPACE_PRACTICE = 'Практика'
export const TITLE_NAMESPACE_FEEDBACK = 'Обратная связь'
export const TITLE_NAMESPACE_TYPE = 'Тип'
export const TITLE_NAMESPACE_BOOK = 'Книга'
export const TITLE_NAMESPACE_COURSE = 'Курс'
export const TITLE_NAMESPACE_DOCUMENTATION = 'Документация'
export const TITLE_NAMESPACE_VIDEO = 'Видео'
export const TITLE_NAMESPACE_WEBINAR = 'Вебинар'
export const TITLE_NAMESPACE_ARTICLE = 'Статья'
export const TITLE_NAMESPACE_TASK = 'Задача'
export const TITLE_NAMESPACE_PROJECT = 'Проект'
export const TITLE_NAMESPACE_MASTER_CLASS = 'Мастер-класс'
export const TITLE_NAMESPACE_TRAINER = 'Тренажер'
export const TITLE_NAMESPACE_COMMUNICATION = 'Общение'
export const TITLE_NAMESPACE_INTERVIEW = 'Собеседование'
export const TITLE_NAMESPACE_MENTORING = 'Наставничество'
export const TITLE_NAMESPACE_TRAINING = 'Тренинг'
export const TITLE_NAMESPACE_EXERCISE = 'Упражнение'
export const TITLE_NAMESPACE_SIMULATOR = 'Симулятор'
export const TITLE_NAMESPACE_OTHER = 'Другое'
export const TITLE_NAMESPACE_GROUPS = 'Группы'

export const TITLE_NAMESPACE_PERMISSION_VIEW = 'Просмотр ✱'
export const TITLE_NAMESPACE_PERMISSION_VIEW_ALL = 'Просмотр'
export const TITLE_NAMESPACE_PERMISSION_EDIT = 'Управление ✱'
export const TITLE_NAMESPACE_PERMISSION_EDIT_ALL = 'Управление'
export const TITLE_NAMESPACE_PERMISSION_DENIED = 'Нет доступа'

export const TITLE_NAMESPACE_INDICATORS = 'Индикаторы'
export const TITLE_NAMESPACE_INDICATOR = 'Индикатор'
export const TITLE_NAMESPACE_WEIGHT = 'Вес'
export const TITLE_NAMESPACE_NOT_ADDED = 'Не добавлены'
export const TITLE_NAMESPACE_NOT_SELECTED_YET = 'Еще не выбраны'
export const TITLE_NAMESPACE_ADDED = 'Добавлен'
export const TITLE_NAMESPACE_WORK_EXPERIENCE_IN_COMPANY = 'Стаж в компании'

export const TITLE_NAMESPACE_CONTENT_OF_PLAN = 'Содержание плана'

export const TITLE_NAMESPACE_CURRENT_POSITION = 'Текущая позиция'
export const TITLE_NAMESPACE_CURRENT_SPECIALIZATION_EMPLOYEES = 'Текущие специализации сотрудников'
export const TITLE_NAMESPACE_CURRENT_POSITION_EMPLOYEES = 'Текущие позиции сотрудников'
export const TITLE_NAMESPACE_CPA_SCALE_NUMERIC = 'Числовая'
export const TITLE_NAMESPACE_CPA_SCALE_RANGE = 'Диапазон'
export const TITLE_NAMESPACE_INDIVIDUAL_COMPETENCIES = 'Отдельные компетенции'
export const TITLE_NAMESPACE_POINT_POSITION = 'Целевая позиция'
export const TITLE_NAMESPACE_POINT = 'Цель'
export const TITLE_NAMESPACE_ENDED_TO = 'Завершить к'
export const TITLE_NAMESPACE_NOT_COMPLETED = 'Не завершен'
export const TITLE_NAMESPACE_DONE = 'Выполнено'
export const TITLE_NAMESPACE_COMPLETED = 'Завершено'
export const TITLE_NAMESPACE_CURRENT = 'Текущий'
export const TITLE_NAMESPACE_PREVIOUS_POSITION_IN_COMPANY = 'Предыдущие позиции в компании'
export const TITLE_NAMESPACE_POSITION_IN_COMPANY = 'Позиции в компании'
export const TITLE_NAMESPACE_PREVIOUS_JOBS = 'Предыдущие места работы'
export const TITLE_NAMESPACE_PROJECTS_AND_ROLES = 'Проекты и роли'
export const TITLE_NAMESPACE_HIGH_EDUCATION = 'Высшее образование'
export const TITLE_NAMESPACE_COURSES = 'Курсы'
export const TITLE_NAMESPACE_SHORT_DATE_YEAR = 'г'

export const TITLE_NAMESPACE_EMAIL = 'E-mail'

export const TITLE_NAMESPACE_BESTS = 'Лучшие'
export const TITLE_NAMESPACE_BEST_COMPETENCIES = 'Лучшие компетенции'

export const TITLE_NAMESPACE_PHONE_NUMBER = 'Номер телефона'
export const TITLE_NAMESPACE_SHORT_DATE_MONTH = 'мес'


export const TITLE_NAMESPACE_INCOME = 'Доход'
export const TITLE_NAMESPACE_ALLOWANCES = 'Надбавки'
export const TITLE_NAMESPACE_ALLOWANCE_VIEW_ONE_TIME = 'Разовая'
export const TITLE_NAMESPACE_ALLOWANCE_VIEW_TEMPORARY = 'Временная'
export const TITLE_NAMESPACE_ALLOWANCE_VIEW_PERMANENT = 'Постоянная'
export const TITLE_NAMESPACE_LEVEL_BASE = 'Базовый'

export const TITLE_NAMESPACE_LEVEL_MIDDLE = 'Средний'
export const TITLE_NAMESPACE_LEVEL_HIGH = 'Высокий'
export const TITLE_NAMESPACE_MINUTES_SHORT = 'мин'
export const TITLE_NAMESPACE_DURATION = 'Займет'
export const TITLE_NAMESPACE_ASSESSMENT_COMPLETE = 'Пройдено'
export const TITLE_NAMESPACE_REQUIRED = 'Обязательность'
export const TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY = 'Требуемый уровень владения'
export const TITLE_NAMESPACE_PROFICIENCY_LEVEL_COMPETENCE = 'Уровень владения компетенцией'
export const TITLE_NAMESPACE_REQUIRED_LEVEL = 'Требуемый уровень'
export const TITLE_NAMESPACE_QUESTIONS_FOR_LEVEL = 'Вопросы на уровень'
export const TITLE_NAMESPACE_RESULTS_FOR_LEVELS = 'Результаты по уровням'
export const TITLE_NAMESPACE_RESULT = 'Результат'
export const TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL = 'Итоговый результат на требуемый уровень'
export const TITLE_NAMESPACE_TOTAL = 'Итог'
export const TITLE_NAMESPACE_DATE = 'Дата'
export const TITLE_NAMESPACE_ACTION = 'Событие'
export const TITLE_NAMESPACE_AUTHOR_ACTION = 'Автор события'
export const TITLE_NAMESPACE_AUTHOR = 'Автор'
export const TITLE_NAMESPACE_GRADE_COMPLIANCE = 'Соответствие грейду'
export const TITLE_NAMESPACE_STUDIED = 'Изучено'
export const TITLE_NAMESPACE_NOT_STUDIED = 'Не изучено'
export const TITLE_NAMESPACE_DESCRIPTION_GRADE_PLACEHOLDER = 'Кратко опишите грейд, в чем его существенные отличия от грейда предыдущего уровня'
export const TITLE_NAMESPACE_REQUIREMENTS_GRADE_PLACEHOLDER = 'Укажите требования к грейду: например, стаж, количество проектов'
export const TITLE_NAMESPACE_PLATFORM_SECTIONS = 'Разделы платформы'
export const TITLE_NAMESPACE_USER_ACTIVITY = 'Пользовательская активность'
export const TITLE_NAMESPACE_PDP_ON_PROGRESS = 'На изучении'
export const TITLE_NAMESPACE_PDP_EXPIRED = 'Не завершен в срок'
export const TITLE_NAMESPACE_PDP_REVIEW = 'На проверке'
export const TITLE_NAMESPACE_PDP_COMPLETED = 'Завершен'
export const TITLE_NAMESPACE_PDP_LEARNED = 'Изучен'
export const TITLE_NAMESPACE_PDP_SENT = 'Отправлен сотруднику'
export const TITLE_NAMESPACE_PDP_READY_TO_STUDY = 'Готов к изучению'
export const TITLE_NAMESPACE_PDP_START_TO_STUDY = 'Начните прохождение'
export const TITLE_NAMESPACE_PDP_ON_APPROVAL = 'На согласовании'
export const TITLE_NAMESPACE_PDP_RETURNED = 'Вернулся на изучение'
export const TITLE_NAMESPACE_RETURNED_FOR_PROGRESS = 'Возвращён на изучение'
export const TITLE_NAMESPACE_SENT = 'Отправлен'
export const TITLE_NAMESPACE_SENT_FOR_APPROVAL = 'Отправлен на согласование'
export const TITLE_NAMESPACE_CHANGED = 'Изменен'
export const TITLE_NAMESPACE_TERM_OF_PASSAGE = 'Срок прохождения'
export const TITLE_NAMESPACE_CANCELED = 'Отменен'
export const TITLE_NAMESPACE_SELF_ASSESSMENT = 'Cамооценка'
export const TITLE_NAMESPACE_ASSESSMENT = 'Оценка'
export const TITLE_NAMESPACE_ASSESSMENT_180 = 'Оценка 180'
export const TITLE_NAMESPACE_ASSESSMENT_360 = 'Оценка 360'
export const TITLE_NAMESPACE_ASSESSMENT_GENITIVE = 'Оценку'
export const TITLE_NAMESPACE_DATE_END = 'Дата завершения'
export const TITLE_NAMESPACE_OWNS_POSITION = 'Владеет позицией на'


export const TITLE_NAMESPACE_ANALYTIC_INDEX_NOT_ASSIGNED = 'Не назначены'
export const TITLE_NAMESPACE_ANALYTIC_INDEX_NOT_STARTED = 'Не начаты'
export const TITLE_NAMESPACE_ANALYTIC_INDEX_IN_PROGRESS = 'В процессе'
export const TITLE_NAMESPACE_ANALYTIC_INDEX_NOT_COMPLETE_ON_TIME = 'Не завершены в срок'
export const TITLE_NAMESPACE_ANALYTIC_INDEX_DONE = 'Завершены'

export const TITLE_NAMESPACE_ERROR = 'Ошибка'

export const TITLE_NOT_FOUND = 'Не найдено'

export const TITLE_FIELD_TITLE = 'Название'
export const TITLE_FIELD_COMMENT = 'Комментарий'

export const TITLE_NAMESPACE_WAS_CREATED = 'Создана'
export const TITLE_NAMESPACE_WAS_CREATED_NEUTER = 'Создано'
export const TITLE_NAMESPACE_WAS_LAUNCHED = 'Запущена'
export const TITLE_NAMESPACE_WAS_LAUNCHED_NEUTER = 'Запущено'
export const TITLE_NAMESPACE_CREATED = 'Создан'
export const TITLE_NAMESPACE_NOT_CREATED = 'Не создан'
export const TITLE_NAMESPACE_AWAITING_ESTIMATION = 'Ожидает оценки'
export const TITLE_NAMESPACE_AWAITING_CONFIRMATION = 'Ожидает итогов'
export const TITLE_NAMESPACE_SUMMARIZING = 'Подведение итогов'
export const TITLE_NAMESPACE_COMPLETE = 'Завершена'
export const TITLE_NAMESPACE_NOT_COMPLETE = 'Не завершена'
export const TITLE_NAMESPACE_AWAIT_COMPLETE = 'Ожидает завершения'
export const TITLE_NAMESPACE_READY_TO_START = 'Готова к запуску'
export const TITLE_NAMESPACE_READY_TO_START_NEUTER = 'Готово к запуску'
export const TITLE_NAMESPACE_IN_PROGRESS = 'В процессе'
export const TITLE_NAMESPACE_ON_APPROVAL = 'На утверждении'

export const TITLE_NAMESPACE_INITIATOR = 'Инициатор'
export const TITLE_NAMESPACE_REVIEWER = 'Проверяющий'
export const TITLE_NAMESPACE_APPROVED = 'Утвердил'
export const TITLE_NAMESPACE_ACCORDANCE = 'Соответствие'
export const TITLE_NAMESPACE_ACCORDANCE_REQUIREMENTS = 'Соответствие требованиям'
export const TITLE_NAMESPACE_EVALUATION_CRITERIA = 'Критерий оценивания'
export const TITLE_NAMESPACE_CPA_CRITERIA = 'Критерии оценивания'
export const TITLE_NAMESPACE_CPA_SCALES = 'Шкалы оценивания'
export const TITLE_NAMESPACE_EMPLOYEE_ASSESSMENT = 'Оценка сотрудника'
export const TITLE_NAMESPACE_CPA_PARTICIPANTS = 'Участники оценки'
export const TITLE_NAMESPACE_CPA_SCALE = 'Шкала оценивания'
export const TITLE_NAMESPACE_CPA_EMPLOYEES = 'Оцениваемые сотрудники'
export const TITLE_NAMESPACE_CPA_EVALUATED_EMPLOYEES = 'Оцениваемый сотрудник'
export const TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS = 'Выбор оцениваемых сотрудников'
export const TITLE_NAMESPACE_CPA_CHOOSE_PARTICIPANTS = 'Выбор участников оценки сотрудника'
export const TITLE_NAMESPACE_SCALE_NAME = 'Название шкалы'
export const TITLE_NAMESPACE_ANSWER_VARIANTS = 'Варианты ответа'
export const TITLE_NAMESPACE_CPA_CRITERIA_SELECT_FOR_COMPETENCE = 'Выбор компетенций для оценки'
export const TITLE_NAMESPACE_DATE_ENDED_AT = 'Срок завершения'

export const TITLE_NAMESPACE_DYNAMIC_OF_EMPLOYEE = 'Динамика по сотрудникам'
export const TITLE_NAMESPACE_DYNAMIC = 'Динамика'
export const TITLE_NAMESPACE_AVERAGE = 'Среднее'
export const TITLE_NAMESPACE_AVERAGE_ASSESSMENT = 'Среднее по Оценке'
export const TITLE_NAMESPACE_AVERAGE_DIVISIONS = 'Среднее по подразделениям'

export const TITLE_FIELD_EMAIL_LOGIN = 'E-mail для входа в систему'
export const TITLE_FIELD_EMAIL = 'E-mail (логин)'
export const TITLE_FIELD_PASSWORD = 'Пароль'
export const TITLE_FIELD_NEW_PASSWORD = 'Новый пароль'
export const TITLE_FIELD_PASSWORD_CONFIRM = 'Подтверждение пароля'
export const TITLE_FIELD_DESCRIPTION = 'Описание'
export const TITLE_FIELD_POINT = 'Цель'
export const TITLE_FIELD_TYPE = 'Тип'
export const TITLE_FIELD_TIME = 'Время'
export const TITLE_FIELD_LAST_NAME = 'Фамилия'
export const TITLE_FIELD_FIRST_NAME = 'Имя'
export const TITLE_FIELD_SECOND_NAME = 'Отчество'
export const TITLE_FIELD_YEAR_OF_BIRTH = 'Год рождения'
export const TITLE_FIELD_DATE_OF_BIRTH = 'Дата рождения'
export const TITLE_FIELD_ASSESSMENT_ENDED_AT = 'Срок завершения оценки'
export const TITLE_FIELD_DIVISION = 'Подразделение'
export const TITLE_FIELD_PROJECT = 'Проект'
export const TITLE_FIELD_SPECIALIZATION = 'Специализация'
export const TITLE_FIELD_CPA_TYPE = 'Тип оценки'
export const TITLE_FIELD_CPA_TITLE = 'Название оценки'
export const TITLE_FIELD_ROLE = 'Роль'
export const TITLE_FIELD_PERMISSIONS = 'Права доступа'
export const TITLE_FIELD_STATUS = 'Статус'
export const TITLE_FIELD_PHONE = 'Телефон'
export const TITLE_FIELD_FORMAT = 'Формат'
export const TITLE_FIELD_STUDY_TIME = 'Время изучения (часов)'
export const TITLE_FIELD_GRADE_PREVIOUS = 'Грейд предыдущего уровня'
export const TITLE_FIELD_GRADE = 'Грейд'
export const TITLE_FIELD_REQUIREMENTS = 'Требования'
export const TITLE_FIELD_SALARY = 'Оклад (₽)'
export const TITLE_FIELD_EMPLOYEE_PHOTO = 'Фото сотрудника'
export const TITLE_FIELD_DATE_START_WORK_ON_COMPANY = 'Дата начала работы в компании'
export const TITLE_FIELD_DATE_START = 'Дата начала'
export const TITLE_FIELD_DATE_END = 'Дата окончания'
export const TITLE_FIELD_PDP_DATE_ENDED_AT = 'Срок завершения'

export const TITLE_FIELD_COMPANY = 'Компания'
export const TITLE_FIELD_POSITION = 'Должность'

export const TITLE_FIELD_DIRECTION = 'Направление'
export const TITLE_FIELD_LEVEL = 'Уровень'
export const TITLE_FIELD_EDUCATIONAL_INSTITUTION = 'Учебное заведение'
export const TITLE_FIELD_YEAR_GRADUATION = 'Год выпуска'

export const TITLE_FIELD_TITLE_PDP_ITEM = 'Название пункта плана развития'

export const TITLE_FIELD_ASSESSMENT_COMMENT_GLOBAL = 'Общий комментарий о сотруднике (виден всем участникам оценки)'
export const TITLE_FIELD_ASSESSMENT_COMMENT_INDICATOR = 'Комментарий (виден всем участникам оценки)'

export const TITLE_FIELD_PLACEHOLDER_SEARCH = 'Поиск'
export const TITLE_FIELD_PLACEHOLDER_SEARCH_TITLE_ASSESSMENT = 'Название оценки'
export const TITLE_FIELD_PLACEHOLDER_SEARCH_COMPETENCE_OR_GROUP = 'Название группы или компетенции...'
export const TITLE_FIELD_PLACEHOLDER_SEARCH_MATERIAL = 'Название материала...'
export const TITLE_FIELD_PLACEHOLDER_SEARCH_EMPLOYEE = 'ФИО сотрудника'
export const TITLE_FIELD_PLACEHOLDER_SEARCH_SPECIALIZATION = 'Название специализации'
export const TITLE_FIELD_PLACEHOLDER_ADD_POINT = 'Добавьте цель'
export const TITLE_FIELD_PLACEHOLDER_PDP_ITEM_DESCRIPTION = 'Для чего это необходимо, что требуется сделать, какой ожидается результат'

export const TITLE_ELEMENT_TYPE_ORIGIN = 'Стандартный'
export const TITLE_ELEMENT_TYPE_CUSTOM = 'Пользовательский'

export const TITLE_PDP_ALERT_DEVELOPMENT_GO_REVIEW_WHEN_READY = 'Отправьте план на согласование'
export const TITLE_ABOUT_DEVELOPMENT_PDP = 'О плане развития'
export const TITLE_COMPETENCE_DESCRIPTION = 'Описание компетенции'

export const TITLE_ALERT_INFO_ON_APPROVAL = 'План на согласовании'
export const TITLE_ALERT_INFO_READY_TO_STUDY = 'Начните прохождение'
export const TITLE_ALERT_INFO_ON_PROGRESS = 'Пройдите план'
export const TITLE_ALERT_INFO_EXPIRED = 'Вы не успели в срок'
export const TITLE_ALERT_INFO_REVIEW = 'Пройденный план на проверке'
export const TITLE_ALERT_INFO_RETURNED = 'План вернули на изучение'
export const TITLE_ALERT_SEND_TO_APPROVAL = 'Отправьте план на согласование'

export const TITLE_BUTTON_CREATE_MASS_EXAM = 'Создать тестирование'
export const TITLE_DIALOG_CREATE_MASS_EXAM = 'Создание тестирования'
export const TITLE_FIELD_MASS_EXAM_TITLE = 'Название тестирования'
export const TITLE_FIELD_EXAM_TITLE = 'Название теста'
export const TITLE_FIELD_MASS_EXAM_ENDED_AT = 'Срок завершения тестирования'
export const TITLE_REMINDER_ABOUT_EXAM = 'Напоминание о тестировании'
export const TITLE_BUTTON_ADD_DESCRIPTION = 'Добавить описание'
export const TITLE_MASS_EXAM_DESCRIPTION = 'Описание тестирования'
export const TITLE_DIALOG_UPDATE_MASS_EXAM = 'Редактирование тестирования'
export const TITLE_DIALOG_DELETE_MASS_EXAM = 'Удаление тестирования'
export const TITLE_QUESTIONS = 'Вопросы'
export const TITLE_PARTICIPATING_EMPLOYEES = 'Участвующие сотрудники'
export const TITLE_PASS_MARK = 'Проходной балл'
export const TITLE_INFO_ADD = 'Добавьте'
export const TITLE_BTN_CONSTRUCTOR = 'Конструктор'
export const TITLE_NAMESPACE_MASS_EXAM_CHOOSE_EMPLOYEES_TO_EXAM = 'Выбор тестируемых сотрудников'
export const TITLE_EXAM_CONSTRUCTOR = 'Конструктор теста'
export const TITLE_CREATE_QUESTION = 'Создать вопрос'
