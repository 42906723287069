import React, { FC, useState } from 'react'
import { Box, Collapse, Tooltip, Typography } from '@mui/material'
import { ChevronRightRounded, ExpandLess, ExpandMore, MoreHorizRounded } from '@mui/icons-material'
import {
    SpecializationListSpecialization,
} from 'pages/specialization/SpecializationList/components/SpecializationListContent/components/SpecializationListSpecialization/SpecializationListSpecialization'

type SpecializationListDivisionProps = {
    element: ISpecializationListDivision;
}

export const SpecializationListDivision: FC<SpecializationListDivisionProps> = ({ element }) => {
    const [open, setOpen] = useState(true)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <Box mb={4}>
            <Box display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick} mb={3}>
                <Tooltip placement="bottom-start" title={element?.fullPath} enterDelay={300} enterNextDelay={300}>
                    <Box display="flex">
                        {element?.fullPath && <>
                            <MoreHorizRounded color="disabled"/>
                            <ChevronRightRounded color="disabled" sx={{ mr: 0.5 }}/>
                        </>}

                        <Typography mr={0.5} variant="subtitle1">
                            {element.title}
                        </Typography>
                    </Box>
                </Tooltip>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
            >
                {element?.specializations?.map(specialization =>
                    <SpecializationListSpecialization
                        key={`${specialization.uuid}-${element.uuid}`}
                        element={specialization}
                        divisionUuid={element.uuid}
                    />)}
            </Collapse>
        </Box>
    )
}
