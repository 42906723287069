import React, { FC, useMemo, useState } from 'react'
import { ICPAGetCriteriaCompetenceSkillLevel } from 'api/typing/cpaTypes'
import { Box, Button, Chip, Collapse, Grid, Stack, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TITLE_BUTTON_COLLAPSE, TITLE_BUTTON_VIEW_MORE } from 'helpers/enums/titles'

type CPACriteriaCompetenceItemProps = {
    item: ICPAGetCriteriaCompetenceSkillLevel;
}

export const CPACriteriaCompetenceItem: FC<CPACriteriaCompetenceItemProps> = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const viewedItems = useMemo(() => item.competencies.slice(0, 4), [item])
    const lessItemsItems = useMemo(() => item.competencies.slice(4), [item])

    return (
        <Stack spacing={2}>
            <Box>
                <Typography variant="subtitle1">
                    {item.title}
                </Typography>
            </Box>
            <Grid container gap={2} wrap="wrap">
                {viewedItems.map(el => (<Grid
                    item
                    mobile="auto"
                    key={el.uuid}
                >
                    <Chip
                        label={el.title}
                        color="default"
                    />
                </Grid>))}
                <Collapse
                    in={expand}
                    timeout="auto"
                >
                    <Grid container gap={2} wrap="wrap">
                        {lessItemsItems.map(el => (<Grid
                            item
                            mobile="auto"
                            key={el.uuid}
                        >
                            <Chip
                                key={el.uuid}
                                label={el.title}
                                color="default"
                            />
                        </Grid>))}
                    </Grid>
                </Collapse>
            </Grid>
            {lessItemsItems.length > 0 ? <Box>
                <Button
                    variant="text"
                    startIcon={expand ? <ExpandLess/> : <ExpandMore/>}
                    onClick={() => setExpand(prev => !prev)}
                >
                    {expand ? TITLE_BUTTON_COLLAPSE : TITLE_BUTTON_VIEW_MORE}
                </Button>
            </Box> : null}
        </Stack>
    )
}
