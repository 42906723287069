import React from 'react'
import { TITLE_NAMESPACE_NOT_SELECTED_YET } from 'helpers/enums/titles'
import { Stack, Typography } from '@mui/material'
import {
    CPAEmployeesDivision,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/CPAEmployeesDivision'
import { useAppSelector } from 'store/hooks/redux'

export const CPAEmployeesContent = () => {
    const data = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    if (!data || !data.length)
        return <Typography variant="body1" sx={{ color: (theme) => theme.palette.warning.main }}>
            {TITLE_NAMESPACE_NOT_SELECTED_YET}
        </Typography>

    return (
        <Stack spacing={6}>
            {data?.map(division => <CPAEmployeesDivision key={division.uuid} division={division}/>)}
        </Stack>
    )
}
