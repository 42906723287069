import React from 'react'
import IMask from 'imask'
import { IMaskMixin } from 'react-imask'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { InputForm } from 'components/form/InputForm/InputForm'

export type InputFormProps = IMask.AnyMaskedOptions & {
    id: string;
    title?: string;
    colorLabel?: string;
    // mask: any
    radix?: any
}

// TODO: need fix refs and use shouldFocusError in useForm hook where used mask
export const InputMask = IMaskMixin<TextFieldProps & InputFormProps>((props) => {
    // @ts-ignore
    return <InputForm {...props} />
})

//import React from 'react'
// import IMask from 'imask'
// import {
//     ComponentProps,
//     forwardRef,
//     MutableRefObject,
//     useEffect,
//     useState,
// } from 'react'
// import { useIMask } from 'react-imask'
// import { InputForm } from 'components/form/InputForm/InputForm'
// import { TextField } from '@mui/material'
//
// export type MaskedInputProps = {
//     maskOptions: IMask.AnyMaskedOptions
// } & ComponentProps<typeof InputForm>
//
// // eslint-disable-next-line react/display-name
// export const InputMask = forwardRef<HTMLInputElement, MaskedInputProps>(
//     (props, ref) => {
//         const { maskOptions, ...rest } = props
//
//         const [opts, setOpts] = useState<IMask.AnyMaskedOptions>(maskOptions)
//         const { ref: IMaskInput } = useIMask(opts)
//         const inputMaskRef = IMaskInput as MutableRefObject<HTMLInputElement>
//
//         useEffect(() => {
//             setOpts(maskOptions)
//         }, [maskOptions])
//
//         function handleRefs(instance: HTMLInputElement | null) {
//             if (ref) {
//                 if (typeof ref === 'function') {
//                     ref(instance)
//                 } else {
//                     // eslint-disable-next-line no-param-reassign
//                     ref.current = instance
//                 }
//             }
//
//             if (instance) {
//                 inputMaskRef.current = instance
//             }
//         }
//
//         return (
//             <InputForm ref={handleRefs} inputRef={ref} {...rest} />
//         )
//     },
// )

