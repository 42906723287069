import React, { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_CPA_EMPLOYEES } from 'helpers/enums/titles'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'
import {
    CPAEmployeesAction,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/CPAEmployeesAction'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

export const CPAEmployeesHeader = () => {
    const employees = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const employeesCount = useMemo(
        () => employees?.reduce((acc, division) => acc + division?.employees?.length, 0) || 0, [employees],
    )

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack flex={1} direction="row" alignItems="center" spacing={1}>
                <Typography variant="h3">
                    {TITLE_NAMESPACE_CPA_EMPLOYEES}
                </Typography>
                <Authenticated service="cpa" accessLevel="wx">
                    {employeesCount <= 0 && <ErrorOutlineRounded color="warning"/>}
                </Authenticated>
            </Stack>
            <Authenticated service="cpa" accessLevel="wx">
                <CPAEmployeesAction employeesCount={employeesCount}/>
            </Authenticated>
        </Box>
    )
}
