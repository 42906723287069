import React, { useMemo, useState } from 'react'
import { Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { TITLE_BUTTON_RUN, TITLE_DIALOG_RUN_ASSESSMENT } from 'helpers/enums/titles'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { PlayArrowRounded } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { cpaItemActions } from 'store/actions'
import { cpaStatusProgress } from 'helpers/cpa/cpaStatuses'
import { addDays, isPast, parse } from 'date-fns'
import { isValidDateFromApi } from '../../../../../../../helpers/dateAndTime/isValidDateFromApi'

const dialogText = 'Вы уверены, что хотите запустить оценочную процедуру?'
const dialogHelperText360 = ['Пока не для всех сотрудников добавлены дополнительные участники.', 'После запуска опросники будут отправлены участникам для прохождения.', 'Критерии оценивания и шкала после запуска будут зафиксированы и их изменить будет невозможно.']
const dialogHelperTextSimple = ['Все участники оценки получат опросы на прохождение.', 'Критерии оценивания и шкала после запуска будут зафиксированы и их изменить будет невозможно.']

export const CPAMetaButtonAction = () => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const cpaTypeCode = useAppSelector(state => state.CPAItemReducer.cpaTypeCode)
    const meta = useAppSelector(state => state.CPAItemReducer.meta)
    const employees = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployees)
    const someHasNotParticipants = useMemo(
        () => employees?.some(division => division.employees.some(employee =>
            cpaTypeCode === '360' && employee.participantCount < 2
            || cpaTypeCode === '180' && employee.participantCount < 1)),
        [employees, cpaTypeCode],
    )
    const renderButton = cpaStatusCode === 'created' || cpaStatusCode === 'ready'
    const hasNotCriteria = !meta?.criteria || isEmptyUuid(meta?.criteria.uuid)
    const hasNotScale = !meta?.scale?.uuid || isEmptyUuid(meta?.scale?.uuid) || isEmptyUuid(meta?.scale?.typeUuid)
    const hasNotEmployees = meta?.employeesCount <= 0
    const isValidDate = useMemo(() =>  isValidDateFromApi(meta?.endedAt), [meta?.endedAt])

    const isCPAExpired = useMemo(
        () => isValidDate && isPast(addDays(parse(meta?.endedAt, 'dd.MM.yyyy', new Date()), 1)) ,
        [isValidDate, meta?.endedAt],
    )
    const disableButton = hasNotCriteria || hasNotScale || hasNotEmployees || isCPAExpired
    const dialogHelperText = useMemo(() => cpaTypeCode === '360' && someHasNotParticipants
        ? dialogHelperText360
        : dialogHelperTextSimple, [cpaTypeCode, someHasNotParticipants])

    const runAssessment = async () => {
        if (loading) return
        try {
            setLoading(true)
            await dispatch(cpaItemActions.startCpa({
                cpaUuid: meta.uuid,
                cpaStatusUuid: cpaStatusProgress,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    if (!renderButton) return null

    return (
        <>
            <DialogConfirm
                open={open}
                dialogTitle={TITLE_DIALOG_RUN_ASSESSMENT}
                dialogText={dialogText}
                dialogHelperText={dialogHelperText}
                titleButtonConfirm={TITLE_BUTTON_RUN}
                onSubmit={runAssessment}
                handleClose={() => setOpen(false)}
            />
            <Button
                variant="contained"
                disabled={disableButton}
                startIcon={<PlayArrowRounded/>}
                onClick={() => setOpen(true)}
            >
                {TITLE_BUTTON_RUN}
            </Button>
        </>
    )
}
