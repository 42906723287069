import React, { FC, useMemo, useState } from 'react'
import {
    FormHelperText,
    IconButton,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    styled,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { SelectProps } from '@mui/material/Select/Select'
import { containsText } from 'helpers/containsText'
import { TITLE_FIELD_PLACEHOLDER_SEARCH } from 'helpers/enums/titles'
import CloseIcon from '@mui/icons-material/Close'

export type SelectFormProps = {
    id: string;
    title?: string;
    colorLabel?: string;
    helperText?: any;
    placeholder?: string;
    values: IEntity[];
}

const Highlight = styled('span')(({ theme }) => ({
    color: theme.palette.warning.main,
}))

export const SelectForm: FC<SelectProps & SelectFormProps> = React.forwardRef(function InputForm(inProps, ref) {
    const [search, setSearch] = useState('')
    const { values, helperText, title, colorLabel, placeholder, ...props } = inProps
    const theme = useTheme()
    const filteredValues = useMemo(() => values
        .map(value => ({
            ...value,
            shown: containsText(value?.title ?? value, search),
        })), [search, values])
    const searchInputShow = values?.length > Number(process.env.REACT_APP_MIN_ELEMENTS_FOR_SELECT_SEARCH)

    return (
        <Stack spacing={0.5}>
            {title
                ? <InputLabel
                    htmlFor={inProps.id}
                    sx={{
                        color: colorLabel,
                    }}
                >
                    {title} {inProps.required ? '*' : null}
                </InputLabel>
                : null}
            <Select
                ref={ref}
                fullWidth
                MenuProps={{
                    autoFocus: false,
                    // TODO: need replace into mainTheme
                    PaperProps: {
                        sx: {
                            borderRadius: 4,
                            maxHeight: 296,
                        },
                    },
                }}
                displayEmpty={!!placeholder}
                renderValue={(uuid) => uuid
                    ? <span>{values.find(el => el.uuid === uuid)?.title}</span>
                    : <span style={{ color: theme.palette.text.disabled }}>{placeholder}</span>
                }
                {...props}
            >
                {searchInputShow && <ListSubheader sx={{ pb: 2 }}>
                    <TextField
                        value={search}
                        fullWidth
                        placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH}
                        InputProps={{
                            endAdornment: search && <IconButton
                                size="small"
                                onClick={() => setSearch('')}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        }}
                        onChange={({ target: { value } }) => setSearch(value)}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                                e.stopPropagation()
                            }
                        }}
                    />
                </ListSubheader>}
                <MenuItem value="" sx={{ display: 'none' }}/>
                {filteredValues?.map(el => {
                    const index = el.title.toLowerCase().indexOf(search.toLowerCase())
                    return (
                        <MenuItem key={el.uuid} value={el.uuid} sx={{ display: !el.shown ? 'none' : 'flex' }}>
                            <Typography variant="body2">
                                {el.title.substring(0, index)}
                                <Highlight>
                                    {el.title.substring(index, index + search.length)}
                                </Highlight>
                                {el.title.substring(index + search.length)}
                            </Typography>
                        </MenuItem>
                    )
                })}
            </Select>
            {helperText && <FormHelperText>
                <span
                    style={{
                        color: inProps.error ? theme.palette.error.main : theme.palette.text.primary,
                    }}
                >
                    {helperText}
                </span>
            </FormHelperText>}
        </Stack>
    )
})
