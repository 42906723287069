import axios from 'axios'
import { AppDispatch } from 'store/store'
import { userSlice } from 'store/reducers/UserSlice'
import { UserService } from 'api/services/UserService'


export class UserAction {
    private static get userApi() {
        return new UserService(axios)
    }

    public fetchUser() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(userSlice.actions.userFetching())
                const data = await UserAction.userApi.fetchUser()
                dispatch(userSlice.actions.userFetchingSuccess(data))
            } catch (e: any) {
                dispatch(userSlice.actions.userFetchingError(e))
                throw e
            }
        }
    }

    public setUser(user: IUser) {
        return (dispatch: AppDispatch) => {
            dispatch(userSlice.actions.userFetchingSuccess(user))
        }
    }
}
