import { EmployeeService } from '../../services/EmployeeService'
import { useQuery } from 'react-query'

const employeeApi = new EmployeeService().employeeViewService()

const getEmployeePersonalDataFn = async (employeeUuid: string) =>
    await employeeApi.getEmployeePersonalData(employeeUuid)

export const useEmployeePersonalData = (employeeUuid: string, enabled = true) => {
    return useQuery({
        queryKey: 'useEmployeePersonalData',
        queryFn: () => getEmployeePersonalDataFn(employeeUuid),
        refetchOnWindowFocus: false,
        retry: 0,
        enabled,
    })
}
