import React, { FC } from 'react'
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import {
    CPAEmployeeDrawerPreviewAssessment,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/CPAEmployeeDrawerPreviewAssessment'
import { useAppSelector } from 'store/hooks/redux'

type CPAEmployeePreviewAssessmentDrawerProps = {
    open: boolean;
    handleClose: () => void;
    employee: ICpaItemDivisionEmployee | ICpaEmployee;
    cpaUuid: string;
}

export const CPAEmployeePreviewAssessmentDrawer: FC<CPAEmployeePreviewAssessmentDrawerProps> = ({
    open,
    handleClose,
    employee,
    cpaUuid,
}) => {
    const { title } = useAppSelector(state => state.CPAItemReducer.meta)

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: { mobile: '100%', laptop: 'calc(100% - 320px)' },
                },
            }}
        >
            <Box
                role="presentation"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box display="flex" flexDirection="column" px={5} pt={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box flex={1}>
                            <Typography variant="h1">
                                {title}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={handleClose}>
                            <CloseRounded/>
                        </IconButton>
                    </Box>
                </Box>
                <Stack spacing={3} flex={1} overflow="auto" px={5} pb={5}>
                    <CPAEmployeeDrawerPreviewAssessment employee={employee} cpaUuid={cpaUuid}/>
                </Stack>
            </Box>
        </Drawer>
    )
}
