import React from 'react'
import { Box, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material'

export const CPAAnalyticSkeleton = () => {
    return (
        <Stack spacing={2}>
            <Card sx={{ p: 3, borderRadius: 4 }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Stack spacing={1}>
                            <Typography variant="subtitle1">
                                <Skeleton width={150} />
                            </Typography>
                            <Typography variant="body2">
                                <Skeleton width={130} />
                            </Typography>
                        </Stack>
                        <Skeleton width={80} height={80} variant="rounded" />
                    </Box>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Stack spacing={2}>
                            <Typography variant="h3">
                                <Skeleton width={150} />
                            </Typography>
                            <Stack direction="row" flexWrap="wrap" gap={2}>
                                {Array(3).fill(1).map((el, i) => <Skeleton key={`weak-${el}-${i}`} variant="rounded" width={50} />)}
                            </Stack>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h3">
                                <Skeleton width={150} />
                            </Typography>
                            <Stack direction="row" flexWrap="wrap" gap={2}>
                                {Array(6).fill(1).map((el, i) => <Skeleton key={`strong-${el}-${i}`} variant="rounded" width={50} />)}
                            </Stack>
                        </Stack>
                    </Stack>
                </CardContent>

            </Card>
            <Stack direction="row" justifyContent="justify" spacing={2}>
                <Card sx={{ flexGrow: 1 }}>
                    <CardContent>
                        <Skeleton variant="rounded" />
                    </CardContent>
                </Card>
                <Card sx={{ flexGrow: 1 }}>
                    <CardContent>
                        <Skeleton variant="rounded" />
                    </CardContent>
                </Card>
            </Stack>
        </Stack>
    )
}
