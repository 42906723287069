import React, { useMemo } from 'react'
import { Icon, IconButton, Stack, Typography, useTheme, Zoom } from '@mui/material'
import { additionalMenuItems, mainMenuItems } from 'helpers/enums/menuItemsPosition'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks/redux'
import { ChevronLeftRounded } from '@mui/icons-material'


export const AppMenuMobileTitle = () => {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const backButtonPath = useAppSelector(state => state.menuReducer.backButtonPath)
    const currentMenuItem = useMemo(() => {
        const menuItems = [...mainMenuItems, ...additionalMenuItems]
        const currentParentPath = location.pathname.split('/').filter(Boolean)[0]
        const menuItem = menuItems.find(el => el.path.split('/').filter(Boolean)[0] === currentParentPath)
        return menuItem
    }, [location])

    if (!currentMenuItem) return null

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            <Zoom in={!!backButtonPath} timeout={300} unmountOnExit>
                <IconButton color="primary" onClick={() => navigate(backButtonPath ?? '')}>
                    <ChevronLeftRounded/>
                </IconButton>
            </Zoom>
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                style={{
                    transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }}
            >
                <Icon color="primary">
                    {React.createElement(currentMenuItem.icon)}
                </Icon>
                <Typography variant="subtitle1">
                    {currentMenuItem.text}
                </Typography>
            </Stack>
        </Stack>
    )
}
