import React from 'react'
import { Stack } from '@mui/material'
import { ExamListItem } from './ExamListItem/ExamListItem'
import { useAppSelector } from '../../../store/hooks/redux'

export const ExamList = () => {
    const examList = useAppSelector(state => state.examListReducer.filteredList)

    return (
        <Stack spacing={2}>
            {examList.map(exam => (
                <ExamListItem key={exam.uuid} exam={exam} />
            ))}
        </Stack>
    )
}
