import {
    Box,
    Card,
    Checkbox,
    Divider,
    FormControlLabel,
    Icon,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'
import React, { FC } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { formatHours } from '../../../helpers/dateAndTime/hoursToString'
import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { getMaterialType } from 'helpers/getMaterialType'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { developmentPdpItemActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useParams } from 'react-router-dom'
import { useNotify } from 'store/hooks/useNotify'
import { SecondaryActions } from '../../SecondaryActions/SecondaryActions'
import { Authenticated } from '../../auth/Authenticated/Authenticated'
import { BadgeChangeLog } from '../../badges/BadgeChangeLog/BadgeChangeLog'

type PdpCompetenceMaterialCardProps = {
    material: IPdpCompetenceMaterial;
    pdpStatus: IPdpStatus;
    toggleDialog: (arg1: any, arg2: boolean) => void;
    skillLevelUuid: string;
    isDevelopment?: boolean;
    isUniqueMaterial?: boolean;
    openDeleteMaterial?: () => void;
    openEditMaterial?: () => void;
}

export const PdpCompetenceMaterialCard: FC<PdpCompetenceMaterialCardProps> = ({
    material,
    pdpStatus,
    toggleDialog,
    skillLevelUuid,
    isDevelopment,
    openDeleteMaterial,
    openEditMaterial,
    isUniqueMaterial,
}) => {
    const dispatch = useAppDispatch()
    const { pdpUuid, itemUuid } = useParams()
    const notify = useNotify()
    const { palette } = useTheme()
    const materialType = getMaterialType(material.materialTypeUuid)
    const hideCommentBtn = (pdpStatus.status === 'completed' || pdpStatus.status === 'canceled')
        && material?.comments?.length <= 0

    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { item: pdpItem } = useAppSelector(state => state.pdpItemReducer.data)
    const { item: developmentItem } = useAppSelector(state => state.developmentPdpItemReducer.data)
    const item = pdpItem || developmentItem
    const employeeUuid = item?.employee?.uuid

    const changeActiveMaterial = async () => {
        try {
            if (!pdpUuid || !itemUuid) return
            await dispatch(developmentPdpItemActions.passPdpCompetenceMaterial({
                pdpUuid,
                itemUuid,
                isPassed: !material.isPassed,
                materialUuid: material.uuid,
                skillLevelUuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Card
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                // @ts-ignore
                background: material.isPassed ? palette.success['50'] : '#FFF',
            }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' alignItems='center'>
                    <Icon
                        sx={{
                            color: materialType?.color,
                            width: '40px',
                            height: '40px',
                            mr: 1,
                            '& .MuiSvgIcon-root': {
                                fontSize: '40px',
                            },
                        }}
                    >
                        {React.createElement(materialType?.icon || '')}
                    </Icon>
                    {!!material?.changeLog &&
                        <BadgeChangeLog
                            changeLog={material?.changeLog}
                            evaluatedUuid={employeeUuid}
                            badgeDrawerTitle={material.title}
                            badgeDrawerSubTitle={material.format}
                        />
                    }
                </Stack>
                <IconButton
                    target="_blank"
                    href={material.link}
                >
                    <OpenInNewIcon color='primary' />
                </IconButton>
            </Stack>
            <Box>
                <Typography variant='body2' mb={0.5}>{material.title}</Typography>
                <Typography variant='body1'>{material.format}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <AccessTimeIcon color='disabled' sx={{ mr: 1 }} />
                <Typography variant="body1">
                    {formatHours(material.studyTime)}
                </Typography>
            </Box>
            <Divider />
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <FormControlLabel
                    label='Изучен'
                    control={
                        <Checkbox
                            checked={material.isPassed}
                            disabled={pdpStatus.disableChangeItemStatus}
                            onClick={changeActiveMaterial}
                            color='success'
                        />
                    }
                />
                <Stack direction='row' alignItems='center'>
                    {!hideCommentBtn &&
                        <IconButton
                            color="primary"
                            onClick={() => toggleDialog('dialogCommentView', true)}
                        >
                            {material.comments?.length > 0 ?
                                <CommentOutlined color="primary"/>
                                :
                                <AddCommentOutlined color={'action'}/>
                            }
                        </IconButton>
                    }
                    {!isDevelopment ?
                        <SecondaryActions disabled={!isDevelopment}/>
                        :
                        <Authenticated
                            service={isDevelopment ? undefined : 'pdp'}
                            accessLevel="w"
                            divisions={[employee?.division?.uuid]}
                        >
                            <SecondaryActions
                                onDelete={openDeleteMaterial ? () => openDeleteMaterial() : undefined}
                                onEdit={isUniqueMaterial && openEditMaterial ? () => openEditMaterial() : undefined}
                            />
                        </Authenticated>
                    }
                </Stack>
            </Stack>
        </Card>
    )
}
