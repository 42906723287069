import { AppDispatch } from 'store/store'
import { notifierSlice } from 'store/reducers/NotifierSlice'
import { SnackbarKey, SnackbarMessage, VariantType } from 'notistack'
import { CSSProperties } from 'react'

export type NotificationType = {
    message: SnackbarMessage;
    key: SnackbarKey;
    type: VariantType;
    link?: string;
    dismissed?: boolean;
    style?: CSSProperties;
}

export class NotifierAction {

    public notifyAdd(notification: Omit<NotificationType, 'key'>) {
        return (dispatch: AppDispatch) => {
            const key = new Date().getTime() + Math.random()
            dispatch(notifierSlice.actions.notifyAdd({
                ...notification,
                key,
            }))
        }
    }
    public notifyClose(key?: SnackbarKey) {
        return (dispatch: AppDispatch) => {
            dispatch(notifierSlice.actions.notifyClose({
                dismissAll: key === null || typeof key === 'undefined', // dismiss all if no key has been defined
                key,
            }))
        }
    }
    public notifyRemove(key: SnackbarKey) {
        return (dispatch: AppDispatch) => {
            dispatch(notifierSlice.actions.notifyRemove(key))
        }
    }
}
