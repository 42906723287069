import { AppDispatch } from '../../store'
import { MassExamService } from '../../../api/services/massExamService'
import { massExamListSlice } from '../../reducers/massExam/massExamListSlice'
import { IMassExamCreateRequest, IMassExamListItem } from 'api/typing/massExam'

export class MassExamListAction {
    private static get massExamListApi() {
        return new MassExamService().listService()
    }

    public getList() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamListSlice.actions.fetching())
                const data = await MassExamListAction.massExamListApi.getList()
                dispatch(massExamListSlice.actions.setList(data))
                dispatch(massExamListSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setList(data: IMassExamListItem[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(massExamListSlice.actions.setList(data))
        }
    }

    public createOne(payload: IMassExamCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamListSlice.actions.fetching())
                const { uuid } = await MassExamListAction.massExamListApi.createOne(payload)
                dispatch(massExamListSlice.actions.fetchingSuccess())
                return uuid
            } catch (e: any) {
                dispatch(massExamListSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setTitleFilter(payload: string) {
        return (dispatch: AppDispatch) => {
            dispatch(massExamListSlice.actions.setTitleFilter(payload))
        }
    }

    public setStatusFilter(payload: IEntity[]) {
        return (dispatch: AppDispatch) => {
            dispatch(massExamListSlice.actions.setStatusFilter(payload))
        }
    }
}
