import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    saveChangesSpecializationCompetenceMatrix,
    updateCompetencies,
} from 'helpers/specializationCompetenceFunctions'
import { ChangeSpecializationCompetence, SpecializationGradeMetaPayload } from 'store/actions/specializationAction'
import { ISpecializationGradeOnePayload, ISpecializationListOnePayload } from 'api/typing/specializationTypes'
import { emptyUuid } from 'helpers/isEmptyUuid'

const initialState: IState<ISpecializationState> = {
    data: {} as ISpecializationState,
    isLoading: false,
    error: null,
}

const findPreviousGradeUuid = (currentUuid: string, listGrades: ISpecializationGradeItem[]) => {
    const currentIndex = listGrades.findIndex(el => el.uuid === currentUuid)
    return currentIndex > 0 ? listGrades[currentIndex - 1].uuid : emptyUuid
}

export const specializationSlice = createSlice({
    name: 'specialization',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getSpecialization: (state, action: PayloadAction<ISpecialization>) => {
            state.isLoading = false
            state.error = null
            state.data.specialization = action.payload
        },
        editSpecialization: (state, action: PayloadAction<ISpecializationListOnePayload
            & { divisionsList: ISpecializationMetaForCreateDivision[] }>) => {
            state.error = null
            state.isLoading = false
            if (!state.data.specialization) return
            state.data.specialization.divisions = action.payload.divisionsList.filter(el =>
                action.payload.divisionsUuid.includes(el.uuid))
        },
        deleteSpecialization: (state, action: PayloadAction<ISpecializationListOnePayload>) => {
            state.error = null
            state.isLoading = false
            if (!state.data.specialization) return
            if (state.data.specialization.divisions.length === action.payload.divisionsUuid.length) {
                state.data.specialization = {} as ISpecialization
            } else {
                state.data.specialization.divisions =
                    state?.data?.specialization?.divisions?.filter(el =>
                        !action.payload.divisionsUuid.includes(el.uuid))
            }
        },
        getSpecializationGrade: (state, action: PayloadAction<ISpecializationGradeItem[]>) => {
            state.isLoading = false
            state.error = null
            state.data.specializationGrade = action.payload
        },
        getSpecializationGradeMeta: (state, action: PayloadAction<ISpecializationGradeMeta>) => {
            state.isLoading = false
            state.error = null
            state.data.specializationGradeMeta = action.payload
        },
        createSpecializationGrade: (state, action: PayloadAction<ISpecializationGradeOnePayload
            & SpecializationGradeMetaPayload>,
        ) => {
            state.isLoading = false
            state.error = null
            const { payload } = action
            state.data.specializationGradeMeta = {
                grades: state.data.specializationGradeMeta.grades.filter(grade =>
                    grade.uuid !== payload.currentGradeUuid),
                previousGrade: [...state.data.specializationGradeMeta.previousGrade, {
                    title: payload.currentGradeTitle,
                    uuid: payload.currentGradeUuid,
                    description: payload.currentGradeDescription,
                }],
            }

            if (payload.previousGradeUuid === emptyUuid) {
                if (!state.data.specializationGrade) state.data.specializationGrade = []
                state.data.specializationGrade.unshift({
                    title: payload.currentGradeTitle,
                    salary: payload.salary,
                    description: payload.description,
                    requirements: payload.requirements,
                    uuid: payload.currentGradeUuid,
                    competenciesCount: 0,
                })
            } else {
                const previousIndex = state.data?.specializationGrade?.findIndex(grade =>
                    grade.uuid === payload.previousGradeUuid)
                state.data.specializationGrade.splice(previousIndex + 1, 0, {
                    title: payload.currentGradeTitle,
                    salary: payload.salary,
                    description: payload.description,
                    requirements: payload.requirements,
                    uuid: payload.currentGradeUuid,
                    competenciesCount: payload.competenciesCount ?? 0,
                })
            }

            state.data.specialization.hasGrades = state.data?.specializationGrade?.length > 0
        },
        updateSpecializationGrade: (state, action: PayloadAction<ISpecializationGradeOnePayload>) => {
            state.isLoading = false
            state.error = null
            const { payload } = action
            const oldPreviousUuid = findPreviousGradeUuid(payload.currentGradeUuid, state.data.specializationGrade)

            const currentGrade = state.data.specializationGrade.find(el => el.uuid === payload.currentGradeUuid)
            if (!currentGrade) return

            const newGradeState: ISpecializationGradeItem = {
                ...currentGrade,
                salary: payload.salary,
                requirements: payload.requirements,
                description: payload.description,
            }

            if (payload.previousGradeUuid === oldPreviousUuid) {
                state.data.specializationGrade = state.data.specializationGrade.map(el => {
                    if (el.uuid === payload.currentGradeUuid)
                        return newGradeState
                    return el
                })
                return
            }

            const newGradesList = state.data.specializationGrade.filter(el => el.uuid !== payload.currentGradeUuid)
            const previousGradeIndex = newGradesList.findIndex(el => el.uuid === payload.previousGradeUuid)
            if (payload.previousGradeUuid === emptyUuid) {
                newGradesList.unshift(newGradeState)
            } else {
                newGradesList.splice(previousGradeIndex + 1, 0, newGradeState)
            }

            state.data.specializationGrade = newGradesList
        },
        deleteSpecializationGrade: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = null
            state.data.specializationGrade = state.data.specializationGrade.filter(grade =>
                grade.uuid !== action.payload)
            const deletedGradeMetaIndex = state.data.specializationGradeMeta.previousGrade.findIndex(grade =>
                grade.uuid === action.payload)
            const deletedGradeMeta =
                state.data.specializationGradeMeta.previousGrade.splice(deletedGradeMetaIndex, 1)[0]
            state.data.specializationGradeMeta.grades.push(deletedGradeMeta)
            state.data.specialization.hasGrades = state.data?.specializationGrade?.length > 0
        },

        getSpecializationCompetencies: (state, action: PayloadAction<ISpecializationCompetenceGroup[]>) => {
            state.data.matrixCompetence = action.payload
            state.data.matrixCompetenceInitial = action.payload
            state.data.matrixCompetenceToSave = {}
            state.isLoading = false
            state.error = null
        },
        getSpecializationCompetenceGrade: (state, action: PayloadAction<ISpecializationCompetenceGrade[]>) => {
            state.data.competenceGrades = action.payload
            state.isLoading = false
            state.error = null
        },
        changeSpecializationCompetence: (state, action: PayloadAction<ChangeSpecializationCompetence>) => {
            updateCompetencies(
                state.data.matrixCompetence,
                action.payload.competenceGroupUuid,
                action.payload.competenceUuid,
                action.payload.grade,
                state.data.matrixCompetenceToSave,
            )
            state.isLoading = false
            state.error = null
        },
        saveChangesSpecializationCompetenceMatrix: (state) => {
            saveChangesSpecializationCompetenceMatrix(state.data.matrixCompetence)
            state.data.matrixCompetenceToSave = {}
            state.data.matrixCompetenceInitial = JSON.parse(JSON.stringify(state.data.matrixCompetence))
            state.isLoading = false
            state.error = null
        },
        cancelChangeSpecializationCompetence: (state) => {
            state.data.matrixCompetence = JSON.parse(JSON.stringify(state.data.matrixCompetenceInitial))
            state.data.matrixCompetenceToSave = {}
            state.isLoading = false
            state.error = null
        },
    },
})

export const specializationReducer = specializationSlice.reducer
