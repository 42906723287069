import axios, { AxiosStatic } from 'axios'
import { MassExamListService } from './massExamListService'
import { MassExamItemService } from './massExamItemService'
import { MassExamQuestionConstructorService } from './massExamQuestionConstructorService'
import { MassExamNotifyService } from './MassExamNotifyService'

interface IMassExamService {
    listService: () => MassExamListService;
    itemService: () => MassExamItemService;
    notifyService: () => MassExamNotifyService;
}

export class MassExamService implements IMassExamService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public listService(): MassExamListService {
        return new MassExamListService(this.axiosInstance)
    }

    public itemService(): MassExamItemService {
        return new MassExamItemService(this.axiosInstance)
    }

    public questionConstructorService(): MassExamQuestionConstructorService {
        return new MassExamQuestionConstructorService(this.axiosInstance)
    }

    public notifyService(): MassExamNotifyService {
        return new MassExamNotifyService(this.axiosInstance)
    }
}
