import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMassExamQuestion, MassExamAnswerData, ExamQuestionType } from '../../../api/typing/massExam'
import { getQuestionTypeCode } from '../../../helpers/massExam/massExamConstructor'

interface IMassExamQuestionConstructorState {
    isLoading: boolean;
    error: IErrorResponse | null;
    list: IMassExamQuestion[];
    draftEditedQuestion: IMassExamQuestion | null;
}

const initialState: IMassExamQuestionConstructorState = {
    isLoading: false,
    error: null,
    list: [],
    draftEditedQuestion: null,
}

export const massExamQuestionConstructorSlice = createSlice({
    name: 'massExamQuestionConstructor',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        setList: (state, action: PayloadAction<IMassExamQuestion[]>) => {
            state.list = action.payload
        },
        setListFromFile: (state, action: PayloadAction<IMassExamQuestion[]>) => {
            state.list = [
                ...state.list,
                ...action.payload,
            ]
        },
        setNewQuestion: (state, action: PayloadAction<IMassExamQuestion>) => {
            state.list = [
                ...state.list,
                action.payload,
            ]
        },
        setUpdatedQuestion: (state, action: PayloadAction<IMassExamQuestion>) => {
            state.list = state.list.map(question => {
                if (question.uuid === action.payload.uuid) {
                    return action.payload
                }

                return question
            })
        },
        setQuestionImageLink: (state, action: PayloadAction<{ link: string, uuid: string }>) => {
            state.list = state.list.map(question => {
                if (question.uuid === action.payload.uuid) {
                    return {
                        ...question,
                        imgLink: action.payload.link,
                    }
                }

                return question
            })
        },
        setAnswerImageLink: (state, action: PayloadAction<{
            link: string,
            questionUuid: string,
            answerUuid: string,
            answerSort: number,
        }>) => {
            const {
                link,
                questionUuid,
                answerUuid,
                answerSort,
            } = action.payload

            const currentQuestion = state.list.find(item => item.uuid === questionUuid)
            const currentAnswers =
                currentQuestion?.answers[getQuestionTypeCode(currentQuestion.typeUuid) as ExamQuestionType]

            if (!currentAnswers) return

            const newAnswer: MassExamAnswerData = { ...currentAnswers[ answerSort ], imgLink: link, uuid: answerUuid }
            currentAnswers?.splice(action.payload.answerSort, 1, newAnswer)
            const newQuestion = {
                ...currentQuestion,
                answers: {
                    [getQuestionTypeCode(currentQuestion?.typeUuid || '') as ExamQuestionType]: currentAnswers,
                },
            }

            state.list = state.list.map(question => {
                if (question.uuid === questionUuid) {
                    return newQuestion
                }

                return question
            })

        },
        setDraftEditedQuestion: (state, action: PayloadAction<IMassExamQuestion | null>) => {
            state.draftEditedQuestion = action.payload
        },
        setAnswerImgLinkChangesToDraft: (state, action: PayloadAction<{
            imgLink: string,
            questionUuid: string,
            answerUuid: string,
            answerSort: number,
        }>) => {
            if (action.payload.questionUuid !== state.draftEditedQuestion?.uuid) {
                return
            }

            const questionTypeCode = getQuestionTypeCode(state.draftEditedQuestion?.typeUuid || '')
            const newAnswers = state.draftEditedQuestion?.answers[questionTypeCode]?.map(item => {
                if (item.uuid === action.payload.answerUuid || item.sort === action.payload.answerSort) {
                    return {
                        ...item,
                        imgLink: action.payload.imgLink,
                        uuid:  action.payload.answerUuid,
                    }
                }
                return item
            })

            state.draftEditedQuestion = {
                ...state.draftEditedQuestion,
                answers: {
                    [questionTypeCode]: newAnswers,
                },
            }
        },
        setQuestionImgLinkChangesToDraft: (state, action: PayloadAction<{
            imgLink: string,
            questionUuid: string,
        }>) => {
            if (action.payload.questionUuid !== state.draftEditedQuestion?.uuid) {
                return
            }

            state.draftEditedQuestion = {
                ...state.draftEditedQuestion,
                imgLink: action.payload.imgLink,
            }
        },
    },
})

export const massExamQuestionConstructorReducer = massExamQuestionConstructorSlice.reducer
