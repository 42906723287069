import React, { FC } from 'react'
import { Box, Button, Stack } from '@mui/material'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_SAVE } from 'helpers/enums/titles'
import { cpaItemCriteriaActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { useParams } from 'react-router-dom'

type CPACriteriaDrawerItemCurrentProps = {
    handleClose: () => void;
}

export const CPACriteriaDrawerItemCurrent: FC<CPACriteriaDrawerItemCurrentProps> = ({ handleClose }) => {
    const { cpaUuid } = useParams()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const saveCriteriaCurrent = async () => {
        if (!cpaUuid) return
        try {
            await dispatch(cpaItemCriteriaActions.saveCriteriaCurrent(cpaUuid))
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Box flex={1} display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
                <Button
                    variant="text"
                    onClick={handleClose}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    disabled={currentCriteriaUuid === cpaCriteriaCurrentUuid}
                    onClick={saveCriteriaCurrent}
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Box>
    )
}
