import React, { FC, useState } from 'react'
import { ErrorOutlineRounded, InfoOutlined, MoreHorizRounded, WarningAmberRounded } from '@mui/icons-material'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import { Box, Chip, Divider, IconButton, Stack, SwipeableDrawer, Tooltip, Typography } from '@mui/material'
import { Puller } from 'components/common/Puller/Puller'
import {
    TITLE_NAMESPACE_RESULTS_FOR_LEVELS,
    TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL,
} from 'helpers/enums/titles'
import {
    AssessmentResultTreeItemMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeItemMobile/AssessmentResultTreeItemMobile'
import { useAppSelector } from 'store/hooks/redux'

type AssessmentResultTreeItemSkillLevelChipMobileProps = {
    competence: IAssessmentCompetenceGroupCompetence;
    isDrawer?: boolean;
}

export const AssessmentResultTreeItemSkillLevelChipMobile: FC<AssessmentResultTreeItemSkillLevelChipMobileProps> = ({
    competence,
    isDrawer,
}) => {
    const [open, setOpen] = useState(false)
    const accordance = useAppSelector(state => state.assessmentResultReducer.data.accordance)
    const showDrawer = competence.skillLevels.length > 0

    const showWarningIcon = !competence.isUncompleted && competence.indicators.some(el => {
        if (el.needAction) return true
        return el.answers.some(answer => answer.role?.divergent)
    })
    const isUnCompleted = !competence.scores.some(el => el.userUuid === 'final' || el.roleUuid === 'final')

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <>
            <Stack direction="row" alignItems="center">
                {!accordance
                    ? <ChipSkillLevel
                        skillLevelTitle={competence.skillLevelTitle}
                        deleteIcon={<MoreHorizRounded color="primary"/>}
                        onClick={isDrawer || !showDrawer ? undefined : handleOpen}
                        onDelete={isDrawer || !showDrawer ? undefined : handleOpen}
                    />
                    : isDrawer
                        ? null
                        : <IconButton color="primary" onClick={handleOpen}>
                            <MoreHorizRounded/>
                        </IconButton>
                }
                {isUnCompleted && !isDrawer && <Tooltip title="Недостаточно данных для объективной оценки">
                    <ErrorOutlineRounded color="error"/>
                </Tooltip>}
                {showWarningIcon && !isDrawer && <Tooltip title="Оценки участников сильно расходятся">
                    <WarningAmberRounded color="warning"/>
                </Tooltip>}
            </Stack>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                ModalProps={{
                    keepMounted: false,
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '16px 16px 0 0',
                    },
                }}
            >
                <Box
                    role="presentation"
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        px: 3,
                        pb: 3,
                        pt: 2,
                    }}
                >
                    <Stack spacing={3} flex={1}>
                        <Puller/>
                        <AssessmentResultTreeItemMobile isDrawer {...{ competence }}/>
                        <Stack direction="row" flexWrap="wrap" gap={2}>
                            {isUnCompleted && <Chip
                                label="Недостаточно данных для объективной оценки"
                                variant="outlined"
                                color="error"
                                size="small"
                                icon={<InfoOutlined/>}
                                sx={{
                                    padding: '4px 4px 4px 12px',
                                    height: 'auto',

                                    '& .MuiChip-label': {
                                        display: 'block',
                                        whiteSpace: 'pre-wrap',
                                    },
                                }}
                            />}
                            {showWarningIcon && <Chip
                                label="Оценки участников сильно расходятся"
                                variant="outlined"
                                color="warning"
                                size="small"
                                icon={<WarningAmberRounded/>}
                                sx={{
                                    padding: '4px 4px 4px 12px',
                                    height: 'auto',

                                    '& .MuiChip-label': {
                                        display: 'block',
                                        whiteSpace: 'pre-wrap',
                                    },
                                }}
                            />}
                        </Stack>
                        <Divider/>
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                <Typography variant="body2">
                                    {TITLE_NAMESPACE_RESULTS_FOR_LEVELS}
                                </Typography>
                                <Stack spacing={2} divider={<Divider/>}>
                                    {competence.skillLevels.map(el => <Stack
                                        key={el.uuid}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <ChipSkillLevel skillLevelTitle={el.title}/>
                                        <Chip
                                            sx={{ minWidth: 56, marginLeft: 3 }}
                                            label={`${el?.score}%`}
                                            color={el?.score > 75 ? 'default' : 'warning'}
                                        />
                                    </Stack>)}
                                </Stack>
                                <Divider/>
                            </Stack>
                            <Stack spacing={2}>
                                <Typography variant="body2">
                                    {TITLE_NAMESPACE_TOTAL_RESULTS_FOR_REQUIRED_LEVEL}
                                </Typography>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <ChipSkillLevel skillLevelTitle={competence.skillLevelTitle}/>
                                    <Chip
                                        sx={{ minWidth: 56, marginLeft: 3 }}
                                        label={`${competence?.score}%`}
                                        color={competence?.score > 75 ? 'default' : 'warning'}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </SwipeableDrawer>
        </>
    )
}
