import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { StyledComponent } from '@emotion/styled'
import {
    LibraryAddCheckRounded,
    PaymentsRounded,
    PlaylistAddCheckRounded,
    PlaylistRemoveRounded,
    SendRounded,
    StarRounded,
    UndoRounded,
    CancelPresentation,
    FactCheck,
} from '@mui/icons-material'
import React from 'react'
import { PointPositionGradeChip } from 'components/badges/PointPositionGradeChip/PointPositionGradeChip'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import {
    EmployeeTabHistoryItemTextDescription,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabHistory/components/EmployeeHistory/components/EmployeeTabHistoryListItem/components/EmployeeTabHistoryItemTextDescription/EmployeeTabHistoryItemTextDescription'
import { MoneyChip } from 'components/badges/MoneyChip/MoneyChip'
import { ExamResultChipForHistory } from 'components/badges/ExamResultChipForHistory/ExamResultChipForHistory'

const eventSalaryChangedUuid = '78bf30a9-fb2e-41bc-abde-52d1f97dbf5c' // Изменена зп
const eventAddAllowanceUuid = '266bc355-5807-4606-af85-0f1db1f6ed63' // Добавлена надбавка
const eventAddNewGradeUuid = '43e557c6-8c0c-4f6f-8fee-dc3e7f3c9fa2' // Добавлен новый грейд
const eventSentPdpUuid = '04636aca-c217-4587-b696-4f775a0acd1e' // Отправлен ипр
const eventRollbackPdpUuid = '65f2db39-4fd5-4c90-b8c9-7627c4c72142' // Возвращен ипр
const eventCompletePdpUuid = '672a7e5e-5037-4287-8b8f-f20e44136703' // Завершен ипр
const eventCancelPdpUuid = 'f18887e5-e70f-4ffc-9b05-7b811db040e0' // Отменен ипр
const eventDivisionChangedUuid = '86cb3acb-96e1-440b-98cc-b20813cbb948' // Изменено подразделение
const eventSpecializationChangedUuid = '0dea0a00-2a6e-499f-9304-1493c3e01c2d' // Изменена специализация
// update from cpa
const eventCompleteAssessmentSelfUuid = '31b0cfe0-a18c-432c-aa7f-6e051f7bf894' // ,Завершена самооценка
const eventCompleteAssessment180Uuid = '52c7520f-3eec-48dd-95ca-547eaf9e4edf' // ,Завершена оценка 180
const eventCompleteAssessment360Uuid = '8d75abae-a2b6-41d5-a87f-2b0c53aabdd5' // ,Завершена оценка 360
const eventNotCompleteAssessmentSelfUuid = '07341c50-9f30-4da0-a366-550e88fc61fb' // ,Не завершена самооценка
const eventNotCompleteAssessment180Uuid = 'e933fd4f-27eb-49bf-8137-1bdf1c1ad27e' // ,Не завершена 180
const eventNotCompleteAssessment360Uuid = '5a2a9d35-6c0f-4316-8245-c5d0e1f4ce1e' // ,Не завершена 360
// for mass exam
export const eventCompleteExam = '6bd196ce-dc8b-4572-b786-0bb57baf8bd1' // Завершено тестирование
export const eventNotCompleteExam = '0356b775-e11f-400b-82cf-41e4b5e11357' // Не завершено тестирование

type EventsUuid = typeof eventSalaryChangedUuid
    | typeof eventAddAllowanceUuid
    | typeof eventAddNewGradeUuid
    | typeof eventDivisionChangedUuid
    | typeof eventSpecializationChangedUuid
    | typeof eventCompletePdpUuid
    | typeof eventCancelPdpUuid
    | typeof eventSentPdpUuid
    | typeof eventRollbackPdpUuid

    | typeof eventCompleteAssessmentSelfUuid
    | typeof eventCompleteAssessment180Uuid
    | typeof eventCompleteAssessment360Uuid
    | typeof eventNotCompleteAssessmentSelfUuid
    | typeof eventNotCompleteAssessment180Uuid
    | typeof eventNotCompleteAssessment360Uuid

    | typeof eventCompleteExam
    | typeof eventNotCompleteExam


type EventTypes = 'income'
    | 'grade'
    | 'developmentPlan'
    | 'estimations'
    | 'division'
    | 'specialization'
    | 'assessment'
    | 'exam'

export interface IHistoryEvent {
    eventUuid: EventsUuid;
    type: EventTypes;
    showMoney: boolean;
    description: React.JSXElementConstructor<any> | null;
    subDescription: React.JSXElementConstructor<any> | null;
    sum: React.JSXElementConstructor<any> | null;
    link: string;
    icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & { muiName: string } | StyledComponent<any>;
}

export const historyEventTypes: Record<string, IHistoryEvent> = {
    [eventSalaryChangedUuid]: {
        eventUuid: eventSalaryChangedUuid,
        link: '',
        showMoney: true,
        type: 'income',
        description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        sum: MoneyChip,
        icon: PaymentsRounded,
    },
    [eventAddAllowanceUuid]: {
        eventUuid: eventAddAllowanceUuid,
        link: '',
        showMoney: true,
        type: 'income',
        description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        sum: MoneyChip,
        icon: PaymentsRounded,
    },
    [eventAddNewGradeUuid]: {
        eventUuid: eventAddNewGradeUuid,
        link: '',
        showMoney: false,
        type: 'grade',
        description: PointPositionSpecializationGrade,
        subDescription: null,
        sum: null,
        icon: StarRounded,
    },
    [eventDivisionChangedUuid]: {
        eventUuid: eventDivisionChangedUuid,
        link: '',
        showMoney: false,
        type: 'division',
        description: PointPositionSpecializationGrade,
        subDescription: null,
        sum: null,
        icon: StarRounded,
    },
    [eventSpecializationChangedUuid]: {
        eventUuid: eventSpecializationChangedUuid,
        link: '',
        showMoney: false,
        type: 'specialization',
        description: PointPositionSpecializationGrade,
        subDescription: null,
        sum: null,
        icon: StarRounded,
    },
    [eventCompletePdpUuid]: {
        eventUuid: eventCompletePdpUuid,
        link: 'pdp',
        showMoney: false,
        type: 'developmentPlan',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        // subDescription: PointPositionGradeChip,
        subDescription: null,
        sum: null,
        icon: PlaylistAddCheckRounded,
    },
    [eventCancelPdpUuid]: {
        eventUuid: eventCancelPdpUuid,
        link: 'pdp',
        showMoney: false,
        type: 'developmentPlan',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        // subDescription: PointPositionGradeChip,
        subDescription: null,
        sum: null,
        icon: PlaylistRemoveRounded,
    },
    [eventSentPdpUuid]: {
        eventUuid: eventSentPdpUuid,
        link: 'pdp',
        showMoney: false,
        type: 'developmentPlan',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        // subDescription: PointPositionGradeChip,
        subDescription: null,
        sum: null,
        icon: SendRounded,
    },
    [eventRollbackPdpUuid]: {
        eventUuid: eventRollbackPdpUuid,
        link: 'pdp',
        showMoney: false,
        type: 'developmentPlan',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        // subDescription: PointPositionGradeChip,
        subDescription: null,
        sum: null,
        icon: UndoRounded,
    },

    // complete assessment
    [eventCompleteAssessmentSelfUuid]: {
        eventUuid: eventCompleteAssessmentSelfUuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: LibraryAddCheckRounded,
    },
    [eventCompleteAssessment180Uuid]: {
        eventUuid: eventCompleteAssessment180Uuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: LibraryAddCheckRounded,
    },
    [eventCompleteAssessment360Uuid]: {
        eventUuid: eventCompleteAssessment360Uuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: LibraryAddCheckRounded,
    },

    // not complete assessment
    [eventNotCompleteAssessmentSelfUuid]: {
        eventUuid: eventNotCompleteAssessmentSelfUuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: PlaylistRemoveRounded,
    },
    [eventNotCompleteAssessment180Uuid]: {
        eventUuid: eventNotCompleteAssessment180Uuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: PlaylistRemoveRounded,
    },
    [eventNotCompleteAssessment360Uuid]: {
        eventUuid: eventNotCompleteAssessment360Uuid,
        link: 'assessment',
        showMoney: false,
        type: 'estimations',
        description: PointPositionGradeChip,
        // description: EmployeeTabHistoryItemTextDescription,
        subDescription: null,
        // subDescription: PointPositionGradeChip,
        sum: null,
        icon: PlaylistRemoveRounded,
    },
    [eventCompleteExam]: {
        eventUuid: eventCompleteExam,
        link: 'exam',
        showMoney: false,
        type: 'exam',
        description: EmployeeTabHistoryItemTextDescription,
        subDescription: ExamResultChipForHistory,
        sum: null,
        icon: FactCheck,
    },
    [eventNotCompleteExam]: {
        eventUuid: eventNotCompleteExam,
        link: 'exam',
        showMoney: false,
        type: 'exam',
        description: EmployeeTabHistoryItemTextDescription,
        subDescription: ExamResultChipForHistory,
        sum: null,
        icon: CancelPresentation,
    },
}

export const getHistoryEventTypes = (typeUuid: string): IHistoryEvent | null => {
    const currentType = historyEventTypes[typeUuid]
    if (currentType) return currentType
    return null
}
