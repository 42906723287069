import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import { DateRangeRounded } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPLETE, TITLE_NAMESPACE_ENDED_TO } from 'helpers/enums/titles'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { useAppSelector } from 'store/hooks/redux'
import {
    MyAssessmentPassHeaderHelperChips,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassHeaderHelperChips/MyAssessmentPassHeaderHelperChips'

export const MyAssessmentPassMobileHeader = () => {
    const assessment = useAppSelector(state => state.MyAssessmentPassReducer.data.assessment)
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    return (
        <Stack spacing={4}>
            <Stack spacing={1}>
                <Typography variant="h1">
                    {assessment?.title}
                </Typography>
                <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
                    <ChipAssessmentType cpaType={assessment?.cpa?.typeUuid || assessment?.typeUuid}/>
                    {assessmentSetup?.isValidDateEnd && <Stack spacing={1} direction="row" alignItems="center">
                        <DateRangeRounded color="warning"/>
                        <Typography
                            variant="body1"
                            sx={{
                                color: ({ palette, typography }) => assessmentSetup?.isExpire
                                    ? palette.warning.main
                                    : typography.body1.color,
                            }}
                        >
                            {assessmentSetup?.completeStatus
                                ? `${TITLE_NAMESPACE_COMPLETE} ${assessment?.finishedAt}`
                                : `${TITLE_NAMESPACE_ENDED_TO} ${assessment?.endedAt}`}
                        </Typography>
                    </Stack>}
                </Stack>
            </Stack>
            <Stack spacing={2}>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {`${assessment?.evaluated?.lastName} ${assessment?.evaluated?.firstName} ${assessment?.evaluated?.secondName}`}
                    </Typography>
                    <Typography variant="body1">
                        {assessment?.evaluated?.currentPosition?.title}
                    </Typography>
                </Stack>
                {assessmentSetup?.canSeeCriteria && <Box>
                    <PointPositionSpecializationGrade title={assessmentSetup?.positionTitle}/>
                </Box>}
            </Stack>
            <Divider/>
            <Stack spacing={1} alignItems="flex-start">
                {assessmentSetup?.completeStatus && <Typography variant="h2">
                    Подробные результаты оценки
                </Typography>}
                <MyAssessmentPassHeaderHelperChips/>
            </Stack>
        </Stack>
    )
}
