import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate } from 'api/typing/employeeTypes'

const initialState: IState<IEmployeeWorkExperienceProjectAndRoleItem[]> = {
    data: [] as IEmployeeWorkExperienceProjectAndRoleItem[],
    isLoading: false,
    error: null,
}

export const employeeEditWorkExperienceProjectsAndRolesSlice = createSlice({
    name: 'employeeEditWorkExperienceProjectsAndRoles',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getPreviousProjectAndRoles: (state, action: PayloadAction<IEmployeeWorkExperienceProjectAndRoleItem[]>) => {
            state.data = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        createPreviousProjectAndRoles: (state, action: PayloadAction<IEmployeeWorkExperienceProjectAndRoleItem>) => {
            state.data.push(action.payload)
            state.isLoading = false
            state.error = null
        },

        updatePreviousProjectAndRoles: (
            state,
            action: PayloadAction<IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate>,
        ) => {
            state.data = state.data.map(el => {
                if (el.uuid === action.payload.uuid)
                    return {
                        ...el,
                        ...action.payload,
                    }
                return el
            })
            state.isLoading = false
            state.error = null
        },

        deletePreviousProjectAndRoles: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(el => el.uuid !== action.payload)
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = []
            state.isLoading = false
            state.lastQuery = null
            state.error = null
        },
    },
})

export const employeeEditWorkExperienceProjectsAndRolesReducer = employeeEditWorkExperienceProjectsAndRolesSlice.reducer
