import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationType } from 'store/actions/notifierAction'
import { SnackbarKey } from 'notistack'

const initialState: IState<NotificationType[]> = {
    data: [],
    isLoading: false,
    error: null,
}

export const notifierSlice = createSlice({
    name: 'notifier',
    initialState,
    reducers: {
        notifyAdd: (state, action: PayloadAction<NotificationType>) => {
            state.data.push(action.payload)
        },
        notifyClose: (state, action: PayloadAction<{ key?: SnackbarKey, dismissAll: boolean }>) => {
            state.data = state.data.map(notification => (
                (action.payload.dismissAll || notification.key === action.payload.key)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            ))
        },
        notifyRemove: (state, action: PayloadAction<SnackbarKey>) => {
            state.data = state.data.filter(notification => notification.key !== action.payload)
        },
    },
})

export const notifierReducer = notifierSlice.reducer
