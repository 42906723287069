import React, { FC, useMemo, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CONFIRM,
    TITLE_BUTTON_SAVE,
    TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS,
    TITLE_NAMESPACE_SELECTED_EMPLOYEES_COUNT,
} from 'helpers/enums/titles'
import { cpaItemActions, cpaItemEmployeesActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useCpaItem, useCpaItemEmployees, useCpaStatusState } from 'api/queries/cpa/cpaQueries'
import { useParams } from 'react-router-dom'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { cpaStatusProgress } from 'helpers/cpa/cpaStatuses'

type CPAEmployeesDrawerSummarizeProps = {
    handleClose: () => void;
    onClose: () => void;
}

export const CPAEmployeesDrawerSummarize: FC<CPAEmployeesDrawerSummarizeProps> = ({ handleClose, onClose }) => {
    const { cpaUuid } = useParams()
    const [openConfirm, setOpenConfirm] = useState(false)
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const needToStartUpdateState = !!cpaStatusCode && (cpaStatusCode !== 'created' && cpaStatusCode !== 'complete' && cpaStatusCode !== 'ready')
    const { refetch: refetchEmployees } = useCpaItemEmployees(cpaUuid || '', false)
    const { refetch: refetchStatus } = useCpaStatusState(cpaUuid || '', false)
    const { refetch: refetchCpaMeta } = useCpaItem(cpaUuid || '', false)
    const savedEmployees = useAppSelector(state => state.CPAItemEmployeesReducer.employeeListSaved)
    const currentSavedEmployees = useAppSelector(state => state.CPAItemEmployeesReducer.currentEmployeeListSaved)
    const wasChanged = useMemo(
        () => {
            const savedEmployeesSet = new Set(savedEmployees)
            const currentSavedEmployeesSet = new Set(currentSavedEmployees)
            if (savedEmployeesSet.size !== currentSavedEmployeesSet.size)
                return true
            return savedEmployees.some(el => !currentSavedEmployeesSet.has(el))
        },
        [savedEmployees, currentSavedEmployees],
    )


    const saveSelectedEmployees = async () => {
        if (!cpaUuid) return
        try {
            if (needToStartUpdateState) {
                await dispatch(cpaItemActions.updateStatus({
                    cpaUuid,
                    cpaStatusUuid: cpaStatusProgress,
                }))
            }
            await dispatch(cpaItemEmployeesActions.saveEmployeesToCpa({
                cpaUuid,
                employees: savedEmployees,
            }))
            if (needToStartUpdateState) {
                await refetchCpaMeta()
                await refetchEmployees()
                await refetchStatus()
            } else {
                await refetchEmployees()
            }
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            <DialogConfirm
                open={openConfirm}
                dialogTitle={TITLE_NAMESPACE_CPA_CHOOSE_EMPLOYEES_TO_ASSESS}
                dialogText="Вы уверены, что хотите сохранить выбор?"
                dialogHelperText={['Отмеченные сотрудники будут добавлены в оценку.', 'Сотрудники, у которых снята отметка, будут удалены из процедуры, а их данные по оценке не сохранятся.']}
                titleButtonConfirm={TITLE_BUTTON_CONFIRM}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={saveSelectedEmployees}
                handleClose={() => setOpenConfirm(false)}
            />
            <Stack spacing={2}>
                <Typography variant="body1" textAlign="right">
                    {TITLE_NAMESPACE_SELECTED_EMPLOYEES_COUNT}: {savedEmployees.length}
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button onClick={onClose}>
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button variant="contained" onClick={wasChanged ? () => setOpenConfirm(true) : handleClose}>
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}
