import React, { useMemo } from 'react'
import { useCpaCriteriaCompetence } from 'api/queries/cpa/cpaQueries'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { TITLE_NAMESPACE_COMPETENCIES_GENITIVE } from 'helpers/enums/titles'
import { Box, Skeleton } from '@mui/material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useParams } from 'react-router-dom'

export const CPAEmployeeDrawerPreviewAssessmentCriteriaCompetence = () => {
    const { cpaUuid } = useParams()
    const { isLoading, data, error } = useCpaCriteriaCompetence(cpaUuid || '')
    const competenciesCount = useMemo(
        () => data?.reduce((acc, level) => acc + level.competencies.length, 0) || 0,
        [data],
    )

    if (isLoading) return <Skeleton variant="rounded" width={200}/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Box>
            <PointPositionSpecializationGrade
                title={`${TITLE_NAMESPACE_COMPETENCIES_GENITIVE}: ${competenciesCount}`}
            />
        </Box>
    )
}
