import { AppDispatch } from 'store/store'
import {
    IPdpChangeOrderItemRequest,
    IPdpChangeRequest,
    IPdpChangeStatusRequest,
    IPdpCommentCreateRequest,
    IPdpCommentDeleteRequest,
    IPdpCommentUpdateRequest,
    IPdpCreateRequest,
    IPdpDeleteItemRequest,
    IPdpGetOrDeleteRequest,
    IPdpItemCommentCreateRequest,
    IPdpItemCommentDeleteRequest,
    IPdpItemCommentUpdateRequest,
    IPdpItemUpdateRequest,
    IPdpSetupCompetenceItemsRequest,
    IPdpUniqueItemCreateRequest,
    IPdpUpdateRequest,
} from 'api/typing/employeeTypes'
import { pdpSlice } from 'store/reducers/employee/view/tabs/pdp/PdpSlice'
import { FormService } from 'api/services/FormService'
import { employeeSlice } from 'store/reducers/EmployeeSlice'
import { PdpService } from 'api/services/PdpService'


export class PdpAction {

    private static get pdpApi() {
        return new PdpService().pdpService()
    }

    private static get formApiEmployee() {
        return new FormService().employeeFormService()
    }

    private static get formApiShared() {
        return new FormService().sharedFormService()
    }

    public sendPdp(payload: IPdpChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const { status } = await PdpAction.pdpApi.sendPdp(payload)
                dispatch(pdpSlice.actions.changeStatusPdp(status))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public rollbackPdp(payload: IPdpChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const { status } = await PdpAction.pdpApi.rollbackPdp(payload)
                dispatch(pdpSlice.actions.changeStatusPdp(status))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public cancelPdp(payload: IPdpChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.cancelPdp(payload)
                dispatch(pdpSlice.actions.clearState())
                dispatch(employeeSlice.actions.updateEmployee({ pdpUuid: '' }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public donePdp(payload: IPdpChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.donePdp(payload)
                dispatch(pdpSlice.actions.clearState())
                dispatch(employeeSlice.actions.updateEmployee({ pdpUuid: '' }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getPdp(payload: IPdpGetOrDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.getPdp(payload)
                dispatch(pdpSlice.actions.setPdp(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setPdp(data: IPdp) {
        return (dispatch: AppDispatch) => {
            dispatch(pdpSlice.actions.setPdp(data))
        }
    }

    public createPdp(payload: IPdpCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.createPdp(payload)
                dispatch(pdpSlice.actions.setPdp(data))
                dispatch(employeeSlice.actions.updateEmployee({ pdpUuid: data.uuid }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changePdp(payload: IPdpChangeRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.changePdp(payload)
                dispatch(pdpSlice.actions.setPdp(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
                return data.uuid
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdp(payload: IPdpUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.updatePdp(payload)
                // const data = await PdpAction.pdpApi.updatePdp(payload)
                dispatch(pdpSlice.actions.updatePdp(payload))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdp(payload: IPdpGetOrDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.deletePdp(payload)
                dispatch(pdpSlice.actions.setPdp({} as IPdp))
                dispatch(employeeSlice.actions.updateEmployee({ pdpUuid: '' }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createUniqueItemPdp(payload: IPdpUniqueItemCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.createUniqueItemPdp(payload)
                dispatch(pdpSlice.actions.createItemPdp({ ...payload, ...data }))
                dispatch(pdpSlice.actions.updatePdpTotalProgress(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateUniqueItemPdp(payload: IPdpItemUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.updateUniqueItemPdp(payload)
                dispatch(pdpSlice.actions.updateItemPdp({ ...payload, ...data }))
                dispatch(pdpSlice.actions.updatePdpTotalProgress(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdpComment(payload: IPdpCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const { uuid } = await PdpAction.pdpApi.createPdpComment(payload)
                dispatch(pdpSlice.actions.createPdpComment({ ...payload, uuid }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpComment(payload: IPdpCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.updatePdpComment(payload)
                dispatch(pdpSlice.actions.updatePdpComment(payload))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpComment(payload: IPdpCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.deletePdpComment(payload)
                dispatch(pdpSlice.actions.deletePdpComment(payload))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdpItemComment(payload: IPdpItemCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const { uuid } = await PdpAction.pdpApi.createPdpItemComment(payload)
                dispatch(pdpSlice.actions.createPdpItemComment({ ...payload, uuid }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpItemComment(payload: IPdpItemCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.updatePdpItemComment(payload)
                dispatch(pdpSlice.actions.updatePdpItemComment(payload))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpItemComment(payload: IPdpItemCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.deletePdpItemComment(payload)
                dispatch(pdpSlice.actions.deletePdpItemComment(payload))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setupCompetenceItemsPdp(payload: IPdpSetupCompetenceItemsRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.setupCompetenceItemsPdp(payload)
                dispatch(pdpSlice.actions.setupCompetenceItemsPdp(data))
                dispatch(pdpSlice.actions.updatePdpTotalProgress(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteItemPdp(payload: IPdpDeleteItemRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.pdpApi.deleteItemPdp(payload)
                dispatch(pdpSlice.actions.deleteItemPdp({ ...payload, ...data }))
                dispatch(pdpSlice.actions.updatePdpTotalProgress(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeOrderItemPdp(payload: IPdpChangeOrderItemRequest & { newItemsArray: IPdpPlanItem[] }) {
        return async (dispatch: AppDispatch) => {
            try {
                const { newItemsArray, ...data } = payload
                dispatch(pdpSlice.actions.fetching())
                void await PdpAction.pdpApi.changeOrderItemPdp(data)
                dispatch(pdpSlice.actions.fetchingSuccess())
                dispatch(pdpSlice.actions.changeOrderItems(newItemsArray))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getCompetenceForPdp(employeeUuid: string, pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                const data = await PdpAction.formApiEmployee.getCompetenceForPdp(employeeUuid, pdpUuid)
                dispatch(pdpSlice.actions.getCompetenceForPdp(data))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeActiveCompetence(uuid: string, groupUuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                dispatch(pdpSlice.actions.changeActiveCompetence({ uuid, groupUuid, value }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
            }
        }
    }

    public changeActiveCompetenceGroup(uuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(pdpSlice.actions.fetching())
                dispatch(pdpSlice.actions.changeActiveCompetenceGroup({ uuid, value }))
                dispatch(pdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(pdpSlice.actions.fetchingError(e))
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(pdpSlice.actions.clearState())
        }
    }

}
