import { TransferEmployee } from 'api/services/EmployeeService/transferEmployee'

export class EmployeeViewService extends TransferEmployee {

    public getEmployeePersonalData(uuid: string) {
        const path = `/${uuid}`
        return this.request<null, IEmployee>('GET', path)
    }

    public getEmployee(employeeUuid: string) {
        const path = `/${employeeUuid}`
        return this.request<null, IEmployee>('GET', path)
    }

    //----------Work experience----------//
    public getEmployeeWorkInCompanyWorkExperience(employeeUuid: string) {
        const path = `${employeeUuid}/company/work-experience`
        return this.request<null, IEmployeeWorkInCompanyWorkExperience>('GET', path)
    }

    //----------Work experience----------//

    //----------Current position----------//
    public getEmployeeWorkInCompanyCurrentPosition(employeeUuid: string) {
        const path = `${employeeUuid}/company/current-position`
        return this.request<null, IEmployeeWorkInCompanyCurrentPosition>('GET', path)
    }

    //----------Previous position----------//
    public getEmployeeWorkInCompanyPreviousPosition(employeeUuid: string) {
        const path = `${employeeUuid}/company/previous-position/all`
        return this.request<null, IEmployeeWorkInCompanyPreviousPosition[]>('GET', path)
    }

    //----------Previous position----------//

    //----------Projects and Roles----------//
    public getEmployeeWorkInCompanyProjectsAndRoles(employeeUuid: string) {
        const path = `${employeeUuid}/company/projects-and-roles/all`
        return this.request<null, IEmployeeWorkInCompanyProjectsAndRoles[]>('GET', path)
    }

    //----------Salary----------//
    public getEmployeeSalary(
        employeeUuid: string,
        // TODO: temporary, need delete when was created chains
        params: { specializationUuid: string | null, gradeUuid: string | null },
    ) {
        const path = `/${employeeUuid}/salary`
        return this.request<null, IEmployeeSalary>('GET', path, { params })
    }

    //----------Salary----------//

    //----------Work experience----------//
    //----------Work experience previous jobs----------//
    public getPreviousJobs(employeeUuid: string) {
        const path = `${employeeUuid}/experience/job/all`
        return this.request<null, IEmployeeWorkExperiencePreviousJobItem[]>('GET', path)
    }

    //----------Work experience previous jobs----------//

    //----------Work experience previous projects and roles----------//
    public getPreviousProjectAndRoles(employeeUuid: string) {
        const path = `${employeeUuid}/experience/projects-and-roles/all`
        return this.request<null, IEmployeeWorkExperienceProjectAndRoleItem[]>('GET', path)
    }

    //----------Work experience previous projects and roles----------//

    //----------Education----------//
    //----------Education-card----------//
    public getEducationList(employeeUuid: string) {
        const path = `${employeeUuid}/education/education/all`
        return this.request<null, IEmployeeEditEducationEducation[]>('GET', path)
    }

    //----------Education-card----------//

    //----------Course-card----------//
    public getCourseList(employeeUuid: string) {
        const path = `${employeeUuid}/education/course/all`
        return this.request<null, IEmployeeEditEducationCourse[]>('GET', path)
    }

    //----------Course-card----------//
    //----------Education----------//

    //    History tab
    public getHistory(employeeUuid: string, params: { recently: string, eventTypeUuid: string }) {
        const path = `${employeeUuid}/history`
        return this.request<null, IEmployeeHistory[]>('GET', path, { params })
    }

    //    competencies tab
    public getCompetencies(employeeUuid: string, specializationUuid: string, gradeUuid: string) {
        const path = `${employeeUuid}/specialization/${specializationUuid}/grade/${gradeUuid}/competence`
        return this.request<null, IEmployeeTabCompetencies>('GET', path)
    }

    public getAssessments(employeeUuid: string) {
        const path = `/${employeeUuid}/assessment/all`
        return this.request<null, IAssessmentMeta[]>('GET', path)
    }
}
