import React from 'react'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { useCpaCriteriaPoint } from 'api/queries/cpa/cpaQueries'
import { Box, Skeleton } from '@mui/material'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { TITLE_NAMESPACE_ALL_GRADES } from 'helpers/enums/titles'
import { useParams } from 'react-router-dom'

export const CPACriteriaPoint = () => {
    const { cpaUuid } = useParams()
    const { isLoading, error, data } = useCpaCriteriaPoint(cpaUuid || '')
    const specializationTitle = data?.specialization?.title
    const gradeTitle = data?.grade?.uuid && isEmptyUuid(data?.grade?.uuid)
        ? TITLE_NAMESPACE_ALL_GRADES
        : data?.grade?.title
    const pointPosition = `${specializationTitle} - ${gradeTitle}`

    if (isLoading) return <Skeleton variant="rounded" width={200}/>
    if (error) return <ErrorPage error={error}/>

    return (
        <Box>
            <PointPositionSpecializationGrade
                title={pointPosition}
            />
        </Box>
    )
}
