import { TransferCPA } from 'api/services/CPAService/transferCPA'
import { TDictionaryScaleNumericItemCreateRequest } from 'api/typing/dictionaryTypes'

export class CPADictionaryService extends TransferCPA {
    private prefix = 'dictionary'

    public getScaleNumericItem(uuid: string) {
        const path = `/${this.prefix}/scale/numeric/${uuid}`
        return this.request<null, IDictionaryScaleItem>('GET', path)
    }

    public updateScaleNumericItem(data: IDictionaryScaleItem) {
        const path = `/${this.prefix}/scale/numeric/${data.uuid}`
        return this.request<IDictionaryScaleItem>('PUT', path, { data })
    }

    public createScaleNumericItem(data: TDictionaryScaleNumericItemCreateRequest) {
        const path = `/${this.prefix}/scale/numeric/create/one`
        return this.request<TDictionaryScaleNumericItemCreateRequest, { uuid: string }>('POST', path, { data })
    }

    public deleteScaleNumericItem(uuid: string) {
        const path = `/${this.prefix}/scale/numeric/${uuid}`
        return this.request<{ uuid: string }>('DELETE', path, { data: { uuid } })
    }
}
