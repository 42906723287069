import axios, { AxiosStatic } from 'axios'
import { Analytic } from 'api/services/AnalyticService/analytic'

interface IAnalyticService {
    analyticService: () => Analytic;
}

export class AnalyticService implements IAnalyticService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public analyticService() {
        return new Analytic(this.axiosInstance)
    }
}
