import React from 'react'
import { Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_EVALUATION_CRITERIA } from 'helpers/enums/titles'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { useAppSelector } from 'store/hooks/redux'

export const MyAssessmentMetaCriteria = () => {
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)

    if (!assessmentMetaSetup?.canSeeCriteria) return null
    return (
        <Stack spacing={2}>
            <Typography variant="body2">
                {TITLE_NAMESPACE_EVALUATION_CRITERIA}
            </Typography>
            <PointPositionSpecializationGrade
                title={assessmentMetaSetup?.positionTitle}
            />
        </Stack>
    )
}
