import React, { FC } from 'react'
import { Box, Stack } from '@mui/material'
import {
    EmployeeTabInfoPersonalData,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabInfo/components/EmployeeTabInfoPersonalData/EmployeeTabInfoPersonalData'
import {
    EmployeeTabInfoWorkInCompany,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabInfo/components/EmployeeTabInfoWorkInCompany/EmployeeTabInfoWorkInCompany'
import {
    EmployeeTabInfoExperience,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabInfo/components/EmployeeTabInfoExperience/EmployeeTabInfoExperience'
import {
    EmployeeTabInfoEducation,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabInfo/components/EmployeeTabInfoEducation/EmployeeTabInfoEducation'
import { PATH_PROFILE } from 'helpers/enums/routePath'
import { useAppSelector } from 'store/hooks/redux'
import { useAuthentication } from 'store/hooks/authentication'


type EmployeeTabInfoProps = {
    employeeUuid: string;
    parentPath: string;
}

export const EmployeeTabInfo: FC<EmployeeTabInfoProps> = ({ employeeUuid, parentPath }) => {
    const isProfilePage = parentPath.includes(PATH_PROFILE)
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const hasPermissionEdit =
        useAuthentication({ service: 'employee', accessLevel: 'w', divisions: [employee?.division?.uuid] })
        || ((uuid === employeeUuid) && useAuthentication({ service: 'profile', accessLevel: 'w' }))

    return (
        <Stack spacing={3}>
            <Box display="grid">
                <EmployeeTabInfoPersonalData hasNotPermissionEdit={!hasPermissionEdit} parentPath={parentPath} />
            </Box>
            <Box display="grid">
                <EmployeeTabInfoWorkInCompany
                    employeeUuid={employeeUuid}
                    parentPath={parentPath}
                    isProfilePage={isProfilePage}
                    hasNotPermissionEdit={!hasPermissionEdit}
                />
            </Box>
            <Box display="grid">
                <EmployeeTabInfoExperience
                    employeeUuid={employeeUuid}
                    parentPath={parentPath}
                    hasNotPermissionEdit={!hasPermissionEdit}
                />
            </Box>
            <Box display="grid">
                <EmployeeTabInfoEducation
                    employeeUuid={employeeUuid}
                    parentPath={parentPath}
                    hasNotPermissionEdit={!hasPermissionEdit}
                />
            </Box>
        </Stack>
    )
}
