import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'

interface IAuth {
    loggedIn: boolean;
    hashVerified: boolean;
}

const cookies = new Cookies()

const initialState: IState<IAuth> = {
    data: {
        loggedIn: !!(cookies.get('accessToken') && cookies.get('companyUUID')),
        hashVerified: false,
    },
    isLoading: false,
    error: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authPending: (state) => {
            state.isLoading = true
        },
        authFulfilled: (state) => {
            state.isLoading = true
        },
        authSuccess: (state, action: PayloadAction<boolean>) => {
            state.isLoading = false
            state.error = null
            state.data.loggedIn = action.payload
        },
        authError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        authClearError: (state) => {
            state.error = null
            state.isLoading = false
        },
        authVerifyHash: (state, action: PayloadAction<boolean>) => {
            state.data.hashVerified = action.payload
            state.isLoading = false
        },
    },
})

export const authReducer = authSlice.reducer
