import { TransferForm } from 'api/services/FormService/transferForm'
import { IPassMarkType, IQuestionType } from '../../typing/massExam'

export class SharedFormService extends TransferForm {

    public getSpecializationList() {
        const path = '/specialization/all'
        return this.request<null, IFormSpecialization[]>('GET', path)
    }

    public geDivisionsList() {
        const path = '/division/all'
        return this.request<null, ISpecializationMetaForCreateDivision[]>('GET', path)
    }

    public getGradeList(specializationUuid: string) {
        const path = `/specialization/${specializationUuid}/grade/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getAssessmentAnswers() {
        const path = '/assessment/answer/all'
        return this.request<null, Array<IEntity & { description: string; weight: number }>>('GET', path)
    }

    public getOAAssessmentAnswers() {
        const path = '/oa/assessment/answer/all'
        return this.request<null, IEntity[]>('GET', path)
    }

    public getCpaTypes() {
        const path = '/cpa/type/all'
        return this.request<null, IEntity[]>('GET', path)
    }

    public getCpaRoles() {
        const path = '/cpa/participant/role/all'
        return this.request<null, IEntity[]>('GET', path)
    }

    public getPassMarkTypes() {
        const path = '/mass-exam/pass-mark'
        return this.request<null, IPassMarkType[]>('GET', path)
    }

    public getQuestionsTypes() {
        const path = '/mass-exam/question-type/all'
        return this.request<null, IQuestionType[]>('GET', path)
    }
}
