import Cookies from 'universal-cookie'

type HeaderScripts = {
    page: string
}

interface IQueryHeaders {
    setCookieHeader: (payload: HeaderScripts) => void;
    clearCookieHeader: () => void;
}

export class QueryHeaders implements IQueryHeaders {
    private static instance: QueryHeaders
    private cookies = new Cookies()
    // TODO: need use variable for name cookie
    private cookieName = 'X-Public-Script'

    static getInstance() {
        if (!QueryHeaders.instance) {
            QueryHeaders.instance = new QueryHeaders()
        }
        return QueryHeaders.instance
    }

    public clearCookieHeader(): void {
        this.cookies.remove(this.cookieName)
    }

    public setCookieHeader(payload: HeaderScripts): void {
        this.cookies.set(this.cookieName, payload)
    }

}
