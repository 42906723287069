import React, { FC } from 'react'
import {
    TITLE_FIELD_PLACEHOLDER_SEARCH_MATERIAL,
} from 'helpers/enums/titles'
import { IconButton, TextField } from '@mui/material'
import { ClearRounded, SearchRounded } from '@mui/icons-material'

type PdpSetupMaterialsFiltersProps = {
    search: string;
    setSearch: (value: string) => void;
}

export const PdpSetupMaterialsFilters: FC<PdpSetupMaterialsFiltersProps> = ({ search, setSearch}) => (
    <TextField
        fullWidth
        placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_MATERIAL}
        InputProps={{
            endAdornment: search.length > 0
                ? <IconButton
                    size="small"
                    onClick={() => setSearch('')}
                >
                    <ClearRounded color="disabled"/>
                </IconButton>
                : <SearchRounded color="disabled"/>,
        }}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
    />
)
