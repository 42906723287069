import React, { FC } from 'react'
import { MenuList } from '@mui/material'
import { IMenuListItem, MenuItemsCodesWithAlerts } from 'helpers/enums/menuItemsPosition'
import { AppMenuListItemLink } from 'components/AppMenu/components/AppMenuListItemLink/AppMenuListItemLink'
import { useAppSelector } from 'store/hooks/redux'

type AppMenuMobileDrawerContentListProps = {
    list: IMenuListItem[];
    handleCloseDrawer: () => void;
}

export const AppMenuMobileDrawerContentList: FC<AppMenuMobileDrawerContentListProps> = ({
    list,
    handleCloseDrawer,
}) => {
    const alerts = useAppSelector(state => state.alertsReducer)

    return (
        <MenuList component="nav" disablePadding>
            {list.map(item =>
                <AppMenuListItemLink
                    key={item.code}
                    item={item}
                    handleCloseDrawer={handleCloseDrawer}
                    alert={alerts[item.code as MenuItemsCodesWithAlerts]}
                />,
            )}
        </MenuList>
    )
}
