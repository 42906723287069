import React from 'react'
import { Button, Divider, Stack } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import {
    TITLE_BUTTON_APPROVE,
    TITLE_BUTTON_APPROVE_ASSESSMENT,
    TITLE_BUTTON_ASSESSMENT_RESULT_DETAILED_RESULT,
    TITLE_BUTTON_BACK_TO_ASSESSMENT,
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CORRECT_ANSWERS,
    TITLE_BUTTON_TO_CALIBRATE,
} from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { PATH_ASSESSMENT, PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { useNavigate } from 'react-router-dom'
import { AppRegistrationRounded, CheckRounded, FactCheckRounded } from '@mui/icons-material'
import { myAssessmentActions } from 'store/actions'
import { useNotify } from 'store/hooks/useNotify'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { assessmentStatusComplete } from 'helpers/assessment/getAssessmentStatus'
import { format } from 'date-fns'

export const AssessmentResultActions = () => {
    const dispatch = useAppDispatch()
    const { dialogCalibrate, dialogApprove, toggleDialog } = useDialogsState({
        dialogCalibrate: false,
        dialogApprove: false,
    })

    const notify = useNotify()
    const navigate = useNavigate()
    const result = useAppSelector(state => state.assessmentResultReducer.data.result)
    const resultSetup = useAppSelector(state => state.assessmentResultReducer.data.resultSetup)
    const assessmentPath = resultSetup?.viewerIsEvaluated ? `${PATH_ASSESSMENT}/${result?.uuid}` : `${PATH_EMPLOYEES}/${result?.evaluated.uuid}/assessment/${result?.uuid}`
    const showButtonCalibrate = resultSetup?.summarizingStatus
        || resultSetup?.confirmationStatus
        || resultSetup?.awaitCompeteStatus
    const showButtonApprove = showButtonCalibrate

    const isAllComplete = resultSetup?.isAllComplete

    const approveAssessment = async () => {
        if (!result?.uuid) return
        try {
            await dispatch(myAssessmentActions.assessmentResultApprove({
                uuid: result?.uuid,
                statusUuid: assessmentStatusComplete,
                finishedAt: format(new Date(), 'dd.MM.yyyy'),
                isManual: true,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const goCalibrate = async () => {
        if (!result?.uuid) return

        if (resultSetup?.summarizingStatus) {
            navigate(`${assessmentPath}/pass`)
            return
        }

        try {
            await dispatch(myAssessmentActions.goSummarize(result.uuid))
            navigate(`${assessmentPath}/pass`)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            {/* confirm calibrate */}
            <DialogConfirm
                open={dialogCalibrate}
                dialogTitle="Переход к калибровке ответов"
                dialogText="Вы уверены, что хотите перейти к калибровке ответов?"
                dialogHelperText={isAllComplete ? undefined : 'Не все участники успели оценить сотрудника, а после калибровки они не смогут отправить свои ответы.'}
                titleButtonConfirm={TITLE_BUTTON_TO_CALIBRATE}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={goCalibrate}
                handleClose={() => toggleDialog('dialogCalibrate', false)}
            />
            {/* confirm approve */}
            <DialogConfirm
                open={dialogApprove}
                dialogTitle="Утверждение результатов оценки"
                dialogText="Вы уверены, что хотите приступить к подведению итогов оценки?"
                dialogHelperText={isAllComplete ? undefined : 'Не все участники успели оценить сотрудника, а после подтверждения они не смогут отправить свои ответы. '}
                titleButtonConfirm={TITLE_BUTTON_APPROVE}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={approveAssessment}
                handleClose={() => toggleDialog('dialogApprove', false)}
            />
            <Stack spacing={4} divider={<Divider/>}>
                <Stack direction="row" justifyContent="space-between" gap={2} flexWrap="wrap">
                    <Stack direction="row" spacing={2} flex={1}>
                        <Button
                            variant="outlined"
                            startIcon={<FactCheckRounded/>}
                            onClick={() => navigate(`${assessmentPath}/pass`)}
                        >
                            {TITLE_BUTTON_ASSESSMENT_RESULT_DETAILED_RESULT}
                        </Button>
                        {showButtonCalibrate && <Button
                            variant={!resultSetup?.isCompleteData || resultSetup.someCompetenceHasDivergent
                                ? 'contained'
                                : 'outlined'}
                            color={!resultSetup?.isCompleteData || resultSetup.someCompetenceHasDivergent
                                ? 'warning'
                                : 'primary'}
                            disabled={resultSetup?.summarizingStatus && !resultSetup.viewerIsCurrentReviewer}
                            startIcon={<AppRegistrationRounded/>}
                            onClick={() => toggleDialog('dialogCalibrate', true)}
                        >
                            {TITLE_BUTTON_CORRECT_ANSWERS}
                        </Button>}
                    </Stack>
                    {showButtonApprove && <Button
                        variant="contained"
                        color="primary"
                        disabled={!resultSetup?.confirmationStatus && !resultSetup?.awaitCompeteStatus}
                        startIcon={<CheckRounded/>}
                        onClick={() => toggleDialog('dialogApprove', true)}
                    >
                        {TITLE_BUTTON_APPROVE_ASSESSMENT}
                    </Button>}
                </Stack>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    sx={{ mt: 4 }}
                    onClick={() => navigate(assessmentPath, { replace: true })}
                >
                    {TITLE_BUTTON_BACK_TO_ASSESSMENT}
                </Button>
            </Stack>
        </>
    )
}
