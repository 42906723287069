import React, { FC } from 'react'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import {
    TITLE_BUTTON_ADD_POINT_AND_ENDED_AT,
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CHANGE_POINT_AND_ENDED_AT,
    TITLE_BUTTON_COMMENT_PDP,
    TITLE_BUTTON_SEND_TO_MANAGER,
    TITLE_BUTTON_SEND_TO_REVIEW,
    TITLE_DIALOG_PDP_SEND_TO_REVIEWER, TITLE_NAMESPACE_AUTHOR,
    TITLE_NAMESPACE_DEVELOPMENT_PLAN,
    TITLE_NAMESPACE_ENDED_TO,
    TITLE_NAMESPACE_NOT_COMPLETED,
    TITLE_NAMESPACE_POINT,
    TITLE_NAMESPACE_PROGRESS_COMPLETE,
    TITLE_NAMESPACE_REVIEWER, TITLE_NAMESPACE_SENT, TITLE_NAMESPACE_SENT_FOR_APPROVAL,
} from 'helpers/enums/titles'
import {
    HINT_PDP_SEND_TO_REVIEW,
    HINT_PDP_SEND_TO_REVIEW_HELPER_TEXT,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
} from 'helpers/enums/hints'
import { Box, Button, Chip, Divider, Grid, Stack, Typography, useTheme } from '@mui/material'
import {
    AddCommentOutlined,
    CommentOutlined,
    DateRangeRounded,
    EditRounded,
    FlagRounded,
    PersonRounded,
    SendRounded,
} from '@mui/icons-material'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { developmentPdpActions } from 'store/actions'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import {
    DialogPdpPointAndEndedAt,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanInfo/dialogs/DialogPdpPointAndEndedAt/DialogPdpPointAndEndedAt'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'

type DevelopmentPdpInfoProps = {
    pdp: IDevelopmentPdp;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpInfo: FC<DevelopmentPdpInfoProps> = ({ pdp, pdpStatus }) => {
    const desktop = useDesktop()
    const theme = useTheme()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { dialogComplete, dialogComment, dialogPoint, toggleDialog } = useDialogsState({
        dialogComplete: false,
        dialogComment: false,
        dialogPoint: false,
    })
    const statusProgress = pdpStatus?.status === 'inProgress'
    const statusReturned = pdpStatus?.status === 'returned'
    const statusSent = pdpStatus?.status === 'sent'
    const statusApproval = pdpStatus?.status === 'approval'
    const statusCreated = pdpStatus?.status === 'default'
    const statusExpired = pdpStatus?.status === 'expired'
    const disableEditComments = !(statusProgress || statusReturned || statusSent || statusCreated)

    const validDateEndedAt = isValidDateFromApi(pdp?.endedAt)
    const validDateSentAt = isValidDateFromApi(pdp?.sentAt)
    const validDateApprovalAt = isValidDateFromApi(pdp?.sentForApprovalAt)

    const endedToTitle = (() => {
        const hasCorrectDate = pdp?.endedAt && validDateEndedAt
        switch (true) {
            case hasCorrectDate: {
                return `${TITLE_NAMESPACE_ENDED_TO} ${pdp?.endedAt}`
            }
            case statusCreated && !hasCorrectDate: {
                return 'Назначьте срок завершения'
            }
            default: {
                return 'Срок назначит проверяющий'
            }
        }
    })()

    const pointTitle = (() => {
        switch (true) {
            case !!pdp?.point: {
                return `${TITLE_NAMESPACE_POINT} - ${pdp?.point}`
            }
            case statusCreated && !pdp?.point: {
                return 'Поставьте цель'
            }
            default: {
                return 'Цель будет поставлена проверяющим'
            }
        }
    })()

    const colorEndedAt = validDateEndedAt && statusExpired
        ? theme.palette.warning.main
        : theme.palette.text.secondary

    const sendToReview = async () => {
        try {
            await dispatch(developmentPdpActions.sendToReview(pdp.currentPdpUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const deleteComment = async (uuid: string) => {
        try {
            await dispatch(developmentPdpActions.deletePdpComment({
                uuid,
                pdpUuid: pdp.currentPdpUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createComment = async (comment: IComment) => {
        try {
            await dispatch(developmentPdpActions.createPdpComment({
                ...comment,
                pdpUuid: pdp.currentPdpUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Grid container display={!desktop ? 'block' : 'flex'}  alignItems='stretch'>
            <DrawerComments
                open={dialogComment}
                disableEdit={disableEditComments}
                handleClose={() => toggleDialog('dialogComment', false)}
                entityTitle={TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                itemTitle={<PointPositionSpecializationGrade
                    title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}/>}
                comments={pdp.comments}
                onCreateComment={createComment}
                onDeleteComment={deleteComment}
            />
            <DialogConfirm
                open={dialogComplete}
                dialogTitle={TITLE_DIALOG_PDP_SEND_TO_REVIEWER}
                dialogText={HINT_PDP_SEND_TO_REVIEW}
                dialogHelperText={HINT_PDP_SEND_TO_REVIEW_HELPER_TEXT}
                titleButtonConfirm={TITLE_BUTTON_SEND_TO_MANAGER}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={sendToReview}
                handleClose={() => toggleDialog('dialogComplete', false)}
            />
            {pdp && dialogPoint &&
                <DialogPdpPointAndEndedAt
                    open={dialogPoint}
                    studyTime={pdp?.studyTime}
                    endedAt={pdp?.endedAt}
                    employee={pdp?.employee}
                    idDevelopment
                    point={pdp?.point}
                    pdpUuid={pdp?.currentPdpUuid}
                    handleClose={() => toggleDialog('dialogPoint', false)}
                />
            }
            <Grid item mobile={!desktop ? 0 : 5.5}>
                <Stack spacing={2}>
                    {!desktop &&
                        <>
                            {validDateSentAt && !statusApproval &&
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_SENT}: {pdp?.sentAt}
                                </Typography>
                            }
                            {statusApproval && validDateApprovalAt &&
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_SENT_FOR_APPROVAL}: {pdp?.sentForApprovalAt}
                                </Typography>
                            }
                            {pdp?.author?.name &&
                                <Typography variant="body1">
                                    {TITLE_NAMESPACE_AUTHOR}: {pdp?.author?.name}
                                </Typography>
                            }
                        </>
                    }
                    {!!pdp?.changeLog && !desktop &&
                        <BadgeChangeLog
                            changeLog={pdp?.changeLog}
                            chipDateTime
                            badgeDrawerTitle={<Typography variant='body1' mb={2}>{TITLE_NAMESPACE_DEVELOPMENT_PLAN}</Typography>}
                            badgeDrawerSubTitle={
                                <PointPositionSpecializationGrade
                                    title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}
                                />
                            }
                        />
                    }
                    {pdp?.reviewer &&
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <PersonRounded color="warning" sx={{ml: desktop ? 0 : '-5px'}}/>
                            <Typography>
                                {TITLE_NAMESPACE_REVIEWER}: {pdp?.reviewer?.name}
                            </Typography>
                        </Stack>
                    }
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <DateRangeRounded color="warning" sx={{ml: desktop ? 0 : '-5px'}}/>
                        <Box
                            display='flex'
                            alignItems={desktop ? 'center' : 'flex-start'}
                            flexDirection={desktop ? 'row' : 'column'}
                            gap={1}
                        >
                            <Typography sx={{ color: colorEndedAt }}>
                                {endedToTitle}
                            </Typography>
                            {pdpStatus.status === 'expired' &&
                                <Chip
                                    label={TITLE_NAMESPACE_NOT_COMPLETED}
                                    size="small"
                                    color="warning"
                                />
                            }
                        </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FlagRounded color="warning" sx={{ml: desktop ? 0 : '-5px'}}/>
                        <Typography variant="body1">
                            {pointTitle}
                        </Typography>
                    </Stack>
                    {statusCreated &&
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<EditRounded/>}
                            sx={{ alignSelf: 'flex-start', px: desktop ? 'initial' : 0 }}
                            onClick={() => toggleDialog('dialogPoint', true)}
                        >
                            {pdp?.endedAt && pdp?.point
                                ? TITLE_BUTTON_CHANGE_POINT_AND_ENDED_AT
                                : TITLE_BUTTON_ADD_POINT_AND_ENDED_AT}
                        </Button>
                    }
                    {desktop &&
                        <Button
                            startIcon={pdp?.comments?.length > 0
                                ? <CommentOutlined color="primary"/>
                                : <AddCommentOutlined color="action"/>}
                            disabled={disableEditComments && pdp?.comments?.length <= 0}
                            variant="outlined"
                            sx={{ alignSelf: 'flex-start' }}
                            onClick={() => toggleDialog('dialogComment', true)}
                        >
                            {TITLE_BUTTON_COMMENT_PDP}
                        </Button>
                    }
                </Stack>
            </Grid>
            {!statusCreated && !statusApproval && desktop &&
                <>
                    <Grid item mobile={1}>
                        <Box display="flex" height="100%" justifyContent="center">
                            <Divider orientation="vertical" flexItem/>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        mobile={5.5}
                        display="flex"
                        flexDirection="column"
                        alignItems="end"
                        justifyContent="center"
                    >
                        <GFProgressBar
                            value={pdp?.progress}
                            title={TITLE_NAMESPACE_PROGRESS_COMPLETE}
                        />
                        {pdpStatus.status !== 'review' &&
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!pdp?.canSend || pdpStatus.disableChangeItemStatus}
                                    startIcon={<SendRounded/>}
                                    sx={{ mt: 2 }}
                                    onClick={() => toggleDialog('dialogComplete', true)}
                                >
                                    {TITLE_BUTTON_SEND_TO_REVIEW}
                                </Button>
                            </Box>
                        }
                    </Grid>
                </>
            }
        </Grid>
    )
}
