import React, { FC, useMemo, useState } from 'react'
import { Box, IconButton, Skeleton, Stack, TableCell, TableRow, Tooltip } from '@mui/material'
import { CloseRounded, ErrorOutlineRounded } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { cpaItemEmployeesActions } from 'store/actions'
import {
    CPAEmployeesPreviewAssessment,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/CPAEmployeesPreviewAssessment'
import {
    CPAEmployeesAssessment,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesAssessment/CPAEmployeesAssessment'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_CONFIRM, TITLE_DIALOG_CONFIRM_ACTION } from 'helpers/enums/titles'
import { ChipAssessmentStatus } from 'components/chips/ChipAssessmentStatus/ChipAssessmentStatus'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { useParams } from 'react-router-dom'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { useCpaItem, useCpaItemEmployees } from 'api/queries/cpa/cpaQueries'
import { EmployeeMetaListItem } from 'components/EmployeeMetaListItem/EmployeeMetaListItem'

type CPAEmployeesTableItemProps = {
    employee: ICpaItemDivisionEmployee;
    divisionUuid: string;
    showStatus: boolean;
}

export const CPAEmployeesTableItem: FC<CPAEmployeesTableItemProps> = ({
    employee,
    divisionUuid,
    showStatus,
}) => {
    const notify = useNotify()
    const { cpaUuid } = useParams()
    const dispatch = useAppDispatch()
    const [openDelete, setOpenDelete] = useState(false)
    const { refetch: refetchCpaMeta } = useCpaItem(cpaUuid || '', false)
    const { refetch: refetchEmployeesList } = useCpaItemEmployees(cpaUuid || '', false)
    const cpaType = useAppSelector(state => state.CPAItemReducer.cpaTypeCode)
    const statusState = useAppSelector(state => state.CPAItemReducer.statusState)
    const cpaStatus = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const assessmentStatusCode = getAssessmentStatus(employee?.assessment?.statusUuid)?.code

    const showErrorParticipants = useMemo(
        () => cpaType === '360'
            && employee.participantCount < 2
            && assessmentStatusCode !== 'complete'
            && assessmentStatusCode != 'awaitComplete'
            && assessmentStatusCode !== 'summarizing',
        [cpaType, employee, assessmentStatusCode],
    )

    const statusStateComplete = statusState ? statusState?.fulfilled : true
    const showStatusChip = !(statusState && !statusState?.fulfilled && isEmptyUuid(employee?.assessment?.statusUuid))
    const showControls = showStatusChip || cpaStatus === 'created' || cpaStatus === 'ready'

    const canDelete = assessmentStatusCode !== 'awaitComplete'
        && assessmentStatusCode !== 'complete'
        && cpaStatus !== 'complete' && statusStateComplete

    const deleteEmployee = async () => {
        if (!cpaUuid) return
        try {
            await dispatch(cpaItemEmployeesActions.deleteEmployee({
                employeeUuid: employee.uuid,
                cpaUuid,
                divisionUuid,
            }))
            if (cpaStatus !== 'created' && cpaStatus !== 'ready') {
                await refetchCpaMeta()
                await refetchEmployeesList()
            }
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    return (
        <TableRow>
            <DialogConfirm
                open={openDelete}
                dialogTitle={TITLE_DIALOG_CONFIRM_ACTION}
                dialogText="Вы уверены, что хотите удалить сотрудника из оценочной процедуры?"
                dialogHelperText="При удалении сотрудника все данные по его оценке не будут сохранены."
                titleButtonConfirm={TITLE_BUTTON_CONFIRM}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={deleteEmployee}
                handleClose={() => setOpenDelete(false)}
            />
            <TableCell>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <EmployeeMetaListItem
                        fullName={`${employee.lastName} ${employee.firstName} ${employee.secondName}`}
                        specialization={employee?.specialization}
                        grade={employee?.grade}
                    />
                    {showErrorParticipants && <Tooltip
                        title={showErrorParticipants ? 'Добавьте дополнительных участников оценки' : ''}
                    >
                        <ErrorOutlineRounded color="warning"/>
                    </Tooltip>}
                </Box>
            </TableCell>
            {showStatus && <TableCell align="right">
                {showStatusChip
                    ? <ChipAssessmentStatus status={employee?.assessment?.statusUuid}/>
                    : <Skeleton variant="rounded" width={148}/>}
            </TableCell>}
            <TableCell align="center">
                {showControls && <Stack direction="row">
                    <CPAEmployeesPreviewAssessment employee={employee} hide={!showControls}/>
                    <CPAEmployeesAssessment employeeUuid={employee.uuid}/>
                    <Authenticated service="cpa" accessLevel="wx">
                        {canDelete && <Tooltip
                            placement="bottom-start"
                            title="Удалить сотрудника из оценки"
                            enterDelay={300}
                            enterNextDelay={300}
                        >
                            <IconButton
                                color="primary"
                                onClick={() => setOpenDelete(true)}
                            >
                                <CloseRounded/>
                            </IconButton>
                        </Tooltip>}
                    </Authenticated>
                </Stack>}
            </TableCell>
        </TableRow>
    )
}
