import React, { FC, useState } from 'react'
import { Box, Checkbox, Collapse, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TITLE_NAMESPACE_COMPETENCIES_GENITIVE } from 'helpers/enums/titles'
import { useAppDispatch } from 'store/hooks/redux'
import { developmentPdpActions, pdpActions } from 'store/actions'
import {
    DrawerPdpCompetence,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/EmployeePdp/components/PdpPlan/components/PdpPlanContent/components/dialogs/DrawerCreatePdpItemFromBase/components/DrawerPdpCompetence/DrawerPdpCompetence'

type DrawerPdpCompetenceGroupProps = {
    element: ISetupCompetenceGroup;
    developmentPage?: boolean;
}

export const DrawerPdpCompetenceGroup: FC<DrawerPdpCompetenceGroupProps> = ({ element, developmentPage }) => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(true)

    const handleClick = () => {
        if (element.competencies?.length || element.subGroups?.length)
            setOpen(!open)
    }

    const handleChange = () => {
        if (developmentPage)
            dispatch(developmentPdpActions.changeActiveCompetenceGroup(element.uuid, !element.selected))
        else
            dispatch(pdpActions.changeActiveCompetenceGroup(element.uuid, !element.selected))
    }

    return (
        <List disablePadding>
            <ListItem disablePadding>
                <ListItemButton
                    sx={{ py: 1.5, px: 2, borderRadius: '12px' }}
                    onClick={handleClick}
                >
                    <Box onClick={e => e.stopPropagation()} mr={2}>
                        <Checkbox
                            indeterminate={element.selected && !element.allSelected}
                            checked={element.allSelected}
                            color={element.isChanged ? 'warning' : 'primary'}
                            onChange={handleChange}
                            sx={{
                                color: (theme) => element.isChanged
                                    ? theme.palette.warning.main
                                    : theme.palette.primary.main,
                            }}
                        />
                    </Box>
                    <Box sx={{
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                        <Box display="flex" mb={0.5}>
                            <Typography maxWidth="435px" mr={0.5} variant="subtitle1">
                                {element.title}
                            </Typography>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                color: (theme) => element.competenceCount === 0
                                    ? theme.palette.warning.main
                                    : theme.typography.body1.color,
                            }}
                        >
                            {TITLE_NAMESPACE_COMPETENCIES_GENITIVE}: {element.competenceCount}
                        </Typography>
                    </Box>
                </ListItemButton>
            </ListItem>
            <Divider sx={{ marginLeft: open ? 4 : 0 }} />
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    marginLeft: 2,
                    paddingLeft: 2,
                    borderLeft: '1px solid #C7D2D3',
                    position: 'relative',
                    '&:after': {
                        position: 'absolute',
                        content: '""',
                        width: 7,
                        height: 7,
                        backgroundColor: '#C7D2D3',
                        borderRadius: '50%',
                        top: -3.5,
                        left: -3.5,
                    },
                }}
            >
                {element.subGroups?.map((item) => (
                    <DrawerPdpCompetenceGroup
                        key={item.uuid}
                        element={item}
                        developmentPage={developmentPage}
                    />
                ))}
                {element.competencies?.map((item) => (
                    <DrawerPdpCompetence key={item.uuid} element={item} developmentPage={developmentPage}/>
                ))}
            </Collapse>
        </List>
    )
}
