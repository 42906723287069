import { AxiosStatic } from 'axios'
import { TransferSpecialization } from 'api/services/SpecializationService/transferSpecialization'
import { ISpecializationCompetenceMatrixUpdateRequest } from 'api/typing/specializationTypes'

export class SpecializationCompetenceMatrixService extends TransferSpecialization {
    private static instance: SpecializationCompetenceMatrixService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!SpecializationCompetenceMatrixService.instance)
            SpecializationCompetenceMatrixService.instance = new SpecializationCompetenceMatrixService(axiosInstance)
        return SpecializationCompetenceMatrixService.instance
    }

    public getSpecializationCompetenceGrade(uuid: string) {
        const path = `/${uuid}/matrix/competence/grade/all`
        return this.request<null, ISpecializationCompetenceGrade[]>('GET', path)
    }

    public getSpecializationCompetencies(uuid: string) {
        const path = `/${uuid}/matrix/competence/all`
        return this.request<null, ISpecializationCompetenceGroup[]>('GET', path)
    }

    public updateSpecializationCompetence(uuid: string, data: ISpecializationCompetenceMatrixUpdateRequest) {
        const path = `/${uuid}/matrix/competence`
        return this.request<ISpecializationCompetenceMatrixUpdateRequest, null>('POST', path, { data })
    }

    public getSetupSpecializationCompetence(uuid: string) {
        const path = `/${uuid}/matrix/competence/setup`
        return this.request<null, ISetupCompetenceGroup[]>('GET', path)
    }

    public setSetupSpecializationCompetence(uuid: string, data: ISpecializationSetupSaveData) {
        const path = `/${uuid}/matrix/competence/setup`
        return this.request<ISpecializationSetupSaveData, null>('POST', path, { data })
    }
}
