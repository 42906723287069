import React, { FC, useMemo, useState } from 'react'
import { NotificationsRounded } from '@mui/icons-material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_NOTIFY,
    TITLE_BUTTON_SEND,
    TITLE_DIALOG_CONFIRM_ACTION,
} from 'helpers/enums/titles'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { useGetCpaNotifyDeadlineLast, useSendCpaNotifyDeadline } from 'api/queries/notify/notifyQueries'
import { CpaStatusCode } from 'helpers/cpa/cpaStatuses'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { useNotify } from 'store/hooks/useNotify'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { LoadingButton } from '@mui/lab'


type CpaButtonNotifyProps = {
    uuid: string;
    isExpire: boolean;
    cpaStatusCode: CpaStatusCode | null;
}

const stableDialogText = 'Вы уверены, что хотите отправить напоминания об оценке? Уведомления будут отправлены участникам, которые не завершили оценку, а также проверяющим, которые еще не подвели итоги.'

export const CpaButtonNotify: FC<CpaButtonNotifyProps> = ({
    uuid,
    isExpire,
    cpaStatusCode,
}) => {
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const { isFetching, refetch, data } = useGetCpaNotifyDeadlineLast(uuid, false)
    const notifyDeadline = useSendCpaNotifyDeadline(uuid)
    const canSendNotify = isExpire && (cpaStatusCode === 'progress' || cpaStatusCode === 'approval')
    const dialogText = useMemo(() => {
        return data && isValidDateFromApi(data.date) ? `Вы уже отправляли напоминания ${data.date}. ${stableDialogText}` : stableDialogText
    }, [data])

    const handleClick = async () => {
        try {
            await refetch()
            setOpen(true)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (!canSendNotify || !cpaStatusCode) return null

    return (
        <>
            {data && <DialogConfirm
                open={open}
                dialogTitle={TITLE_DIALOG_CONFIRM_ACTION}
                dialogText={dialogText}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                titleButtonConfirm={TITLE_BUTTON_SEND}
                handleClose={() => setOpen(false)}
                onSubmit={() => notifyDeadline.mutate()}
            />}
            <Authenticated service="cpa" accessLevel="wx">
                <LoadingButton
                    loading={isFetching}
                    color="warning"
                    variant="contained"
                    size="small"
                    startIcon={<NotificationsRounded />}
                    onClick={handleClick}
                >
                    {TITLE_BUTTON_NOTIFY}
                </LoadingButton>
            </Authenticated>
        </>
    )
}
