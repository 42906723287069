import React, { FC } from 'react'
import { Box, Link, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material'
import { PATH_DEVELOPMENT_PLAN, PATH_OPEN_ACCESS } from 'helpers/enums/routePath'
import { NavLink as RouterLink, useParams } from 'react-router-dom'

type DevelopmentPlanCompetenceItemProps = {
    competencies: IGradeDevelopmentPlanCompetencies[];
}

export const DevelopmentPlanCompetenceStepper: FC<DevelopmentPlanCompetenceItemProps> = ({ competencies }) => {
    const { pdpHash } = useParams()
    const theme = useTheme()
    return (
        <Stepper
            orientation="vertical"
            nonLinear
            sx={{
                ' .MuiStepConnector-root': {
                    marginLeft: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        width: '100%',
                        height: '1px',
                        position: 'absolute',
                        top: '50%',
                        left: '26px',
                        backgroundColor: '#E2E7E9',
                    },
                },
                '.MuiStepConnector-root .MuiStepConnector-line': {
                    borderColor: theme.palette.primary.main,
                    borderLeftWidth: 2,
                    opacity: 0.2,
                },
                '.MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
                    borderColor: theme.palette.background.default,
                    borderLeftWidth: 2,
                    opacity: 1,
                },
            }}
        >
            {competencies?.map(step =>
                <Step
                    key={step.uuid}
                    disabled
                    sx={{
                        '.MuiStepLabel-root': {
                            padding: 0,
                        },
                        '& .MuiStepIcon-root': {
                            width: 32,
                            height: 32,
                            borderRadius: 4,
                            border: '2px solid white',
                            boxShadow: '0px 2px 8px -1px rgba(9, 137, 165, 0.45), 0px 10px 40px rgba(9, 137, 165, 0.25)',
                            color: theme.palette.primary.main,
                        },
                        '& .MuiStepIcon-root.Mui-active': {
                            boxShadow: '0px 2px 8px -1px rgba(31, 156, 92, 0.45), 0px 10px 40px rgba(31, 156, 92, 0.25)',
                            color: theme.palette.success.main,
                        },
                        '.Mui-disabled .MuiStepIcon-root': {
                            boxShadow: '0px 6px 8px 1px rgba(63, 86, 91, 0.04), 0px 8px 56px 2px rgba(63, 86, 91, 0.04)',
                            color: theme.palette.background.default,
                        },
                        '.Mui-disabled .MuiStepIcon-text': {
                            fill: 'rgba(19, 39, 43, 0.5)',
                        },
                    }}
                >
                    <StepLabel sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            px={2}
                        >
                            <Box display="flex" justifyContent="space-between" flex={1}>
                                <Box>
                                    <Box mb={0.5}>
                                        <Link
                                            component={RouterLink}
                                            to={`${PATH_OPEN_ACCESS}/${PATH_DEVELOPMENT_PLAN}/${pdpHash}/competence/${step.uuid}/${step.entityType}`}
                                            variant="body1"
                                        >
                                            {step.title}
                                        </Link>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            lineHeight: '16px',
                                            letterSpacing: '0.4px',
                                        }}
                                    >
                                        {step.typeTitle}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        padding: '4px 6px 4px 8px',
                                        alignSelf: 'center',
                                        borderRadius: 2,
                                        backgroundColor: theme.palette.background.default,
                                    }}
                                >
                                    <Typography variant="caption">
                                        {step.skillLevelTitle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </StepLabel>
                </Step>,
            )}
        </Stepper>
    )
}
