import React, { memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Chip, Paper, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_QUESTIONS_FOR_LEVEL } from 'helpers/enums/titles'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    EmployeeAssessmentIndicator,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentCompetencies/components/EmployeeAssessmentCompetence/components/EmployeeAssessmentIndicator/EmployeeAssessmentIndicator'

type MyAssessmentPassSkillLevelMobileProps = {
    competenceUuid: string;
    skillLevelUuid: string;
}

export const MyAssessmentPassSkillLevelMobile = memo(
    function EmployeeAssessmentSkillLevel({
        competenceUuid,
        skillLevelUuid,
    }: MyAssessmentPassSkillLevelMobileProps) {
        const skillLevel = useAppSelector(state => state.MyAssessmentPassReducer.data.competenceSkillLevel[`${competenceUuid}-${skillLevelUuid}`])
        const competenceSkillLevelIndicatorUuids = useAppSelector(state => state
            .MyAssessmentPassReducer
            .data
            .competenceSkillLevelIndicatorUuids[`${competenceUuid}-${skillLevelUuid}`],
        )

        if (!skillLevel) return null

        return (
            <Stack spacing={4}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Stack spacing={1} alignItems="flex-start">
                        <Typography variant="body2">
                            {TITLE_NAMESPACE_QUESTIONS_FOR_LEVEL}
                        </Typography>
                        <ChipSkillLevel skillLevelTitle={skillLevel.title}/>
                    </Stack>
                    <Chip color="warning" label={`${skillLevel.score}%`}/>
                </Stack>
                {competenceSkillLevelIndicatorUuids?.map((indicatorUuid, idx) =>
                    <Paper key={indicatorUuid} variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                        <EmployeeAssessmentIndicator
                            indicatorUuid={indicatorUuid}
                            competenceUuid={competenceUuid}
                            idx={idx + 1}
                        />
                    </Paper>)}
            </Stack>
        )
    },
)
