import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { addDays, isPast, parse } from 'date-fns'
import { TITLE_NAMESPACE_CURRENT, TITLE_NAMESPACE_EMPLOYEE, TITLE_NAMESPACE_TOTAL } from 'helpers/enums/titles'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { getCpaType } from 'helpers/cpa/cpaTypes'
import { cpaRoleColleague, cpaRoleManager, cpaRoleSubordinate } from 'helpers/cpa/cpaRoles'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'

export interface IAssessmentResultSetup {
    viewerUuid: string;
    viewerIsEvaluated: boolean;
    viewerIsCurrentReviewer: boolean;
    evaluatedIsInitiator: boolean;

    isAllComplete: boolean;
    isAllUnComplete: boolean;

    isValidDateEnd: boolean;
    isValidFinishedAt: boolean;
    isExpire: boolean;

    createdStatus: boolean;
    estimationStatus: boolean;
    confirmationStatus: boolean;
    summarizingStatus: boolean;
    awaitCompeteStatus: boolean;
    completeStatus: boolean;
    notCompleteStatus: boolean;

    canSeeCriteria: boolean;

    isCompleteData: boolean;
    someCompetenceHasDivergent: boolean;

    hasNotPdp: boolean;
    hasAnyPdp: boolean;
    hasCurrentPdp: boolean;
    canCreatePdp: boolean;
    showAlertPdp: boolean;
    alertPdpText: string;

    isSelfAssessment: boolean;

    positionTitle: string;
    evaluatedFullName: string;
}

// делаем из дерева компетенций плоский список для мобилки
export const getFlatListCompetence = (assessment: IAssessmentResult): IAssessmentCompetenceGroupCompetence[] => {
    const flatList: IAssessmentCompetenceGroupCompetence[] = []
    const queue = [...assessment.groupCompetence]

    while (queue.length) {
        const group = queue.shift()

        if (!group) break

        queue.push(...group.groupCompetence)

        group.competencies.forEach(competence => flatList.push(competence))
    }
    return flatList
}

// Подготовка необходимых условий по оценке
export const prepareResultState = (assessment: IAssessmentResult, viewerUuid: string): IAssessmentResultSetup => {
    const assessmentStatus = getAssessmentStatus(assessment?.statusUuid)
    const createdStatus = assessmentStatus?.code === 'created'
    const estimationStatus = assessmentStatus?.code === 'estimation'
    const confirmationStatus = assessmentStatus?.code === 'confirmation'
    const summarizingStatus = assessmentStatus?.code === 'summarizing'
    const completeStatus = assessmentStatus?.code === 'complete'
    const awaitCompeteStatus = assessmentStatus?.code === 'awaitComplete'
    const notCompleteStatus = assessmentStatus?.code === 'notComplete'

    const evaluatedIsInitiator = assessment?.initiator?.uuid === assessment?.evaluated?.uuid
    const viewerIsEvaluated = assessment?.evaluated?.uuid === viewerUuid
    const viewerIsCurrentReviewer = Boolean(assessment?.currentReviewer.find(el => el.uuid === viewerUuid))

    const canSeeCriteria = !viewerIsEvaluated || evaluatedIsInitiator || completeStatus


    const isValidDateEnd = isValidDateFromApi(assessment?.endedAt)
    const isValidFinishedAt = isValidDateFromApi(assessment.finishedAt)
    const isExpire = isValidDateEnd && !completeStatus && isPast(addDays(parse(assessment.endedAt, 'dd.MM.yyyy', new Date()), 1))

    const isAllComplete = assessment.progress.completedCount === assessment.progress.assessmentsCount
    const isAllUnComplete = assessment.progress.completedCount === 0

    // Получаем информацию о компетенциях, наличий расхождений между ролями и ответы "не знаю"
    const { isCompleteData, someCompetenceHasDivergent } = prepareResultWithTree(assessment)

    const hasNotPdp = !assessment?.pdp || isEmptyUuid(assessment.pdp?.uuid)
    const hasAnyPdp = !!assessment?.pdp && !assessment?.pdp?.current
    const hasCurrentPdp = !!assessment?.pdp && assessment?.pdp?.current
    // Можем создать ипр на основании, если есть хоть 1 компетенция ниже 75
    const canCreatePdp = findSomeCompetenceLessScore(assessment?.groupCompetence, 75)
    const showAlertPdp = completeStatus && canCreatePdp
    const alertPdpText = getAlertPdpText(hasNotPdp, hasCurrentPdp, hasAnyPdp)

    const isSelfAssessment = getCpaType(assessment?.cpa?.typeUuid) === 'self'
        || getCpaType(assessment.typeUuid) === 'self'
        || !assessment?.cpa

    const evaluatedFullName = `${assessment?.evaluated?.lastName} ${assessment?.evaluated?.firstName} ${assessment?.evaluated?.secondName}`

    const evaluatedSpecializationUuid = assessment?.evaluated?.currentPosition?.specializationUuid
    const evaluatedGradeUuid = assessment?.evaluated?.currentPosition?.gradeUuid

    const assessmentPointPositionTitle = evaluatedSpecializationUuid === assessment?.position?.specializationUuid
        && evaluatedGradeUuid === assessment?.position?.gradeUuid
        && `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || assessment?.position?.title

    const positionTitle = (assessment?.cpa?.criteria?.uuid === cpaCriteriaCurrentUuid
        ? `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        : assessmentPointPositionTitle || assessment?.cpa?.criteria.title) ?? ''


    return {
        viewerUuid,
        notCompleteStatus,
        createdStatus,
        estimationStatus,
        confirmationStatus,
        summarizingStatus,
        completeStatus,
        awaitCompeteStatus,
        evaluatedIsInitiator,
        viewerIsEvaluated,
        isValidDateEnd,
        isValidFinishedAt,
        canSeeCriteria,
        isExpire,
        viewerIsCurrentReviewer,
        isCompleteData,
        someCompetenceHasDivergent,
        isAllComplete,
        hasNotPdp,
        hasAnyPdp,
        hasCurrentPdp,
        showAlertPdp,
        canCreatePdp,
        alertPdpText,
        isAllUnComplete,
        isSelfAssessment,
        evaluatedFullName,
        positionTitle,
    }
}

const getAlertPdpText = (hasNotPdp: boolean, hasCurrentPdp: boolean, hasAnyPdp: boolean) => {
    switch (true) {
        case hasNotPdp: {
            return 'На основе этой оценки можно сформировать план развития'
        }
        case hasCurrentPdp: {
            return 'На основе этой оценки был сформирован план развития'
        }
        case hasAnyPdp: {
            return 'На основе этой оценки можно будет сформировать план развития, когда текущий план развития сотрудника будет завершен'
        }
        default: {
            return ''
        }
    }
}

const findSomeCompetenceLessScore = (group: IAssessmentCompetenceGroup[] | undefined, score: number): boolean => {
    if (!group || group.length <= 0) return false
    return group.some(group => {
        return group.competencies.some(competence => competence.score < score)
            || findSomeCompetenceLessScore(group.groupCompetence, score)
    })
}

// Подготовка графика соответствия грейдам
// TODO: need use useTheme to set colors from palette primary main and light
export const prepareGraphicBar = (
    accordance: IAssessmentAccordance[] | null,
    highColor = '#0989A5',
    lowColor = '#84C4D2',
) => {
    const keys: string[] = []
    const data: Record<string, string | number>[] = []
    // const arr = Object.assign([] as IAssessmentAccordance[], accordance)
    // arr.sort((a, b) => b.score - a.score)
    let arrayOfHighlightGradeUuid: string[] = []
    let gradeScoreMax = 0
    accordance?.forEach(grade => {
        if (gradeScoreMax > grade.score) return
        if (gradeScoreMax < grade.score) {
            arrayOfHighlightGradeUuid = [grade.grade.uuid]
            gradeScoreMax = grade.score
        }
        if (gradeScoreMax === grade.score)
            arrayOfHighlightGradeUuid.push(grade.grade.uuid)
    })

    accordance?.forEach(grade => {
        keys.push(grade.grade.title)
        data.push({
            title: grade.grade.title,
            [grade.grade.title]: grade.score,
            color: arrayOfHighlightGradeUuid.includes(grade.grade.uuid) ? highColor : lowColor,
        })
    })
    return { data, keys }
}

export const prepareResultWithTree = (assessment: IAssessmentResult) => {
    let competenciesCount = 0
    let competenceIsCalculatedCount = 0
    // Флаг наличия хотя бы 1 ответа с расхождениями
    let someCompetenceHasDivergent = false

    // Проверяем незавершенность внутри ответов
    const checkIsUnCompletedAnswers = (answers: IAssessmentIndicatorAnswer[]) => {
        let unCompletedAnswerCount = 0
        const countOfAnswerWithoutFinal = answers.filter(el => el.respondentUuid !== 'final').length
        answers.forEach(answer => {
            someCompetenceHasDivergent = Boolean(someCompetenceHasDivergent || answer.role?.divergent)
            unCompletedAnswerCount += !answer?.isCalculated && answer.respondentUuid !== 'final' ? 1 : 0
        })
        const allUncompleted = unCompletedAnswerCount === countOfAnswerWithoutFinal
        const gtHalfUncompleted = unCompletedAnswerCount >= countOfAnswerWithoutFinal / 2
        return { allUncompleted, gtHalfUncompleted }
    }

    // Проверка индикаторов на количество незавершенных ответов
    const checkIsUnCompletedIndicators = (indicators: IAssessmentIndicator[]) => {
        let unCompletedIndicatorCount = 0
        let allUncompletedIndicator = true
        indicators.forEach(indicator => {
            const { allUncompleted, gtHalfUncompleted } = checkIsUnCompletedAnswers(indicator.answers)
            allUncompletedIndicator = allUncompletedIndicator && allUncompleted
            unCompletedIndicatorCount += gtHalfUncompleted ? 1 : 0
        })
        const gtHalfUncompleted = unCompletedIndicatorCount >= indicators.length / 2
        return { gtHalfUncompleted, allUncompletedIndicator }
    }

    // Проверка компетенций на количество незавершенных ответов
    const checkCompetencies = (competencies: IAssessmentCompetenceGroupCompetence[]) => {
        let isAllUnComplete = true
        competencies.forEach(competence => {
            const { gtHalfUncompleted, allUncompletedIndicator } = checkIsUnCompletedIndicators(competence.indicators)
            competenciesCount++
            competenceIsCalculatedCount += gtHalfUncompleted ? 0 : 1
            // eslint-disable-next-line no-param-reassign
            competence.isUncompleted = gtHalfUncompleted
            isAllUnComplete = isAllUnComplete && allUncompletedIndicator
        })
        return isAllUnComplete
    }

    // Проверка групп компетенций на количество незавершенных ответов
    const checkGroup = (group: IAssessmentCompetenceGroup): boolean => {
        const isAllUncompleted = checkCompetencies(group.competencies)
        let groupHasUncompleted = group.groupCompetence.length > 0
        if (group.groupCompetence.length)
            groupHasUncompleted = group.groupCompetence.every(el => checkGroup(el))

        // Группа считается не завершенной в случае если все подгруппы незавершены и все компетенции этой группы
        // eslint-disable-next-line no-param-reassign
        group.isUncompleted = isAllUncompleted && groupHasUncompleted

        return isAllUncompleted && groupHasUncompleted
    }

    // Инициализация проверки с верхнего уровня групп
    assessment.groupCompetence.forEach(group => checkGroup(group))

    // Оценка считается завершенной в случае, когда рассчитанных ответов больше половины
    const isCompleteData = competenceIsCalculatedCount > competenciesCount / 2

    return { isCompleteData, someCompetenceHasDivergent }
}

// Рассчет сильных и слабых сторон
export const getResultSides = (tree: IAssessmentCompetenceGroup[] | undefined) => {
    if (!tree) return null

    const queue = [...tree]

    const weak: { score: number; title: string }[] = []
    const strong: { score: number; title: string }[] = []

    while (queue.length) {
        const group = queue[0]
        queue.shift()

        group.competencies.forEach(competence => {
            const item = { score: competence.score, title: competence.title }
            competence.score > 75 ? strong.push(item) : weak.push(item)
        })

        if (group?.groupCompetence?.length)
            queue.push(...group.groupCompetence)
    }

    return {
        // По ТЗ слабых сторон нужно показывать не более 3
        weak: weak.sort((a, b) => a.score - b.score).slice(0, 3),
        strong,
    }
}


export type TSpiderData = {
    title: string;
    discrepancy: number;
} & Record<string, string | number>

// Класс по работе с данными для графика паутинки по ролям
class RolesValues {
    // Значения для отображения данных
    private competenciesValues: TSpiderData[] = []
    // Ключи графика, необходимы для рендера графика
    private keys = new Set<string>()
    private isSorted = false
    private isSliced = false

    constructor(private roles: IEntity[], private isMobile?: boolean) {}

    // Парсим компетенцирь по значениям
    setCompetenceValues(competence: IAssessmentCompetenceGroupCompetence) {
        // Если нет финального ответа,не нужно выполнять рассчет
        if (!competence.scores.some(el => el.userUuid === 'final' || el.roleUuid === 'final')) return
        this.isSorted = false
        // Находим ответ сотрудника (его ответ, там где нет роли)
        const employeeAnswer = competence.scores.find(el => !el.roleUuid)
        let discrepancy = 0
        const currentScores: Record<string, any> = {}
        const colors: Record<string, any> = {}

        // Собираем мапу всех ответов по ролям
        for (let i = 0; i < competence.scores.length; i++) {
            const score = competence.scores[i]
            const isFinalAnswer = score.roleUuid === 'final'
            const isAnswerEmployee = !score.roleUuid
            const value = score.score
            // Получаем имя роли
            const roleTitle = this.roles.find(el => el.uuid === score.roleUuid)?.title ?? TITLE_NAMESPACE_EMPLOYEE

            // Находим расхождения внутри ответов. Если это финальный ответ или ответ сотрудника, расхождения нет
            discrepancy = isAnswerEmployee || isFinalAnswer
                ? discrepancy
                : Math.max(discrepancy, Math.abs(employeeAnswer?.score ?? 0 - value))

            const title = isFinalAnswer ? TITLE_NAMESPACE_TOTAL : roleTitle

            this.keys.add(title)

            currentScores[title] = value
            // Устанавливаем цвет для роли по ответу
            colors[`${title}-color`] = this.getColorByRole(score.roleUuid)
        }

        this.competenciesValues.push({ title: competence.title, discrepancy, ...currentScores, ...colors })
    }

    private getColorByRole = (roleUuid: string) => {
        const colorFinal = '#F47560'
        const colorEmployee = '#E8C1A0'
        const colorManager = '#61CDBB'
        const colorColleague = '#F1E15B'
        const colorSubordinate = '#E8A838'

        switch (true) {
            case roleUuid === 'final':
                return colorFinal
            case roleUuid === cpaRoleManager:
                return colorManager
            case roleUuid === cpaRoleColleague:
                return colorColleague
            case roleUuid === cpaRoleSubordinate:
                return colorSubordinate
            default:
                return colorEmployee
        }
    }

    getCompetenciesValues() {
        // В случае превышения количества компетенций, отрезаем их для отображения на графике, сортируем по расхождениям
        if (this.competenciesValues.length > 15) {
            this.sortValues()
            return this.competenciesValues.slice(0, 14)
        }

        this.isMobile && this.setNumericTitles()
        return this.competenciesValues
    }

    // Устанавливаем циферные обозначения, для мобильной версии графика
    private setNumericTitles() {
        this.competenciesValues =
            this.competenciesValues
                .map((el, i) => ({...el, title: `${i + 1}. ${el.title}`}))
    }

    private sortValues() {
        if (this.isSorted) return
        this.competenciesValues.sort((a, b) => b.discrepancy - a.discrepancy)
        this.isMobile && this.setNumericTitles()
        this.isSorted = true
        this.isSliced = true
    }

    getKeys() {
        return Array.from(this.keys)
    }

    getIsSliced() {
        return this.isSliced
    }
}

// Функция получения данных для графика
export const getSpiderData = (
    tree: IAssessmentCompetenceGroup[] | undefined | null,
    roles: IEntity[],
    isMobile?: boolean,
): {data: TSpiderData[]; keys: string[]; isSliced: boolean} => {
    if (!tree || !roles?.length)
        return { data: [], keys: [], isSliced: false }

    // Регистрируем роли по которым будет отбор
    const rolesValues = new RolesValues(roles, isMobile)
    const queueGroups = [...tree]

    while (queueGroups.length) {
        const group = queueGroups.shift()

        // Проходимся по каждой компетенции и добавляем ее в рассчет
        group?.competencies.forEach(competence => {
            rolesValues.setCompetenceValues(competence)
        })

        // push to queue
        if (group?.groupCompetence?.length)
            queueGroups.push(...group.groupCompetence)
    }

    const keys = rolesValues.getKeys()
    const data = rolesValues.getCompetenciesValues()
    const isSliced = rolesValues.getIsSliced()

    return { data, keys, isSliced }
}
