import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeePersonalDataUpdate } from 'api/typing/employeeTypes'

const initialState: IState<IEmployee> = {
    data: {} as IEmployee,
    isLoading: false,
    error: null,
}

export const employeeEditPersonalDataSlice = createSlice({
    name: 'employeeEditPersonalData',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getEmployeePersonalData: (state, action: PayloadAction<IEmployee>) => {
            state.data = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        updateEmployeePersonalData: (state, action: PayloadAction<IEmployeePersonalDataUpdate>) => {
            state.data = {
                ...state.data,
                ...action.payload,
            }
            state.isLoading = false
            state.error = null
        },

        updateEmployeePhoto: (state, action: PayloadAction<string>) => {
            state.data = {
                ...state.data,
                photo: action.payload,
            }
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployee
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditPersonalDataReducer = employeeEditPersonalDataSlice.reducer
