import React from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import { StoreLayer } from 'layers/StoreLayer/StoreLayer'
import { QueryClientLayer } from 'layers/QueryClientLayer/QueryClientLayer'
import { ThemeLayer } from 'layers/ThemeLayer/ThemeLayer'
import { SnackbarLayer } from 'layers/SnackbarLayer/SnackbarLayer'
import { LocalizationLayer } from 'layers/LocalizationLayer/LocalizationLayer'
import { App } from 'App'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)
root.render(
    <BrowserRouter>
        <StoreLayer>
            <React.StrictMode>
                <QueryClientLayer>
                    <ThemeLayer>
                        <SnackbarLayer>
                            <LocalizationLayer>
                                <App/>
                            </LocalizationLayer>
                        </SnackbarLayer>
                    </ThemeLayer>
                </QueryClientLayer>
            </React.StrictMode>
        </StoreLayer>
    </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
