import React, { FC, ReactNode } from 'react'
import { setupStore } from 'store/store'
import { Provider } from 'react-redux'

const store = setupStore()

type StoreLayer = {
    children: ReactNode;
}

export const StoreLayer: FC<StoreLayer> = ({children}) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}
