import { AppDispatch } from 'store/store'
import { AnalyticService } from 'api/services/AnalyticService'
import { CPAItemAnalyticsSlice } from 'store/reducers/cpa/item/CPAItemAnalyticsSlice'

export class AnalyticCpaAction {
    private static get analyticApi() {
        return new AnalyticService().analyticService()
    }

    public setCpaCurrentPosition(data: TAnalyticCpaCurrentPosition[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaCurrentPosition(data))
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaCurrentPositionFlatEmployees(data))
        }
    }

    public setCpaPointCompetence(data: TAnalyticCpaCriteriaCompetence) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaPointCompetence(data))
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaPointCompetenceFlatEmployees(data))
        }
    }

    public setCpaPointCompetenceForCurrentPosition(data: TAnalyticCpaCriteriaCompetence) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaCurrentPositionV2(data))
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaPointCompetenceFlatEmployees(data))
        }
    }

    public setCpaCompetenceTree(data: TAnalyticCpaCompetenceTreeGroup[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setCpaCompetenceTree(data))
        }
    }

    public setSummary(data: TAnalyticCpaStatisticSummary) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setSummary(data))
        }
    }

    public setDivisionListInitial(data: ISpecializationMetaForCreateDivision[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setDivisionListInitial(data))
        }
    }

    public setSpecializationListInitial(data: IFormSpecialization[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.setSpecializationListInitial(data))
        }
    }

    public selectFilterDivision(data: string[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.selectFilterDivision(data))
        }
    }

    public selectFilterSpecialization(data: string[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.selectFilterSpecialization(data))
        }
    }

    public selectFilterCompetence(data: string[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.selectFilterCompetence(data))
        }
    }

    public selectFilterEmployee(data: string[]) {
        return async (dispatch: AppDispatch) => {
            await dispatch(CPAItemAnalyticsSlice.actions.selectFilterEmployee(data))
        }
    }
}
