import { AppDispatch, RootState } from 'store/store'
import { CPAService } from 'api/services/CPAService'
import {
    ICpaDeleteEmployeeRequest,
    ICpaGetEmployeesForAddResponse,
    ICpaGetEmployeesResponse,
    ICpaSaveEmployeesToCpaRequest,
} from 'api/typing/cpaTypes'
import { CPAItemEmployeesSlice } from 'store/reducers/cpa/item/CPAItemEmployeesSlice'
import { CPAItemSlice } from 'store/reducers/cpa/item/CPAItemSlice'
import { CPAItemAnalyticsSlice } from 'store/reducers/cpa/item/CPAItemAnalyticsSlice'


export class CpaItemEmployeesAction {
    private static get cpaItemApi() {
        return new CPAService().itemService()
    }

    public setCurrentEmployees(data: ICpaGetEmployeesResponse) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.setCurrentEmployees(data))
            dispatch(CPAItemAnalyticsSlice.actions.employeesFlatForSummary(data))
        }
    }

    public selectAllEmployeeList() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.selectAllEmployeeList())
        }
    }

    public removeAllEmployeeList() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.removeAllEmployeeList())
        }
    }

    public setSelectStateDivision(payload: { divisionUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.setSelectStateDivision(payload))
        }
    }

    public setSelectStateEmployee(payload: { employeeUuid: string; isSelected: boolean }) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.setSelectStateEmployee(payload))
        }
    }

    public setEmployeesList(data: ICpaGetEmployeesForAddResponse[]) {
        return (dispatch: AppDispatch, getState: () => RootState) => {
            const cpaUuid = getState().CPAItemReducer.meta.uuid
            dispatch(CPAItemEmployeesSlice.actions.setEmployeesList({ list: data, cpaUuid }))
        }
    }

    public restoreEmployeeList() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.restoreEmployeeList())
        }
    }

    public updateEmployeeStatusFromPage(data: ICpaEmployee) {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.updateEmployeeStatusFromPage(data))
        }
    }

    public saveEmployeesToCpa(data: ICpaSaveEmployeesToCpaRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemEmployeesSlice.actions.fetching())
                void await CpaItemEmployeesAction.cpaItemApi.saveEmployeesToCpa(data)
                dispatch(CPAItemEmployeesSlice.actions.saveEmployeesToCpa())
                dispatch(CPAItemSlice.actions.updateEmployees(data))
                dispatch(CPAItemEmployeesSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemEmployeesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteEmployee(data: ICpaDeleteEmployeeRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(CPAItemEmployeesSlice.actions.fetching())
                void await CpaItemEmployeesAction.cpaItemApi.deleteCpaEmployee(data)
                dispatch(CPAItemEmployeesSlice.actions.deleteEmployee(data))
                dispatch(CPAItemSlice.actions.deleteEmployee())
                dispatch(CPAItemEmployeesSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(CPAItemEmployeesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(CPAItemEmployeesSlice.actions.clearState())
        }
    }
}
