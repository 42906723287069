import React, { FC } from 'react'
import logo from 'assets/icons/logo.svg'
import { Box, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

type AppMenuMobileDrawerHeader = {
    handleCloseDrawer: () => void;
}

export const AppMenuMobileDrawerHeader: FC<AppMenuMobileDrawerHeader> = ({ handleCloseDrawer }) => {
    return (
        <Box display="flex" justifyContent="space-between" px={3} py={2}>
            <img src={logo} alt="Grade Factor logo" height={32}/>
            <IconButton
                color="primary"
                onClick={handleCloseDrawer}
            >
                <CloseRounded/>
            </IconButton>
        </Box>
    )
}
