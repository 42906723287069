import {BaseApi} from 'api/index'
import { IReportAnalytic, IReportAssessment, IReportCPA } from 'api/typing/reportServiceTypes'

export class Report extends BaseApi {
    protected endpoint = 'report'

    public getReportAssessment(data: IReportAssessment) {
        const path = `assessment/${data.assessmentUuid}`
        return this.request<IReportAssessment, IReportAssessment>('POST', path, {data})
    }

    public getReportCPA(data: IReportCPA) {
        const path = `cpa/${data.cpaUuid}`
        return this.request<IReportCPA, IReportCPA>('POST', path, {data})
    }

    public getReportAnalytic(data: IReportAnalytic) {
        const path = 'analytics'
        return this.request<IReportAnalytic, IReportAnalytic>('POST', path, {data})
    }
}
