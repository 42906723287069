import React from 'react'
import {useAppSelector} from 'store/hooks/redux'
import { Box, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { ChipAssessmentStatus } from 'components/chips/ChipAssessmentStatus/ChipAssessmentStatus'
import {
    TITLE_BUTTON_ASSESSMENT_GO_TO,
    TITLE_NAMESPACE_ACCORDANCE,
    TITLE_NAMESPACE_APPROVED,
    TITLE_NAMESPACE_COMPLETE,
    TITLE_NAMESPACE_ENDED_TO,
    TITLE_NAMESPACE_INITIATOR,
    TITLE_NAMESPACE_REVIEWER,
    TITLE_NAMESPACE_WAS_CREATED,
} from 'helpers/enums/titles'
import { AssignmentRounded, DateRangeRounded, PersonRounded, PlayArrowRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
    MyAssessmentMetaAlert,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaAlert/MyAssessmentMetaAlert'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { ChipAssessmentType } from 'components/chips/ChipAssessmentType/ChipAssessmentType'
import {
    MyAssessmentMetaSecondaryActions,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaSecondaryActions/MyAssessmentMetaSecondaryActions'
import {
    MyAssessmentMetaViewButton,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaViewButton/MyAssessmentMetaViewButton'
import {
    MyAssessmentMetaActionButton,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaActionButton/MyAssessmentMetaActionButton'
import {
    MyAssessmentMetaCriteria,
} from 'pages/assessment/MyAssessmentMeta/comnponents/MyAssessmentMetaCriteria/MyAssessmentMetaCriteria'

export const MyAssessmentMetaDesktop = () => {
    const navigate = useNavigate()
    const assessmentMeta = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMeta)
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)
    if (!assessmentMeta || !assessmentMetaSetup) return null

    if (!assessmentMetaSetup?.viewerIsEvaluated) {
        return (
            <EmptyPage
                variant="attention"
                contentText="У вас нет прав для просмотра страницы этой оценки. Если вы являетесь её участником, можете перейти к прохождению."
            >
                {!assessmentMeta.isCompleteByRequestingUser ?
                    <Button
                        startIcon={<PlayArrowRounded/>}
                        variant="contained"
                        onClick={() => navigate('pass')}
                    >
                        {TITLE_BUTTON_ASSESSMENT_GO_TO}
                    </Button>
                    :
                    null
                }
            </EmptyPage>
        )
    }

    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack spacing={3} direction="row" alignItems="center">
                            <Box flex={1} overflow="hidden">
                                <Typography variant="h1">
                                    {assessmentMeta.title}
                                </Typography>
                            </Box>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <MyAssessmentMetaActionButton/>
                                <ChipAssessmentStatus status={assessmentMetaSetup.displayedStatusUuid}/>
                                <MyAssessmentMetaSecondaryActions/>
                            </Stack>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <ChipAssessmentType
                                cpaType={assessmentMeta?.cpa?.typeUuid ?? assessmentMeta?.typeUuid}
                            />
                            <Typography variant="body1">
                                {TITLE_NAMESPACE_WAS_CREATED}: {assessmentMeta?.createdAt}
                            </Typography>
                        </Stack>
                    </Stack>
                    <MyAssessmentMetaAlert/>
                    <Divider/>
                    <Stack
                        direction="row"
                        spacing={3}
                        divider={<Divider orientation="vertical" flexItem/>}
                    >
                        <Stack flex={1} spacing={2}>
                            {assessmentMetaSetup?.isValidDate &&
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <DateRangeRounded color="warning"/>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: ({ palette, typography }) => assessmentMetaSetup?.isExpire
                                                ? palette.warning.main
                                                : typography.body1.color,
                                        }}
                                    >
                                        {assessmentMetaSetup?.completeStatus
                                            ? `${TITLE_NAMESPACE_COMPLETE} ${assessmentMeta?.finishedAt}`
                                            : `${TITLE_NAMESPACE_ENDED_TO} ${assessmentMeta?.endedAt}`}
                                    </Typography>
                                </Stack>}
                            <Stack spacing={1} direction="row" alignItems="center">
                                <AssignmentRounded color="warning"/>
                                <Typography variant="body1">
                                    {`${TITLE_NAMESPACE_INITIATOR} - ${assessmentMeta.initiator?.name}`}
                                </Typography>
                            </Stack>
                            {assessmentMeta.approver && <Stack spacing={1} direction="row" alignItems="center">
                                <PersonRounded color="warning"/>
                                <Typography variant="body1">
                                    {`${TITLE_NAMESPACE_APPROVED} - ${assessmentMeta.approver?.name}`}
                                </Typography>
                            </Stack>}
                            <Stack spacing={1}>
                                {assessmentMeta?.currentReviewer?.map(el => <Stack
                                    key={el.uuid}
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                >
                                    <PersonRounded color="warning"/>
                                    <Typography variant="body1">
                                        {`${TITLE_NAMESPACE_REVIEWER} - ${el.name}`}
                                    </Typography>
                                </Stack>)}
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={3}
                                divider={<Divider orientation="vertical" flexItem/>}
                            >
                            </Stack>
                            <Box>
                                <MyAssessmentMetaViewButton/>
                            </Box>
                        </Stack>
                        <Stack
                            flex={1}
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            height="100%"
                        >
                            <MyAssessmentMetaCriteria/>
                            {assessmentMetaSetup?.completeStatus && <Typography variant="h2">
                                {TITLE_NAMESPACE_ACCORDANCE}: {assessmentMeta.score}%
                            </Typography>}
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

