import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IOpenAccessState {
    title: string;
}

const initialState: IOpenAccessState = {
    title: '',
}

export const openAccessSlice = createSlice({
    name: 'openAccess',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload
        },
    },
})

export const openAccessReducer = openAccessSlice.reducer
