import React, { FC } from 'react'
import { Divider, Stack } from '@mui/material'
import {
    AppMenuMobileDrawerContentList,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/components/AppMenuMobileDrawerContent/components/AppMenuMobileDrawerContentList/AppMenuMobileDrawerContentList'
import { additionalMenuItems, mainMenuItems } from 'helpers/enums/menuItemsPosition'
import {
    AppMenuMobileDrawerContentProfile,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/components/AppMenuMobileDrawerContent/components/AppMenuMobileDrawerContentProfile/AppMenuMobileDrawerContentProfile'

type AppMenuMobileDrawerContentProps = {
    handleCloseDrawer: () => void;
}


export const AppMenuMobileDrawerContent: FC<AppMenuMobileDrawerContentProps> = ({ handleCloseDrawer }) => {
    return (
        <Stack flex="1" overflow="auto" spacing={3} divider={<Divider/>}>
            <AppMenuMobileDrawerContentProfile/>
            <Stack spacing={1} divider={<Divider variant="middle" flexItem/>}>
                <AppMenuMobileDrawerContentList
                    list={mainMenuItems}
                    handleCloseDrawer={handleCloseDrawer}
                />
                <AppMenuMobileDrawerContentList
                    list={additionalMenuItems}
                    handleCloseDrawer={handleCloseDrawer}
                />
            </Stack>
        </Stack>
    )
}
