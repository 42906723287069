import React, { FC, useMemo } from 'react'
import { Box, Chip } from '@mui/material'
import { InfoOutlined, WarningAmberRounded } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/redux'

type EmployeeAssessmentIndicatorDivergentProps = {
    indicatorUuid: string;
}

export const EmployeeAssessmentIndicatorDivergent: FC<EmployeeAssessmentIndicatorDivergentProps> = ({
    indicatorUuid,
}) => {
    const indicator = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators[indicatorUuid])

    const localDivergent = useMemo(() => {
        return indicator?.needAction || indicator?.answers?.some(el => el.role?.divergent)
    }, [indicator])
    const notCalculated = useMemo(() =>
        indicator.answers.length / 2 <= indicator.answers.filter(el => !el.isCalculated).length, [indicator])

    if (!localDivergent) return null

    return (
        <Box>
            {!notCalculated && <Chip
                label="Расхождения"
                icon={<WarningAmberRounded/>}
                variant="outlined"
                color="warning"
                size="small"
                sx={{
                    padding: '4px 4px 4px 12px',
                    height: 'auto',

                    '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                    },
                }}
            />}
            {notCalculated && <Chip
                label="Недостаточно данных для объективной оценки "
                icon={<InfoOutlined/>}
                variant="outlined"
                color="error"
                size="small"
                sx={{
                    padding: '4px 4px 4px 12px',
                    height: 'auto',

                    '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                    },
                }}
            />}
        </Box>
    )
}
