import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface IAnalyticState {
    assessment: IAnalyticAssessment;
    pdp: IAnalyticPdp;
}

const initialState: IState<IAnalyticState> = {
    data: {} as IAnalyticState,
    isLoading: false,
    error: null,
}

export const AnalyticSlice = createSlice({
    name: 'analytic',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setAnalyticAssessment: (state, action:PayloadAction<IAnalyticAssessment>) => {
            state.data.assessment = action.payload
        },

        setAnalyticPdp: (state, action:PayloadAction<IAnalyticPdp>) => {
            state.data.pdp = action.payload
        },
    },
})

export const analyticReducer = AnalyticSlice.reducer
