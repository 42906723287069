import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IState<IEmployeeWorkInCompanyWorkExperience> = {
    data: {} as IEmployeeWorkInCompanyWorkExperience,
    isLoading: false,
    error: null,
}

export const employeeEditWorkInCompanyWorkExperienceSlice = createSlice({
    name: 'employeeEditWorkInCompanyWorkExperience',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getWorkExperience: (state, action: PayloadAction<IEmployeeWorkInCompanyWorkExperience>) => {
            state.data = action.payload
            state.lastQuery = new Date().getTime()
            state.isLoading = false
            state.error = null
        },

        updateWorkExperience: (state, action: PayloadAction<IEmployeeWorkInCompanyWorkExperience>) => {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {} as IEmployeeWorkInCompanyWorkExperience
            state.lastQuery = null
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeEditWorkInCompanyWorkExperienceReducer = employeeEditWorkInCompanyWorkExperienceSlice.reducer
