import React, { FC } from 'react'
import { Box, FormControlLabel, Grid, Radio, Stack, Typography } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'

type EmployeeAssessmentPreviewIndicatorProps = {
    indicator: IAssessmentIndicator;
    idx: number
}

export const EmployeeAssessmentPreviewIndicator: FC<EmployeeAssessmentPreviewIndicatorProps> = ({
    idx,
    indicator,
}) => {

    const answers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)

    return (
        <Stack p={2} spacing={2} sx={{ borderRadius: 4 }}>
            <Stack direction="row" spacing={2} alignItems="flex-start">
                <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Box><Typography variant="body2">{idx}.</Typography></Box>
                    <Box><Typography variant="body2">{indicator.title}</Typography></Box>
                </Stack>
            </Stack>
            <Grid container spacing={2}>
                {answers.map(el =>
                    <Grid item zeroMinWidth mobile={12} key={el.uuid}>
                        <FormControlLabel
                            disabled
                            name={`${indicator.uuid}-${el.uuid}`}
                            value={''}
                            control={<Radio/>}
                            label={el.title}
                        />
                    </Grid>)}
            </Grid>
        </Stack>
    )
}
