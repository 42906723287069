import React, { FC, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { Box, Button, Chip, Stack } from '@mui/material'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CHANGE,
    TITLE_BUTTON_DELETE_ALL,
    TITLE_BUTTON_SAVE,
} from 'helpers/enums/titles'
import {
    CPACriteriaDrawerItemCompetenceSkillLevel,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/CPACriteriaDrawerItemCompetenceSkillLevel'
import {
    CPACriteriaDrawerItemCompetenceActionDrawer,
} from 'pages/cpa/CPAPage/components/CPACriteria/components/CPACriteriaHeader/components/CPACriteriaAction/components/CPACriteriaDrawer/components/CPACriteriaDrawerContent/components/CPACriteriaDrawerItem/components/CPACriteriaDrawerItemCompetence/components/CPACriteriaDrawerItemCompetenceSkillLevel/components/CPACriteriaDrawerItemCompetenceActionDrawer/CPACriteriaDrawerItemCompetenceActionDrawer'
import { ClearRounded, InfoOutlined } from '@mui/icons-material'
import { useNotify } from 'store/hooks/useNotify'
import { cpaItemCriteriaActions } from 'store/actions'
import { useCpaCriteriaCompetence } from 'api/queries/cpa/cpaQueries'
import { cpaCriteriaCompetenceUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { useParams } from 'react-router-dom'

type CPACriteriaDrawerItemCompetenceProps = {
    handleClose: () => void;
}
export const CPACriteriaDrawerItemCompetence: FC<CPACriteriaDrawerItemCompetenceProps> = ({ handleClose }) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { cpaUuid } = useParams()
    const { current, saved, isChanged } = useAppSelector(state => state.CPAItemCriteriaReducer.competenceCriteria)
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const { refetch } = useCpaCriteriaCompetence(cpaUuid || '', false)
    const someCompetenceChanged = useMemo(
        () => current
            ?.some(skillLevel =>
                skillLevel
                    .competencies
                    .some(competence =>
                        competence.currentSkillLevelUuid !== competence.initialSkillLevelUuid || competence.isChanged))
        , [current])
    const disableSave = saved?.length <= 0 && !someCompetenceChanged && !isChanged

    const setupCompetencies = async () => {
        if (!cpaUuid || !current.length) return
        const competencies: { uuid: string; skillLevelUuid: string; }[] = []
        current.forEach(el => {
            el.competencies.forEach(competence => {
                competencies.push({ uuid: competence.uuid, skillLevelUuid: competence.currentSkillLevelUuid })
            })
        })
        try {
            await dispatch(cpaItemCriteriaActions.saveCriteriaCompetence({
                cpaUuid,
                competencies,
            }))
            currentCriteriaUuid === cpaCriteriaCompetenceUuid && await refetch()
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const cancelEdit = () => {
        dispatch(cpaItemCriteriaActions.cancelEditCompetenceCriteria())
        handleClose()
    }

    const deleteAll = () => {
        dispatch(cpaItemCriteriaActions.clearCompetenceCriteria())
    }

    if (!current || !current?.length) return <EmptyPage
        contentText="Выберите компетенции, на которые будет производиться оценка"
    >
        <CPACriteriaDrawerItemCompetenceActionDrawer/>
    </EmptyPage>

    return (
        <Stack
            flex={1}
            alignItems="stretch"
            spacing={2}
        >
            <Chip
                variant="outlined"
                color="primary"
                icon={<InfoOutlined/>}
                size="small"
                label="Все уровни владения включают в себя индикаторы предыдущих уровней"
                sx={{ alignSelf: 'flex-start' }}
            />
            <Stack flex={1} spacing={3}>
                {current?.map(el => (<CPACriteriaDrawerItemCompetenceSkillLevel
                    key={el.uuid}
                    item={el}
                />))}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="text"
                        startIcon={<ClearRounded/>}
                        onClick={deleteAll}
                    >
                        {TITLE_BUTTON_DELETE_ALL}
                    </Button>
                    <CPACriteriaDrawerItemCompetenceActionDrawer
                        buttonTitle={TITLE_BUTTON_CHANGE}
                        buttonVariant="text"
                    />
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    variant="text"
                    onClick={cancelEdit}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={disableSave}
                    onClick={setupCompetencies}
                >
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
