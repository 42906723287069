import React, { FC, ReactNode } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { AccordionProps } from '@mui/material/Accordion/Accordion'

type GFAccordionProps = {
    children: ReactNode[];
}

export const GFAccordion: FC<AccordionProps & GFAccordionProps> = ({ children, ...props}) => {
    return (
        <Accordion
            disableGutters
            sx={{
                background: 'none',
                boxShadow: 'none',
                '& .MuiAccordionSummary-content': {
                    m: 0,
                },
                '&:before': { display: 'none' },
            }}
            {...props}
        >
            <AccordionSummary
                expandIcon={ <KeyboardArrowDownIcon color='primary' /> }
                sx={{
                    justifyContent: 'start',
                    p: 0,
                    '& .MuiAccordionSummary-content': {
                        display: 'flex',
                        alignItems: 'center',
                        flex: 'inherit',
                        mr: 1,
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        flex: 1,
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotateX(180deg)' },
                }}
            >
                {children[0]}
            </AccordionSummary>
            <AccordionDetails>
                {children[1]}
            </AccordionDetails>
        </Accordion>
    )
}
