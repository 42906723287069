import React from 'react'
import { AppBar, Box, Toolbar, useTheme } from '@mui/material'
import {
    AppMenuMobileDrawer,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileDrawer/AppMenuMobileDrawer'
import {
    AppMenuMobileTitle,
} from 'components/AppMenu/components/AppMenuMobile/components/AppMenuMobileTitle/AppMenuMobileTitle'

export const AppMenuMobile = () => {
    const theme = useTheme()

    return (
        <>
            <AppBar
                position="fixed"
                variant="outlined"
                elevation={0}
                sx={{
                    border: 0,
                    px: 3,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    background: theme.palette.background.default,
                }}
            >
                <Toolbar sx={{ px: 0 }}>
                    <Box flexGrow={1}>
                        <AppMenuMobileTitle/>
                    </Box>
                    <AppMenuMobileDrawer/>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>
    )
}
