import React, { FC } from 'react'
import {
    IEmployeeEditEducationEducationCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Stack } from '@mui/material'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import { HINT_CHOOSE, HINT_INPUT_TEXT } from 'helpers/enums/hints'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SAVE,
    TITLE_FIELD_DIRECTION,
    TITLE_FIELD_EDUCATIONAL_INSTITUTION,
    TITLE_FIELD_LEVEL,
    TITLE_FIELD_YEAR_GRADUATION,
} from 'helpers/enums/titles'
import { InputForm } from 'components/form/InputForm/InputForm'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { getYearsValuesArray } from 'helpers/dateAndTime/getYearsValuesArray'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useEffectOnce } from 'react-use'
import { employeeActions } from 'store/actions'

type FormValues = Omit<IEmployeeEditEducationEducationCreateOrUpdate, 'uuid'>

type PreviousJobsItemFormProps = {
    itemData?: IEmployeeEditEducationEducation;
    onCancelForm: () => void;
    onSubmitForm: (payload: IEmployeeEditEducationEducationCreateOrUpdate) => void;
}

const isEqualObjectsEducation = (
    item: IEmployeeEditEducationEducation,
    formData: FormValues,
) => {
    const equalEducationalInstitution = item?.educationalInstitution === formData.educationalInstitution
    const equalDirection = item?.direction === formData.direction
    const equalEducationLevelUuid = item?.level.uuid === formData.educationLevelUuid
    const equalPosition = item?.yearGraduation === formData.yearGraduation

    return equalEducationalInstitution && equalDirection && equalEducationLevelUuid && equalPosition
}

export const EducationItemForm: FC<PreviousJobsItemFormProps> = ({ itemData, onSubmitForm, onCancelForm }) => {
    const dispatch = useAppDispatch()
    const { educationLevels } = useAppSelector(state => state.employeeEditEducationEducationReducer.data)

    const { handleSubmit, control, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            educationalInstitution: itemData?.educationalInstitution ?? '',
            educationLevelUuid: itemData?.level?.uuid ?? '',
            yearGraduation: itemData?.yearGraduation ?? '',
            direction: itemData?.direction ?? '',
        },
    })

    const yearsValuesArray = getYearsValuesArray(100, 10)

    const onSubmit = (data: FormValues) => {
        if (itemData && isEqualObjectsEducation(itemData, data)) return
        const payload: IEmployeeEditEducationEducationCreateOrUpdate = {
            ...removeWhiteSpaceFromObjectElements(data),
            yearGraduation: data.yearGraduation,
        }
        if (itemData?.uuid) payload.uuid = itemData.uuid
        onSubmitForm(payload)
    }


    useEffectOnce(() => {
        dispatch(employeeActions.getEducationLevelList())
    })

    if (!(educationLevels?.length > 0)) return null

    return (
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} noValidate sx={{ mt: 4 }}>
            <Box>
                <Controller
                    name="direction"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.education.direction,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="direction"
                            name="direction"
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .education
                                    .direction
                                    .maxLength
                                    .value,
                            }}
                            required
                            title={TITLE_FIELD_DIRECTION}
                            placeholder={HINT_INPUT_TEXT}
                            error={!!errors.direction}
                            helperText={errors?.direction ? errors.direction.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="educationLevelUuid"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.education.level,
                    }}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="educationLevelUuid"
                            name="educationLevelUuid"
                            values={educationLevels || []}
                            title={TITLE_FIELD_LEVEL}
                            required
                            placeholder={HINT_CHOOSE}
                            error={!!errors.educationLevelUuid}
                            helperText={errors?.educationLevelUuid ? errors.educationLevelUuid.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="educationalInstitution"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.education.educationalInstitution,
                    }}
                    render={({ field }) => (
                        <InputForm
                            {...field}
                            id="educationalInstitution"
                            name="educationalInstitution"
                            inputProps={{
                                maxLength: fieldsRestriction
                                    .employees
                                    .employee
                                    .edit
                                    .education
                                    .educationalInstitution
                                    .maxLength
                                    .value,
                            }}
                            required
                            title={TITLE_FIELD_EDUCATIONAL_INSTITUTION}
                            placeholder={HINT_INPUT_TEXT}
                            error={!!errors.educationalInstitution}
                            helperText={errors?.educationalInstitution ? errors.educationalInstitution.message : null}
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    name="yearGraduation"
                    control={control}
                    rules={{
                        ...fieldsRestriction.employees.employee.edit.education.yearGraduation,
                    }}
                    render={({ field }) => (
                        <SelectForm
                            {...field}
                            id="yearGraduation"
                            name="yearGraduation"
                            values={yearsValuesArray || []}
                            title={TITLE_FIELD_YEAR_GRADUATION}
                            placeholder={HINT_CHOOSE}
                            required
                            error={!!errors.yearGraduation}
                            helperText={errors?.yearGraduation ? errors.yearGraduation.message : null}
                        />
                    )}
                />
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={() => onCancelForm()}>
                    {TITLE_BUTTON_CANCEL}
                </Button>
                <Button type="submit" variant="contained">
                    {TITLE_BUTTON_SAVE}
                </Button>
            </Stack>
        </Stack>
    )
}
