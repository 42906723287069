import React, { FC, ReactNode, useMemo } from 'react'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { useMediaQuery, useTheme } from '@mui/material'
import { ReportError } from '../../components/snackbar/ReportError/ReportError'
import { ReportComplete } from '../../components/snackbar/ReportComplete/ReportComplete'
import { ReportWarning } from '../../components/snackbar/ReportWarning/ReportWarning'
import { ReportDefault } from '../../components/snackbar/ReportDefault/ReportDefault'

type SnackbarLayerProps = {
    children: ReactNode;
}

export const SnackbarLayer: FC<SnackbarLayerProps> = ({children}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const snackBarParams: Omit<SnackbarProviderProps, 'children'> = useMemo(() => {
        switch (true) {
            case mobile:
                return {
                    maxSnack: 1,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                }
            default:
                return {
                    maxSnack: 3,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                }
        }
    }, [mobile])

    return (
        <SnackbarProvider
            {...snackBarParams}
            preventDuplicate
            Components={{
                error: ReportError,
                success: ReportComplete,
                warning: ReportWarning,
                default: ReportDefault,
            }}
        >
            {children}
        </SnackbarProvider>
    )
}
