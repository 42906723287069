import React, { FC } from 'react'
import { Typography } from '@mui/material'

type EmployeeTabHistoryListItemDescriptionProps = {
    title: string;
}

export const EmployeeTabHistoryItemTextDescription: FC<EmployeeTabHistoryListItemDescriptionProps> = ({ title }) => (
    <Typography
        variant="body1"
        sx={{ textOverflow: 'ellipsis' }}
    >
        {title.length <= 25 ? title : (title.substr(0, 25) + '...')}
    </Typography>
)

