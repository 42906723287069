import React, { FC } from 'react'
import { DrawerPdpCompetence } from '../DrawerPdpCompetence/DrawerPdpCompetence'

type DrawerPdpCompetenceGroupMobileProps = {
    group: ISetupCompetenceGroup;
    developmentPage?: boolean;
}

export const DrawerPdpCompetenceGroupMobile: FC<DrawerPdpCompetenceGroupMobileProps> = ({ group, developmentPage }) => (
    <>
        {group.competencies.map(competence =>
            <DrawerPdpCompetence
                key={competence.uuid}
                element={competence}
                developmentPage={developmentPage}
                groupName={group.title}
            />,
        )}
        {group.subGroups.map(subGroup =>
            <DrawerPdpCompetenceGroupMobile
                key={subGroup.uuid}
                group={subGroup}
                developmentPage={developmentPage}
            />,
        )}
    </>
)
