import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGetCpaScale } from 'api/typing/formTypes'
import { ICPASetScaleRequest } from 'api/typing/cpaTypes'
import { TDictionaryScaleNumericItemCreateRequest } from 'api/typing/dictionaryTypes'


interface ICPAItemScaleState {
    currentScaleUuid: string;
    selectedForSaveScaleUuid: string;
    scaleNumeric: IDictionaryScaleItem | null;
    scaleList: IGetCpaScale[];
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAItemScaleState = {
    currentScaleUuid: '',
    selectedForSaveScaleUuid: '',
    scaleNumeric: null,
    scaleList: [],
    isLoading: false,
    error: null,
}

export const CPAItemScaleSlice = createSlice({
    name: 'CPAItemScale',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setCurrentScaleUuid: (state, action: PayloadAction<string>) => {
            state.currentScaleUuid = action.payload
            state.selectedForSaveScaleUuid = action.payload
        },

        setScaleNumeric: (state, action: PayloadAction<IDictionaryScaleItem>) => {
            state.scaleNumeric = action.payload
            state.currentScaleUuid = action.payload.uuid
            state.selectedForSaveScaleUuid = action.payload.uuid
        },

        createNewScale: (state, action: PayloadAction<TDictionaryScaleNumericItemCreateRequest & { uuid: string }>) => {
            state.scaleList.push({
                ...action.payload,
                scaleTypeUuid: action.payload.typeUuid,
            })
        },

        updateScale: (state, action: PayloadAction<IDictionaryScaleItem>) => {
            state.scaleList = state.scaleList.map(el => el.uuid === action.payload.uuid ? action.payload : el)
        },

        deleteScale: (state, action: PayloadAction<string>) => {
            state.scaleList = state.scaleList.filter(el => el.uuid !== action.payload)
        },

        selectForSaveScaleUuid: (state, action: PayloadAction<string>) => {
            state.selectedForSaveScaleUuid = action.payload
        },

        setItemScale: (state, action: PayloadAction<ICPASetScaleRequest>) => {
            state.currentScaleUuid = action.payload.scaleUuid
            state.selectedForSaveScaleUuid = action.payload.scaleUuid
            state.scaleNumeric = null
        },

        setScaleList: (state, action: PayloadAction<IGetCpaScale[]>) => {
            state.scaleList = action.payload
        },
    },
})

export const CPAItemScaleReducer = CPAItemScaleSlice.reducer
