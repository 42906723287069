import {
    IMassExamQuestion,
    MassExamAnswerData,
    ExamQuestionType,
} from '../../api/typing/massExam'


export const oneFromListQuestionTypeUuid = '171fe41f-a3d7-47d1-b626-2a79e2056acd'
export const manyFromListQuestionTypeUuid = '44c6db11-1bf4-4411-afc4-797ba43f984e'

export type QuestionTypeUuid = typeof oneFromListQuestionTypeUuid | typeof manyFromListQuestionTypeUuid
export const emptyQuestion: IMassExamQuestion = {
    uuid: '',
    title: '',
    typeUuid: oneFromListQuestionTypeUuid,
    imgLink: '',
    sort: 0,
    active: false,
    answers: {
        oneFromList: [
            {
                uuid: '',
                title:'',
                imgLink: '',
                sort: 0,
                isCorrect: false,
            },
            {
                uuid: '',
                title:'',
                imgLink: '',
                sort: 1,
                isCorrect: false,
            },
        ],
        manyFromList: [
            {
                uuid: '',
                title:'',
                imgLink: '',
                sort: 0,
                isCorrect: false,
            },
            {
                uuid: '',
                title:'',
                imgLink: '',
                sort: 1,
                isCorrect: false,
            },
        ],
    },
}

export const emptyAnswer: MassExamAnswerData = {
    uuid: '',
    title:'',
    imgLink: '',
    sort: 0,
    isCorrect: false,
}

export const getQuestionTypeCode = (uuid: string): ExamQuestionType => {
    switch (uuid) {
        case oneFromListQuestionTypeUuid:
            return ExamQuestionType.ONE_FROM_LIST
        case manyFromListQuestionTypeUuid:
            return ExamQuestionType.MANY_FROM_LIST
        default:
            return ExamQuestionType.ONE_FROM_LIST
    }
}

export const questionTypeData: {
    [key in QuestionTypeUuid]: {
        uuid: QuestionTypeUuid,
        code: ExamQuestionType,
        title: string
    }
} = {
    [oneFromListQuestionTypeUuid]: {
        uuid: oneFromListQuestionTypeUuid,
        code: ExamQuestionType.ONE_FROM_LIST,
        title: 'Один из списка',
    },
    [manyFromListQuestionTypeUuid]: {
        uuid: manyFromListQuestionTypeUuid,
        code: ExamQuestionType.MANY_FROM_LIST,
        title: 'Много из списка',
    },
}
