import React from 'react'
import {
    Avatar,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { authActions } from 'store/actions'
import { useNavigate } from 'react-router-dom'
import { PATH_LOGIN } from 'helpers/enums/routePath'
import { useProfile } from 'api/queries/profile/profileQueries'
import {ExitToAppRounded} from '@mui/icons-material'
import { TITLE_NAMESPACE_EXIT } from 'helpers/enums/titles'
import { HINT_TOOLTIP_LOGOUT } from 'helpers/enums/hints'
import {
    AppSupportLink,
} from 'components/AppMenu/components/AppMenuDesktop/components/AppMenuDesktopFooter/AppSupportLink/AppSupportLink'

export const AppMenuDesktopFooter = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { remove } = useProfile(false)

    const {
        firstName,
        photo,
    } = useAppSelector((state) => state.userReducer.data)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        dispatch(authActions.logout())
        navigate(PATH_LOGIN)
        remove()
    }

    return (
        <Stack mt={2}>
            <Divider/>
            <ListItem sx={{ px: 3, py: 2 }}>
                <ListItemIcon sx={{ minWidth: 42 }}>
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            src={photo}
                            sx={{
                                width: 32,
                                height: 32,
                            }}
                        />
                    </IconButton>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {firstName}
                </ListItemText>
                <AppSupportLink />
                <Tooltip
                    title={HINT_TOOLTIP_LOGOUT}
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={logout}
                    >
                        <ExitToAppRounded/>
                    </IconButton>
                </Tooltip>
            </ListItem>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppRounded fontSize="small"/>
                    </ListItemIcon>
                    {TITLE_NAMESPACE_EXIT}
                </MenuItem>
            </Menu>
        </Stack>
        // <>
        //     <Divider/>
        //     <Box display="flex" px={3} py={2} alignItems="center" maxHeight={64}>
        //         <IconButton
        //             // title={HINT_TOOLTIP_LOGOUT}
        //             sx={{ p: 0 }}
        //             onClick={handleClick}
        //             aria-controls={open ? 'account-menu' : undefined}
        //             aria-haspopup="true"
        //             aria-expanded={open ? 'true' : undefined}
        //         >
        //
        //             <Avatar
        //                 src={photo}
        //                 sx={{
        //                     width: 32,
        //                     height: 32,
        //                     mr: isMenuOpen ? 1.25 : 0,
        //                 }}
        //             />
        //         </IconButton>
        //         {isMenuOpen && <Fade appear in={isMenuOpen} timeout={1000}>
        //             <Typography whiteSpace="normal" sx={{ flex: 1 }}>
        //                 {lastName} {firstName} {secondName}
        //             </Typography>
        //         </Fade>}
        //         {isMenuOpen && <Fade appear in={isMenuOpen} timeout={1000}>
        //             <Tooltip
        //                 title={HINT_TOOLTIP_LOGOUT}
        //                 placement="top"
        //                 arrow
        //             >
        //                 <IconButton
        //                     onClick={logout}
        //                     aria-label="logout"
        //                     component="span"
        //                     sx={{ width: 32, height: 32 }}
        //                 >
        //                     <ExitToAppRoundedIcon/>
        //                 </IconButton>
        //             </Tooltip>
        //         </Fade>}
        //     </Box>
        //     <Menu
        //         anchorEl={anchorEl}
        //         id="account-menu"
        //         open={open}
        //         onClose={handleClose}
        //         onClick={handleClose}
        //         PaperProps={{
        //             elevation: 0,
        //             sx: {
        //                 overflow: 'visible',
        //                 filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        //             },
        //         }}
        //         transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        //         anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        //     >
        //         <MenuItem onClick={logout}>
        //             <ListItemIcon>
        //                 <Logout fontSize="small"/>
        //             </ListItemIcon>
        //             {TITLE_NAMESPACE_EXIT}
        //         </MenuItem>
        //     </Menu>
        // </>
    )
}

