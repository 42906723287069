import React from 'react'
import { Box, Typography } from '@mui/material'
import {
    TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE,
    TITLE_NAMESPACE_GROUP_OF_COMPETENCIES,
    TITLE_NAMESPACE_TOTAL,
} from 'helpers/enums/titles'

export const CompetenceTreeHeaderWithTotal = () => {
    return (
        <Box display="flex" justifyContent="space-between">
            <Box flex="1" display="flex" justifyContent="space-between" mr={3}>
                <Box px={2}>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_GROUP_OF_COMPETENCIES} / {TITLE_NAMESPACE_COMPETENCIES_NOMINATIVE}
                    </Typography>
                </Box>
                {/*<Box display="flex" px={2}>*/}
                {/*    <Typography variant="body1">*/}
                {/*        {TITLE_NAMESPACE_REQUIRED_LEVEL_OF_PROFICIENCY}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
            </Box>
            <Box width={92} display="flex" justifyContent="center">
                <Typography variant="body1">{TITLE_NAMESPACE_TOTAL}</Typography>
            </Box>
        </Box>
    )
}

