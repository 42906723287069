import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Stack, Typography } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'

export const AssessmentResultAccordance = () => {
    const graphicData = useAppSelector(state => state.assessmentResultReducer.data.accordanceGraphic)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timerId = setTimeout(() => setLoading(false), 300)
        return () => clearTimeout(timerId)
    }, [])

    if (!graphicData || graphicData.keys.length < 1) return null

    return (
        <Stack spacing={3}>
            <Typography variant="h2">
                Соответствия по грейдам
            </Typography>
            <Box width="100%" maxWidth={500} height={360}>
                {loading && <LoadingComponent/>}
                <ResponsiveBar
                    data={graphicData.data}
                    keys={graphicData.keys}
                    indexBy="title"
                    isInteractive={false}
                    label={d => `${d.value}%`}
                    margin={{ top: 50, right: 20, bottom: 70, left: 30 }}
                    maxValue={100}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    // colors={[theme.palette.primary.light, theme.palette.secondary.light]}
                    colors={{ datum: 'data.color' }}
                    role="application"
                />
            </Box>
        </Stack>
    )
}
