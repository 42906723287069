import React, { FC } from 'react'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { CloseRounded, LibraryBooksRounded } from '@mui/icons-material'
import {
    TITLE_BUTTON_INSTRUCTION,
    TITLE_DIALOG_PDP_INSTRUCTION,
} from 'helpers/enums/titles'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { Route, Routes } from 'react-router-dom'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { instructionEmployeeWorkWithPdp } from 'helpers/enums/Instructions'
import { DialogInstruction } from 'components/dialogs/DialogInstruction/DialogInstruction'
import { GFAccordionAlertInfo } from 'components/common/GFAccordionAlertInfo/GFAccordionAlertInfo'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { DevelopmentPdpPlan } from './components/DevelopmentPdpPlan/DevelopmentPdpPlan'
import { DevelopmentPdpItemViewPage } from '../DevelopmentPdpItemViewPage/DevelopmentPdpItemViewPage'
import { useLocalStorage } from 'react-use'

type DevelopmentPdpProps = {
    pdp: IDevelopmentPdp;
}

export const DevelopmentPdp: FC<DevelopmentPdpProps> = ({ pdp }) => {
    const [isBannerWatched, setIsBannerWatched] = useLocalStorage('PDPBannerWatched', false)

    const { instruction, toggleDialog } = useDialogsState({
        instruction: false,
    })

    const statusPdp = getPdpStatus(pdp?.status, false)
    const isCanceledOrCompleted = statusPdp.status === 'completed' || statusPdp.status === 'canceled'
    const desktop =  useDesktop()

    return (
        <Stack spacing={3} alignItems={!desktop ? 'initial' : 'flex-start'}>
            <DialogInstruction
                open={instruction}
                instruction={instructionEmployeeWorkWithPdp}
                dialogTitle={TITLE_DIALOG_PDP_INSTRUCTION}
                handleClose={() => toggleDialog('instruction', false)}
            />
            {!isCanceledOrCompleted && !isBannerWatched ?
                <>
                    {!desktop ?
                        <GFAccordionAlertInfo severity={statusPdp.status === 'expired' ? 'warning' : 'info'}>
                            <Typography variant="body1">
                                {statusPdp.alertTextTitle}
                            </Typography>
                            <>
                                <Typography variant="body1" mb={1.5}>
                                    {statusPdp.alertText}
                                </Typography>
                                <Button
                                    variant='outlined'
                                    color={statusPdp.status === 'expired' ? 'warning' : 'primary'}
                                    startIcon={<LibraryBooksRounded/>}
                                    onClick={() => toggleDialog('instruction', true)}
                                    sx={desktop ? {
                                        display: 'flex',
                                        margin: 'auto',
                                        width: '100%',
                                        mt: 1,
                                    } : {
                                        display: 'flex',
                                        margin: 'auto',
                                        width: '100%',
                                        mt: 1,
                                        fontSize: 16,
                                        lineHeight: '26px',
                                        padding: '12px 20px',
                                    }}
                                >
                                    {TITLE_BUTTON_INSTRUCTION}
                                </Button>
                            </>
                        </GFAccordionAlertInfo>
                        :
                        <GFAlertInfo
                            severity={statusPdp.status === 'expired' ? 'warning' : 'info'}
                            text={statusPdp.alertText}
                            action={
                                <Box>
                                    <Button
                                        color={statusPdp.status === 'expired' ? 'warning' : 'primary'}
                                        startIcon={<LibraryBooksRounded/>}
                                        sx={{ whiteSpace: 'nowrap', alignSelf: 'center'  }}
                                        onClick={() => toggleDialog('instruction', true)}
                                    >
                                        {TITLE_BUTTON_INSTRUCTION}
                                    </Button>
                                    {statusPdp.isClosed &&
                                        <IconButton
                                            color={statusPdp.status === 'expired' ? 'warning' : 'primary'}
                                            onClick={() => setIsBannerWatched(true)}
                                        >
                                            <CloseRounded />
                                        </IconButton>
                                    }
                                </Box>
                            }
                        />
                    }
                </>
                :
                null
            }
            <Routes>
                <Route index element={<DevelopmentPdpPlan/>}/>
                <Route path="item/:itemUuid/:entityType" element={<DevelopmentPdpItemViewPage/>}/>
            </Routes>
        </Stack>
    )
}
