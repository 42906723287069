import React, { FC } from 'react'
import { Box, Link, StepLabel, Typography } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'
import { GFEducationStatusProgress } from 'components/common/GFEducationStatusProgress/GFEducationStatusProgress'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { TITLE_NAMESPACE_PLAN_ITEM, TITLE_NAMESPACE_USER_ACTIVITY } from 'helpers/enums/titles'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { CommentsIconButton } from 'components/common/CommentsIconButton/CommentsIconButton'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import { useAppSelector } from 'store/hooks/redux'

type HistoryPdpItemProps = {
    item: IPdpPlanItem;
    parentPath: string;
}

export const HistoryPdpItem: FC<HistoryPdpItemProps> = ({ item, parentPath }) => {
    const { pdp } = useAppSelector(state => state.employeeHistoryPdpReducer.data)
    const { dialogCommentView, toggleDialog } = useDialogsState({
        dialogCommentView: false,
    })

    return (
        <StepLabel>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={true}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
            />
            <Box display="flex" justifyContent="space-between" px={2}>
                <Box display="flex" alignItems="center" flex={1}>
                    <Box mr={3} display="flex" alignItems="center">
                        <GFEducationStatusProgress
                            progress={item.progress}
                            entityType={item.entityType}
                            isPassed={item.isPassed}
                        />
                    </Box>
                    <Box flex={1}>
                        <Box mb={0.5}>
                            <Link
                                component={RouterLink}
                                to={`${parentPath}/item/${item.uuid}/${item.entityType}`}
                                variant="body1"
                            >
                                {item.title}
                            </Link>
                        </Box>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: '16px',
                                letterSpacing: '0.4px',
                            }}
                        >
                            {item.typeTitle || TITLE_NAMESPACE_USER_ACTIVITY}
                        </Typography>
                    </Box>
                    {!!item?.changeLog &&
                        <BadgeChangeLog changeLog={item.changeLog} evaluatedUuid={pdp?.employee?.uuid}/>}
                </Box>
                <Box display="flex">
                    <Box display="flex" justifyContent="center" alignItems="center" width={156}>
                        <Typography variant="body2">
                            {formatHours(item.studyTime)}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width={156}>
                        <CommentsIconButton
                            hasComments={item.comments?.length > 0}
                            canEdit={false}
                            onClick={() => toggleDialog('dialogCommentView', true)}
                        />
                    </Box>
                </Box>
            </Box>
        </StepLabel>
    )
}

