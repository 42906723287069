import React, { FC } from 'react'
import { useAppDispatch } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useParams } from 'react-router-dom'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { developmentPdpItemActions } from 'store/actions'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { HINT_SUCCESS_COMMENT_CREATE, HINT_SUCCESS_COMMENT_DELETE } from 'helpers/enums/hints'
import { useDesktop } from 'helpers/hooks/useDesktop'
import {
    DevelopmentPdpCompetenceMaterialTableRow,
} from '../DevelopmentPdpCompetenceMaterialTableRow/DevelopmentPdpCompetenceMaterialTableRow'
import {
    PdpCompetenceMaterialCard,
} from 'components/common/PdpCompetenceMaterialCard/PdpCompetenceMaterialCard'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'

type DevelopmentPdpCompetenceMaterialProps = {
    material: IPdpCompetenceMaterial;
    skillLevelUuid: string;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpCompetenceMaterial: FC<DevelopmentPdpCompetenceMaterialProps> = ({
    material,
    skillLevelUuid,
    pdpStatus,
}) => {
    const desktop = useDesktop()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { pdpUuid, itemUuid } = useParams()
    const { dialogCommentView, toggleDialog } = useDialogsState({
        dialogCommentView: false,
    })
    const hideCommentBtn = (pdpStatus.status === 'completed' || pdpStatus.status === 'canceled')
        && material?.comments?.length <= 0

    const changeActiveMaterial = async () => {
        try {
            if (!pdpUuid || !itemUuid) return
            await dispatch(developmentPdpItemActions.passPdpCompetenceMaterial({
                pdpUuid,
                itemUuid,
                isPassed: !material.isPassed,
                materialUuid: material.uuid,
                skillLevelUuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const disableEditComments = !(pdpStatus.status === 'inProgress' || pdpStatus.status === 'returned' || pdpStatus.status === 'sent')

    const deleteComment = async (uuid: string) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.deletePdpItemMaterialComment({
                uuid,
                pdpUuid,
                itemUuid,
                skillLevelUuid,
                materialUuid: material.uuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createComment = async (comment: IComment) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.createPdpItemMaterialComment({
                ...comment,
                pdpUuid,
                itemUuid,
                skillLevelUuid,
                materialUuid: material.uuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={disableEditComments}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={material.format}
                itemTitle={material.title}
                comments={material.comments}
                onDeleteComment={deleteComment}
                onCreateComment={createComment}
            />
            {desktop ?
                <DevelopmentPdpCompetenceMaterialTableRow
                    material={material}
                    pdpStatus={pdpStatus}
                    toggleDialog={toggleDialog}
                    skillLevelUuid={skillLevelUuid}
                    changeActiveMaterial={changeActiveMaterial}
                    hideCommentBtn={hideCommentBtn}
                />
                :
                <PdpCompetenceMaterialCard
                    material={material}
                    pdpStatus={pdpStatus}
                    toggleDialog={toggleDialog}
                    skillLevelUuid={skillLevelUuid}
                />
            }
        </>
    )
}
