import React, { FC, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import { TITLE_NAMESPACE_SKILL_LEVEL, TITLE_NAMESPACE_STUDY_TIME } from 'helpers/enums/titles'
import { ExpandElements } from 'components/common/ExpandElements/ExpandElements'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    PdpCompetenceMaterials,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewCompetence/components/PdpItemViewCompetenceContent/components/PdpCompetenceLevel/components/PdpCompetenceMaterials/PdpCompetenceMaterials'
import {
    DevelopmentPdpCompetenceMaterials,
} from './components/DevelopmentPdpCompetenceMaterials/DevelopmentPdpCompetenceMaterials'
import { useDesktop } from 'helpers/hooks/useDesktop'


type DevelopmentPdpCompetenceLevelProps = {
    level: IPdpCompetenceSkillLevel;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpCompetenceLevel: FC<DevelopmentPdpCompetenceLevelProps> = ({ level, pdpStatus }) => {
    const [open, setOpen] = useState(true)
    const desktop = useDesktop()

    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: 0,
                    marginBottom: 2,
                }}
                onClick={handleClick}
            >
                <Box display="flex">
                    <Typography variant="subtitle1" mr={0.5} fontSize={desktop ? '20px' : '18px'}>
                        {`${TITLE_NAMESPACE_SKILL_LEVEL}: ${level.title}`}
                    </Typography>
                    <ExpandElements open={open} />
                </Box>
                <Typography variant="body1" mt={0.5}>
                    {`${TITLE_NAMESPACE_STUDY_TIME}: ${level.studyTime ? formatHours(level.studyTime) : '-'}`}
                </Typography>
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
            >
                {pdpStatus?.status === 'default'
                    ? <PdpCompetenceMaterials
                        isDevelopment
                        materials={level.materials}
                        skillLevelUuid={level.uuid}
                    />
                    : <DevelopmentPdpCompetenceMaterials
                        materials={level.materials}
                        skillLevelUuid={level.uuid}
                        pdpStatus={pdpStatus}
                    />}
            </Collapse>
        </Box>
    )
}

