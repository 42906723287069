import React, { useState } from 'react'
import {
    CPAScaleDrawerContentItemDrawer,
} from 'pages/cpa/CPAPage/components/CPAScale/components/CPAScaleHeader/components/CPAScaleAction/components/CPAScaleDrawer/components/CPAScaleDrawerContent/components/CPASceleDrawerContentItemDrawer/CPASceleDrawerContentItemDrawer'
import { Box, Button } from '@mui/material'
import { AddRounded } from '@mui/icons-material'

export const CPAScaleDrawerContentAddItemAction = () => {
    const [open, setOpen] = useState(false)
    return (
        <Box>
            {open && <CPAScaleDrawerContentItemDrawer open={open} handleClose={() => setOpen(false)}/>}
            <Button
                variant="text"
                startIcon={<AddRounded/>}
                onClick={() => setOpen(true)}
            >
                Создать шкалу
            </Button>
        </Box>
    )
}
