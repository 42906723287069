import React, { useState } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { useCpaItemEmployees, useCpaStatusState } from 'api/queries/cpa/cpaQueries'

export function useUpdateCpaEmployeesFormState(cpaUuid: string | undefined) {
    if (!cpaUuid) return { isLoading: false, error: null }

    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const needToStartUpdateState = !!cpaStatusCode && (cpaStatusCode !== 'created' && cpaStatusCode !== 'complete' && cpaStatusCode !== 'ready')

    const { isLoading, error, refetch } = useCpaItemEmployees(cpaUuid, false)

    const { data, refetch: refetchStatus, isFetching } = useCpaStatusState(cpaUuid, needToStartUpdateState)
    const [completeCount, setCompleteCount] = useState(0)

    React.useEffect(() => {
        if (isFetching) return
        if(!needToStartUpdateState) {
            refetch()
            return
        }
        if (!data?.process?.completedCount && data?.process.completedCount !== 0) return
        const step = data.process.assessmentsCount * 0.1 < 5 ? 5 : data.process.assessmentsCount * 0.1
        const currentCountWithStepGTCompleted = completeCount + step > data.process.completedCount
        const isEqualCompletedCount = data.process.completedCount === data.process.assessmentsCount
        const employeesWasDeleted = completeCount > data.process.assessmentsCount

        if (currentCountWithStepGTCompleted && !isEqualCompletedCount && !employeesWasDeleted) return

        setCompleteCount(data.process.completedCount)
        refetch()

        return () => setCompleteCount(0)

    }, [data, isFetching])

    React.useEffect(() => {
        if (!cpaStatusCode || cpaStatusCode === 'created' || cpaStatusCode === 'ready')
            return
        refetchStatus()
    }, [cpaStatusCode])

    return { isLoading, error }

}
