import React, { Stack } from '@mui/material'
import { DevelopmentPdpCompetenceMaterial } from '../DevelopmentPdpCompetenceMaterial/DevelopmentPdpCompetenceMaterial'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { FC } from 'react'

type DevelopmentPdpCompetenceMaterialsMobileProps = {
    sortedMaterials: IPdpCompetenceMaterial[];
    skillLevelUuid: string;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpCompetenceMaterialsMobile: FC<DevelopmentPdpCompetenceMaterialsMobileProps> = ({
    sortedMaterials,
    skillLevelUuid,
    pdpStatus,
}) => {
    return (
        <Stack spacing={3}>
            {sortedMaterials.map(material => (
                <DevelopmentPdpCompetenceMaterial
                    key={material.uuid}
                    material={material}
                    skillLevelUuid={skillLevelUuid}
                    pdpStatus={pdpStatus}
                />
            ))}
        </Stack>
    )
}
