import React, { FC, useState } from 'react'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { employeeActions } from 'store/actions'
import {
    IEmployeeWorkExperiencePreviousJobCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { Box, Chip, Divider, IconButton, Typography } from '@mui/material'
import { formatTimeToStringDuration } from 'helpers/dateAndTime/formatTimeToStringDuration'
import { ClearRounded, EditRounded } from '@mui/icons-material'
import { parse } from 'date-fns'
import {
    PreviousJobsItemForm,
} from 'pages/employees/EmployeePage/components/EmployeeEdit/components/EmployeeEditTabExperience/components/PreviousJobsCard/components/PreviousJobsItemForm/PreviousJobsItemForm'
import { HINT_RECORD_SUCCESS_DELETE, HINT_RECORD_SUCCESS_EDIT } from 'helpers/enums/hints'

type PreviousJobsItemProps = {
    previousJob: IEmployeeWorkExperiencePreviousJobItem;
    employeeUuid: string;
    pageViewEmployee?: boolean;
}

export const PreviousJobsItem: FC<PreviousJobsItemProps> = ({ previousJob, pageViewEmployee, employeeUuid }) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const [edit, setEdit] = useState(false)

    const handleEdit = () => {
        setEdit(true)
    }

    const handleDelete = async () => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.deletePreviousJobs(employeeUuid, previousJob.uuid))
            notify(HINT_RECORD_SUCCESS_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    const editComplete = async (payload: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) => {
        try {
            if (!employeeUuid) return
            await dispatch(employeeActions.updatePreviousJobs(employeeUuid, { ...payload, employeeUuid }))
            setEdit(false)
            notify(HINT_RECORD_SUCCESS_EDIT, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <>
            {edit
                ? <PreviousJobsItemForm
                    onCancelForm={() => setEdit(false)}
                    onSubmitForm={editComplete}
                    itemData={previousJob}
                />
                : <>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Box overflow="hidden">
                            <Box display="flex" alignItems="center" mb={0.5}>
                                <Typography variant="body2" mr={1}>
                                    {previousJob?.companyTitle} - {previousJob?.positionTitle}
                                </Typography>
                                <Chip
                                    label={formatTimeToStringDuration(
                                        parse(previousJob?.startDate, 'dd.MM.yyyy', new Date()),
                                        parse(previousJob?.endDate, 'dd.MM.yyyy', new Date()),
                                    )}
                                    size="small"
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1">
                                    {previousJob?.startDate} - {previousJob?.endDate}
                                </Typography>
                            </Box>
                        </Box>
                        {!pageViewEmployee && <Box display="flex" flexWrap="nowrap" alignSelf="center">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditRounded />
                            </IconButton>
                            <IconButton color="primary" onClick={handleDelete}>
                                <ClearRounded />
                            </IconButton>
                        </Box>}
                    </Box>
                    {!pageViewEmployee && <Divider sx={{ mt: 2 }} />}
                </>}
        </>
    )
}

