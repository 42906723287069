import React, { FC, memo } from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_REQUIRED_LEVEL } from 'helpers/enums/titles'
import { ChipSkillLevel } from 'components/chips/ChipSkillLevel/ChipSkillLevel'
import {
    MyAssessmentCompetenceSkillLevel,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/components/MyAssessmentPassCompetence/components/MyAssessmentCompetenceSkillLevel/MyAssessmentCompetenceSkillLevel'
import {
    MyAssessmentCompetenceIndicator,
} from 'pages/assessment/MyAssessmentPass/components/MyAssessmentPassDesktop/components/MyAssessmentPassCompetencies/components/MyAssessmentPassCompetence/components/MyAssessmentCompetenceIndicator/MyAssessmentCompetenceIndicator'

type MyAssessmentPassCompetenceProps = {
    competenceUuid: string;
}

export const MyAssessmentPassCompetence: FC<MyAssessmentPassCompetenceProps> = memo(
    function MyAssessmentPassCompetence({ competenceUuid }: MyAssessmentPassCompetenceProps) {
        const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
        const competence = useAppSelector(state => state.MyAssessmentPassReducer.data.competencies[competenceUuid])
        const competenceIndicatorUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceIndicatorUuids[competenceUuid])

        const competenceSkillLevelUuids =
            useAppSelector(state => state.MyAssessmentPassReducer.data.competenceSkillLevelUuids[competenceUuid])

        const showScores = assessmentSetup?.completeStatus
        const showChip = assessmentSetup?.completeStatus

        if (!competence) return null

        return (
            <Stack spacing={3}>
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={3}>
                        <Stack>
                            <Typography variant="caption">
                                {competence.typeTitle}
                            </Typography>
                            <Typography variant="h3">
                                {competence.title}
                            </Typography>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems="center">
                            {showChip && <>
                                <Typography variant="body2">
                                    {TITLE_NAMESPACE_REQUIRED_LEVEL}
                                </Typography>
                                <ChipSkillLevel skillLevelTitle={competence.skillLevelTitle}/>
                            </>}
                            {showScores && <Chip
                                color="warning"
                                label={`${competence.score}%`}
                            />}
                        </Stack>
                    </Box>
                    <Divider/>
                </Box>
                {competenceSkillLevelUuids?.length > 0 && showScores
                    ? <Stack spacing={6}>
                        {competenceSkillLevelUuids?.map(el => <MyAssessmentCompetenceSkillLevel
                            key={el}
                            skillLevelUuid={el}
                            competenceUuid={competenceUuid}
                        />)}
                    </Stack>
                    : <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
                        <Stack spacing={2} divider={<Divider/>}>
                            {competenceIndicatorUuids.map((indicatorUuid, idx) => <MyAssessmentCompetenceIndicator
                                key={indicatorUuid}
                                competenceUuid={competenceUuid}
                                indicatorUuid={indicatorUuid}
                                idx={idx + 1}
                            />)}
                        </Stack>
                    </Paper>
                }
            </Stack>
        )
    },
)
