import { useAppDispatch } from 'store/hooks/redux'
import { CSSProperties, useCallback } from 'react'
import { VariantType } from 'notistack'
import { notifierActions } from 'store/actions'
import { NotificationType } from 'store/actions/notifierAction'

export const useNotify = () => {
    const dispatch = useAppDispatch()
    // const notifyAdd = (notification: Omit<NotificationType, 'key'>) => dispatch(notifierActions.notifyAdd(notification))
    const notifyAdd = useCallback((notification: Omit<NotificationType, 'key'>) => dispatch(notifierActions.notifyAdd(notification)), [])

    return useCallback((message: string, type: VariantType = 'default', link?: string, style?: CSSProperties) => {
        notifyAdd({ message, type, link, style })
    }, [dispatch, notifyAdd])
}
