import React, { useEffect } from 'react'
import { TITLE_NAMESPACE_NOT_SELECTED_YET } from 'helpers/enums/titles'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemCriteriaActions } from 'store/actions'
import { Typography } from '@mui/material'

export const CPACriteriaDefault = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(cpaItemCriteriaActions.clearState())
    }, [])

    return (
        <Typography variant="body1" sx={{ color: (theme) => theme.palette.warning.main }}>
            {TITLE_NAMESPACE_NOT_SELECTED_YET}
        </Typography>
    )
}
