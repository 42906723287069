import { QuestionTypeUuid } from '../../helpers/massExam/massExamConstructor'
import { MassExamStatuses } from '../../helpers/massExam/massExamStatuses'

export  interface IMassExamListItem extends IEntity {
    title: string;
    uuid: string;
    statusUuid: MassExamStatuses
    createdAt: string; // 'dd.mm.yyyy';
    endedAt: string; // 'dd.mm.yyyy';
    finishedAt: string; // 'dd.mm.yyyy';
    notifiedAt: string; // 'dd.mm.yyyy';
    employeesCount: number;
    author: IAuthor;
}

export interface IExamPassMark {
    uuid: string;
    code: MassExamPassMarkCode;
    data: MassExamPassMarkData;
}

export interface IExamResult {
    score: number;
    percent: number;
    isPassed: boolean;
}

export interface IMassExamCreateRequest {
    title: string;
    endedAt: string; // 'dd.mm.yyyy';
}

export enum MassExamPassMarkCode {
    FINAL_PERCENTAGE = 'finalPercentage',
    TOTAL_POINTS = 'totalPoints',
}

export enum ExamQuestionType {
    ONE_FROM_LIST = 'oneFromList',
    MANY_FROM_LIST = 'manyFromList',
}

export type MassExamPassMarkData = {
    [key in MassExamPassMarkCode]: {
        value: number;
    } | null;
};

export interface IMassExamItemMetaQuestion {
    uuid: string;
    active: boolean; // true - полностью готовый вопрос, заполнены основные поля
    score: number;
}

export interface IMassExamItemMeta extends IEntity {
    uuid: string;
    title: string;
    description: string;
    statusUuid: MassExamStatuses;
    createdAt: string;
    endedAt: string;
    startedAt: string;
    finishedAt: string;
    notifiedAt: string;
    employeesCount: number;
    childMassExamUuid: string;
    author: IAuthor;
    questions: IMassExamItemMetaQuestion[];
    progress: {
        examsCount: number;
        completedCount: number;
        value: number;
    } | null;
    passMark: IExamPassMark | null;
}

export interface IMassExamUpdateRequest {
    uuid: string;
    title: string;
    endedAt: string; // 'dd.mm.yyyy';
}

export interface IMassExamDescriptionUpdateRequest {
    uuid: string;
    description: string;
}

export interface IMassExamChangeStatusRequest {
    uuid: string;
    statusUuid: MassExamStatuses;
}

interface IMassExamEmployee {
    uuid: string;
    firstName: string;
    lastName: string;
    secondName: string;
    specialization: IEntity;
    grade: IEntity;
    photo: string;
    exam: {
        uuid: string;
        statusUuid: string;
        massExamUuid: string;
        finishedAt: string;
        result: IExamResult | null // если терминальный статус(done), в противном случае null
    }
}

export interface IMassExamGetEmployeeForAddResponse {
    uuid: string;               // uuid отдела
    title: string;
    fullPath: string;
    progress: {
        value: number;
        examsCount: number;
        completedCount: number;
    };
    employees: IMassExamEmployee[];
    manager: {
        uuid: string;
        firstName: string;
        lastName: string;
    }
}

export interface IMassExamGetItemEmployeesResponse {
    uuid: string;
    title: string;
    fullPath: string;
    progress: number; // прогресс по подразделению
    manager: {
        uuid: string;
        firstName: string;
        lastName: string;
    }
    employees: IMassExamEmployee[]
}

export interface IMassExamSaveEmployeesRequest {
    uuid: string;
    employees: string[];
}

export interface IMassExamDeleteEmployeeRequest {
    uuid: string;
    divisionUuid: string;
    employeeUuid: string;
}

export interface IPassMarkType {
    uuid: string;
    title: string;
    description: string;
    code: MassExamPassMarkCode;
    sort: number;
}

export interface IMassExamPassMarkSaveRequest {
    uuid: string;                       // mass exam uuid
    passMark: IPassMarkType & {
        data: MassExamPassMarkData;
    }
}

export interface IQuestionType {
    uuid: string;
    title: string;
    code: string;
    sort: number;
}

export type MassExamAnswerData = {
    uuid: string;       // uuid ответа
    title: string;
    imgLink: string;
    sort: number;
    isCorrect: boolean;
}

export type MassExamAnswer = {
    [key in ExamQuestionType]?: MassExamAnswerData[] | [];
}

export interface IMassExamQuestion {
    uuid: string;                           // uuid вопроса
    title: string;                          // текст вопроса
    typeUuid: QuestionTypeUuid;             // uuid типа ответа oneFromList/manyFromList
    imgLink: string;                        // картинка вопроса
    sort: number;                           // номер вопроса
    active: boolean;
    answers: MassExamAnswer;
}

export enum MassExamQuestionsFileUploadErrType {
    STRUCTURE = 'structure',
    CONTENT = 'content',
}

export interface IMassExamQuestionsFileUploadReq {
    massExamUuid: string
    file: number[];
}

export interface IMassExamQuestionsFileUploadResp {
    err: {
        errType: MassExamQuestionsFileUploadErrType;
        info: string[];
    };
    questions: IMassExamQuestion[];
}

export interface IMassExamQuestionCreateReq {
    massExamUuid: string;
    sort: number;
}

export interface IMassExamQuestionUpdateReq extends IMassExamQuestion {
    massExamUuid: string;
}

export interface IMassExamQuestionDeleteReq {
    massExamUuid: string;
    uuid: string;
}

export interface IMassExamQuestionSortUpdateReq {
    massExamUuid: string;
    uuid: string;
    sort: number;
}


export interface IMassExamQuestionImgUploadReq {
    massExamUuid: string
    questionUuid: string;
    img: number[];
    imageType: string;
    isNew: boolean
}

export interface IMassExamQuestionImgDeleteReq {
    massExamUuid: string
    questionUuid: string;
    link: string;
}

export interface IMassExamAnswerImgUploadReq {
    massExamUuid: string
    questionUuid: string;
    answerUuid: string; //если ответ только что создан - пустая строка, уид вернется в респонсе
    img: number[];
    imageType: string;
    isNew: boolean;
}

export interface IMassExamAnswerImgDeleteReq {
    massExamUuid: string
    questionUuid: string;
    answerUuid: string;
    link: string;
}
