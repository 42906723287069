import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { PATH_ERROR, PATH_PROFILE } from 'helpers/enums/routePath'
import { Box } from '@mui/material'
import { EmployeeHeader } from 'pages/employees/EmployeePage/components/EmployeeHeader/EmployeeHeader'
import { employeeActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { viewProfileTabs } from 'helpers/enums/tabs/profile/viewProfileTabs'
import { ProfileTabs } from 'pages/profile/ProfilePage/components/ProfileTabs/ProfileTabs'
import { useTabsChecker } from 'store/hooks/useTabsChecker'

export const ProfilePage = () => {
    const { data: { employee } } = useAppSelector(state => state.employeeReducer)
    const { uuid } = useAppSelector(state => state.userReducer.data)
    const dispatch = useAppDispatch()
    const { tabPanel } = useParams()
    useTabsChecker(
        viewProfileTabs,
        tabPanel,
        `${PATH_PROFILE}/${viewProfileTabs[0]}`,
    )

    const { isFetching, error } = useLayoutFetch(
        employeeActions.employeeGet(uuid),
        {
            deps: [uuid],
            permissions: { service: 'profile', accessLevel: 'r' },
        },
    )

    useEffect(() => {
        return () => {
            dispatch(employeeActions.clearStateEmployee())
        }
    }, [])

    if (!Object.keys(employee || {}).length || isFetching) return null

    if (isFetching) return <LoadingComponent />
    if (error) return <Navigate to={PATH_ERROR} replace state={error} />

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            {employee && !isFetching && <EmployeeHeader parentPath={PATH_PROFILE} employee={employee} isProfilePage />}
            {tabPanel && <ProfileTabs />}
        </Box>
    )
}

