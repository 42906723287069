export enum ErrorCodes {
    ERROR_400 = 400,
    ERROR_401 = 401,
    ERROR_403 = 403,
    ERROR_404 = 404,
    ERROR_500 = 500,
    ERROR_502 = 502,
    ERROR_503 = 503,
    ERROR_504 = 504,
    ERROR_505 = 505,
}

export class GetErrorResponse implements IErrorResponse {
    constructor(errorResponse: any) {
        this.errorPreparation(errorResponse)
    }

    private _status = 0

    get status() {
        return this._status
    }

    private _userMessage = ''

    get userMessage() {
        return this._userMessage
    }

    private _message = ''

    get message() {
        return this._message
    }

    private _description = ''

    get description() {
        return this._description
    }

    private _actions = ''

    get actions() {
        return this._actions
    }

    private static getUserMessageFromError(error: any): string {
        return (error.response?.data?.error?.message as string) || error?.message as string
    }

    public getError() {
        return {
            message: this.message,
            description: this.description,
            actions: this.actions,
            userMessage: this.userMessage,
            status: this.status,
        }
    }

    private errorPreparation(error: any) {
        const userErrorMessage = GetErrorResponse.getUserMessageFromError(error)
        const statusError = error?.response?.status || error
        switch (statusError) {
            case ErrorCodes.ERROR_400: {
                this._message = 'Неверный запрос'
                this._description = ''
                this._actions = ''
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_400
                break
            }
            case ErrorCodes.ERROR_401: {
                this._message = 'Пользователь не авторизован'
                this._description = ''
                this._actions = ''
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_401
                break
            }
            case ErrorCodes.ERROR_403: {
                this._message = 'Доступ запрещен'
                this._description = 'Кажется, у вас недостаточно прав доступа\n' +
                    'для просмотра этой страницы.'
                this._actions = 'Обновите страницу или обратитесь к администратору.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_403
                break
            }
            case ErrorCodes.ERROR_404: {
                this._message = 'Страница не найдена'
                this._description = 'Страница, которую вы запрашиваете, не существует.\n' +
                    'Возможно, она устарела, была удалена, или был введен неверный адрес.'
                this._actions = 'Обновите страницу или обратитесь к администратору.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_404
                break
            }
            case ErrorCodes.ERROR_500: {
                this._message = 'Ошибка сервера'
                this._description = 'Возникла проблема на стороне сервера.\n' +
                    'Не беспокойтесь, уже работаем над ее решением.'
                this._actions = 'Попробуйте перезагрузить страницу, очистить кэш и cookies браузера или обратитесь к администратору.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_500
                break
            }
            case ErrorCodes.ERROR_502: {
                this._message = 'Запрос не выполнен'
                this._description = 'Возникла проблема на стороне сервера.\n' +
                    'Не беспокойтесь, уже работаем над ее решением.'
                this._actions = 'Попробуйте перезагрузить страницу, очистить кэш и cookies браузера или обратитесь к администратору.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_502
                break
            }
            case ErrorCodes.ERROR_503: {
                this._message = 'Сервер не отвечает'
                this._description = 'Возникла проблема на стороне сервера.\n' +
                    'Не беспокойтесь, уже работаем над ее решением.'
                this._actions = 'Попробуйте открыть эту страницу позднее.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_503
                break
            }
            case ErrorCodes.ERROR_504: {
                this._message = 'Истекло время запроса к серверу'
                this._description = ''
                this._actions = ''
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_504
                break
            }
            case ErrorCodes.ERROR_505: {
                this._message = 'Версия HTTP, используемая в запросе, не поддерживается сервером'
                this._description = ''
                this._actions = ''
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_505
                break
            }
            default: {
                this._message = 'Страница не найдена'
                this._description = 'Страница, которую вы запрашиваете, не существует.\n' +
                    'Возможно, она устарела, была удалена, или был введен неверный адрес.'
                this._actions = 'Обновите страницу или обратитесь к администратору.'
                this._userMessage = userErrorMessage
                this._status = ErrorCodes.ERROR_404
                break
            }
        }
    }
}
