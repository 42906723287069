import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IEmployeePdpCompetenceMaterialCreateRequest,
    IPdpChangePassItemRequest,
    IPdpChangePassMaterialRequest,
    IPdpCompetenceMaterialDeleteRequest,
    IPdpCompetenceMaterialUpdateRequest,
    IPdpItemCommentDeleteRequest,
    IPdpItemCommentUpdateRequest,
    IPdpItemMaterialCommentCreateRequest,
    IPdpItemMaterialCommentDeleteRequest,
    IPdpItemMaterialCommentUpdateRequest,
    IPdpItemUpdateRequest,
    IPdpMaterialFromBase,
    IPdpProgressRequest,
} from 'api/typing/employeeTypes'
import { changeActivePdpCompetenceMaterialSetup } from 'helpers/pdp/pdpMaterialSetup'
import { IDevelopmentPdpAddMaterialsFromBaseResponse } from 'api/typing/developmentPdpService'

interface PdpItemState {
    item: IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>;
    competenceBaseMaterials: IPdpMaterialFromBase[];
}

const initialState: IState<PdpItemState> = {
    data: {} as PdpItemState,
    isLoading: false,
    error: null,
}

export const pdpItemSlice = createSlice({
    name: 'pdpItem',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        changeOrderItems: (state, action: PayloadAction<{
            materials: IPdpCompetenceMaterial[];
            skillLevelUuid: string;
        }>) => {
            state.data.item.skillLevels.some(level => {
                if (level.uuid === action.payload.skillLevelUuid) {
                    // eslint-disable-next-line no-param-reassign
                    level.materials = action.payload.materials
                    return true
                }
                return false
            })
        },

        updateItem: (state, action: PayloadAction<IPdpItemUpdateRequest>) => {
            state.data.item = { ...state.data.item, ...action.payload }
        },

        createPdpItemComment: (state, action: PayloadAction<IComment>) => {
            state.data.item.comments.push(action.payload)
        },

        updatePdpItemComment: (state, action: PayloadAction<IPdpItemCommentUpdateRequest & IComment>) => {
            state.data.item.comments = state.data.item.comments.map(el => el.uuid === action.payload.uuid
                ? { ...el, ...action.payload }
                : el,
            )
        },

        deletePdpItemComment: (state, action: PayloadAction<IPdpItemCommentDeleteRequest>) => {
            state.data.item.comments = state.data.item.comments.filter(el => el.uuid !== action.payload.uuid)
        },

        createPdpItemMaterialComment: (
            state,
            action: PayloadAction<IPdpItemMaterialCommentCreateRequest & IComment>,
        ) => {
            const { skillLevelUuid, materialUuid, ...comment } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    material.comments.push(comment)
                    return true
                })
            })
        },

        updatePdpItemMaterialComment: (state, action: PayloadAction<IPdpItemMaterialCommentUpdateRequest>) => {
            const { skillLevelUuid, materialUuid, ...comment } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    material.comments = material.comments.map(commentItem => commentItem.uuid === comment.uuid
                        ? { ...commentItem, ...comment }
                        : commentItem,
                    )
                    return true
                })
            })
        },

        deletePdpItemMaterialComment: (state, action: PayloadAction<IPdpItemMaterialCommentDeleteRequest>) => {
            const { skillLevelUuid, materialUuid, uuid } = action.payload
            state.data.item.skillLevels.some(level => {
                if (level.uuid !== skillLevelUuid) return false

                return level.materials.some(material => {
                    if (material.uuid !== materialUuid) return false

                    // eslint-disable-next-line no-param-reassign
                    material.comments = material.comments.filter(comment => comment.uuid !== uuid)
                    return true
                })
            })
        },

        getPdpPlanItem: (state, action: PayloadAction<IPdpItem & Pick<IPdp, 'reviewer' | 'employee' | 'author'>>) => {
            state.data.item = action.payload
        },

        createPdpCompetenceMaterial: (
            state,
            action: PayloadAction<IEmployeePdpCompetenceMaterialCreateRequest & { uuid: string } & IPdpProgressRequest>,
        ) => {
            state.data.item?.skillLevels?.some(level => {
                if (level.uuid !== action.payload.skillLevelUuid) return false
                level.materials.push(action.payload)
                // eslint-disable-next-line no-param-reassign
                level.studyTime += action.payload.studyTime
                return true
            })
            state.data.item.progress = action.payload.progress
            state.data.item.studyTime += action.payload.studyTime
            state.data.item.changeLog = action.payload.changeLogPlanItem
        },

        updatePdpCompetenceMaterial: (
            state,
            action: PayloadAction<IPdpCompetenceMaterialUpdateRequest & IPdpProgressRequest>,
        ) => {
            state.data.item.changeLog = action.payload.changeLogPlanItem
            state.data.item.progress = action.payload.progress
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const oldSkillLevel = state.data.item.skillLevels.find(level =>
                level.materials.some(material =>
                    material.uuid === action.payload.uuid))!

            if (oldSkillLevel.uuid === action.payload.skillLevelUuid) {
                oldSkillLevel.materials = oldSkillLevel.materials.map(material => {
                    if (material.uuid !== action.payload.uuid) return material
                    oldSkillLevel.studyTime = oldSkillLevel.studyTime - material.studyTime + action.payload.studyTime
                    return {
                        ...material,
                        ...action.payload,
                    }
                })
            } else {
                state.data.item.skillLevels = state.data.item.skillLevels.map(level => {
                    if (level.uuid === oldSkillLevel.uuid) return {
                        ...level,
                        studyTime: level.studyTime - action.payload.studyTime,
                        materials: level.materials.filter(material => material.uuid !== action.payload.uuid),
                    }
                    if (level.uuid === action.payload.skillLevelUuid) {
                        // eslint-disable-next-line no-param-reassign
                        level.studyTime += action.payload.studyTime
                        level.materials.push(action.payload)
                    }
                    return level
                })
            }
        },

        deletePdpCompetenceMaterial: (
            state,
            action: PayloadAction<IPdpCompetenceMaterialDeleteRequest & IPdpProgressRequest>,
        ) => {
            const { uuid, skillLevelUuid } = action.payload
            state.data.item.progress = action.payload.progress
            state.data.item.changeLog = action.payload.changeLogPlanItem
            state.data.item.studyTime -= action.payload.studyTime
            state.data.item?.skillLevels?.some((level, levelIdx) => {
                if (level.uuid !== skillLevelUuid) return false
                state.data.item.skillLevels[levelIdx].studyTime -= action.payload.studyTime
                state.data.item.skillLevels[levelIdx].materials = level.materials?.filter(el => el.uuid !== uuid)
                return true
            })
        },

        setupPdpCompetenceMaterials: (state, action: PayloadAction<IDevelopmentPdpAddMaterialsFromBaseResponse>) => {
            state.data.item = {
                ...state.data.item,
                ...action.payload,
            }
            state.data.item.changeLog = action.payload.changeLogPlanItem
        },

        changeActivePdpCompetenceMaterialSetup: (state, action: PayloadAction<{ uuid: string; flag: boolean; }>) => {
            const { uuid, flag } = action.payload
            changeActivePdpCompetenceMaterialSetup(state.data.competenceBaseMaterials, uuid, flag)
        },

        changePassItem: (state, action: PayloadAction<IPdpChangePassItemRequest & IPdpProgressRequest>) => {
            state.data.item.isPassed = action.payload.isPassed
            state.data.item.changeLog = action.payload.changeLog
            state.data.item.pdpStatus = action.payload.status
        },

        changePassCompetenceMaterial: (
            state,
            action: PayloadAction<IPdpProgressRequest & IPdpChangePassMaterialRequest>,
        ) => {
            state.data.item.progress = action.payload.progress
            state.data.item.changeLog = action.payload.changeLogPlanItem
            state.data.item.pdpStatus = action.payload.status
            state.data.item.skillLevels.some(skillLevel => {
                if (skillLevel.uuid !== action.payload.skillLevelUuid) return false
                return skillLevel.materials.some(material => {
                    if (material.uuid !== action.payload.materialUuid) return false
                    // eslint-disable-next-line no-param-reassign
                    material.changeLog = action.payload.changeLog
                    // eslint-disable-next-line no-param-reassign
                    material.isPassed = action.payload.isPassed
                    return true
                })
            })
        },

        getMaterialsForPdpCompetence: (state, action: PayloadAction<IPdpMaterialFromBase[]>) => {
            state.data.competenceBaseMaterials = action.payload
        },
    },
})

export const pdpItemReducer = pdpItemSlice.reducer
