import { IReportAnalytic, IReportAssessment, IReportCPA } from 'api/typing/reportServiceTypes'
import {AppDispatch} from 'store/store'
import {ReportSlice} from 'store/reducers/report/ReportSlice'
import { ReportService } from 'api/services/ReportService'

export class ReportAction {
    private static get reportApi() {
        return new ReportService().reportService()
    }
    public assessmentAnalyticReport(payload: IReportAssessment) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(ReportSlice.actions.fetching())
                const data =  await ReportAction.reportApi.getReportAssessment(payload)
                dispatch(ReportSlice.actions.setAssessmentReport(payload))
                dispatch(ReportSlice.actions.fetchingSuccess())
                return data.reportLink
            } catch (e: any) {
                dispatch(ReportSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public cpaAnalyticReport(payload: IReportCPA) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(ReportSlice.actions.fetching())
                const data =  await ReportAction.reportApi.getReportCPA(payload)
                dispatch(ReportSlice.actions.setCPAReport(payload))
                dispatch(ReportSlice.actions.fetchingSuccess())
                return data.reportLink
            } catch (e: any) {
                dispatch(ReportSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public analyticReport(payload: IReportAnalytic) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(ReportSlice.actions.fetching())
                const data =  await ReportAction.reportApi.getReportAnalytic(payload)
                dispatch(ReportSlice.actions.setAnalyticReport(payload))
                dispatch(ReportSlice.actions.fetchingSuccess())
                return data.reportLink
            } catch (e: any) {
                dispatch(ReportSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
