import React, { FC } from 'react'
import { InputLabel, Stack, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

export type InputFormProps = {
    id: string;
    title?: string;
    colorLabel?: string;
}

export const InputForm: FC<TextFieldProps & InputFormProps> = React.forwardRef(function InputForm(inProps, ref) {
    return (
        <Stack spacing={0.5}>
            {inProps.title ?
                <InputLabel
                    htmlFor={inProps.id}
                    sx={{
                        color: inProps.colorLabel,
                        whiteSpace: 'normal',
                    }}
                >
                    {inProps.title} {inProps.required ? '*' : null}
                </InputLabel>
                :
                null
            }
            <TextField
                ref={ref}
                fullWidth
                {...inProps}
            />
        </Stack>
    )
})
