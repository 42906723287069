import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { Box, Chip, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { TITLE_NAMESPACE_EMPLOYEE } from 'helpers/enums/titles'
import {
    EmployeeAssessmentComment,
} from 'pages/employees/EmployeeAssessmentPage/components/EmployeeAssessment/components/EmployeeAssessmentComments/components/EmployeeAssessmentComment/EmployeeAssessmentComment'
import { InfoOutlined, WarningAmberRounded } from '@mui/icons-material'


type EmployeeAssessmentIndicatorAnswerProps = {
    answer: IAssessmentIndicatorAnswer;
    indicator: IAssessmentIndicator,
    competenceUuid: string,
}

export const EmployeeAssessmentIndicatorAnswer: FC<EmployeeAssessmentIndicatorAnswerProps> = ({
    indicator,
    answer,
    competenceUuid,
}) => {
    const { assessmentUuid } = useParams()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const formAnswers = useAppSelector(state => state.MyAssessmentPassReducer.data.answers)
    const cpaRoles = useAppSelector(state => state.formReducer.data.cpaRoles)

    const currentRole = useMemo(() => cpaRoles?.find(el => el.uuid === answer?.role?.uuid), [cpaRoles, answer])
    const currentAnswer = useMemo(() => formAnswers?.find(el => el.uuid === answer.uuid), [formAnswers, answer])
    const currentComments = useMemo(
        () => indicator?.comments.filter(el => !currentRole ? !el.roleUuid : el.roleUuid === answer?.role?.uuid) || [],
        [currentRole, indicator, answer],
    )

    const deleteComment = async (deletedUuid: string) => {
        if (!assessmentUuid) return

        try {
            await dispatch(myAssessmentActions.deleteIndicatorComment({
                indicatorUuid: indicator?.uuid,
                uuid: deletedUuid,
                assessmentUuid,
                competenceUuid,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if  (answer?.respondentUuid === 'final') return null

    return (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={267}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body2">
                        {currentRole?.title ?? TITLE_NAMESPACE_EMPLOYEE}
                    </Typography>
                    {answer.role?.divergent && answer.isCalculated && <Tooltip
                        title="Ответы участников этой роли расходятся"
                        enterDelay={300}
                        enterNextDelay={300}
                    >
                        <WarningAmberRounded color="warning"/>
                    </Tooltip>}
                    {!answer.isCalculated && <Tooltip
                        title="Недостаточно данных для объективной оценки"
                        enterDelay={300}
                        enterNextDelay={300}
                    >
                        <InfoOutlined color="error"/>
                    </Tooltip>}
                </Stack>
                <Chip
                    label={currentAnswer?.title}
                    size="small"
                />
            </Box>
            {currentComments?.length > 0 &&
                <Paper elevation={0}
                    sx={{ p: 2, background: (theme) => theme.palette.background.default, borderRadius: 4 }}>
                    <Stack spacing={2} divider={<Divider/>}>
                        {currentComments.map(comment => <EmployeeAssessmentComment
                            key={comment.uuid}
                            comment={comment}
                            deleteComment={deleteComment}
                        />)}
                    </Stack>
                </Paper>
            }
        </Stack>
    )
}
