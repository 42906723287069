import React from 'react'
import {
    OptionsObject,
    SnackbarKey,
    useSnackbar,
} from 'notistack'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { notifierActions } from 'store/actions'

let displayed: SnackbarKey[] = []

type AdditionalProps = {
    link?: string;
}

export const useNotifier = () => {
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(state => state.notifierReducer.data)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)]
    }

    React.useEffect(() => {
        notifications.forEach(({ key, message, type, link, dismissed = false, style }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key)
                return
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return

            // prepare option object
            let preparedOption: OptionsObject & AdditionalProps = {
                key,
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(notifierActions.notifyRemove(myKey))
                    removeDisplayed(myKey)
                },
            }

            if (style) {
                preparedOption.style = style
            }

            switch (type) {
                case 'error': {
                    preparedOption.persist = true
                    preparedOption.variant = 'error'
                    break
                }
                case 'success': {
                    preparedOption.variant = 'success'
                    break
                }
                case 'warning': {
                    preparedOption.variant = 'warning'
                    break
                }
                default: {
                    preparedOption.variant = 'default'
                    preparedOption = {
                        ...preparedOption,
                        link,
                    }
                    break
                }
            }
            // display snackbar using notistack
            enqueueSnackbar(message, preparedOption)

            // keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [notifications])
}
