import React from 'react'
import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import { TITLE_NAMESPACE_WEAK_SIDES } from 'helpers/enums/titles'

const getWeakSidesSortLess = (tree: IAssessmentCompetenceGroup[] | undefined) => {
    if (!tree) return []
    return tree.reduce((acc, group) => {
        acc.push(
            ...getWeakSidesSortLess(group.groupCompetence),
            ...group.competencies.reduce((a, el) => {
                if (el.score <= 75) a.push({ ...el, title: el.title.replace(/^(\d+\.\s)/gm, '') })
                return a
            }, [] as IAssessmentCompetenceGroupCompetence[]),
        )
        return acc
    }, [] as IAssessmentCompetenceGroupCompetence[])
        .sort((a, b) => a.score - b.score)
        .slice(0, 3)
}

export const OAAssessmentResultWeakSide = () => {
    const theme = useTheme()
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    const weakSides = getWeakSidesSortLess(assessmentResult?.groupCompetence)

    if (weakSides.length <= 0) return null

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h2">
                    {TITLE_NAMESPACE_WEAK_SIDES}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {weakSides.map(side =>
                    <Grid key={`${side.uuid}-${side.score}`} item mobile="auto" maxWidth="100%">
                        <Chip
                            label={side.title}
                            color="success"
                            sx={{
                                // @ts-ignore
                                backgroundColor: theme.palette.warning[50],
                                color: theme.palette.warning.dark,
                            }}
                            size="small"
                        />
                    </Grid>)}
            </Grid>
        </Box>
    )
}
