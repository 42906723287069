import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import {
    AssessmentResultTreeItemMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeItemMobile/AssessmentResultTreeItemMobile'
import { Divider, Stack } from '@mui/material'
import {
    AssessmentResultTreeShowMoreMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/components/AssessmentResultTreeShowMoreMobile/AssessmentResultTreeShowMoreMobile'

const previewItemsCount = 3

export const AssessmentResultTreeMobile = () => {
    const competenciesList = useAppSelector(state => state.assessmentResultReducer.data.competenciesFlatList)

    return (
        <Stack spacing={2}>
            <Divider/>
            <Stack spacing={2} divider={<Divider/>}>
                {competenciesList.slice(0, previewItemsCount).map(competence => <AssessmentResultTreeItemMobile
                    key={competence.uuid}
                    competence={competence}
                />)}
            </Stack>
            <Divider/>
            <AssessmentResultTreeShowMoreMobile
                previewItemsCount={previewItemsCount}
            />
        </Stack>
    )
}
