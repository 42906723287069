import { MassExamService } from 'api/services/massExamService'
import { massExamItemSlice } from '../../reducers/massExam/massExamItemSlice'
import { AppDispatch } from '../../store'
import {
    IMassExamChangeStatusRequest,
    IMassExamDescriptionUpdateRequest,
    IMassExamItemMeta,
    IMassExamPassMarkSaveRequest,
    IMassExamUpdateRequest,
} from 'api/typing/massExam'
import { massExamItemActions } from '../index'

export class MassExamItemAction {
    private static get massExamItemApi() {
        return new MassExamService().itemService()
    }

    public getItem(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                const data = await MassExamItemAction.massExamItemApi.getItem(uuid)
                dispatch(massExamItemSlice.actions.setMassExamMeta(data))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setItem(data: IMassExamItemMeta) {
        return (dispatch: AppDispatch) => {
            dispatch(massExamItemSlice.actions.setMassExamMeta(data))
        }
    }

    public updateItem(data: IMassExamUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.updateItem(data)
                dispatch(massExamItemSlice.actions.updateItem(data))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteItem(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.deleteItem(uuid)
                dispatch(massExamItemActions.clearState())
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateDescription(data: IMassExamDescriptionUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.updateItemDescription(data)
                dispatch(massExamItemSlice.actions.updateDescription(data))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(massExamItemSlice.actions.clearState())
        }
    }

    public startMassExam(data: IMassExamChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.changeStatusMassExam(data)
                dispatch(massExamItemSlice.actions.changeStatusMassExam(data))
                dispatch(massExamItemSlice.actions.startMassExam())
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public completeMassExam(data: IMassExamChangeStatusRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                await MassExamItemAction.massExamItemApi.changeStatusMassExam(data)
                await dispatch(massExamItemActions.getItem(data.uuid))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePassMark(data: IMassExamPassMarkSaveRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.savePassMark(data)
                dispatch(massExamItemSlice.actions.updatePassMark(data))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePassMark(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                void await MassExamItemAction.massExamItemApi.deletePassMark(uuid)
                dispatch(massExamItemSlice.actions.deletePassMark())
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setNotifiedAt(notifiedAt: string){
        return (dispatch: AppDispatch) => {
            dispatch(massExamItemSlice.actions.setNotifiedAt(notifiedAt))

        }
    }

    public getQuestionPreview(data: { massExamUuid: string, questionUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(massExamItemSlice.actions.fetching())
                const question =
                    await MassExamItemAction.massExamItemApi.getQuestionPreview(data.massExamUuid, data.questionUuid)
                dispatch(massExamItemSlice.actions.setQuestionPreview(question))
                dispatch(massExamItemSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(massExamItemSlice.actions.fetchingError(e))
                throw e
            }
        }
    }
}
