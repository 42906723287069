import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks/redux'
import { PATH_DEVELOPMENT_PLAN, PATH_OPEN_ACCESS } from 'helpers/enums/routePath'
import { developmentPlanActions } from 'store/actions'
import {
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import {
    TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN,
    TITLE_BUTTON_OPEN,
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_FORMAT,
    TITLE_NAMESPACE_LINK,
    TITLE_NAMESPACE_SKILL_LEVEL,
    TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_TITLE,
    TITLE_NAMESPACE_TYPE,
} from 'helpers/enums/titles'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { CommentOutlined } from '@mui/icons-material'
import { DrawerMaterialCommentView } from 'components/drawers/DrawerMaterialCommentView/DrawerMaterialCommentView'
import { MaterialTypeIcon } from 'components/common/MaterialTypeIcon/MaterialTypeIcon'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'

// TODO: need refactoring to separate this component

export const DevelopmentPlanCompetence = () => {
    const navigate = useNavigate()
    const { pdpHash, competenceUuid, entityType } = useParams()
    const { competence } = useAppSelector(state => state.openAccessDevelopmentPlanReducer.data)
    const [viewComment, setViewedComment] = useState<DialogMaterialCommentState | null>({
        view: false,
        comment: '',
        materialTitle: '',
        materialFormat: '',
    })

    const goBackToDevelopmentPlan = `${PATH_OPEN_ACCESS}/${PATH_DEVELOPMENT_PLAN}/${pdpHash}`

    const {
        error,
        isFetching,
    } = useLayoutFetch(
        developmentPlanActions.getPdpCompetence(pdpHash || '', competenceUuid || '', entityType || ''),
        { deps: [pdpHash, competenceUuid, entityType] },
    )

    const setOpenComment = (comment: string, materialTitle: string, materialFormat: string) => {
        setViewedComment({
            view: true,
            comment,
            materialTitle,
            materialFormat,
        })
    }

    if (isFetching) return <LoadingComponent/>
    if (error) return <ErrorPage error={error}/>
    if (!competence) return null

    return (
        <Box>
            <Card>
                <CardContent sx={{ p: 4 }}>
                    <Box>
                        <Button
                            startIcon={<ArrowBackRoundedIcon/>}
                            onClick={() => navigate(goBackToDevelopmentPlan, { replace: true })}
                            sx={{ mb: 3 }}
                        >
                            {TITLE_BUTTON_BACK_TO_DEVELOPMENT_PLAN}
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={6}>
                        <Box maxWidth="70%">
                            <Typography variant="h2" mb={1}>
                                {competence.title}
                            </Typography>
                            <Typography variant="body1" mb={2}>
                                {competence.typeTitle}
                            </Typography>
                            <Typography variant="body1" mb={2}>
                                {competence.description}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" mb={0.5}>
                            {TITLE_NAMESPACE_SKILL_LEVEL}: {competence.skillLevelTitle}
                        </Typography>
                        <Typography variant="body1" mb={2}>
                            {TITLE_NAMESPACE_STUDY_TIME}: {formatHours(competence.studyTime)}
                        </Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="1%" align="center">{TITLE_NAMESPACE_TYPE}</TableCell>
                                        <TableCell width="100%" align="left">{TITLE_NAMESPACE_TITLE}</TableCell>
                                        <TableCell width="1%" align="center">{TITLE_NAMESPACE_COMMENT}</TableCell>
                                        <TableCell width="1%" align="left">{TITLE_NAMESPACE_FORMAT}</TableCell>
                                        <TableCell
                                            sx={{ whiteSpace: 'nowrap' }}
                                            align="left"
                                        >
                                            {TITLE_NAMESPACE_STUDY_TIME}
                                        </TableCell>
                                        <TableCell width="1%" padding="checkbox"
                                            align="left">{TITLE_NAMESPACE_LINK}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {competence.materials?.map((material) => (
                                        <TableRow key={material.uuid} sx={{ height: 52 }}>
                                            <TableCell>
                                                <MaterialTypeIcon materialTypeUuid={material.materialTypeUuid} />
                                            </TableCell>
                                            <TableCell align="left">
                                                {material.title}
                                            </TableCell>
                                            <TableCell align="center">
                                                {material.comment && <IconButton
                                                    color="primary"
                                                    onClick={() => setOpenComment(
                                                        material.comment,
                                                        material.title,
                                                        material.format,
                                                    )}
                                                >
                                                    <CommentOutlined />
                                                </IconButton>}
                                            </TableCell>
                                            <TableCell align="left">
                                                {material.format}
                                            </TableCell>
                                            <TableCell align="left">
                                                {formatHours(material.studyTime)}
                                            </TableCell>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">
                                                {material.link && material.link.length > 0 && <Button
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    endIcon={<ArrowForwardRoundedIcon />}
                                                    target="_blank"
                                                    href={material.link}
                                                >
                                                    {TITLE_BUTTON_OPEN}
                                                </Button>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            {viewComment && <DrawerMaterialCommentView
                open={viewComment.view}
                comment={viewComment.comment}
                materialTitle={viewComment.materialTitle}
                materialFormat={viewComment.materialFormat}
                handleClose={() => setViewedComment(null)}
            />}
        </Box>
    )
}
