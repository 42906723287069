import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'

export const changeActivePdpCompetenceMaterialSetup = (
    list: IPdpMaterialFromBase[],
    uuid: string,
    flag: boolean,
) => {
    list.some((material, idx) => {
        if (material.uuid !== uuid) return false

        // по ссылке меняем элементу флаг выбранности и измененности
        // eslint-disable-next-line no-param-reassign
        list[idx] = {
            ...material,
            selected: flag,
            isChanged: !material.isChanged,
        }
    })
}
