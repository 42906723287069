import React, { FC } from 'react'
import { TITLE_BUTTON_OPEN } from 'helpers/enums/titles'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { Box, Button, Checkbox, IconButton, Stack, TableCell, TableRow, useTheme } from '@mui/material'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import {
    AddCommentOutlined,
    CheckCircleRounded,
    CommentOutlined,
    RadioButtonUncheckedRounded,
} from '@mui/icons-material'
import { MaterialTypeIcon } from 'components/common/MaterialTypeIcon/MaterialTypeIcon'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'

type PdpCompetenceMaterialDesktopProps = {
    material: IPdpCompetenceMaterial;
    item: IPdpItem & Pick<IPdp, 'employee' | 'author' | 'reviewer'>;
    pdpStatus: IPdpStatus;
    draggableProvided: DraggableProvided;
    snapshot: DraggableStateSnapshot
    isDevelopment?: boolean;
    employee: IEmployee;
    pdpStatusReview: boolean;
    isUniqueMaterial: boolean;
    changePassMaterial: () => void;
    openDeleteMaterial: () => void;
    openEditMaterial: () => void;
    toggleDialog: (arg1: any, arg2: boolean) => void;
}

export const PdpCompetenceMaterialDesktop: FC<PdpCompetenceMaterialDesktopProps> = ({
    material,
    pdpStatus,
    draggableProvided,
    snapshot,
    isDevelopment,
    employee,
    pdpStatusReview,
    changePassMaterial,
    openDeleteMaterial,
    isUniqueMaterial,
    openEditMaterial,
    item,
    toggleDialog,
}) => {
    const theme = useTheme()

    return (
        <TableRow
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
                ...draggableProvided.draggableProps.style,
                // @ts-ignore
                background: material.isPassed ? theme.palette.success[50]
                    : snapshot.isDragging
                        ? 'rgba(245,245,245, 0.75)'
                        : 'none',
                height: 52,
            }}
        >
            {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
            <Authenticated
                service={isDevelopment ? undefined : 'pdp'}
                accessLevel="w"
                divisions={[employee?.division?.uuid]}
            >
                <TableCell padding="none" sx={{ p: 1 }}>
                    <Box display="flex">
                        <Box{...draggableProvided?.dragHandleProps} display="flex">
                            <Box
                                sx={{
                                    backgroundColor: '#F8F8F8',
                                    borderRadius: 4,
                                    width: 24,
                                    py: 1.25,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <DragIndicatorRoundedIcon
                                    fontSize="small"
                                    color="action"
                                />
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
            </Authenticated>
            {pdpStatus.status !== 'default' && pdpStatus.status !== 'approval' &&
                <TableCell align="center">
                    {pdpStatusReview
                        ? <Box>
                            <Checkbox
                                color="success"
                                checked={material.isPassed}
                                onChange={changePassMaterial}
                            />
                        </Box>
                        : <Box>
                            {material.isPassed
                                ? <CheckCircleRounded color={'success'}/>
                                : <RadioButtonUncheckedRounded sx={{ color: theme.palette.success.light }}/>}
                        </Box>}
                </TableCell>
            }
            <TableCell>
                <MaterialTypeIcon materialTypeUuid={material.materialTypeUuid}/>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box flex={1}>
                        {material.title}
                    </Box>
                    {!!material?.changeLog &&
                        <BadgeChangeLog changeLog={material?.changeLog} evaluatedUuid={item?.employee?.uuid}/>}
                </Stack>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => toggleDialog('dialogCommentEdit', true)}>
                    {material.comments?.length > 0
                        ? <CommentOutlined color="primary"/>
                        : <AddCommentOutlined color={'action'}/>}
                </IconButton>
            </TableCell>
            <TableCell align="left">
                {material.format}
            </TableCell>
            <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                {formatHours(material.studyTime)}
            </TableCell>
            <TableCell sx={{ paddingRight: 0 }} align="left">
                {material.link && <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    endIcon={<ArrowForwardRoundedIcon/>}
                    target="_blank"
                    href={material.link}
                >
                    {TITLE_BUTTON_OPEN}
                </Button>}
            </TableCell>
            <Authenticated
                service={isDevelopment ? undefined : 'pdp'}
                accessLevel="w"
                divisions={[employee?.division?.uuid]}
            >
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                    <SecondaryActions
                        onDelete={() => openDeleteMaterial()}
                        onEdit={isUniqueMaterial ? () => openEditMaterial() : undefined}
                    />
                </TableCell>
            </Authenticated>
        </TableRow>
    )
}
