import React, { FC } from 'react'
import { Stack } from '@mui/material'
import { CPAAnalyticContentSides } from './components/CPAAnalyticContentSides/CPAAnalyticContentSides'
import { CPAAnalyticSkeleton } from '../CPAAnalyticSkeleton/CPAAnalyticSkeleton'
import { useGetAnalyticCpaStatisticSummary } from 'api/queries/analytics/analytics'
import { useAppSelector } from 'store/hooks/redux'
import { CPAAnalyticContentAverage } from './components/CPAAnalyticContentAverage/CPAAnalyticContentAverage'
import { CPAAnalyticContentEmployees } from './components/CPAAnalyticContentEmployees/CPAAnalyticContentEmployees'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'


type CPAAnalyticContentProps = {
    hideAverage?: boolean;
}

export const CPAAnalyticContent: FC<CPAAnalyticContentProps> = ({ hideAverage }) => {
    const cpaMeta = useAppSelector(state => state.CPAItemReducer.meta)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const cpaCriteriaPoint = useAppSelector(state => state.CPAItemCriteriaReducer.pointCriteria)
    const { data, isLoading } = useGetAnalyticCpaStatisticSummary(cpaMeta.uuid, cpaMeta && cpaStatusCode === 'complete')
    const isAllGrades = cpaMeta.criteria?.uuid !== cpaCriteriaCurrentUuid
        && cpaCriteriaPoint?.grade && isEmptyUuid(cpaCriteriaPoint?.grade.uuid)

    if (isLoading) return <CPAAnalyticSkeleton/>
    if (!data) return null

    return (
        <Stack spacing={2}>
            {!hideAverage &&
                <CPAAnalyticContentAverage average={data.average} isAllGrades={isAllGrades}/>
            }
            <CPAAnalyticContentSides competencies={data.competencies}/>
            {!isAllGrades && <CPAAnalyticContentEmployees employees={data.employees}/>}
        </Stack>
    )
}
