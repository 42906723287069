import { TransferForm } from 'api/services/FormService/transferForm'
import { AxiosStatic } from 'axios'
import { IPdpMaterialFromBase } from 'api/typing/employeeTypes'

export class EmployeeFormService extends TransferForm {
    private static instance: EmployeeFormService
    private prefix = 'employee'

    static getInstance(axiosInstance: AxiosStatic) {
        if (!EmployeeFormService.instance)
            EmployeeFormService.instance = new EmployeeFormService(axiosInstance)
        return EmployeeFormService.instance
    }

    public getEmployeeListDivisions() {
        const path = `/${this.prefix}/list/division/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyCurrentPositionDivisions() {
        const path = `/${this.prefix}/edit/company/current-position/division/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyCurrentPositionSpecializations(divisionUuid: string) {
        const path = `/${this.prefix}/edit/company/current-position/division/${divisionUuid}/specialization/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyCurrentPositionGrades(specializationUuid: string) {
        const path = `/${this.prefix}/edit/company/current-position/specialization/${specializationUuid}/grade/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyPreviousPositionDivisions() {
        const path = `/${this.prefix}/edit/company/previous-position/division/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyPreviousPositionSpecializations(divisionUuid: string) {
        const path = `/${this.prefix}/edit/company/previous-position/division/${divisionUuid}/specialization/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyPreviousPositionGrades(specializationUuid: string) {
        const path = `/${this.prefix}/edit/company/previous-position/specialization/${specializationUuid}/grade/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyProjectsAndRolesProjects() {
        const path = `/${this.prefix}/edit/company/project-and-roles/projects/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditWorkInCompanyProjectsAndRolesRoles() {
        const path = `/${this.prefix}/edit/company/project-and-roles/role/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getEmployeeEditEducationLevels() {
        const path = `/${this.prefix}/edit/education/level/all`
        return this.request<null, IEntity[]>('GET', path)
    }

    public getCompetenceForPdp(employeeUuid: string, pdpUuid: string) {
        const path = `/${this.prefix}/${employeeUuid}/pdp/${pdpUuid}/competence/all`
        return this.request<null, ISetupCompetenceGroup[]>('GET', path)
    }

    public getMaterialsForPdpCompetence(
        pdpUuid: string,
        specializationUuid: string,
        competenceUuid: string,
        entityType: EntityType,
    ) {
        const path = `/${this.prefix}/pdp/${pdpUuid}/specialization/${specializationUuid}/competence/${competenceUuid}/${entityType}/material/all`
        return this.request<null, IPdpMaterialFromBase[]>('GET', path)
    }
}
