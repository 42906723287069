import React, { FC } from 'react'
import { Box, Fab, useMediaQuery, useScrollTrigger, useTheme, Zoom } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'

type ButtonScrollTopProps = {
    target: Node | undefined;
}

export const ButtonScrollTop: FC<ButtonScrollTopProps> = ({target}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const trigger = useScrollTrigger({
        target: target,
        disableHysteresis: true,
        threshold: 100,
    })

    const scrollToTop = () => {
        //@ts-ignore
        target?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    if (!mobile) return null

    return (
        <Zoom in={trigger}>
            <Box
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 32,
                    right: 32,
                    zIndex: 1,
                }}
            >
                <Fab
                    onClick={scrollToTop}
                    // color="primary"
                    color="inherit"
                    sx={{
                        background: theme.palette.background.paper,
                        backgroundColor: theme.palette.background.paper,
                    }}
                    // variant="extended"
                    size="medium"
                    aria-label="Scroll back to top"
                >
                    <KeyboardArrowUp fontSize="medium" />
                </Fab>
            </Box>
        </Zoom>
    )
}
