import {
    Box,
    Button,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    TITLE_ABOUT_DEVELOPMENT_PDP,
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_COMMENT_PDP,
    TITLE_BUTTON_SEND_TO_MANAGER,
    TITLE_BUTTON_SEND_TO_REVIEW,
    TITLE_DIALOG_PDP_SEND_TO_REVIEWER,
    TITLE_NAMESPACE_DEVELOPMENT_PLAN,
    TITLE_NAMESPACE_PROGRESS_COMPLETE,
} from 'helpers/enums/titles'
import { DevelopmentPdpInfo } from '../DevelopmentPdpInfo/DevelopmentPdpInfo'
import React, { CSSProperties, FC, useMemo } from 'react'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { AddCommentOutlined, CommentOutlined, SendRounded } from '@mui/icons-material'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useMobile } from 'helpers/hooks/useMobile'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { developmentPdpActions } from 'store/actions'
import {
    HINT_PDP_SEND_TO_REVIEW,
    HINT_PDP_SEND_TO_REVIEW_HELPER_TEXT,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
} from 'helpers/enums/hints'
import { useNotify } from 'store/hooks/useNotify'
import { useAppDispatch } from 'store/hooks/redux'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { GFAccordion } from 'components/common/GFAccordion/GFAccordion'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { biggerBtnForMobile } from 'styles/styles'

type DevelopmentPdpInfoMobileProps = {
    pdp: IDevelopmentPdp;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpInfoMobile: FC<DevelopmentPdpInfoMobileProps> = ({ pdp, pdpStatus }) => {
    const mobile = useMobile()
    const desktop = useDesktop()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const statusApproval = pdpStatus?.status === 'approval'
    const statusCreated = pdpStatus?.status === 'default'
    const statusProgress = pdpStatus?.status === 'inProgress'
    const statusReturned = pdpStatus?.status === 'returned'
    const statusSent = pdpStatus?.status === 'sent'
    const disableEditComments = !(statusProgress || statusReturned || statusSent || statusCreated)

    const { toggleDialog, dialogComment, dialogComplete } = useDialogsState({
        dialogComplete: false,
        dialogComment: false,
        dialogPoint: false,
    })

    const notifyStyles: CSSProperties = useMemo(() => desktop ? {} : {position: 'inherit', bottom: '110px', width: 'fit-content'}, [])
    const sendToReview = async () => {
        try {
            await dispatch(developmentPdpActions.sendToReview(pdp.currentPdpUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const deleteComment = async (uuid: string) => {
        try {
            await dispatch(developmentPdpActions.deletePdpComment({
                uuid,
                pdpUuid: pdp.currentPdpUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    const createComment = async (comment: IComment) => {
        try {
            await dispatch(developmentPdpActions.createPdpComment({
                ...comment,
                pdpUuid: pdp.currentPdpUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    return (
        <>
            {!statusCreated && !statusApproval &&
                <Box mb={3}>
                    <Grid item mobile={1}>
                        <Box display="flex" height="100%" justifyContent="center">
                            <Divider orientation="vertical" flexItem/>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        mobile={5.5}
                        display="flex"
                        flexDirection="column"
                        alignItems="end"
                        justifyContent="flex-end"
                    >
                        <GFProgressBar
                            value={pdp?.progress}
                            title={TITLE_NAMESPACE_PROGRESS_COMPLETE}
                        />
                        {pdpStatus.status !== 'review' &&
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!pdp?.canSend || pdpStatus.disableChangeItemStatus}
                                startIcon={<SendRounded/>}
                                sx={{ mt: 2, ...biggerBtnForMobile }}
                                onClick={() => toggleDialog('dialogComplete', true)}
                            >
                                {TITLE_BUTTON_SEND_TO_REVIEW}
                            </Button>
                        }
                    </Grid>
                </Box>
            }
            <DrawerComments
                open={dialogComment}
                disableEdit={disableEditComments}
                handleClose={() => toggleDialog('dialogComment', false)}
                entityTitle={TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                itemTitle={<PointPositionSpecializationGrade
                    title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}/>}
                comments={pdp.comments}
                onCreateComment={createComment}
                onDeleteComment={deleteComment}
            />
            <DialogConfirm
                open={dialogComplete}
                dialogTitle={TITLE_DIALOG_PDP_SEND_TO_REVIEWER}
                dialogText={HINT_PDP_SEND_TO_REVIEW}
                dialogHelperText={HINT_PDP_SEND_TO_REVIEW_HELPER_TEXT}
                titleButtonConfirm={TITLE_BUTTON_SEND_TO_MANAGER}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                onSubmit={sendToReview}
                handleClose={() => toggleDialog('dialogComplete', false)}
            />
            <Button
                startIcon={pdp?.comments?.length > 0
                    ? <CommentOutlined color="primary"/>
                    : <AddCommentOutlined color="action"/>}
                disabled={disableEditComments && pdp?.comments?.length <= 0}
                variant="outlined"
                sx={!mobile ? {
                    width: 'fit-content',
                    mb: 3 ,
                } : {
                    width: '100%',
                    mb: 3 ,
                    fontSize: 16,
                    lineHeight: '26px',
                    padding: '12px 20px',
                }}
                onClick={() => toggleDialog('dialogComment', true)}
            >
                {TITLE_BUTTON_COMMENT_PDP}
            </Button>
            <GFAccordion>
                <Stack direction='row' alignItems='center'>
                    <InfoOutlinedIcon color='primary' sx={{ mr: 1 }} />
                    <Typography variant='body2'>{TITLE_ABOUT_DEVELOPMENT_PDP}</Typography>
                </Stack>
                <DevelopmentPdpInfo pdp={pdp} pdpStatus={pdpStatus} />
            </GFAccordion>
        </>
    )
}
