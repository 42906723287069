import React, { useMemo, useState } from 'react'
import { Box, IconButton, Stack } from '@mui/material'
import { useAppSelector } from 'store/hooks/redux'
import {
    EmployeesTabCurrentDepartment,
} from 'pages/employees/EmployeesPage/components/EmployeesTabCurrent/components/EmployeesTabCurrentDepartment/EmployeesTabCurrentDepartment'
import { containsText } from 'helpers/containsText'
import { InputForm } from 'components/form/InputForm/InputForm'
import { ClearRounded, FilterListRounded, SearchRounded } from '@mui/icons-material'
import { employeeActions } from 'store/actions'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { Navigate } from 'react-router-dom'
import { PATH_ERROR } from 'helpers/enums/routePath'
import { TITLE_FIELD_PLACEHOLDER_SEARCH_EMPLOYEE } from 'helpers/enums/titles'

export const EmployeesTabCurrent = () => {
    const { data: { employeesCurrent }, isLoading } = useAppSelector(state => state.employeeReducer)
    const [search, setSearch] = useState('')
    const filteredEmployees = useMemo(() =>
        employeesCurrent?.map(el => ({
            ...el,
            employees: el.employees?.filter(employee =>
                containsText(employee.firstName, search)
                    || containsText(employee.lastName, search)
                    || containsText(employee.secondName, search),
            ),
        }))?.filter(el => el.employees?.length),
    [search, employeesCurrent],
    )

    const { isFetching, error } = useLayoutFetch(employeeActions.getEmployeesCurrent(), {
        permissions: { service: 'employee', accessLevel: 'r' },
    })

    if (!employeesCurrent && !isLoading) return null
    if (!employeesCurrent) return null

    if (isFetching) return <LoadingComponent />
    if (error) return <Navigate to={PATH_ERROR} replace state={error} />

    return (
        <Box>
            <Stack spacing={2} mb={5} direction="row" alignItems="center" maxWidth={600}>
                <Box flex={1}>
                    <InputForm
                        id="search-employee"
                        placeholder={TITLE_FIELD_PLACEHOLDER_SEARCH_EMPLOYEE}
                        variant="outlined"
                        value={search}
                        fullWidth
                        InputProps={{
                            endAdornment: search.length > 0
                                ? <IconButton
                                    size="small"
                                    onClick={() => setSearch('')}
                                >
                                    <ClearRounded color="disabled"/>
                                </IconButton>
                                : <SearchRounded color="disabled"/>,
                        }}
                        onChange={({ target: { value } }) => setSearch(value)}
                    />
                </Box>
                <IconButton color="primary">
                    <FilterListRounded/>
                </IconButton>
            </Stack>
            <Box>
                {filteredEmployees?.map(el => <EmployeesTabCurrentDepartment
                    key={el.uuid}
                    element={el}
                />)}
            </Box>
        </Box>
    )
}

