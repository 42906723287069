import { Stack } from '@mui/material'
import React, { FC } from 'react'
import {
    CPAAnalyticContentEmployeesAtRisk,
} from './components/CPAAnalyticContentEmployeesAtRisk/CPAAnalyticContentEmployeesAtRisk'
import {
    CPAAnalyticContentEmployeesBest,
} from './components/CPAAnalyticContentEmployeesBest/CPAAnalyticContentEmployeesBest'

type CPAAnalyticContentEmployeesProps = {
    employees: TAnalyticCpaStatisticSummary['employees'];
}

export const CPAAnalyticContentEmployees: FC<CPAAnalyticContentEmployeesProps> = ({ employees }) => {
    return (
        <Stack direction="row" justifyContent="justify" spacing={2}>
            <CPAAnalyticContentEmployeesAtRisk employees={employees?.lowest} />
            <CPAAnalyticContentEmployeesBest employees={employees?.highest} />
        </Stack>
    )
}
