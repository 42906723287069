import { AxiosStatic } from 'axios'
import { TransferForm } from 'api/services/UploadService/transferForm'
import { IUploadEmployeePhotoRequest } from 'api/typing/uploadServiceTypes'

export class EmployeeUploadService extends TransferForm {
    private static instance: EmployeeUploadService

    static getInstance(axiosInstance: AxiosStatic) {
        if (!EmployeeUploadService.instance)
            EmployeeUploadService.instance = new EmployeeUploadService(axiosInstance)
        return EmployeeUploadService.instance
    }

    public uploadEmployeePhoto(data: IUploadEmployeePhotoRequest) {
        const path = '/employee/photo'
        return this.request<IUploadEmployeePhotoRequest, { path: string }>('POST', path, { data })
    }
}
