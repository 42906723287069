import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGradeUpdate } from 'api/typing/specializationTypes'

const initialState: IState<IGrade> = {
    data: {} as IGrade,
    isLoading: false,
    error: null,
}

export const gradeSlice = createSlice({
    name: 'grade',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        getGrade: (state, action: PayloadAction<IGrade>) => {
            state.isLoading = false
            state.error = null
            state.data = action.payload
        },
        updateGrade: (state, action: PayloadAction<IGradeUpdate>) => {
            state.isLoading = false
            state.error = null
            state.data = { ...state.data, ...action.payload }
        },
        deleteGrade: (state) => {
            state.isLoading = false
            state.error = null
            state.data = {} as IGrade
        },
    },
})

export const gradeReducer = gradeSlice.reducer
