import React, { FC } from 'react'
import { ArrowForwardRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'

type PointPositionGradeChipProps = {
    title: string;
}

export const PointPositionGradeChip: FC<PointPositionGradeChipProps> = ({title}) => (
    <Chip
        label={title}
        size="medium"
        icon={<ArrowForwardRounded/>}
        variant="outlined"
        color="warning"
        sx={{
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '18px',
            padding: '4px 4px 4px 12px',
        }}
    />
)

