import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus, IAssessmentStatusItem } from 'helpers/assessment/getAssessmentStatus'
import { GFAlertInfo } from 'components/common/GFAlertInfo/GFAlertInfo'
import { CpaTypeCode, getCpaType } from 'helpers/cpa/cpaTypes'

const getAlertText = (
    assessmentStatus: IAssessmentStatusItem | null | undefined,
    viewerIsEvaluated: boolean | undefined,
    assessmentTypeCode: CpaTypeCode | null | undefined,
    isCompleteByRequestingUser: boolean | undefined,
    // isExpire: boolean,
) => {
    const estimationStatus = assessmentStatus?.code === 'estimation'
    const noDataStatus = assessmentStatus?.code === 'noData'
    const confirmationStatus = assessmentStatus?.code === 'confirmation'
    const summarizingStatus = assessmentStatus?.code === 'summarizing'
    const awaitingComplete = assessmentStatus?.code === 'awaitComplete'
    const selfAss = assessmentTypeCode === 'self'

    if (!viewerIsEvaluated) return ''

    switch (true) {
        case estimationStatus && selfAss || noDataStatus || estimationStatus && !isCompleteByRequestingUser: {
            return 'Пройдите самооценку и сохраните результаты.'
        }
        case (confirmationStatus || summarizingStatus) && selfAss
        || ((estimationStatus || confirmationStatus || summarizingStatus || awaitingComplete)
            && isCompleteByRequestingUser): {
            return 'Вы окончили прохождение оценки. Дождитесь итогов.'
        }
        // case confirmationStatus && !isCompleteByRequestingUser: {
        //     return 'Пройдите самооценку и отправьте результаты.'
        // }
        case (summarizingStatus || awaitingComplete) && !isCompleteByRequestingUser: {
            return 'Ожидайте подведения итогов по имеющимся результатам.'
        }
        default: {
            return ''
        }
    }
}

export const MyAssessmentMetaAlert = () => {
    const assessmentMeta = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMeta)
    const assessmentMetaSetup = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaSetup)
    const assessmentType = getCpaType(assessmentMeta?.cpa?.typeUuid || assessmentMeta?.typeUuid)
    const assessmentStatus = getAssessmentStatus(assessmentMeta?.statusUuid)

    const alertText = getAlertText(
        assessmentStatus,
        assessmentMetaSetup?.viewerIsEvaluated,
        assessmentType,
        assessmentMeta?.isCompleteByRequestingUser,
        // isExpire,
    )

    if (assessmentMetaSetup?.createdStatus || assessmentMetaSetup?.completeStatus || !alertText) return null

    return (
        <GFAlertInfo
            severity="warning"
            text={alertText}
        />
    )
}
