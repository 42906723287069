import React, { FC, useEffect, useState } from 'react'
import { Box, Chip, Stack, Typography } from '@mui/material'
import {
    OAAssessmentIndicatorAnswers,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentCompetence/components/OAAssessmentIndicator/components/OAAssessmentIndicatorAnswers/OAAssessmentIndicatorAnswers'
import { red } from '@mui/material/colors'
import { useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'

type OAAssessmentIndicatorProps = {
    indicator: IAssessmentIndicator;
    competenceUuid: string;
    idx: number;
}

export const OAAssessmentIndicator: FC<OAAssessmentIndicatorProps> = ({
    idx,
    indicator,
    competenceUuid,
}) => {
    const [error, setError] = useState(indicator?.error ?? false)
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const statusComplete = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'

    useEffect(() => {
        setError(!!indicator?.error)
    }, [indicator?.error])

    return (
        <Stack
            p={2}
            spacing={2}
            id={indicator.uuid}
            sx={{ backgroundColor: error ? red[50] : undefined, borderRadius: 4 }}
        >
            <Stack direction="row" spacing={2} alignItems="flex-start">
                <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Box><Typography variant="body2">{idx}.</Typography></Box>
                    <Box><Typography variant="body2">{indicator.title}</Typography></Box>
                </Stack>
                {error && <Chip
                    label="Выберите ответ"
                    color="error"
                    size="small"
                />}
            </Stack>
            <OAAssessmentIndicatorAnswers
                assessmentComplete={statusComplete}
                indicatorUuid={indicator.uuid}
                finalAnswerUuid={indicator.finalAnswerUuid}
                competenceUuid={competenceUuid}
                skillLevelUuid={indicator.skillLevelUuid}
                setError={setError}
            />
        </Stack>
    )
}
