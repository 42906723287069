import React, { FC } from 'react'
import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'

type CommentsIconButtonProps = {
    hasComments: boolean
    canEdit: boolean;
    onClick: () => void;
}
export const CommentsIconButton: FC<CommentsIconButtonProps> = ({ hasComments, canEdit, onClick }) => {
    const CommentIcon: FC = () => hasComments
        ? <CommentOutlined color="primary" />
        : !hasComments && canEdit
            ? <AddCommentOutlined color="action" />
            : null

    if (!CommentIcon) return null

    return (
        <IconButton onClick={onClick}>
            <CommentIcon />
        </IconButton>
    )
}
