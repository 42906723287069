import React, { FC } from 'react'
import { Icon, Tooltip } from '@mui/material'
import { getMaterialType } from 'helpers/getMaterialType'

type MaterialTypeIconProps = {
    materialTypeUuid: string;
}

export const MaterialTypeIcon: FC<MaterialTypeIconProps> = ({ materialTypeUuid }) => {
    const materialType = getMaterialType(materialTypeUuid)

    if (!materialType) return null

    return (
        <Tooltip title={materialType?.title}>
            <Icon sx={{ color: materialType.color }}>
                {React.createElement(materialType.icon)}
            </Icon>
        </Tooltip>
    )
}
