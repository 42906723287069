import React, { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { Button, Divider, Stack } from '@mui/material'
import {
    TITLE_BUTTON_APPROVE_ASSESSMENT,
    TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT,
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CORRECT_RESULT,
    TITLE_BUTTON_SAVE_CHANGES,
} from 'helpers/enums/titles'
import { AppRegistrationRounded, ArrowBackRounded, CheckRounded } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_EMPLOYEES } from 'helpers/enums/routePath'
import { useNotify } from 'store/hooks/useNotify'
import { myAssessmentActions } from 'store/actions'
import { assessmentStatusComplete } from 'helpers/assessment/getAssessmentStatus'
import { format } from 'date-fns'
import { useGetAssessmentPass } from 'api/queries/assessment/assessmentQueries'

export const EmployeeAssessmentActions = () => {
    const { assessmentUuid, employeeUuid } = useParams()
    const navigate = useNavigate()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const currentSummarize = useMemo(
        () => assessmentSetup?.summarizingStatus && assessmentSetup.viewerIsCurrentReviewer,
        [assessmentSetup],
    )

    const { refetch } = useGetAssessmentPass(assessmentUuid || '', false)

    const approveAssessment = async () => {
        if (!assessmentUuid) return
        try {
            await dispatch(myAssessmentActions.assessmentPassChangeStatus({
                uuid: assessmentUuid,
                statusUuid: assessmentStatusComplete,
                finishedAt: format(new Date(), 'dd.MM.yyyy'),
                isManual: true,
            }))
            goToResult()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const goToSummarize = async () => {
        if (!assessmentUuid) return

        try {
            await dispatch(myAssessmentActions.goSummarize(assessmentUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const saveSummarizing = async () => {
        if (!assessmentUuid) return

        try {
            await dispatch(myAssessmentActions.saveSummarize(assessmentUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const cancelSummarizing = async () => {
        if (!assessmentUuid) return

        try {
            await dispatch(myAssessmentActions.cancelSummarize(assessmentUuid))
            refetch()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const goToResult = () => {
        navigate(`${PATH_EMPLOYEES}/${employeeUuid}/assessment/${assessmentUuid}/result`)
    }

    return (
        <Stack spacing={4} divider={<Divider/>}>
            {(assessmentSetup?.confirmationStatus || assessmentSetup?.awaitCompleteStatus) &&
                <Stack
                    gap={2}
                    sx={{
                        flexDirection: { 'mobile': 'column', 'tablet': 'row' },
                        alignItems: { 'mobile': 'stretch', 'tablet': 'center' },
                    }}
                >
                    <Button
                        variant="contained"
                        startIcon={<CheckRounded/>}
                        onClick={approveAssessment}
                    >
                        {TITLE_BUTTON_APPROVE_ASSESSMENT}
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<AppRegistrationRounded/>}
                        onClick={goToSummarize}
                    >
                        {TITLE_BUTTON_CORRECT_RESULT}
                    </Button>
                </Stack>}
            {assessmentSetup?.summarizingStatus && currentSummarize && <Stack
                gap={2}
                sx={{
                    flexDirection: { 'mobile': 'column', 'tablet': 'row' },
                    alignItems: { 'mobile': 'stretch', 'tablet': 'center' },
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<CheckRounded/>}
                    onClick={saveSummarizing}
                >
                    {TITLE_BUTTON_SAVE_CHANGES}
                </Button>
                <Button
                    variant="text"
                    onClick={cancelSummarizing}
                >
                    {TITLE_BUTTON_CANCEL}
                </Button>
            </Stack>}
            <Button
                variant="text"
                startIcon={<ArrowBackRounded/>}
                onClick={goToResult}
                sx={{ alignSelf: 'flex-start' }}
            >
                {TITLE_BUTTON_BACK_TO_ASSESSMENT_RESULT}
            </Button>
        </Stack>
    )
}
