import React, { FC, useMemo, useState } from 'react'
import { Box, Divider, Stack } from '@mui/material'
import {
    TITLE_DRAWER_ADD_MATERIALS_IN_PDP_MOBILE,
    TITLE_NAMESPACE_SELECTED_MATERIALS_COUNT,
} from 'helpers/enums/titles'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { developmentPdpItemActions, pdpItemActions } from 'store/actions'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { containsText } from 'helpers/containsText'
import { HINT_SUCCESS_PDP_MATERIAL_SETUP } from 'helpers/enums/hints'

import { IPdpMaterialFromBaseSend } from 'api/typing/employeeTypes'
import { useParams } from 'react-router-dom'
import { useMobile } from 'helpers/hooks/useMobile'
import { PdpSetupMaterialsActions } from './components/PdpSetupMaterialsActions/PdpSetupMaterialsActions'
import { PdpSetupMaterialsHeader } from './components/PdpSetupMaterialsHeader/PdpSetupMaterialsHeader'
import { PdpSetupMaterialsTable } from './components/PdpSetupMaterialsTable/PdpSetupMaterialsTable'
import { PdpSetupMaterialMobile } from './components/PdpSetupMaterialMobile/PdpSetupMaterialMobile'

import { DrawerFiltersMobile } from 'components/drawers/DrawerFiltersMobile/DrawerFiltersMobile'
import { PdpSetupMaterialsFilters } from './components/PdpSetupMaterialsFilters/PdpSetupMaterialsFilters'

type PdpSetupMaterialsProps = {
    handleClose: () => void;
    isDevelopment?: boolean;
}

export const DrawerPdpSetupMaterials: FC<PdpSetupMaterialsProps> = ({ handleClose, isDevelopment }) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const mobile = useMobile()
    const { pdpUuid } = useParams()
    const [search, setSearch] = useState('')
    const [openFilters, setOpenFilters] = useState(false)
    const { pdp: pdpPdp } = useAppSelector(state => state.pdpReducer.data)
    const {
        item: pdpItem,
        competenceBaseMaterials: pdpCompetenceBaseMaterials,
    } = useAppSelector(state => state.pdpItemReducer.data)
    const { pdp: developmentPdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const {
        item: developmentItem,
        competenceBaseMaterials: developmentCompetenceBaseMaterials,
    } = useAppSelector(state => state.developmentPdpItemReducer.data)

    const pdp = pdpPdp || developmentPdp
    const item = pdpItem || developmentItem
    const employeeUuid = item?.employee?.uuid
    const competenceBaseMaterials = pdpCompetenceBaseMaterials || developmentCompetenceBaseMaterials

    const selectedMaterials = useMemo(
        () => competenceBaseMaterials
            && competenceBaseMaterials.reduce((acc, el) => {
                const { skillLevel, ...item } = el
                if (el.selected) acc.push({ ...item, skillLevelUuid: skillLevel.uuid })
                return acc
            }, [] as IPdpMaterialFromBaseSend[])
            || [],
        [competenceBaseMaterials],
    )
    const countTitle = `${TITLE_NAMESPACE_SELECTED_MATERIALS_COUNT}: ${selectedMaterials.length}`
    const filteredValues = useMemo(
        () => competenceBaseMaterials
            && competenceBaseMaterials.filter(el => containsText(el.title, search)) || [],
        [search, competenceBaseMaterials],
    )

    const { isFetching: fetchingForPdp } = useLayoutFetch(pdpItemActions.getMaterialsForPdpCompetence(
        pdpUuid || '',
        pdp.pointPosition.specializationUuid,
        item.uuid,
        item.entityType,
    ), {
        deps: [pdpUuid, pdp.pointPosition.specializationUuid, item.uuid, item.entityType, !isDevelopment],
        permissions: { service: 'pdp', accessLevel: 'w' },
    })

    const { isFetching: fetchingForDevelopment } = useLayoutFetch(
        developmentPdpItemActions.getMaterialsForPdpCompetence(
            pdpUuid || '',
            pdp.pointPosition.specializationUuid,
            item.uuid,
            item.entityType,
        ), {
            deps: [pdpUuid, pdp.pointPosition.specializationUuid, item.uuid, item.entityType, isDevelopment],
        })

    const addMaterials = async () => {
        if (!employeeUuid || !pdpUuid) return
        try {
            if (isDevelopment)
                await dispatch(developmentPdpItemActions.setupPdpCompetenceMaterials({
                    pdpUuid,
                    employeeUuid,
                    materials: selectedMaterials,
                    itemUuid: item.uuid,
                }))
            else
                await dispatch(pdpItemActions.setupPdpCompetenceMaterials({
                    pdpUuid,
                    employeeUuid,
                    materials: selectedMaterials,
                    itemUuid: item.uuid,
                }))
            notify(HINT_SUCCESS_PDP_MATERIAL_SETUP, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Box
            role="presentation"
            sx={{
                width: mobile ? '100%' : 'calc(100vw - 320px)',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'auto',
            }}
        >
            <PdpSetupMaterialsHeader
                item={item}
                search={search}
                setSearch={setSearch}
                handleClose={handleClose}
                addMaterials={addMaterials}
                countTitle={countTitle}
                setOpenFilters={setOpenFilters}
            />
            {mobile &&
                <DrawerFiltersMobile
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    applySearchFilter={setSearch}
                    subTitle={TITLE_DRAWER_ADD_MATERIALS_IN_PDP_MOBILE}
                >
                    <PdpSetupMaterialsFilters
                        search={search}
                        setSearch={setSearch}
                    />
                </DrawerFiltersMobile>
            }
            {fetchingForPdp || fetchingForDevelopment ?
                <LoadingComponent/>
                :
                <Box
                    sx={{
                        ml: mobile ? 0 : 10,
                        mr: mobile ? 0 : 5,
                        overflow: 'auto',
                    }}
                >
                    {!mobile ?
                        <PdpSetupMaterialsTable
                            filteredValues={filteredValues}
                            isDevelopment={isDevelopment}
                        />
                        :
                        <Stack gap={2} m={3}>
                            {filteredValues?.map(material => (
                                <PdpSetupMaterialMobile
                                    key={material.uuid}
                                    material={material}
                                    isDevelopment={isDevelopment}
                                />
                            ))}
                        </Stack>
                    }
                </Box>
            }
            {mobile &&
                <Box
                    sx={{
                        zIndex: 1,
                        position: 'sticky',
                        bottom: 0,
                        background: 'white',
                    }}
                >
                    <Divider sx={{ mb: 3 }} />
                    <PdpSetupMaterialsActions
                        countTitle={countTitle}
                        handleClose={handleClose}
                        addMaterials={addMaterials}
                    />
                </Box>
            }
        </Box>
    )
}
