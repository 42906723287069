import React, { FC, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'
import { oaAssessmentActions } from 'store/actions'
import {
    Box,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { TITLE_NAMESPACE_TOTAL } from 'helpers/enums/titles'
import { QueueQueueRequestsController } from 'utils/QueueRequests'

type OAAssessmentIndicatorAnswersProps = {
    finalAnswerUuid: string;
    skillLevelUuid: string;
    indicatorUuid: string;
    competenceUuid: string;
    assessmentComplete: boolean;
    setError: (state: boolean) => void;
}

export const OAAssessmentIndicatorAnswers: FC<OAAssessmentIndicatorAnswersProps> = ({
    finalAnswerUuid,
    indicatorUuid,
    competenceUuid,
    assessmentComplete,
    setError,
    skillLevelUuid,
}) => {
    const { assessmentUuid, hash } = useParams()
    const [answer, setAnswer] = useState(finalAnswerUuid)
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { oaAnswers } = useAppSelector(state => state.formReducer.data)
    const totalAnswerTitle = useMemo(() => oaAnswers?.find(el => el.uuid === answer)?.title || '', [answer, oaAnswers])
    const queue = QueueQueueRequestsController.getInstance().addNewQueue(assessmentUuid)

    const asyncAction = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!assessmentUuid || !hash) return
        try {
            await dispatch(oaAssessmentActions.assessmentAddAnswer({
                answerUuid: (e.target as HTMLInputElement).value,
                assessmentUuid,
                indicatorUuid,
                competenceUuid,
                skillLevelUuid,
                hash,
            }))
            setError(false)
            setAnswer(e.target.value as string)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => queue?.enqueue(
        () => asyncAction(e),
        indicatorUuid,
    )

    return (
        <Box>
            {assessmentComplete
                ? <Stack spacing={3} direction="row" divider={<Divider flexItem orientation="vertical"/>}>
                    <Stack spacing={1} justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_TOTAL}
                        </Typography>
                        <Chip
                            label={totalAnswerTitle}
                            color="default"
                            size="small"
                        />
                    </Stack>
                </Stack>
                : <FormControl>
                    <RadioGroup row onChange={handleChange} value={answer}>
                        <Grid container spacing={2}>
                            {oaAnswers?.map(el =>
                                <Grid item zeroMinWidth mobile={12} tablet={3} key={el.uuid}>
                                    <FormControlLabel
                                        value={el.uuid}
                                        control={<Radio/>}
                                        label={el.title}
                                    />
                                </Grid>)}
                        </Grid>
                    </RadioGroup>
                </FormControl>}
        </Box>
    )
}
