import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { containsText } from '../../../helpers/containsText'
import { IExamListItem } from '../../../api/typing/exam'

interface IExamListState {
    isLoading: boolean;
    error: IErrorResponse | null;
    list: IExamListItem[];
    filteredList: IExamListItem[];
    filterTitle: string;
    filterType: IEntity[];
    filterStatus: IEntity[];
}

const initialState: IExamListState = {
    isLoading: false,
    error: null,
    list: [],
    filteredList: [],
    filterTitle: '',
    filterType: [],
    filterStatus: [],
}

const filterValues = (state: IExamListState) => {
    state.filteredList = state.list.filter(item => {
        const acceptTitle = containsText(item.title, state.filterTitle)
        const acceptStatus = !state.filterStatus.length || state.filterStatus.some(el => el.uuid === item.statusUuid)
        return acceptTitle && acceptStatus
    })
}

export const examListSlice = createSlice({
    name: 'examList',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        setList: (state, action: PayloadAction<IExamListItem[]>) => {
            state.list = action.payload
            filterValues(state)
        },
        setTitleFilter: (state, action: PayloadAction<string>) => {
            state.filterTitle = action.payload
            filterValues(state)
        },
        setStatusFilter: (state, action: PayloadAction<IEntity[]>) => {
            state.filterStatus = action.payload
            filterValues(state)
        },
    },
})

export const examListReducer = examListSlice.reducer
