import { AppDispatch } from 'store/store'
import { AssessmentService } from 'api/services/AssessmentService'
import { AssessmentSlice } from 'store/reducers/assessment/AssessmentSlice'
import {
    IAssessmentChangeStatusRequest,
    IAssessmentCreateRequest,
    IAssessmentUpdateRequest,
} from 'api/typing/assessmentServiceTypes'
import { EmployeeService } from 'api/services/EmployeeService'

export class AssessmentAction {
    private static get assessmentApi() {
        return new AssessmentService().assessmentService()
    }

    private static get employeeApi() {
        return new EmployeeService().employeeViewService()
    }

    public setAssessmentMeta(data: IAssessmentMeta) {
        return (dispatch: AppDispatch) => {
            dispatch(AssessmentSlice.actions.setAssessmentMeta(data))
        }
    }
    public clearAssessmentResultState() {
        return (dispatch: AppDispatch) => {
            dispatch(AssessmentSlice.actions.clearState())
        }
    }

    public assessmentCreate(payload: IAssessmentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AssessmentSlice.actions.fetching())
                const data = await AssessmentAction.assessmentApi.assessmentCreate(payload)
                dispatch(AssessmentSlice.actions.setAssessmentMeta(data))
                dispatch(AssessmentSlice.actions.unshiftAssessmentMetaList(data))
                dispatch(AssessmentSlice.actions.fetchingSuccess())
                return data.uuid
            } catch (e: any) {
                dispatch(AssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentDelete(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AssessmentSlice.actions.fetching())
                await AssessmentAction.assessmentApi.assessmentDelete(uuid)
                dispatch(AssessmentSlice.actions.setAssessmentMeta({} as IAssessmentMeta))
                dispatch(AssessmentSlice.actions.deleteAssessmentMetaFormList(uuid))
                dispatch(AssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AssessmentSlice.actions.fetchingError(e))
                dispatch(AssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public assessmentUpdate(payload: IAssessmentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AssessmentSlice.actions.fetching())
                const data = await AssessmentAction.assessmentApi.assessmentUpdate(payload)
                if (payload.onlyEndedAt) {
                    dispatch(AssessmentSlice.actions.updateAssessmentMetaEndedAt(payload))
                } else {
                    dispatch(AssessmentSlice.actions.setAssessmentMeta(data))
                }
                dispatch(AssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setAssessmentStatus(payload: IAssessmentChangeStatusRequest & { finishedAt?: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AssessmentSlice.actions.fetching())
                void await AssessmentAction.assessmentApi.assessmentStatus(payload)
                dispatch(AssessmentSlice.actions.assessmentStatusChange(payload))
                dispatch(AssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeAssessments(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(AssessmentSlice.actions.fetching())
                const data = await AssessmentAction.employeeApi.getAssessments(employeeUuid)
                await dispatch(AssessmentSlice.actions.setAssessmentMetaList(data))
                dispatch(AssessmentSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(AssessmentSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public setEmployeeAssessments(data: IAssessmentMeta[]) {
        return (dispatch: AppDispatch) => {
            dispatch(AssessmentSlice.actions.setAssessmentMetaList(data))
        }
    }
}
