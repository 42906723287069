import { AppDispatch } from 'store/store'
import axios from 'axios'
import { CompanyService } from 'api/services/CompanyService'
import { companySlice } from 'store/reducers/CompanySlice'


export class CompanyAction {
    private static get companyApi() {
        return new CompanyService(axios)
    }

    public getCompany() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(companySlice.actions.companyPending())
                const data = await CompanyAction.companyApi.getCompany()
                dispatch(companySlice.actions.companySuccess(data))
            } catch (e: any) {
                dispatch(companySlice.actions.companyError(e))
                throw e
            }
        }
    }

    public setCompany(data: ICompany) {
        return (dispatch: AppDispatch) => {
            dispatch(companySlice.actions.companySuccess(data))
        }
    }
}
