import React, { FC, useState } from 'react'
import { ICpaGetEmployeesForAddResponse } from 'api/typing/cpaTypes'
import { Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { ChevronRightRounded, ExpandLess, ExpandMore, MoreHorizRounded } from '@mui/icons-material'
import {
    CPAEmployeeDrawerContentEmployee,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerContent/components/CPAEmployeeDrawerContentEmployee/CPAEmployeeDrawerContentEmployee'
import {
    CPAEmployeeDrawerDivisionCheckbox,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesHeader/components/CPAEmployeesAction/components/CPAEmployeesDrawer/components/CPAEmployeesDrawerContent/components/CPAEmployeesDrawerContentDivision/components/CPAEmployeeDrawerDivisionCheckbox/CPAEmployeeDrawerDivisionCheckbox'

type CPAEmployeesDrawerContentDivisionProps = {
    division: ICpaGetEmployeesForAddResponse;
}

export const CPAEmployeesDrawerContentDivision: FC<CPAEmployeesDrawerContentDivisionProps> = ({ division }) => {
    const [expand, setExpand] = useState(true)

    return (
        <Stack spacing={3}>
            <Tooltip placement="bottom-start" title={division.fullPath} enterDelay={300} enterNextDelay={300}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <CPAEmployeeDrawerDivisionCheckbox
                        divisionUuid={division.uuid}
                        employees={division.employees}
                    />
                    <Stack direction="row" alignItems="center" spacing={0.5} onClick={() => setExpand(prev => !prev)}>
                        <MoreHorizRounded/>
                        <ChevronRightRounded/>
                        <Typography variant="subtitle1">
                            {division.title}
                        </Typography>
                        {expand ? <ExpandLess/> : <ExpandMore/>}
                    </Stack>
                </Stack>
            </Tooltip>
            {expand && <Divider/>}
            <Collapse
                in={expand}
                timeout="auto"
                unmountOnExit
                sx={{
                    marginLeft: 2,
                    paddingLeft: 2,
                }}
            >
                <Stack spacing={2} divider={<Divider/>}>
                    {division.employees.map(employee => <CPAEmployeeDrawerContentEmployee
                        key={employee.uuid}
                        employee={employee}
                    />)}
                </Stack>
            </Collapse>
        </Stack>
    )
}
