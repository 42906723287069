import React, { FC } from 'react'
import { Stack } from '@mui/material'
import {
    CPAEmployeeDrawerPreviewAssessmentCompetence,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/components/CPAEmployeeDrawerPreviewAssessment/components/CPAEmployeeDrawerPreviewAssessmentCompetencies/components/CPAEmployeeDrawerPreviewAssessmentCompetence/CPAEmployeeDrawerPreviewAssessmentCompetence'

type CPAEmployeeDrawerPreviewAssessmentCompetenciesProps = {
    competencies: {
        uuid: string;
        title: string;
        typeTitle: string;
        skillLevelTitle: string;
        indicators: IEntity[];
    }[];
}

export const CPAEmployeeDrawerPreviewAssessmentCompetencies:
    FC<CPAEmployeeDrawerPreviewAssessmentCompetenciesProps> = ({
        competencies,
    }) => {
        return (
            <Stack spacing={8}>
                {competencies?.map((competence: any) => <CPAEmployeeDrawerPreviewAssessmentCompetence
                    key={competence.uuid}
                    competence={competence}
                />)}
            </Stack>
        )
    }
