import { AppDispatch } from 'store/store'
import { employeeHistoryPdpSlice } from 'store/reducers/employee/view/tabs/history/EmployeeHistoryPdpSlice'
import { PdpService } from 'api/services/PdpService'


export class EmployeeHistoryAction {
    private static get employeePdpApi() {
        return new PdpService().pdpService()
    }

    public getEmployeeHistoryPdp(employeeUuid: string, pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeHistoryPdpSlice.actions.fetching())
                const data = await EmployeeHistoryAction.employeePdpApi.getHistoryPdp({ employeeUuid, pdpUuid })
                dispatch(employeeHistoryPdpSlice.actions.getHistoryPdp(data))
            } catch (e: any) {
                dispatch(employeeHistoryPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeHistoryPdpItem(employeeUuid: string, pdpUuid: string, itemUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeHistoryPdpSlice.actions.fetching())
                const data = await EmployeeHistoryAction.employeePdpApi.getHistoryPdpItem({
                    employeeUuid,
                    pdpUuid,
                    itemUuid,
                })
                dispatch(employeeHistoryPdpSlice.actions.getHistoryPdpItem(data))
            } catch (e: any) {
                dispatch(employeeHistoryPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

}
