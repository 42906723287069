import axios, { AxiosStatic } from 'axios'
import { DevelopmentPlanService } from 'api/services/OpenAccessService/developmentPlanService'
import { OAAssessmentService } from 'api/services/OpenAccessService/OAAssessmentService'


interface IOpenAccessService {
    developmentPlanService: () => DevelopmentPlanService;
    oaAssessmentService: () => OAAssessmentService;
}

export class OpenAccessService implements IOpenAccessService {
    private readonly axiosInstance: AxiosStatic

    constructor() {
        this.axiosInstance = axios
    }

    public developmentPlanService() {
        return DevelopmentPlanService.getInstance(this.axiosInstance)
    }

    public oaAssessmentService() {
        return new OAAssessmentService(this.axiosInstance)
    }
}
