import React, { FC } from 'react'
import { PdpCompetenceMaterialCard } from 'components/common/PdpCompetenceMaterialCard/PdpCompetenceMaterialCard'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { IconButton, Stack } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type PdpCompetenceMaterialMobileProps = {
    material: IPdpCompetenceMaterial;
    skillLevelUuid: string;
    pdpStatus: IPdpStatus;
    toggleDialog: (arg1: any, arg2: boolean) => void;
    lift: (value: string) => any;
    isControlDragging?: boolean;
    firstItem: boolean;
    lastItem: boolean;
    isUniqueMaterial: boolean;
    isDevelopment?: boolean;
    openDeleteMaterial: () => void;
    openEditMaterial: () => void;
}

export const PdpCompetenceMaterialMobile: FC<PdpCompetenceMaterialMobileProps> = ({
    material,
    skillLevelUuid,
    pdpStatus,
    toggleDialog,
    lift,
    isControlDragging,
    firstItem,
    lastItem,
    isDevelopment,
    openDeleteMaterial,
    openEditMaterial,
    isUniqueMaterial,
}) => {
    return (
        <Stack direction='row' alignItems='center' mb={3}>
            <Stack>
                <IconButton
                    color='primary'
                    disabled={isControlDragging || firstItem}
                    onClick={() => {
                        const drag = lift(material.uuid)
                        drag.moveUp()
                        drag.drop()
                    }}
                >
                    <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                    color='primary'
                    disabled={isControlDragging || lastItem}
                    onClick={() => {
                        const drag = lift(material.uuid)
                        drag.moveDown()
                        drag.drop()
                    }}
                >
                    <KeyboardArrowDownIcon />
                </IconButton>
            </Stack>
            <PdpCompetenceMaterialCard
                material={material}
                pdpStatus={pdpStatus}
                toggleDialog={toggleDialog}
                skillLevelUuid={skillLevelUuid}
                isDevelopment={isDevelopment}
                openDeleteMaterial={openDeleteMaterial}
                openEditMaterial={openEditMaterial}
                isUniqueMaterial={isUniqueMaterial}
            />
        </Stack>
    )
}
