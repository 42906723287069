import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Loading } from 'components/Loading/Loading'
import {
    OAAssessmentInit,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessmentInit/OAAssessmentInit'
import {
    OAAssessment,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/OAAssessment'


export const OAAssessmentPage = () => {

    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route index element={<OAAssessmentInit/>}/>
                <Route path=":assessmentUuid">
                    <Route index element={<OAAssessment/>}/>
                </Route>
            </Routes>
        </Suspense>
    )
}
