import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { containsText } from 'helpers/containsText'


interface ICPAListState {
    list: ICpaListItem[];
    filteredList: ICpaListItem[];
    filterTitle: string;
    filterType: IEntity[];
    filterStatus: IEntity[];
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: ICPAListState = {
    list: [],
    filteredList: [],
    filterTitle: '',
    filterType: [],
    filterStatus: [],
    isLoading: false,
    error: null,
}

const filterValues = (state: ICPAListState) => {
    state.filteredList = state.list.filter(item => {
        const acceptTitle = containsText(item.title, state.filterTitle)
        const acceptType = !state.filterType.length || state.filterType.some(el => el.uuid === item.typeUuid)
        const acceptStatus = !state.filterStatus.length || state.filterStatus.some(el => el.uuid === item.statusUuid)
        return acceptTitle && acceptType && acceptStatus
    })
}

export const CPAListSlice = createSlice({
    name: 'CPAList',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },

        setList: (state, action: PayloadAction<ICpaListItem[]>) => {
            state.list = action.payload
            filterValues(state)
        },

        setTitleFilter: (state, action: PayloadAction<string>) => {
            state.filterTitle = action.payload
            filterValues(state)
        },

        setTypeFilter: (state, action: PayloadAction<IEntity[]>) => {
            state.filterType = action.payload
            filterValues(state)
        },

        setStatusFilter: (state, action: PayloadAction<IEntity[]>) => {
            state.filterStatus = action.payload
            filterValues(state)
        },
    },
})

export const CPAListReducer = CPAListSlice.reducer
