import { FormService } from 'api/services/FormService'
import { useQuery } from 'react-query'
import { useAppDispatch } from 'store/hooks/redux'
import { cpaItemCriteriaActions, cpaItemScaleActions, formActions, myAssessmentActions } from 'store/actions'
// import { GetErrorResponse } from 'helpers/getErrorResponse'
// import { useNotify } from 'store/hooks/useNotify'

const formApi = new FormService().sharedFormService()
const cpaFormApi = new FormService().cpaFormService()
const getGradesFn = async (gradeUuid: string) => await formApi.getGradeList(gradeUuid)
export const getDivisionsFn = async () => await formApi.geDivisionsList()
export const getSpecializationsFn = async () => await formApi.getSpecializationList()
export const getAssessmentAnswersFn = async () => await formApi.getAssessmentAnswers()
const getCpaTypesFn = async () => await formApi.getCpaTypes()
const getCpaRolesFn = async () => await formApi.getCpaRoles()
const getCpaCriteriaFn = async () => await cpaFormApi.getCpaCriteria()
const getCpaCriteriaCompetenciesFn = async () => await cpaFormApi.getCpaCriteriaCompetencies()
const getCpaScalesFn = async () => await cpaFormApi.getCpaScales()
const getPassMarkTypesFn = async () => await formApi.getPassMarkTypes()
const getQuestionsTypesFn = async () => await formApi.getQuestionsTypes()

export const useFormSpecializations = () => {
    return useQuery({
        queryKey: 'specialization',
        queryFn: getSpecializationsFn,
        refetchOnWindowFocus: false,
        retry: 2,
    })
}

export const useFormCpaTypes = () => {
    return useQuery({
        queryKey: 'cpaTypes',
        queryFn: getCpaTypesFn,
        refetchOnWindowFocus: false,
        retry: 0,
        refetchOnMount: false,
    })
}
export const useFormCpaRoles = (enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useFormCpaRoles',
        queryFn: getCpaRolesFn,
        onSuccess: (data) => {
            dispatch(formActions.setCpaRoles(data))
        },
        refetchOnWindowFocus: false,
        retry: 0,
        refetchOnMount: false,
        enabled,
    })
}

export const useFormGrades = (specializationUuid: string) => {
    return useQuery({
        queryKey: 'grades',
        queryFn: () => getGradesFn(specializationUuid),
        refetchOnWindowFocus: false,
        retry: 0,
        enabled: !!specializationUuid,
    })
}

export const useFormCpaCriteria = (enable: boolean) => {
    return useQuery({
        queryKey: 'cpaCriteria',
        queryFn: getCpaCriteriaFn,
        refetchOnWindowFocus: false,
        retry: 0,
        enabled: enable,
    })
}

export const useFormCpaCriteriaCompetencies = () => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'cpaCriteriaCompetencies',
        queryFn: getCpaCriteriaCompetenciesFn,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemCriteriaActions.setCriteriaCompetenceList(data))
        },
        retry: 0,
    })
}

export const useFormCpaScale = (enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'cpaScale',
        queryFn: getCpaScalesFn,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(cpaItemScaleActions.setScaleList(data))
        },
        retry: 0,
        enabled,
    })
}

export const useGetAssessmentAnswers = (enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: 'useGetAssessmentAnswers',
        queryFn: getAssessmentAnswersFn,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(myAssessmentActions.setAssessmentPassAnswers(data))
        },
        retry: 0,
        enabled,
    })
}

export const useGetDivisions = (enabled = true) => {
    return useQuery({
        queryKey: 'getDivisionsFn',
        queryFn: getDivisionsFn,
        refetchOnWindowFocus: false,
        retry: 0,
        enabled,
    })
}

export const usePassMarkTypes = () => {
    return useQuery({
        queryKey: 'passMarkTypes',
        queryFn: getPassMarkTypesFn,
        refetchOnWindowFocus: false,
        retry: 0,
        refetchOnMount: false,
    })
}

export const useQuestionsTypes = () => {
    return useQuery({
        queryKey: 'QuestionsTypes',
        queryFn: getQuestionsTypesFn,
        refetchOnWindowFocus: false,
        retry: 0,
        refetchOnMount: false,
    })
}
