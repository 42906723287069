import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IMassExamDeleteEmployeeRequest,
    IMassExamGetEmployeeForAddResponse, IMassExamGetItemEmployeesResponse,
} from 'api/typing/massExam'

interface IMassExamItemEmployeesState {
    selectedEmployees: IMassExamGetItemEmployeesResponse[];
    employeesList: IMassExamGetEmployeeForAddResponse[];
    currentSavedEmployeeList: string[];
    employeeListForSave: string[];
    isLoading: boolean,
    error: IErrorResponse | null,
}

const initialState: IMassExamItemEmployeesState = {
    selectedEmployees: [],
    employeesList: [],
    currentSavedEmployeeList: [],
    employeeListForSave: [],
    isLoading: false,
    error: null,
}

export const MassExamItemEmployeesSlice = createSlice({
    name: 'MassExamItemEmployees',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,
        setAllEmployees: (state, action: PayloadAction<IMassExamGetEmployeeForAddResponse[]>) => {
            state.employeesList = action.payload
        },
        restoreEmployeeList: (state) => {
            state.employeeListForSave = [...state.currentSavedEmployeeList]
        },
        selectAllEmployeeList: (state) => {
            const savedSet = new Set('')
            state.employeesList.forEach(division => {
                division.employees.forEach(employee => {
                    savedSet.add(employee.uuid)
                })
            })

            state.employeeListForSave = Array.from(savedSet)
        },

        removeAllEmployeeList: (state, action: PayloadAction<string[]>) => {
            const savedSet = new Set(state.employeeListForSave)
            if (savedSet.size <= 0) return

            state.employeeListForSave = action.payload
        },

        saveEmployeesToMassExam: (state) => {
            state.currentSavedEmployeeList = [...state.employeeListForSave]

        },

        setSelectStateEmployee: (state, action: PayloadAction<{ employeeUuid: string; isSelected: boolean }>) => {
            const savedSet = new Set(state.employeeListForSave)

            action.payload.isSelected
                ? savedSet.add(action.payload.employeeUuid)
                : savedSet.delete(action.payload.employeeUuid)

            state.employeeListForSave = Array.from(savedSet)
        },

        setSelectStateDivision: (state, action: PayloadAction<{ divisionUuid: string; isSelected: boolean }>) => {
            const savedSet = new Set(state.employeeListForSave)
            state.employeesList.forEach(division => {
                if (division.uuid !== action.payload.divisionUuid) return
                division.employees.forEach(employee => {
                    if (employee.exam?.finishedAt) return
                    action.payload.isSelected ? savedSet.add(employee.uuid) : savedSet.delete(employee.uuid)
                })
            })

            state.employeeListForSave = Array.from(savedSet)
        },

        setExamedEmployees: (state, action: PayloadAction<IMassExamGetItemEmployeesResponse[]>) => {
            state.selectedEmployees = action.payload
            const savedSet = new Set<string>()
            action.payload.forEach(division => {
                division.employees?.forEach(employee => savedSet.add(employee.uuid))
            })
            state.employeeListForSave = Array.from(savedSet)
            state.currentSavedEmployeeList = Array.from(savedSet)
        },

        deleteEmployee: (state, action: PayloadAction<IMassExamDeleteEmployeeRequest>) => {
            const { employeeUuid, divisionUuid } = action.payload
            const divisionIdx = state.selectedEmployees.findIndex(el => el.uuid === divisionUuid)
            state.selectedEmployees[divisionIdx].employees = state
                .selectedEmployees[divisionIdx]
                .employees
                .filter(el => el.uuid !== employeeUuid)

            state.currentSavedEmployeeList = state.currentSavedEmployeeList.filter(el => el !== employeeUuid)
            state.employeeListForSave = state.employeeListForSave.filter(el => el !== employeeUuid)
            if (state.selectedEmployees[divisionIdx].employees.length <= 0)
                state.selectedEmployees.splice(divisionIdx, 1)
        },
    },
})

export const MassExamItemEmployeesReducer = MassExamItemEmployeesSlice.reducer
