import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    Box,
    Typography, useMediaQuery, useTheme,
} from '@mui/material'
import {
    TITLE_BUTTON_CANCEL, TITLE_BUTTON_DELETE,
    TITLE_DIALOG_DELETE_SPECIALIZATION,
    TITLE_NAMESPACE_SPECIALIZATION_ACCUSATIVE,
} from 'helpers/enums/titles'
import { useNotify } from 'store/hooks/useNotify'
import {
    HINT_DELETE_SPECIALIZATION_FROM_DIVISION,
    HINT_IMPOSSIBLE_TO_CANCEL_ACTION,
    HINT_SPECIALIZATION_WILL_SAVED_IN_OTHER_DIVISIONS, HINT_SUCCESS_DELETE_SPECIALIZATION,
    HINT_SURE_TO_DELETE,
} from 'helpers/enums/hints'
import { specializationListActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { PATH_SPECIALIZATION } from 'helpers/enums/routePath'
import { useNavigate } from 'react-router-dom'
import { compareString } from 'helpers/compareString'

type DeleteSpecializationDialogProps = {
    element: Omit<ISpecializationListSpecialization, 'gradesCount' | 'employeesCount'>;
    currentDivisionUuid: string;
    includedDivisions: ISpecializationMetaForCreateDivision[];
    handleClose: () => void;
    open: boolean;
}

export const DeleteSpecializationDialog: FC<DeleteSpecializationDialogProps> = ({
    open,
    handleClose,
    element,
    currentDivisionUuid,
    includedDivisions,
}) => {
    const notify = useNotify()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))

    const singleDelete = async () => {
        try {
            await dispatch(specializationListActions.deleteOne({
                specializationUuid: element.uuid,
                divisionsUuid: [currentDivisionUuid],
            }))
            notify(HINT_SUCCESS_DELETE_SPECIALIZATION, 'success')
            await handleClose()
            navigate(PATH_SPECIALIZATION)
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    const contextText = compareString(
        '',
        HINT_SURE_TO_DELETE,
        TITLE_NAMESPACE_SPECIALIZATION_ACCUSATIVE.toLowerCase(),
        `«${element.title}»`,
        HINT_DELETE_SPECIALIZATION_FROM_DIVISION,
        `«${includedDivisions.find(el => el.uuid === currentDivisionUuid)?.title ?? ''}»?`,
    )


    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth
            sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_DELETE_SPECIALIZATION}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <Typography variant="body2" mb={1}>
                        {contextText}
                    </Typography>
                    <Typography variant="body2" mb={1}>
                        {HINT_IMPOSSIBLE_TO_CANCEL_ACTION}
                    </Typography>
                    {includedDivisions.length > 1 ?
                        <Typography variant="body2" mt={3} fontWeight={700}>
                            {HINT_SPECIALIZATION_WILL_SAVED_IN_OTHER_DIVISIONS}
                        </Typography> : null}
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={singleDelete}
                    >
                        {TITLE_BUTTON_DELETE}
                    </Button>
                </DialogActions>
            </Box>

        </Dialog>
    )
}
