import React, { FC, useEffect } from 'react'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CREATE_FOR_CURRENT,
    TITLE_BUTTON_SAVE,
    TITLE_DIALOG_UPDATE_ASSESSMENT,
    TITLE_FIELD_ASSESSMENT_ENDED_AT,
    TITLE_FIELD_GRADE,
    TITLE_NAMESPACE_SPECIALIZATION,
} from 'helpers/enums/titles'
import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    InputLabel,
    ListSubheader,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import {
    HINT_CHOOSE,
    HINT_EMPLOYEE_CREATE_ASSESSMENT,
    HINT_ERROR_REQUIRED,
    HINT_SUCCESS_ASSESSMENT_UPDATED,
} from 'helpers/enums/hints'
import { AddButton } from 'components/common/AddButton/AddButton'
import { ChevronRightRounded, DateRangeRounded, MoreHorizRounded } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { assessmentActions, formActions } from 'store/actions'
import { IAssessmentUpdateRequest } from 'api/typing/assessmentServiceTypes'
import { InputForm } from 'components/form/InputForm/InputForm'
import { DatePicker } from '@mui/x-date-pickers'
import { addYears, format, parse } from 'date-fns'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import { useNotify } from 'store/hooks/useNotify'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import { useFormGrades, useFormSpecializations } from 'api/queries/form/formQueries'

type AssessmentForm = Omit<IAssessmentUpdateRequest, 'endedAt' | 'specializationUuid'> & {
    endedAt: Date | null
    specialization: IFormSpecialization | null;
}

type DialogUpdateAssessment = {
    handleClose: () => void;
    open: boolean;
    viewerIsEvaluated?: boolean;
    assessmentMeta: IAssessmentMeta;
}

export const DialogUpdateAssessment: FC<DialogUpdateAssessment> = ({
    open,
    handleClose,
    viewerIsEvaluated,
    assessmentMeta,
}) => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const {
        data: formSpecializations,
        isFetching: fetchSpecializations,
        error: errorSpecializations,
    } = useFormSpecializations()
    const {
        data: formGrades,
        isFetching: fetchGrades,
        error: errorGrades,
    } = useFormGrades(assessmentMeta.position.specializationUuid)

    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { uuid, grade, specialization } = useAppSelector(state => state.userReducer.data)
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        getValues,
        formState: { errors },
    } = useForm<AssessmentForm>({
        defaultValues: {
            uuid: assessmentMeta.uuid,
            specialization: formSpecializations?.find(el =>
                el.uuid === assessmentMeta.position.specializationUuid) || null,
            gradeUuid: assessmentMeta.position.gradeUuid,
            employeeUuid: assessmentMeta?.evaluated?.uuid,
            endedAt: isValidDateFromApi(assessmentMeta.endedAt)
                ? parse(assessmentMeta.endedAt, 'dd.MM.yyyy', new Date())
                : null,
        },
    })
    const assessmentStatus = getAssessmentStatus(assessmentMeta?.statusUuid)
    const currentSpecialization = assessmentMeta.evaluated.uuid === uuid ? specialization : employee?.specialization
    const currentGrade = assessmentMeta.evaluated.uuid === uuid ? grade : employee?.grade

    useEffect(() => {
        if (!formGrades?.some(el => el.uuid === getValues('gradeUuid')))
            setValue('gradeUuid', '')
    }, [formGrades])

    useEffect(() => {
        reset({
            uuid: assessmentMeta.uuid,
            specialization: formSpecializations?.find(el =>
                el.uuid === assessmentMeta.position.specializationUuid) || null,
            gradeUuid: assessmentMeta.position.gradeUuid,
            employeeUuid: assessmentMeta?.evaluated?.uuid,
            endedAt: isValidDateFromApi(assessmentMeta.endedAt)
                ? parse(assessmentMeta.endedAt, 'dd.MM.yyyy', new Date())
                : null,
        })
    }, [fetchSpecializations, fetchGrades])

    const updateGrades = async (specializationUuid?: string) => {
        if (!specializationUuid) return
        try {
            await dispatch(formActions.getGrades(specializationUuid))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }


    const onSubmit = async (data: AssessmentForm) => {
        if (!data.endedAt && !viewerIsEvaluated) return
        const onlyEndedAt =
            data.specialization?.uuid === assessmentMeta.position.specializationUuid
            && data.gradeUuid === assessmentMeta.position.gradeUuid

        try {
            await dispatch(assessmentActions.assessmentUpdate({
                ...data,
                onlyEndedAt,
                endedAt: viewerIsEvaluated ? assessmentMeta.endedAt : data.endedAt ? format(data.endedAt, 'dd.MM.yyyy') : assessmentMeta.endedAt,
                specializationUuid: data.specialization?.uuid || '',
            }))
            notify(HINT_SUCCESS_ASSESSMENT_UPDATED, 'success')
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createForCurrent = () => {
        if (assessmentStatus?.code !== 'created') return
        setValue('specialization', formSpecializations?.find(el => el.uuid === currentSpecialization?.uuid) || null)
        setValue('gradeUuid', currentGrade?.uuid || '')
    }

    if (fetchSpecializations || fetchGrades) return null

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                overflow="hidden"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <DialogTitle onClose={handleClose}>
                    {TITLE_DIALOG_UPDATE_ASSESSMENT}
                </DialogTitle>
                {errorSpecializations || errorGrades
                    ? <Box overflow="auto" py={2}>
                        <ErrorPage error={errorSpecializations || errorGrades} />
                    </Box>
                    : <DialogContent>
                        <Stack spacing={2.5}>
                            <Box>
                                <Typography variant="body2">
                                    {HINT_EMPLOYEE_CREATE_ASSESSMENT}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" mb={1}>
                                    Текущие специализация и грейд сотрудника
                                </Typography>
                                <Stack direction="row" flexWrap="wrap" gap={1} alignItems="center">
                                    <Chip
                                        label={`${currentSpecialization?.title} - ${currentGrade?.title}`}
                                    />
                                    <AddButton
                                        disabled={assessmentStatus?.code !== 'created'}
                                        onClick={createForCurrent}
                                    >
                                        {TITLE_BUTTON_CREATE_FOR_CURRENT}
                                    </AddButton>
                                </Stack>
                            </Box>
                            <Box>
                                <InputLabel htmlFor="specialization" sx={{ mb: 0.5 }}>
                                    {TITLE_NAMESPACE_SPECIALIZATION} *
                                </InputLabel>
                                <Controller
                                    name="specialization"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            id="specialization"
                                            options={formSpecializations ?? []}
                                            getOptionLabel={(option) => option.title}
                                            filterSelectedOptions
                                            disabled={assessmentStatus?.code !== 'created'}
                                            isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
                                            groupBy={option => option.parentDivisionTitle}
                                            onChange={(e, d) => {
                                                field.onChange(d)
                                                void updateGrades(d?.uuid)
                                            }}
                                            renderGroup={({ group, key, children }) => {
                                                const currentDivision =
                                                    formSpecializations?.find(el =>
                                                        el.parentDivisionTitle === group)
                                                return (
                                                    <div key={key}>
                                                        <ListSubheader sx={{ position: 'sticky', top: '-8px' }}>
                                                            <Tooltip
                                                                title={currentDivision?.fullPath || ''}
                                                                placement="bottom-start"
                                                                PopperProps={{
                                                                    sx: {
                                                                        '& .MuiTooltip-tooltip': {
                                                                            // TODO: Need fix. Need important to override custom slyles
                                                                            margin: '-4px !important',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    <MoreHorizRounded color="disabled"
                                                                        fontSize="small" />
                                                                    <ChevronRightRounded color="disabled"
                                                                        fontSize="small"
                                                                        sx={{ mr: 0.5 }} />
                                                                    {group}
                                                                </Box>
                                                            </Tooltip>
                                                        </ListSubheader>
                                                        {children}
                                                    </div>
                                                )
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} value={option.title}>
                                                    <Typography
                                                        variant="body2"
                                                        ml={7}
                                                    >
                                                        {option.title}
                                                    </Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    title={TITLE_NAMESPACE_SPECIALIZATION}
                                                    placeholder={HINT_CHOOSE}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name="gradeUuid"
                                    control={control}
                                    rules={{ required: HINT_ERROR_REQUIRED }}
                                    render={({ field }) => (
                                        <SelectForm
                                            {...field}
                                            id="gradeUuid"
                                            title={TITLE_FIELD_GRADE}
                                            required
                                            disabled={fetchSpecializations || fetchGrades || assessmentStatus?.code !== 'created'}
                                            placeholder={HINT_CHOOSE}
                                            values={formGrades || []}
                                            error={!!errors.gradeUuid}
                                            helperText={errors?.gradeUuid ? errors.gradeUuid.message : null}
                                        />
                                    )}
                                />
                            </Box>
                            <Typography variant="body2">
                                Смена специализации или грейда приведет к полной замене вопросов оценки
                            </Typography>
                            {!viewerIsEvaluated && <Divider />}
                            {!viewerIsEvaluated && <Box>
                                <Controller
                                    control={control}
                                    name="endedAt"
                                    rules={{ ...fieldsRestriction.employees.employee.assessment.endedAt }}
                                    render={({ field: { ref, onBlur, ...field }, fieldState }) => (
                                        <DatePicker
                                            {...field}
                                            inputRef={ref}
                                            inputFormat="dd.MM.yyyy"
                                            disabled={fetchSpecializations || fetchGrades}
                                            minDate={new Date()}
                                            maxDate={addYears(new Date(), 100)}
                                            components={{ OpenPickerIcon: DateRangeRounded }}
                                            toolbarPlaceholder={HINT_CHOOSE}
                                            renderInput={(params) => (
                                                <InputForm
                                                    {...params}
                                                    id="endedAt"
                                                    title={TITLE_FIELD_ASSESSMENT_ENDED_AT}
                                                    onBlur={onBlur}
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: HINT_CHOOSE,
                                                    }}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>}
                        </Stack>
                    </DialogContent>}
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button
                        variant="contained"
                        disabled={!!errorSpecializations || !!errorGrades}
                        type="submit"
                    >
                        {TITLE_BUTTON_SAVE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
