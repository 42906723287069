import React, { FC, useMemo, useState } from 'react'
import { PATH_SPECIALIZATION } from 'helpers/enums/routePath'
import { Box, Divider, Link, Typography, useTheme } from '@mui/material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import {
    TITLE_NAMESPACE_EMPLOYEES_GENITIVE,
    TITLE_NAMESPACE_GRADES_GENITIVE, TITLE_NAMESPACE_NO,
} from 'helpers/enums/titles'
import {
    EditSpecializationDialog,
} from 'pages/specialization/SpecializationList/components/dialogs/EditSpecializationDialog/EditSpecializationDialog'
import { useAppSelector } from 'store/hooks/redux'
import {
    DeleteSpecializationDialog,
} from 'pages/specialization/SpecializationList/components/dialogs/DeleteSpecializationDialog/DeleteSpecializationDialog'
import { NavLink as RouterLink } from 'react-router-dom'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'

type SpecializationListSpecializationProps = {
    element: ISpecializationListSpecialization;
    divisionUuid: string;
}

export const SpecializationListSpecialization: FC<SpecializationListSpecializationProps> = ({
    element,
    divisionUuid,
}) => {
    const { list, createOneMeta } = useAppSelector(state => state.specializationListReducer.data)
    const theme = useTheme()
    const [edit, setEdit] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)

    const includedDivisions = useMemo(() => list?.map(division => {
        if (division?.specializations?.some(specialization => specialization.uuid === element.uuid))
            return division.uuid
        return ''
    }).filter(Boolean), [list, element])

    const includedDivisionsMeta = useMemo(() =>
        createOneMeta?.divisions?.filter(division => includedDivisions.includes(division.uuid)),
    [createOneMeta, includedDivisions])

    const onDelete = () => setDeleteDialog(true)

    const onEdit = () => setEdit(true)

    return (
        <Box ml={1.5}>
            {edit && <EditSpecializationDialog
                element={element}
                open={edit}
                selectedDivisions={includedDivisionsMeta}
                handleClose={() => setEdit(false)}
            />}
            {deleteDialog && <DeleteSpecializationDialog
                open={deleteDialog}
                includedDivisions={includedDivisionsMeta}
                element={element}
                currentDivisionUuid={divisionUuid}
                handleClose={() => setDeleteDialog(false)}
            />}
            <Box display="flex" mb={1.5}>
                <Box flex="1" display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Box mb={0.5}>
                            <Link
                                component={RouterLink}
                                to={`${PATH_SPECIALIZATION}/${element.uuid}/grade`}
                                variant="body1"
                            >
                                {element.title}
                            </Link>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1">
                                {TITLE_NAMESPACE_EMPLOYEES_GENITIVE}: {element.employeesCount > 0
                                    ? element.employeesCount
                                    : <span style={{ color: theme.palette.warning.main }}>
                                        {TITLE_NAMESPACE_NO}
                                    </span>}
                            </Typography>
                            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem/>
                            <Typography variant="body1">
                                {TITLE_NAMESPACE_GRADES_GENITIVE}: {element.gradesCount > 0
                                    ? element.gradesCount
                                    : <span style={{ color: theme.palette.warning.main }}>
                                        {TITLE_NAMESPACE_NO}
                                    </span>}
                            </Typography>
                        </Box>
                    </Box>
                    <Authenticated service="specializations" accessLevel="w" divisions={[divisionUuid]}>
                        <SecondaryActions
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    </Authenticated>
                </Box>
            </Box>
            <Divider sx={{ mb: 1.5 }} />
        </Box>
    )
}
