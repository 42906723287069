import React from 'react'
import { useAppSelector } from 'store/hooks/redux'
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { TITLE_NAMESPACE_ACCORDANCE_REQUIREMENTS } from 'helpers/enums/titles'
import {
    OAAssessmentResultStrongSide,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultSides/components/OAAssessmentResultStrongSide/OAAssessmentResultStrongSide'
import {
    OAAssessmentResultWeakSide,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultSides/components/OAAssessmentResultWeakSide/OAAssessmentResultWeakSide'

export const OAAssessmentResultSides = () => {
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    return (
        <Stack spacing={4}>
            <Card>
                <CardContent>
                    <Stack spacing={3}>
                        <Box>
                            <Typography variant="h2">
                                {TITLE_NAMESPACE_ACCORDANCE_REQUIREMENTS} {assessmentResult?.score}%
                            </Typography>
                        </Box>
                        <Divider/>
                        <OAAssessmentResultStrongSide/>
                        <OAAssessmentResultWeakSide/>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}
