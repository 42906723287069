import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { getAssessmentStatus } from 'helpers/assessment/getAssessmentStatus'
import { oaAssessmentActions } from 'store/actions'
import { useParams } from 'react-router-dom'
import {
    OAAssessmentResultSides,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultSides/OAAssessmentResultSides'
import {
    OAAssessmentGraphic,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentGraphic/OAAssessmentGraphic'
import {
    OAAssessmentResultTree,
} from 'pages/openAccess/OpenAccessPage/components/OAAssessmentPage/components/OAAssessment/components/OAAssessmentResult/components/OAAssessmentResultTree/OAAssessmentResultTree'
import { Stack } from '@mui/material'

export const OAAssessmentResult = () => {
    const dispatch = useAppDispatch()
    const { hash } = useParams()
    const assessment = useAppSelector(state => state.oaAssessmentReducer.assessment)
    const assessmentResult = useAppSelector(state => state.oaAssessmentReducer.assessmentResult)
    const showGraphic = assessmentResult?.score > 1

    const assessmentComplete = getAssessmentStatus(assessment?.statusUuid)?.code === 'complete'

    useEffect(() => {
        if (!assessmentComplete || !hash || !assessment?.uuid) return
        dispatch(oaAssessmentActions.getAssessmentResult(assessment.uuid, hash))

    }, [assessmentComplete])

    if (!assessmentComplete) return null
    if (!Object.values(assessmentResult || {}).length) return null

    return (
        <Stack spacing={4}>
            <OAAssessmentResultSides/>
            {showGraphic && <OAAssessmentGraphic/>}
            <OAAssessmentResultTree/>
        </Stack>
    )
}
