import React, { FC } from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

type GFProgressBarProps = BoxProps & {
    value: string | number;
    title?: string;
}

export const GFProgressBar: FC<GFProgressBarProps> = ({ value, title, ...boxProps }) => {
    return (
        <Box width="100%" {...boxProps}>
            {title && <Typography variant="body1" mb={1}>
                {title}
            </Typography>}
            <Box
                width="100%"
                sx={{
                    backgroundColor: (theme) => theme.palette.background.default,
                    height: 32,
                    borderRadius: 4,
                    p: 0.25,
                }}
                display="flex"
                alignItems="stretch"
            >
                {value ? <Box
                    width={`${value}%`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        borderRadius: 4,
                        backgroundColor: (theme) => theme.palette.primary.main,
                    }}
                >
                    <Typography variant="caption" color="white" sx={{ fontSize: '14px !important' }}>
                        {value}%
                    </Typography>
                </Box>
                    : <Box
                        flex={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.disabled }}>
                            0%
                        </Typography>
                    </Box>}

            </Box>
        </Box>

    )
}

