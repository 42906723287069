import React, { FC, ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

type QueryClientLayerProps = {
    children: ReactNode;
}

export const QueryClientLayer: FC<QueryClientLayerProps> = ({children}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
