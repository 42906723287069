import { useQuery } from 'react-query'
import { AnalyticService } from 'api/services/AnalyticService'
import { useAppDispatch } from 'store/hooks/redux'
import { analyticCpaAction } from 'store/actions'

// Инициализация сервиса аналитики для выполнения запросов к сервису
const analyticApi = new AnalyticService().analyticService()

// Описание функций сервиса аналитики
const getCpaStatisticSummary = async (cpaUuid: string) => await analyticApi.getCpaStatisticSummary(cpaUuid)
const getCpaStatisticEmployee = async (cpaUuid: string) => await analyticApi.getCpaStatisticEmployee(cpaUuid)
// TODO: delete
// const getAnalyticCpaCurrentPosition = async (cpaUuid: string) =>
//     await analyticApi.getAnalyticCpaCurrentPosition(cpaUuid)
const getAnalyticCpaPointCompetence = async (cpaUuid: string) =>
    await analyticApi.getAnalyticCpaPointCompetence(cpaUuid)
const getAnalyticCpaCompetenceTree = async (cpaUuid: string) =>
    await analyticApi.getAnalyticCpaCompetenceTree(cpaUuid)
const getAnalyticCpaLevelCompliance = async (cpaUuid: string) =>
    await analyticApi.getAnalyticCpaLevelCompliance(cpaUuid)

// Обертка над useQuery для получения данных с сервиса аналитики
export const useGetAnalyticCpaStatisticSummary = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['useGetAnalyticCpaStatisticSummary', cpaUuid],
        queryFn: () => getCpaStatisticSummary(cpaUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        // при успешном запросе - инициируем экшн установки данных в store аналитики
        onSuccess: (data) => {
            dispatch(analyticCpaAction.setSummary(data))
        },
        retry: 0,
        enabled,
    })
}
export const useGetAnalyticCpaStatisticEmployee = (cpaUuid: string, enabled = true) => {
    return useQuery({
        queryKey: ['useGetAnalyticCpaStatisticEmployee', cpaUuid],
        queryFn: () => getCpaStatisticEmployee(cpaUuid),
        retry: 0,
        enabled,
    })
}

export const useGetAnalyticCpaCurrentPosition = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['getAnalyticCpaCurrentPosition', cpaUuid],
        queryFn: () => getAnalyticCpaPointCompetence(cpaUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(analyticCpaAction.setCpaPointCompetenceForCurrentPosition(data))
        },
        retry: 0,
        enabled,
    })
}

export const useGetAnalyticCpaPointCompetence = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['getAnalyticCpaPointCompetence', cpaUuid],
        queryFn: () => getAnalyticCpaPointCompetence(cpaUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(analyticCpaAction.setCpaPointCompetence(data))
        },
        retry: 0,
        enabled,
    })
}


export const useGetAnalyticCpaCompetenceTree = (cpaUuid: string, enabled = true) => {
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['getAnalyticCpaCompetenceTree', cpaUuid],
        queryFn: () => getAnalyticCpaCompetenceTree(cpaUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            dispatch(analyticCpaAction.setCpaCompetenceTree(data))
        },
        retry: 0,
        enabled,
    })
}

export const useGetAnalyticCpaLevelCompliance = (cpaUuid: string, enabled = true) => {
    return useQuery({
        queryKey: ['getAnalyticCpaLevelCompliance', cpaUuid],
        queryFn: () => getAnalyticCpaLevelCompliance(cpaUuid),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 0,
        enabled,
    })
}
