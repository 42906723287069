import React, { FC, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const StyledLink = styled(Typography)({
    borderBottom: '1px solid',
    ':hover': {
        cursor: 'pointer',
        color: 'black',
    },
})

type TextLinkProps = {
    children?: ReactNode;
    onCustomClickEvent?: () => void;
}
export const TextLink: FC<TextLinkProps> = ({ children, onCustomClickEvent }) => (
    <StyledLink
        onClick={onCustomClickEvent}
    >
        {children && children}
    </StyledLink>
)
