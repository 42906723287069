import { Box, Button, Checkbox, IconButton, Stack, TableCell, TableRow, useTheme } from '@mui/material'
import { MaterialTypeIcon } from 'components/common/MaterialTypeIcon/MaterialTypeIcon'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { TITLE_BUTTON_OPEN } from 'helpers/enums/titles'
import React, { FC } from 'react'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import { useAppSelector } from 'store/hooks/redux'

type DevelopmentPdpCompetenceMaterialTableRowProps = {
    material: IPdpCompetenceMaterial;
    pdpStatus: IPdpStatus;
    toggleDialog: (arg1: any, arg2: boolean) => void;
    skillLevelUuid: string;
    isDevelopment?: boolean;
    changeActiveMaterial: () => void;
    hideCommentBtn: boolean;
}

export const DevelopmentPdpCompetenceMaterialTableRow: FC<DevelopmentPdpCompetenceMaterialTableRowProps> = ({
    material,
    pdpStatus,
    toggleDialog,
    changeActiveMaterial,
    hideCommentBtn,

}) => {
    const { isLoading, data: { item } } = useAppSelector(state => state.developmentPdpItemReducer)
    const theme = useTheme()

    return (
        <TableRow
            style={{
                // @ts-ignore
                background: material.isPassed ? theme.palette.success[50] : 'none',
                height: 52,
            }}
        >
            <TableCell align="center">
                <Checkbox
                    checked={material.isPassed}
                    disabled={pdpStatus.disableChangeItemStatus || isLoading}
                    onClick={changeActiveMaterial}
                />
            </TableCell>
            <TableCell>
                <MaterialTypeIcon materialTypeUuid={material.materialTypeUuid}/>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box flex={1}>
                        {material.title}
                    </Box>
                    {!!material?.changeLog &&
                        <BadgeChangeLog changeLog={material?.changeLog} evaluatedUuid={item?.employee?.uuid}/>
                    }
                </Stack>
            </TableCell>
            <TableCell align="center">
                {!hideCommentBtn && <IconButton
                    color="primary"
                    onClick={() => toggleDialog('dialogCommentView', true)}
                >
                    {material.comments?.length > 0
                        ? <CommentOutlined color="primary"/>
                        : <AddCommentOutlined color={'action'}/>}
                </IconButton>}
            </TableCell>
            <TableCell align="left">
                {material.format}
            </TableCell>
            <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                {formatHours(material.studyTime)}
            </TableCell>
            <TableCell sx={{ paddingRight: 0 }} align="left">
                {material.link && <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    endIcon={<ArrowForwardRoundedIcon />}
                    target="_blank"
                    href={material.link}
                >
                    {TITLE_BUTTON_OPEN}
                </Button>}
            </TableCell>
        </TableRow>
    )
}
