import { useAppSelector } from 'store/hooks/redux'
import { appRoles, getPermission, services } from 'helpers/permissions'

type UseAuthenticationProps = {
    service: PermissionServiceCode;
    accessLevel: AccessLevel;
    divisions?: string[];
}

// Проверка на соответстивя уровня прав доступа
const checkAccessLevel = (requiredAccess: AccessLevel, currentAccess: AccessLevel) => {
    switch (true) {
        case currentAccess === 'wx':
            return true
        case currentAccess === 'w':
            return currentAccess === requiredAccess || requiredAccess === 'r' || requiredAccess === 'rx'
        case currentAccess === 'rx':
            return requiredAccess === 'r' || requiredAccess === 'rx'
        case currentAccess === 'r':
            return currentAccess === requiredAccess
        case currentAccess === 'd' || !currentAccess:
            return false
        default:
            return false
    }
}

// Проверка на права доступа по выбранным подразделениям
const checkDivisionsAccess = (
    accessLevel: AccessLevel,
    requiredAccess: AccessLevel,
    divisionManager: string[],
    divisions?: string[],
) => {
    // В случае отуствия условия подразделений
    // или полных прав или права на редактирование, при требовании только чтения, возвращаем доступность
    if (accessLevel === 'wx'
        || accessLevel === 'w' && requiredAccess === 'r'
        || accessLevel === 'rx'
        || !divisions
        || divisions.length < 0
    ) return true
    if (accessLevel === 'd' || !divisionManager || divisionManager.filter(Boolean).length <= 0) return false

    return divisions.some(division => divisionManager.includes(division))
}

export const useAuthentication: (params: UseAuthenticationProps) => boolean = ({ service, accessLevel, divisions }) => {
    const { permissions: userPermissions, roleList, divisionManager } = useAppSelector(state => state.userReducer.data)

    // Для админа и рута проверка не учитывается
    if (roleList?.includes(appRoles.admin) || roleList?.includes(appRoles.root))
        return true
    // Если уровень доступа к компоненту = d - доступ к его просмотру закрыт
    if (accessLevel === 'd') return false

    // Получаем uuid сервиса доступа
    const requiredServiceUuid = services?.[service].uuid
    // Получаем текущий уид доступа к сервису у пользователя
    const currentPermissionUuid = userPermissions?.[requiredServiceUuid]
    // Получаем кодовое значение по uuid доступа к сервису
    const currentPermission = getPermission(currentPermissionUuid)
    // Получаем текущий уровень доступа к текущему сервису
    const currentAccessLevel = currentPermission?.accessLevel
    // Выполняем проверку соответствия уровня доступа
    const hasAccessFromAccessLevel = checkAccessLevel(accessLevel, currentAccessLevel)
    // Выполняем проверку соответствия доступа по подразделениям
    const hasAccessFromDivisionManage = checkDivisionsAccess(
        currentAccessLevel,
        accessLevel,
        divisionManager,
        divisions,
    )

    // Доступ разрешен, в случае выполнения всех проверок
    return hasAccessFromDivisionManage && hasAccessFromAccessLevel
}
