import React, { FC } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { TITLE_BUTTON_ADD_OR_UPDATE_COMMENT, TITLE_BUTTON_DELETE, TITLE_BUTTON_EDIT } from 'helpers/enums/titles'

type SecondaryActionsProps = {
    onEdit?: () => void;
    editTitle?: string;
    onComment?: () => void;
    commentTitle?: string;
    onDelete?: () => void;
    deleteTitle?: string;
    disabled?: boolean;
}

export const SecondaryActions: FC<SecondaryActionsProps> = ({
    onEdit,
    onDelete,
    onComment,
    editTitle = TITLE_BUTTON_EDIT,
    deleteTitle = TITLE_BUTTON_DELETE,
    commentTitle = TITLE_BUTTON_ADD_OR_UPDATE_COMMENT,
    disabled = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClickAnchor = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseAnchor = () => {
        setAnchorEl(null)
    }

    const clickEdit = () => {
        handleCloseAnchor()
        onEdit && onEdit()
    }

    const clickDelete = () => {
        handleCloseAnchor()
        onDelete && onDelete()
    }

    const clickComment = () => {
        handleCloseAnchor()
        onComment && onComment()
    }

    return (
        <>
            <IconButton
                disabled={disabled}
                aria-controls={open ? 'control-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickAnchor}
            >
                <MoreVertIcon color={!disabled ? 'primary' : 'disabled'} />
            </IconButton>
            <Menu
                aria-labelledby={'control-menu'}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseAnchor}
                PaperProps={{ sx: { borderRadius: 4 } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {onEdit && <MenuItem onClick={clickEdit}>
                    {editTitle}
                </MenuItem>}
                {onComment && <MenuItem onClick={clickComment}>
                    {commentTitle}
                </MenuItem>}
                {onDelete && <MenuItem
                    sx={{ color: (theme) => theme.palette.error.main }}
                    onClick={clickDelete}
                >
                    {deleteTitle}
                </MenuItem>}
            </Menu>
        </>
    )
}
